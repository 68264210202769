import { mdiSend } from "@mdi/js";
import React, { useEffect, useRef, useState } from "react";
import { Input } from "rsuite";
import { StandardIcon } from "../../../01-atoms/Icons/Icons";
import "./MessageInput.scss";
type Props = {};

const MessageInput = (props: Props) => {
  const textareaRef = useRef<HTMLTextAreaElement | null>(null);

  // The value of the textarea
  const [value, setValue] = useState<string>("");

  useEffect(() => {
    if (textareaRef && textareaRef.current) {
      textareaRef.current.style.height = "0px";
      const scrollHeight = textareaRef.current.scrollHeight;
      textareaRef.current.style.height = scrollHeight + "px";
    }
  }, [value]);

  const onSubmit = (value: string) => {
    setValue("");
  };

  useEffect(() => {
    const listener = (event: any) => {
      if (
        (event.code === "Enter" && !(event.shiftKey || event.ctrlKey)) ||
        (event.code === "NumpadEnter" && !(event.shiftKey || event.ctrlKey))
      ) {
        event.preventDefault();
        onSubmit(value);
      }
    };
    document.addEventListener("keydown", listener);
    return () => {
      document.removeEventListener("keydown", listener);
    };
  }, [value]);

  return (
    <div className="phalcon-inbox__message-input">
      {/* <ButtonToolbar className="message-input__toolbar">
         <ButtonGroup>
          <IconButton icon={<Icon path={mdiFormatBold} size={0.75} />} />
          <IconButton icon={<Icon path={mdiFormatItalic} size={0.75} />} />
          <IconButton
            icon={<Icon path={mdiFormatStrikethrough} size={0.75} />}
          />
          <IconButton icon={<Icon path={mdiFormatUnderline} size={0.75} />} />
          <IconButton
            icon={<Icon path={mdiFormatListNumbered} size={0.75} />}
          />
          <IconButton
            icon={<Icon path={mdiFormatListBulleted} size={0.75} />}
          />
          <IconButton icon={<Icon path={mdiFormatQuoteOpen} size={0.75} />} />
        </ButtonGroup>
        <ButtonGroup>
          <StandardIcon icon={mdiPaperclip} clickable />
           <IconButton icon={<Icon path={mdiAt} size={0.75} />} />
        </ButtonGroup>
      </ButtonToolbar> */}
      <Input
        as="textarea"
        type="text"
        rows={3}
        value={value}
        className="message-input__textarea"
        ref={textareaRef}
        placeholder="Enoyer un message dans #Général"
        onChange={(value) => setValue(value)}
      />
      <StandardIcon
        icon={mdiSend}
        clickable
        className="phalcon-inbox__message-input--button"
        /* onClick={() => onSubmit(value)} */
      />
    </div>
  );
};

export default MessageInput;
