import { createSlice } from "@reduxjs/toolkit";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { salesTargetServices } from "../../services/salesTarget";
import {
  AchivedSalesTargetListInterface,
  ExpectedSalesTargetListInterface,
  SalesTargetInterface,
  SalesTargetListInterface,
} from "../../types/SalesTarget.model";

export interface SalesTargetState {
  salesTargetrequestState: "idle" | "loading";
  isExport: "idle" | "loading";
  errors: any;
  salesTargetList: SalesTargetListInterface | null;
  achivedSalesTargetListExport: AchivedSalesTargetListInterface | null;
  estimatedSalesTargetListExport: ExpectedSalesTargetListInterface | null;
}

export const addSalesTarget = createAsyncThunk(
  "[SalesTarget]_AddSalesTarget_",
  async (payload: SalesTargetInterface[], { rejectWithValue }) => {
    try {
      const response = await salesTargetServices.addSalesTarget(
        payload,
        rejectWithValue
      );
      return response;
    } catch (err: any) {
      return err;
    }
  }
);

export const getThExpectedSalesTarget = createAsyncThunk(
  "[SalesTarget]_GetThExpectedSalesTarget_",
  async (payload: any, { rejectWithValue }) => {
    try {
      const response = await salesTargetServices.getSalesTargetList(
        payload,
        rejectWithValue
      );
      return response;
    } catch (err: any) {
      return err;
    }
  }
);

export const getSalesTargetAchivedListExport = createAsyncThunk(
  "[SalesTarget]_getSalesTargetAchivedListExport_",
  async (payload: any, { rejectWithValue }) => {
    try {
      const response =
        await salesTargetServices.getSalesTargetAchivedListToBeExported(
          payload,
          rejectWithValue
        );
      return response;
    } catch (err: any) {
      return err;
    }
  }
);

export const getestimatedSalesTargetListExport = createAsyncThunk(
  "[SalesTarget]_getestimatedSalesTargetListExport_",
  async (payload: any, { rejectWithValue }) => {
    try {
      const response =
        await salesTargetServices.getEstimatedSalesTargetListToBeExported(
          payload,
          rejectWithValue
        );
      return response;
    } catch (err: any) {
      return err;
    }
  }
);

export const deleteSalesTarget = createAsyncThunk(
  "[SalesTarget]_deleteSalesTarget_",
  async (payload: any, { rejectWithValue }) => {
    try {
      const response =
        await salesTargetServices.deleteSalesTarget(
          payload,
          rejectWithValue
        );
      return response;
    } catch (err: any) {
      return err;
    }
  }
);

export const updateSalesTargetApi = createAsyncThunk(
  "[SalesTarget]_updateSalesTarget_",
  async (payload: any, { rejectWithValue }) => {
    try {
      const response =
        await salesTargetServices.updateSalesTarget(
          payload,
          rejectWithValue
        );
      return response;
    } catch (err: any) {
      return err;
    }
  }
);

const initialState: SalesTargetState = {
  salesTargetrequestState: "idle",
  isExport: "idle",
  errors: null,
  salesTargetList: null,
  achivedSalesTargetListExport: null,
  estimatedSalesTargetListExport: null,
};

export const salesTargetSlice = createSlice({
  name: "[SalesTarget]",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(addSalesTarget.pending, (state) => {
      state.salesTargetrequestState = "loading";
      state.errors = null;
    });
    builder.addCase(addSalesTarget.fulfilled, (state, { payload }) => {
      state.salesTargetrequestState = "idle";
      state.errors = null;
    });
    builder.addCase(addSalesTarget.rejected, (state, { payload }) => {
      state.salesTargetrequestState = "idle";
      state.errors = payload;
    });
    builder.addCase(getThExpectedSalesTarget.pending, (state) => {
      state.salesTargetrequestState = "loading";
      state.errors = null;
    });
    builder.addCase(
      getThExpectedSalesTarget.fulfilled,
      (state, { payload }) => {
        state.salesTargetrequestState = "idle";
        state.errors = null;
        state.salesTargetList = payload;
      }
    );
    builder.addCase(getThExpectedSalesTarget.rejected, (state, { payload }) => {
      state.salesTargetrequestState = "idle";
      state.errors = payload;
      state.salesTargetList = null;
    });
    builder.addCase(getSalesTargetAchivedListExport.pending, (state) => {
      state.isExport = "loading";
      state.errors = null;
    });
    builder.addCase(
      getSalesTargetAchivedListExport.fulfilled,
      (state, { payload }) => {
        state.isExport = "idle";
        state.errors = null;
        state.achivedSalesTargetListExport = payload;
      }
    );
    builder.addCase(
      getSalesTargetAchivedListExport.rejected,
      (state, { payload }) => {
        state.isExport = "idle";
        state.errors = payload;
        state.achivedSalesTargetListExport = null;
      }
    );
    builder.addCase(getestimatedSalesTargetListExport.pending, (state) => {
      state.isExport = "loading";
      state.errors = null;
    });
    builder.addCase(
      getestimatedSalesTargetListExport.fulfilled,
      (state, { payload }) => {
        state.isExport = "idle";
        state.errors = null;
        state.estimatedSalesTargetListExport = payload;
      }
    );
    builder.addCase(
      getestimatedSalesTargetListExport.rejected,
      (state, { payload }) => {
        state.isExport = "idle";
        state.errors = payload;
        state.estimatedSalesTargetListExport = null;
      }
    );
    builder.addCase(deleteSalesTarget.pending, (state) => {
      state.salesTargetrequestState = "loading";
      state.errors = null;
    });
    builder.addCase(
      deleteSalesTarget.fulfilled,
      (state, { payload }) => {
        state.salesTargetrequestState = "idle";
        state.errors = null;
      }
    );
    builder.addCase(
      deleteSalesTarget.rejected,
      (state, { payload }) => {
        state.salesTargetrequestState = "idle";
        state.errors = payload;
       
      }
    );
    builder.addCase(updateSalesTargetApi.pending, (state) => {
      state.salesTargetrequestState = "loading";
      state.errors = null;
    });
    builder.addCase(
      updateSalesTargetApi.fulfilled,
      (state, { payload }) => {
        state.salesTargetrequestState = "idle";
        state.errors = null;
      }
    );
    builder.addCase(
      updateSalesTargetApi.rejected,
      (state, { payload }) => {
        state.salesTargetrequestState = "idle";
        state.errors = payload;
       
      }
    );
  },
});
 
export default salesTargetSlice.reducer;
