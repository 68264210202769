import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { Button, Checkbox, Form } from "rsuite";
import { toast } from "react-toastify";
import { useForm, Controller } from "react-hook-form";
import "./LoginForm.scss";
import { useAppDispatch, useAppSelector } from "../../../../redux/hooks";
import { login } from "../../../../redux/Authentication/authenticationSlice";
import { TextField } from "../../../02-molecules/forms/TextField/TextField";
import { PasswordField } from "../../../02-molecules/forms/PasswordField/PasswordField";
import { RootState } from "../../../../redux/store";
import { useAuthErrors } from "../../../../utils/useAuthErrors";
interface Props {}
type LoginInputs = {
  username: string;
  password: string;
  rememberMe: boolean;
};

export const LoginForm = ({ ...props }: Props) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<LoginInputs>({
    defaultValues: { username: "", password: "", rememberMe: false },
  });

  const onSubmit = handleSubmit((data) => {
    dispatch(login(data));
  });

  const fieldIsRequired = t("common.error_messages.is_required");
  const { requestState, error } = useAppSelector(
    (state: RootState) => state.auth
  );

  //Errors Handling
  const formError = requestState === "fail";

  let errorMessage = useAuthErrors({
    page: "login_page",
    status: error.status,
  });

  useEffect(() => {
    formError &&
      toast.error(errorMessage, {
        position: "bottom-right",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formError]);

  return (
    <Form className="login__form__wrapper">
      <TextField
        label={t("login_page.username_label")}
        inputName="username"
        rules={{ required: fieldIsRequired }}
        control={control}
        errors={errors}
      />

      <PasswordField
        label={t("login_page.password_label")}
        inputName="password"
        rules={{ required: fieldIsRequired }}
        control={control}
        errors={errors}
        passwordCheck = {false}
      />

      <div className="actions">
        <div className="remember-me__checkbox">
          <Controller
            control={control}
            name="rememberMe"
            render={({ field: { onChange, name, value } }) => (
              <Form.Control
                name={name}
                accepter={Checkbox}
                checked={value}
                defaultChecked={false}
                onChange={(e: any, val: any) => {
                  onChange(val);
                }}
              />
            )}
          />
          <Form.ControlLabel>{t("login_page.remember_me")}</Form.ControlLabel>
        </div>
        <div className="auth-form-link">
          <Link to="/forgot-password">{t("login_page.forgot_password")}</Link>
        </div>
      </div>
      <Button
        loading={requestState === "loading"}
        type="submit"
        appearance="primary"
        onClick={onSubmit}
        className="submit-button"
      >
        {t("login_page.submit_button")}
      </Button>
    </Form>
  );
};
