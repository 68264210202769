import _ from "lodash";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import { RootState } from "../../../redux/store";
import {
  applyTargetChange,
  assignDoctorsToTarget,
  copyDoctors,
  redoTargetChange,
  undoTargetChange,
} from "../../../redux/Target/targetSlice";
import { Target } from "../../../types/Target.model";
import { TargetWeek } from "../../../types/TargetWeek.model";

const useTarget = () => {
  const dispatch = useAppDispatch();
  const {
    targetById,
    targetByIdEdit,
    clipBoard,
    assignDone,
    selectedDoctors,
    activeUpdatedState,
    activeUpdatedStateIndex,
    redoIsActive,
    resetIsActive,
    undoIsActive,
    requestState,
  } = useAppSelector((state: RootState) => state.target);
  const { doctorSummary } = useAppSelector((state: RootState) => state.doctor);

  const handleCopy = (innerWeekSelection: number[]) => {
    dispatch(copyDoctors(innerWeekSelection));
  };
  const handleCut = (activeWeek: TargetWeek, innerWeekSelection: number[]) => {
    dispatch(copyDoctors(innerWeekSelection));

    activeUpdatedState &&
      dispatch(
        applyTargetChange({
          ...activeUpdatedState,
          targetWeeks:
            activeUpdatedState &&
            activeUpdatedState.targetWeeks &&
            activeUpdatedState.targetWeeks.length > 0
              ? activeUpdatedState?.targetWeeks.map((e) => {
                  if (e.targetWeekId === (activeWeek.targetWeekId || 0)) {
                    return {
                      ...e,
                      doctors: e.doctors.filter(
                        (item) => !innerWeekSelection.includes(item.doctorId)
                      ),
                    };
                  } else return e;
                })
              : [],
        })
      );
  };

  const handlePaste = (activeWeek: TargetWeek) => {
    const pastableIds = clipBoard.filter(
      (item) => !activeWeek.doctors.map((el) => el.doctorId).includes(item)
    );

    activeUpdatedState &&
      dispatch(
        applyTargetChange({
          ...activeUpdatedState,
          targetWeeks:
            activeUpdatedState &&
            activeUpdatedState.targetWeeks &&
            activeUpdatedState.targetWeeks.length > 0
              ? activeUpdatedState?.targetWeeks.map((e) => {
                  if (e.targetWeekId === (activeWeek.targetWeekId || 0)) {
                    return {
                      ...e,
                      doctors:
                        clipBoard.length > 0
                          ? [
                              ...e.doctors,
                              ...doctorSummary.filter((elem) =>
                                pastableIds.includes(elem.doctorId)
                              ),
                            ]
                          : [...e.doctors],
                    };
                  } else return e;
                })
              : [],
        })
      );
  };
  const handleReset = (activeWeek: TargetWeek) => {
    activeUpdatedState &&
      dispatch(
        applyTargetChange({
          ...activeUpdatedState,
          targetWeeks:
            activeUpdatedState &&
            activeUpdatedState.targetWeeks &&
            activeUpdatedState.targetWeeks.length > 0
              ? activeUpdatedState?.targetWeeks.map((e) => {
                  if (e.targetWeekId === (activeWeek.targetWeekId || 0)) {
                    return {
                      ...e,
                      doctors: [],
                    };
                  } else return e;
                })
              : [],
        })
      );
  };

  const handleUndo = () => {
    dispatch(undoTargetChange());
  };
  const handleRedo = () => {
    dispatch(redoTargetChange());
  };

  const prepareUpdateTargetPayload = (activeUpdatedState: Target) => {
    return {
      targetId: activeUpdatedState?.targetId,
      targetWeeks: activeUpdatedState?.targetWeeks?.map((el: TargetWeek) => {
        return {
          targetWeekId: el.targetWeekId,
          clients: _.uniqBy(el.doctors, (client) => client.doctorId),
        };
      }),
    };
  };

  const handleAssign = () => {
    let updatedTargetPayload =
      activeUpdatedState && prepareUpdateTargetPayload(activeUpdatedState);
    !assignDone &&
      activeUpdatedState &&
      updatedTargetPayload &&
      dispatch(assignDoctorsToTarget(updatedTargetPayload));
  };

  return {
    targetById,
    targetByIdEdit,
    clipBoard,
    assignDone,
    selectedDoctors,
    activeUpdatedState,
    activeUpdatedStateIndex,
    redoIsActive,
    resetIsActive,
    undoIsActive,
    handleCopy,
    handleCut,
    handlePaste,
    handleReset,
    handleUndo,
    handleRedo,
    doctorSummary,
    handleAssign,
    requestState,
  };
};

export default useTarget;
