import { RootState } from "./../../../../redux/store";
import { useAppSelector } from "./../../../../redux/hooks";
import { useEffect, useState } from "react";
import moment from "moment";
export type RpFormContext = "read" | "add" | "update";
interface UseRpFormProps {
  context: RpFormContext;
}
export interface RpFormInputs {
  subject: string;
  type: string | number | null;
  date: [Date, Date] | undefined;
  budget: string;
  facility: string;
  principalContact: string;
  principalContactPhone: string;
  email: string;
  description: string;
  tags: (number | string)[];
  users: number[];
  doctors: number[];
  externals: number[];
  moderators: number[];
  products: number[];
  comment: string;
}
export const useRpForm = ({ context }: UseRpFormProps) => {
  const [initForm, setInitForm] = useState<RpFormInputs>({
    subject: "",
    type: null,
    date: undefined,
    budget: "",
    facility: "",
    principalContact: "",
    principalContactPhone: "",
    email: "",
    description: "",
    tags: [],
    users: [],
    doctors: [],
    externals: [],
    moderators: [],
    products: [],
    comment: "",
  });
  const { rpEventById } = useAppSelector((state: RootState) => state.rpEvent);

  const prepareInitData = (ctx: RpFormContext) => {
    if (ctx === "add") {
      return;
    } else {
      let start: Date | null = rpEventById
        ? moment(rpEventById.startDate).local().toDate()
        : null;
      let end: Date | null = rpEventById
        ? moment(rpEventById.endDate).local().toDate()
        : null;
      const date: [Date, Date] | undefined =
        start && end ? [start, end] : undefined;

      let init = {
        subject: rpEventById?.subject || "",
        type: rpEventById?.type || null,
        date,
        budget: `${rpEventById?.esitimatedBudget}` || "",
        facility: rpEventById?.place || "",
        principalContact: rpEventById?.principalContact
          ? rpEventById?.principalContact.fullName
          : "",
        principalContactPhone: rpEventById?.principalContact
          ? rpEventById?.principalContact.phone
          : "",
        email: rpEventById?.principalContact
          ? rpEventById?.principalContact.email
          : "",
        description: rpEventById?.description || "",
        tags:
          rpEventById?.tags && rpEventById?.tags.length > 0
            ? rpEventById?.tags.map((e) => e.tagId)
            : [],
        users:
          rpEventById?.participants.users &&
          rpEventById?.participants.users.length > 0
            ? rpEventById?.participants.users.map((e) => e.userId)
            : [],
        doctors:
          rpEventById?.participants.doctors &&
          rpEventById?.participants.doctors.length > 0
            ? rpEventById?.participants.doctors.map((e) => e.doctorId)
            : [],
        externals:
          rpEventById?.participants.externals &&
          rpEventById?.participants.externals.length > 0
            ? rpEventById?.participants.externals.map((e) => e.externalId)
            : [],
        moderators:
          rpEventById?.participants.moderators &&
          rpEventById?.participants.moderators.length > 0
            ? rpEventById?.participants.moderators.map((e) => e.externalId)
            : [],
        products: [],
        comment: rpEventById?.comment || "",
      };

      setInitForm(init);
    }
  };

  useEffect(() => {
    prepareInitData(context);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rpEventById]);

  return {
    initForm,
  };
};
