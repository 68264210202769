import React from "react";
import { mdiHelpCircleOutline, mdiInformationOutline } from "@mdi/js";
import { Icon } from "@mdi/react";
import { Tooltip } from "@material-ui/core";

type Props = {
  message: string;
  style?: React.CSSProperties | undefined;
  type: "help" | "info";
};

const ToolTip = ({ message, style, type }: Props) => {
  return (
    <Tooltip title={message}>
      <Icon
        path={type === "help" ? mdiHelpCircleOutline : mdiInformationOutline}
        style={style}
      />
    </Tooltip>
  );
};

export default ToolTip;
