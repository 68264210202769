import {
  IconButton,
  Popover,
  Typography,
  Button,
  createTheme,
  ThemeProvider,
} from "@material-ui/core";
import { MoreVert, RemoveRedEye } from "@material-ui/icons";
import React from "react";
import "./PhalconCell.scss";

type Props = {
  onClick: (event: React.MouseEvent<HTMLButtonElement>) => void;
  handleDispatchServiceByContext: (
    context: "paymentDetails" | "orderDetails" | "deliveryDetails" | null,
    id: number
  ) => void;
  selectedRowId: number | null;
  popOverIsOpen: boolean;
  reference?: number | string | undefined;
  handlePopOverClose: () => void;
  anchorEl: HTMLButtonElement | null;
  setIsDetailsModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setContext: React.Dispatch<
    React.SetStateAction<
      "paymentDetails" | "orderDetails" | "deliveryDetails" | null
    >
  >;
};

const orderListOfActionButton: {
  label: string;
  context: "paymentDetails" | "orderDetails" | "deliveryDetails";
}[] = [
  { label: "Détails Commande", context: "orderDetails" },
  { label: "Détails Livraison", context: "deliveryDetails" },
  { label: "Détails Paiement", context: "paymentDetails" },
];

export default function ActionButtonCell({
  onClick,
  popOverIsOpen,
  reference,
  handlePopOverClose,
  anchorEl,
  setIsDetailsModalOpen,
  setContext,
  handleDispatchServiceByContext,
  selectedRowId,
}: Props) {
  const theme = createTheme({
    overrides: {
      MuiPopover: {
        paper: {
          border: " 0.5px solid #646464",
          borderRadius: "5%",
        },
      },
    },
  });
  return (
    <div>
      <IconButton
        color="primary"
        onClick={onClick}
        aria-describedby={`simple-popover`}
      >
        <MoreVert />
      </IconButton>
      <div>
        <ThemeProvider theme={theme}>
          <Popover
            open={popOverIsOpen}
            id={`${reference}`}
            anchorEl={anchorEl}
            onClick={handlePopOverClose}
            anchorOrigin={{
              vertical: "center",
              horizontal: "left",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
          >
            <Typography className="phalcon-order-action-cell">
              {orderListOfActionButton &&
                orderListOfActionButton.map((el, index) => {
                  return (
                    <Button
                      color="primary"
                      className="order-btn"
                      key={index}
                      onClick={() => {
                        setIsDetailsModalOpen(true);
                        setContext(el.context);
                        selectedRowId && handleDispatchServiceByContext(el.context, selectedRowId);
                      }}
                    >
                      <span className="order-btn-label">{el.label}</span>
                      <RemoveRedEye fontSize="small" />
                    </Button>
                  );
                })}
            </Typography>
          </Popover>
        </ThemeProvider>
      </div>
    </div>
  );
}
