import { createSlice } from "@reduxjs/toolkit";
import { axiosPrivate } from "../../utils/interceptors";
import axios from "axios";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { API_DEV } from "../../utils/apiRoute";
import { Locality } from "../../types/Locality.model";
import { AxiosError, AxiosResponse } from "axios";

export interface LocalityState {
  requestState: "idle" | "loading";
  isError: boolean;
  error: any;
  governorates: Locality[];
  localitiesOptions: Locality[][];
}

const initialState: LocalityState = {
  requestState: "idle",
  isError: false,
  error: null,
  governorates: [],
  localitiesOptions: [],
};

// Get Localities options Thunk on parent change
export const getGovernorates = createAsyncThunk(
  "[Localities]__getGovernorates__",
  async (_, { rejectWithValue }) => {
    const response = await axiosPrivate
      .get(`${API_DEV}/Locality/level/1?subLevel=1`)
      .then((response: { data: any }) => {
        return response.data;
      })
      .catch((err: Error | AxiosError) => {
        if (axios.isAxiosError(err)) {
          return rejectWithValue(err.response as AxiosResponse);
        } else {
          throw err;
        }
      });
    return response;
  }
);

// Get Localities options Thunk on parent change
export const getLocalitiesOptions = createAsyncThunk<
  Locality,
  string,
  {
    rejectValue: AxiosResponse;
  }
>("[Localities]__getOptions__", async (localityId, { rejectWithValue }) => {
  const response = await axiosPrivate
    .get(`${API_DEV}/Locality/Sublocalities/${localityId}`)
    .then((response: { data: any }) => {
      return response.data;
    })
    .catch((err: Error | AxiosError) => {
      if (axios.isAxiosError(err)) {
        return rejectWithValue(err.response as AxiosResponse);
      } else {
        throw err;
      }
    });
  return response;
});

// Get default localities options Thunk on first load depending on preselected localities
export const getDefaultLocalitiesOptions = createAsyncThunk<
  Locality,
  string,
  {
    rejectValue: AxiosResponse;
  }
>(
  "[Localities]__getDefaultOptions__",
  async (localityId, { rejectWithValue }) => {
    const response = await axiosPrivate
      .get(`${API_DEV}/Locality/Sublocalities/${localityId}`)
      .then((response: { data: any }) => {
        return response.data;
      })
      .catch((err: Error | AxiosError) => {
        if (axios.isAxiosError(err)) {
          return rejectWithValue(err.response as AxiosResponse);
        } else {
          throw err;
        }
      });
    return response;
  }
);

export const resetLocsOpt = createAsyncThunk<any>("[Localities]__resetLocsOpt__", () => {
  return true;
});


export const localitiesSlice = createSlice({
  name: "[Localities]",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // Governorates extra reducers
    builder
      .addCase(getGovernorates.fulfilled, (state, { payload }) => {
        // Add governorates to the state
        state.governorates = payload;
      })
      .addCase(resetLocsOpt.fulfilled, (state) => {
        state.localitiesOptions = []
      })
      .addCase(getGovernorates.rejected, (state, action) => { })
      .addCase(
        getGovernorates.pending,
        (state) => { }
        // Localities options extra reducers
      )
      .addCase(getLocalitiesOptions.fulfilled, (state, { payload }) => {
        // Prepare the levels to be deleted depending on the updated locality level
        if (payload.subLocalities && payload.subLocalities.length > 0) {
          // Delete old options starting from the changed level and update the new options
          state.localitiesOptions.splice(
            payload.level - 1,
            state.localitiesOptions.length,
            payload.subLocalities
          );
        } else {
          // Only delete if there is no options to push
          state.localitiesOptions.splice(
            payload.level - 1,
            state.localitiesOptions.length
          );
        }
      })
      .addCase(getLocalitiesOptions.rejected, (state, action) => { })
      .addCase(
        getLocalitiesOptions.pending,
        (state) => { }
        // Localities default options extra reducers
      )
      .addCase(getDefaultLocalitiesOptions.fulfilled, (state, { payload }) => {
        // Prepare the levels to be deleted depending on the updated locality level

        if (payload.subLocalities && payload.subLocalities.length > 0) {
          // Delete old options starting from the changed level and update the new options
          state.localitiesOptions[payload.level - 1] = payload.subLocalities;
        }
      })
      .addCase(getDefaultLocalitiesOptions.rejected, (state, action) => { })
      .addCase(getDefaultLocalitiesOptions.pending, (state) => { });
  },
});

export default localitiesSlice.reducer;
