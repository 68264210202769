import { Grid } from "@material-ui/core";
import LocalHospitalIcon from "@material-ui/icons/LocalHospital";
import StorageIcon from "@material-ui/icons/Storage";
import EventIcon from "@material-ui/icons/Event";
import ErrorIcon from "@material-ui/icons/Error";
import InfoIcon from "@material-ui/icons/Info";
import {
  DashboardGlobalInsights,
  DashboardVisitsInsights,
} from "../../../types/Dashboard.model";
import { useState, useEffect } from "react";
import GlobalInsightsItem from "./GlobalInsightsItem";
import VisitsByPotential from "./VisitsByPotential";
import "./Dashboard.scss";

interface GlobalInsightsProps {
  globalInsights: DashboardGlobalInsights;
  visitsInsights: DashboardVisitsInsights;
}

export type GlobalInsightsData = {
  count: number;
  text: string;
  icon: any;
  color: string;
};

type VisitsInsightsData = {
  total: number;
  potentials: {
    key: number;
    label: string;
    value: number;
    color: string;
  }[];
};

const GlobalInsights = ({
  globalInsights,
  visitsInsights,
}: GlobalInsightsProps) => {
  const [globalInsightsItems, setGlobalInsightsItems] = useState<
    GlobalInsightsData[]
  >([]);
  const [visitsInsightsItems, setVisitsInsightsItems] =
    useState<VisitsInsightsData>({
      total: 0,
      potentials: [],
    });
  useEffect(() => {
    setGlobalInsightsItems([
      {
        count: globalInsights.dataBaseApprovalsCount,
        text: "Demandes de mise à jour de la base de donnée",
        icon: <StorageIcon fontSize="small" />,
        color: "#fca130",
      },
      {
        count: globalInsights.rpApprovalsCount,
        text: "Demandes de validation d'un évènement",
        icon: <EventIcon fontSize="small" />,
        color: "#61affe",
      },
      {
        count: globalInsights.requestsCount,
        text: "Demandes non traitées",
        icon: <InfoIcon fontSize="small" />,
        color: "#f93e3e",
      },
      {
        count: globalInsights.managerRequestsCount,
        text: "Demandes assignées non traitées",
        icon: <InfoIcon fontSize="small" />,
        color: "#f93e3e",
      },
      {
        count: globalInsights.objectionsCount,
        text: "Objections non traitées",
        icon: <ErrorIcon fontSize="small" />,
        color: "#f93e3e",
      },
      {
        count: globalInsights.doctorsConflicts,
        text: "Médecins en commun",
        icon: <LocalHospitalIcon fontSize="small" />,
        color: "#FF9357",
      },
    ]);
  }, [globalInsights]);

  useEffect(() => {
    const potentialColors = ["#007aff", "#00c7be", "#f93e3e", "#ff9500"];
    setVisitsInsightsItems({
      total: visitsInsights.totalPercentage,
      potentials: visitsInsights.visitsByPotential.map((e) => ({
        key: e.potential.potentialId,
        label: `Potentiel ${e.potential.potentialName}`,
        color: potentialColors[e.potential.potentialId - 1],
        value: e.percentage || 0,
      })),
    });
  }, [visitsInsights]);

  return (
    <Grid container  style={{margin: "0"}}>
      <Grid container  lg={6} xs={12}>
        {globalInsightsItems.map(
          (dataItem: GlobalInsightsData, index: number) => (
            <Grid key={index} item xs={12} md={6} lg={4}  className="globalInsightsData_Dashboard_Section" >
              <GlobalInsightsItem data={dataItem} />
            </Grid>
          )
        )}
      </Grid>
      <Grid item xs={12} lg={6}  className="vistiByPotential_Dashboard_Section" >
          <VisitsByPotential 
            total={visitsInsightsItems.total}
            potentials={visitsInsightsItems.potentials}
          />
        </Grid>
    </Grid>
  ); 
};

export default GlobalInsights;
