import React from "react";
import { ProfileInfoItemPlaceholder } from "../../../02-molecules/placeholders/Placeholder";
import { StandardIcon } from "../../../01-atoms/Icons/Icons";
type Props = {
  info: string;
  loading: boolean;
  icon?: string;
};

export const StandardInfo = ({ info, loading, icon }: Props) => {
  return (
    <>
      {!loading ? (
        <div className="email-item">
          {icon && <StandardIcon icon={icon} />}
          <dl className="content">
            <dt>{info || "_"}</dt>
          </dl>
        </div>
      ) : (
        <div className="info-item">
          <ProfileInfoItemPlaceholder withCircle /> 
        </div>
      )}
    </>
  );
};