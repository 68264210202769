import {
  mdiBellOutline,
  mdiMenu,
  mdiPencilOutline,
  mdiPlus,
  mdiTrashCanOutline,
  mdiUpdate,
  mdiAccountOutline,
  mdiAccountPlusOutline,
  mdiDotsVertical,
  mdiAutorenew,
  mdiContentCut,
  mdiContentCopy,
  mdiContentPaste,
  mdiCalendar,
  
} from "@mdi/js";
import { Icon } from "@mdi/react";
import { MailOutline } from "@material-ui/icons";
import { IconButton, Menu, MenuItem } from "@material-ui/core";
import React, { createRef, ReactElement, useState } from "react";
import { Link } from "react-router-dom";
import ArrowRightLineIcon from "@rsuite/icons/ArrowRightLine";
import ArrowLeftLineIcon from "@rsuite/icons/ArrowLeftLine";
import ArrowDownLineIcon from "@rsuite/icons/ArrowDownLine";
import ArrowUpLineIcon from "@rsuite/icons/ArrowUpLine";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import { logout } from "../../../redux/Authentication/authenticationSlice";
import "./Buttons.scss";
import { RootState } from "../../../redux/store";
import { Whisper, Popover, Divider, ButtonGroup, Loader } from "rsuite";
import { toggleTargetModal } from "../../../redux/Target/targetSlice";
import { togglePlanificationModal } from "../../../redux/Planification/planificationSlice";
import { CustomAvatar } from "../CustomAvatar/CustomAvatar";
interface ProfileDropdownProps {
  photoUrl: string;
  fullName: string;
}

export const ProfileDropdown = ({
  photoUrl,
  fullName,
}: ProfileDropdownProps) => {
  const { refreshToken } = useAppSelector((state: RootState) => state.auth);
  const handleLogout = () => {
    dispatch(logout(refreshToken));
  };
  const dispatch = useAppDispatch();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <>
      <IconButton
        aria-label="more"
        id="long-button"
        aria-controls={open ? "long-menu" : undefined}
        aria-expanded={open ? "true" : undefined}
        aria-haspopup="true"
        onClick={handleClick}
        size="small"
      >
        <CustomAvatar
          image={{
            src: `${photoUrl}`,
            alt: `${fullName}'s avatar`,
          }}
          style={{ width: 30, height: 30 }}
        />
      </IconButton>
      <Menu
        id="long-menu"
        MenuListProps={{
          "aria-labelledby": "long-button",
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            width: "14ch",
            marginTop: "2.2ch",
          },
        }}
      >
        <Link
          to="/account"
          style={{
            textDecoration: "none",
          }}
        >
          {" "}
          <MenuItem
            style={{
              textDecoration: "none",
            }}
          >
            Mon Compte
          </MenuItem>{" "}
        </Link>
        <MenuItem onClick={() => handleLogout()}>Se déconnecter</MenuItem>
      </Menu>
    </>
  );
};
interface BurgerButtonProps {
  onClick: () => void;
}
export const BurgerButton = ({ onClick }: BurgerButtonProps) => {
  return (
    <div className="burger-button" onClick={onClick}>
      <Icon path={mdiMenu} size={1} />
    </div>
  );
};

interface NotificationsButtonProps {
  onClick: () => void;
}
export const NotificationsButton = ({ onClick }: NotificationsButtonProps) => {
  return (
    <div className="notifications-button" onClick={onClick}>
      <Icon path={mdiBellOutline} size={1} />
    </div>
  );
};
interface ProfileButtonProps {
  to: string;
}
export const ProfileButton = ({ to }: ProfileButtonProps) => {
  return (
    <Link className="profile-button" to={to}>
      <Icon path={mdiAccountOutline} size={1} />
    </Link>
  );
};
interface CyclesButtonProps {
  number: number;
}
export const CyclesButton = ({ number }: CyclesButtonProps) => {
  return (
    <div className="cycles-button">
      <span>{number}</span>

      <Icon path={mdiUpdate} size={0.8} />
    </div>
  );
};
interface InboxButtonProps {}
export const InboxButton = (props: InboxButtonProps) => {
  return (
    <Link to="/inbox">
      <IconButton size="small">
        <MailOutline />
      </IconButton>
    </Link>
  );
};
interface AssociateButtonProps {
  onClick?: any;
  disabled?: boolean;
}
export const AssociateButton = ({
  onClick,
  disabled,
}: AssociateButtonProps) => {
  return (
    <div
      className={`associate-button ${disabled ? "isDisabled" : ""}`}
      onClick={disabled ? undefined : onClick}
    >
      <Icon path={mdiAccountPlusOutline} size={1} />
    </div>
  );
};
interface CustomIconButtonProps {
  onClick?: any;
  icon: string;
  disabled?: boolean;
}
export const CustomIconButton = ({
  onClick,
  icon,
  disabled,
}: CustomIconButtonProps) => {
  return (
    <div
      className={`custom-icon-button ${disabled ? "isDisabled" : ""}`}
      onClick={disabled ? undefined : onClick}
    >
      <Icon path={icon} size={1} />
    </div>
  );
};

interface WeekButtonProps {
  handleCopy?: () => void;
  handleCut?: () => void;
  handlePaste?: () => void;
  handleReset?: () => void;
  onClick?: any;
}
export const WeekButton = ({
  onClick,
  handleCopy,
  handleCut,
  handlePaste,
  handleReset,
}: WeekButtonProps) => {
  const dispatch = useAppDispatch();
  const [open, setOpen] = useState<boolean>(false);
  const renderWeekButton = (
    { onClose, left, top, className }: any,
    ref: any
  ) => {
    return (
      <Popover
        ref={ref}
        className={className}
        style={{ top, left }}
        onBlurCapture={() => setOpen(false)}
        onMouseLeave={()=> {setOpen(false)}}
      >
        <ButtonGroup className="week-button__inner">
          <CustomIconButton
            icon={mdiAutorenew}
            onClick={() => {
              setOpen(false);
              handleReset && handleReset();
            }}
          />
          <Divider vertical />
          <CustomIconButton
            icon={mdiContentCut}
            onClick={() => {
              setOpen(false);
              handleCut && handleCut();
            }}
          />
          <CustomIconButton
            icon={mdiContentCopy}
            onClick={() => {
              setOpen(false);
              handleCopy && handleCopy();
            }}
          />
          <CustomIconButton
            icon={mdiContentPaste}
            onClick={() => {
              setOpen(false);
              handlePaste && handlePaste();
            }}
          />
          <CustomIconButton
            icon={mdiPlus}
            onClick={() => {
              setOpen(false);
              dispatch(toggleTargetModal());
            }}
          />
        </ButtonGroup>
      </Popover>
    );
  };
  const weekButtomRef: any = createRef();
  return (
    <div
      className="week-button"
      ref={weekButtomRef}
      onBlur={() => setOpen(false)}
    >
      <Whisper
        trigger="click"
        placement={"bottomEnd"}
        speaker={renderWeekButton}
        container={() => weekButtomRef.current}
        open={open}
        onClose={() => setOpen(false)}
        onBlur={() => setOpen(false)}
        onExit={() => setOpen(false)}
        onClick={() => setOpen(true)}
      >
        <Icon path={mdiDotsVertical} size={1} />
      </Whisper>
    </div>
  );
};
export const PlanificationWeekButton = ({
  onClick,
  handleCopy,
  handleCut,
  handlePaste,
  handleReset,
}: WeekButtonProps) => {
  const dispatch = useAppDispatch();
  const [open, setOpen] = useState<boolean>(false);
  const renderWeekButton = (
    { onClose, left, top, className }: any,
    ref: any
  ) => {
    return (
      <Popover ref={ref} className={className} full style={{ top, left }} >
        <ButtonGroup className="week-button__inner">
          <CustomIconButton 
            icon={mdiAutorenew}
            onClick={() => {
              setOpen(false); 
              handleReset && handleReset();
            }}
          />
          <Divider vertical />
          <CustomIconButton
            icon={mdiContentCut}
            onClick={() => {
              setOpen(false);
              handleCut && handleCut();
            }}
          />
          <CustomIconButton
            icon={mdiContentCopy}
            onClick={() => {
              setOpen(false);
              handleCopy && handleCopy();
            }}
          />
          <CustomIconButton
            icon={mdiContentPaste}
            onClick={() => {
              setOpen(false);
              handlePaste && handlePaste();
            }}
          />
          <CustomIconButton
            icon={mdiPlus}
            onClick={() => {
              setOpen(false);
              dispatch(togglePlanificationModal());
            }}
          />
        </ButtonGroup>
      </Popover>
    );
  };
  const weekButtomRef: any = createRef();
  return (
    <div
      className="week-button"
      ref={weekButtomRef}
      onBlur={() => setOpen(false)}
    >
      <Whisper
        trigger="click"
        placement={"bottomEnd"}
        speaker={renderWeekButton}
        container={() => weekButtomRef.current}
        open={open}
        onBlur={() => setOpen(false)}
        onClick={() => setOpen(true)}
      >
        <Icon path={mdiDotsVertical} size={1} />
      </Whisper>
    </div>
  );
};

interface PlanificationButtonProps {
  onClick?: any;
  text?: string;
}
export const PlanificationButton = ({
  onClick,
  text,
}: PlanificationButtonProps) => {
  return (
    <div className="planification-button" onClick={onClick}>
      <span>{text}</span>
      <Icon path={mdiCalendar} size={0.75} />
    </div>
  );
};

interface CustomButtonProps {
  text?: string;
  icon?: ReactElement;
  color?: "dark" | "light" | "customized";
  style?: any;
  className?: string;
  loading?: boolean;
  onClick?: () => void;
  textClassName?: string;
  handelClickMouseEvent?: (event: React.MouseEvent<HTMLElement>) => void
}
export const CustomButton = ({
  text,
  textClassName,
  icon,
  onClick,
  style,
  color,
  className,
  loading,
  handelClickMouseEvent,
}: CustomButtonProps) => {
  return (
    <div
      style={style}
      className={
        color
          ? `custom-button ${className ? className : ""} ${color}`
          : `custom-button ${className ? className : ""}`
      }
      onClick={handelClickMouseEvent ? handelClickMouseEvent : onClick}
    > 
      {loading ? (
        <Loader />
      ) : (
        <>
          {text  &&<span className={textClassName}>{text}</span>}
          {icon && icon}
        </> 
      )}
    </div>
  );
};
interface ArrowButtonProps {
  onClick?: () => void;
  to?: string;
  icon: "right" | "left" | "up" | "down";
}
export const ArrowButton = ({
  icon = "right",
  to,
  onClick,
  ...props
}: ArrowButtonProps) => {
  const iconButton = (
    <div className="arrow-button" onClick={onClick}>
      {icon === "down" ? (
        <ArrowDownLineIcon />
      ) : icon === "up" ? (
        <ArrowUpLineIcon />
      ) : icon === "left" ? (
        <ArrowLeftLineIcon />
      ) : (
        <ArrowRightLineIcon />
      )}
    </div>
  );
  return <>{to ? <Link to={to}>{iconButton}</Link> : iconButton}</>;
};

interface genericButtonProps {
  styled?: string;
  onClick?: () => void;
  text?: string;
  path: string;
}
export const GenericButton = ({
  onClick,
  styled,
  text,
  path,
}: genericButtonProps) => {
  return (
    <div className={styled} onClick={onClick}>
      {text ? text : null}
      {path ? <Icon path={path} size={1} /> : null}
    </div>
  );
};

interface AddButtonProps {
  onClick?: () => void;
}
export const AddButton = ({ onClick }: AddButtonProps) => {
  return (
    <div className="add-button" onClick={onClick}>
      <Icon path={mdiPlus} size={0.75} />
    </div>
  );
};

interface EditButtonProps {
  onClick?: () => void;
}
export const EditButton = ({ onClick }: EditButtonProps) => (
  <div className="edit-button" onClick={onClick}>
    <Icon path={mdiPencilOutline} size={0.75} />
  </div>
);
interface DeleteButtonProps {
  onClick?: () => void;
  disabled?: boolean;
}
export const DeleteButton = ({ onClick, disabled }: DeleteButtonProps) => (
  <div
    className={`delete-button ${disabled ? "isDisabled" : ""}`}
    onClick={disabled ? undefined : onClick}
  >
    <Icon path={mdiTrashCanOutline} size={0.75} />
  </div>
);
