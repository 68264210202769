import { useAppDispatch } from "./../../../../redux/hooks";
import moment from "moment";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { fetchFilteredRequest } from "../../../../redux/Request/requestSlice";
import { RequestsListContext } from "../../../04-templates/profile/requests-list/RequestsList";

interface UseFilterRequestProps {
  context: RequestsListContext;
}
interface RequestOrderBy {
  [isClosed: string]: number;
  isClosed_desc: number;
  isSatisfied: number;
  isSatisfied_desc: number;
  openedOn: number;
  openedOn_desc: number;
  closedOn: number;
  closedOn_desc: number;
  priority: number;
  priority_desc: number;
}
type RequestFilterData = {
  priority: string[];
  isClosed: boolean | null;
  date: [Date, Date] | undefined;
};
export type GlobalRequestFilterState = {
  search: {
    searchText: string;
    isClosed: boolean | null;
    startDate: string | Date | null;
    endDate: string | Date | null;
    priorities: number[] | { priorityId: number }[];
    userId: number | null;
    doctorId: number | null;
    sellerId: number | null;
    pharmacyId: number | null;
  };
  pagination: {
    pageNumber: number;
    pageSize: number;
  };
  orderBy: number;
};
export const useFilterRequest = ({ context }: UseFilterRequestProps) => {
  const dispatch = useAppDispatch();
  const params = useParams();

  const [globalFilterState, setGlobalFilterState] =
    useState<GlobalRequestFilterState>({
      search: {
        searchText: "",
        isClosed: null,
        startDate: null,
        endDate: null,
        priorities: [],
        userId: params.id && context === "user" ? parseInt(params.id) : null,
        doctorId:
          params.id && context === "doctor" ? parseInt(params.id) : null,
        sellerId:
          params.id && context === "seller" ? parseInt(params.id) : null,
        pharmacyId:
          params.id && context === "pharmacy" ? parseInt(params.id) : null,
      },
      pagination: {
        pageNumber: 1,
        pageSize: 10,
      },
      orderBy: 0,
    });

  const captureSearchTextValue = (searchTerm: string) => {
    setGlobalFilterState({
      search: {
        ...globalFilterState.search,
        searchText: searchTerm,
      },
      pagination: {
        ...globalFilterState.pagination,
      },
      orderBy: globalFilterState.orderBy,
    });
  };

  const captureFilter = (data: RequestFilterData) => {
    const { date, priority, isClosed } = data;
    setGlobalFilterState({
      search: {
        ...globalFilterState.search,
        priorities: priority.map((item) => ({ priorityId: parseInt(item) })),
        isClosed,
        startDate: date ? moment(date[0]).utc().toDate() : null,
        endDate: date ? moment(date[1]).utc().toDate() : null,
      },
      pagination: {
        ...globalFilterState.pagination,
      },
      orderBy: globalFilterState.orderBy,
    });
  };

  const capturePageTarget = (page: number) => {
    setGlobalFilterState({
      search: {
        ...globalFilterState.search,
      },
      pagination: {
        ...globalFilterState.pagination,
        pageNumber: page + 1,
      },
      orderBy: globalFilterState.orderBy,
    });
  };

  const captureLimitTarget = (limit: number) => {
    setGlobalFilterState({
      search: {
        ...globalFilterState.search,
      },
      pagination: {
        ...globalFilterState.pagination,
        pageNumber: 1,
        pageSize: limit,
      },
      orderBy: globalFilterState.orderBy,
    });
  };

  const captureOrderBy = (sortColumn: string, sortType: string) => {
    let orderByValue = `${
      sortType === "desc" ? sortColumn + "_" + sortType : sortColumn
    }`;

    const requestOrderBy: RequestOrderBy = {
      isClosed: 0,
      isClosed_desc: 1,
      isSatisfied: 2,
      isSatisfied_desc: 3,
      openedOn: 4,
      openedOn_desc: 5,
      closedOn: 6,
      closedOn_desc: 7,
      priority: 8,
      priority_desc: 9,
    };
    setGlobalFilterState({
      search: {
        ...globalFilterState.search,
      },
      pagination: {
        ...globalFilterState.pagination,
      },
      orderBy: requestOrderBy[orderByValue],
    });
  };

  useEffect(() => {
    dispatch(fetchFilteredRequest(globalFilterState));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [globalFilterState]);

  return {
    globalFilterState,
    captureSearchTextValue,
    captureOrderBy,
    captureFilter,
    capturePageTarget,
    captureLimitTarget,
  };
};
