import React from "react";
import RpForm from "../../03-organisms/forms/rp/RpForm";
import { Header } from "../../05-layouts/Header/Header";
import "./RpPage.scss";
type Props = {};

const RpAddPage = (props: Props) => {
  return (
    <>
      <Header
        leftItem={
          <div className="cycle-management__header__title">Ajouter un RP</div>
        }
        subRoutes={[]}
      />
      <div className="rp-page__inner">
        <RpForm context="add" />
      </div>
    </>
  );
};
export default RpAddPage;
