import { useAppSelector  } from "./../../../../redux/hooks";
import {  useMemo } from "react";
import { ObjectionDataSource } from "../../../../types/ObjectionDataSource.model";
import { RootState } from "../../../../redux/store";


const prepareObjectionsByCriteria = (
  data: ObjectionDataSource,
  criteria: string
) => {
  let labels : string []=  ["Très insatisfait", "Plutôt insatisfait", "Mi satisfait","Plutôt satisfait", "Très satisfait" ];
  let series = data.treatedObjectionsBySatisfactionLevel.map(
    ({count}) => count
  );
  return {
    labels : labels, 
    series : series,
  }
};

interface ObjectionOptions {
  data: ObjectionDataSource;
  criteria: {
    field: string;
    displayField: string;
  };
}

const prepareObjectionsStatusByCriteria = (options: ObjectionOptions) => {
  const { data } = options;
 

  return {
    xAxis: data.objectionsByDelegate.map(({delegate})=> `${delegate.firstName} ${delegate.lastName}`),
    series: [
      {
        name: "Traitées",
        data: data.objectionsByDelegate.map(({treated})=> treated ),
      },
      { 
        name: "Non traitées",
        data: data.objectionsByDelegate.map(({unTreated})=> unTreated ),
      },
    ], 
  };
};
// const prepareObjectionsPotentialsByCriteria = (options: ObjectionOptions) => {
//   const { data, criteria } = options;
//   const groupedBy = _.chain(data)
//     .groupBy(criteria.field)
//     .mapValues((values) =>
//       _.chain(values).groupBy("potentiel").mapValues(_.size).value()
//     )
//     .value();
//   return {
//     xAxis: _.map(_.uniqBy(data, criteria.field), criteria.displayField),
//     series: [
//       {
//         name: "A",
//         data: _.values(_.map(groupedBy, "A")),
//       },
//       {
//         name: "B",
//         data: _.values(_.map(groupedBy, "B")),
//       },
//       {
//         name: "C",
//         data: _.values(_.map(groupedBy, "C")),
//       },
//     ],
//   };
// };

const prepareObjectionsCount = (data: ObjectionDataSource) => {
  return {
    total: data.totalCount,
    closedCount: data.treatedCount,
    notClosed: {
      total: data.unTreatedObjections.total,
      potential: data.unTreatedObjections.unTreatedObjectionsByPotential,
    },
  };
};

const prepareObjectionsStatusByProduct = (options: ObjectionOptions) => {
  const { data } = options;
 

  return {
    xAxis: data.objectionsByProduct.map(({product})=> product.productName),
    series: [
      {
        name: "Traitées",
        data: data.objectionsByProduct.map(({treated})=> treated ),
      },
      { 
        name: "Non traitées",
        data: data.objectionsByProduct.map(({unTreated})=> unTreated ),
      },
    ],
  };
};

interface props {}

const useBiObjections = (props: props) => {
  const { biObjectionData } = useAppSelector((state: RootState) => state.bi);
  const ObjectionsCount = useMemo(
    () => prepareObjectionsCount(biObjectionData),
    [biObjectionData]
  );

  const objectionsBySatisfaction = useMemo(
    () => prepareObjectionsByCriteria(biObjectionData, "satisfactionLevel"),
    [biObjectionData]
  );
  const objectionsStatusByUser = useMemo(
    () =>
      prepareObjectionsStatusByCriteria({
        data: biObjectionData,
        criteria: {
          field: "duserid",
          displayField: "dfirstname",
        },
      }),
    [biObjectionData]
  );
  const objectionsStatusByProduct = useMemo(
    () =>
    prepareObjectionsStatusByProduct({
        data: biObjectionData,
        criteria: {
          field: "productId",
          displayField: "name",
        },
      }),
    [biObjectionData]
  );
  // const objectionsPotentialByValue = useMemo(
  //   () =>
  //     prepareObjectionsPotentialsByCriteria({
  //       data: biObjectionData || [],
  //       criteria: {
  //         field: "value", 
  //         displayField: "value",
  //       },
  //     }),
  //   [biObjectionData]
  // );
  return {
    ObjectionsCount,
    objectionsBySatisfaction,
    objectionsStatusByUser,
    objectionsStatusByProduct,
    // objectionsPotentialByValue,
  };
};

export default useBiObjections;

// xAxis: any[];
//     series: {
//         name: string;
//         data: number[];
//     }[];
