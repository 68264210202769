import { TablePagination } from "@material-ui/core";
import React from "react";

type Props = {
  capturePageTarget: (page: number) => void;
  captureLimitTarget: (limit: number) => void;
  pageCount: number;
};

export default function Pagination({
  capturePageTarget,
  captureLimitTarget,
  pageCount,
}: Props) {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPage(newPage);
    capturePageTarget(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
    captureLimitTarget(parseInt(event.target.value));
  };
  return (
    <TablePagination
      component="div"
      count={pageCount}
      page={page}
      onPageChange={handleChangePage}
      rowsPerPage={rowsPerPage}
      onRowsPerPageChange={handleChangeRowsPerPage}
    />
  );
}
