import { mdiBadgeAccountHorizontal } from "@mdi/js";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Divider } from "rsuite";
import { Locality } from "../../../../types/Locality.model";
import { Phone } from "../../../../types/Phone.model";
import User from "../../../../types/User.model";
import { getLocalDate } from "../../../../utils/dateHelpers";
import { StandardIcon } from "../../../01-atoms/Icons/Icons";
import { ProfileInfoItemPlaceholder } from "../../../02-molecules/placeholders/Placeholder";
import { ProfileHeader } from "../../../03-organisms/user-account/profile-header/ProfileHeader";
import { InfoItemEmail } from "./InfoItemEmail";
import { InfoItemExtra } from "./InfoItemExtra";
import { InfoItemLocality } from "./InfoItemLocality";
import { InfoItemPhone } from "./InfoItemPhone";
import "./ProfileInfos.scss";

type Props = {
  user: User | null;
};
type ProfileData = {
  localities: Locality[];
  streetName: string;
  noteAdress: string;
  phones: Phone[];
  email: string;
  extraInfos: { label: string; content: string }[];
};
export const UserProfileInfo = ({ user }: Props) => {
  const { t } = useTranslation();
  const [profileData, setprofileData] = useState<ProfileData>({
    localities: [],
    streetName: "",
    noteAdress: "",
    phones: [],
    email: "",
    extraInfos: [],
  });
  const prepareData = () => ({
    localities: user ? user.address.localities : [],
    streetName: user ? user.address.streetName : "",
    noteAdress: user ? user.address.addressNote : "",
    phones: user ? user.phones : [],
    email: user ? user.email : "",
    extraInfos: [
      {
        label: t("account_page.user_infos.registration_number"),
        content: user ? user.registrantionNumber : "",
      },
      {
        label: t("account_page.user_infos.hire_date"),
        content: user ? getLocalDate(user.hireDate) : "",
      },
      {
        label: t("account_page.user_infos.birth_date"),
        content: user ? getLocalDate(user.birthDate) : "",
      },
      {
        label: t("account_page.user_infos.gender"),
        content: user ? (user.gender ? "femme" : "homme") : "",
      },
    ], 
  });
  useEffect(() => {
    const data = prepareData();
    setprofileData(data);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  return (
    <div className="profile-info__wrapper">
      <div className="profile-infos">
        <div className="profile_avatar_rwd">
          <ProfileHeader context={"user"} person={user} />
          <Divider />
        </div>
 
        {/* Locality Item */}
        {user ? (
          <>
            {profileData.localities && profileData.localities.length > 0 && (
              <InfoItemLocality
                loading={false}
                localities={profileData.localities}
                streetName={profileData.streetName}
                noteAdress={profileData.noteAdress}
              />
            )}
          </>
        ) : (
          <ProfileInfoItemPlaceholder withCircle rows={2} />
        )}
        {/* Phone Item */}
        {user ? (
          <InfoItemPhone phones={profileData.phones} loading={false} />
        ) : (
          <ProfileInfoItemPlaceholder withCircle rows={4} />
        )}
        {/* Email Item */}

        {user ? (
          <InfoItemEmail email={profileData.email} loading={false} />
        ) : (
          <ProfileInfoItemPlaceholder withCircle rows={2} />
        )}

        <Divider hidden />
        <Divider />
        <Divider hidden />
        <div className="extra">
          {user ? (
            <div className="extra__heading">
              <StandardIcon icon={mdiBadgeAccountHorizontal} />
              <dl className="content">
                <dt>{t("account_page.user_infos.additional_infos")}</dt>
              </dl>
            </div>
          ) : (
            <ProfileInfoItemPlaceholder withCircle rows={1} />
          )}

          {user ? (
            <>
              {profileData.extraInfos &&
                profileData.extraInfos.length > 0 &&
                profileData.extraInfos.map((item, index) => (
                  <InfoItemExtra
                    key={index}
                    loading={false}
                    label={item.label}
                    content={item.content}
                  />
                ))}
            </>
          ) : (
            <ProfileInfoItemPlaceholder
              rows={2}
              style={{ marginLeft: "3.5rem", marginTop: "2rem" }}
            />
          )}
        </div>
      </div>
    </div>
  );
};
