import React , { useEffect }from "react";
import { useTranslation } from "react-i18next";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { useRoutingConfig } from "../../../utils/useRoutingConfig";
import { Header } from "../../05-layouts/Header/Header";

type Props = {};

const ReportsPage = (props: Props) => {
  const { t } = useTranslation();
  const headerLeftItem = <h2>{t("reports_page.title")}</h2>;
  const currentRoute = useRoutingConfig().find((e) => e.key === "5");
  const subRoutes =
    typeof currentRoute !== "undefined" ? currentRoute.children : [];
  const { pathname } = useLocation();
  const navigate = useNavigate(); 
  useEffect(() => {
    pathname === "/reports" &&
      navigate("/reports/report");
  }, [navigate, pathname]);
  return ( 
    <div>
      <Header leftItem={headerLeftItem} subRoutes={subRoutes} />
      <Outlet />
    </div>
  );
};

export default ReportsPage;
