import {
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Avatar,
  Tooltip,
  Chip,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  Typography,
  Box,
  makeStyles,
  Theme,
  createStyles,
  TablePagination,
} from "@material-ui/core";
import VisibilityIcon from "@material-ui/icons/Visibility";
import { useEffect, useState } from "react";
import RequestSummary from "../../../types/RequestSummary.model";
import { getLocalDate } from "../../../utils/dateHelpers";

interface UntreatedRequestsTableProps {
  untreatedRequests: RequestSummary[];
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    tableTitle: {
      padding: theme.spacing(2),
    },
    spacing: {
      marginTop: theme.spacing(2),

    },
  })
);

const UntreatedRequestsTable = ({
  untreatedRequests,
}: UntreatedRequestsTableProps) => {
  const priorityColors = ["#f93e3e", "#fca130", "#49cc90"];
  const [rowStatus, setRowStatus] = useState<{rowId: number | null, isOpen: boolean}>({rowId: null, isOpen: true})
  const handleToggle = (id: number) =>{
    rowStatus.rowId !== id && setRowStatus({rowId: id, isOpen: true})
   };
  const classes = useStyles();
  const [dataToShow, setDataToShow] = useState<RequestSummary[]>([]);
  const [page, setPage] = useState(0);
  const rowsPerPage= 10; 

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPage(newPage);
  };

  useEffect(() => {
    const startIndex = rowsPerPage * page;
    const endIndex = startIndex + rowsPerPage;
    const data = untreatedRequests.filter(
      (_, index) => index >= startIndex && index <= endIndex
    );
    setDataToShow(data);
  }, [untreatedRequests, page, rowsPerPage]);

  return (
    <TableContainer component={Paper} className={classes.spacing}>
      <Box className={classes.tableTitle}>
        <Typography variant="subtitle2">Demandes non traitées</Typography>
      </Box>
      <Table aria-label="simple table" size="small">
        <TableHead>
          <TableRow>
            <TableCell align="left" width={40}>
              Délégué
            </TableCell>
            <TableCell align="left">Médecin</TableCell>
            <TableCell align="left">Priorité</TableCell>
            <TableCell align="left">Type de demande</TableCell>
            <TableCell align="left">Date de la commande</TableCell>
            <TableCell align="left">Assignée à</TableCell>
            <TableCell align="center">Aperçu</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {dataToShow &&
            dataToShow.length > 0 &&
            dataToShow.map((item: RequestSummary) => (
              <TableRow key={item.requestId}>
                <TableCell component="th" scope="row" align="left">
                  <Tooltip
                    placement="right"
                    title={
                      item.createdBy.firstName + " " + item.createdBy.lastName
                    }
                    aria-label={
                      item.createdBy.firstName + " " + item.createdBy.lastName
                    }
                  >
                    <Avatar
                      alt={
                        item.createdBy.firstName + " " + item.createdBy.lastName
                      }
                      src={item.createdBy.photoUrl}
                    />
                  </Tooltip>
                </TableCell>
                <TableCell align="left">{item.client.name}</TableCell>
                <TableCell align="left">
                  <Chip
                    size="small"
                    label={item.priority.priorityName}
                    style={{
                      backgroundColor: priorityColors[item.priority.level - 1],
                      color: "white",
                    }}
                  />
                </TableCell>
                <TableCell align="left">
                  {item.requestValue.requestValueType.requestValueTypeName}
                </TableCell>
                <TableCell align="left">
                  {getLocalDate(item.createdOn)}
                </TableCell>
                <TableCell align="center">
                  <Tooltip
                    placement="right"
                    title={
                      item.assignedTo.firstName + " " + item.assignedTo.lastName
                    }
                    aria-label={
                      item.assignedTo.firstName + " " + item.assignedTo.lastName
                    }
                  >
                    <Avatar
                      alt={
                        item.assignedTo.firstName +
                        " " +
                        item.assignedTo.lastName
                      }
                      src={item.assignedTo.photoUrl}
                    />
                  </Tooltip>
                </TableCell>

                <TableCell align="center">
                  <IconButton onClick={()=> handleToggle(item?.requestId)}>
                    <VisibilityIcon fontSize="small" />
                  </IconButton>
                  <RequestModal
                    rowStatus = {rowStatus}
                    requestId = {item?.requestId}
                    setRowStatus= {setRowStatus}
                    requestValue={item?.requestValue?.description || "-"}
                  />
                </TableCell>
              </TableRow>
            ))}
        </TableBody>
      </Table>
      <TablePagination
        component="div"
        rowsPerPageOptions={[10]}
        count={untreatedRequests.length} 
        page={page} 
        onPageChange={handleChangePage}
        rowsPerPage = {rowsPerPage}
         
      /> 
    </TableContainer>
  );
};

export default UntreatedRequestsTable;

const RequestModal = ({
  requestValue,
  rowStatus,
  setRowStatus,
  requestId
}: {
  requestValue: string;
  rowStatus: {
    rowId: number | null;
    isOpen: boolean;
},
  setRowStatus: any,
  requestId: number
}) => {
  return (
    <>
      {rowStatus.rowId && (rowStatus.rowId === requestId) && rowStatus.isOpen ? <Dialog
        open={true}
        onClose={()=> { setRowStatus({rowId: null, isOpen: false})}}
        maxWidth="lg"
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Description"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {requestValue}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={()=> { setRowStatus({rowId: null, isOpen: false})}} color="primary" autoFocus>
            Fermer
          </Button>
        </DialogActions>
      </Dialog> : ""}
    </>
  );
};
 