import React from "react";
import { Modal } from "rsuite";

type Props = {
  isOpen: boolean;
  onClose: () => void;
};

const DoctorCheckModal = ({ isOpen, onClose }: Props) => {
  return (
    <Modal open={isOpen} onExit={onClose}>
      <Modal.Header>Check doctor</Modal.Header>
      <Modal.Body>test</Modal.Body>
      <Modal.Footer></Modal.Footer>
    </Modal>
  );
};

export default DoctorCheckModal;
