import { Box, CircularProgress, IconButton, Modal } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import InfoWithIconTag from "../../../01-atoms/InfoWithIconTag/InfoWithIconTag";
import "./Details.scss";
import {
  mdiMedicalBag,
  mdiCalendarHeart,
  mdiCalendar,
  mdiCalendarRemove,
  mdiDolly,
  mdiHandCoinOutline,
  mdiPackageVariantClosed,
  mdiReceipt,
  mdiBrightnessPercent,
  mdiLock,
  mdiMortarPestlePlus,
} from "@mdi/js";
import { CloseOutlined } from "@material-ui/icons";
import OrderDetailsList from "./OrderDetails";
import PaymentDetails from "./PaymentDetails";
import DeliveryDetails from "./DeliveryDetails";
import { getLocalDate } from "../../../../utils/dateHelpers";
import { orderStatus } from "../../../03-organisms/filter/orderFilters/OrderFilters";
import { Order } from "../../../../types/Order.model";
import { Payment } from "../../../../types/Payment.model";
import { Delivery } from "../../../../types/Delivery.modal";
type Props = {
  isModalOpen: boolean;
  setIsModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  context: "paymentDetails" | "orderDetails" | "deliveryDetails" | null;
  requestState: "loading" | "idle";
  orderDetails: Order | null;
  paymentDetails: Payment | null;
  deliveryDetails: Delivery[] | null;
  clientInfo: string;
};

type StandardElementDtails = {
  [icon: string]: any;
  info: string;
  label: string | null;
  key: string;
}[];

type StandaeElements = {
  orderDetails: StandardElementDtails;
  paymentDetails: StandardElementDtails;
  deliveryDetails: StandardElementDtails;
};

const standardElements: StandaeElements = {
  orderDetails: [
    {
      icon: mdiCalendar,
      label: null,
      info: "Date Création Commande",
      key: "dateCreation",
    },
    {
      icon: mdiCalendarHeart,
      label: null,
      info: "Date de livraison souhaitée",
      key: "dateDeadLineDelivery",
    },
    {
      icon: mdiPackageVariantClosed,
      label: null,
      info: "Stock",
      key: "depot",
    },
    {
      icon: mdiCalendarRemove,
      label: null,
      info: "Date d'échéhance",
      key: "dateDeadLine",
    },
    {
      icon: mdiHandCoinOutline,
      label: null,
      info: "Total HT",
      key: "priceHT",
    },
    {
      icon: mdiHandCoinOutline,
      label: null,
      info: "Total TTC",
      key: "priceTTC",
    },
    {
      icon: mdiHandCoinOutline,
      label: null,
      info: "Remise",
      key: "discount",
    },
    {
      icon: mdiBrightnessPercent,
      label: null,
      info: "Après remise / TTC",
      key: "afterDiscount",
    },
    {
      icon: mdiHandCoinOutline,
      label: null,
      info: "Reste",
      key: "remainingAmount",
    },
  ],
  paymentDetails: [
    {
      icon: mdiCalendarRemove,
      label: null,
      info: "Date d'échéhance",
      key: "dateDeadLine",
    },
    {
      icon: mdiReceipt,
      info: "Numéro facture",
      label: null,
      key: "facNum",
    },
    {
      icon: mdiDolly,
      info: "Statut livraison",
      label: null,
      key: "deliveryStatus",
    },
    {
      icon: mdiHandCoinOutline,
      info: "Statut paiement",
      label: null,
      key: "paymentStatus",
    },
    {
      icon: mdiLock,
      info: "",
      label: "Paiment Clôturé",
      key: "closedPayment",
    },

    {
      icon: mdiHandCoinOutline,
      info: "Total",
      label: "1 200 DT",
      key: "total",
    },
    {
      icon: mdiHandCoinOutline,
      info: "Montant payé",
      label: "200 Dt",
      key: "payedAmount",
    },
    {
      icon: mdiHandCoinOutline,
      info: "Reste à payer",
      label: "1 000 DT",
      key: "remainingAmount",
    },
  ],
  deliveryDetails: [
    {
      icon: mdiCalendarRemove,
      label: null,
      info: "Date d'échéhance",
      key: "dateDeadLine",
    },
    {
      icon: mdiReceipt,
      info: "Numéro facture",
      label: null,
      key: "facNum",
    },
    {
      icon: mdiDolly,
      info: "Statut livraison",
      label: null,
      key: "deliveryStatus",
    },
    {
      icon: mdiHandCoinOutline,
      info: "Statut paiement",
      label: null,
      key: "paymentStatus",
    },
  ],
};
export default function Details({
  isModalOpen,
  setIsModalOpen,
  context,
  requestState,
  orderDetails,
  paymentDetails,
  deliveryDetails,
  clientInfo,
}: Props) {
  const [generalInformation, setGeneralInformation] = useState<any>(null);

  const handelRenderSection = (context: string) => {
    if (context === "orderDetails") {
      return (
        <OrderDetailsList
          requestState={requestState}
          orderDetailsList={orderDetails}
        />
      );
    } else if (context === "paymentDetails") {
      return (
        <PaymentDetails
          requestState={requestState}
          paymentDetailsList={paymentDetails}
        />
      );
    } else if (context === "deliveryDetails") {
      return (
        <DeliveryDetails
          requestState={requestState}
          deliveryDetailsList={deliveryDetails}
        />
      );
    } else return "";
  };

  useEffect(() => {
    let u: any = formatDataForDetails();
    setGeneralInformation(u);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [context, requestState, orderDetails, paymentDetails, deliveryDetails]);

  //Format data for order-details-body-section1
  const formatDataForDetails = () => {
    if (requestState === "idle" && orderDetails && context === "orderDetails") {
      return {
        dateCreation: orderDetails.dateCreation
          ? getLocalDate(orderDetails.dateCreation)
          : "-",

        dateDeadLineDelivery: orderDetails.dateDeadLineDelivery
          ? getLocalDate(orderDetails.dateDeadLineDelivery)
          : "-",
        depot: orderDetails.depot ? orderDetails.depot.depotName : "-",
        dateDeadLine: orderDetails.dateDeadLine
          ? getLocalDate(orderDetails.dateDeadLine)
          : "-",
        priceHT: orderDetails.priceHT ? ` ${orderDetails.priceHT}` : "-",
        priceTTC: orderDetails.priceTTC ? `${orderDetails.priceTTC}` : "-",
        discount: orderDetails.remise ? `${orderDetails.remise} %` : "-",
        afterDiscount:
          orderDetails.priceTTC && orderDetails.remise
            ? `${
                orderDetails.priceTTC -
                (orderDetails.priceTTC * orderDetails.remise) / 100
              }`
            : "-",
        remainingAmount: orderDetails.payements
          ? `${orderDetails.payements.remainingAmount}`
          : orderDetails.remise && orderDetails.priceTTC
          ? `${
              orderDetails.priceTTC -
              (orderDetails.priceTTC * orderDetails.remise) / 100
            }`
          : `${orderDetails?.priceTTC || "-"}`,
      };
    } else if (
      requestState === "idle" &&
      paymentDetails &&
      paymentDetails.order &&
      context === "paymentDetails"
    ) {
      let { remainingAmount, status } = paymentDetails;
      let {
        dateDeadLine,
        numFact,
        priceTTC,
        statutDelivery,
      } = paymentDetails.order;      
      return {
        dateDeadLine: dateDeadLine ? getLocalDate(dateDeadLine) : "-",
        facNum: numFact ? `${numFact}` : "-",
        deliveryStatus: statutDelivery?.codeStatus
          ? orderStatus[statutDelivery.codeStatus]
          : "-",
        paymentStatus: status?.codeStatus
          ? orderStatus[status.codeStatus]
          : "-",
        closedPayment: "",
        total: priceTTC ? `${priceTTC}` : "-",
        payedAmount: "",
        remainingAmount: remainingAmount ? `${remainingAmount}` : "-",
      };
    } else if (
      requestState === "idle" &&
      deliveryDetails &&
      deliveryDetails.length &&
      context === "deliveryDetails"
    ) {
      let { codeStatusPayment, dateDeadLine, numFact, statutDelivery } =
        deliveryDetails[0].order;
      return {
        dateDeadLine: dateDeadLine ? getLocalDate(dateDeadLine) : "-",
        facNum: numFact ? `${numFact}` : "-",
        deliveryStatus: statutDelivery?.codeStatus
          ? orderStatus[statutDelivery.codeStatus]
          : "-",
        paymentStatus: codeStatusPayment?.codeStatus
          ? orderStatus[codeStatusPayment.codeStatus]
          : "-",
      };
    } else return "no element";
  };

  //Format client info
  const formatClientInfo = () => {
    if (clientInfo) {
      let client: {
        doctorId?: number;
        title?: string;
        firstName?: string;
        lastName?: string;
        sellerId?: number;
        sellerName?: string;
      } = JSON.parse(clientInfo);
      return {
        icon: client?.doctorId ? mdiMedicalBag : mdiMortarPestlePlus,
        clientName: client?.doctorId
          ? ` ${client?.title} ${client?.firstName} ${client?.lastName}`
          : `${client?.sellerName || "-"}`,
        type: client?.doctorId ? "" : "",
      };
    } else {
      return { icon: mdiMedicalBag, clientName: "", type: "" };
    }
  };

  return (
    <Modal
      open={isModalOpen}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      className="order-details__wrapper"
    >
      <Box className="order-details__box-wrapper">
        <header className="order-details-header-section">
          <div className="client-info">
            <InfoWithIconTag
              label={formatClientInfo().clientName}
              info={formatClientInfo().type}
              icon={`${formatClientInfo().icon}`}
              IconSize={0.9}
              labelClassName={"style1"}
              infoClassName={"style2"}
            />
          </div>
          <IconButton
            id="close-order-detail-modal"
            onClick={() => {
              setIsModalOpen(false);
            }}
          >
            <CloseOutlined />
          </IconButton>
        </header>
        <div className="separator"></div>
        {requestState === "idle" && generalInformation ? (
          <section className="order-details-body">
            <div className="order-details-body-section1">
              {standardElements &&
                context &&
                standardElements[context].map((el, index) => {
                  if (
                    el.key &&
                    generalInformation &&
                    generalInformation[
                      el.key as keyof typeof generalInformation
                    ]
                  ) {
                    return (
                      <InfoWithIconTag
                        info={el.info}
                        label={
                          generalInformation
                            ? generalInformation[
                                el.key as keyof typeof generalInformation
                              ]
                            : ""
                        }
                        icon={el.icon}
                        IconSize={0.9}
                        labelClassName={"style3"}
                        infoClassName={"style2"}
                        key={index}
                      />
                    );
                  } else if (el.key === "cloturé" && el.label) {
                    return (
                      <InfoWithIconTag
                        info={null}
                        label={el.label}
                        icon={el.icon}
                        IconSize={0.9}
                        labelClassName={"style3"}
                        infoClassName={"style2"}
                        key={index}
                      />
                    );
                  }
                  return "";
                })}
            </div>
            {context && handelRenderSection(context)}
          </section>
        ) : (
          <div className="order-body-loader">
            <CircularProgress color="primary" />{" "}
          </div>
        )}
      </Box>
    </Modal>
  );
}
