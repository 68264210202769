import React from "react";
import { FlexboxGrid } from "rsuite";
import PhalconBarChart from "../../../03-organisms/charts/PhalconBarChart";
import PhalconPieChart from "../../../03-organisms/charts/PhalconPieChart";
import useBiRp from "./useBiRp";

type Props = {};
type ChartDefinition = {
  title: string;
  type:
    | "pie"
    | "donut"
    | "line"
    | "area"
    | "bar"
    | "histogram"
    | "radialBar"
    | "scatter"
    | "bubble"
    | "heatmap"
    | "treemap"
    | "boxPlot"
    | "candlestick"
    | "radar"
    | "polarArea"
    | "rangeBar";
  labels?: string[];
  series: number[] | { name: string; data: number[] }[];
  xAxisCategories?: string[];
  stacked?: boolean;
  md?: number;
  yAxis?: string;
};
const BiRp = (props: Props) => {
  const { RpByType, RpTypeByProduct, RpTypeByUser } = useBiRp({});
  const charts: ChartDefinition[] = [
    {
      title: "Répartition RP selon le type d'évènement | Budget | Participants",
      type: "donut",
      labels: RpByType.labels,
      series: RpByType.data,
      md: 12,
    },
    {
      title: "Répartition RP selon les produits",
      type: "bar",
      xAxisCategories: RpTypeByProduct.xAxis,
      series: RpTypeByProduct.series,
      stacked: true,
      md: 12,
      yAxis: "Nombres Rp",
    },
    {
      title: "Répartition RP | Délégués",
      type: "bar",
      xAxisCategories: RpTypeByUser.xAxis,
      series: RpTypeByUser.series,
      stacked: true,
      md: 24,
      yAxis: "Nombres Rp",
    },
  ];

  return (
    <div className="phalcon-bi-general__wrapper">
      <div className="phalcon-bi-general__charts-area">
        <FlexboxGrid align="bottom">
          {charts.map((chart: any, index: number) => {
            if (["pie", "donut"].includes(chart.type)) {
              return (
                <PhalconPieChart
                  key={index}
                  title={chart.title}
                  type={chart.type}
                  labels={chart.labels}
                  series={chart.series}
                  md={chart.md}
                />
              );
            }
            if (chart.type === "bar") {
              return (
                <PhalconBarChart
                  key={index}
                  title={chart.title}
                  series={chart.series}
                  xAxisCategories={chart.xAxisCategories}
                  stacked={chart.stacked}
                  md={chart.md}
                  yAxis={chart.yAxis}
                />
              );
            } else {
              return null;
            }
          })}
        </FlexboxGrid>
      </div>
    </div>
  );
};

export default BiRp;
