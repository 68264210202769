import { PotentialProgress } from "./../../types/PotentialProgress.model";
import { createSlice } from "@reduxjs/toolkit";
import { API_DEV } from "../../utils/apiRoute";
import { axiosPrivate } from "../../utils/interceptors";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { WeekContent } from "../../types/WeekContent.model";
import { Locality } from "../../types/Locality.model";

export interface TrackingWeek {
  startDate: string;
  endDate: string;
  localities: Locality[];
  weekNumberInYear: number;
  weekContents: WeekContent[];
  potentialProgress: PotentialProgress[];
}
export interface TrackingState {
  requestState: "loading" | "idle";
  week: TrackingWeek;
  errors: any;
}

export const getCurrentWeekPlanification = createAsyncThunk(
  "[Tracking]_getCurrentWeek_",
  async (userId: number, { rejectWithValue }) => {
    try {
      let response = await axiosPrivate.get(
        `${API_DEV}/Planification/CurrentWeek?userId=${userId}`
      );
      return response.data;
    } catch (error) {
      console.log(JSON.parse(JSON.stringify(error)));
      return rejectWithValue(JSON.parse(JSON.stringify(error)));
    }
  }
);

const initialState: TrackingState = {
  requestState: "idle",

  week: {
    startDate: "",
    endDate: "",
    localities: [],
    weekNumberInYear: 0,
    weekContents: [],
    potentialProgress: [],
  },

  errors: null,
};

export const trackingSlice = createSlice({
  name: "[Pharmacies]",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getCurrentWeekPlanification.pending, (state) => {
      state.requestState = "loading";
      state.errors = null;
    });
    builder.addCase(
      getCurrentWeekPlanification.fulfilled,
      (state, { payload }) => {
        state.requestState = "idle";
        state.week = payload;
        state.errors = null;
      }
    );
    builder.addCase(
      getCurrentWeekPlanification.rejected,
      (state, { payload }) => {
        state.requestState = "idle";
        state.errors = payload;
      }
    );
  },
});

export default trackingSlice.reducer;
