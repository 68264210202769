import React from "react";
import { Avatar, Tooltip } from "@material-ui/core";
/* import { API_DEV } from "../../../utils/apiRoute";
import { axiosPrivate } from "../../../utils/interceptors"; */
import imageEnvironmentUrl from "../../../imageEnvironmentUrl";
import "./CustomAvatar.scss";
interface Props {
  image: { src: string; alt: string };
  style?: React.CSSProperties | undefined;
  isClassName?: string;
  isToolTip?: boolean;
  toolTipTitle?: string;
}
/**
 * Dynamic Avatar atome
 *
 *
 * @param image - { src : string alt : string }  Image Object to display
 * @param style - React.CSSProperties | undefined
 * @param isClassName - string
 * @param isToolTip - boolean
 */
export const CustomAvatar = ({
  image,
  style,
  isClassName,
  isToolTip,
  toolTipTitle,
  ...props
}: Props) => {
  if (isToolTip) {
    return (
      <Tooltip placement="bottom" title={toolTipTitle ? toolTipTitle : ""}>
        <Avatar
          alt={image?.alt ? image.alt : "Remy Sharp"}
          src={image?.src ? `${imageEnvironmentUrl.baseUrl}${image.src}` : ""}
          style={style}
          className={isClassName ? isClassName : ""}
        />
      </Tooltip>
    );
  } else {
    return (
      <Avatar
        alt={image?.alt ? image.alt : "Remy Sharp"}
        src={image?.src ? `${imageEnvironmentUrl.baseUrl}${image.src}` : ""}
        style={style}
        className={isClassName ? isClassName : ""}
      />
    );
  }
};
