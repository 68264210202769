import React from "react";
import { ProfileInfoItemPlaceholder } from "../../../02-molecules/placeholders/Placeholder";

type Props = {
  label: string;
  content: any;
  loading: boolean;
};

export const InfoItemExtra = ({ loading, label, content }: Props) => {
  return (
    <dl className="extra-item">
      {!loading ? (
        <div className="content">
          <dt>{label}</dt>
          <dd>{content ? content : "N/A"}</dd>
        </div>
      ) : (
        <ProfileInfoItemPlaceholder rows={2} />
      )}
    </dl>
  );
};
