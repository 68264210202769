import frFr from "date-fns/locale/fr";

export const useRsuiteTranstion = () => {
  const Calendar = {
    sunday: "Dim",
    monday: "Lun",
    tuesday: "Mar",
    wednesday: "Mer",
    thursday: "Jeu",
    friday: "Ven",
    saturday: "Sam",
    ok: "OK",
    today: "Aujourd'hui",
    yesterday: "Hier",
    hours: "Heures",
    minutes: "Minutes",
    seconds: "Secondes",
    /**
     * Format of the string is based on Unicode Technical Standard #35:
     * https://www.unicode.org/reports/tr35/tr35-dates.html#Date_Field_Symbol_Table
     **/
    formattedMonthPattern: "MMM yyyy",
    formattedDayPattern: "dd MMM yyyy",
    dateLocale: frFr,
  };

  const locale = {
    common: {
      loading: "Chargement",
      emptyMessage: "Aucun résultat",
    },
    Plaintext: {
      unfilled: "Unfilled",
      notSelected: "Not selected",
      notUploaded: "Not uploaded",
    },
    Pagination: {
      more: "Plus",
      prev: "Précédent",
      next: "Suivant",
      first: "Premier",
      last: "Dérnier",
      limit: "{0} / page",
      total: "Total : {0}",
      skip: "Aller à {0}",
    },
    Calendar,
    DatePicker: {
      ...Calendar,
    },
    DateRangePicker: {
      ...Calendar,
      last7Days: "Derniers 7 jours",
    },
    Picker: {
      noResultsText: "Aucun résultat",
      placeholder: "Selectionner",
      searchPlaceholder: "Recherche",
      checkAll: "Tous",
    },
    InputPicker: {
      newItem: "Nouveau",
      createOption: 'Créer "{0}"',
    },
    Uploader: {
      inited: "Initial",
      progress: "Uploading",
      error: "Error",
      complete: "Finished",
      emptyFile: "Empty",
      upload: "Upload",
    },
    CloseButton: {
      closeLabel: "Fermer",
    },
    Breadcrumb: {
      expandText: "Show path",
    },
    Toggle: {
      on: "Open",
      off: "Close",
    },
  };
  return locale;
};
