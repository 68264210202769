import React from "react";
import NewRpTable from "../../03-organisms/tables/RpTable/NewRpTable";
import { Header } from "../../05-layouts/Header/Header";

import "./RpPage.scss";
type Props = {};

const RpListPage = (props: Props) => {
  return (
    <>
      <Header
        leftItem={
          <div className="cycle-management__header__title">Liste des RP</div>
        }
        subRoutes={[]}
      />
      <div className="rp-page__inner">
        <NewRpTable context="global" />
      </div>
    </>
  );
};

export default RpListPage;
