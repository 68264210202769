import {
  Paper,
  styled,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@material-ui/core";
import React from "react";
import "./CustomizedTable.scss";

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(2n)": {
    backgroundColor: "#F0F0F0",
  },
  "&:nth-of-type(odd)": {
    backgroundColor: "#FFFFFF",
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

type Props = {
  columns: {
    title: string;
    align: "left" | "right" | "inherit" | "center" | "justify" | undefined;
  }[];
  rows: any;
};
export default function CustomizedTables({ columns, rows }: Props) {  
  return (
    <TableContainer component={Paper}>
      <Table aria-label="customized table" className="customized-table-style">
        <TableHead className="customized-table-hedear-style">
          <TableRow>
            {columns &&
              columns.length &&
              columns.map((el, index) => {
                return (
                  <TableCell
                    className="customized-table-header-title-style"
                    align={el.align}
                    key={index + "tableColumn"}
                  >
                    {el.title}
                  </TableCell>
                );
              })}
          </TableRow>
        </TableHead>
        <TableBody>
          {rows && rows.length ? rows.map((row: any, index: number) => (
            <StyledTableRow key={index + "customizedTable"}>
              {Object.values(row).map((el: any, ind: number) => {
                return (
                  <TableCell
                    component="th"
                    scope="row"
                    key={ind + "rowElement"}
                  >
                    {typeof el === "object" ? (
                      <div>  
                        {el &&
                          el.length &&
                          el.map((product: any, productId: number) => {
                            return (
                              <div
                                key={productId + "product"}
                                className="row-text-style"
                              >
                                {product?.produit || "-"}
                              </div>
                            );
                          })}
                      </div>
                    ) : (
                      el ? el :  "-"
                    )}
                  </TableCell>
                ); 
              })}
            </StyledTableRow>
          )): ""}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
