import jwtDecode from "jwt-decode";
import { getAccessToken } from "./currentUserToken";

// Get user Id from accesstoken
export const getConnectedUserId = () => {
  const token = getAccessToken();
  const decoded: any = token && jwtDecode(token);
  return decoded.id;
};

// Get user Bu from accesstoken
export const getConnectedUserBu = () => {
  const token = getAccessToken();
  const decoded: any = token && jwtDecode(token);
  const Bu: number[] = decoded ? JSON.parse(
    decoded.bus
  ).map(
    (e: { BusinessUnitId: number; BusinessUnitName: string }) =>
      e.BusinessUnitId
  ) : [];
  return Bu;
};
