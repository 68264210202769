import {
  mdiFacebookMessenger,
  mdiVideoPlus,
  mdiMicrosoftTeams,
  mdiPhone,
  mdiWhatsapp,
  mdiHumanMaleMale,
} from "@mdi/js";

export const getChannelIconPath = (channelId: number | undefined) => {
  switch (channelId) {
    case 1:
      return mdiHumanMaleMale;
    case 2:
      return mdiPhone;
    case 3:
      return mdiWhatsapp;
    case 4:
      return mdiFacebookMessenger;
    case 5:
      return mdiVideoPlus;
    case 6:
      return mdiMicrosoftTeams;
    default:
      return mdiHumanMaleMale;
  }
};
