import { ErrorMessage } from "@hookform/error-message";
import React from "react";
import { Form } from "rsuite";

interface Props {
  errors: any;
  name: string;
  passwordCheck?: boolean;
}

export const CustomErrorMessage = ({ errors, name, passwordCheck }: Props) => {
  return (
    <ErrorMessage
      errors={errors}
      name={name}
      render={
        passwordCheck
          ? ({ messages }) => (
              <Form.ErrorMessage show={true} placement="bottomStart">
                {messages &&
                  Object.entries(messages).map(([type, message]) => (
                    <li key={type}>{message}</li>
                  ))}
              </Form.ErrorMessage>
            )
          : ({ message }) => (
              <Form.ErrorMessage show={true} placement="bottomStart">
                {message}
              </Form.ErrorMessage>
            )
      }
    />
  );
};
