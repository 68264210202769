import { useState } from "react";
import { getCurrentYear } from "../../../../utils/dateHelpers";

export type SalesTargetFilterData = {
  year: number | string;
  delegateId: number | null;
  products: number[];
};

export type SalesTargetFilterInterface = {
  year: number | string;
  productsId: number[];
  delegateId?: null | number;
  pagination: {
    pageNumber: number;
    pageSize: number;
  };
};
export type GlobalSalesTargetFilterState = {
  searchWsSalesTarget: SalesTargetFilterInterface;
  searchSalesTargetAchived: SalesTargetFilterInterface;
};
export const useSalesTargetFilters = () => {
  const [globalFilterState, setGlobalFilterState] =
    useState<GlobalSalesTargetFilterState>({
      searchWsSalesTarget: {
        year: getCurrentYear("YYYY"),
        productsId: [],
        pagination: {
          pageNumber: 1,
          pageSize: 10,
        },
      },
      searchSalesTargetAchived: {
        year: getCurrentYear("YYYY"),
        delegateId: null,
        productsId: [],
        pagination: {
          pageNumber: 1,
          pageSize: 10,
        },
      },
    });

  const captureYear = (year: string | number) => {
    setGlobalFilterState({
      searchWsSalesTarget: {
        year: year,
        productsId: globalFilterState.searchWsSalesTarget.productsId,
        pagination: globalFilterState.searchWsSalesTarget.pagination,
      },
      searchSalesTargetAchived: {
        year: year,
        delegateId: globalFilterState.searchSalesTargetAchived.delegateId,
        productsId: globalFilterState.searchSalesTargetAchived.productsId,
        pagination: globalFilterState.searchSalesTargetAchived.pagination,
      },
    });

    
  };

  const captureDelegate = (delegate: number) => {
    setGlobalFilterState({
      searchWsSalesTarget: {
        year: globalFilterState.searchWsSalesTarget.year,
        productsId: globalFilterState.searchWsSalesTarget.productsId,
        pagination: globalFilterState.searchWsSalesTarget.pagination,
      },
      searchSalesTargetAchived: {
        year: globalFilterState.searchSalesTargetAchived.year,
        delegateId: delegate,
        productsId: globalFilterState.searchSalesTargetAchived.productsId,
        pagination: globalFilterState.searchSalesTargetAchived.pagination,
      },
    });
    
  };

  const captureProducts = (products: number[]) => {
    setGlobalFilterState({
      searchWsSalesTarget: {
        year: globalFilterState.searchWsSalesTarget.year,
        productsId: products,
        pagination: globalFilterState.searchWsSalesTarget.pagination,
      },
      searchSalesTargetAchived: {
        year: globalFilterState.searchSalesTargetAchived.year,
        delegateId: globalFilterState.searchSalesTargetAchived.delegateId,
        productsId: products,
        pagination: globalFilterState.searchSalesTargetAchived.pagination,
      },
    });
    
  };

  const capturePageTarget = (
    page: number,
    context: "achievedSales" | "estimatedSales"
  ) => {
    if (context === "achievedSales") {
      setGlobalFilterState({
        ...globalFilterState,
        searchSalesTargetAchived: {
          year: globalFilterState.searchSalesTargetAchived.year,
          delegateId: globalFilterState.searchSalesTargetAchived.delegateId,
          productsId: globalFilterState.searchSalesTargetAchived.productsId,
          pagination: {
            ...globalFilterState.searchSalesTargetAchived.pagination,
            pageNumber: page + 1,
          },
        },
      });
      
    } else {
      setGlobalFilterState({
        ...globalFilterState,
        searchWsSalesTarget: {
          year: globalFilterState.searchWsSalesTarget.year,
          productsId: globalFilterState.searchWsSalesTarget.productsId,
          pagination: {
            ...globalFilterState.searchWsSalesTarget.pagination,
            pageNumber: page + 1,
          },
        },
      });
    }
    
  };

  const captureLimitTarget = (
    limit: number,
    context: "achievedSales" | "estimatedSales"
  ) => {
    if (context === "achievedSales") {
      setGlobalFilterState({
        ...globalFilterState,
        searchSalesTargetAchived: {
          year: globalFilterState.searchSalesTargetAchived.year,
          delegateId: globalFilterState.searchSalesTargetAchived.delegateId,
          productsId: globalFilterState.searchSalesTargetAchived.productsId,
          pagination: {
            ...globalFilterState.searchSalesTargetAchived.pagination,
            pageNumber: 1,
            pageSize: limit,
          },
        },
      });
      
    } else {
      setGlobalFilterState({
        ...globalFilterState,
        searchWsSalesTarget: {
          year: globalFilterState.searchWsSalesTarget.year,
          productsId: globalFilterState.searchWsSalesTarget.productsId,
          pagination: {
            ...globalFilterState.searchWsSalesTarget.pagination,
            pageNumber: 1,
            pageSize: limit,
          },
        },
      });
    }
    
  };

  return {
    globalFilterState,
    captureYear,
    captureDelegate,
    captureProducts,
    capturePageTarget,
    captureLimitTarget,
    setGlobalFilterState,
  };
};
