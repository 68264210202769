import { mdiTune } from "@mdi/js";
import Icon from "@mdi/react";
import React, { createRef } from "react";
import { Controller, useForm } from "react-hook-form";
import { DateRangePicker, Form, Popover, Whisper } from "rsuite";
import { useHandleResize } from "../../../../../utils/useHandleResize";
import { CustomButton } from "../../../../01-atoms/Buttons/Buttons";
import { CustomErrorMessage } from "../../../../02-molecules/forms/ErrorMessage/CustomErrorMessage";
import "./../OrderFilters.scss";
import { OrderTrackingFilterData } from "./useOrderAnalyticsFilter";
import { TagsField } from "../../../../02-molecules/forms/TagsField/TagsField";
import moment from "moment";

type Props = {
  captureFilter: (data: OrderTrackingFilterData) => void;
  deliveryStatusValue?: string[];
  productValue?: number[];
  dateValue?: [Date, Date] | undefined;
  setAppliedFilters: React.Dispatch<React.SetStateAction<string[]>>;
  productOptions: (
    | {
        key: number;
        label: string;
        value: number;
      }
    | undefined
  )[];
};

export const orderStatus: {
  [UND: string]: string;
  PD: string;
  TD: string;
  UNP: string;
  PP: string;
  TP: string;
} = {
  UND: "Non livré",
  PD: "Partiellement livré",
  TD: "Livré",
  UNP: "Non payé",
  PP: "Partiellement payé",
  TP: "Payé",
};

export const OrderAnalyticsFilter = ({
  captureFilter,
  dateValue,
  productOptions,
  setAppliedFilters,
}: Props) => {
  const deviceSize = useHandleResize();
  const initForm: OrderTrackingFilterData = {
    statusDelivry: [],
    products: [],
    date: dateValue,
  };
  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<OrderTrackingFilterData>({
    defaultValues: initForm,
    criteriaMode: "all",
    reValidateMode: "onChange",
  });
  const handleGetAppliedFilter = (data: {
    statusDelivry: string[];
    products: number[];
    date: [Date, Date] | undefined;
  }) => {
    const initialDateValue: string[] = [
      moment().startOf("month").format("L"),
      moment().format("L"),
    ];
    let deliveryFilterList: string[] = [];
    let productFilterList: string[] = [];
    let dateFilterList: string[] = [initialDateValue[0], initialDateValue[1]];
    deliveryFilterList = data.statusDelivry.map((el) => { 
      if (orderStatus[el]) {
        return orderStatus[el];
      } else return "";
    });

    productFilterList = data.products.map((el) => {
      let productName: string =
        productOptions?.find((product) => product?.key === el)?.label || "";
      if (productName) {
        return productName;
      } else return "";
    });
    if (data.date && data.date.length) {
      dateFilterList = data.date.map((el) => moment(el).format("L"));
    }
    setAppliedFilters(
      deliveryFilterList.concat(productFilterList).concat(dateFilterList)
    );
  };

  const onSubmit = handleSubmit((data) => {
    handleGetAppliedFilter(data);
    captureFilter(data);
  });
  const datePickerRangeRef: any = createRef();
  const renderMenu = ({ onClose, left, top, className }: any, ref: any) => {
    return (
      <Popover
        ref={ref}
        className={className + " request-filter__whisper"}
        style={{ left, top }}
        full
      >
        <Form>
          <div className="order-filter__inner analytic-filter-width">
            <div className="products-area">
              <TagsField
                control={control}
                errors={errors}
                inputName="products"
                xs={24}
                sm={24}
                md={24}
                data={productOptions ? productOptions : []}
                cleanable={false}
                label={"Produits"}
                onSubmit={onSubmit}
              />
            </div>
            <div className="dates-area">
              <Form.Group>
                <Controller
                  control={control}
                  name="date"
                  render={({ field: { onChange, name, value } }) => (
                    <Form.Control
                      ref={datePickerRangeRef}
                      accepter={DateRangePicker}
                      name={name}
                      value={value}
                      isoWeek
                      className="order-date-width"
                      format={"yyyy-LL-dd"}
                      style={{ width: "100%" }}
                      container={() => datePickerRangeRef.current}
                      placement="autoHorizontal"
                      onChange={(e: any) => {
                        onChange(e);
                        onSubmit();
                      }}
                    />
                  )}
                />
                <CustomErrorMessage errors={errors} name="date" />
              </Form.Group>
            </div>
          </div>
        </Form>
      </Popover>
    );
  };
  return (
    <div className="request-filter__wrapper">
      <Whisper
        placement="autoVerticalEnd"
        trigger="click"
        speaker={renderMenu}
        preventOverflow
      >
        <div>
          <CustomButton
            text={["Mobile", "Tablet"].includes(deviceSize) ? "" : "Filtrer"}
            icon={<Icon path={mdiTune} size={1} />}
          />
        </div>
      </Whisper>
    </div>
  );
};
