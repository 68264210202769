import React from "react";
import { Controller } from "react-hook-form";
import { InputGroup, Input, Form, Col } from "rsuite";
import { CustomErrorMessage } from "../ErrorMessage/CustomErrorMessage";
import VisibleIcon from "@rsuite/icons/Visible";
import UnvisibleIcon from "@rsuite/icons/Unvisible";

type Props = {
  control: any;
  errors: any;
  label: string;
  inputName: string;
  rules: any;
  inputMode?:
    | "search"
    | "text"
    | "none"
    | "tel"
    | "url"
    | "email"
    | "numeric"
    | "decimal";
  xs?: number;
  md?: number;
  lg?: number;
  passwordCheck: boolean;
};

export const PasswordField = ({
  label,
  control,
  inputName,
  rules,
  errors,
  inputMode,
  xs,
  md,
  lg,
  passwordCheck,
}: Props) => {
  const [visible, setVisible] = React.useState(false);

  const handleChange = () => {
    setVisible(!visible);
  };
  return (
    <Col xs={xs || 24} md={md} lg={lg} style={{ marginBottom: "1rem" }}>
      <Form.Group>
        <Form.ControlLabel>{label}</Form.ControlLabel>
        <Controller
          control={control}
          name={inputName}
          rules={rules}
          render={({ field: { onChange, name, value } }) => (
            <InputGroup inside style={{ width: "100%" }}>
              <Form.Control
                style={{ width: "100%" }}
                className="input-full-width"
                autoComplete="none"
                accepter={Input}
                type={visible ? "text" : "password"}
                name={name}
                value={value}
                inputMode={inputMode}
                onChange={(e: any) => onChange(e)}
              />
              <InputGroup.Button onClick={handleChange}>
                {visible ? <UnvisibleIcon /> : <VisibleIcon />}
              </InputGroup.Button>
            </InputGroup>
          )}
        />
        <CustomErrorMessage errors={errors} name={inputName} passwordCheck={passwordCheck} />
      </Form.Group>
    </Col>
  );
};
