import React from "react";
import { NavLink } from "react-router-dom";
import "./AnalyticsSubHeader.scss";
type Props = {};

const AnalyticsSubHeader = (props: Props) => {
  const links = [
    {
      title: "Général",
      path: "/analytics/phalcon-analytics/general",
    },
    {
      title: "Visites",
      path: "/analytics/phalcon-analytics/visits",
    },
    // {
    //   title: "EADV",
    //   path: "/analytics/phalcon-analytics/eadv",
    // },
    {
      title: "Objections",
      path: "/analytics/phalcon-analytics/objections",
    },
    {
      title: "Demandes",
      path: "/analytics/phalcon-analytics/requests",
    },
    // {
    //   title: "RP",
    //   path: "/analytics/phalcon-analytics/rp",
    // },
    // {
    //   title: "Commandes",
    //   path: "/analytics/phalcon-analytics/orders",
    // },
  ];
  return (
    <div className="analytics-sub-header__wrapper">
      <div className="analytics-sub-header__inner">
        <ul className="analytics-sub-header__list">
          {links.map((e, index) => (
            <li key={index}>
              <NavLink className="sub-header-link" to={e.path}>
                {e.title}
              </NavLink>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default AnalyticsSubHeader;
