import {  useAppSelector } from "../../../../../redux/hooks";
import { useEffect, useState } from "react";
import {
  AnalyticsOrderFilters,
} from "../../../../../redux/Order/orderSlice";
import { getCurrentMonthToCurrentDay, getdateWithFormatedtTimeZoneToZero } from "../../../../../utils/dateHelpers";
import { RootState } from "../../../../../redux/store";
import { prepareProductOptions } from "../../../../../helpers/GlobalFormHelpers";
// import { useParams } from "react-router-dom";

export type OrderTrackingFilterData = {
  statusDelivry: string[];
  products: number[];
  date: [Date, Date] | undefined;
};

export const useOrderAnalyticFilter = () => {
  const { products } = useAppSelector((state: RootState) => state.general);
  const [formOptions, setFormOptions] = useState<
    (
      | {
          key: number;
          label: string;
          value: number;
        }
      | undefined
    )[]
  >([]);
  const [captureDateProductFilter, setCaptureDateProductFilter] =
    useState<any[]>();
  const [globalFilterState, setGlobalFilterState] =
    useState<AnalyticsOrderFilters>({
      statusDelivry: [],
      products: [],
      wordSearch: null,
      startDate: getCurrentMonthToCurrentDay()?.startDate || null,
      endDate: getCurrentMonthToCurrentDay()?.endDate || null,
      pagination: {
        pageNumber: 1,
        pageSize: 10,
      },
    });

  const captureSearchTextValue = (searchTerm: string) => {
    setGlobalFilterState({
      wordSearch: searchTerm,
      products: globalFilterState.products,
      statusDelivry: globalFilterState.statusDelivry,
      startDate: globalFilterState.startDate,
      endDate: globalFilterState.endDate,
      pagination: {
        ...globalFilterState.pagination,
      },
    });
  };

  const captureFilter = (data: OrderTrackingFilterData) => {
    const { date, statusDelivry, products } = data;
    let startDateValue = date
      ? getdateWithFormatedtTimeZoneToZero(date[0])
      : getCurrentMonthToCurrentDay()?.startDate || null;
    let endDateValue = date
      ? getdateWithFormatedtTimeZoneToZero(date[1])
      : getCurrentMonthToCurrentDay()?.endDate || null;
    setGlobalFilterState({
      wordSearch: globalFilterState.wordSearch,
      startDate: startDateValue,
      endDate: endDateValue,
      products: products,
      statusDelivry: statusDelivry,
      pagination: {
        ...globalFilterState.pagination,
      },
    });
    setCaptureDateProductFilter([startDateValue, endDateValue, products]);
  };

  const capturePageTarget = (page: number) => {
    setGlobalFilterState({
      wordSearch: globalFilterState.wordSearch,
      statusDelivry: globalFilterState.statusDelivry,
      startDate: globalFilterState.startDate,
      endDate: globalFilterState.endDate,
      products: globalFilterState.products,
      pagination: {
        ...globalFilterState.pagination,
        pageNumber: page + 1,
      },
    });
  };

  const captureLimitTarget = (limit: number) => {
    setGlobalFilterState({
      wordSearch: globalFilterState.wordSearch,
      statusDelivry: globalFilterState.statusDelivry,
      startDate: globalFilterState.startDate,
      endDate: globalFilterState.endDate,
      products: globalFilterState.products,
      pagination: {
        ...globalFilterState.pagination,
        pageNumber: 1,
        pageSize: limit,
      },
    });
  };
  //product options preparation

  useEffect(() => {
    const productsOptions = prepareProductOptions(products);

    if (productsOptions && productsOptions.length > 0) {
      setFormOptions(productsOptions);
    }
  }, [products]);



  return {
    globalFilterState,
    captureFilter,
    capturePageTarget,
    captureLimitTarget,
    captureSearchTextValue,
    captureDateProductFilter,
    formOptions,
  };
};
