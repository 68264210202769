import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { API_DEV } from "../../utils/apiRoute";
import { axiosPrivate } from "../../utils/interceptors";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { CommonFilters } from "../../types/CommonFilters.model";

interface GlobalCommonFilterState {
  search: {
    weekId: number | null;
    specialities: number[] | null;
    potentials: number[] | null;
    localities: number[] | null;
    facilities: number[] | null;
    tags: number[] | null;
    showAssigned: boolean;
  };
  groupBy: {
    groupedBy: string[] | null;
  };
  orderBy: {
    value: string;
    order: string;
  };
}
export interface CommonState {
  requestState: "loading" | "idle";
  filter: CommonFilters | null;
  globalCommonFilterState: GlobalCommonFilterState;
  filteredCommon: any;
  errors: any;
}

export const fetchCommonFilters = createAsyncThunk(
  "[Common]__fetchFilters__",
  async (isShowAssigned: boolean, { rejectWithValue }) => {
    try {
      const response = await axiosPrivate.post(
        `${API_DEV}/Common/GetFiltersOnly`,
        { isShowAssigned }
      );
      return response.data;
    } catch (error) {
      console.log(JSON.parse(JSON.stringify(error)));
      return rejectWithValue(JSON.parse(JSON.stringify(error)));
    }
  }
);
export const fetchFilteredCommon = createAsyncThunk(
  "[Common]__fetchFilteredCommon__",
  async (payload: GlobalCommonFilterState, { rejectWithValue }) => {
    try {
      const response = await axiosPrivate.post(
        `${API_DEV}/Common/GetAllWithFilters`,
        payload
      );
      return response.data;
    } catch (error) {
      console.log(JSON.parse(JSON.stringify(error)));
      return rejectWithValue(JSON.parse(JSON.stringify(error)));
    }
  }
);

const initialState: CommonState = {
  requestState: "idle",
  filter: null,
  globalCommonFilterState: {
    search: {
      weekId: null,
      specialities: [],
      potentials: [],
      localities: [],
      facilities: [],
      tags: [],
      showAssigned: false,
    },
    groupBy: {
      groupedBy: [],
    },
    orderBy: {
      value: "",
      order: "",
    },
  },
  filteredCommon: null,
  errors: null,
};

export const commonslice = createSlice({
  name: "[Common]",
  initialState,
  reducers: {
    captureSearch: (
      state,
      action: PayloadAction<{
        weekId: number | null;
        specialities: number[];
        potentials: number[];
        localities: number[];
        facilities: number[];
        tags: number[];
        showAssigned: boolean;
      }>
    ) => {
      state.globalCommonFilterState.search = action.payload;
    },
    captureGroupBy: (
      state,
      action: PayloadAction<{
        groupedBy: string[];
      }>
    ) => {
      state.globalCommonFilterState.groupBy = action.payload;
    },
    captureOrderBy: (
      state,
      action: PayloadAction<{
        value: string;
        order: string;
      }>
    ) => {
      state.globalCommonFilterState.orderBy = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchCommonFilters.pending, (state) => {
      state.requestState = "loading";
      state.errors = null;
    });
    builder.addCase(fetchCommonFilters.fulfilled, (state, { payload }) => {
      state.requestState = "idle";
      state.filter = payload;
      state.errors = null;
    });
    builder.addCase(fetchCommonFilters.rejected, (state, { payload }) => {
      state.requestState = "idle";
      state.errors = payload;
    });
    builder.addCase(fetchFilteredCommon.pending, (state) => {
      state.requestState = "loading";
      state.errors = null;
    });
    builder.addCase(fetchFilteredCommon.fulfilled, (state, { payload }) => {
      state.requestState = "idle";
      state.filteredCommon = payload;
      state.errors = null;
    });
    builder.addCase(fetchFilteredCommon.rejected, (state, { payload }) => {
      state.requestState = "idle";
      state.errors = payload;
    });
  },
});

export const { captureSearch, captureGroupBy, captureOrderBy } =
  commonslice.actions;
export default commonslice.reducer;
