import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { useAppDispatch, useAppSelector } from "../../../../redux/hooks";
import { sendSupportEmail } from "../../../../redux/Authentication/authenticationSlice";
import { RootState } from "../../../../redux/store";
import { useAuthErrors } from "../../../../utils/useAuthErrors";
import { Link } from "react-router-dom";
import Icon from "@mdi/react";
import { mdiChevronLeft } from "@mdi/js";
import { Form, Button } from "rsuite";
import { TextField } from "../../../02-molecules/forms/TextField/TextField";
import { toast } from "react-toastify";
import "./ForgotPasswordForm.scss";
interface Props {}

type ForgotPasswordInputs = {
  //Credentials can be Username or email
  credentials: string;
};
export const ForgotPasswordForm = ({ ...props }: Props) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<ForgotPasswordInputs>();

  const fieldIsRequired = t("common.error_messages.is_required");
  const [formIsSubmitted, setFormIsSubmitted] = useState(false);
  const onSubmit = handleSubmit((data) => {
    dispatch(sendSupportEmail(data.credentials));
    setFormIsSubmitted(true);
  });

  const { supportState, error } = useAppSelector(
    (state: RootState) => state.auth
  );
  const formError = supportState === "fail" && formIsSubmitted;
  const formSuccess = supportState === "success" && formIsSubmitted;
  let errorMessage = useAuthErrors({
    page: "forgot_password_page",
    status: error.status,
  });

  useEffect(() => {
    formError &&
      toast.error(errorMessage, {
        position: "bottom-right",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formError]);
  useEffect(() => {
    formSuccess &&
      toast.success(t("forgot_password_page.success"), {
        position: "bottom-right",
        autoClose: 8000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formSuccess]);
  return (
    <div className="forgot-password__form__wrapper">
      <Link to={"/login"} className="auth-return-button">
        <Icon path={mdiChevronLeft} size={1} />
      </Link>

      <Form autoComplete="none">
        <TextField
          label={t("forgot_password_page.username_label")}
          inputName="credentials"
          rules={{ required: fieldIsRequired }}
          control={control}
          errors={errors}
        />

        <Button
          loading={supportState === "loading"}
          type="submit"
          appearance="primary"
          onClick={onSubmit}
          className="submit-button"
        >
          {t("forgot_password_page.submit_button")}
        </Button>
      </Form>
    </div>
  );
};
