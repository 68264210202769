import React, { createRef, useEffect, useState } from "react";
import { mdiFullscreen, mdiFullscreenExit, mdiMagnify } from "@mdi/js";
import Icon from "@mdi/react";
import "./ObjectionsList.scss";
import { DOMHelper, Input, InputGroup, Loader } from "rsuite";
import { CustomButton } from "../../../01-atoms/Buttons/Buttons";
import { useAppSelector } from "../../../../redux/hooks";
import { RootState } from "../../../../redux/store";

import moment from "moment";
import {
  GlobalObjectionFilterState,
  useFilterObjection,
} from "../../../03-organisms/filter/ObjectionFilter/useFilterObjection";
import { ObjectionsTable } from "../../../03-organisms/tables/ObjectionsTable/ObjectionsTable";
import { ObjectionFilter } from "../../../03-organisms/filter/ObjectionFilter/ObjectionFilter";

export type ObjectionsListContext =
  | "doctor"
  | "pharmacy"
  | "seller"
  | "user"
  | "global";
type Props = {
  context: ObjectionsListContext;
};

export type ObjectionsTableData = {
  id: number;
  customer: string;
  isTreated: boolean;
  assignedTo: string;
  oponedOn: string;
  closedOn: string;
  isClosed: boolean;
  closedBy: string;
  treatedBy: string;
  objectionValue: string;
  product: string;
  response: string;
  satisfactionLevel: number;
  objectionType: string;
};

export const ObjectionsList = ({ context }: Props) => {
  const expandRef: any = createRef();
  const { toggleClass } = DOMHelper;
  const [isFullWidth, setIsFullWidth] = useState(false);
  const { filteredObjections, requestState, errors } = useAppSelector(
    (state: RootState) => state.objection
  );

  const { delegateById } = useAppSelector((state: RootState) => state.user);
  const { doctorById } = useAppSelector((state: RootState) => state.doctor);
  const { sellerById } = useAppSelector((state: RootState) => state.seller);
  const { pharmacyById } = useAppSelector((state: RootState) => state.pharmacy);

  const getEntityName = (ctx: ObjectionsListContext) => {
    switch (ctx) {
      case "doctor":
        return `${doctorById?.title} ${doctorById?.firstName} ${doctorById?.lastName}`;
      case "user":
        return `${delegateById?.firstName} ${delegateById?.lastName}`;
      case "seller":
        return `${sellerById?.sellerName}`;
      case "pharmacy":
        return `${pharmacyById?.name}`;

      default:
        break;
    }
  };

  const { 
    globalFilterState,
    captureSearchTextValue,
    captureOrderBy,
    captureFilter,
    capturePageTarget,
    captureLimitTarget,
  } = useFilterObjection({ context });

  const [searchTerm, setSearchTerm] = useState("");
  const [searchFieldIsLoading, setSearchFieldIsLoading] = useState(false);

  const formatData = () => {
    return filteredObjections.data.map((item) => ({
      id: item.objectionId,
      isTreated: item.isTreated,
      objectionType:
        item.productObjection.productObjectionType.productObjectionTypeName ||
        "-",
      customer: JSON.stringify({ ...item.doctor }),
      assignedTo: JSON.stringify({
        userId: item.assignedTo ? item.assignedTo.userId : "",
        name: item.assignedTo
          ? `${item.assignedTo.firstName} ${item.assignedTo.lastName}`
          : "",
        photoUrl: item.assignedTo ? item.assignedTo.photoUrl : "",
      }),
      oponedOn: item.openedOn,
      objectionValue: `${
        item.productObjection.productObjectionType.productObjectionTypeName
      } ${
        item.productObjection.description
          ? `: ${item.productObjection.description}`
          : ""
      }`,
      treatedBy: JSON.stringify({
        userId: item.treatedBy ? item.treatedBy.userId : "",
        name: item.treatedBy
          ? `${item.treatedBy.firstName} ${item.treatedBy.lastName}`
          : "",
        photoUrl: item.treatedBy ? item.treatedBy.photoUrl : "",
      }),
      product: item.productObjection.product.productName,
      response: item.response,
      isClosed: item.isClosed,
      closedOn: item.closedOn,
      closedBy: JSON.stringify({
        userId: item.closedBy ? item.closedBy.userId : "",
        name: item.closedBy
          ? `${item.closedBy.firstName} ${item.closedBy.lastName}`
          : "",
        photoUrl: item.closedBy ? item.closedBy.photoUrl : "",
      }),
      satisfactionLevel: item.satisfactionLevel,
    }));
  };

  const prepareData = () => ({
    data: formatData(),
    currentPageNumber: filteredObjections.pagination.pageNumber,
    pageSize: filteredObjections.pagination.pageSize,
    totalCount: filteredObjections.pagination.totalCount,
  });

  const { data, currentPageNumber, pageSize, totalCount } = prepareData();

  const [sortColumn, setSortColumn] = useState();
  const [sortType, setSortType] = useState();
  /* const [tableIsLoading, setTableIsLoading] = useState(true); */

  const handleSortColumn = (column: any, type: any) => {
    captureOrderBy(column, type);
    setSortColumn(column);
    setSortType(type);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  };

  const getDateValue = (globalFilterState: GlobalObjectionFilterState) => {
    const { startDate, endDate } = globalFilterState.search;

    const dateValue: [Date, Date] | undefined =
      startDate && endDate
        ? [
            moment(globalFilterState.search.startDate).utc().toDate(),
            moment(globalFilterState.search.endDate).utc().toDate(),
          ]
        : undefined;

    return dateValue;
  };

  useEffect(() => {
    searchTerm.length && setSearchFieldIsLoading(true);
    const delayDebounceFn = setTimeout(() => {
      captureSearchTextValue(searchTerm);
    }, 500);
    return () => {
      clearTimeout(delayDebounceFn);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchTerm]);

  useEffect(() => {
    if (requestState === "idle" && !errors) setSearchFieldIsLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [requestState]);

  useEffect(() => {
    prepareData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filteredObjections]);

  return (
    <div className="objections-list__wrapper" ref={expandRef}>
      <h3 className="full-screen-title">
        Objections de {getEntityName(context)}
      </h3>
      <div className="top-area">
        <InputGroup inside className="profile-search-input">
          <Input
            size="lg"
            type="text"
            name="search"
            placeholder="Recherche"
            onChange={(value) => setSearchTerm(value)}
          />
          <InputGroup.Addon>
            {searchFieldIsLoading ? (
              <Loader />
            ) : (
              <Icon path={mdiMagnify} size={1} />
            )}
          </InputGroup.Addon>
        </InputGroup>

        <div className="buttons-area">
          <ObjectionFilter
            captureFilter={captureFilter}
            satisfactionLevelValue={globalFilterState.search.satisfactionLevel}
            dateValue={getDateValue(globalFilterState)}
          />

          {context !== "global" && (
            <CustomButton
              className="fullscreen-button"
              icon={
                <Icon
                  path={isFullWidth ? mdiFullscreenExit : mdiFullscreen}
                  size={1}
                />
              }
              onClick={() => {
                toggleClass(expandRef.current, "full-screen");
                setIsFullWidth(!isFullWidth);
              }}
            />
          )}
        </div>
      </div>
      <div className="table-area">
        <ObjectionsTable
          objections={data}
          context={context}
          loading={requestState === "loading"}
          sortColumn={sortColumn}
          sortType={sortType}
          onSortColumn={handleSortColumn}
          totalCount={totalCount}
          pageSize={pageSize}
          currentPageNumber={currentPageNumber}
          captureLimitTarget={captureLimitTarget}
          capturePageTarget={capturePageTarget}
        />
      </div>
    </div>
  );
};
