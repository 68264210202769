//@ts-nocheck
import React, { useEffect, useState } from 'react'
import { useAppDispatch, useAppSelector } from '../../../redux/hooks'
// import { fetchProducts } from '../../../redux/Products/Products'
import { Box, Grid } from '@material-ui/core';
import { DataGrid, GridCellParams, GridColDef } from "@material-ui/data-grid";
import { SearchBar } from "../../02-molecules/searchBar/SearchBar"
import Button from '@material-ui/core/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faToggleOn, faToggleOff } from '@fortawesome/free-solid-svg-icons';
import AddForm from './FaciltyTypesForms/FacilityTypeForm';
import localizedTextsMap from '../../../utils/dataGridColumnMenuLanguage';
import { getAllFacilitiesTypesThunk } from '../../../redux/FacilityType/FacilityTypeSlice';
import { toggleFacility } from '../../../redux/FacilityType/FacilityTypeSlice';
import { mdiPencil } from "@mdi/js";
import Icon from "@mdi/react";
import errorToast from '../../../utils/errorToast';
import { getFacilityTypeByIdThunk } from '../../../redux/FacilityType/FacilityTypeSlice';
import { mdiPlusCircleOutline } from '@mdi/js';
// import errorToast from '../../../utils/errorToast';

export default function FacilityType() {
    const { Ftypes } = useAppSelector(state => state.facilityType)
    const { FbyId } = useAppSelector(state => state.facilityType)
    const dispatch = useAppDispatch()
    const [gridRows, setGridRows] = useState([])
    const [searchQuery, setSearchQuery] = useState('');
    const [showAddForm, setShowAddForm] = useState(false)
    const [FacilityId, SetFById] = useState()
    useEffect(() => {
        dispatch(getAllFacilitiesTypesThunk())
    }, [dispatch])

    useEffect(() => {
        // console.log(Ftypes)
        const newGridRows = Ftypes.map((item: any, i) => ({
            Name: item.facilityTypeName,
            id: item.facilityTypeId,
            toggle: item.active,
            Update: { utility: item.isUtility, id: item.facilityTypeId }
        }));
        let filteredRows = newGridRows
        setGridRows(filteredRows)
    }, [Ftypes])
    useEffect(() => {
        SetFById(FbyId)
    }, [FbyId])
    // const renderVirtualCell = (params: GridCellParams) => (
    //   <div style={{ color: params.value ? 'black' : 'black' }}>
    //     {params.value ? 'Action' : 'Produit'}
    //   </div>
    // );


    const renderToggleCell = (params: GridCellParams) => {
        // console.log(params)
        return (<div style={{ display: 'flex', justifyContent: 'flex-end' }}>
            {params.value === 1 ? <FontAwesomeIcon onClick={async () => {
                await dispatch(toggleFacility({
                    "facilityTypeId": params.row.id,
                    "active": 0
                }))
                dispatch(getAllFacilitiesTypesThunk())
            }} style={{ fontSize: '2em', color: "#20abe3" }} icon={faToggleOn} />
                :
                <FontAwesomeIcon style={{ fontSize: '2em', color: "#20abe3", opacity: 0.5 }} onClick={async () => {
                    await dispatch(toggleFacility({
                        "facilityTypeId": params.row.id,
                        "active": 1
                    }))
                    dispatch(getAllFacilitiesTypesThunk())
                }} icon={faToggleOff} />}
        </div>)
    };
    const renderUpdateCell = (params: GridCellParams) => {
        // console.log(params)
        return (<div >
            <div style={{ display: "flex", justifyContent: "space-around", alignItems: "center", flex: 1 }} onClick={async () => {
                if (params.value.utility) {
                    return errorToast('Type  D\'établissement est utilisé')
                }
                else {
                    await dispatch(getFacilityTypeByIdThunk(params.value.id))
                    setShowAddForm(true)
                }
            }}   >
                <Icon style={{ color: "#61affe", cursor: "pointer" }} path={mdiPencil} size={1} />
            </div>

        </div>)
    };
    const handleSearchChange = (value: any) => {
        setSearchQuery(value);
    };

    const filteredRows = gridRows.filter(row => {
        return row.Name.toLowerCase().includes(searchQuery.toLowerCase());
    });

    const columns: GridColDef[] = [
        { field: 'Name', headerName: "Nom d'Etablissement", minWidth: 190, flex: 1 },

        {
            field: 'toggle',
            headerName: 'Statut',
            width: 110,
            renderCell: renderToggleCell,
        },
        {
            field: 'Update',
            headerName: 'Actions',
            width: 110,
            renderCell: renderUpdateCell,
        },

    ];


    return (
        <>
            {showAddForm ? <AddForm SetFById={SetFById} FbyId={FacilityId} closeForm={setShowAddForm} /> : null}
            <Box sx={{ height: 600, width: "100%" }}>
                <Grid style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }} container >
                    <SearchBar containerClass='productSearchBar' placeHolder='Recherche' setSearchTerm={handleSearchChange} />
                    <Button onClick={() => setShowAddForm(true)} style={{ padding: 20, color: "white", width: "8rem", height: 10, marginRight: "1rem" }} variant="contained" size="large" color="primary" >
                        ajouter <div style={{ display: "flex", justifyContent: "center", alignItems: "center", marginLeft: 7 }}><Icon path={mdiPlusCircleOutline} size={1.2} /></div>
                    </Button>
                </Grid>
                {gridRows.length > 0 ? (
                    <DataGrid
                        rowsPerPageOptions={[5, 10, 20]}
                        localeText={localizedTextsMap}
                        style={{ backgroundColor: "white", marginRight: 12, marginLeft: 17 }}
                        rows={filteredRows}
                        columns={columns}
                        autoPageSize={10}
                        disableColumnMenu
                    />
                ) : null}
            </Box>
        </>
    )
}
