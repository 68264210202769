//@ts-nocheck
import React, { useState } from 'react'
import { Button, FormControl, Grid } from "@material-ui/core"
import { TextField, InputLabel, MenuItem, Select } from '@material-ui/core';
import { useFormik } from 'formik';
import { getConnectedUserBu } from "../../../../utils/getConnectedData";
import { useAppDispatch } from '../../../../redux/hooks';
import { addProduct } from '../../../../redux/Products/Products';
import * as yup from 'yup';



export default function AddForm({ closeForm }: any) {
    const [selectedValue, setSelectedValue] = useState(0);
    
    const validationSchema = selectedValue === 1 ? null : yup.object({
        productName: yup.string().required('nom obligatoire'),
        reference: yup.string().required('reference obligatoire'),
        priceHT: yup.number().required('prix doit etre un nombre').min(1, 'Le prix doit être supérieur à zéro'),
        tva: yup.number().required('tva doit etre un nombre').min(1, 'La tva doit être supérieure à zéro'),
        capacity: yup.number().required('capacité doit etre un nombre').min(1, 'La capacité doit être supérieure à zéro'),
    });
    
    const dispatch = useAppDispatch()

    const handleChange = (event: any) => {
        setSelectedValue(event.target.value);
    };

    const formik = useFormik({
        initialValues: {
            "productName": "",
            "reference": "",
            "status": 1,
            "priceHT": 0,
            "tva": 0,
            "priceTTC": 0,
            "capacity": 0,
            "isVirtual": false,
            "businessUnits": [

            ]
        },
        validationSchema: validationSchema,
        onSubmit: (values) => {
            if (selectedValue === 1) {
                values.priceHT = 0
                values.priceTTC = 0
                values.tva = 0
                values.capacity = String("0")
                values.reference = null
            }
            values.priceTTC = (values.priceHT * values.tva / 100) + values.priceHT
            //@ts-ignore
            values.businessUnits = [getConnectedUserBu()[0] || null]
            values.isVirtual = selectedValue === 1
            values.tva = (values.tva / 100)
            dispatch(addProduct(values))
            closeForm(false)
        },
    });


    return (
        <>
            <div style={{ zIndex: 2, height: "100%", width: "100%", backgroundColor: "black", opacity: 0.3, position: "fixed", display: "flex", justifyContent: "center", alignItems: "center" }} className='cont'></div>
            <Grid style={{ display: "flex", position: "absolute", zIndex: 3, flex: 1, justifyContent: "center", alignItems: "center", width: "100%", overflow: "hidden" }} container>
                <div className='GridStart' style={{ marginTop: "8%", zIndex: 9, color: "white", backgroundColor: "white", width: "70%", borderRadius: 9 }}>
                    <Grid style={{ minHeight: 70, maxHeight: 70, borderBottom: "1px solid black", display: "flex", justifyContent: "center", alignItems: "center" }}>
                        <div style={{ color: "black", fontSize: "20px" }}>Ajouter un Produit/Action</div>
                    </Grid>
                    <Grid container style={{ flex: 1, justifyContent: "space-around", marginTop: 40 }}>
                        <Grid xs={3} item>
                            <TextField fullWidth id="productName"
                                name="productName"
                                value={formik.values.productName}
                                onChange={formik.handleChange}
                                error={formik.touched.productName && Boolean(formik.errors.productName)}
                                label="Nom" variant="outlined" />
                        </Grid>
                        <Grid xs={3} item>
                            <TextField fullWidth id="reference"
                                name="reference"
                                value={formik.values.reference}
                                onChange={formik.handleChange} label="Référence"
                                error={formik.touched.reference && Boolean(formik.errors.reference)}
                                variant="outlined" />
                        </Grid>
                        <Grid xs={3} item>
                            <FormControl fullWidth variant="outlined">
                                <InputLabel id="dropdown-label">Type</InputLabel>
                                <Select
                                    labelId="dropdown-label"
                                    id="dropdown"
                                    value={selectedValue}
                                    onChange={handleChange}
                                    label="Type"
                                >

                                    <MenuItem value={1}>action</MenuItem>
                                    <MenuItem value={0}>Produit</MenuItem>

                                </Select>
                            </FormControl>
                        </Grid>
                    </Grid>
                    <Grid container style={{ flex: 1, justifyContent: "space-around", marginTop: 40 }}>
                        {selectedValue === 0 ?
                            <>
                                <Grid xs={3} item>
                                    <TextField fullWidth
                                        id="tva"
                                        name="tva"
                                        value={formik.values.tva}
                                        onChange={formik.handleChange}
                                        error={formik.touched.tva && Boolean(formik.errors.tva)}
                                        label="TVA" variant="outlined" />
                                </Grid>
                                {/* <Grid xs={2} item>
                                    <TextField fullWidth
                                        id="priceTTC"
                                        name="priceTTC"
                                        value={formik.values.priceTTC}
                                        onChange={formik.handleChange}
                                        error={formik.touched.priceTTC && Boolean(formik.errors.priceTTC)}
                                        label="Prix TTC" variant="outlined" />
                                </Grid> */}
                                <Grid xs={3} item>
                                    <TextField fullWidth
                                        id="capacity"
                                        name="capacity"
                                        value={formik.values.capacity}
                                        onChange={formik.handleChange}
                                        error={formik.touched.capacity && Boolean(formik.errors.capacity)}
                                        label="Capacité" variant="outlined" />
                                </Grid>
                                <Grid xs={3} item>
                                    <TextField fullWidth
                                        id="priceHT"
                                        name="priceHT"
                                        value={formik.values.priceHT}
                                        error={formik.touched.priceHT && Boolean(formik.errors.priceHT)}
                                        onChange={formik.handleChange}
                                        label="Prix HT" variant="outlined" />
                                </Grid></> : <> <Grid xs={3} item>
                                    <TextField fullWidth
                                        id="tva"
                                        name="tva"
                                        value={parseInt(0)}
                                        disabled variant="outlined" />
                                </Grid>
                                {/* <Grid xs={2} item>
                                    <TextField fullWidth
                                        id="priceTTC"
                                        name="priceTTC"
                                        value={parseInt(0)}
                                        disabled variant="outlined" />
                                </Grid> */}
                                <Grid xs={3} item>
                                    <TextField fullWidth
                                        id="capacity"
                                        name="capacity"
                                        value={parseInt(0)}
                                        disabled variant="outlined" />
                                </Grid>
                                <Grid xs={3} item>
                                    <TextField fullWidth
                                        id="priceHT"
                                        name="priceHT"
                                        value={parseInt(0)}
                                        disabled variant="outlined" />
                                </Grid>
                            </>}
                    </Grid>

                    <Grid container style={{ flex: 1, justifyContent: "center", alignItems: "center", marginTop: 40, paddingBottom: 30 }}>
                        <Button style={{ color: "black", backgroundColor: "#e5e5ea", marginRight: 30, width: 120 }} variant="contained" color="primary" onClick={() => closeForm(false)}>annuler</Button>
                        <Button style={{ color: "white", backgroundColor: "black", width: 120 }} variant="contained" color="primary" onClick={() => formik.handleSubmit()}>ajouter</Button>
                    </Grid>
                </div>
            </Grid>

        </>


    )
}


