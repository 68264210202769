import React from "react";
import InfoWithIconTag from "../../../01-atoms/InfoWithIconTag/InfoWithIconTag";
import "./Details.scss";
import {
  mdiPackageVariantClosedCheck,
  mdiBookEditOutline,
  mdiPound,
} from "@mdi/js";
import CustomizedTables from "../../../02-molecules/tables/CustomizedTable";
import Tag from "../../../01-atoms/tags/Tag";
import { Order, OrderDetails } from "../../../../types/Order.model";

type Props = {
  requestState : "loading" | "idle";
  orderDetailsList: Order | null;
}

 const ordredCommendColumnsTitle: {
  title: string;
  align: "left" | "right" | "inherit" | "center" | "justify" | undefined;
}[] = [
  { title: "Réf", align: "left" },
  { title: "Produit", align: "left" },
  { title: "Quantité", align: "left" },
  { title: "P.U HT (DT)", align: "left" },
  { title: "TVA (%)", align: "left" },
  { title: "Gratuité", align: "left" },
];
//Function that format the orderDails in the right form for the customized table
const formatOrderDetailsArray = (orderDetailsArray: OrderDetails[]) => {
  if (orderDetailsArray && orderDetailsArray.length ) {
   return orderDetailsArray.map((el)=> {
      let { product, quantityFree,quantity } = el
      return (
        {
          ref:  product?.reference ? product.reference : "-",
          productName: product?.productName ? product.productName : "-",
          quantiity: quantity ? quantity : "-",
          pu: product?.priceHT  ? product.priceHT: "-",
          tva: product?.tva ? `${product.tva *100} %` : "-",
          gratuity:quantityFree ? quantityFree : "-" ,
        }
      )
    })
  } else return []
} 

export default function OrderDetailsList({orderDetailsList, requestState}:Props) {
  
  //Function that format order list data 
  const formatOrderDetailsData = () => {
     if (orderDetailsList && requestState === "idle") {
      let { commentaire, orderDetails, tagsReponse} = orderDetailsList
      return {
        comment :  commentaire ? commentaire : "",
        tagsReponse : tagsReponse && tagsReponse.length ? tagsReponse :  [],
        details :formatOrderDetailsArray(orderDetails),
      }
     } else return (
      {
        comment :  "",
        tagsReponse :  [],
        details : []
      }
     )
  } 

  return (
    <div className="order-detail-body-section2">
      <section>
        <InfoWithIconTag
          info={null}
          label={"Produits commandés"}
          icon={mdiPackageVariantClosedCheck}
          IconSize={0.9}
          labelClassName={"style3"}
          infoClassName={"style2"}
        />
      </section>
      <section>
        { <CustomizedTables
          columns={ordredCommendColumnsTitle}
          rows={formatOrderDetailsData().details}
        />}
      </section>
      <section>
        <InfoWithIconTag
          info={null}
          label={"Commentaire"}
          icon={mdiBookEditOutline}
          IconSize={0.9}
          labelClassName={"style3"}
          infoClassName={"style2"}
        />
        <div className="order-details-comment-section">
          {formatOrderDetailsData().comment}
        </div>
      </section>
      <section>
        <InfoWithIconTag
          info={null}
          label={"Tags"}
          icon={mdiPound}
          IconSize={0.9}
          labelClassName={"style3"}
          infoClassName={"style2"}
        />
        <div className="order-details-comment-section tag-wrapper">
          {
            formatOrderDetailsData().tagsReponse && formatOrderDetailsData().tagsReponse.length ? 
            formatOrderDetailsData().tagsReponse.map((tag)=> {
              return (
                <Tag tagName={tag.tagName}  key={tag.tagId}/>
              )
            }) : ""
          }
        </div>
      </section>
    </div>
  );
}
