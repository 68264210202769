import { facilitiestate } from "../../../../redux/Facility/facilitySlice";
import { useAppDispatch, useAppSelector } from "../../../../redux/hooks";
import { RootState } from "../../../../redux/store";

import { Facility } from "../../../../types/Facility.model";
import {
  toggleAddfacilityDrawer,
  togglefacilitiesFilterDrawer,
} from "../../../../redux/UI/uiSlice";

export interface facilityTableData {
  id: number;
  facilityType: string;
  name: string;
  phones: string;
  // services: string;
  locality1: string;
  locality2: string;
  locality3: string;
  locality4: string;
  // noteAdress: string;
  streetName : {streetName: string | null, addressNote: string | null}
}
const useFacilityTable = () => {
  const dispatch = useAppDispatch();
  const { facilitiesFilterDrawerIsOpen } = useAppSelector(
    (state: RootState) => state.UI
  );
  const facilitiestate = useAppSelector((state: RootState) => state.facility);

  const formatData: (fetchedData: Facility[]) => facilityTableData[] = (
    fetchedData
  ) => {
    return fetchedData.length > 0
      ? fetchedData.map((facility: Facility) => ({
          id: facility.facilityId,
          facilityType: facility.facilityType.facilityTypeName,
          name: facility.facilityName,
          sector: facility.sector ? "Privé" : "Public",
          phones: JSON.stringify(facility.phones),
          // services: JSON.stringify(facility.services),
          locality1:
            facility.address.localities.find((e) => e.level === 1)
              ?.localityName || "",
          locality2:
            facility.address.localities.find((e) => e.level === 2)
              ?.localityName || "",
          locality3:
            facility.address.localities.find((e) => e.level === 3)
              ?.localityName || "",
          locality4:
            facility.address.localities.find((e) => e.level === 4)
              ?.localityName || "",
          streetName: {streetName: facility?.address?.streetName , addressNote : facility?.address?.addressNote} ,
          noteAdress: facility.address.addressNote || "",
        }))
      : [];
  };

  const prepareDatabase = (facilitiestate: facilitiestate) => {
    return {
      data: formatData(facilitiestate.filteredFacilities.data),
      dataResquestState: facilitiestate.requestState || "idle",
      errors: facilitiestate.errors || null,
      currentPageNumber:
        facilitiestate.filteredFacilities.pagination.pageNumber,
      pageSize: facilitiestate.filteredFacilities.pagination.pageSize,
      totalCount: facilitiestate.filteredFacilities.pagination.totalCount,
      filterDrawerIsOpen: facilitiesFilterDrawerIsOpen,
      toggleFilterDrawer: () => dispatch(togglefacilitiesFilterDrawer()),
      toggleAddDrawer: () => dispatch(toggleAddfacilityDrawer()),
    };
  };

  const {
    data,
    dataResquestState,
    errors,
    currentPageNumber,
    pageSize,
    totalCount,
    filterDrawerIsOpen,
    toggleFilterDrawer,
    toggleAddDrawer,
  } = prepareDatabase(facilitiestate);

  const columns = [
    {
      key: "facilityType",
      label: "Type",
      width: 150,
      fixed: false,
      fullWidth: false,
      type: "textCell",
      sortable: true,
      checked: true,
      role: "Informations générales",
    },
    {
      key: "name",
      label: "Établissement",
      width: 350,
      fixed: false,
      fullWidth: false,
      type: "textCell",
      sortable: true,
      checked: true,
      role: "Informations générales",
    },
    {
      key: "sector",
      label: "Secteur",
      width: 150,
      fixed: false,
      fullWidth: false,
      type: "textCell",
      sortable: true,
      checked: true,
      role: "Informations générales",
    },
    {
      key: "phones",
      label: "Téléphones",
      width: 200,
      fixed: false,
      fullWidth: false,
      type: "phoneCell",
      sortable: false,
      checked: true,
      role: "Informations générales",
    },
    {
      key: "locality1",
      label: "Gouvernorat",
      width: 140,
      fixed: false,
      fullWidth: false,
      type: "textCell",
      sortable: true,
      checked: true,
      role: "Localisation",
    },
    {
      key: "locality2",
      label: "Délégation",
      width: 180,
      fixed: false,
      fullWidth: false,
      type: "textCell",
      sortable: true,
      checked: true,
      role: "Localisation",
    },
    {
      key: "locality3",
      label: "Code postal",
      width: 140,
      fixed: false,
      fullWidth: false,
      type: "textCell",
      sortable: true,
      checked: false,
      role: "Localisation",
    },
    {
      key: "locality4",
      label: "Localité",
      width: 200,
      fixed: false,
      fullWidth: false,
      type: "textCell",
      sortable: true,
      checked: false,
      role: "Localisation",
    },
    {
      key: "streetName",
      label: "Adresse",
      width: 400,
      fixed: false,
      fullWidth: false,
      type: "addressCell",
      sortable: true,
      checked: true,
      role: "Localisation",
    },
    // {
    //   key: "noteAdress",
    //   label: "Complément d'adresse",
    //   width: 320,
    //   fixed: false,
    //   type: "textCell",
    //   checked: false,
    // },
    // {
    //   key: "services",
    //   label: "Services",
    //   width: 220,
    //   fixed: false,
    //   type: "textCell",
    //   checked: false,
    // },
  ];

  return {
    data,
    columns,
    dataResquestState,
    errors,
    currentPageNumber,
    pageSize,
    totalCount,
    filterDrawerIsOpen,
    toggleFilterDrawer,
    toggleAddDrawer,
  };
};

export default useFacilityTable;
