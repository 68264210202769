import { Tooltip } from "@material-ui/core";
import React from "react";
import "./PhalconCell.scss";
type Props = {
  item: string;
  textTransform?:
    | "none"
    | "capitalize"
    | "uppercase"
    | "lowercase"
    | "full-width"
    | "full-size-kana";
  toolTipeText?: string;
  textBoldness?: string;
  color?: string;
};

const TextCell = ({
  item,
  textTransform,
  toolTipeText,
  textBoldness,
  color,
}: Props) => {
  return (
    <Tooltip title={toolTipeText ? toolTipeText : ""} placement="top">
      <div
        className="phalcon-text-cell"
        style={{
          textTransform: textTransform ? textTransform : "capitalize",
          fontWeight: textBoldness ? textBoldness : 400,
          color: color ? color : undefined,
        }}
      >
        {item}
      </div>
    </Tooltip>
  );
};

export default TextCell;
