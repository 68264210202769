import React from "react";
import { useTranslation } from "react-i18next";
import { Button } from "rsuite";
import { useAppDispatch, useAppSelector } from "../../../../redux/hooks";
import { RootState } from "../../../../redux/store";
import { toggleUpdatePhotoModal } from "../../../../redux/UI/uiSlice";

import { CustomAvatar } from "../../../01-atoms/CustomAvatar/CustomAvatar";
import { UpdatePasswordForm } from "../../../03-organisms/forms/user-account/UpdatePasswordForm";
import UserForm from "../../../03-organisms/forms/user-account/UserForm";
import "./UserSettings.scss";
interface Props {}

export const UserSettings = (props: Props) => {
  const { currentUser } = useAppSelector((state: RootState) => state.user);
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  return (
    <div className="user-infos__wrapper">
      <div className="custom-form-section">
        <h3 className="form-block-title">
          {t("account_page.user_settings.avatar_section_title")}
        </h3> 
        <div className="avatar-edit">
          <div className="avatar">
            <CustomAvatar
              image={{
                src: currentUser.photoUrl ? currentUser.photoUrl : "", //to do
                alt: `${currentUser.firstName} ${currentUser.lastName}'s avatar`,
              }}
              style={{ width: "75px", height: "75px" }}
            />
            <Button
              appearance="primary"
              type="submit"
              className="form-button-dark"
              onClick={() => dispatch(toggleUpdatePhotoModal())}
            >
              {t("account_page.forms.update_photo_button")}
            </Button>
          </div>
        </div>
      </div>

      <div style={{ marginTop: "1rem" }}>
        <UserForm />
      </div>

      <UpdatePasswordForm />
    </div>
  );
};
