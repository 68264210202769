import {
  mdiCalendarRange,
  mdiAccountGroup,
  mdiDoctor,
  mdiEye,
  mdiMapMarkerOutline,
  mdiAccountSwitchOutline,
} from "@mdi/js";
import Icon from "@mdi/react";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { CustomAvatar } from "../../../01-atoms/CustomAvatar/CustomAvatar";
import "./VisitCard.scss";
import { VisitInfoCard } from "./VisitInfoCard";
import { useAppDispatch, useAppSelector } from "../../../../redux/hooks";
import { RootState } from "../../../../redux/store";
import { fetchVisitById } from "../../../../redux/Visit/visitSlice";
import { ReportSummary } from "../../../../types/ReportSummary.model";
import { getLocalDate } from "../../../../utils/dateHelpers";
import { getChannelIconPath } from "../../../../utils/getChannelIconPath";
import ListStack from "../../list-stack/ListStack";
// import { Report } from "../../../../types/Report.model";

interface Props {
  visit: ReportSummary;
  numberOfDisplayedProduct: number;
  customizedWidth: string;
}

const prepareDoctorIcon = (
  substituteName: string | undefined,
  participantNumbers: number | undefined
) => {
  let icon = mdiDoctor;
  if (participantNumbers) {
    icon = mdiAccountGroup;
  } else if (substituteName) {
    icon = mdiAccountSwitchOutline;
  }
  return <Icon path={icon} size={1.1} />;
};

/** Function for selecting client icon  */
export const VisitCard = ({ visit , numberOfDisplayedProduct, customizedWidth}: Props) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { visitById, requestState } = useAppSelector(
    (state: RootState) => state.visit
  );

  const [visitInfoCardIsOpen, setVisitInfoCardIsOpen] =
    useState<boolean>(false);

  const handleFetchVisitById = (id: number) => {
    dispatch(fetchVisitById(id));
  };
  const preparePresentetdProduct = (
    presentedProduct: { productId: number; productName: string }[]
  ) => {
    if (presentedProduct && presentedProduct.length) {
      return presentedProduct.map((element) => {
        return {
          id: element.productId,
          name: element.productName,
        };
      });
    } else {
      return [];
    }
  };
  return (
    <>
      <div className="visit-card__wrapper" key={"visit" + visit?.reportId}>
        <div className="visit-card__main">
          <div className="visit-card__content">
            <div className="visit-card__content--top">
              <div className="visit-card__person">
                <div className="visit-card__person--avatar">
                  <div className="icon">
                    {/* { to handle the doctor icon : the icon switch depends of the presence of substitute or participant} */}
                    {/* {prepareDoctorIcon(visit.substitute?.firstName, visit?.participants?.length)} */}
                    {prepareDoctorIcon("", 0)}
                  </div>
                </div>
                <div>
                  <div className="visit-card__person--name">
                    {`${visit?.weekContent?.doctor?.title || "-"} ${
                      visit?.weekContent?.doctor?.firstName || "-"
                    } ${visit?.weekContent?.doctor?.lastName || "-"}`}
                  </div>
                  <div className="visit-card__person--spec">
                    {visit?.weekContent?.doctor?.specialities?.find(
                      (speciality) => speciality.isPrincipal
                    )?.specialityName || ""}
                    -
                    <span className="potential-noborder-color pot1">
                      {" " +
                        visit.weekContent?.doctor?.potential?.potentialName ||
                        ""}
                    </span>
                  </div>
                </div>
              </div>
              <div className="visit-card__products" style={{width: customizedWidth}}>
                <h4 style={{ marginBottom: "0.4rem" }}>{`${t(
                  "cards.event.products"
                )} `}</h4>
                {visit.presentedProducts && visit.presentedProducts.length ? (
                  <ListStack
                    listName="Liste des produits présentés"
                    item={preparePresentetdProduct(visit.presentedProducts)}
                    numberOfDisplayedProduct={numberOfDisplayedProduct}
                  />
                ) : (
                  <div className="no-product"> - </div>
                )}
              </div>
            </div>
            <div className="visit-card__content--bottom">
              <div className="visit-card-infos">
                <div className="icon">
                  {visit.location &&
                  visit.location.address &&
                  visit.location.address.localities &&
                  visit.location.address.localities.length ? (
                    <Icon path={mdiMapMarkerOutline} size={1} />
                  ) : (
                    <Icon
                      path={getChannelIconPath(visit.channel?.channelId)}
                      size={1}
                    />
                  )}
                </div>
                <div className="address">
                  {visit.location &&
                  visit.location.address &&
                  visit.location.address.localities &&
                  visit.location.address.localities.length
                    ? `${visit.location.address.localities[0]?.localityName}, ${visit.location.address.localities[1]?.localityName} `
                    : visit.channel?.channelName}
                </div>
              </div>
              <div className="visit-card-infos" style={{ columnGap: "2rem", width: customizedWidth }}>
                <div className="visit-card-date">
                  <div className="icon">
                    <Icon path={mdiCalendarRange} size={0.75} />
                  </div>
                  <div className="content">
                    {visit?.date ? getLocalDate(visit.date) : "-"}
                  </div>
                </div>
                <div className="visit-card__person">
                  <div className="visit-card__person--avatar">
                    <CustomAvatar
                      image={{
                        src: `${visit?.delegate?.photoUrl || ""}`,
                        alt: `${visit?.delegate?.firstName || ""}`,
                      }}
                      style={{ width: "25px", height: "25px" }}
                    />
                  </div>
                  <div className="visit-card__person--name">{`${
                    visit?.delegate?.firstName || "-"
                  } ${visit?.delegate?.lastName || "-"}`}</div>
                </div>
              </div>
            </div>
          </div>
          <div className="visit-card__button">
            <div
              className="icon"
              onClick={() => {
                handleFetchVisitById(visit.reportId);
                setVisitInfoCardIsOpen(true);
              }}
            >
              <Icon path={mdiEye} size={0.75} color={"rgb(97, 175, 254)"} />
            </div>
          </div>
        </div>
      </div>
      <VisitInfoCard
        visitInfoCardIsOpen={visitInfoCardIsOpen}
        setVisitInfoCardIsOpen={setVisitInfoCardIsOpen}
        visitDetails={visitById}
        requestState={requestState}
      />
    </>
  );
};
