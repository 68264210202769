import { useState } from "react";
import "./PlanificationGroupBy.scss";
import { PlanificationWeek } from "../../../types/PlanificationWeek.model";
import { DataGrid, GridColumns } from "@material-ui/data-grid";
import localizedTextsMap from "../../../utils/dataGridColumnMenuLanguage";
import { DoctorSummary } from "../../../types/DoctorSummary.model";
interface Props {
  planificationWeek: PlanificationWeek;
  setInnerSelected: React.Dispatch<React.SetStateAction<DoctorSummary[]>>;
}

const columns: GridColumns = [ 
  {
    field: "name",
    headerName: "Nom et Prénom",
    width: 300,
    resizable: true,
  },
  {
    field: "potential",
    headerName: "Pot.",
    width: 110,
    description: "Potentiel",
  },
  {
    field: "speciality",
    headerName: "Spéc.",
    width: 115,
    description: "Spécialité",
  },
  {
    field: "level1",
    headerName: "Gouv.",
    width: 180,
    description: "Gouvernorat",
  },
  {
    field: "level2",
    headerName: "Délég.",
    width: 180,
    description: "Délégation",
  },
  {
    field: "facilityType",
    headerName: "Type Etab.",
    width: 150,
    description: "Type d'établissement",
  },
  {
    field: "facilityName",
    headerName: "Etab.",
    width: 300,
    resizable: true,
    description: "Etablissement",
  },
];

const PlanificationGroupByInner = ({
  planificationWeek,
  setInnerSelected,
}: Props) => {
  const formatData = planificationWeek.weekContent.map((e) => {
    const {
      title,
      firstName,
      lastName,
      potential,
      specialities,
      principalAddress,
    } = e.doctor;

    return {
      id: JSON.stringify(e.doctor),
      name: `${title} ${firstName} ${lastName}`,
      potential: potential?.potentialName || "",
      speciality: specialities[0]?.abreviation || "",
      specialityName: specialities[0]?.specialityName || "",
      facilityName: principalAddress?.facilityName || "",
      facilityType:
        principalAddress?.facilityType?.facilityTypeName || "Cabinet",
      level1: principalAddress?.address?.localities[0]?.localityName || "",
      level2: principalAddress?.address?.localities[1]?.localityName || "",
    };
  });
  const [rowsPerPage, setRowsPerPage] = useState<number>(25);

  return (
    <DataGrid 
      headerHeight={52}
      rows={formatData}
      columns={columns}
      pageSize={rowsPerPage}
      rowsPerPageOptions={[10, 25, 50, 100]} 
      onPageSizeChange={(newPageSize) => setRowsPerPage(newPageSize)}
      checkboxSelection
      onSelectionModelChange={(selectionModel) => {
        setInnerSelected(selectionModel.map((e) => JSON.parse(e as string)));
      }}
      localeText={localizedTextsMap}
    />
  );
};

export default PlanificationGroupByInner;
