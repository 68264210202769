import React from "react";
import ToolTip from "../../../../01-atoms/ToolTip/ToolTip";
import "./PhalconCell.scss";
type Props = {
  streetName: string;
  addressNote?: string;
};

const AddressCell = ({ streetName, addressNote }: Props) => {
  return (
    <div className="phalcon-address-cell">
      <div style={{color: "#111111"}}> {streetName}</div>
      {addressNote ? (
        <div>
          <ToolTip
            type="info"
            message={addressNote || ""}
            style={{
              width: "14px",
              color: "var(--rs-text-secondary)",
              marginLeft: "10px",
              display: "inline-flex",
              marginTop: "2px",
            }}
          />
        </div>
      ) : (
        ""
      )}
    </div>
  );
};

export default AddressCell;
