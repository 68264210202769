import { AuthorizationState } from "../../../../redux/Authorization/authorizationSlice";
import { useAppDispatch, useAppSelector } from "../../../../redux/hooks";
import { RootState } from "../../../../redux/store";
import { Authorization } from "../../../../types/Authorization.model";
import {
  fetchAuthorizations,
  activeAuthorozation,
  deactiveAuthorozation,
} from "../../../../redux/Authorization/authorizationSlice";
import { useEffect } from "react";

export interface AuthenticationManagementTableData {
  id: number;
  delegate: {
    firstName: string;
    lastName: string;
    userId: number;
    photoUrl: string;
  };
  startDate: string;
  endDate: string;
  expirationDate: string;
  isActive: boolean;
}

const useAuthorizationManagementTable = () => {
  const dispatch = useAppDispatch();
  const authorizationState = useAppSelector(
    (state: RootState) => state.authoriation
  );

  useEffect(() => {
    dispatch(fetchAuthorizations());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const formatData: (
    fetchedData: Authorization[]
  ) => AuthenticationManagementTableData[] = (fetchedData) => {
    return fetchedData && fetchedData.length > 0
      ? fetchedData.map((data) => ({
          id: data.unlockAuthorizationId,
          delegate: {
            firstName: data.user?.firstName || "",
            lastName: data.user?.lastName || "",
            userId: data.user?.userId || 0,
            photoUrl: data.user?.photoUrl || "",
          },
          startDate: data.startDate,
          endDate: data.endDate,
          expirationDate: data.expirationDate,
          isActive: data.isActive,
        }))
      : [];
  };

  const prepareAuthorizationData = (authorizationState: AuthorizationState) => {
    return {
      data: formatData(authorizationState.authorizations),
      dataResquestState: authorizationState.requestState || "idle",
      error: authorizationState.errors || null,
    };
  };

  const handleAuthorizationStatus = (
    isActive: boolean,
    authorizationId: number
  ) => {
    if (isActive) {
      dispatch(deactiveAuthorozation(authorizationId));
    } else {
      dispatch(activeAuthorozation(authorizationId));
    }
  };

  
  const { data, dataResquestState, error } =
    prepareAuthorizationData(authorizationState);
  return {
    data,
    dataResquestState,
    error,
    handleAuthorizationStatus,
  };
};

export default useAuthorizationManagementTable;
