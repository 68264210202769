import React, { createRef } from "react";
import { Controller } from "react-hook-form";
import { Form, Col, CheckPicker } from "rsuite";
import { CustomErrorMessage } from "../ErrorMessage/CustomErrorMessage";
import "./ProductsField.scss";

type ProductItem = {
  label: string;
  value: number;
};
type Props = {
  control: any;
  errors: any;
  label: string;
  inputName: string;
  xs?: number;
  md?: number;
  lg?: number;
  data: ProductItem[];
  className?: string;
  hiddenProducts: number[];
};

export const ProductsField = ({
  label,
  control,
  inputName,
  errors,
  xs,
  md,
  lg,
  data,
  className,
  hiddenProducts,
}: Props) => {
  const productsInputRef: any = createRef();

  const renderElement = (element: any) => {
    const { name } = JSON.parse(element.label);
    return (
      <div className="product-element">
        <span>{name}</span>
      </div>
    );
  };

  return (
    <Col
      xs={xs || 24}
      md={md}
      lg={lg}
      style={{ marginBottom: "1rem" }}
      className={className}
    >
      <Form.Group>
        <Form.ControlLabel>{label}</Form.ControlLabel>

        <Controller
          control={control}
          name={inputName}
          render={({ field: { onChange, name, value } }) => (
            <Form.Control
              ref={productsInputRef}
              accepter={CheckPicker}
              name={name}
              value={value}
              searchable
              open
              className="input-full-width products-field"
              data={data}
              container={() => productsInputRef.current}
              renderMenuItem={(nodeData: any, item: any) => renderElement(item)}
              disabledItemValues={hiddenProducts}
              placement="autoHorizontal"
              onChange={(e: any) => {
                onChange(e);
              }}
            />
          )}
        />
        <CustomErrorMessage errors={errors} name={inputName} />
      </Form.Group>
    </Col>
  );
};
