import { Divider, IconButton, Tooltip } from "@material-ui/core";
import {
  mdiContentCopy,
  mdiContentCut,
  mdiContentPaste,
  mdiEraser,
  mdiMapMarkerOutline,
  mdiPencil,
  mdiPlus,
  mdiRedo,
  mdiUndo,
} from "@mdi/js";
import { Button, ButtonGroup } from "rsuite";
import { useAppDispatch } from "../../../redux/hooks";
import {
  togglePlanificationConfigModal,
  updatePlanification,
} from "../../../redux/Planification/planificationSlice";
import { togglePlanificationModal } from "../../../redux/Planification/planificationSlice";
import { DoctorSummary } from "../../../types/DoctorSummary.model";

import { Frequency } from "../../../types/Frequency.model";
import { Planification, UpdatePlanification } from "../../../types/Planification.model";
import { PlanificationWeek } from "../../../types/PlanificationWeek.model";
import { CustomIconButton } from "../../01-atoms/Buttons/Buttons";
import { StandardIcon } from "../../01-atoms/Icons/Icons";
import "./PlanificationSubheader.scss";
type Props = {
  userAddress: string;
  activeUpdatedState: Planification | null;
  activeWeek?: PlanificationWeek;
  innerSelected: DoctorSummary[];
  setInnerSelected: React.Dispatch<React.SetStateAction<DoctorSummary[]>>;
  cycleConfig: {
    label: string;
    frequencies: Frequency[];
    weeksNumber: number | null;
  };
  handleCopy: (innerWeekSelection: DoctorSummary[]) => void;
  handleCut: (
    activePlanificationWeek: PlanificationWeek,
    innerWeekSelection: DoctorSummary[]
  ) => void;
  handlePaste: (activePlanificationWeek: PlanificationWeek) => void;
  handleReset: (activePlanificationWeek: PlanificationWeek) => void;
  handleRedo: () => void;
  handleUndo: () => void;
  redoIsActive: boolean;
  undoIsActive: boolean;
  copyIsActive: boolean;
  cutIsActive: boolean;
  pasteIsActive: boolean;
  assignDone: boolean;
  resetIsActive: boolean;
  requestState: "idle" | "loading";
};
const prepareUpdatePlanificationPayload = (activeUpdatedState : Planification) => {
  return {
    planificationId: activeUpdatedState?.planificationId,
    weeks: activeUpdatedState?.planificationWeeks?.map((week)=> {
      return {
        weekId: week.weekId,
        weekContents : week.weekContent
      }
    })
  }
}

const PlanificationSubheader = ({
  userAddress,
  cycleConfig,
  activeWeek,
  handleCopy,
  handleCut,
  handlePaste,
  handleReset,
  innerSelected,
  setInnerSelected,
  handleRedo,
  handleUndo,
  redoIsActive,
  undoIsActive,
  copyIsActive,
  cutIsActive,
  pasteIsActive,
  resetIsActive,
  assignDone,
  activeUpdatedState,
  requestState,
}: Props) => {
  const dispatch = useAppDispatch();
  return (
    <div className="planification-subheader">
      <div className="planification-subheader--left">
        <StandardIcon icon={mdiMapMarkerOutline} />
        <div className="userAddress">{userAddress}</div>
      </div>
      <div className="planification-subheader--right">
        <div className="week-infos-area">
          <div className="week-number">
            S{activeWeek?.weekNumberPlanification || "-"}
          </div>
          <div className="week-number">
            Total {activeWeek?.weekContent.length}
          </div>
        </div>
        <div className="week-actions-button">
          <ButtonGroup className="week-button__inner">
            <Tooltip title="Vider la semaine actuelle">
              <IconButton
                disabled={!resetIsActive}
                size="small"
                onClick={() => {
                  resetIsActive && activeWeek && handleReset(activeWeek);
                }}
              >
                <CustomIconButton disabled={!resetIsActive} icon={mdiEraser} />
              </IconButton>
            </Tooltip>
            <Divider
              orientation="vertical"
              flexItem
              style={{ marginLeft: "1rem", marginRight: "1rem" }}
            />
            <Tooltip title="Couper">
              <IconButton
                disabled={!cutIsActive}
                size="small"
                onClick={() => {
                  cutIsActive && setInnerSelected([]);
                  cutIsActive &&
                    activeWeek &&
                    handleCut(activeWeek, innerSelected);
                }}
              >
                <CustomIconButton
                  icon={mdiContentCut}
                  disabled={!cutIsActive}
                />
              </IconButton>
            </Tooltip>
            <Tooltip title="Copier">
              <IconButton
                disabled={!copyIsActive}
                size="small"
                onClick={() => {
                  handleCopy(innerSelected);
                }}
              >
                <CustomIconButton
                  disabled={!copyIsActive}
                  icon={mdiContentCopy}
                />
              </IconButton>
            </Tooltip>
            <Tooltip title="Coller">
              <IconButton
                disabled={!pasteIsActive}
                size="small"
                onClick={() => {
                  pasteIsActive && activeWeek && handlePaste(activeWeek);
                }}
              >
                <CustomIconButton
                  disabled={!pasteIsActive}
                  icon={mdiContentPaste}
                />
              </IconButton>
            </Tooltip>
            <Tooltip title="Modifier">
              <IconButton
                size="small"
                onClick={() => {
                  dispatch(togglePlanificationModal());
                }}
              >
                <CustomIconButton
                  icon={
                    activeWeek && activeWeek.weekContent.length === 0
                      ? mdiPlus
                      : mdiPencil
                  }
                />
              </IconButton>
            </Tooltip>

            <Divider
              orientation="vertical"
              flexItem
              style={{ marginLeft: "1rem", marginRight: "1rem" }}
            />
            <Tooltip title="Annuler">
              <IconButton
                disabled={!undoIsActive}
                size="small"
                onClick={() => {
                  undoIsActive && handleUndo();
                }}
              >
                <CustomIconButton icon={mdiUndo} disabled={!undoIsActive} />
              </IconButton>
            </Tooltip>
            <Tooltip title="Refaire">
              <IconButton
                disabled={!redoIsActive}
                size="small"
                onClick={() => {
                  redoIsActive && handleRedo();
                }}
              >
                <CustomIconButton icon={mdiRedo} disabled={!redoIsActive} />
              </IconButton>
            </Tooltip>

            <Divider
              orientation="vertical"
              flexItem
              style={{ marginLeft: "1rem", marginRight: "1rem" }}
            />
          </ButtonGroup>
          <Button
            className="database-submit-button"
            type="submit"
            appearance="primary"
            onClick={() => {
              if (!assignDone && activeUpdatedState) {
                let updatePlanificationPayload : UpdatePlanification = prepareUpdatePlanificationPayload(activeUpdatedState)
                dispatch(
                  updatePlanification(updatePlanificationPayload)
                );
              } else {
                dispatch(togglePlanificationConfigModal());
              }
            }}
            loading={requestState !== "idle"}
          >
            {"Sauvegarder"}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default PlanificationSubheader;
