import {
  mdiEmoticonOutline,
  mdiEmoticonAngryOutline,
  mdiEmoticonSadOutline,
  mdiEmoticonHappyOutline,
  mdiEmoticonNeutralOutline,
} from "@mdi/js";

export const getSatisfactionIconPath = (
  level: number | undefined | boolean
) => {
  switch (level) {
    case 1:
      return mdiEmoticonSadOutline;
    case 2:
      return mdiEmoticonNeutralOutline;
    case 3:
      return mdiEmoticonHappyOutline;
    case 4:
      return mdiEmoticonOutline;
    case true:
      return mdiEmoticonOutline;
    default:
      return mdiEmoticonAngryOutline;
  }
};
