//@ts-nocheck
/* eslint-disable */
import React, { useState, useEffect, useMemo } from 'react'
import { Button, FormControl, Grid } from "@material-ui/core"
import { TextField, InputLabel, MenuItem, Select, OutlinedInput } from '@material-ui/core';
// import { SelectPicker } from 'rsuite';
import { useFormik } from 'formik';
import { getConnectedUserBu } from "../../../../utils/getConnectedData";
import { useAppDispatch, useAppSelector } from '../../../../redux/hooks';
import { addProduct } from '../../../../redux/Products/Products';
import * as yup from 'yup';
import { getGovernorates, getLocalitiesOptions } from '../../../../redux/Locality/localitySlice';
import { DatePicker } from 'rsuite';
import { createUserThunk } from '../../../../redux/users/users';
import errorToast from '../../../../utils/errorToast';
import { resetLocsOpt } from '../../../../redux/Locality/localitySlice';


export default function AddForm({ closeForm }: any) {
    const dispatch = useAppDispatch()
    const Bs = useAppSelector(state => state.general.businessUnits)
    const governorates = useAppSelector(state => state.locality.governorates)
    const { localitiesOptions } = useAppSelector(state => state.locality)
    const BU = getConnectedUserBu()[0]
    ///////////////////////////////////////////////////
    const [reppass, setreppass] = useState('')
    const [pass, setpass] = useState('')
    const [selectedDate, setSelectedDate] = useState(null);
    ////////////////////////////////////////////
    const [firstSelectValue, setFirstSelectValue] = useState("");
    const [secondSelectValue, setSecondSelectValue] = useState("");
    const [thirdSelectValue, setThirdSelectValue] = useState();
    const [fourthSelectValue, setFourthSelectValue] = useState()
    const [postalCodes, setPostalCodes] = useState([])
    const [locs, setLocs] = useState([])
    ////////////////////////////////////
    const [hireDate, setHireDate] = useState(null)
    const [gender, setgender] = useState(0)
    const [Bu, setBu] = useState(BU)
    const [gov, setGov] = useState(1)
    /////////////////////////////////////////
    const handleFirstSelectChange = (event) => {
        setFirstSelectValue(event.target.value);
        setSecondSelectValue("")
        setThirdSelectValue("")
        setFourthSelectValue("")
    };

    const handleSecondSelectChange = (event) => {
        setSecondSelectValue(event.target.value);
        setThirdSelectValue("")
        setFourthSelectValue("")
    };
    const handleThirdSelectChange = (event) => {
        setThirdSelectValue(event.target.value);
        setFourthSelectValue("")
    };
    const handleFourthSelectChange = (event) => {
        setFourthSelectValue(event.target.value);
    };


    useEffect(() => {
        dispatch(getGovernorates())
        dispatch(resetLocsOpt())
    }, [dispatch])

    useEffect(() => {
        if (firstSelectValue) {
            dispatch(getLocalitiesOptions(firstSelectValue));
        }
        console.log(localitiesOptions)
    }, [firstSelectValue, dispatch]);

    useEffect(() => {
        console.log("second", secondSelectValue)
        if (secondSelectValue) {
            let postals = localitiesOptions[0].filter(item => (item.localityId === secondSelectValue))[0]?.subLocalities
            setPostalCodes(postals)

        }

    }, [secondSelectValue]);

    useEffect(() => {
        console.log("second", secondSelectValue)
        if (thirdSelectValue) {
            let locs = postalCodes?.filter(item => (item.localityId === thirdSelectValue))[0]?.subLocalities
            setLocs(locs)
        }
    }, [thirdSelectValue]);


    const validationSchema = yup.object({
        userName: yup
            .string()
            .required('Nom d\'utilisateur est obligatoire'),
        password: yup
            .string()
            .required('Mot de passe est obligatoire'),
        repeatPassword: yup.string()
            .oneOf([yup.ref('password'), null], 'Passwords must match')
            .required('Repeat password is required'),

        firstName: yup
            .string()
            .required('Prénom est obligatoire'),
        lastName: yup
            .string()
            .required('Nom est obligatoire'),
        email: yup
            .string()
            .email('L\'adresse email est invalide')
            .required('L\'adresse email est obligatoire'),
        gender: yup
            .number()
            .required('Veuillez sélectionner le sexe'),
        businessUnits: yup
            .array()
            .of(
                yup.object().shape({
                    businessUnitId: yup.number().required('L\'unité commerciale est requise')
                })
            ),
        phones: yup
            .array()
            .of(
                yup.object().shape({
                    phoneNumber: yup.number().required('Le numéro de téléphone est requis')
                })
            ),
        

        registrantionNumber: yup
            .string()
            .required('Le numéro d\'enregistrement est requis')
    });






    const handleGenderChange = (event: any) => {
        setgender(event.target.value);
    };
    const handleBuChange = (event: any) => {
        setBu(event.target.value);
    };

    const formik = useFormik({
        initialValues: {
            "password": "",
            'repeatPassword': '',
            "userName": "",
            "firstName": "",
            "lastName": "",
            "email": "",
            "gender": 0,
            "photoUrl": null,
            "role": 3,
            "businessUnits": [
                {
                    "businessUnitId": 1
                }
            ],
            "phones": [
                {

                    "phoneNumber": "",
                    "phoneDescription": "",
                    "phoneType": 0,
                    "isPrincipal": true
                }
            ],
            "address": {

                "streetName": null,
                "addressNote": "",
                "localities": [

                ]
            },
            "birthDate": null,
            "hireDate": null,
            "registrantionNumber": ""
        },
        validationSchema: validationSchema,
        onSubmit: (values) => {
            if ((!thirdSelectValue || thirdSelectValue === "Aucun") || (!fourthSelectValue || fourthSelectValue === "Aucun")) {
                errorToast("Les champs de localité sont requis.")
            }
            else if (!values.address.streetName) {
                errorToast("Champ Adresse est requis.")
            }
            else if (!selectedDate) {
                errorToast("Champ Date de naissance est requis.")
            } else if (!hireDate) {
                errorToast("Champ Date de recrutement est requis.")
            }
            else {
                values.address.localities.push({
                    "localityId": firstSelectValue,
                    "localityName": "",
                    "level": 1,
                    "levelName": 1
                })
                values.address.localities.push({
                    "localityId": secondSelectValue,
                    "localityName": "",
                    "level": 2,
                    "levelName": 2
                })
                values.address.localities.push({
                    "localityId": thirdSelectValue,
                    "localityName": "",
                    "level": 3,
                    "levelName": 3
                })
                values.address.localities.push({
                    "localityId": fourthSelectValue,
                    "localityName": "",
                    "level": 4,
                    "levelName": 4
                })
                console.log(values.address.localities)
                values.birthDate = selectedDate ? selectedDate : new Date()
                values.hireDate = hireDate ? hireDate : new Date()
                values.gender = gender
                values.businessUnits[0].businessUnitId = Bu
                delete values.repeatPassword
                console.log(values)
                dispatch(createUserThunk(values))
                closeForm(false)
            }

        },
    });



    return (
        <>
            <div style={{ zIndex: 2, height: "100%", width: "100%", backgroundColor: "black", opacity: 0.3, position: "fixed", display: "flex", justifyContent: "center", alignItems: "center" }} className='cont'></div>
            <Grid style={{ marginTop: 40, display: "flex", position: "absolute", zIndex: 3, flex: 1, justifyContent: "center", alignItems: "center", width: "100%", overflow: "hidden" }} container>
                <div className='GridStart' style={{ marginTop: "1%", zIndex: 9, color: "white", backgroundColor: "white", width: "80vw", borderRadius: 9, }}>
                    <Grid style={{ minHeight: 70, maxHeight: 70, borderBottom: "1px solid black", display: "flex", justifyContent: "center", alignItems: "center" }}>
                        <div style={{ color: "black", fontSize: "20px" }}>Ajouter un Utilisateur</div>
                    </Grid>
                    <Grid style={{ height: "60vh", overflow: "auto", scrollbarWidth: 0 }}>
                        <Grid container wrap="wrap" style={{ flex: 1, justifyContent: "space-around", marginTop: 40, padding: 20 }}>
                            <Grid xs={3} item>
                                <TextField fullWidth id="userName"
                                    name="userName"
                                    value={formik.values.userName}
                                    onChange={formik.handleChange} label="Nom d'utilisateur"
                                    error={formik.touched.userName && Boolean(formik.errors.userName)}
                                    variant="outlined" />
                            </Grid>
                            <Grid xs={3} item>
                                <TextField fullWidth id="password"
                                    name="password"
                                    value={formik.values.password}
                                    onChange={formik.handleChange}
                                    error={formik.touched.password && Boolean(formik.errors.password)}
                                    label="Mot de Passe" variant="outlined" />
                            </Grid>
                            <Grid xs={3} item>
                                <TextField fullWidth
                                    variant='outlined'
                                    id="repeatPassword"
                                    name="repeatPassword"
                                    label="Confirmer mot de passe"
                                    value={formik.values.repeatPassword}
                                    onChange={formik.handleChange}
                                    error={formik.touched.repeatPassword && Boolean(formik.errors.repeatPassword)}
                                // helperText={formik.touched.repeatPassword && formik.errors.repeatPassword} 
                                />
                            </Grid>
                        </Grid>
                        {/*  */}
                        <Grid container wrap="wrap" style={{ flex: 1, justifyContent: "space-around", padding: 20 }}>
                            <Grid xs={3} item>
                                <TextField fullWidth id="lastName"
                                    name="lastName"
                                    value={formik.values.lastName}
                                    onChange={formik.handleChange}
                                    error={formik.touched.lastName && Boolean(formik.errors.lastName)}
                                    label="Nom" variant="outlined" />
                            </Grid>
                            <Grid xs={3} item>
                                <TextField fullWidth id="firstName"
                                    name="firstName"
                                    value={formik.values.firstName}
                                    onChange={formik.handleChange} label="Prénom"
                                    error={formik.touched.firstName && Boolean(formik.errors.firstName)}
                                    variant="outlined" />
                            </Grid>
                            <Grid xs={3} item>
                                <TextField fullWidth id="email"
                                    name="email"
                                    value={formik.values.email}
                                    onChange={formik.handleChange} label="email"
                                    error={formik.touched.email && Boolean(formik.errors.email)}
                                    variant="outlined" />
                            </Grid>

                        </Grid>
                        {/*  */}
                        <Grid container wrap="wrap" style={{ flex: 1, justifyContent: "space-around", padding: 20 }}>
                            <Grid xs={3} item>
                                <FormControl fullWidth variant="outlined">
                                    <InputLabel id="dropdown-label">Sexe</InputLabel>
                                    <Select
                                        labelId="dropdown-label"
                                        id="dropdown"
                                        value={gender}
                                        onChange={handleGenderChange}
                                        label="Sexe"
                                    >

                                        <MenuItem value={1}>femelle</MenuItem>
                                        <MenuItem value={0}>male</MenuItem>

                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid xs={3} item>
                                <TextField fullWidth id="phones[0].phoneNumber"
                                    name="phones[0].phoneNumber"
                                    value={formik.values.phones[0].phoneNumber}
                                    onChange={formik.handleChange}
                                    error={formik.touched.phones && Boolean(formik.errors.phones)}
                                    label="Telephone" variant="outlined" />
                            </Grid>



                            <Grid xs={3} item>
                                <FormControl fullWidth variant="outlined">
                                    <InputLabel id="dropdown-label">Bu</InputLabel>
                                    <Select
                                        labelId="dropdown-label"
                                        id="dropdown"
                                        value={Bu}
                                        onChange={handleBuChange}
                                        label="Bu"
                                    >
                                        {Bs.map(item => (BU === item.businessUnitId ? <MenuItem key={item.businessUnitId} value={item.businessUnitId}>{item.businessUnitName}</MenuItem> : null))}

                                    </Select>
                                </FormControl>
                            </Grid>

                        </Grid>
                        {/*  */}
                        <Grid container wrap="wrap" style={{ flex: 1, justifyContent: "space-around", padding: 20 }}>
                            <Grid xs={3} style={{ display: "flex", justifyContent: "center", alignItems: "100%" }} item>
                                <DatePicker placement='top' cleanable={false} style={{ width: "100%" }} value={selectedDate} onChangeCalendarDate={setSelectedDate} placeholder="Date de naissance" />
                            </Grid>

                            <Grid xs={3} style={{ display: "flex", justifyContent: "center", alignItems: "100%" }} item>
                                <DatePicker placement='top' cleanable={false} style={{ width: "100%" }} value={hireDate} onChangeCalendarDate={setHireDate} placeholder="Date de recruitement" />
                            </Grid>

                            <Grid xs={3} item>
                                <TextField fullWidth id="registrantionNumber"
                                    name="registrantionNumber"
                                    value={formik.values.registrantionNumber}
                                    onChange={formik.handleChange}
                                    error={formik.touched.registrantionNumber && Boolean(formik.errors.registrantionNumber)}
                                    label="Matricule" variant="outlined" />
                            </Grid>
                        </Grid>
                        {/* localities */}
                        <Grid container wrap="wrap" style={{ flex: 1, justifyContent: "space-around", padding: 20 }}>
                            <Grid item xs={2} >
                                <FormControl fullWidth variant="outlined">
                                    <InputLabel style={{ backgroundColor: "white" }} id="governorate-label">Gouvernorat</InputLabel>
                                    <Select
                                        labelId="governorate-label"
                                        value={firstSelectValue}
                                        onChange={handleFirstSelectChange}
                                    >
                                        <MenuItem value="">
                                            <em>Aucun</em>
                                        </MenuItem>
                                        {governorates.map((option) => (
                                            <MenuItem key={option.localityId} value={option.localityId}>
                                                {option.localityName}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={2} >
                                <FormControl fullWidth variant="outlined">
                                    <InputLabel style={{ backgroundColor: "white" }} id="governorate-label">Délégation</InputLabel>
                                    <Select
                                        labelId="governorate-label"
                                        value={secondSelectValue}
                                        onChange={handleSecondSelectChange}
                                    >
                                        <MenuItem value="">
                                            <em>Aucun</em>
                                        </MenuItem>
                                        {localitiesOptions[0]?.map((option) => (
                                            <MenuItem key={option.localityId} value={option.localityId}>{option.localityName}</MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={2} >
                                <FormControl fullWidth variant="outlined">
                                    <InputLabel style={{ backgroundColor: "white" }} id="governorate-label">Code Postale</InputLabel>
                                    <Select
                                        labelId="governorate-label"
                                        value={thirdSelectValue}
                                        onChange={handleThirdSelectChange}
                                    >
                                        <MenuItem value="">
                                            <em>Aucun</em>
                                        </MenuItem>
                                        {postalCodes?.map((option) => (
                                            <MenuItem key={option.localityId} value={option.localityId}>{option.localityName}</MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={2} >
                                <FormControl fullWidth variant="outlined">
                                    <InputLabel style={{ backgroundColor: "white" }} id="governorate-label">Localité</InputLabel>
                                    <Select
                                        labelId="governorate-label"
                                        value={fourthSelectValue}
                                        onChange={handleFourthSelectChange}
                                    >
                                        <MenuItem value="">
                                            <em>Aucun</em>
                                        </MenuItem>
                                        {locs?.map((option) => (
                                            <MenuItem key={option.localityId} value={option.localityId}>{option.localityName}</MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Grid>
                        </Grid>
                        <Grid container wrap="wrap" style={{ flex: 1, justifyContent: "center", padding: 20 }}>
                            <Grid xs={11} item>
                                <TextField fullWidth id="address.streetName"
                                    name="address.streetName"
                                    value={formik.values.address.streetName}
                                    onChange={formik.handleChange} label="Adresse"
                                  
                                    variant="outlined" />
                            </Grid>
                        </Grid>
                        <Grid container wrap="wrap" style={{ flex: 1, justifyContent: "center", padding: 20 }}>
                            <Grid xs={11} item>
                                <TextField fullWidth id="address.addressNote"
                                    name="address.addressNote"
                                    value={formik.values.address.addressNote}
                                    onChange={formik.handleChange} label="Complément adresse"

                                    variant="outlined" />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid container style={{ flex: 1, justifyContent: "center", alignItems: "center", marginTop: 40, paddingBottom: 30 }}>
                        <Button style={{ color: "black", backgroundColor: "#e5e5ea", marginRight: 30, width: 120 }} variant="contained" color="primary" onClick={() => closeForm(false)}>annuler</Button>
                        <Button style={{ color: "white", backgroundColor: "black", width: 120 }} variant="contained" color="primary" onClick={() => formik.handleSubmit()}>ajouter</Button>
                    </Grid>
                </div>
            </Grid>

        </>


    )
}


