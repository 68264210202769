import React from "react";
import "./PhalconCell.scss";
import { LinearProgress } from "@material-ui/core";
import {weekStatusEnum} from "../../../../../Enumeration/WeekContentStatusEnum"
type Props = {
  item: number;
}; 


const WeekStatusCell = ({ item }: Props) => {
  return (
    <div className={`phalcon-WeekContentStatus-cell weekStatus${item}`}>
    <div style={{width: "60px"}}> {weekStatusEnum[item] ? weekStatusEnum[item].status : "Autres"} </div> 
      <LinearProgress
        value={item ? weekStatusEnum[item].value : 0}
        variant="determinate" 
        style={{ width: "80px", marginTop: "1.3rem" }}
      />
    </div>
  );
};

export default WeekStatusCell;
