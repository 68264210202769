import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  styled,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Form } from "rsuite";
import { useAppSelector, useAppDispatch } from "../../../../redux/hooks";
import { RootState } from "../../../../redux/store";
import { toggleAddAuthorizationDrawer } from "../../../../redux/UI/uiSlice";
import { CustomButton } from "../../../01-atoms/Buttons/Buttons";
import { divider } from "../../../02-molecules/cards/visit-card/VisitInfoCard";
import { DateField } from "../../../02-molecules/forms/DateField/DateField";
// import { TagsField } from "../../../02-molecules/forms/TagsField/TagsField";
import useAuthorisationForm, { fieldIsRequired } from "./useAddAuthorization";
import "./AddAuthorizationForm.scss";
import { addAuthorization } from "../../../../redux/Authorization/authorizationSlice";
import { SelectField } from "../../../02-molecules/forms/SelectField/SelectField";
import moment from "moment";
export interface AuthorizationFormInput {
  delegatesId: number | null;
  startDate: Date | null;
  endDate: Date | null;
  expirationDate: Date | null;
}
const CustomizedDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
    marginTop: "1rem",
    overflowY: "visible",
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
  "& .MuiDialog-paper": {
    width: "828px",
    overflowY: "visible",
  },
  "& .MuiDialog-paperWidthSm": {
    maxWidth: "1000px",
    height: "456px",
  },
}));

const AddAuthorizationForm = () => {
  const [selectStartDate, setSlectedStartDate] = useState<boolean>(true);
  const dispatch = useAppDispatch();
  const { addAuthorizationIsOpen } = useAppSelector(
    (state: RootState) => state.UI
  );
  const { initialValues, formOptions, prepareAuthorisationFormPayload } =
    useAuthorisationForm();
  const {
    control,
    handleSubmit,
    reset,
    getValues,
    formState: { errors },
  } = useForm<AuthorizationFormInput>({
    defaultValues: initialValues,
    criteriaMode: "all",
    reValidateMode: "onChange",
  });

  useEffect(() => {
    let starDateValue = getValues("startDate");
    if (!starDateValue) {
      let valuesAfterStartDateClear = getValues();
      valuesAfterStartDateClear.endDate = null;
      reset(valuesAfterStartDateClear);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getValues("startDate")]);
  const handleSelectedStartDate = (
    date: string | Date | undefined | null,
    name: string
  ) => {
    if (date && name === "startDate") {
      setSlectedStartDate(false);
    } else if (!date && name === "startDate") {
      setSlectedStartDate(true);
    }
  };
  const handleClose = () => {
    dispatch(toggleAddAuthorizationDrawer());
  };
  const onSubmit = handleSubmit((data) => {
    let payload = prepareAuthorisationFormPayload(data);
    dispatch(addAuthorization(payload));
  });

  return (
    <Form>
      <CustomizedDialog open={addAuthorizationIsOpen}>
        <DialogTitle id="alert-dialog-title">
          <div className="add-authorization-dialog-title">
            Ajouter une autorisation
          </div>
        </DialogTitle>
        {divider("94%")}
        <DialogContent>
          {/* {"select delegate picker"} */}
          <div>
            <SelectField
              control={control}
              errors={errors}
              inputName="delegatesId"
              rules={{ required: fieldIsRequired }}
              xs={24}
              sm={24}
              md={24}
              data={formOptions ? formOptions.teamMembersOptions : []}
              cleanable={false}
              label={"Chosir délégué(s)"}
              searchable={true}
            />
          </div>
          {/* {select start date} */}
          <div>
            <DateField
              control={control}
              errors={errors}
              label={"Date début"}
              inputName="startDate"
              rules={{ required: fieldIsRequired }}
              disabledDate={(date: Date) => {
                return moment(date.toString()).isAfter();
              }}
              md={8}
              handleDateSelection={handleSelectedStartDate}
            />
            <DateField
              control={control}
              errors={errors}
              label={"Date fin"}
              inputName="endDate"
              rules={{ required: fieldIsRequired }}
              disabled={selectStartDate}
              disabledDate={(date: Date) => {
                if (control?._fields?.startDate?._f?.value) {
                  return moment(date.toString()).isBefore(
                    moment(control._fields.startDate._f.value).subtract(
                      1,
                      "days"
                    )
                  );
                } else {
                  return false;
                }
              }}
              md={8}
            />
            <DateField
              control={control}
              errors={errors}
              label={"Date expiration"}
              inputName="expirationDate"
              rules={{ required: fieldIsRequired }}
              disabledDate={(date: Date) => {
                return moment(date.toString()).isBefore(
                  moment().subtract(1, "days")
                );
              }}
              md={8}
            />
          </div>
        </DialogContent>
        <DialogActions>
          <CustomButton
            text={"Annuler"}
            color={"light"}
            style={{
              height: "47px",
              width: "155px",
              border: "1px solid",
              justifyContent: "center",
            }}
            onClick={handleClose}
          />
          <CustomButton
            text={"Confirmer"}
            color={"dark"}
            style={{ height: "47px", width: "155px", justifyContent: "center" }}
            onClick={onSubmit}
          />
        </DialogActions>
      </CustomizedDialog>
    </Form>
  );
};

export default AddAuthorizationForm;
