import { createSlice } from "@reduxjs/toolkit";
import { API_DEV } from "../../utils/apiRoute";
import { axiosPrivate } from "../../utils/interceptors";
import { createAsyncThunk } from "@reduxjs/toolkit";





const initialState: any = {
    Response: ""
}



export const UploadEadv = createAsyncThunk("[EADV]_uplaodeadv_",
    async (data: any, { rejectWithValue }) => {
        console.log(data)
        try {
            let response = await axiosPrivate.put(`${API_DEV}/Product/eadv/${data.id}`,data.formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });
            // console.log(response.data)
            return response.data;
        } catch (error) {
            console.log(JSON.parse(JSON.stringify(error)));
            return rejectWithValue(JSON.parse(JSON.stringify(error)));
        }
    })

export const UploadEadvSlice = createSlice({
    name: "[EADV]",
    initialState,
    reducers: {
    },
    extraReducers: (builder) => {

        builder.addCase(UploadEadv.fulfilled, (state, { payload }) => {
            console.log(payload)
        }).addCase(UploadEadv.rejected, (state, { payload }) => {
            console.log(payload)
        })


        // getSpecByIdThunk
    },
});

export const { } = UploadEadvSlice.actions;// eslint-disable-line


export default UploadEadvSlice.reducer;
