import React from "react";
import { NavLink } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import { RootState } from "../../../redux/store";
import { toggleSidebar } from "../../../redux/UI/uiSlice";
import {
  BurgerButton,
  // InboxButton,
  ProfileDropdown,
} from "../../01-atoms/Buttons/Buttons";

import "./Header.scss";
// import { Notification } from "../../03-organisms/notifications/Notification";

type subRoute = { key: string; path: string; title: string };
interface Props {
  subRoutes?: subRoute[];
  leftItem: any;
  middleItem?: any;
}

export const Header = ({ leftItem, subRoutes, middleItem }: Props) => {
  const dispatch = useAppDispatch();
  const { currentUser } = useAppSelector((state: RootState) => state.user);
  return (
    <div className="header__wrapper">
      <div className="header__inner">
        <div className="header__left">
          <div className="burger-button__rwd">
            <BurgerButton onClick={() => dispatch(toggleSidebar())} />
          </div>
          <div className="header__left__item">{leftItem}</div>
        </div>
        <div className="header__logo__rwd">
          <h3>GALDERMA</h3>
        </div>
        <div className="header__tabs">
          {middleItem ? middleItem : null}
          {subRoutes ? (
            <ul>
              {subRoutes.map((subRoute: subRoute, i: number) => (
                <li key={i}>
                  <NavLink to={subRoute.path} className="header-link">
                    <span>{subRoute.title}</span>
                  </NavLink>
                </li>
              ))}
            </ul>
          ) : null}
        </div>
        <div className="header__right">
          <div className="header__inbox">{/* <InboxButton /> */}</div>
          <div className="header__notification">{/* <Notification /> */}</div>
          <div className="header__account">
            <ProfileDropdown
              photoUrl={currentUser.photoUrl}
              fullName={currentUser.firstName + " " + currentUser.lastName}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
