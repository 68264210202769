const imageEnvironmentUrl = {
    baseUrl: "",
  };
  
  if (process.env.REACT_APP_ENV === "development") {
    imageEnvironmentUrl.baseUrl = "";
  }
  if (process.env.REACT_APP_ENV === "test") {
    imageEnvironmentUrl.baseUrl = "";
  }
  if (process.env.REACT_APP_ENV === "staging") {
    imageEnvironmentUrl.baseUrl = "";
  }
  if (process.env.REACT_APP_ENV === "demo") {
    imageEnvironmentUrl.baseUrl = "";
  }
  
  if (process.env.REACT_APP_ENV === "Production") {
    imageEnvironmentUrl.baseUrl = "https://galdermatn.phalcon-crm.com/";
  }
  
  export default imageEnvironmentUrl;