import React, { useEffect } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { useRoutingConfig } from "../../../utils/useRoutingConfig";
import { Header } from "../../05-layouts/Header/Header";
import "./RequestsObjectionsPage.scss";
type Props = {};

export const RequestsObjectionsPage = (props: Props) => {
  const facility = useLocation();
  const navigate = useNavigate();
  useEffect(() => {
    facility.pathname === "/requests" && navigate("/requests/requests");
  }, [facility, navigate]);

  const currentRoute = useRoutingConfig().find((e) => e.key === "6");
  const subRoutes =
    typeof currentRoute !== "undefined" ? currentRoute.children : [];

  return (
    <>
      <Header
        leftItem={
          <div className="cycle-management__header__title">
            Demande / Objections
          </div>
        }
        subRoutes={subRoutes}
      />
      <div className="requests-objections-page__inner">
        <Outlet />
      </div>
    </>
  );
};
