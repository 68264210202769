/**
 * Handle seller api message errors
 * @errorMessage string
 */

import errorToast from "./errorToast";

export const sellerMessageHandler = (errorMessage: string) => {
  if (errorMessage && errorMessage.length && errorMessage.includes("A Seller with the SellerName")) {
    errorToast("Le nom de la pharamcie/grossite existe déjà");
  } else if (errorMessage && errorMessage.length && errorMessage.includes("A Seller with the Email")) {
    errorToast("Cette adresse e-mail est déjà utilisée");
  } else if (errorMessage && errorMessage.length && errorMessage.includes("A Seller with the Reference")) {
    errorToast("Cette référence est déjà utilisée");
  } else if (errorMessage && errorMessage.length && errorMessage === "Unauthorized") {
    errorToast("Mot de passe incorrect");
  } else {
    errorToast("Une erreur s'est produite, veuillez réessayer");
  }
};

/**
 * Handle facility api message errors
 * @errorMessage string
 */

export const facilityMessageHandler = (errorMessage: string) => {
  if (errorMessage && errorMessage.length && errorMessage === "Unauthorized") {
    errorToast("Mot de passe incorrect");
  } else if (errorMessage && errorMessage.length && errorMessage === "Facility already related to a Doctor") {
    errorToast("L'établissement est rattaché à un ou plusieurs médecins");
  } else {
    errorToast("Une erreur s'est produite, veuillez réessayer");
  }
};

/**
 * Handle doctor api message errors
 * @errorMessage string
 */

export const doctorMessageHandler = (errorMessage: string) => {
  if (errorMessage && errorMessage.length && errorMessage.includes("Doctor must have a primary Service / Office")) {
    errorToast("Le médecin doit avoir une address principale");
  } else if (errorMessage && errorMessage.length && errorMessage.includes("Doctor email")) {
    errorToast("Cette adresse e-mail est déjà utilisée");
  } else if (
    errorMessage && errorMessage.length && (errorMessage.includes("Reference number") ||
      errorMessage.includes("Registration number"))
  ) {
    errorToast("Cette référence est déjà utilisée");
  } else if (
    errorMessage && errorMessage.length && errorMessage.includes(
      "Doctor can't have more than one primary Service / Office"
    )
  ) {
    errorToast("Le médecin ne peut avoir qu'une adresse principale");
  } else if (errorMessage && errorMessage.length && errorMessage.includes("Duplicate facility")) {
    errorToast(
      "Le même établissement ne doit pas être sélectionné plus qu'une fois"
    );
  } else if (errorMessage && errorMessage.length && errorMessage.includes("Unauthorized")) {
    errorToast("Mot de passe incorrect");
  } else {
    errorToast("Une erreur s'est produite, veuillez réessayer");
  }
};

/**
 * Handle cycle api message errors
 * @errorMessage string
 */

export const cycleMessageHandler = (errorMessage: string) => {
  if (errorMessage && errorMessage.length && errorMessage.includes("Unauthorized")) {
    errorToast("Mot de passe incorrect");
  } else if (errorMessage && errorMessage.length && errorMessage.includes("NbVisitWeek")) {
    errorToast("Le nombre de visites doit être différent de 0");
  } else {
    errorToast("Une erreur s'est produite, veuillez réessayer");
  }
};

/**
 * Handle planification api message errors
 * @errorMessage string
 */

export const planificationMessageHandler = (errorMessage: string) => {
  if (errorMessage && errorMessage.length && errorMessage === "Start Date can't be greater than end date") {
    errorToast("La date de début ne peut pas dépasser la date fin");
  } else {
    errorToast("Une erreur s'est produite, veuillez réessayer");
  }
};

/**
 * Handle salesTarget api message errors
 * @errorMessage string
 */

export const salesTargetMessageHandler = (errorMessage: string) => {
  if (errorMessage && errorMessage.length && errorMessage.includes("One or more errors occurred. (the sales target of previous year ago )")) {
    errorToast("Vous ne pouvez pas supprimer les objectifs de ventes des années précédentes");
  }
  else if (errorMessage && errorMessage.length && errorMessage.includes("InData Base Two Date are overlap")) {
    errorToast("L'objectif de vente du produit sélectionné existe déjà pour les ou l'un des mois entrés");
  }

  else {
    errorToast("Une erreur s'est produite, veuillez réessayer");
  }
};


export const usersMessageHandler = (errorMessage: string) => {
  if (errorMessage.includes('User email')) {
    errorToast('email est deja utilisé')
  } else if (errorMessage.includes('Registration')) {
    errorToast('matricule est deja utilisé')
  } else if (errorMessage.includes('Number of Delegate')) {
    errorToast("La limite du nombre d'utilisateurs pour la license est atteinte")
  } else if (errorMessage.includes("This username is already been taken")) {
    errorToast("nom d'utilisateur est deja utilisé")
  }
}

export const toggleFaciltyMessageHandler = (errorMessage: string) => {
  if (errorMessage.includes('this Type utility with another facilty')) {
    errorToast("Type d'etablissement est déjà utilisé")
  } else if (errorMessage.includes("The Facility Type name")) {
    errorToast('Établissement existe déjà ')
  }


}

export const specMsgHandler = (errorMessage: string) => {
  if (errorMessage.includes("The Speciality name")) {
    errorToast('Spécialité existe déjà')
  } else if (errorMessage.includes("The Speciality name ")) {
    errorToast("Spécialité existe déjà")
  } else if (errorMessage.includes("This Speciality utility with other doctors")) {
    errorToast("Spécialité est déjà utilisée")
  } else {
    errorToast("Une erreur se produite");
  } 
}