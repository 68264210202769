const environment = {
  baseUrl: "https://localhost:7181/api",
};
//just adding
if (process.env.REACT_APP_ENV === "development") {
  environment.baseUrl = "http://10.9.100.131:8058/api";
}
if (process.env.REACT_APP_ENV === "test") {
   environment.baseUrl = "http://10.9.100.131:8053/api";  
  // environment.baseUrl = "http://10.9.100.131:8058/api"; 
}
if (process.env.REACT_APP_ENV === "staging") {
  /* environment.baseUrl = "http://10.9.100.131:8053/api"; */ 
  environment.baseUrl = "http://10.9.100.131:8054/api"; 
}
if (process.env.REACT_APP_ENV === "devweb") {
  environment.baseUrl = "http://10.9.100.131:8058/api"; //checked 
}
if (process.env.REACT_APP_ENV === "demo") {
  environment.baseUrl = "http://10.9.100.131:8059/api";
}

if (process.env.REACT_APP_ENV === "Production") {
  // environment.baseUrl = "https://197.13.19.205/api";
  environment.baseUrl = "https://galdermatn.phalcon-crm.com/api";
}

export default environment;
