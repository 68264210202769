// import {
//   mdiHandBackLeftOutline,
//   mdiCheckCircle,
//   mdiCloseCircle,
// } from "@mdi/js";
// import React from "react";
// import { FlexboxGrid } from "rsuite";
// import AnalyticsCard from "../../03-organisms/analytics/cards/AnalyticsCard";
// import PhalconBarChart from "../../03-organisms/charts/PhalconBarChart";

type Props = {};

const BiOrders = (props: Props) => {
  return <div>BiOrders</div>;
};

export default BiOrders;
