import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { API_DEV } from "../../utils/apiRoute";
import { axiosPrivate } from "../../utils/interceptors";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { Cycle } from "../../types/Cycle.model";

export interface GlobalFilterStateI {
  search: {
    searchText: string;
    delegateId?: number;
    managerId?: number;
  };
  pagination: {
    pageNumber: number;
    pageSize: number;
  };
}
export interface CycleState {
  requestState: "loading" | "idle";
  cycles: Cycle[];
  filteredCycles: {
    data: Cycle[];
    pagination: {
      pageNumber: number;
      pageSize: number;
      totalCount: number;
      totalPages: number;
    };
  };
  globalFilterState: GlobalFilterStateI;
  cycleById: Cycle | null;
  errors: any;
}

export const addCycle = createAsyncThunk(
  "[Cycles]_add_",
  async (payload: any, { rejectWithValue }) => {
    try {
      await axiosPrivate.post(`${API_DEV}/Cycle`, payload);
    } catch (err: any) {
      return rejectWithValue(err.response.data); 
    }
  }
);

export const fetchCycles = createAsyncThunk(
  "[Cycles]_fetchAll_",
  async (_, { rejectWithValue }) => {
    try {
      let response = await axiosPrivate.get(
        `${API_DEV}/Cycle/GetAllByConnectedManager`
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(JSON.parse(JSON.stringify(error)));
    }
  }
);

export const fetchCycleById = createAsyncThunk( 
  "[Cycles]_fetchById_",
  async (id: number, { rejectWithValue }) => {
    try {
      let response = await axiosPrivate.get(`${API_DEV}/Cycle/${id}`);
      return response.data;
    } catch (error) {
      return rejectWithValue(JSON.parse(JSON.stringify(error)));
    }
  }
);

export const updateCycle = createAsyncThunk(
  "[Cycles]_update_",
  async (payload: any, { rejectWithValue }) => {
    try {
      let response = await axiosPrivate.put(`${API_DEV}/Cycle`, payload);
      return response.data;
    } catch (error) {
      return rejectWithValue(JSON.parse(JSON.stringify(error)));
    }
  }
); 

export const associateDelegates = createAsyncThunk(
  "[Cycles]__associateDelegates__",
  async (
    { cycle, delegatesIds }: { cycle: Cycle; delegatesIds: number[] },
    { rejectWithValue }
  ) => {
    const payload = {
      cycleId: cycle.cycleId,
      delegates: delegatesIds.map((el) => ({
        userId: el,
      })),
    };
    try {
      const response = await axiosPrivate.put(
        `${API_DEV}/Cycle/AssignDelegatesToCycle`,
        payload
      );
      return response.data;
    } catch (err:any) {
      return rejectWithValue(err.response.data);
    }
  }
);
export const dissociateDelegates = createAsyncThunk(
  "[Cycles]__dissociateDelegates__",
  async (
    { cycleId, userId }: { cycleId: number; userId: number },
    { rejectWithValue }
  ) => {
    const payload = {
      cycleId,
      userId,
    };
    try {
      const response = await axiosPrivate.put(
        `${API_DEV}/Cycle/RemoveDelegateFromCycle`,
        payload
      );
      return response.data;
    } catch (err:any) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const deleteCycle = createAsyncThunk(
  "[Cycles]_delete_",
  async (
    { itemId, password }: { itemId: number; password: string },
    { rejectWithValue }
  ) => {
    const payload = {
      itemId,
      password,
    };
    try {
      let response = await axiosPrivate.post(
        `${API_DEV}/Cycle/Delete`,
        payload
      );
      return response.data;
    } catch (err:any) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const fetchFilteredCycle = createAsyncThunk(
  "[Cycles]_GetAllWithFilters_",
  async (options: any, { rejectWithValue }) => {
    try {
      let response = await axiosPrivate.post(
        `${API_DEV}/Cycle/Filter`,
        options
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(JSON.parse(JSON.stringify(error)));
    }
  }
);

const initialState: CycleState = {
  requestState: "idle",

  cycles: [],
  filteredCycles: {
    data: [],
    pagination: {
      pageNumber: 1,
      pageSize: 10,
      totalCount: 0,
      totalPages: 0,
    },
  },
  cycleById: null,
  errors: null,
  globalFilterState: {
    search: {
      searchText: "",
      // managerId: decoded.id,
    },
    pagination: {
      pageNumber: 1,
      pageSize: 10,
    },
  },
};

export const cycleSlice = createSlice({
  name: "[Cycles]",
  initialState,
  reducers: {
    captureSearchTextValue: (state, action: PayloadAction<string>) => {
      state.globalFilterState.search.searchText = action.payload;
    },
    capturePageTarget: (state, action: PayloadAction<number>) => {
      state.globalFilterState.pagination.pageNumber = action.payload;
    },
    captureLimitTarget: (state, action: PayloadAction<number>) => {
      state.globalFilterState.pagination.pageSize = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(addCycle.pending, (state) => {
      state.requestState = "loading";
      state.errors = null;
    });
    builder.addCase(addCycle.fulfilled, (state) => {
      state.requestState = "idle";
      state.errors = null;
    });
    builder.addCase(addCycle.rejected, (state, { payload }) => {
      state.requestState = "idle";
      state.errors = payload;
    });
    builder.addCase(fetchCycles.pending, (state) => {
      state.requestState = "loading";
      state.errors = null;
    });
    builder.addCase(fetchCycles.fulfilled, (state, { payload }) => {
      state.requestState = "idle";
      state.cycles = payload;
      state.errors = null;
    });
    builder.addCase(fetchCycles.rejected, (state, { payload }) => {
      state.requestState = "idle";
      state.errors = payload;
    });
    builder.addCase(deleteCycle.pending, (state) => {
      state.requestState = "loading";
      state.errors = null;
    });
    builder.addCase(deleteCycle.fulfilled, (state, { payload }) => {
      state.requestState = "idle";
      state.errors = null;
    });
    builder.addCase(deleteCycle.rejected, (state, { payload }) => {
      state.requestState = "idle";
      state.errors = payload;
    });

    builder.addCase(fetchCycleById.pending, (state) => {
      state.requestState = "loading";
      state.errors = null;
    });
    builder.addCase(fetchCycleById.fulfilled, (state, { payload }) => {
      state.requestState = "idle";
      state.cycleById = payload;
      state.errors = null;
    });
    builder.addCase(fetchCycleById.rejected, (state, { payload }) => {
      state.requestState = "idle";
      state.errors = payload;
    });
    builder.addCase(updateCycle.pending, (state) => {
      state.requestState = "loading";
      state.errors = null;
    });
    builder.addCase(updateCycle.fulfilled, (state) => {
      state.requestState = "idle";
      state.errors = null;
    });
    builder.addCase(updateCycle.rejected, (state, { payload }) => {
      state.requestState = "idle";
      state.errors = payload;
    });
    builder.addCase(fetchFilteredCycle.pending, (state) => {
      state.requestState = "loading";
      state.errors = null;
    });
    builder.addCase(fetchFilteredCycle.fulfilled, (state, { payload }) => {
      state.requestState = "idle";
      state.filteredCycles = payload;
      state.errors = null;
    });
    builder.addCase(fetchFilteredCycle.rejected, (state, { payload }) => {
      state.requestState = "idle";
      state.errors = payload;
    });
  },
});

export const { captureSearchTextValue, capturePageTarget, captureLimitTarget } =
  cycleSlice.actions;

export default cycleSlice.reducer;
