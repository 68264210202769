import { getCurrency } from "./../../../../helpers/getCurrency";
import { RpEvent } from "./../../../../types/RpEvent.model";
import { useAppDispatch, useAppSelector } from "../../../../redux/hooks";
import { RootState } from "../../../../redux/store";
import {
  toggleAddDoctorDrawer,
  toggleDoctorsFilterDrawer,
} from "../../../../redux/UI/uiSlice";
import { RpState } from "../../../../redux/Rp/rpSlice";

export interface RpEventTableData {
  id: number;
  subject: string;
  type: string;
  budget: string;
  principalContact: string;
  principalContactPhone: string;
  organizator: string;
  startDate: string;
  endDate: string;
  place: string;
  participants: string;
}
const useRpTable = () => {
  const dispatch = useAppDispatch();

  const rpEventState = useAppSelector((state: RootState) => state.rpEvent);

  const formatData: (fetchedData: RpEvent[]) => RpEventTableData[] = (
    fetchedData
  ) => {
    return fetchedData.length > 0
      ? fetchedData.map((rpEvent: RpEvent) => ({
          id: rpEvent.rpEventId,
          subject: rpEvent.subject,
          type: rpEvent.type,
          budget: `${
            rpEvent.esitimatedBudget
              ? rpEvent.esitimatedBudget + " " + getCurrency()
              : "-"
          }`,
          principalContact: rpEvent?.principalContact?.fullName || "",
          principalContactPhone: rpEvent.principalContact.phone,
          organizator: rpEvent.info.createdBy
            ? JSON.stringify(rpEvent.info.createdBy)
            : JSON.stringify(""),
          startDate: rpEvent.startDate,
          endDate: rpEvent.endDate,
          place: rpEvent.place,
          participants: rpEvent.participants
            ? JSON.stringify(rpEvent.participants)
            : JSON.stringify(""),
        }))
      : [];
  };

  const prepareDatabase = (rpEventState: RpState) => {
    return {
      data: formatData(rpEventState.filteredRpEvents.content),
      dataResquestState: rpEventState.requestState || "idle",
      errors: rpEventState.errors || null,
      currentPageNumber:
        rpEventState.filteredRpEvents.pagination.currentPageNumber,
      pageSize: rpEventState.filteredRpEvents.pagination.pageSize,
      totalCount: rpEventState.filteredRpEvents.pagination.totalCount,
      toggleFilterDrawer: () => dispatch(toggleDoctorsFilterDrawer()),
      toggleAddDrawer: () => dispatch(toggleAddDoctorDrawer()),
    };
  };

  const {
    data,
    dataResquestState,
    errors,
    currentPageNumber,
    pageSize,
    totalCount,
    toggleFilterDrawer,
    toggleAddDrawer,
  } = prepareDatabase(rpEventState);

  const columns = [
    {
      key: "subject",
      label: "Evènement",
      width: 200,
      fixed: false,
      type: "textCell",
    },
    {
      key: "type",
      label: "Type",
      width: 120,
      fixed: false,
      type: "textCell",
    },
    {
      key: "budget",
      label: "Budget",
      width: 150,
      fixed: false,
      type: "textCell",
    },
    {
      key: "principalContact",
      label: "Contact Principal",
      width: 200,
      fixed: false,
      type: "textCell",
    },
    {
      key: "principalContactPhone",
      label: "Téléphone",
      width: 150,
      fixed: false,
      type: "textCell",
    },
    {
      key: "organizator",
      label: "Organisateur",
      width: 250,
      fixed: false,
      type: "personCell",
    },

    {
      key: "startDate",
      label: "Date début",
      width: 120,
      fixed: false,
      type: "dateCell",
    },
    {
      key: "endDate",
      label: "Date fin",
      width: 120,
      fixed: false,
      type: "dateCell",
    },
    {
      key: "participants",
      label: "Participants",
      width: 150,
      fixed: false,
      type: "participantsCell",
    },
    {
      key: "place",
      label: "Lieu de l'événement",
      width: 600,
      fixed: false,
      type: "textCell",
    },
  ];

  return {
    data,
    columns,
    dataResquestState,
    errors,
    currentPageNumber,
    pageSize,
    totalCount,
    toggleFilterDrawer,
    toggleAddDrawer,
  };
};

export default useRpTable;
