import { mdiPlus } from "@mdi/js";
import Icon from "@mdi/react";
import React, { useEffect, useState } from "react";
import { Loader } from "rsuite";
import { useAppDispatch } from "../../../redux/hooks";
import { togglePlanificationModal } from "../../../redux/Planification/planificationSlice";
import { DoctorSummary } from "../../../types/DoctorSummary.model";
import { Planification } from "../../../types/Planification.model";
import { PlanificationWeek } from "../../../types/PlanificationWeek.model";
import PlanificationGroupByInner from "./PlanificationGroupByInner";
import "./PlanificationWeekContent.scss";
type Props = {
  activeUpdatedPlanificationState: Planification | null;
  planificationWeek?: PlanificationWeek;
  innerSelected: DoctorSummary[];
  setInnerSelected: React.Dispatch<React.SetStateAction<DoctorSummary[]>>;
};

export const PlanificationWeekContent = ({
  activeUpdatedPlanificationState,
  planificationWeek,
  setInnerSelected,
}: Props) => {
  const dispatch = useAppDispatch();

  // useEffect(() => {
  //   planificationWeek &&
  //     dispatch(
  //       selectDoctors(planificationWeek.weekContent.map((e) => e.doctor))
  //     );
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [planificationWeek]);

  const [innerWeek, setInnerWeek] = useState<PlanificationWeek>();

  useEffect(() => {
    const getActiveWeek = () => {
      if (
        activeUpdatedPlanificationState &&
        activeUpdatedPlanificationState.planificationWeeks.length > 0
      ) {
        let index =
          activeUpdatedPlanificationState.planificationWeeks.findIndex(
            (e) => e.weekId === planificationWeek?.weekId
          );
        const result =
          activeUpdatedPlanificationState.planificationWeeks[index];
        return result;
      } else {
        return planificationWeek;
      }
    };
    setInnerWeek(getActiveWeek());
  }, [planificationWeek, activeUpdatedPlanificationState]);

  return (
    <div className="week-content__wrapper">
      {innerWeek ? (
        <div className="week-content__inner">
          {innerWeek.weekContent.length > 0 ? (
            <PlanificationGroupByInner
              planificationWeek={innerWeek}
              setInnerSelected={setInnerSelected}
            />
          ) : (
            <div className="week-content__inner--bottom">
              <div
                className="add-content-box"
                onClick={() => dispatch(togglePlanificationModal())}
              >
                <Icon path={mdiPlus} size={1} />
              </div>
            </div>
          )}
        </div>
      ) : (
        <Loader />
      )}
    </div>
  );
};
