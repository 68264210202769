import "./PlanificationGroupBy.scss";
import { DoctorSummary } from "../../../types/DoctorSummary.model";
import { DataGrid, GridColumns } from "@material-ui/data-grid";
import { createStyles, makeStyles, Theme } from "@material-ui/core";
import localizedTextsMap from "../../../utils/dataGridColumnMenuLanguage";
import { useState } from "react";
interface Props {
  data: DoctorSummary[];
  selectDoctorsCallBack: (doctors: DoctorSummary[]) => void;
}
const PlanificationGroupBy = ({ data, selectDoctorsCallBack }: Props) => {
  const [rowsPerPage, setRowsPerPage] = useState<number>(25);

  const columns: GridColumns = [
    {
      field: "name",
      headerName: "Nom et Prénom",
      minWidth: 300,
    },
    {
      field: "potential",
      headerName: "Pot.",
      width: 110,
      description: "Potentiel",
    },
    {
      field: "speciality",
      headerName: "Spéc.",
      width: 115,
      description: "Spécialité",
    },
    {
      field: "facilityType",
      headerName: "Type Etab.",
      width: 150,
      description: "Type d'établissement",
    },
    {
      field: "level1",
      headerName: "Gouv.",
      width: 180,
      description: "Gouvernorat",
    },
    {
      field: "level2",
      headerName: "Délég.",
      width: 180,
      description: "Délégation",
    },
    {
      field: "facilityName",
      headerName: "Etab.",
      minWidth: 300,
      description: "Etablissement",
    },
  ];
  const useStyles = makeStyles((theme: Theme) =>
    createStyles({
      table: {
        minHeight: `calc(100vh - 64px)`,
        maxHeight: `calc(100vh - 64px)`,
      },
    })
  );
  const classes = useStyles();

  const formatData = data.map((e) => ({
    id: JSON.stringify(e),
    name: `${e.title} ${e.firstName} ${e.lastName}`,
    potential: e.potential?.potentialName || "",
    speciality: e.specialities[0]?.abreviation || "",
    specialityName: e.specialities[0]?.specialityName || "",
    facilityName: e.principalAddress?.facilityName || "",
    facilityType:
      e.principalAddress?.facilityType?.facilityTypeName || "Cabinet",
    level1: e.principalAddress?.address?.localities[0]?.localityName || "",
    level2: e.principalAddress?.address?.localities[1]?.localityName || "",
  }));

  return (
    <DataGrid
      headerHeight={52}
      rows={formatData}
      className={classes.table}
      columns={columns}
      pageSize={rowsPerPage}
      rowsPerPageOptions={[10, 25, 50, 100]}
      onPageSizeChange={(newPageSize) => setRowsPerPage(newPageSize)}
      checkboxSelection
      onSelectionModelChange={(selectionModel) =>
        selectDoctorsCallBack(
          selectionModel.map((e) => JSON.parse(e as string)) as DoctorSummary[]
        )
      }
      localeText={localizedTextsMap}
    />
  );
};
 
export default PlanificationGroupBy;
