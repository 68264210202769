interface WeekStatusEnum {
    [key: number]: { status: string; value: number };
  }

export const weekStatusEnum: WeekStatusEnum = {
    0: { status: "Non visité", value: 0 },
    1: { status: "RDV", value: 25 },
    2: { status: "EADV", value: 50 },
    3: { status: "En attente", value: 75 },
    4: { status: "Clôturée", value: 100 },
  };

