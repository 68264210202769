import React, { useState } from "react";
import "./PhalconCell.scss";
import {Switch} from "@material-ui/core";


type Props = {
  item: number;
  isClicked?: React.MouseEventHandler;
}; 


const StatusToggleCell = ({ item, isClicked }: Props) => {
    const [isChecked, setIsChecked] = useState<boolean>(!!item)
  return (
    <div className={`phalcon-authorization-cell authorizationStatus${item}`} onClick={isClicked} >
    <Switch
      checked={isChecked}
      color="primary"
     
      onChange={(e)=> {setIsChecked(!isChecked) 
      
      }}
      inputProps={{ 'aria-label': 'controlled' }}
    />
    </div>
  );
};

export default StatusToggleCell;