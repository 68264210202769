// import { getFilteredSellers } from "./../Seller/sellerSlice";
// import { getFilteredFacilities } from "../Facility/facilitySlice";
// import { getFilteredDoctor } from "./../Doctor/doctorSlice";
// import { getFilteredPharmacies } from "./../Pharmacy/pharmacySlice";
import { ThunkDispatch } from "@reduxjs/toolkit";
// import { toast } from "react-toastify";
import { AnyAction, Middleware } from "redux";
// import {
//   toggleRedirectToTargetPage,
//   toggleAddDoctorDrawer,
//   toggleAddfacilityDrawer,
//   toggleAddPharmacyDrawer,
//   toggleAddSellerDrawer,
//   toggleUpdateDoctorDrawer,
//   toggleUpdatefacilityDrawer,
//   toggleUpdatePharmacyDrawer,
//   toggleUpdatePhotoModal,
//   toggleUpdateSellerDrawer,
// } from "../UI/uiSlice";
// import { fetchFilteredCycle } from "../Cycle/cycleSlice";
// import { fetchCurrentUser } from "../User/userSlice";
// import { getExternals } from "../Rp/rpSlice";

export const rpMiddleware: Middleware<
  {},
  unknown,
  ThunkDispatch<unknown, unknown, AnyAction>
> = (storeApi) => (next) => (action) => {
  // type PersonItem = {
  //   id: number;
  //   fullName: string;
  //   photoUrl: string;
  //   occupation: string;
  //   link?: string;
  //   businessUnit?: string[];
  // };

  // type Persons = {
  //   users: PersonItem[];
  //   doctors: PersonItem[];
  //   externals: PersonItem[];
  //   moderators: PersonItem[];
  // };
  // const dispatch = (thunkAction: any) => storeApi.dispatch(thunkAction);
  // const state: any = storeApi.getState();

  if (action.type === "[RpEvents]_getPersons_/fulfilled") {
    /*   const data = action.payload;
    action.payload = [];

    const persons: Persons = {
      users: [
        {
          id: 1,
          fullName: "John Doe",
          photoUrl: "string",
          occupation: "string",
          link: "/link",
          businessUnit: ["Ax", "Rx"],
        },
      ],
      doctors: [],
      externals: [],
      moderators: [],
    };
    action.payload = persons; */
    return [];
  }
  next(action);
};
