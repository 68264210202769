import { createSlice } from "@reduxjs/toolkit";

export interface SidebarState {
  sidebarIsOpen: boolean;
  updatePhotoModalIsOpen: boolean;
  addfacilityDrawerIsOpen: boolean;
  updatefacilityDrawerIsOpen: boolean;
  addPharmacyDrawerIsOpen: boolean;
  updatePharmacyDrawerIsOpen: boolean;
  addSellerDrawerIsOpen: boolean;
  updateSellerDrawerIsOpen: boolean;
  addDoctorDrawerIsOpen: boolean;
  updateDoctorDrawerIsOpen: boolean;
  facilitiesFilterDrawerIsOpen: boolean;
  pharmaciesFilterDrawerIsOpen: boolean;
  sellersFilterDrawerIsOpen: boolean;
  doctorsFilterDrawerIsOpen: boolean;
  redirectToTargetPage: boolean;
  redirectToPlanificationPage: boolean;
  addAuthorizationIsOpen: boolean;
}

const initialState: SidebarState = {
  sidebarIsOpen: false,
  updatePhotoModalIsOpen: false,
  addfacilityDrawerIsOpen: false,
  updatefacilityDrawerIsOpen: false,
  addPharmacyDrawerIsOpen: false,
  updatePharmacyDrawerIsOpen: false,
  addSellerDrawerIsOpen: false,
  updateSellerDrawerIsOpen: false,
  addDoctorDrawerIsOpen: false,
  updateDoctorDrawerIsOpen: false,
  facilitiesFilterDrawerIsOpen: false,
  pharmaciesFilterDrawerIsOpen: false,
  sellersFilterDrawerIsOpen: false,
  doctorsFilterDrawerIsOpen: false,
  redirectToTargetPage: false,
  redirectToPlanificationPage: false,
  addAuthorizationIsOpen: false,
};

export const uiSlice = createSlice({
  name: "[UI]",
  initialState,
  reducers: {
    toggleSidebar: (state) => {
      state.sidebarIsOpen = !state.sidebarIsOpen;
    },
    closeSidebar: (state) => {
      state.sidebarIsOpen = false;
    },

    toggleUpdatePhotoModal: (state) => {
      state.updatePhotoModalIsOpen = !state.updatePhotoModalIsOpen;
    },
    toggleAddfacilityDrawer: (state) => {
      state.addfacilityDrawerIsOpen = !state.addfacilityDrawerIsOpen;
    },
    toggleUpdatefacilityDrawer: (state) => {
      state.updatefacilityDrawerIsOpen = !state.updatefacilityDrawerIsOpen;
    },
    toggleAddPharmacyDrawer: (state) => {
      state.addPharmacyDrawerIsOpen = !state.addPharmacyDrawerIsOpen;
    },
    toggleUpdatePharmacyDrawer: (state) => {
      state.updatePharmacyDrawerIsOpen = !state.updatePharmacyDrawerIsOpen;
    },
    toggleAddSellerDrawer: (state) => {
      state.addSellerDrawerIsOpen = !state.addSellerDrawerIsOpen;
    },
    toggleUpdateSellerDrawer: (state) => {
      state.updateSellerDrawerIsOpen = !state.updateSellerDrawerIsOpen;
    },
    toggleAddDoctorDrawer: (state) => {
      state.addDoctorDrawerIsOpen = !state.addDoctorDrawerIsOpen;
    },
    toggleUpdateDoctorDrawer: (state) => {
      state.updateDoctorDrawerIsOpen = !state.updateDoctorDrawerIsOpen;
    },
    togglefacilitiesFilterDrawer: (state) => {
      state.facilitiesFilterDrawerIsOpen = !state.facilitiesFilterDrawerIsOpen;
    },
    togglePharmaciesFilterDrawer: (state) => {
      state.pharmaciesFilterDrawerIsOpen = !state.pharmaciesFilterDrawerIsOpen;
    },
    toggleSellersFilterDrawer: (state) => {
      state.sellersFilterDrawerIsOpen = !state.sellersFilterDrawerIsOpen;
    },
    toggleDoctorsFilterDrawer: (state) => {
      state.doctorsFilterDrawerIsOpen = !state.doctorsFilterDrawerIsOpen;
    },
    toggleRedirectToTargetPage: (state, { payload }: { payload: boolean }) => {
      state.redirectToTargetPage = payload;
    },
    toggleRedirectToPlanificationPage: (
      state,
      { payload }: { payload: boolean }
    ) => {
      state.redirectToPlanificationPage = payload;
    },
    toggleAddAuthorizationDrawer: (state) => {
      state.addAuthorizationIsOpen = !state.addAuthorizationIsOpen;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  toggleSidebar,
  closeSidebar,
  toggleUpdatePhotoModal,
  toggleAddfacilityDrawer,
  toggleUpdatefacilityDrawer,
  toggleAddPharmacyDrawer,
  toggleUpdatePharmacyDrawer,
  toggleAddSellerDrawer,
  toggleUpdateSellerDrawer,
  toggleAddDoctorDrawer,
  toggleUpdateDoctorDrawer,
  togglefacilitiesFilterDrawer,
  togglePharmaciesFilterDrawer,
  toggleSellersFilterDrawer,
  toggleDoctorsFilterDrawer,
  toggleRedirectToTargetPage,
  toggleRedirectToPlanificationPage,
  toggleAddAuthorizationDrawer,
} = uiSlice.actions;

export default uiSlice.reducer;
