import { Box } from "@material-ui/core";
import { DataGrid, GridCellParams, GridColDef } from "@material-ui/data-grid";
import React from "react";
import { getPotentialId } from "../../../../helpers/dataFormator";
import { useAppSelector } from "../../../../redux/hooks";
import { RootState } from "../../../../redux/store";
import {
  FormatedDataOrderTrackingList
} from "../../../../types/OrderTracking.model";
import localizedTextsMap from "../../../../utils/dataGridColumnMenuLanguage";
import TextCell from "../../../03-organisms/tables/common/Cells/TextCell";
import "../Order.scss";

type Props = {
  data: FormatedDataOrderTrackingList[];
  orderTrackingListIsLoaded: "idle" | "loading";
  pageSize: number;
  currentPageNumber: number;
  captureLimitTarget: (page: number) => void;
  capturePageTarget: (page: number) => void;
  totalCount: number;
};

export default function AnalyticsOrderTable({
  data,
  orderTrackingListIsLoaded,
  pageSize,
  currentPageNumber,
  captureLimitTarget,
  capturePageTarget,
  totalCount,
}: Props) {
  const { potentials } = useAppSelector((state: RootState) => state.general);

  const columns: GridColDef[] = [
    {
      field: "id",
      headerName: "ID",
      width: 0,
      hide: true,
      sortable: false,
      filterable: false,
    },
    {
      field: "client",
      headerName: "Client",
      width: 370,
      sortable: false,
      filterable: false,
      renderCell: (params: GridCellParams) => {
        return <TextCell item={params.row.client} />;
      },
    },
    {
      field: "potential",
      headerName: "Pot.",
      width: 100,
      sortable: false,
      filterable: false,
      renderCell: (params: GridCellParams) => {
        return (
          <div
            className={`potential-color pot${getPotentialId(
              potentials,
              params?.row?.potential || ""
            )} `}
          >
            {params.row.potential}
          </div>
        );
      },
    },
    {
      field: "doctorSpeciality",
      headerName: "Spécialité",
      width: 170,
      sortable: false,
      filterable: false,
      renderCell: (params: GridCellParams) => {
        return <TextCell item={params.row.doctorSpeciality} />;
      },
    },
    {
      field: "governorate",
      headerName: "Gouvernorat",
      width: 170,
      sortable: false,
      filterable: false,
      renderCell: (params: GridCellParams) => {
        return <TextCell item={params.row.governorate} />;
      },
    },
    {
      field: "delegation",
      headerName: "Délégation",
      width: 170,
      sortable: false,
      filterable: false,
      renderCell: (params: GridCellParams) => {
        return <TextCell item={params.row.delegation} />;
      },
    },
    {
      field: "products",
      headerName: "Produits",
      width: 400,
      sortable: false,
      filterable: false,
      renderCell: (params: GridCellParams) => {
        return (
          <TextCell
            item={params.row.products}
            toolTipeText={params.row.products}
          />
        );
      },
    },
    {
      field: "productQuantities",
      headerName: "Quantités produits",
      width: 120,
      sortable: false,
      filterable: false,
      renderCell: (params: GridCellParams) => {
        return <TextCell item={params.row.productQuantities} />;
      },
    },
    {
      field: "freeSamples",
      headerName: "Gratuité",
      width: 120,
      sortable: false,
      filterable: false,
      renderCell: (params: GridCellParams) => {
        return <TextCell item={params.row.freeSamples} />;
      },
    },
    {
      field: "turnover",
      headerName: "C.A HT (DT)",
      width: 170,
      sortable: false,
      filterable: false,
      renderCell: (params: GridCellParams) => {
        return <TextCell item={params.row.turnover} />;
      },
    },
      {
      field: "turnoverAfterDiscount",
      headerName: "C.A HT après remise (DT)",
      width: 170,
      sortable: false,
      filterable: false,
      renderCell: (params: GridCellParams) => {
        return <TextCell item={params.row.turnoverAfterDiscount} />;
      },
    },
  ];

  return (
    <Box className="order-table-style style2">
      <DataGrid
        rows={data}
        columns={columns}
        loading={orderTrackingListIsLoaded === "loading"}
        rowsPerPageOptions={[10, 50, 100]}
        disableSelectionOnClick
        onPageChange={capturePageTarget}
        onPageSizeChange={captureLimitTarget}
        localeText={localizedTextsMap}
        paginationMode="server"
        rowCount={totalCount}
        pageSize={pageSize}
      />
    </Box>
  );
}
