import React, { useState, useEffect } from "react";
import { InputGroup, Input } from "rsuite";
import Icon from "@mdi/react";
import { mdiMagnify } from "@mdi/js";
import User from "../../../types/User.model";
import "./ActivityTrackingSidebar.scss";
import ActivityTrackingUserCard from "./ActivityTrackingUserCard";
import { useAppDispatch } from "../../../redux/hooks";
import { getCurrentWeekPlanification } from "../../../redux/Tracking/trackingSlice";
type Props = {
  activeUser: User | null;
  setActiveUser: React.Dispatch<React.SetStateAction<User | null>>;
  teamMembers: User[];
};

const ActivityTrackingSidebar = ({
  activeUser,
  setActiveUser,
  teamMembers,
}: Props) => {
  const dispatch = useAppDispatch();
  const [searchValue, setSearchValue] = useState("");
  const [members, setMembers] = useState<User[]>([]);
  const filterMembers = (user: User) =>
    user.firstName.toLowerCase().includes(searchValue) ||
    user.lastName.toLowerCase().includes(searchValue);
  useEffect(() => {
    setMembers(teamMembers);
  }, [teamMembers]);

  const handleSelectUser = (member: User) => {
    setActiveUser(member); 
    // dispatch(getCurrentWeekPlanification(member.userId));
  };

  // Now do the effect without any dependencies
  useEffect(() => {
    if (members?.length > 0) {
      setActiveUser(members[0]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [members]);

  useEffect(() => {
    if (activeUser) {
      dispatch(getCurrentWeekPlanification(activeUser.userId));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeUser]);

  return (
    <div className="activity-tracking-sidebar ">
      <div className="activity-tracking-sidebar__header">
        <InputGroup inside className="profile-search-input">
          <Input
            size="lg"
            type="text"
            name="search"
            placeholder="Recherche par délégué"
            onChange={(value) => setSearchValue(value.toLowerCase())}
          />
          <InputGroup.Button>
            <Icon path={mdiMagnify} size={1} />
          </InputGroup.Button>
        </InputGroup>
      </div>
      <div className="activity-tracking-sidebar__body phalcon-scroll-bar">
        {members && members.length > 0
          ? members
              .filter((e) => filterMembers(e))
              .map((member) => (
                <ActivityTrackingUserCard
                  key={member.userId}
                  active={activeUser?.userId === member.userId}
                  user={member}
                  onClick={() => handleSelectUser(member)}
                />
              ))
          : null}
      </div>
    </div>
  );
};

export default ActivityTrackingSidebar;
