import { mdiTune } from "@mdi/js";
import Icon from "@mdi/react";
import moment from "moment";
import React from "react";
import { useForm } from "react-hook-form";
import { Form, Popover, Whisper } from "rsuite";
import { useHandleResize } from "../../../../utils/useHandleResize";
import { CustomButton } from "../../../01-atoms/Buttons/Buttons";
import { DateRangeField } from "../../../02-molecules/forms/DateRangeField/DateRangeField";
import { ReportFilterType } from "../../../04-templates/Reports/useReportFilters";

// eslint-disable-next-line @typescript-eslint/no-unused-vars
type Props = {
  captureFilter: (data: ReportFilterType) => void;
  setAppliedFilters: React.Dispatch<React.SetStateAction<string[]>>;
};
type ReportFilterInputs = {
  date: [Date, Date] | undefined;
};

export const ReportFilter = ({ captureFilter, setAppliedFilters }: Props) => {
  const deviceSize = useHandleResize();
  const initialDateValue: string[] = [
    moment().startOf("month").format("L"),
    moment().format("L"),
  ];
  const initForm: ReportFilterInputs = {
    date: undefined,
  };
  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<ReportFilterInputs>({
    defaultValues: initForm,
    criteriaMode: "all",
    reValidateMode: "onChange",
  });

  const onSubmit = handleSubmit((data) => {
    let { date } = data;
    let startDate: string =
      date && date.length ? moment(date[0]).format("L") : initialDateValue[0];
    let endDate: string =
      date && date.length ? moment(date[1]).format("L") : initialDateValue[1];
    setAppliedFilters([startDate, endDate]);
    captureFilter(data);
  });

  const renderMenu = ({ onClose, left, top, className }: any, ref: any) => {
    return (
      <Popover
        ref={ref}
        className={className + " bi-filter__whisper"}
        style={{ left, top }}
        full
      >
        <Form>
          <div className="bi-filter__inner" style={{ boxShadow: "none" }}>
            <DateRangeField
              control={control}
              inputName="Date"
              label=""
              errors={errors}
              onSubmit={onSubmit}
            />
          </div>
        </Form>
      </Popover>
    );
  };
  return (
    <div className="bi-filter__wrapper">
      <Whisper
        placement="autoVerticalEnd"
        trigger="click"
        speaker={renderMenu}
        preventOverflow
      >
        <div>
          <CustomButton
            text={["Mobile", "Tablet"].includes(deviceSize) ? "" : "Filtrer"}
            icon={<Icon path={mdiTune} size={1} />}
            style={{ backgroundColor: "#20ABE3", color: "white" }}
          />
        </div>
      </Whisper>
    </div>
  );
};
