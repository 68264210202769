import { createSlice } from "@reduxjs/toolkit";
import { API_DEV } from "../../utils/apiRoute";
import { axiosPrivate } from "../../utils/interceptors";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { Facility } from "../../types/Facility.model";

export interface FilteredFacilitiesStyle  {
  data: Facility[];
  pagination: {
    pageNumber: number;
    pageSize: number;
    totalCount: number;
    totalPages: number;
  };
};
export interface facilitiestate {
  requestState: "loading" | "idle";
  requestStateForm: boolean;
  facilities: Facility[];
  facilityById: Facility | null;
  filteredFacilities: FilteredFacilitiesStyle;
  toBeExportedFacility: FilteredFacilitiesStyle;
  errors: any;
}

export const addFacility = createAsyncThunk(
  "[Facilities]_add_",
  async (payload: any, { rejectWithValue, dispatch }) => {
    try {
      await axiosPrivate.post(`${API_DEV}/Facility`, payload);
    } catch (err: any) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const fetchFacilities = createAsyncThunk(
  "[Facilities]_fetchAll_",
  async (_, { rejectWithValue }) => {
    try {
      let response = await axiosPrivate.get(`${API_DEV}/Facility`);
      return response.data;
    } catch (error) {
      return rejectWithValue(JSON.parse(JSON.stringify(error)));
    }
  }
);
export const fetchFacilititesWithoutCabinets = createAsyncThunk(
  "[Facilities]_fetchAllWithoutCabinets_",
  async (_, { rejectWithValue }) => {
    try {
      let response = await axiosPrivate.post(
        `${API_DEV}/Facility`, //to do
        {}
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(JSON.parse(JSON.stringify(error)));
    }
  }
);
export const fetchFacilityById = createAsyncThunk(
  "[Facilities]_fetchById_",
  async (id: number, { rejectWithValue }) => {
    try {
      let response = await axiosPrivate.get(`${API_DEV}/Facility/${id}`);
      return response.data;
    } catch (error) {
      return rejectWithValue(JSON.parse(JSON.stringify(error)));
    }
  }
);

export const getFilteredFacilities = createAsyncThunk(
  "[Facilities]_getFiltered_",
  async (options: any, { rejectWithValue }) => {
    try {
      let response = await axiosPrivate.post(
        `${API_DEV}/Facility/Filters`, //to do
        options
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(JSON.parse(JSON.stringify(error)));
    }
  }
);

export const getExportedFacilityList = createAsyncThunk(
  "[Facilities]_getExportedFacilityList_",
  async (_, { rejectWithValue }) => {
    try {
      let response = await axiosPrivate.post(
        `${API_DEV}/Facility/Filters`, 
        {
          search: {},
          pagination: null,
        }
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(JSON.parse(JSON.stringify(error)));
    }
  }
);

export const updateFacility = createAsyncThunk(
  "[Facilities]_update_",
  async (payload: any, { rejectWithValue }) => {
    try {
      let response = await axiosPrivate.put(`${API_DEV}/Facility`, payload);
      return response.data;
    } catch (err: any) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const deleteFacility = createAsyncThunk(
  "[Facilities]_delete_",
  async (
    payload: { itemId: number; password: string },
    { rejectWithValue }
  ) => {
    try {
      let response = await axiosPrivate.post(
        `${API_DEV}/Facility/Delete`,
        payload
      );
      return response.data;
    } catch (err: any) {
      return rejectWithValue(err.response.data);
    }
  }
);

const initialState: facilitiestate = {
  requestState: "idle",
  requestStateForm: false,
  facilities: [],
  facilityById: null,
  filteredFacilities: {
    data: [],
    pagination: {
      pageNumber: 0,
      pageSize: 0,
      totalCount: 0,
      totalPages: 0,
    },
  },
  errors: null,
  toBeExportedFacility: {
    data: [],
    pagination: {
      pageNumber: 0,
      pageSize: 0,
      totalCount: 0,
      totalPages: 0,
    },
  },
};

export const facilitySlice = createSlice({
  name: "[facility]",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(addFacility.pending, (state) => {
      state.requestState = "loading";

      state.errors = null;
    });
    builder.addCase(addFacility.fulfilled, (state) => {
      state.requestState = "idle";
      state.requestStateForm = !state.requestStateForm;
      state.errors = null;
    });
    builder.addCase(addFacility.rejected, (state, { payload }) => {
      state.requestState = "idle";
      state.errors = payload;
    });

    builder.addCase(fetchFacilities.pending, (state) => {
      state.requestState = "loading";
      state.errors = null;
    });
    builder.addCase(fetchFacilities.fulfilled, (state, { payload }) => {
      state.requestState = "idle";
      state.facilities = payload;
      state.errors = null;
    });
    builder.addCase(fetchFacilities.rejected, (state, { payload }) => {
      state.requestState = "idle";
      state.errors = payload;
    });
    builder.addCase(fetchFacilititesWithoutCabinets.pending, (state) => {
      state.requestState = "loading";
      state.errors = null;
    });
    builder.addCase(
      fetchFacilititesWithoutCabinets.fulfilled,
      (state, { payload }) => {
        state.requestState = "idle";
        state.facilities = payload;
        state.errors = null;
      }
    );
    builder.addCase(
      fetchFacilititesWithoutCabinets.rejected,
      (state, { payload }) => {
        state.requestState = "idle";
        state.errors = payload;
      }
    );

    builder.addCase(fetchFacilityById.pending, (state) => {
      state.requestState = "loading";
      state.errors = null;
    });
    builder.addCase(fetchFacilityById.fulfilled, (state, { payload }) => {
      state.requestState = "idle";
      state.facilityById = payload;
      state.errors = null;
    });
    builder.addCase(fetchFacilityById.rejected, (state, { payload }) => {
      state.requestState = "idle";
      state.errors = payload;
    });
    builder.addCase(getFilteredFacilities.pending, (state) => {
      state.requestState = "loading";
      state.errors = null;
    });
    builder.addCase(getFilteredFacilities.fulfilled, (state, { payload }) => {
      state.requestState = "idle";
      state.filteredFacilities = payload;
      state.errors = null;
    });
    builder.addCase(getFilteredFacilities.rejected, (state, { payload }) => {
      state.requestState = "idle";
      state.filteredFacilities = {
        data: [],
        pagination: {
          pageNumber: 0,
          pageSize: 0,
          totalCount: 0,
          totalPages: 0,
        },
      };
      state.errors = payload;
    });

    builder.addCase(updateFacility.pending, (state) => {
      state.requestState = "loading";
      state.errors = null;
    });
    builder.addCase(updateFacility.fulfilled, (state, { payload }) => {
      state.requestState = "idle";
      state.errors = null;
    });
    builder.addCase(updateFacility.rejected, (state, { payload }) => {
      state.requestState = "idle";
      state.errors = payload;
    });

    builder.addCase(deleteFacility.pending, (state) => {
      state.requestState = "loading";
      state.errors = null;
    });
    builder.addCase(deleteFacility.fulfilled, (state, { payload }) => {
      state.requestState = "idle";
      state.errors = null;
    });
    builder.addCase(deleteFacility.rejected, (state, { payload }) => {
      state.requestState = "idle";
      state.errors = payload;
    });
    // builder.addCase(deletefacilityRange.pending, (state) => {
    //   state.requestState = "loading";
    //   state.errors = null;
    // });
    // builder.addCase(deletefacilityRange.fulfilled, (state, { payload }) => {
    //   state.requestState = "idle";
    //   state.errors = null;
    // });
    // builder.addCase(deletefacilityRange.rejected, (state, { payload }) => {
    //   state.requestState = "idle";
    //   state.errors = payload;
    // });
  },
});

export default facilitySlice.reducer;
