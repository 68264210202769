import { Facility } from "../../../../types/Facility.model";
import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../../redux/hooks";
import { RootState } from "../../../../redux/store";
import {
  toggleAddfacilityDrawer,
  toggleUpdatefacilityDrawer,
} from "../../../../redux/UI/uiSlice";
import {
  preparefacilityTypesOptions,
  prepareTagsOptions,
} from "../../../../helpers/GlobalFormHelpers";
import { getDefaultLocalitiesOptions } from "../../../../redux/Locality/localitySlice";
import { Phone } from "../../../../types/Phone.model";
import { Tag } from "../../../../types/Tag.model";
import { ServiceType } from "../../../../types/ServiceType.model";
import { Chief } from "../../../../types/Chief.modal";
import { Locality } from "../../../../types/Locality.model";

export interface facilityFormInputs {
  facilityId?: number | null;
  facilityType: {
    facilityTypeId: number | null;
    facilityTypeName: string;
  };
  facilityName: string;
  phones: Phone[];
  address: {
    addressId: number | null;
    streetName: string;
    addressNote: string;
    localities: Locality[];
  };
  sector: number | null;
  services: {
    serviceId?: number | null;
    serviceType: ServiceType;
    facilityId?: number | null;
    servicePhone: Phone;
    chiefId?: number | null;
    chief?: Chief | null;
  }[];
  tags: Tag[] | number[];
}

export const preparefacilityFormPayload = (formOutput: facilityFormInputs) => {
  return {
    facilityId: formOutput.facilityId,
    facilityTypeId: formOutput.facilityType.facilityTypeId,
    facilityName: formOutput.facilityName,
    phones: formOutput.phones,
    address: {
      addressId: formOutput.address?.addressId || null,
      streetName: formOutput.address?.streetName,
      addressNote: formOutput.address?.addressNote,
      localities: formOutput.address?.localities,
    },
    sector: formOutput.sector,
    services: formOutput.services?.map((service) => ({
      //to improve
      serviceId: service.serviceId,
      serviceTypeId: service.serviceType.serviceTypeId,
      chiefId: service.chiefId,
      servicePhones: [service.servicePhone],
    })),
    tags: formOutput.tags?.map((tag) => {
      return typeof tag === "number" ? { tagId: tag } : { tagName: tag };
    }) || [],
  };
};

export const fieldIsRequired = "Ce champ est requis";
type Props = {
  formContext: "add" | "update";
};
type FormOptions = {
  facilityTypesOptions: {
    key: number | null;
    label: string;
    value: number | null;
  }[];
  tagsOptions: {
    key: number;
    label: string;
    value: number | string;
  }[];
};

const useFacilityForm = ({ formContext }: Props) => {
  const dispatch = useAppDispatch();
  const { addfacilityDrawerIsOpen, updatefacilityDrawerIsOpen } =
    useAppSelector((state: RootState) => state.UI);
  const { facilityById } = useAppSelector((state: RootState) => state.facility);

  const { facilityTypes, tags } = useAppSelector(
    (state: RootState) => state.general
  );

  const [initialValues, setInitialValues] = useState<facilityFormInputs>({
    facilityId: null,
    facilityType: {
      facilityTypeId: null,
      facilityTypeName: "",
    },
    facilityName: "",
    phones: [
      {
        phoneId: null,
        phoneType: null,
        phoneNumber: "",
        phoneDescription: "",
        isPrincipal: true,
      },
    ],
    address: {
      addressId: null,
      streetName: "",
      addressNote: "",
      localities: [],
    },
    sector: null,
    services: [
      {
        serviceId: null,
        serviceType: {
          serviceTypeId: 0,
          serviceTypeName: "",
        },
        chiefId: null,
        servicePhone: {
          phoneId: null,
          phoneType: null,
          phoneNumber: "",
          phoneDescription: "",
          isPrincipal: true,
        },
      },
    ],
    tags: [],
  });

  const [formIsReady, setFormIsReady] = useState(false);

  const [formOptions, setFormOptions] = useState<FormOptions | null>(null);

  //Methods
  const defineFormUI: (formCtx: "add" | "update") => {
    formIsOpen: boolean;
    toggleForm: () => unknown;
  } = (formCtx: "add" | "update") => {
    switch (formCtx) {
      case "add":
        return {
          formIsOpen: addfacilityDrawerIsOpen,
          toggleForm: () => dispatch(toggleAddfacilityDrawer()),
        };

      case "update":
        return {
          formIsOpen: updatefacilityDrawerIsOpen,
          toggleForm: () => dispatch(toggleUpdatefacilityDrawer()),
        };

      default:
        return {
          formIsOpen: false,
          toggleForm: () => null,
        };
    }
  };

  const defineInitialValues = (
    formContext: "add" | "update",
    facilityRecord: Facility | null
  ): facilityFormInputs => {
    const facilityInitialRawData =
      formContext === "update" ? facilityRecord : null;
    if (formContext === "update") {
      facilityInitialRawData &&
        facilityInitialRawData?.address.localities.forEach((loc) => {
          dispatch(getDefaultLocalitiesOptions(loc.localityId.toString()));
        });
    }

    return {
      facilityId: facilityInitialRawData?.facilityId || null,
      facilityType: {
        facilityTypeId:
          facilityInitialRawData?.facilityType?.facilityTypeId || null,
        facilityTypeName:
          facilityInitialRawData?.facilityType?.facilityTypeName || "",
      },
      facilityName: facilityInitialRawData?.facilityName || "",
      sector: facilityInitialRawData?.sector || 0,
      phones: facilityInitialRawData?.phones || [
        {
          phoneId: null,
          phoneType: null,
          phoneNumber: "",
          phoneDescription: "",
          isPrincipal: false,
        },
      ],
      address: {
        addressId: facilityInitialRawData?.address?.addressId || null,
        localities: facilityInitialRawData?.address?.localities &&  facilityInitialRawData?.address?.localities.length > 0  ? [...facilityInitialRawData.address.localities].sort((a,b )=> a.level - b.level) : [],
        streetName: facilityInitialRawData?.address?.streetName || "",
        addressNote: facilityInitialRawData?.address?.addressNote || "",
      },
      services:
        facilityInitialRawData && facilityInitialRawData?.services?.length > 0
          ? facilityInitialRawData?.services.map((loc, index) => ({
              serviceId: loc.serviceId,
              serviceType: loc.serviceType,
              chief: loc.chief || null,
              chiefId: loc.chief?.doctorId || null,
              servicePhone: loc.servicePhones[0] || {
                phoneId: null,
                phoneType: null,
                phoneNumber: "",
                phoneDescription: "",
                isPrincipal: true,
              },
            }))
          : [],
      tags:
        facilityInitialRawData && facilityInitialRawData?.tags.length > 0
          ? facilityInitialRawData?.tags.map((tag) => tag.tagId)
          : [],
    };
  };

  //Global data preparation

  useEffect(() => {
    const tagsOptions = prepareTagsOptions(tags);
    const facilityTypesOptions = preparefacilityTypesOptions(facilityTypes);
    if (facilityTypesOptions.length > 0) {
      setFormOptions({
        facilityTypesOptions,
        tagsOptions,
      });
    }
  }, [facilityTypes, tags]);

  useEffect(() => {
    if (formContext === "add") {
      formOptions ? setFormIsReady(true) : setFormIsReady(false);
    } else {
      facilityById && formOptions
        ? setFormIsReady(true)
        : setFormIsReady(false);
    }
  }, [formIsReady, formOptions, facilityById, formContext]);

  //Methods execution
  const formUI = defineFormUI(formContext);

  useEffect(() => {
    const init = defineInitialValues(formContext, facilityById);
    setInitialValues(init);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [facilityById, formContext]);

  return { initialValues, formUI, formIsReady, formOptions, setFormIsReady };
};

export default useFacilityForm;
