import React, { useEffect, useState, createRef } from "react";
import {
  Input,
  InputGroup,
  Loader,
  Whisper,
  Tooltip,
  Tag,
  Pagination,
  CheckPicker,
} from "rsuite";
import Icon from "@mdi/react";
import { mdiMagnify, mdiTune, mdiPlusCircleOutline } from "@mdi/js";
import { useHandleResize } from "../../../../utils/useHandleResize";
import { FilterTagItem, useFilter } from "../../../../helpers/useFilter";
import { CustomButton } from "../../../01-atoms/Buttons/Buttons";
import { FilterDrawer } from "../../filter/FilterDrawer/FilterDrawer";
import TablePhalcon, { Column } from "../common/TablePhalcon";
import { useAppDispatch } from "../../../../redux/hooks";
import { fetchDoctorSummary } from "../../../../redux/Doctor/doctorSlice";
import FacilityForm from "../../forms/facility/FacilityForm";
import useFacilityTable from "./useFacilityTable";
import {
  FilteredFacilitiesStyle,
  getExportedFacilityList,
} from "../../../../redux/Facility/facilitySlice";
import { FileExporter } from "../../../02-molecules/Exoort-File/FileExporter";
import {
  addressString,
  formatArrayListIntoAStringList,
} from "../../../../helpers/dataFormator";

interface Props {}

const FacilityTable = (props: Props) => {
  const dispatch = useAppDispatch();

  const dataBaseContext = "facility";
  const deviceSize = useHandleResize();
  const {
    filtersDisplayData,
    filterState,
    filterTagsArray,
    selectedFilters,
    captureFilterInputs,
    captureSearchText,
    captureSelectedFilters,
    capturePageTarget,
    captureLimitTarget,
    resetSelectedFilters,
    captureOrderBy,
  } = useFilter({
    dataBaseContext,
  });

  const {
    data,
    columns,
    dataResquestState,
    errors,
    currentPageNumber,
    pageSize,
    totalCount,
    filterDrawerIsOpen,
    toggleFilterDrawer,
    toggleAddDrawer,
  } = useFacilityTable();

  const [searchTerm, setSearchTerm] = useState("");
  const [searchFieldIsLoading, setSearchFieldIsLoading] = useState(false);

  useEffect(() => {
    setSearchFieldIsLoading(true);
    const delayDebounceFn = setTimeout(() => {
      captureSearchText(searchTerm);
    }, 500);
    return () => {
      clearTimeout(delayDebounceFn);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchTerm]);
  useEffect(() => {
    if (dataResquestState === "idle" && !errors) setSearchFieldIsLoading(false);
  }, [dataResquestState, errors]);

  const [columnsOptions, setColumnsOptions] = useState<Column[]>([]);
  const [columnKeys, setColumnKeys] = React.useState<string[]>([]);

  const columnsToShow = columns.filter((column) =>
    columnKeys.some((key) => key === column.key)
  );
  const colPickerRef: any = createRef();

  useEffect(() => {
    setColumnsOptions(columns);
    let defaultColsKeys = columns
      .filter((col) => col.checked)
      .map((column) => column.key);
    setColumnKeys(defaultColsKeys);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handDispatchFacilityListToBeExported = async () => {
    let res = await dispatch(getExportedFacilityList());
    return res.payload;
  };

  // for doctor export
  const formatExportedData = (
    exportToCSV: (exportedData: any, filteName: string) => void
  ) => {
    handDispatchFacilityListToBeExported().then(
      (res: FilteredFacilitiesStyle) => {
        let { data } = res;
        if (data && typeof data === "object" && data.length) {
          let result = data.map((item) => {
            return {
              Type: item?.facilityType?.facilityTypeName || "-",
              "Nom de l'établissement": item?.facilityName || "-",
              Secteur:
                item?.sector === 0 ? "Public" : item?.sector ? "Privé" : "-",
              Téléphones: formatArrayListIntoAStringList(
                item?.phones,
                "phoneNumber"
              ),
              Adresse: addressString(
                item?.address?.localities,
                item?.address?.streetName
              ),
              Services: formatArrayListIntoAStringList(
                item?.services,
                "serviceType",
                "serviceTypeName"
              ),
              Tags: formatArrayListIntoAStringList(item?.tags, "tagName"),
            };
          });
          exportToCSV(result, "Liste des établissement");
        } else {
          exportToCSV(
            [{ Commentaire: "liste vide" }],
            "Liste des établissement"
          );
        }
      }
    );
  };

  return (
    <div className="database-template__wrapper">
      <div className="top-area">
        <div className="top-area__right">
          <InputGroup inside className="database-search-input-group">
            <Input
              size="lg"
              type="text"
              name="search"
              placeholder="Recherche"
              onChange={(value) => setSearchTerm(value)}
              className="database-search-input"
            />
            <InputGroup.Addon>
              {searchFieldIsLoading ? (
                <Loader />
              ) : (
                <Icon path={mdiMagnify} size={1} />
              )}
            </InputGroup.Addon>
          </InputGroup>

          <CustomButton
            text={["Mobile", "Tablet"].includes(deviceSize) ? "" : "Filtrer"}
            icon={<Icon path={mdiTune} size={1} />}
            onClick={() => toggleFilterDrawer()}
            color={"customized"}
          />
          <CustomButton
            text={["Mobile", "Tablet"].includes(deviceSize) ? "" : "Ajouter"}
            icon={<Icon path={mdiPlusCircleOutline} size={1} />}
            onClick={() => {
              toggleAddDrawer();
              dispatch(fetchDoctorSummary());
            }}
            color={"customized"}
          />
          <FileExporter getDataAndFormatDataFunction={formatExportedData} />
        </div>
        <div className="top-area__left">
          <div className="filter-tags__area">
            <div className="filter-tags__title">Filtres Appliqués</div>
            {filterTagsArray.length === 0 && (
              <span className="empty-tags-message">Aucun filtre appliqué</span>
            )}

            {Object.values(filterState).map((filtersArr) => {
              if (filtersArr.length > 0) {
                return (
                  <Whisper
                    trigger="hover"
                    placement={"bottom"}
                    speaker={
                      <Tooltip>
                        <ul>
                          {filtersArr.map(
                            (item: FilterTagItem, index: number) => (
                              <li key={index}>{item.label}</li>
                            )
                          )}
                        </ul>
                      </Tooltip>
                    }
                  >
                    <Tag size="lg" onClick={() => toggleFilterDrawer()}>
                      {filtersArr[0].type === "locality"
                        ? "Localité"
                        : filtersArr[0].type === "facilityType"
                        ? "Type d'établissement"
                        : filtersArr[0].type === "service"
                        ? "Services"
                        : filtersArr[0].type === "potential"
                        ? "Potentiels"
                        : filtersArr[0].type === "tag"
                        ? "Tags"
                        : filtersArr[0].type === "businessUnit"
                        ? "Business Units"
                        : filtersArr[0].type === "speciality"
                        ? "Spécialités"
                        : filtersArr[0].type === "facility"
                        ? "Etablissements"
                        : ""}
                      <span className="selected-tags-count">{` (${filtersArr.length})`}</span>
                    </Tag>
                  </Whisper>
                );
              } else return null;
            })}
          </div>
        </div>
      </div>
      <div className="pagination-area">
        <Pagination
          prev
          next
          first
          last
          ellipsis
          boundaryLinks
          size="sm"
          layout={["total", "-", "limit", "|", "pager", "skip"]}
          total={totalCount}
          limitOptions={[10, 50, 100]}
          limit={pageSize}
          maxButtons={5}
          activePage={currentPageNumber}
          onChangePage={capturePageTarget}
          onChangeLimit={captureLimitTarget}
        />
      </div>
      <div className="table-area">
        <div className="col-selecter">
          <div ref={colPickerRef} className="col-selecter--input">
            <CheckPicker
              sticky
              name="colPicker"
              data={columnsOptions}
              labelKey="label"
              valueKey="key"
              value={columnKeys}
              groupBy="role"
              onChange={setColumnKeys}
              cleanable={false}
              container={() => colPickerRef.current}
              style={{ width: "100%" }}
            />
          </div>
        </div>
        <TablePhalcon
          data={data}
          dataBaseContext={dataBaseContext}
          columns={columnsToShow}
          dataResquestState={dataResquestState}
          captureOrderBy={captureOrderBy}
        />
      </div>
      <FacilityForm formContext="add" />
      <FacilityForm formContext="update" />
      <FilterDrawer
        isOpen={filterDrawerIsOpen}
        onClose={() => toggleFilterDrawer()}
        filtersDisplayData={filtersDisplayData}
        captureFilterInputs={captureFilterInputs}
        captureSelectedFilters={captureSelectedFilters}
        selectedFilters={selectedFilters}
        dataBaseContext={dataBaseContext}
        resetSelectedFilters={resetSelectedFilters}
      />
    </div>
  );
};

export default FacilityTable;
