import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Form, Button } from "rsuite";
import { useForm } from "react-hook-form";
import { useAppDispatch, useAppSelector } from "../../../../redux/hooks";
import { resetPassword } from "../../../../redux/Authentication/authenticationSlice";
import { RootState } from "../../../../redux/store";
import { Link, useNavigate } from "react-router-dom";
import jwtDecode from "jwt-decode";
import { useAuthErrors } from "../../../../utils/useAuthErrors";
import { mdiChevronLeft } from "@mdi/js";
import Icon from "@mdi/react";
import { PasswordField } from "../../../02-molecules/forms/PasswordField/PasswordField";
import "./ResetPasswordForm.scss";
import { toast } from "react-toastify";

interface Props {}
type ResetPasswordInputs = {
  newPassword: string;
  confirmNewPassword: string;
};
export const ResetPasswordForm = ({ ...props }: Props) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const {
    handleSubmit,
    watch,
    control,
    formState: { errors },
  } = useForm<ResetPasswordInputs>({
    criteriaMode: "all",
    reValidateMode: "onChange",
  });

  // Error messages
  const fieldIsRequired = t("common.error_messages.is_required");
  const fieldDoesNotMatch = t(
    "common.error_messages.password_confirmtion_match"
  );
  const fieldDoesNotMatchPattern = t(
    "common.error_messages.password_pattern_match.global_pattern_message"
  );
  const minLengthError = t(
    "common.error_messages.password_pattern_match.min_length"
  );
  const hasLowerCaseError = t(
    "common.error_messages.password_pattern_match.lower_case"
  );
  const hasUpperCaseError = t(
    "common.error_messages.password_pattern_match.upper_case"
  );
  const hasNumberError = t(
    "common.error_messages.password_pattern_match.number"
  );
  const hasSymbolError = t(
    "common.error_messages.password_pattern_match.symbol"
  );
  const { resetPasswordState, error } = useAppSelector(
    (state: RootState) => state.auth
  );

  const onSubmit = handleSubmit((data) => {
    //Decode the token from local storage to get the username
    const token =
      localStorage.getItem("resetPasswordtoken") ||
      sessionStorage.getItem("resetPasswordtoken") ||
      "";
    const tokenDecoded: { UserName: string } | "" = token && jwtDecode(token);

    let resetPasswordPayload: {
      emailLogin: string;
      newPassword: string;
      token: string;
    } = {
      emailLogin: tokenDecoded && tokenDecoded.UserName,
      token: token,
      newPassword: data.newPassword,
    };

    dispatch(resetPassword(resetPasswordPayload));
  });

  const formError = resetPasswordState === "fail";
  const formSuccess = resetPasswordState === "success";

  useEffect(() => {
    formSuccess &&
      toast.success(t("forgot_password_page.reset_success"), {
        position: "bottom-right",
        autoClose: 8000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    formSuccess &&
      setTimeout(() => {
        navigate("/login");
      }, 2000);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resetPasswordState]);
  let errorMessage = useAuthErrors({
    page: "reset_password_page",
    status: error.status,
  });
  useEffect(() => {
    formError &&
      toast.error(errorMessage, {
        position: "bottom-right",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formError]);

  return (
    <div className="reset-password__form__wrapper">
      <Link to={"/login"} className="auth-return-button">
        <Icon path={mdiChevronLeft} size={1} />
      </Link>
      <Form>
        <PasswordField
          label={t("reset_password_page.new_password_label")}
          inputName="newPassword"
          rules={{
            required: fieldIsRequired,
            validate: {
              minLength: (value: string) =>
                /(?=.{8,})/g.test(value) || minLengthError, //password should have a minimum length of 8 characters
              globalPattern: (value: string) =>
                /(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[^A-Za-z0-9])/g.test(
                  value
                ) || fieldDoesNotMatchPattern,
              hasSymbol: (value: string) =>
                /(.*\W.*)/g.test(value) || hasSymbolError, //password should contain at least one special character
              hasLowerCaseCharacter: (value: string) =>
                /(.*[a-z].*)/g.test(value) || hasLowerCaseError, //password should contain at least one lower case character
              hasUpperCaseCharacter: (value: string) =>
                /(.*[A-Z].*)/.test(value) || hasUpperCaseError, //password should contain at least one upper case character
              hasNumber: (value: string) =>
                /(.*\d.*)/g.test(value) || hasNumberError, //password should contain at least one number
            },
          }}
          control={control}
          errors={errors}
          passwordCheck={true}
        />
        <PasswordField
          label={t("reset_password_page.confirm_new_password_label")}
          inputName="confirmNewPassword"
          rules={{
            required: fieldIsRequired,
            validate: (value: string) =>
              value === watch("newPassword") || fieldDoesNotMatch, // confirmNewPassword should be equal to newPassword
          }}
          control={control}
          errors={errors}
          passwordCheck={true}
        />

        <Button
          loading={resetPasswordState === "loading"}
          type="submit"
          appearance="primary"
          onClick={onSubmit}
          className="submit-button"
        >
          {t("reset_password_page.submit_button")}
        </Button>
      </Form>
    </div>
  );
};
