import React from "react";
import { Panel, Badge } from "rsuite";
import { CustomAvatar } from "../../../01-atoms/CustomAvatar/CustomAvatar";
import "./InboxDirectMessages.scss";
type Props = {};

type DirectMessagesItemProps = {
  active: boolean;
};
const DirectMessagesItem = ({ active }: DirectMessagesItemProps) => {
  return (
    <div
      className={`phalcon-inbox__direct-messages--item ${
        active ? "isActive" : ""
      }`}
    >
      <div className="direct-messages-item__top">
        <div className="direct-messages-item__top--sender">
          <CustomAvatar
            image={{
              src: "",
              alt: "",
            }}
            style={{ width: "25px", height: "25px" }}
          />
          <span>Chikhaoui Mohamed Khalil</span>
        </div>
        <div className="direct-messages-item__top--date">8:30</div>
      </div>
      <span className="direct-messages-item__bottom">
        <div className="direct-messages-item__bottom--content">
          Lorem ipsum dolor sit, amet consectetur adipisicing elit. Recusandae
          ipsa distinctio deleniti consequuntur quia odio ab nisi repudiandae
          natus optio?
        </div>
        <Badge content={1} />
      </span>
    </div>
  );
};
const InboxDirectMessages = (props: Props) => {
  return (
    <div className="phalcon-inbox__direct-messages">
      <Panel
        key={"direct-messages"}
        header="Messages Directes"
        defaultExpanded
        className="phalcon-inbox__direct-messages"
      >
        {[
          "Général",
          "Objections",
          "Demandes",
          "Evènements",
          "Objectifs de la semaine",
          "Objectifs du cycle",
          "Commandes",
          "Objectifs de ventes",
        ].map((item, index) => (
          <DirectMessagesItem key={index} active={index === 0} />
        ))}
      </Panel>
    </div>
  );
};

export default InboxDirectMessages;
