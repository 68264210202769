import React from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { Button } from "rsuite";
import { Form, Divider } from "rsuite";
import { useAppDispatch, useAppSelector } from "../../../../redux/hooks";
import { RootState } from "../../../../redux/store";
import { updateCurrentUserPassword } from "../../../../redux/User/userSlice";
import { PasswordField } from "../../../02-molecules/forms/PasswordField/PasswordField";
import "./UpdatePasswordForm.scss";
interface Props {}
type UpdatePasswordInputs = {
  currentPassword: string;
  newPassword: string;
  confirmNewPassword: string;
};
export const UpdatePasswordForm = ({ ...props }: Props) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const {
    handleSubmit,
    control,
    watch,
    formState: { errors },
  } = useForm<UpdatePasswordInputs>({
    criteriaMode: "all",
    reValidateMode: "onChange",
  });
  const { updatePasswordRequestState } = useAppSelector(
    (state: RootState) => state.user
  );

  const onSubmit = handleSubmit((data) => {
    dispatch(
      updateCurrentUserPassword({
        actualPassword: data.currentPassword,
        newPassword: data.newPassword,
      })
    );
  });
  // Error messages
  const fieldIsRequired = t("common.error_messages.is_required");
  const fieldDoesNotMatch = t(
    "common.error_messages.password_confirmtion_match"
  );
  const fieldDoesNotMatchPattern = t(
    "common.error_messages.password_pattern_match.global_pattern_message"
  );
  const minLengthError = t(
    "common.error_messages.password_pattern_match.min_length"
  );
  const hasLowerCaseError = t(
    "common.error_messages.password_pattern_match.lower_case"
  );
  const hasUpperCaseError = t(
    "common.error_messages.password_pattern_match.upper_case"
  );
  const hasNumberError = t(
    "common.error_messages.password_pattern_match.number"
  );
  const hasSymbolError = t(
    "common.error_messages.password_pattern_match.symbol"
  );
  return (
    <div className="custom-form-section">
      <h3 className="form-block-title">Mettre à jour mon mot de passe</h3>
      <Divider style={{ margin: "1rem 0" }} />
      <Form className="update-password-form__wrapper">
        <PasswordField
          label={t("reset_password_page.current_password_label")}
          inputName="currentPassword"
          rules={{
            required: fieldIsRequired,
          }}
          control={control}
          errors={errors}
          passwordCheck={true}
        />
        <PasswordField
          label={t("reset_password_page.new_password_label")}
          inputName="newPassword"
          rules={{
            required: fieldIsRequired,
            validate: {
              minLength: (value: string) =>
                /(?=.{8,})/g.test(value) || minLengthError, //password should have a minimum length of 8 characters
              globalPattern: (value: string) =>
                /(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[^A-Za-z0-9])/g.test(
                  value
                ) || fieldDoesNotMatchPattern,
              hasSymbol: (value: string) =>
                /(.*\W.*)/g.test(value) || hasSymbolError, //password should contain at least one special character
              hasLowerCaseCharacter: (value: string) =>
                /(.*[a-z].*)/g.test(value) || hasLowerCaseError, //password should contain at least one lower case character
              hasUpperCaseCharacter: (value: string) =>
                /(.*[A-Z].*)/.test(value) || hasUpperCaseError, //password should contain at least one upper case character
              hasNumber: (value: string) =>
                /(.*\d.*)/g.test(value) || hasNumberError, //password should contain at least one number
            },
          }}
          control={control}
          errors={errors}
          passwordCheck={true}

        />
        <PasswordField
          label={t("reset_password_page.confirm_new_password_label")}
          inputName="confirmNewPassword"
          rules={{
            required: fieldIsRequired,
            validate: (value: string) =>
              value === watch("newPassword") || fieldDoesNotMatch, // confirmNewPassword should be equal to newPassword
          }}
          control={control}
          errors={errors}
          passwordCheck={true}
        />

        <Button
          loading={updatePasswordRequestState === "loading"}
          type="submit"
          appearance="primary"
          onClick={onSubmit}
          className="submit-button"
        >
          {t("account_page.forms.update_password_button")}
        </Button>
      </Form>
    </div>
  );
};
