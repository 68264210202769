import React from "react";
import { CustomAvatar } from "../../../01-atoms/CustomAvatar/CustomAvatar";
import GearIcon from "@rsuite/icons/Gear";
import "./ProfileHeader.scss";
import { Link } from "react-router-dom";
import User from "../../../../types/User.model";
import { ProfileInfoItemPlaceholder } from "../../../02-molecules/placeholders/Placeholder";
import { Doctor } from "../../../../types/Doctor.model";
import { Seller } from "../../../../types/Seller.model";
import { Pharmacy } from "../../../../types/Pharmacy.model";
import { Person } from "../../../../types/Person.model";
import { isManager } from "../../../../utils/enumHelpers";
import { BusinessUnit } from "../../../../types/BusinessUnit.model";
import { mdiDoctor } from "@mdi/js";
import Icon from "@mdi/react";
type ProfileHeaderContext = "user" | "doctor" | "seller" | "pharmacy";
interface Props {
  person: Person;
  context: ProfileHeaderContext;
}

export const ProfileHeader = ({ person, context }: Props) => {
  const prepareData = (context: ProfileHeaderContext) => {
    switch (context) {
      case "user":
        let user = person as User;
        return {
          fullName: user && `${user.firstName} ${user.lastName}`,
          occupation: isManager(user) ? "Manager" : "Délégué",
          imgUrl: user && user.photoUrl,
          businessUnits: user && user.businessUnits,
        };
      case "doctor":
        let doctor = person as Doctor;

        return {
          fullName:
            doctor && ` ${doctor.title} ${doctor.firstName} ${doctor.lastName}`,
          occupation:
            doctor?.specialities?.find((speciality) => speciality.isPrincipal)
              ?.specialityName || "",
          imgUrl: "",
        };
      case "seller":
        let seller = person as Seller;
        return {
          fullName: seller && `${seller.sellerName}`,
          occupation: seller ? seller?.sellerType?.sellerTypeName : "_",
          imgUrl: "",
        };
      case "pharmacy":
        let pharmacy = person as Pharmacy;
        return {
          fullName: pharmacy && `${pharmacy.name}`,
          occupation: "Pharmacie",
          imgUrl: "",
        };

      default:
        return {
          fullName: "",
          occupation: "",
          imgUrl: "",
        };
    }
  };

  const { fullName, occupation, imgUrl, businessUnits } = prepareData(context);
  return (
    <div className="user-profile-header__wrapper">
      <div className="user-profile-header">
        {person ? (
          <div className="custom-avatar__wrapper">
            {context === "user" ? (
              <CustomAvatar
                image={{
                  src: imgUrl,
                  alt: `${fullName}'s avatar`,
                }}
                style={{ width: "35px", height: "35px" }}
              />
            ) : (
              <Icon path={mdiDoctor} size={1} />
            )}
            <div className="label__wrapper">
              <span className="label">{fullName}</span>
              <span className="sub-label">
                {context === "user" && occupation + " - "}
                {businessUnits &&
                  businessUnits.length > 0 &&
                  businessUnits.map((businessUnit: BusinessUnit) => (
                    <span key={businessUnit.businessUnitId}>
                      {businessUnit?.businessUnitName + " "}
                    </span>
                  ))}
                {context === "doctor" && occupation}
                {context === "seller" && occupation}
              </span>
            </div>
          </div>
        ) : (
          <ProfileInfoItemPlaceholder withCircle />
        )}
        {occupation === "Manager" && (
          <div className="settings_button">
            <Link to="/account/settings">
              <GearIcon />
            </Link>
          </div>
        )}
      </div>
    </div>
  );
};
