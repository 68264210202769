import {
  TableContainer,
  Table,
  Paper,
  TableHead,
  TableBody,
  TableCell,
  TableRow,
  Avatar,
  Tooltip,
  LinearProgress,
  Typography,
  Box,
  makeStyles,
  createStyles,
  Theme,
} from "@material-ui/core";
import { VisitStatisiticsByWeek } from "../../../types/Dashboard.model";

interface TeamVisitsTableProps {
  data: VisitStatisiticsByWeek[];
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    progressCell: {
      display: "flex",
      alignItems: "center",
      width: "100%",
    },
    progressLabel: {
      whiteSpace: "nowrap",
      marginLeft: theme.spacing(2),
    },
    progressBar: {
      height: ".75rem",
      borderRadius: "2rem",
      width: "100%",
    },
    tableTitle: {
      padding: theme.spacing(2),
    },
    totalCell: {
      backgroundColor: "#49cc90",
      height: theme.spacing(4),
      width: theme.spacing(4),
      color: "white",
      padding: theme.spacing(1),
      borderRadius: "100%",
      marginLeft: "auto",
      marginRight: "auto",
    },
  })
);

const TeamVisitsTable = ({ data }: TeamVisitsTableProps) => {
  const classes = useStyles();

  return (
    <TableContainer component={Paper}>
      <Box className={classes.tableTitle}>
        <Typography variant="subtitle2">
          Activité de l'équipe (semanine courante)
        </Typography>
      </Box>
      <Box style={{maxHeight: "700px"}}>
      <Table aria-label="simple table" size="small">
        <TableHead>
          <TableRow>
            <TableCell>Délégué</TableCell>
            <TableCell align="left">Avancement</TableCell>
            <TableCell align="center">Jours sans activités</TableCell>
            <TableCell align="center">Non visitée</TableCell>
            <TableCell align="center">RDV</TableCell>
            <TableCell align="center">EADV</TableCell>
            <TableCell align="center">En attente</TableCell>
            <TableCell align="center">Clôturée</TableCell>
            <TableCell align="center">Total</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data &&
            data.length > 0 &&
            data.map((item) => {
              if (item.delegate) {
                return (
                  <TableRow key={item.delegate.userId}>
                    <TableCell component="th" scope="row" width={40}>
                      <Tooltip
                        placement="right"
                        title={
                          item.delegate.firstName + " " + item.delegate.lastName
                        }
                        aria-label={
                          item.delegate.firstName + " " + item.delegate.lastName
                        }
                      >
                        <Avatar
                          alt={
                            item.delegate.firstName +
                            " " +
                            item.delegate.lastName
                          }
                          src={item.delegate.photoUrl}
                        />
                      </Tooltip>
                    </TableCell>
                    <TableCell align="left" width={280}>
                      <Box className={classes.progressCell}>
                        <LinearProgress
                          className={classes.progressBar}
                          variant="determinate"
                          value={item.progress}
                        />
                        <Typography
                          className={classes.progressLabel}
                          variant="caption"
                        >
                          {Math.round(item.progress) + " %"}
                        </Typography>
                      </Box>
                    </TableCell>
                    <TableCell align="center">{item.inactiveDays}</TableCell>
                    <TableCell align="center">{item.toBeDone}</TableCell>
                    <TableCell align="center">{item.appointment}</TableCell>
                    <TableCell align="center">{item.eadv}</TableCell>
                    <TableCell align="center">{item.pending}</TableCell>
                    <TableCell align="center">
                      <Box className={classes.totalCell}>{item.submitted}</Box>
                    </TableCell>
                    <TableCell align="center">{item.total}</TableCell>
                  </TableRow>
                );
              } else {
                return "";
              }
            })}
        </TableBody>
      </Table>
      </Box>
    </TableContainer>
  );
};

export default TeamVisitsTable;
