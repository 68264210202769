import React, { useState } from "react";
import {
  Table,
  Whisper,
  Popover,
  Dropdown,
  IconButton,
  Modal,
  Button,
  Input,
  InputGroup,
} from "rsuite";
import VisibleIcon from "@rsuite/icons/Visible";
import UnvisibleIcon from "@rsuite/icons/Unvisible";
import { useAppDispatch } from "../../../redux/hooks";

import {
  toggleUpdateDoctorDrawer,
  toggleUpdatefacilityDrawer,
  toggleUpdatePharmacyDrawer,
  toggleUpdateSellerDrawer,
} from "../../../redux/UI/uiSlice";
import {
  fetchFacilityById,
  deleteFacility,
} from "../../../redux/Facility/facilitySlice";
import {
  // deletePharmacy,
  fetchPharmacyById,
} from "../../../redux/Pharmacy/pharmacySlice";
import "./DatabaseTableActionCell.scss";
import {
  deleteSeller,
  fetchSellerById,
} from "../../../redux/Seller/sellerSlice";
import {
  deleteDoctor,
  fetchDoctorById,
  fetchDoctorSummary,
} from "../../../redux/Doctor/doctorSlice";
import Icon from "@mdi/react";
import { mdiDotsVertical, mdiEye, mdiPencil, mdiTrashCan } from "@mdi/js";
import { Link, useNavigate } from "react-router-dom";
// import { deleteRpEvent } from "../../../redux/Rp/rpSlice";

interface ActionCellProps {
  rowData?: any;
  dataKey: any;
  dataBaseContext: "doctor" | "pharmacy" | "seller" | "facility" | "rp";
}
export const DatabaseTableActionCell = ({
  rowData,
  dataKey,
  dataBaseContext,
  ...props
}: ActionCellProps) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [alertIsOpen, setAlertIsOpen] = useState(false);

  const handleLink = () => {
    switch (dataBaseContext) {
      case "facility":
        return "";
      case "pharmacy":
        return `/pharmacy/${rowData[dataKey]}`;
      case "seller":
        return `/seller/${rowData[dataKey]}`;
      case "doctor":
        return `/doctor/${rowData[dataKey]}`;
      case "rp":
        return `/rp/${rowData[dataKey]}`;

      default:
        return "";
    }
  };

  const handleEdit = () => {
    const abortController = new AbortController();
    switch (dataBaseContext) {
      case "facility":
        dispatch(fetchFacilityById(rowData[dataKey]));
        dispatch(toggleUpdatefacilityDrawer());
        dispatch(fetchDoctorSummary());
        break;
      case "pharmacy":
        dispatch(
          fetchPharmacyById({
            id: rowData[dataKey],
            signal: abortController.signal,
          })
        );
        dispatch(toggleUpdatePharmacyDrawer());
        break;
      case "seller":
        dispatch(
          fetchSellerById({
            id: rowData[dataKey],
            signal: abortController.signal,
          })
        );
        dispatch(toggleUpdateSellerDrawer());
        break;
      case "doctor":
        dispatch(fetchDoctorById({ id: rowData[dataKey], signal: null }));
        dispatch(toggleUpdateDoctorDrawer());
        break;
      case "rp":
        navigate(`/rp/edit/${rowData[dataKey]}`);
        break;

      default:
        break;
    }
  };
  const handleDelete = () => {
    setAlertIsOpen(true);
  };

  const renderMenu = ({ onClose, left, top, className }: any, ref: any) => {
    return (
      <Popover ref={ref} className={className} full>
        <Dropdown.Menu onSelect={onClose}  style={{ display: "flex" , padding: "0" }}  >
          <Dropdown.Item style={{ marginRight: ".5rem" }}>
            <Link to={handleLink()}>
              <IconButton
                appearance="subtle"
                style={{ color: "#61affe" }}
                icon={<Icon path={mdiEye} size={0.75} />}
              />{" "}
              Inspecter
            </Link>
          </Dropdown.Item>
          <Dropdown.Item onClick={handleEdit} style={{ marginRight: ".5rem" }}>
            <IconButton
              appearance="subtle"
              style={{ color: "#61affe" }}
              icon={<Icon path={mdiPencil} size={0.75} />}
            />
            Modifier
          </Dropdown.Item>
          <Dropdown.Item
            onClick={handleDelete}
            style={{ marginRight: ".5rem" }}
          >
            <IconButton
              appearance="subtle"
              style={{ color: "#61affe" }}
              icon={<Icon path={mdiTrashCan} size={0.75} />}
            />
            Supprimer
          </Dropdown.Item>
        </Dropdown.Menu>
      </Popover>
    );
  };

  const handleCloseAlert = () => {
    setAlertIsOpen(false);
  };

  const handleActionApprove = () => {
    switch (dataBaseContext) {
      case "facility":
        dispatch(
          deleteFacility({
            itemId: rowData[dataKey] as number,
            password: consentPassword,
          })
        );
        break;
      case "doctor":
        dispatch(
          deleteDoctor({
            itemId: rowData[dataKey] as number,
            password: consentPassword,
          })
        );
        break;
      case "seller": 
        dispatch(
          deleteSeller({ itemId: rowData[dataKey] as number, password: consentPassword })
        );
        break;

      // case "rp":
      //   dispatch(
      //     deleteRpEvent({ itemId: rowData[dataKey] as number, password: consentPassword })
      //   );
      //   break;
      default:
        break;
    }
    setAlertIsOpen(false);
  };
  const [consentPassword, setConsentPassword] = useState<string>("");
  const [visible, setVisible] = useState<boolean>(false);
  return (
    <Table.Cell className="actions-cell" {...props}>
      <Whisper placement="leftStart" trigger="click" speaker={renderMenu}>
        <IconButton
          appearance="subtle"
          style={{ color: "#61affe" }}
          icon={<Icon path={mdiDotsVertical} size={0.75} />}
        />
      </Whisper>
      <Modal
        backdrop="static"
        role="alertdialog"
        open={alertIsOpen}
        onClose={handleCloseAlert}
        size="xs"
        className="database-action-cell-alert-modal"
      >
        <Modal.Body>
          <p className={"delete-message"}>
            Veuillez saisir votre mot de passe afin de confirmer
          </p>
          <InputGroup inside style={{ width: "100%" }}>
            <Input
              style={{ width: "100%" }}
              className="input-full-width"
              autoComplete="none"
              placeholder="********"
              type={visible ? "text" : "password"}
              name={"pwd"}
              value={consentPassword}
              onChange={(value) => setConsentPassword(value)}
            />
            <InputGroup.Button onClick={() => setVisible(!visible)}>
              {visible ? <UnvisibleIcon /> : <VisibleIcon />}
            </InputGroup.Button>
          </InputGroup>
        </Modal.Body>
        <Modal.Footer>
          <Button
            onClick={handleCloseAlert}
            className="cycle-remove-cancel-button"
            appearance="primary"
          >
            Annuler
          </Button>
          <Button
            disabled={!consentPassword}
            className="cycle-remove-submit-button"
            onClick={() => {
              if (consentPassword) {
                handleActionApprove();
              }
            }}
            appearance="primary"
          >
            Supprimer
          </Button>
        </Modal.Footer>
      </Modal>
    </Table.Cell>
  );
};
