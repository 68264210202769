import React, { useEffect, useState } from "react";
import {
  DataGrid,
  GridColDef,
  GridValueGetterParams,
  GridCellParams,
  GridRowParams,
} from "@material-ui/data-grid";
import "./DoctorValidationTable.scss";
import PersonCell from "../common/Cells/PersonCell";
import { Navigate } from "react-router-dom";
import localizedTextsMap from "../../../../utils/dataGridColumnMenuLanguage";
import useValidation from "./useValidation";
import { useAppDispatch } from "../../../../redux/hooks";
import { toggleNavigateToValidationTable } from "../../../../redux/Approval/approvalSlice";

//Handle validationType
const getValidationType = (type: number, validationId: number) => {
  let path = `add/${validationId}`;
  switch (type) {
    case 1:
      path = `update/${validationId}`;
      break;
    case 2:
      path = `delete/${validationId}`;
      break;
  }
  return path;
};
const DoctorValidationTable = () => {
  const columns: GridColDef[] = [
    { field: "id", headerName: "ID", width: 30, hide: true },
    {
      field: "Type",
      headerName: "Type",
      width: 120,
      sortable: true,
      renderCell: (params: GridCellParams) => {
        let radioColor: string =
          params.row.type === 1
            ? "#fca130"
            : params.row.type === 2
            ? "#D94141"
            : "#127EC1";
        return (
          <div
            style={{ color: radioColor, fontWeight: 500, marginLeft: ".5rem" , cursor: "pointer"}}
          >
            {params.row.type === 1
              ? "Mise à jour"
              : params.row.type === 2
              ? "Suppression"
              : "Ajout"}
       
          </div>
        );
      },
      valueGetter: (params: GridValueGetterParams) =>
        `${
          (params.row.type === 2
            ? "Suppression"
            : params.row.type === 1
            ? "Mise à jour"
            : "Ajout") || ""
        } `,
    },
    {
      field: "doctor",
      headerName: "Médecin", 
      width: 350,

      valueGetter: (params: GridValueGetterParams) =>
        `${params.row.doctor.fullName || ""} `,
    },
    {
      field: "speciality",
      headerName: "Spécialité",
      width: 140,
      valueGetter: (params: GridValueGetterParams) =>
        `${params.row.speciality.abreviation || ""} `,
    },
    {
      field: "potential",
      headerName: "Pot.",
      width: 110,
      renderCell: (params: GridCellParams) => {
        return (
          <div
            className={`potential-color pot${params.row.potential.potentialId} `}
          >
            {" "}
            {params.row.potential.potentialName}{" "}
          </div>
        );
      },
      valueGetter: (params: GridValueGetterParams) =>
        `${params.row.potential.potentialName}`,
    },
    {
      field: "facility",
      headerName: "Etab.",
      minWidth: 200,
      flex: 1,
      resizable: true,
    },
    {
      field: "governorate",
      headerName: "Gouvernorat",
      width: 200,
    },
    {
      field: "delegation",
      headerName: "Delegation",
      width: 200,
    },
    {
      field: "phoneNumber",
      headerName: "Téléphone",
      width: 150,
    },
    {
      field: "delegate",
      headerName: "Délégué",
      minWidth: 200,
      flex: 1,
      resizable: true,
      renderCell: (params: GridCellParams) => {
        return (
          <PersonCell
            item={JSON.stringify({
              name: `${params.row.delegate.firstName} ${params.row.delegate.lastName}`,
              userId: params.row.delegate.userId,
              photoUrl: params.row.delegate?.photoUrl,
            })}
          />
        );
      },
      valueGetter: (params: GridValueGetterParams) =>
        `${params.row.delegate.firstName} ${params.row.delegate.lastName}`,
    },
  ];
  const dispatch = useAppDispatch();
  const { approvalData, requestState } = useValidation();
  useEffect(() => {
    dispatch(toggleNavigateToValidationTable(false));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [navigateTo, setNavigateTo] = useState<string>("");
  const onRowClick = (params: GridRowParams) => {
    const link = getValidationType(params.row.type || 0, params.row.id);
    setNavigateTo(link);
  };

  return (
    <div className="doctor-validation__wrapper">
      {navigateTo && <Navigate to={navigateTo} replace />}
      <DataGrid
        rows={approvalData ? approvalData : []}
        loading={requestState === "loading" && !approvalData.length}
        onRowClick={onRowClick}
        columns={columns}
        pageSize={100}
        rowsPerPageOptions={[5]}
        checkboxSelection={false}
        disableSelectionOnClick
        localeText={localizedTextsMap}
      />
    </div>
  );
};

export default DoctorValidationTable;
