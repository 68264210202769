import { Grid, makeStyles, createStyles, Theme,CircularProgress } from "@material-ui/core";
// import GeographicInsights from "./GeographicInsights";
import GlobalInsights from "./GlobalInsights";
import TeamVisitsTable from "./TeamVisitsTable";
import UntreatedRequestsTable from "./UntreatedRequestsTable";
import UntreatedObjectionsTable from "./UntreatedObjectionsTable";
import useDashboard from "./useDashboard";
import TeamActivities from "./TeamActivities";
import "./Dashboard.scss";
import TeamDailyVisitsTable from "./TeamDailyVisistsTable";

interface DashboardProps {}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    separation: {
      marginTop: theme.spacing(1),
    },
  })
);

const Dashboard = (props: DashboardProps) => {
  const [
    globalInsights,
    visitsInsights,
    visitsStatisticsByWeek,
    untreatedRequests,
    untreatedObjections,
    activitiesInsights,
    // geographicInsights,
    dailyVisitsStatistics,
    ready,
    // requestState,
  ] = useDashboard();

  const classes = useStyles();
  
 
  return (
    <>
      {ready ? (
        <>
            <Grid item xs={12}>
              <GlobalInsights
                globalInsights={globalInsights}
                visitsInsights={visitsInsights}
              />
            </Grid>
          <Grid container spacing={2} className={classes.separation}>
            <Grid item xs={12} lg={10} className="visit_Dashboard_Section">
              <TeamVisitsTable data={visitsStatisticsByWeek} />
            <TeamDailyVisitsTable data={dailyVisitsStatistics} />
              <UntreatedRequestsTable untreatedRequests={untreatedRequests} />
              <UntreatedObjectionsTable
                untreatedObjections={untreatedObjections}
              />
            </Grid>
            <Grid item xs={12} lg={2} className="visit_Dashboard_Section">
              <TeamActivities data={activitiesInsights} />
            </Grid>
          </Grid>
        </>
      ) : (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            height: "77vh",
            alignItems: "center",
          }}
        >
          <CircularProgress size={50} />
        </div>
      )}
    </>
  );
};

export default Dashboard;
