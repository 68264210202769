import React from "react";
import useBiRequests from "./useBiVisits";
import PhalconPieChart from "../../../03-organisms/charts/PhalconPieChart";
import PhalconBarChart from "../../../03-organisms/charts/PhalconBarChart";
import AnalyticsCard from "../../../03-organisms/analytics/cards/AnalyticsCard";
import { ChartDefinition } from "../BiObjections/BiObjections";
import {
  mdiCalendarRange,
  mdiBriefcaseOutline,
  mdiHumanMaleBoardPoll,
  mdiBriefcaseOffOutline,
  mdiPoll,
  mdiHumanGreetingProximity,
  mdiPulse,
} from "@mdi/js";
import { FlexboxGrid } from "rsuite";
import { BiFilter } from "../../../03-organisms/filter/BiFilter/BiFilter";
import AnalyticsTable from "../../../03-organisms/analytics/tables/AnalyticsTable";
import CustomizedTableForAnalytics from "../../../02-molecules/tables/CustomizedTableForAnalytics";

type Props = {};

const BiVisits = (props: Props) => { 
  const {
    visitByDayType,
    daysCount,
    visitByActivityType,
    visitCount,
    visitsFrequenciesByGovernerat,
    visitAverageAndVisitCovrageByDelegate,
    visitBydoctorSpecilaity,
    couverageVisitsByDoctorSpeciality,
    visitCountByDelegate,
  } = useBiRequests({});
  
  const visitAverageAndVisitCovrageByDelegateColumns: string[] = [
    "Délégué",
    "Moyenne des visites",
    "Taux de couverture (%)",
  ];

  const vistVivistCoverageByDoctorSpeciality: string[] = [
    "Spécialité",
    "Taux de couverture (%)",
  ];

  const charts: ChartDefinition[] = [
    {
      title: "Répartition jours",
      type: "donut",
      labels: visitByDayType.labels,
      series: visitByDayType.series,
      md: 12,
    },
    {
      title: "Répartition jours hors terrain selon activités",
      type: "donut",
      labels: visitByActivityType.labels,
      series: visitByActivityType.series,
      md: 12,
    },
    {
      title: "Nombre de visites | Délégué",
      type: "bar",
      xAxisCategories: visitCountByDelegate.xAxis,
      series: visitCountByDelegate.series,
      stacked: true,
      md: 24,
      yAxis: "",
    },
    {
      title: "Nombre de médecins visités | Spécialité | Potentiels",
      type: "bar",
      xAxisCategories: visitBydoctorSpecilaity.xAxis,
      series: visitBydoctorSpecilaity.series,
      stacked: true,
      md: 24,
      yAxis: "",
    },
 
  ];
  return (
    <div className="phalcon-bi-general__wrapper top-space">
      <div style={{ display: "flex", justifyContent: "end" }}>
        <BiFilter context={"visit"} />
      </div>
      <div className="phalcon-bi-general__cards-area top-space">
        <FlexboxGrid align="bottom">
          <AnalyticsCard
            mainMetric={{
              icon: mdiCalendarRange,
              count: daysCount.total,
              title: "Jours",
            }}
            md={6}
          />
          <AnalyticsCard
            mainMetric={{
              icon: mdiBriefcaseOutline,
              count: daysCount.workinDays,
              title: "Jours terrain",
            }}
            md={6}
          />
          <AnalyticsCard
            mainMetric={{
              icon: mdiHumanMaleBoardPoll,
              count: daysCount.activityDays,
              title: "Jours hors terrain",
            }}
            md={6}
          />
          <AnalyticsCard
            mainMetric={{
              icon: mdiBriefcaseOffOutline,
              count: daysCount.holidays,
              title: "Jours fériés",
            }}
            md={6}
          />
        </FlexboxGrid>
      </div>

      <div className="phalcon-bi-general__charts-area">
        <FlexboxGrid align="bottom">
          {charts.map((chart: any, index: number) => {
            if (["pie", "donut"].includes(chart.type)) {
              return (
                <PhalconPieChart
                  key={index}
                  title={chart.title}
                  type={chart.type}
                  labels={chart.labels}
                  series={chart.series}
                  md={chart.md}
                />
              );
            } else {
              return null;
            }
          })}
        </FlexboxGrid>
        <FlexboxGrid align="bottom">
          <AnalyticsCard
            mainMetric={{
              icon: mdiHumanGreetingProximity,
              count: visitCount.totalVisit,
              title: "Nombre de visites",
            }}
            md={6}
          />
          <AnalyticsCard
            mainMetric={{
              icon: mdiPoll,
              count: visitCount.coveragePercentage,
              title: "Taux de couverture des visites",
              unit: "%",
            }}
            md={6}
            toolTipeTitle ={"Nombre de médecins visités / Nombre de médecins"}
          />

          <AnalyticsCard
            mainMetric={{
              icon: mdiPulse,
              count: visitCount.frequenciesbyPotential.total,
              title: "Moyenne de visites / jour",
            }}
            potentials={visitCount.frequenciesbyPotential.potential}
            md={12}
            toolTipeTitle ={"Nombre de visites / Nombre de jours terrain"}
          />
        </FlexboxGrid>
        <FlexboxGrid align="bottom">
          {charts.map((chart: any, index: number) => {
            if (chart.type === "bar") {
              return (
                <PhalconBarChart
                  key={index}
                  title={chart.title}
                  series={chart.series}
                  xAxisCategories={chart.xAxisCategories}
                  stacked={chart.stacked}
                  md={chart.md}
                  yAxis={chart.yAxis}
                />
              );
            } else {
              return null;
            }
          })}
        </FlexboxGrid>
        <FlexboxGrid>
          <CustomizedTableForAnalytics
            size={12}
            tableTitle=""
            visitAverageAndVisitCovrageByDelegateColumns={
              visitAverageAndVisitCovrageByDelegateColumns
            }
            data={visitAverageAndVisitCovrageByDelegate}
            columnKeyType={[
              { key: "delegate" },
              { key: "visitAverage" },
              { key: "coveragePercentage" },
            ]}
          />
          <CustomizedTableForAnalytics
            size={12}
            tableTitle=""
            visitAverageAndVisitCovrageByDelegateColumns={
              vistVivistCoverageByDoctorSpeciality
            }
            data={couverageVisitsByDoctorSpeciality}
            columnKeyType={[
              { key: "speciality" },
              { key: "coveragePercentage" },
            ]}
          />
        </FlexboxGrid>
        <FlexboxGrid>
          <AnalyticsTable
            generalPotentialByLocality={visitsFrequenciesByGovernerat}
            tableTitle="Répartition nombre de Visites | Localités | Potentiels"
          />
        </FlexboxGrid>
      </div>
    </div>
  );
};

export default BiVisits;
