import {
  makeStyles,
  Paper,
  Theme,
  createStyles,
  Box,
  Typography,
  Grid,
} from "@material-ui/core";
import CircularProgress, {
  CircularProgressProps,
} from "@material-ui/core/CircularProgress";
interface VisitsByPotentialProps {
  total: number;
  potentials: { key: number; label: string; value: number; color: string }[];
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      height: "100%",
      padding: theme.spacing(2),
    },
    typography: {
      marginLeft: theme.spacing(2),
      lineHeight: 1.4,
    },
    progressBox: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
      alignItems: "center",
    },
    circularProgressWithLabel: {
      marginTop: theme.spacing(2),
    },
    backgroundProgress: {
      position: "absolute",
      zIndex: 1,
      right: 0,
    },
    foregroundProgress: {
      position: "relative",
      zIndex: 2,
    },
  })
);

function CircularProgressWithLabel({
  colorRef,
  ...props
}: CircularProgressProps & {
  value: number;
  size: number;
  thickness: number;
  colorRef: string;
}) {
  const classes = useStyles();
  return (
    <Box
      position="relative"
      display="inline-flex"
      className={classes.circularProgressWithLabel}
    >
      <CircularProgress
        variant="determinate"
        style={{ color: colorRef }}
        {...props}
        className={classes.foregroundProgress}
      />
      <CircularProgress
        variant="determinate"
        {...props}
        value={100}
        className={classes.backgroundProgress}
        color="secondary"
      />
      <Box
        top={0}
        left={0}
        bottom={0}
        right={0}
        position="absolute"
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <Typography
          variant="caption"
          component="div"
          color="textPrimary"
        >{`${Math.round(props.value)}%`}</Typography>
      </Box>
    </Box>
  );
}

const VisitsByPotential = ({ total, potentials }: VisitsByPotentialProps) => {
  const classes = useStyles();
  return (
    <Paper
      className={classes.paper}
      elevation={1}
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Grid container>
        <Grid item xs={3}>
          <Box className={classes.progressBox}>
            <Typography variant="caption" color="textPrimary">
              Visites effectuées
            </Typography>
            <CircularProgressWithLabel
              colorRef="#806CFB"
              value={total}
              size={64}
              thickness={6}
              variant="determinate"
            />
          </Box>
        </Grid>
        {potentials.map(({ key, label, value, color }) => (
          <Grid key={key} item xs={3}>
            <Box className={classes.progressBox}>
              <Typography
                variant="caption"
                color="textPrimary"
                style={{ marginBottom: ".5rem" }}
              >
                {label}
              </Typography>
              <CircularProgressWithLabel
                colorRef={color}
                value={value}
                size={56}
                thickness={6}
              />
            </Box>
          </Grid>
        ))}
      </Grid>
    </Paper>
  );
};

export default VisitsByPotential;
