import { useTranslation } from "react-i18next";

interface Props {
  page:
    | "login_page"
    | "forgot_password_page"
    | "token_verification_page"
    | "reset_password_page";
  status: number;
}
/**
 * This hook will display the error message according to the error type
 *
 *
 * @param page - "login_page" | "forgot_password_page" | "token_verification_page" | "reset_password_page"
 * @param status - Error status 
 
 */
export const useAuthErrors = ({ page, status }: Props) => {
  const { t } = useTranslation();
  switch (status) {
    case 401:
      return t(`${page}.errors.unauthorized`);

    case 403:
      return t(`${page}.errors.forbidden`);

    case 404:
      return t(`${page}.errors.unauthorized`);

    default:
      return t(`${page}.errors.server_error`);
  }
};
