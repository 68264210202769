import React, { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import {
  getestimatedSalesTargetListExport,
  getSalesTargetAchivedListExport,
  getThExpectedSalesTarget,
} from "../../../redux/SalesTarget/salesTargetSlice";
import { RootState } from "../../../redux/store";
import {
  AchivedSalesTargetInterface,
  ExpectedSalesTargetInterface,
  SalesTargetUsedInFormInterface,
} from "../../../types/SalesTarget.model";
import AddSalesTarget from "../../03-organisms/forms/addSalesTarget/AddSalesTarget";
import { useSalesTargetFilters } from "./sales-target-buttons/useSalesTargetFilters";
import SalesTarget from "./SalesTarget";
import "./SalesTarget.scss";
import Cookies from "cookies-ts";

export default function SalesTargetPage() {
  const cookies = new Cookies();
  const [formTargetSalesModalOpen, setFormTargetSalesModalOpen] =
    useState<boolean>(false);
  const [updateSalesTargetObject, setUpdateSalesTargetObject] =
    useState<null | {
      productId: number;
      salesObject: SalesTargetUsedInFormInterface;
      monthsId: {label: number | string, value: number | string | null}[];
    }>(null);
    const [formContext, setFormContext] = useState<"addSalesTarget" | "updateSalesTarget">("addSalesTarget")
  const dispatch = useAppDispatch();
  const { salesTargetList, salesTargetrequestState } = useAppSelector(
    (state: RootState) => state.salesTarget
  );
  const {
    captureDelegate,
    captureProducts,
    captureYear,
    captureLimitTarget,
    capturePageTarget,
    globalFilterState,
  } = useSalesTargetFilters();

  useEffect(() => {
    dispatch(getThExpectedSalesTarget(globalFilterState));
    cookies.set("salesTargetFilter", globalFilterState);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [globalFilterState]);




  const prepareTableROwInfo = (
    data: any[] | null,
    context: "achieved" | "estimated"
  ) => {
    if (
      data &&
      typeof data === "object" &&
      data.length &&
      context === "achieved"
    ) {
      return data.reduce(
        (
          accu: any[],
          curr: AchivedSalesTargetInterface,
          currentIndex: number
        ) => {
          return accu.concat([
            {
              id: currentIndex + 1,
              april: curr.april,
              august: curr.august,
              december: curr.december,
              february: curr.february,
              january: curr.january,
              july: curr.july,
              june: curr.june,
              march: curr.march,
              may: curr.may,
              november: curr.november,
              october: curr.october,
              productId: curr.productId,
              productName: curr.productName,
              september: curr.september,
              totalQuantity: curr.totalQuantity,
            },
          ]);
        },
        []
      );
    } else if (
      data &&
      typeof data === "object" &&
      data.length &&
      context === "estimated"
    ) {
      return data.reduce(
        (
          accu: any[],
          curr: ExpectedSalesTargetInterface,
          currentIndex: number
        ) => {
          return accu.concat([
            {
              id: currentIndex + 1,
              april: curr.april,
              august: curr.august,
              december: curr.december,
              february: curr.february,
              january: curr.january,
              july: curr.july,
              june: curr.june,
              march: curr.march,
              may: curr.may,
              november: curr.november,
              october: curr.october,
              productId: curr.productId,
              productName: curr.productName,
              september: curr.september,
              totalQuantity: curr.totalQuantity,
              monthIds: [
                { label: 1, value: curr.januaryId },
                { label: 2, value: curr.februaryId },
                { label: 3, value: curr.marchId },
                { label: 4, value: curr.aprilId },
                { label: 5, value: curr.mayId },
                { label: 6, value: curr.juneId },
                { label: 7, value: curr.julyId },
                { label: 8, value: curr.augustId },
                { label: 9, value: curr.septemberId },
                { label: 10, value: curr.octoberId },
                { label: 11, value: curr.novemberId },
                { label: 12, value: curr.decemberId },
              ],
            },
          ]);
        },
        []
      );
    } else return [];
  };

  const handleExportDispatch = async (
    context: "achievedSales" | "estimatedSales"
  ) => {
    if (context === "achievedSales") {
      let res = await dispatch(
        getSalesTargetAchivedListExport({
          productsId:
            globalFilterState?.searchSalesTargetAchived?.productsId || [],
          year: globalFilterState.searchSalesTargetAchived.year,
          delegateId:
            globalFilterState?.searchSalesTargetAchived?.delegateId || null,
          pagination: {
            pageNumber: 1,
            pageSize: 600,
          },
        })
      );
      return res.payload;
    } else if (context === "estimatedSales") {
      let res = await dispatch(
        getestimatedSalesTargetListExport({
          year: globalFilterState.searchWsSalesTarget.year,
          productsId: globalFilterState?.searchWsSalesTarget?.productsId || [],
          pagination: {
            pageNumber: 1,
            pageSize: 600,
          },
        })
      );
      return res.payload;
    } else {
      return [];
    }
  };

  
  //Format the data that will be exported and export it
  const formatExportedData = (
    exportToCSV: (exportedData: any, filteName: string) => void,
    context: "achievedSales" | "estimatedSales"
  ) => {
    handleExportDispatch(context).then((res: any) => {
      let list =
        context === "achievedSales" ? res.wsSalesTargetOrder : res.salesTargets;
      if (list && list.length) {
        let result = list.map((item: any) => ({
          Année: item?.year || "-",
          Produit: item?.productName || "-",
          Janvier: item?.january || "-",
          Février: item?.february || "-",
          Mars: item?.march || "-",
          Avril: item?.april || "-",
          Mai: item?.may || "-",
          Juin: item?.june || "-",
          Juillet: item?.july || "-",
          Août: item?.august || "-",
          Septembre: item?.september || "-",
          Octobre: item?.october || "-",
          Novombre: item?.november || "-",
          Décembre: item?.december || "-",
          Total: item?.totalQuantity || "-",
        }));
        exportToCSV(
          result,
          `Liste des ${
            context === "achievedSales" ? "objectifs réalisés" : "éstimations"
          } par produit`
        );
      } else {
        exportToCSV(
          [{ Commentaire: "liste vide" }],
          `Liste des ${
            context === "achievedSales" ? "objectifs réalisés" : "éstimations"
          } par produit`
        );
      }
    });
  };
  return (
    <div className="sales-target__wrapper">
      <section>
        <SalesTarget
          context="estimatedSales"
          title="Objectifs des ventes"
          setFormTargetSalesModalOpen={setFormTargetSalesModalOpen}
          expectedSalesTargetList={prepareTableROwInfo(
            salesTargetList?.salesTargestsResponses?.salesTargets || [],
            "estimated"
          )}
          captureYear={captureYear}
          captureProducts={captureProducts}
          captureLimitTarget={captureLimitTarget}
          capturePageTarget={capturePageTarget}
          salesTargetrequestState={salesTargetrequestState}
          pageSize={
            salesTargetList?.salesTargestsResponses?.pagination?.pageSize || 1
          }
          totalCount={
            salesTargetList?.salesTargestsResponses?.pagination?.totalCount || 0
          }
          formatExportedData={formatExportedData}
          setUpdateSalesTargetObject={setUpdateSalesTargetObject}
          setFormContext={setFormContext}
        />
      </section>
      <section className="sales-targed-sections-padding">
        <SalesTarget
          context="achievedSales"
          title="Ventes réalisées"
          achievedSalesTargetList={prepareTableROwInfo(
            salesTargetList?.wsSalesTargetAchieveds?.wsSalesTargetOrder || [],
            "achieved"
          )}
          listToCompare={prepareTableROwInfo(
            salesTargetList?.salesTargestsResponses?.salesTargets || [],
            "estimated"
          )}
          captureDelegate={captureDelegate}
          captureLimitTarget={captureLimitTarget}
          capturePageTarget={capturePageTarget}
          salesTargetrequestState={salesTargetrequestState}
          pageSize={
            salesTargetList?.wsSalesTargetAchieveds?.pagination?.pageSize || 1
          }
          totalCount={
            salesTargetList?.wsSalesTargetAchieveds?.pagination?.totalCount || 0
          }
          formatExportedData={formatExportedData}
        />
      </section>
      <AddSalesTarget
        formTargetSalesModalOpen={formTargetSalesModalOpen}
        setFormTargetSalesModalOpen={setFormTargetSalesModalOpen}
        updateSalesTargetObject={updateSalesTargetObject}
        setUpdateSalesTargetObject={setUpdateSalesTargetObject}
        formContext={formContext}
        selectYear={globalFilterState?.searchSalesTargetAchived?.year || null}
      />
    </div>
  );
}
