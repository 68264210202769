import React, { useState } from "react";
import { DataGrid, GridCellParams, GridColDef } from "@material-ui/data-grid";
import { Box } from "@material-ui/core";
import localizedTextsMap from "../../../../utils/dataGridColumnMenuLanguage";
import ActionButtonCell from "../../../03-organisms/tables/common/Cells/ActionButtonCell";
import ClientCell from "../../../03-organisms/tables/common/Cells/ClientCell";
import PersonCell from "../../../03-organisms/tables/common/Cells/PersonCell";
import DateCell from "../../../03-organisms/tables/common/Cells/DateCell";
import { orderStatus } from "../../../03-organisms/filter/orderFilters/OrderFilters";
import TextCell from "../../../03-organisms/tables/common/Cells/TextCell";
import { FormatedDataOrderList } from "../../../../types/Order.model";
import { useAppDispatch, useAppSelector } from "../../../../redux/hooks";
import {
  getDeliveryDetails,
  getOrderDetails,
  getPaymentrDetails,
} from "../../../../redux/Order/orderSlice";
import { mdiBrightnessPercent } from "@mdi/js";
import { Icon } from "@mdi/react";
import { RootState } from "../../../../redux/store";
import { getPotentialId } from "../../../../helpers/dataFormator";
import "../Order.scss";

type Props = {
  setIsDetailsModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setContext: React.Dispatch<
    React.SetStateAction<
      "paymentDetails" | "orderDetails" | "deliveryDetails" | null
    >
  >;
  capturePageTarget: (page: number) => void;
  listIsLoaded: "idle" | "loading";
  pageSize: number;
  currentPageNumber: number;
  captureLimitTarget: (page: number) => void;
  orderRows: FormatedDataOrderList[];
  totalCount: number;
  actionButtonContext:
    | "paymentDetails"
    | "orderDetails"
    | "deliveryDetails"
    | null;
  setClientInfo: React.Dispatch<React.SetStateAction<string>>;
};

export default function OrderTables({
  totalCount,
  orderRows,
  setIsDetailsModalOpen,
  setContext,
  captureLimitTarget,
  capturePageTarget,
  pageSize,
  currentPageNumber,
  actionButtonContext,
  listIsLoaded,
  setClientInfo,
}: Props) {
  const dispatch = useAppDispatch();
  const { potentials } = useAppSelector((state: RootState) => state.general);
  const [selectedRowId, setSelectedRowId] = useState<number | null>(null);
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null
  );
  const handleClickActionButton = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    setAnchorEl(event.currentTarget);
  };
  const handleCloseActionButton = () => {
    setAnchorEl(null);
  };
  const handleOpenActionButton = (id: any) => {
    return selectedRowId === id && Boolean(anchorEl);
  };

  const handleDispatchServiceByContext = (
    context: "paymentDetails" | "orderDetails" | "deliveryDetails" | null,
    id: number
  ) => {
    if (context === "deliveryDetails") {
      dispatch(getDeliveryDetails(id));
    } else if (context === "orderDetails") {
      dispatch(getOrderDetails(id));
    } else if (context === "paymentDetails") {
      dispatch(getPaymentrDetails(id));
    } else return;
  };

  const columns: GridColDef[] = [
    {
      field: "id",
      headerName: "ID",
      width: 0,
      hide: true,
      sortable: false,
      filterable: false,
    },
    {
      field: "reference",
      headerName: "Référence",
      width: 170,
      sortable: false,
      filterable: false,
      renderCell: (params: GridCellParams) => {
        return <TextCell item={params.row.reference} />;
      },
    },
    {
      field: "delegate",
      headerName: "Délégué",
      width: 270,
      sortable: false,
      filterable: false,
      renderCell: (params: GridCellParams) => {
        return <PersonCell item={params.row.delegate} />;
      },
    },
    {
      field: "client",
      headerName: "Client",
      width: 270,
      sortable: false,
      filterable: false,
      renderCell: (params: GridCellParams) => {
        return <ClientCell item={params.row.client} />;
      },
    },
    {
      field: "potential",
      headerName: "Pot.",
      width: 100,
      sortable: false,
      filterable: false,
      renderCell: (params: GridCellParams) => {
        return (
          <div
            className={`potential-color pot${getPotentialId(
              potentials,
              params?.row?.potential || ""
            )} `}
          >
            {params.row.potential}
          </div>
        );
      },
    },
    {
      field: "facNumb",
      headerName: "N° Facture",
      width: 170,
      sortable: false,
      filterable: false,
      renderCell: (params: GridCellParams) => {
        return <TextCell item={params.row.facNumb} />;
      },
    },
    {
      field: "orderCreationDate",
      headerName: "Date Commande",
      type: "date",
      sortable: false,
      filterable: false,
      width: 170,
      renderCell: (params: GridCellParams) => {
        return <DateCell item={params.row.orderCreationDate} />;
      },
    },
    {
      field: "desiredDeliveryDate",
      headerName: "Date livraison souhaitée",
      width: 170,
      sortable: false,
      filterable: false,
      renderCell: (params: GridCellParams) => {
        return <DateCell item={params.row.desiredDeliveryDate} />;
      },
    },
    {
      field: "deadLineDate",
      headerName: "Date d'échéance",
      sortable: false,
      width: 150,
      filterable: false,
      renderCell: (params: GridCellParams) => {
        return <DateCell item={params.row.deadLineDate} />;
      },
    },
    {
      field: "delivery",
      headerName: "Livraison",
      sortable: false,
      width: 170,
      filterable: false,
      renderCell: (params: GridCellParams) => {
        return (
          <div
            className={`order-Status-color ${params.row.delivery.codeStatus}`}
          >
            {orderStatus[params.row.delivery.codeStatus]}
          </div>
        );
      },
    },
    {
      field: "payment",
      headerName: "Paiement",
      sortable: false,
      width: 170,
      filterable: false,
      renderCell: (params: GridCellParams) => {
        return (
          <div
            className={`order-Status-color ${params.row.payment.codeStatus}`}
          >
            {orderStatus[params.row.payment.codeStatus]}
          </div>
        );
      },
    },
    {
      field: "priceHT",
      headerName: "HT (DT)",
      sortable: false,
      filterable: false,
      width: 120,
      renderCell: (params: GridCellParams) => {
        return <TextCell item={params.row.priceHT} />;
      },
    },
    {
      field: "priceTTC",
      headerName: "TTC (DT)",
      sortable: false,
      filterable: false,
      width: 120,
      renderCell: (params: GridCellParams) => {
        return <TextCell item={params.row.priceTTC} />;
      },
    },
    {
      field: "afterDiscount",
      headerName: "TTC après remise (DT)",
      sortable: false,
      filterable: false,
      width: 120,
      renderCell: (params: GridCellParams) => {
        return (
          <div style={{ display: "flex" }}>
            <TextCell item={params.row.afterDiscount} />
            {params.row.afterDiscount !== "-" ? (
              <Icon
                path={mdiBrightnessPercent}
                size={0.5}
                color={"#111111"}
                style={{ marginTop: "0.5rem" }}
              />
            ) : (
              ""
            )}
          </div>
        );
      },
    },
    {
      field: "unpaidAmount",
      headerName: "Reste (DT)",
      sortable: false,
      width: 120,
      filterable: false,
      renderCell: (params: GridCellParams) => {
        return <TextCell item={params.row.unpaidAmount} />;
      },
    },
    {
      field: "actions",
      headerName: "Actions",
      sortable: false,
      filterable: false,
      renderCell: (params: GridCellParams) => {
        return (
          <ActionButtonCell
            anchorEl={anchorEl}
            onClick={(event) => {
              handleClickActionButton(event);
              setSelectedRowId(params.row.id);
              setClientInfo(params.row.client);
            }}
            handleDispatchServiceByContext={handleDispatchServiceByContext}
            popOverIsOpen={handleOpenActionButton(params.row.id)}
            handlePopOverClose={handleCloseActionButton}
            reference={params.row.id}
            setIsDetailsModalOpen={setIsDetailsModalOpen}
            setContext={setContext}
            selectedRowId={selectedRowId}
          />
        );
      },
      width: 100,
    },
  ];

  return (
    <Box className="order-table-style">
      <DataGrid
        rows={orderRows}
        columns={columns}
        loading={listIsLoaded === "loading"}
        pageSize={pageSize}
        rowsPerPageOptions={[10, 50, 100]}
        disableSelectionOnClick
        onPageChange={capturePageTarget}
        onPageSizeChange={captureLimitTarget}
        localeText={localizedTextsMap}
        paginationMode="server"
        rowCount={totalCount}
      />
    </Box>
  );
}
