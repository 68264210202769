import { createSlice } from "@reduxjs/toolkit";
/* import { createSlice } from "@reduxjs/toolkit"; */
import { API_DEV } from "../../utils/apiRoute";
import { axiosPrivate } from "../../utils/interceptors";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { Request } from "../../types/Request.model";
import { GlobalRequestFilterState } from "../../components/03-organisms/filter/RequestFilter/useFilterRequest";

type FilteredRequests = {
  data: Request[];
  pagination: {
    pageNumber: number;
    pageSize: number; 
    totalCount: number;
    totalPages: number;
  };
};

export interface RequestState {
  requestState: "idle" | "loading";
  requestById: Request | null;
  filteredRequests: FilteredRequests;
  errors: any;
}

export const addRequest = createAsyncThunk(
  "[Request]_add_",
  async (payload: any, { rejectWithValue }) => {
    try {
      const response = await axiosPrivate.post(`${API_DEV}/Request`, payload);
      return response.data;
    } catch (error) {
      console.log(JSON.parse(JSON.stringify(error)));
      return rejectWithValue(JSON.parse(JSON.stringify(error)));
    }
  }
);
export const updateRequest = createAsyncThunk(
  "[Request]_update_",
  async (payload: any, { rejectWithValue }) => {
    try {
      const response = await axiosPrivate.put(`${API_DEV}/Request`, payload);
      return response.data;
    } catch (error) {
      console.log(JSON.parse(JSON.stringify(error)));
      return rejectWithValue(JSON.parse(JSON.stringify(error)));
    }
  }
);
export const deleteRequest = createAsyncThunk(
  "[Request]_delete_",
  async (id: number, { rejectWithValue }) => {
    try {
      const response = await axiosPrivate.delete(`${API_DEV}/Request/${id}`);
      return response.data;
    } catch (error) {
      console.log(JSON.parse(JSON.stringify(error)));
      return rejectWithValue(JSON.parse(JSON.stringify(error)));
    }
  }
);

export const fetchRequestById = createAsyncThunk(
  "[Request]_fetchById_",
  async (id: number, { rejectWithValue }) => {
    try {
      const response = await axiosPrivate.get(`${API_DEV}/Request/${id}`);
      return response.data;
    } catch (error) {
      console.log(JSON.parse(JSON.stringify(error)));
      return rejectWithValue(JSON.parse(JSON.stringify(error)));
    }
  }
);
export const fetchFilteredRequest = createAsyncThunk(
  "[Request]_fetchByFiltered_",
  async (payload: GlobalRequestFilterState, { rejectWithValue }) => {
    try {
      const response = await axiosPrivate.post(
        `${API_DEV}/Request/Filters`,
        payload
      );
      return response.data;
    } catch (error) {
      console.log(JSON.parse(JSON.stringify(error)));
      return rejectWithValue(JSON.parse(JSON.stringify(error)));
    }
  }
);

export const updateRequestResponse = createAsyncThunk(
  "[Request]_updateResponse_",
  async (payload: any, { rejectWithValue }) => { 
    try {
      const response = await axiosPrivate.post(`${API_DEV}/Request/RespondToRequest`, payload);
      return response.data;
    } catch (error) {
      console.log(JSON.parse(JSON.stringify(error)));
      return rejectWithValue(JSON.parse(JSON.stringify(error)));
    }
  }
);

const initialState: RequestState = {
  requestState: "idle",
  requestById: null,
  filteredRequests: {
    data: [],
    pagination: {
      pageNumber: 1,
      pageSize: 10,
      totalCount: 0,
      totalPages: 0,
    },
  },
  errors: null,
};

export const requestSlice = createSlice({
  name: "[Request]",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(addRequest.pending, (state, { payload }) => {
      state.requestState = "loading";
      state.errors = null;
    });
    builder.addCase(addRequest.fulfilled, (state, { payload }) => {
      state.requestState = "idle";
      state.errors = null;
    });
    builder.addCase(addRequest.rejected, (state, { payload }) => {
      state.requestState = "idle";
      state.errors = payload;
    });
    builder.addCase(updateRequest.pending, (state, { payload }) => {
      state.requestState = "loading";
      state.errors = null;
    });
    builder.addCase(updateRequest.fulfilled, (state, { payload }) => {
      state.requestState = "idle";
      state.errors = null;
    });
    builder.addCase(updateRequest.rejected, (state, { payload }) => {
      state.requestState = "idle";
      state.errors = payload;
    });
    builder.addCase(deleteRequest.pending, (state, { payload }) => {
      state.requestState = "loading";
      state.errors = null;
    });
    builder.addCase(deleteRequest.fulfilled, (state, { payload }) => {
      state.requestState = "idle";
      state.errors = null;
    });
    builder.addCase(deleteRequest.rejected, (state, { payload }) => {
      state.requestState = "idle";
      state.errors = payload;
    });
    builder.addCase(fetchRequestById.pending, (state, { payload }) => {
      state.requestState = "loading";
      state.requestById = null;
      state.errors = null;
    });
    builder.addCase(
      fetchRequestById.fulfilled,
      (state, { payload }: { payload: any }) => {
        state.requestState = "idle";
        state.requestById = payload;
        state.errors = null;
      }
    );
    builder.addCase(fetchRequestById.rejected, (state, { payload }) => {
      state.requestState = "idle";
      state.requestById = null;
      state.errors = payload;
    });
    builder.addCase(fetchFilteredRequest.pending, (state, { payload }) => {
      state.requestState = "loading";
      state.filteredRequests = {
        data: [],
        pagination: {
          pageNumber: 1,
          pageSize: 10,
          totalCount: 0,
          totalPages: 0,
        },
      };
      state.errors = null;
    });
    builder.addCase(
      fetchFilteredRequest.fulfilled,
      (state, { payload }: { payload: any }) => {
        state.requestState = "idle";
        state.filteredRequests = payload;
        state.errors = null;
      }
    );
    builder.addCase(fetchFilteredRequest.rejected, (state, { payload }) => {
      state.requestState = "idle";
      state.filteredRequests = {
        data: [],
        pagination: {
          pageNumber: 1,
          pageSize: 10,
          totalCount: 0,
          totalPages: 0,
        },
      };
      state.errors = payload;
    });
    builder.addCase(updateRequestResponse.pending, (state, { payload }) => {
      state.requestState = "loading";
      state.errors = null;
    });
    builder.addCase(updateRequestResponse.fulfilled, (state, { payload }) => {
      state.requestState = "idle";
      state.errors = null;
    });
    builder.addCase(updateRequestResponse.rejected, (state, { payload }) => {
      state.requestState = "idle";
      state.errors = payload;
    });
  },
});

export default requestSlice.reducer;
