import Dashboard from "./../../types/Dashboard.model";
import { createSlice } from "@reduxjs/toolkit";
import { axiosPrivate } from "../../utils/interceptors";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { API_DEV } from "../../utils/apiRoute";

export interface BiState {
  requestState: "idle" | "loading";
  dashboardData: Dashboard;
  errors: any;
}

export const getDashboardData = createAsyncThunk(
  "[Dashboard]__getDashboardData__",
  async (_, { rejectWithValue }) => {
    try {
      const globalInsightsResponse = axiosPrivate.get(
        `${API_DEV}/ManagerDashboard/GlobalInsights`
      );
      // const geographicInsightsResponse = axiosPrivate.get(
      //   `${API_DEV}/ManagerDashboard/GeographicInsights`
      // );
      const visitsInsightsResponse = axiosPrivate.get(
        `${API_DEV}/ManagerDashboard/VisitsInsights`
      );
      const visitsStatisticsByWeekResponse = axiosPrivate.get(
        `${API_DEV}/ManagerDashboard/VisitsStatisticsByWeek`
      );
      const untreatedRequestsResponse = axiosPrivate.get(
        `${API_DEV}/ManagerDashboard/UntreatedRequests`
      );
      const untreatedObjectionsResponse = axiosPrivate.get(
        `${API_DEV}/ManagerDashboard/UntreatedObjections`
      );
      const activitiesInsightsResponse = axiosPrivate.get(
        `${API_DEV}/ManagerDashboard/ActivitiesInsights`
      );
      const daylyVisitsStatisticsResponse = axiosPrivate.get(
        `${API_DEV}/ManagerDashboard/DailyVisitStatistics`
      );

      const [
        globalInsights,
        // geographicInsights,
        visitsInsights,
        visitsStatisticsByWeek,
        untreatedRequests,
        untreatedObjections,
        activitiesInsights,
        dailyVisitsStatistics,
      ] = await Promise.all([
        globalInsightsResponse,
        // geographicInsightsResponse,
        visitsInsightsResponse,
        visitsStatisticsByWeekResponse,
        untreatedRequestsResponse,
        untreatedObjectionsResponse,
        activitiesInsightsResponse,
        daylyVisitsStatisticsResponse,
      ]);

      return {
        globalInsights: globalInsights.data,
        // geographicInsights: geographicInsights.data,
        visitsInsights: visitsInsights.data,
        visitsStatisticsByWeek: visitsStatisticsByWeek.data,
        untreatedRequests: untreatedRequests.data,
        untreatedObjections: untreatedObjections.data,
        activitiesInsights: activitiesInsights.data,
        dailyVisitsStatistics: dailyVisitsStatistics.data,
      };
    } catch (error) {
      console.log(JSON.parse(JSON.stringify(error)));
      return rejectWithValue(JSON.parse(JSON.stringify(error)));
    }
  } 
);

const initialState: BiState = {
  requestState: "idle",
  dashboardData: {
    globalInsights: {
      dataBaseApprovalsCount: 0,
      rpApprovalsCount: 0,
      requestsCount: 0,
      managerRequestsCount: 0,
      objectionsCount: 0,
      doctorsConflicts: 0,
    },
    visitsInsights: {
      totalPercentage: 0,
      visitsByPotential: [],
    },
    visitsStatisticsByWeek: [],
    untreatedRequests: [],
    untreatedObjections: [],
    activitiesInsights: [],
    geographicInsights: [],
    dailyVisitsStatistics: [],
  },
  errors: null,
};

export const commonslice = createSlice({
  name: "[Common]",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getDashboardData.pending, (state) => {
      state.requestState = "loading";
      state.errors = null;
    });
    builder.addCase(getDashboardData.fulfilled, (state, { payload }) => {
      state.requestState = "idle";
      state.dashboardData = payload;
      state.errors = null;
    });
    builder.addCase(getDashboardData.rejected, (state, { payload }) => {
      state.requestState = "idle";
      state.errors = payload;
    });
  },
});

export default commonslice.reducer;
