import React, { useEffect, useState } from "react";
import { Controller } from "react-hook-form";
import { Form, Input, Col } from "rsuite";
import { CustomErrorMessage } from "../ErrorMessage/CustomErrorMessage";
import "./TextField.scss";
type Props = {
  control: any;
  errors: any;
  label: string;
  inputName: string;
  rules?: any;
  inputMode?:
    | "search"
    | "text"
    | "none"
    | "tel"
    | "url"
    | "email"
    | "numeric"
    | "decimal";
  textarea?: boolean;
  xs?: number;
  sm?: number;
  md?: number;
  lg?: number;
  readOnly?: boolean;
  className?: string;
  setFocus?: any;
  disabled?: boolean;
  color?: string;
  inputboldness?: string;
  onSubmit?: ()=> void
};

export const TextField = ({
  label,
  control,
  inputName,
  rules,
  errors,
  disabled,
  setFocus,
  inputMode,
  textarea,
  xs,
  sm,
  md,
  lg,
  readOnly,
  className,
  color,
  inputboldness,
  onSubmit,
}: Props) => {
  const Textarea = React.forwardRef((props, ref: any) => (
    <Input {...props} as="textarea" ref={ref} />
  ));

  const [test, setTest] = useState<any>("");

  useEffect(() => {
    // test && setFocus(test);
  }, [setFocus, test]);

  return (
    <Col
      xs={xs || 24}
      sm={sm}
      md={md}
      lg={lg}
      className={className}
      style={{ marginBottom: "1rem" }}
    >
      <Form.Group>
        <Form.ControlLabel>{label}</Form.ControlLabel>
        <Controller
          control={control}
          name={inputName}
          rules={rules}
          render={({
            field: { onChange, name, value },
            fieldState: { error },
          }) => {
            error && setTest(error.ref);
            return (
              <Form.Control
                style={{ width: "100%", color: color ? color :"#373737" , fontWeight: inputboldness ? inputboldness:500}}
                className="input-full-width"
                name={name}
                disabled={disabled}
                readOnly={readOnly}
                autoComplete="n"
                accepter={textarea ? Textarea : Input}
                value={value}
                inputMode={inputMode}
                onChange={(e: any) => {onChange(e); onSubmit && onSubmit()}}
              />
            );
          }}
        />
        <CustomErrorMessage errors={errors} name={inputName} />
      </Form.Group>
    </Col>
  );
};
