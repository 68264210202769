import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@material-ui/core";
import React from "react";
import { Col } from "rsuite";

type Props = {
  size: number;
  tableTitle: string;
  visitAverageAndVisitCovrageByDelegateColumns: string[];
  data: listType[];
  columnKeyType: ColumnKeyType;
};

type ColumnKeyType = {
  [key: string]: string;
}[];

type listType = {
  delegate?: string;
  coveragePercentage?: string;
  visitAverage?: string;
};
export default function CustomizedTableForAnalytics({
  size,
  tableTitle,
  visitAverageAndVisitCovrageByDelegateColumns,
  data,
  columnKeyType,
}: Props) {

  return (
    <>
      <Col style={{ marginBottom: "1rem" }} xs={size}>
        <div className="analytics-card__wrapper">
          <div
            className="analytics-card__inner"
            style={{ flexDirection: "column" }}
          >
            <div style={{ paddingBottom: "1rem" }}>
              <dl>
                <dt
                  style={{
                    fontFamily: "Helvetica, Arial, sans-serif",
                    fontSize: "14px",
                    fontWeight: "900",
                    color: "rgb(55,61,63)",
                  }}
                >
                  {tableTitle}
                </dt>
              </dl>
            </div>
            {data && data.length  && columnKeyType && columnKeyType.length> 0 ? (
              <TableContainer
                component={Paper}
                className="Georgraphic_Dashbord_Section"
              >
                <Table stickyHeader aria-label="sticky table" size="small">
                  <TableHead>
                    <TableRow>
                      {visitAverageAndVisitCovrageByDelegateColumns.map(
                        (col: string, index: number) => {
                          return (
                            <TableCell align="left" width={100} key={index}>
                              {col}
                            </TableCell>
                          );
                        }
                      )}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {data.map((el: listType, index) => {
                      return (
                        <TableRow key={index + "rowInTheTable"}>
                          {columnKeyType.map((columnKey, index) => {
                            return (
                              <TableCell key={index}>
                                {el[columnKey.key as keyof typeof el]}
                              </TableCell> 
                            );
                          })}
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
            ) : (
              <div>Loading</div>
            )}
          </div>
        </div>
      </Col>
    </>
  );
}
