import React from "react";
import useBiRequests from "./useBiRequests";
import PhalconPieChart from "../../../03-organisms/charts/PhalconPieChart";
import PhalconBarChart from "../../../03-organisms/charts/PhalconBarChart";
import AnalyticsCard from "../../../03-organisms/analytics/cards/AnalyticsCard";
import { ChartDefinition } from "../BiObjections/BiObjections";
import {
  mdiCheckCircle,
  mdiCloseCircle,
  mdiHandBackLeftOutline,
} from "@mdi/js";
// import _ from "lodash";
import { FlexboxGrid } from "rsuite";
import { BiFilter } from "../../../03-organisms/filter/BiFilter/BiFilter";

type Props = {};

const BiRequests = (props: Props) => {
  const {
    RequestsCount,
    requestsByValue,
    requestsStatusByValue,
    requestsStatusByUser,
  } = useBiRequests({});

  const charts: ChartDefinition[] = [
    {
      title: "Répartition demandes traitées selon le type",
      type: "donut",
      labels: requestsByValue.labels,
      series: requestsByValue.series,
      md: 12,
    },
    {
      title: "Répartition demandes | Délégués | Statut",
      type: "bar",
      xAxisCategories: requestsStatusByUser.xAxis,
      series: requestsStatusByUser.series,
      stacked: true,
      md: 12,
      yAxis: "Demandes",
    },
    {
      title: "Répartition demande Type | Statut",
      type: "bar",
      xAxisCategories: requestsStatusByValue.xAxis, 
      series: requestsStatusByValue.series,
      stacked: false,
      md: 24,
      yAxis: "Demandes",
    },
  ];
  return (
    <div className="phalcon-bi-general__wrapper">
       <div style={{display: "flex", justifyContent: "end"}}>
      <BiFilter context={"request"}/>
      </div>  
      <div className="phalcon-bi-general__cards-area top-space" style={{marginTop: "1rem"}}>
        <FlexboxGrid align="bottom">
           <AnalyticsCard
            mainMetric={{ 
              icon: mdiHandBackLeftOutline,
              count: RequestsCount.total,
              title: "Demandes",
            }}
            md={4}
          />
          <AnalyticsCard
            mainMetric={{
              icon: mdiCheckCircle,
              count: RequestsCount.closedCount,
              title: "Demandes traitées",
            }}
            md={10}
          />
          <AnalyticsCard
            mainMetric={{
              icon: mdiCloseCircle,
              count: RequestsCount.notClosed.total,
              title: "Demandes non traitées",
            }}
            potentials={RequestsCount.notClosed.potential}
            md={10}
          /> 
        </FlexboxGrid>
      </div>

      <div className="phalcon-bi-general__charts-area">
        <FlexboxGrid align="bottom">
          {charts.map((chart: any, index: number) => {
            if (["pie", "donut"].includes(chart.type)) {
              return (
                <PhalconPieChart
                  key={index}
                  title={chart.title}
                  type={chart.type}
                  labels={chart.labels}
                  series={chart.series}
                  md={chart.md}
                />
              );
            }
            if (chart.type === "bar") {
              return (
                <PhalconBarChart
                  key={index}
                  title={chart.title}
                  series={chart.series}
                  xAxisCategories={chart.xAxisCategories}
                  stacked={chart.stacked}
                  md={chart.md}
                  yAxis={chart.yAxis}
                />
              );
            } else {
              return null;
            }
          })}
        </FlexboxGrid>
      </div>
    </div>
  );
};

export default BiRequests;
