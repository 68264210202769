import { mdiPlusCircleOutline } from "@mdi/js";
import Icon from "@mdi/react";
import React from "react";

import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import { RootState } from "../../../redux/store";
import "./AuthorizationManagement.scss";
import AuthorizationManagementTable from "../../03-organisms/tables/AuthorizationManagement/AuthorizationManagementTable";
import { CustomButton } from "../../01-atoms/Buttons/Buttons";
import { useHandleResize } from "../../../utils/useHandleResize";
import { toggleAddAuthorizationDrawer } from "../../../redux/UI/uiSlice";
import AddAuthorizationForm from "../../03-organisms/forms/authorization/addAuthorizationForm";
interface Props {}

export const AuthorizationManagement = (props: Props) => {
  const { addAuthorizationIsOpen } = useAppSelector(
    (state: RootState) => state.UI
  );
  const dispatch = useAppDispatch();
  const deviceSize = useHandleResize();
  return (
    <div className="authorization-management__wrapper">
      <div className="top-area">
        <CustomButton
          text={["Mobile", "Tablet"].includes(deviceSize) ? "" : "Ajouter"}
          icon={<Icon path={mdiPlusCircleOutline} size={1} />}
          onClick={() => dispatch(toggleAddAuthorizationDrawer())}
          color={"customized"}
        />
      </div>
      <div className="authorization-management-inner">
        <AuthorizationManagementTable />
      </div>
      {addAuthorizationIsOpen ? <AddAuthorizationForm /> : ""}
    </div>
  );
};