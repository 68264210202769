import React from "react";
import { useFieldArray } from "react-hook-form";
import { Divider, FlexboxGrid } from "rsuite";
import { phoneTypeOptions } from "../../../../helpers/GlobalFormHelpers";
import { SelectField } from "../../../02-molecules/forms/SelectField/SelectField";
import { TextField } from "../../../02-molecules/forms/TextField/TextField";
import { AddBlockButton } from "../addBlockButton/AddBlockButton";
import { RemoveBlockButton } from "../removeBlockButton/RemoveBlockButton";

type PhonesBlockProps = {
  fieldIsRequired: string;
  control: any;
  errors: any;
  watch?: any;
  setValue?: any;
  extra?: boolean;
};

export const PhonesBlock = ({
  fieldIsRequired,
  control,
  errors,
  watch,
  setValue,
  extra,
}: PhonesBlockProps) => {
  const phones = useFieldArray({
    control,
    name: "phones",
  });

  return (
    <div className="custom-form-section">
      <h3 className="form-block-title">
        {extra ? "Autres téléphones" : "Téléphones"}{" "}
      </h3>
      <Divider />
      {phones.fields.map((field, index) => (
        <div key={field.id}>
          {/* <Form.Group style={{ marginBottom: "2px", display: "flex" }}>
            <Controller
              control={control}
              name={`phones.${index}.isPrincipal`}
              render={({ field: { onChange, name, value } }) => (
                <Form.Control
                  name={name}
                  value={value}
                  accepter={Toggle}
                  size={"sm"}
                  defaultChecked={value}
                  style={{ width: "100%" }}
                  onChange={(e: any) => {
                    onChange(e);
                  }}
                />
              )}
            />
            <Form.HelpText className="isPrimary-toolTip" tooltip>
              Téléphone principale
            </Form.HelpText>
          </Form.Group> */}
          <FlexboxGrid align="bottom">
            <SelectField
              label={"Type du téléphone"}
              inputName={`phones.${index}.phoneType`}
              rules={{ required: fieldIsRequired }}
              control={control}
              errors={errors}
              searchable={false}
              cleanable={false}
              data={phoneTypeOptions}
              xs={24}
              sm={6}
              md={6}
            />
            <TextField
              label={"Numéro"}
              inputName={`phones.${index}.phoneNumber`}
              rules={{ required: fieldIsRequired }}
              control={control}
              errors={errors}
              inputMode="tel"
              xs={24}
              sm={6}
              md={6}
            />
            <TextField
              label={"Description"}
              inputName={`phones.${index}.phoneDescription`}
              control={control}
              errors={errors}
              xs={24}
              sm={phones.fields.length > 1 ? 11 : extra ? 11 : 12}
              md={phones.fields.length > 1 ? 11 : extra ? 11 : 12}
            />
            <RemoveBlockButton
              contextArray={phones}
              index={index}
              style={{ marginBottom: "1rem" }}
              removeAll={extra}
            />
          </FlexboxGrid>
        </div>
      ))}
      <AddBlockButton context="phone" contextArray={phones} />
    </div>
  );
};
