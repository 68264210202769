import { useAppDispatch } from "./../../../../redux/hooks";
import { useEffect, useState } from "react";
import { RootState } from "../../../../redux/store";
import { prepareBusinessUnitsOptions } from "../../../../helpers/GlobalFormHelpers";
import User from "../../../../types/User.model";
import { useAppSelector } from "../../../../redux/hooks";
import { getDefaultLocalitiesOptions } from "../../../../redux/Locality/localitySlice";
import { Phone } from "../../../../types/Phone.model";
import { LocalitySummary } from "../../../../types/Locality.model";

export type UserFormInputs = {
  userId: number | null;
  firstName: string;
  lastName: string;
  email: string;
  birthDate: string;
  businessUnits: number[];
  principalPhone: Phone;
  phones: Phone[];
  address: {
    addressId: number | null;
    streetName: string;
    addressNote: string;
    localities: LocalitySummary[];
  };
  password: string;
};

export const prepareUserFormPayload = (formOutput: UserFormInputs) => {
  return {
    userId: formOutput.userId,
    firstName: formOutput.firstName,
    lastName: formOutput.lastName,
    email: formOutput.email.trim(),
    birthDate: formOutput.birthDate,
    phones: [...formOutput.phones, formOutput.principalPhone],
    address: {
      addressId: formOutput.address?.addressId || null,
      streetName: formOutput.address?.streetName,
      addressNote: formOutput.address?.addressNote,
      localities: formOutput.address?.localities,
    },
    businessUnits: formOutput.businessUnits.map((e) => ({ businessUnitId: e })),
  };
};

export const fieldIsRequired = "Ce champ est requis";

type FormOptions = {
  businessUnitsOptions: {
    key: number;
    label: string;
    value: number | string;
  }[];
};

const useUserForm = () => {
  const dispatch = useAppDispatch();
  const { currentUser } = useAppSelector((state: RootState) => state.user);
  const { businessUnits } = useAppSelector((state: RootState) => state.general);

  const [initialValues, setInitialValues] = useState<UserFormInputs>({
    userId: null,
    firstName: "",
    lastName: "",
    email: "",
    birthDate: "",
    principalPhone: {
      phoneId: null,
      phoneType: null,
      phoneNumber: "",
      phoneDescription: "",
      isPrincipal: true,
    },
    phones: [],
    address: {
      addressId: null,
      streetName: "",
      addressNote: "",
      localities: [],
    },
    businessUnits: [],
    password: "",
  });

  const [formIsReady, setFormIsReady] = useState(false);

  const [formOptions, setFormOptions] = useState<FormOptions | null>(null);

  const defineInitialValues = (userRecord: User | null): UserFormInputs => {
    userRecord?.address.localities.forEach((loc) => {
      dispatch(getDefaultLocalitiesOptions(loc.localityId.toString()));
    });    
    return {
      userId: userRecord?.userId || null,
      firstName: userRecord?.firstName || "",
      lastName: userRecord?.lastName || "",
      email: userRecord?.email || "",
      birthDate: userRecord?.birthDate || "",
      principalPhone: userRecord?.phones.find(
        (p) => p.isPrincipal === true
      ) || {
        phoneId: null,
        phoneType: null,
        phoneNumber: "",
        phoneDescription: "",
        isPrincipal: true,
      },
      phones: userRecord?.phones.filter((p) => !p.isPrincipal) || [],
      businessUnits:
        userRecord?.businessUnits.map((e: any) => (e = e.businessUnitId)) || [],
      address: {
        addressId: userRecord?.address?.addressId || null,
        localities:  userRecord?.address?.localities && userRecord?.address?.localities?.length >0 
        ? [...userRecord.address.localities].sort(
            (a, b) => a.level - b.level
          )
        : []  as LocalitySummary[],
        streetName: userRecord?.address?.streetName || "",
        addressNote: userRecord?.address?.addressNote || "",
      },
      password: "",
    };
  };

  //Global data preparation

  useEffect(() => {
    const businessUnitsOptions = prepareBusinessUnitsOptions(businessUnits);
    if (businessUnitsOptions.length > 0) {
      setFormOptions({
        businessUnitsOptions,
      });
    }
  }, [businessUnits]);

  useEffect(() => {
    currentUser && formOptions ? setFormIsReady(true) : setFormIsReady(false);
  }, [formIsReady, formOptions, currentUser]);

  useEffect(() => {
    const init = defineInitialValues(currentUser);
    setInitialValues(init);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUser]);

  return { initialValues, formIsReady, formOptions, setFormIsReady };
};

export default useUserForm;
