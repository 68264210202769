import { Tooltip } from "@material-ui/core";
import React from "react";
import { CustomAvatar } from "../../../../01-atoms/CustomAvatar/CustomAvatar";
import "./PhalconCell.scss";
type Props = {
  item: any;
};

type PersonData = {
  userId: string;
  photoUrl: string;
  name: string;
};

const PersonCell = ({ item }: Props) => {
  const personData: PersonData = JSON.parse(item);
  return (
    <div className="phalcon-person-cell">
      <CustomAvatar
        image={{
          src: personData.photoUrl,
          alt: personData.name + "'s avatar",
        }}
        style={{ width: "2rem", height: "2rem" }}
      />
      {personData?.name && personData.name.length > 20 ? (
        <Tooltip title={personData?.name || "-"}>
          <span>{personData?.name || "-"}</span>
        </Tooltip>
      ) : (
        <span>{personData?.name || "-"}</span>
      )}
    </div>
  );
};

export default PersonCell;
