import React, { useEffect } from "react";
import { Controller, useFieldArray } from "react-hook-form";
import { Divider, FlexboxGrid, Form, Toggle } from "rsuite";
import { phoneTypeOptions } from "../../../../helpers/GlobalFormHelpers";
import { LocalitySummary } from "../../../../types/Locality.model";
import ToolTip from "../../../01-atoms/ToolTip/ToolTip";
import { CustomErrorMessage } from "../../../02-molecules/forms/ErrorMessage/CustomErrorMessage";
import { SelectField } from "../../../02-molecules/forms/SelectField/SelectField";
import { TextField } from "../../../02-molecules/forms/TextField/TextField";
import { AddBlockButton } from "../addBlockButton/AddBlockButton";
import AddressBlock from "../addressBlock/AddressBlock";
import { RemoveBlockButton } from "../removeBlockButton/RemoveBlockButton";

type Props = {
  fieldIsRequired: string;
  control: any;
  errors: any;
  setValue: any;
  getValues: any;
  resetField: any;
  selectedPrimaryfacility: {
    office: boolean;
    facility: boolean;
  };
  setSelectedPrimaryfacility: React.Dispatch<
    React.SetStateAction<{
      office: boolean;
      facility: boolean;
    }>
  >;
  initialLocalities?: LocalitySummary[];
  formContext: "add" | "update";
};

const OfficeBlock = ({
  fieldIsRequired,
  control,
  errors,
  setValue,
  getValues,
  resetField,
  initialLocalities,
  selectedPrimaryfacility,
  setSelectedPrimaryfacility,
  formContext,
}: Props) => {
  const offices = useFieldArray({
    control,
    name: "offices",
  });
  useEffect(() => {
    if (selectedPrimaryfacility.facility) {
      for (let i = 0; i < offices.fields.length; i++) {
        let oldValues = getValues().offices[i];
        offices.update(i, {
          ...oldValues,
          isPrimary: false,
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedPrimaryfacility]);

  return (
    <div className="custom-form-section">
      <div style={{ display: "flex", alignItems: "center" }}>
        <h3 className="form-block-title">Cabinet</h3>
      </div>
      <Divider />

      {offices.fields.map((item, index) => (
        <div key={index}>
          <Form.Group
            style={{
              marginLeft: ".5rem",
              marginBottom: "1rem",
              display: "flex",
            }}
          >
            <Controller
              control={control}
              name={`offices[0].isPrimary`}
              render={({ field: { onChange, name, value } }) => (
                <Form.Control
                  name={name}
                  value={value}
                  accepter={Toggle}
                  size={"sm"}
                  defaultChecked={value}
                  style={{ width: "100%" }}
                  onChange={(e: any) => {
                    onChange(e);

                    setSelectedPrimaryfacility({
                      office: e ? true : false,
                      facility: false,
                    });
                  }}
                />
              )}
            />
            <ToolTip
              message="Ce bouton indique si c'est l'adresse principale"
              type = "help" 
              style={{
                width: "13px",
                color: "var(--rs-text-secondary)",
                marginLeft: "10px",
                display: "inline-flex",
                marginTop: "2px",
              }}
            />
            <CustomErrorMessage errors={errors} name={`offices[0].isPrimary`} />
          </Form.Group>
          <AddressBlock
            localitiesField={`offices[0].address.localities`}
            streetNameField={`offices[0].address.streetName`}
            noteAdressField={`offices[0].address.addressNote`}
            initialLocalities={initialLocalities}
            fieldIsRequired={fieldIsRequired}
            control={control}
            errors={errors}
            noteAddress
            setValue={setValue}
            formContext={formContext}
          />
          <FlexboxGrid align="bottom">
            <SelectField
              label={"Type du téléphone"}
              inputName={`offices[0].phones.${index}.phoneType`}
              rules={{ required: fieldIsRequired }}
              control={control}
              errors={errors}
              searchable={false}
              cleanable={false}
              data={phoneTypeOptions}
              xs={24}
              sm={4}
              md={4}
            />

            <TextField
              label={"Numéro"}
              inputName={`offices[0].phones.${index}.phoneNumber`}
              rules={{ required: fieldIsRequired }}
              control={control}
              errors={errors}
              inputMode="tel"
              xs={24}
              sm={8}
              md={8}
            />

            <TextField
              label={"Description"}
              inputName={`offices[0].phones.${index}.phoneDescription`}
              control={control}
              errors={errors}
              xs={24}
              sm={8}
              md={12}
            />
          </FlexboxGrid>
          <RemoveBlockButton
            contextArray={offices}
            index={index}
            style={{ marginBottom: "1rem", width: "2.6%" }}
            removeAll
          />
        </div>
      ))}
      {offices.fields.length < 1 && (
        <AddBlockButton contextArray={offices} context={"offices"} />
      )}
    </div>
  );
};

export default OfficeBlock;
