import { mdiTune } from "@mdi/js";
import Icon from "@mdi/react";
import React, { createRef } from "react";
import { Controller, useForm } from "react-hook-form";
import { CheckTree, DateRangePicker, Form, Popover, Whisper } from "rsuite";
import { useHandleResize } from "../../../../utils/useHandleResize";
import { CustomButton } from "../../../01-atoms/Buttons/Buttons";
import { CustomErrorMessage } from "../../../02-molecules/forms/ErrorMessage/CustomErrorMessage";
import { OrderFilterData } from "./useFilterOrder";
import "./OrderFilters.scss";
import moment from "moment";

type Props = {
  captureFilter: (data: OrderFilterData) => void;
  deliveryStatusValue?: string[];
  paymentStatusValue?: string[];
  dateValue?: [Date, Date] | undefined;
  setAppliedFilters: React.Dispatch<React.SetStateAction<string[]>>;
};

const trackingOrderStatus: {
  name: any;
  data: { label: string; value: string }[];
}[] = [
  {
    name: "deliveryStatus",
    data: [
      { label: "Non livré", value: "UND" },
      { label: "Partiellement livré", value: "PD" },
      { label: "Livré", value: "TD" },
    ],
  },
  {
    name: "paymentStatus",
    data: [
      { label: "Non payé", value: "UNP" },
      { label: "Partiellement payé", value: "PP" },
      { label: "Payé", value: "TP" },
    ],
  },
];
export const orderStatus: {
  [UND: string]: string;
  PD: string;
  TD: string;
  UNP: string;
  PP: string;
  TP: string;
} = {
  UND: "Non livré",
  PD: "Partiellement livré",
  TD: "Livré",
  UNP: "Non payé",
  PP: "Partiellement payé",
  TP: "Payé",
};

export const OrderFilters = ({
  captureFilter,
  deliveryStatusValue,
  paymentStatusValue,
  dateValue,
  setAppliedFilters,
}: Props) => {
  const deviceSize = useHandleResize();
  const initForm: OrderFilterData = {
    deliveryStatus: [],
    paymentStatus: [],
    date: dateValue,
  };
  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<OrderFilterData>({
    defaultValues: initForm,
    criteriaMode: "all",
    reValidateMode: "onChange",
  });

  const handleGetAppliedFilter = (data: {
    deliveryStatus: string[];
    paymentStatus: string[];
    date: [Date, Date] | undefined;
  }) => {
    const initialDateValue: string[] = [
      moment().startOf("month").format("L"),
      moment().format("L"),
    ];
    let deliveryFilterList: string[] = [];
    let paymentFilterList: string[] = [];
    let dateFilterList: string[] = [initialDateValue[0], initialDateValue[1]];
    deliveryFilterList = data.deliveryStatus.map((el) => {
      if (orderStatus[el]) {
        return orderStatus[el];
      } else return "";
    });

    paymentFilterList = data.paymentStatus.map((el) => {
      if (orderStatus[el]) {
        return orderStatus[el];
      } else return "";
    });
    if (data.date && data.date.length) {
      dateFilterList = data.date.map((el) => moment(el).format("L"));
    }
    setAppliedFilters(
      deliveryFilterList.concat(paymentFilterList).concat(dateFilterList)
    );
  };
  const onSubmit = handleSubmit((data) => {
    handleGetAppliedFilter(data);
    captureFilter(data);
  });

  const datePickerRangeRef: any = createRef();
  const renderMenu = ({ onClose, left, top, className }: any, ref: any) => {
    return (
      <Popover
        ref={ref}
        className={className + " request-filter__whisper"}
        style={{ left, top }}
        full
      >
        <Form>
          <div className="order-filter__inner">
            {trackingOrderStatus.map((el, index) => {
              return (
                <div className="order-status-area" key={index}>
                  <Form.Group className="order-status-field">
                    <Controller
                      control={control}
                      name={el.name}
                      render={({ field: { onChange, name, value } }) => (
                        <Form.Control
                          name={name}
                          accepter={CheckTree}
                          data={el.data}
                          value={value}
                          onChange={(e: any, val: any) => {
                            onChange(e);
                            onSubmit();
                          }}
                        />
                      )}
                    />
                  </Form.Group>
                </div>
              );
            })}
            <div className="dates-area">
              <Form.Group>
                <Controller
                  control={control}
                  name="date"
                  render={({ field: { onChange, name, value } }) => (
                    <Form.Control
                      ref={datePickerRangeRef}
                      accepter={DateRangePicker}
                      name={name}
                      value={value}
                      isoWeek
                      className="order-date-width"
                      format={"yyyy-LL-dd"}
                      style={{ width: "100%" }}
                      container={() => datePickerRangeRef.current}
                      placement="autoHorizontal"
                      onChange={(e: any) => {
                        onChange(e);
                        onSubmit();
                      }}
                    />
                  )}
                />
                <CustomErrorMessage errors={errors} name="date" />
              </Form.Group>
            </div>
          </div>
        </Form>
      </Popover>
    );
  };
  return (
    <div className="request-filter__wrapper">
      <Whisper
        placement="autoVerticalEnd"
        trigger="click"
        speaker={renderMenu}
        preventOverflow
      >
        <div>
          <CustomButton
            text={["Mobile", "Tablet"].includes(deviceSize) ? "" : "Filtrer"}
            icon={<Icon path={mdiTune} size={1} />}
          />
        </div>
      </Whisper>
    </div>
  );
};
