import React from "react";
import InboxList from "../../03-organisms/inbox/InboxList/InboxList";
import MessagesArea from "../../03-organisms/inbox/MessagesArea/MessagesArea";
import { Header } from "../../05-layouts/Header/Header";
import "./InboxTemplate.scss";
type Props = {};

const InboxTemplate = (props: Props) => {
  return (
    <>
      <Header
        leftItem={<div className="header-left-item-title">Messagerie</div>}
      />
      <div className="phalcon-inbox__inner">
        <div className="phalcon-inbox__inner__left">
          <InboxList />
        </div>
        <div className="phalcon-inbox__inner__main">
          <MessagesArea />
        </div>
      </div>
    </>
  );
};

export default InboxTemplate;
