import {
  mdiMapMarkerPlusOutline,
  mdiTag,
  mdiInformationVariant,
} from "@mdi/js";
import Icon from "@mdi/react";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Divider } from "rsuite";
import { doctorPhones } from "../../../../helpers/dataFormator";
import { Doctor } from "../../../../types/Doctor.model";
import { DoctorFacility } from "../../../../types/DoctorFacility.model";
import { Locality } from "../../../../types/Locality.model";
import { Office } from "../../../../types/Office.model";
import { Phone } from "../../../../types/Phone.model";
import { ProfileInfoItemPlaceholder } from "../../../02-molecules/placeholders/Placeholder";
import { ProfileHeader } from "../../../03-organisms/user-account/profile-header/ProfileHeader";
import { InfoItemEmail } from "./InfoItemEmail";
import { InfoItemExtraAddress } from "./InfoItemExtraAddress";
import { InfoItemLocality } from "./InfoItemLocality";
import { InfoItemPhone } from "./InfoItemPhone";
import { InfoItemTags } from "./InfoItemTags";

type Props = {
  doctor: Doctor | null;
};

type SeparatedOffices = {
  primaryOffice: Office | null;
  extraOffices: Office[];
} | null;
type Separatedfacilities = {
  primaryFacility: DoctorFacility | null;
  extrafacilities: DoctorFacility[];
} | null;

type ProfileData = {
  primaryFacility: {
    facilityName: string;
    localities: Locality[];
    streetName: string;
    noteAdress: string;
    services: { serviceName: string; isChief: boolean }[] | null;
  } | null;
  phones: Phone[];
  email: string;
  tags: string[];
  additionalInfo: string[];
  extrafacilities:
    | {
        facilityName: string;
        localities: Locality[];
        streetName: string;
        noteAdress: string;
        services: { serviceName: string; isChief: boolean }[] | null;
      }[]
    | null;
};
export const DoctorProfileInfo = ({ doctor }: Props) => {
  const { t } = useTranslation();
  const [profileData, setprofileData] = useState<ProfileData>({
    primaryFacility: null,
    phones: [],
    email: "",
    tags: [],
    extrafacilities: [],
    additionalInfo: [],
  });
  const prepareData = (doctor: Doctor | null) => {
    if (!doctor) return null;
    const getOffices = (offices: Office[]) => {
      if (!offices || !offices[0]) return null;
      else
        return {
          primaryOffice: offices.find((e) => e.isPrimary) || null,
          extraOffices: offices.filter((e) => !e.isPrimary),
        };
    };

    const getfacilities = (facilities: DoctorFacility[]) => {
      if (!facilities || facilities.length === 0) return null;
      else {
        return {
          primaryFacility: facilities.find((e) => e.isPrimaryFacility) || null,
          extrafacilities: facilities.filter((e) => !e.isPrimaryFacility),
        };
      }
    };
    const getprimaryFacility = (
      offices: SeparatedOffices,
      facilities: Separatedfacilities
    ) => {
      if (offices && offices?.primaryOffice)
        return {
          facilityName: "Cabinet",
          localities: offices?.primaryOffice?.address?.localities || [],
          streetName: offices?.primaryOffice?.address?.streetName || "",
          noteAdress: offices?.primaryOffice?.address?.addressNote || "",
          services: null,
        };
      if (facilities && facilities?.primaryFacility)
        return {
          facilityName: facilities?.primaryFacility?.facilityName || "",
          localities: facilities?.primaryFacility?.address?.localities || [],
          streetName: facilities?.primaryFacility?.address?.streetName || "",
          noteAdress: facilities?.primaryFacility?.address?.addressNote || "",
          services:
            facilities?.primaryFacility?.services?.map((el) => ({
              serviceName: el.serviceType?.serviceTypeName || "",
              isChief: el.chief?.doctorId === doctor.doctorId ? true : false,
            })) || [],
        };
      else return null;
    };

    const getExtrafacilities = (
      offices: SeparatedOffices,
      facilities: Separatedfacilities
    ) => {
      let extra = null;
      if (offices && offices?.extraOffices) {
        extra = [
          ...offices.extraOffices.map((e) => ({
            facilityName: "Cabinet",
            localities: e?.address?.localities || [],
            streetName: e?.address?.streetName || "",
            noteAdress: e?.address?.addressNote || "",
            services: null,
          })),
        ];
      }

      if (facilities && facilities?.extrafacilities) {
        extra = [
          ...facilities.extrafacilities.map((e) => ({
            facilityName: e?.facilityName || "",
            localities: e?.address?.localities || [],
            streetName: e?.address?.streetName || "",
            noteAdress: e?.address?.addressNote || "",
            services:
              e?.services?.map((el) => ({
                serviceName: el.serviceType?.serviceTypeName || "",
                isChief: el.chief?.doctorId === doctor.doctorId ? true : false,
              })) || [],
          })),
        ];
      }
      return extra;
    };
    const offices = getOffices(doctor.offices);
    const facilities = getfacilities(doctor.facilities);
    return {
      primaryFacility: getprimaryFacility(offices, facilities),
      phones: doctorPhones(doctor?.phones || [], doctor?.offices || []),
      email: doctor?.email || "",
      tags: doctor.tags?.map((tag) => tag.tagName) || [],
      extrafacilities: getExtrafacilities(offices, facilities),
      additionalInfo:
        doctor.additionalInfos?.map(
          (info) => info.label + " : " + info.description
        ) || [],
    };
  };

  useEffect(() => {
    const data = prepareData(doctor);

    data && setprofileData(data);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [doctor]);

  return (
    <div className="profile-info__wrapper">
      <div className="profile-infos">
        <div className="profile_avatar_rwd">
          <ProfileHeader context={"doctor"} person={doctor} />
          <Divider />
        </div>

        {/* Locality Item */}
        {profileData.primaryFacility ? (
          <InfoItemLocality
            loading={false}
            facilityName={profileData.primaryFacility.facilityName}
            localities={profileData.primaryFacility.localities}
            streetName={profileData.primaryFacility.streetName}
            noteAdress={profileData.primaryFacility.noteAdress}
            service={profileData.primaryFacility.services}
          />
        ) : null}
        {/* Phone Item */}
        <InfoItemPhone phones={profileData.phones} loading={false} />
        {/* Email Item */}
        <InfoItemEmail email={profileData.email} loading={false} />
        <Divider hidden />
        <Divider />
        <Divider hidden />
        <div className="extra">
          {doctor ? (
            <div className="extra__heading">
              <div className="icon">
                <Icon path={mdiMapMarkerPlusOutline} size={1} />
              </div>
              <dl className="content">
                <dt>{t("account_page.user_infos.other_addresses")}</dt>
                {!profileData.extrafacilities && <dt>nope</dt>}
              </dl>
            </div>
          ) : (
            <ProfileInfoItemPlaceholder withCircle rows={1} />
          )}
          {profileData.extrafacilities
            ? profileData.extrafacilities.map((e,index) => (
                <InfoItemExtraAddress
                  loading={false}
                  facilityName={e.facilityName}
                  localities={e.localities}
                  streetName={e.streetName}
                  noteAdress={e.noteAdress}
                  service={e.services}
                  key={index}
                />
              ))
            : null}
        </div>

        <Divider hidden />
        <Divider />
        <Divider hidden />
        <div className="extra">
          {doctor ? (
            <div className="extra__heading">
              <div className="icon">
                <Icon path={mdiTag} size={1} />
              </div>
              <dl className="content">
                <dt>{t("account_page.user_infos.tags")}</dt>
              </dl>
            </div>
          ) : (
            <ProfileInfoItemPlaceholder withCircle rows={1} />
          )}

          {profileData.tags && profileData.tags.length > 0 && (
            <InfoItemTags loading={false} tags={profileData.tags} />
          )}
        </div>
        <Divider hidden />
        <Divider />
        <Divider hidden />
        <div className="extra">
          <div className="extra__heading">
            <div className="icon">
              <Icon path={mdiInformationVariant} size={1} />
            </div>
            <dl className="content">
              <dt>{t("account_page.user_infos.additional_infos")}</dt>
            </dl>
          </div>
          <div>
            {profileData.additionalInfo &&
              profileData.additionalInfo.length > 0 &&
              profileData.additionalInfo.map((info,index) => (
                <div className="info-item" key={index}>{info}</div>
              ))}
          </div> 
        </div>
      </div>
    </div>
  );
};
