import { mdiClose, mdiPlus } from "@mdi/js";
import Icon from "@mdi/react";
import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { Col, Divider, Form, Loader, Modal, Nav, FlexboxGrid } from "rsuite";
import { useAppDispatch, useAppSelector } from "../../../../redux/hooks";
import { addExternal } from "../../../../redux/Rp/rpSlice";
import { RootState } from "../../../../redux/store";
import { Product } from "../../../../types/Product.model";
import { Tag } from "../../../../types/Tag.model";
// import { getServerDateFormat } from "../../../../utils/dateHelpers";
import { CustomButton } from "../../../01-atoms/Buttons/Buttons";
import { CustomAvatar } from "../../../01-atoms/CustomAvatar/CustomAvatar";
import { DateRangeField } from "../../../02-molecules/forms/DateRangeField/DateRangeField";
import { PersonsField } from "../../../02-molecules/forms/PersonsField/PersonsField";
import { ProductsField } from "../../../02-molecules/forms/ProductsField/ProductsField";
import { SelectField } from "../../../02-molecules/forms/SelectField/SelectField";
import { TagsField } from "../../../02-molecules/forms/TagsField/TagsField";
import { TextField } from "../../../02-molecules/forms/TextField/TextField";
import { RpFormContext, RpFormInputs, useRpForm } from "./useRpForm";

type Props = {
  context: RpFormContext;
};

const RpForm = ({ context }: Props) => {
  const dispatch = useAppDispatch();

  const { initForm } = useRpForm({ context });
  const {
    control,
    // handleSubmit,
    watch,
    setValue,
    reset,
    formState: { errors },
  } = useForm<RpFormInputs>({
    defaultValues: initForm,
    criteriaMode: "all",
    reValidateMode: "onChange",
  });

  const [participantsModalIsOpen, setParticipantsModalIsOpen] = useState(false);
  const [productsModalIsOpen, setProductsModalIsOpen] = useState(false);
  const [tagsOptions, setTagsOptions] = useState<
    { key: number; label: string; value: number }[]
  >([]);
  const { tags } = useAppSelector((state: RootState) => state.general);

  useEffect(() => {
    reset(initForm);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initForm]);

  useEffect(() => {
    setTagsOptions(
      tags.map((tag: Tag) => ({
        key: tag.tagId,
        label: tag.tagName,
        value: tag.tagId,
      }))
    );
  }, [tags]);

  type ParticipantsTabKeys = "users" | "doctors" | "externals" | "moderators";
  interface ParticipantModalTabs {
    key: ParticipantsTabKeys;
    label: string;
    data: { label: string; value: number }[];
  }

  const [participantsTabs, setParticipantsTabs] = useState<
    ParticipantModalTabs[]
  >([]);

  const { persons, requestState } = useAppSelector(
    (state: RootState) => state.rpEvent
  );

  useEffect(() => {
    setParticipantsTabs([
      {
        key: "users",
        label: "Collègues",
        data:
          persons.users.length > 0
            ? persons.users.map((el) => ({
                label: JSON.stringify(el),
                value: el.id,
              }))
            : [],
      },

      {
        key: "doctors",
        label: "Médecins",
        data:
          persons.doctors.length > 0
            ? persons.doctors.map((el) => ({
                label: JSON.stringify(el),
                value: el.id,
              }))
            : [],
      },
      {
        key: "externals",
        label: "Externes",
        data:
          persons.externals.length > 0
            ? persons.externals.map((el) => ({
                label: JSON.stringify(el),
                value: el.id,
              }))
            : [],
      },
      {
        key: "moderators",
        label: "Modérateurs",
        data:
          persons.moderators.length > 0
            ? persons.moderators.map((el) => ({
                label: JSON.stringify(el),
                value: el.id,
              }))
            : [],
      },
    ]);
  }, [persons]);

  const [activeParticipantsTab, setActiveParticipantsTab] = useState<
    string | undefined
  >();
  useEffect(() => {
    participantsTabs.length > 0 &&
      setActiveParticipantsTab(participantsTabs[0].key);
  }, [participantsTabs]);

  const usersValue = watch("users");
  const doctorsValue = watch("doctors");
  const externalsValue = watch("externals");
  const moderatorsValue = watch("moderators");
  const productsValue = watch("products");

  type SelectedParticipants = {
    users: any[];
    doctors: any[];
    externals: any[];
    moderators: any[];
  };
  const [selectedParticipants, setSelectedParticipants] =
    useState<SelectedParticipants>({
      users: [],
      doctors: [],
      externals: [],
      moderators: [],
    });

  useEffect(() => {
    setSelectedParticipants({
      users: usersValue,
      doctors: doctorsValue,
      externals: externalsValue,
      moderators: moderatorsValue,
    });
  }, [doctorsValue, externalsValue, moderatorsValue, usersValue]);

  const handleRemovePerson = (key: ParticipantsTabKeys, id: number) => {
    let newarray = selectedParticipants[
      key as keyof SelectedParticipants
    ].filter((el) => el !== id);
    setValue(key, newarray);
  };

  const handleAddExternal = (
    fullName: string,
    email: string,
    phone: string,
    context: number,
    description: string
  ) => {
    dispatch(
      addExternal({
        fullName,
        email,
        phone,
        context,
        description,
      })
    );
  };

  /* Products variables */

  const { products } = useAppSelector((state: RootState) => state.product);

  const [selectedProducts, setSelectedProducts] = useState<number[]>([]);
  const [selectedProductsObjects, setSlectedProductsObjects] = useState<
    Product[]
  >([]);

  useEffect(() => {
    let newSelected = products.filter((e) =>
      selectedProducts.includes(e.productId)
    );
    setSlectedProductsObjects(newSelected);
  }, [products, selectedProducts]);

  useEffect(() => {
    setSelectedProducts(productsValue);
  }, [productsValue]);
  const handleRemoveProduct = (id: number) => {
    let newMyProductsArray = selectedProducts.filter((el) => el !== id);
    setValue("products", newMyProductsArray);
  };

  const { separatedProducts } = useAppSelector(
    (state: RootState) => state.product
  );
  const { myProducts, othersProducts } = separatedProducts;
  const myProductsIds =
    myProducts.length > 0 ? myProducts.map((e) => e.productId) : [];
  const othersProductsIds =
    othersProducts.length > 0 ? othersProducts.map((e) => e.productId) : [];

  type ProductsTabKeys = "myProducts" | "othersProducts";
  interface ProductsModalTabs {
    key: ProductsTabKeys;
    label: string;
    data: number[];
  }

  const [productsTabs, setProductsTabs] = useState<ProductsModalTabs[]>([]);

  useEffect(() => {
    setProductsTabs([
      {
        key: "myProducts",
        label: "Mes produits",
        data: othersProductsIds,
      },
      {
        key: "othersProducts",
        label: "Autres produits",
        data: myProductsIds,
      },
    ]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [myProducts, othersProducts]);

  const [activeProductsTab, setActiveProductsTab] = useState<
    string | undefined
  >();

  const [hiddenProducts, setHiddenProducts] =
    useState<number[]>(othersProductsIds);
  useEffect(() => {
    productsTabs.length > 0 && setActiveProductsTab(productsTabs[0].key);
  }, [productsTabs]);

  return (
    <div className="rp-form__wrapper">
      {requestState === "loading" ? (
        <Loader size="lg" />
      ) : (
        <Form>
          <div className="custom-form-section">
            <FlexboxGrid align="bottom">
              <TextField
                control={control}
                inputName="subject"
                rules={{}}
                errors={errors}
                label={"Sujet"}
                readOnly={context === "read"}
                md={12}
              />

              <SelectField
                control={control}
                inputName="type"
                rules={{}}
                errors={errors}
                label={"Type d'évenement"}
                searchable={false}
                cleanable={false}
                readOnly={context === "read"}
                data={[
                  { label: "Table Ronde", value: "RoundTable" },
                  { label: "EPU", value: "EPU" },
                  { label: "Lancement", value: "Starter" },
                ]}
                md={4}
              />

              <DateRangeField
                control={control}
                inputName="date"
                cleanable={false}
                errors={errors}
                label={"Date début / fin"}
                readOnly={context === "read"}
                md={8}
              />
              <TextField
                control={control}
                inputName="budget"
                rules={{}}
                errors={errors}
                label={"Budget"}
                readOnly={context === "read"}
                md={6}
              />
              <TextField
                control={control}
                inputName="facility"
                rules={{}}
                errors={errors}
                label={"Lieu de l'évènement"}
                readOnly={context === "read"}
                md={18}
              />
              <TextField
                control={control}
                inputName="principalContact"
                rules={{}}
                errors={errors}
                label={"Contact Principal"}
                readOnly={context === "read"}
                md={8}
              />
              <TextField
                control={control}
                inputName="principalContactPhone"
                rules={{}}
                errors={errors}
                label={"Numéro de téléphone"}
                readOnly={context === "read"}
                md={8}
              />
              <TextField
                control={control}
                inputName="email"
                rules={{}}
                errors={errors}
                label={"Email"}
                readOnly={context === "read"}
                md={8}
              />
              <TextField
                control={control}
                inputName="description"
                rules={{}}
                errors={errors}
                label={"Description"}
                readOnly={context === "read"}
                textarea
              />
            </FlexboxGrid>
          </div>
          <div className="custom-form-section">
            <div className="participants-area">
              <div className="participants-area--title">Participants</div>
              <div className="participants-area--inner">
                {participantsTabs.map((tab) => {
                  const { key } = tab;

                  const array = tab.data.filter((item) =>
                    selectedParticipants[
                      key as keyof SelectedParticipants
                    ].includes(JSON.parse(item.label).id)
                  );

                  const sectionTitle =
                    key === "users"
                      ? "Collègues"
                      : key === "doctors"
                      ? "Médecins"
                      : key === "externals"
                      ? "Externes"
                      : "Modérateurs";
                  return (
                    <div key={key}>
                      {array.length > 0 ? (
                        <div className={`${key}-area`}>
                          <div className="section-title">{sectionTitle}</div>
                          <div className={`${key}-area--inner`}>
                            {array.map((el) => {
                              const { photoUrl, fullName, id } = JSON.parse(
                                el.label
                              );
                              return (
                                <div className="person-element" key={id}>
                                  {["myTeams", "otherTeams"].includes(key) && (
                                    <CustomAvatar
                                      image={{
                                        src: photoUrl,
                                        alt: `${fullName}'s avatar`,
                                      }}
                                      style={{ width: "25px", height: "25px" }}
                                    />
                                  )}
                                  <span>{fullName}</span>
                                  {context !== "read" && (
                                    <div
                                      className="remove-participant"
                                      onClick={() =>
                                        handleRemovePerson(key, id)
                                      }
                                    >
                                      <Icon path={mdiClose} size={0.75} />
                                    </div>
                                  )}
                                </div>
                              );
                            })}
                          </div>
                        </div>
                      ) : null}
                    </div>
                  );
                })}

                {context !== "read" && (
                  <CustomButton
                    text="Ajouter des participants"
                    color="light"
                    onClick={() => setParticipantsModalIsOpen(true)}
                    className="add-persons-button"
                    icon={<Icon path={mdiPlus} size={0.75} />}
                  />
                )}
              </div>
            </div>
          </div>
          <div className="custom-form-section">
            <FlexboxGrid align="bottom">
              <TagsField
                control={control}
                inputName="tags"
                errors={errors}
                data={tagsOptions}
                label={"Tags"}
                readOnly={context === "read"}
                creatable
              />
            </FlexboxGrid>
          </div>

          {context !== "add" && (
            <>
              <Col xs={24}>
                <Divider />
                <div className="products-area">
                  <div className="products-area--title">Produits</div>
                  <div className="products-area--inner">
                    {selectedProductsObjects.length > 0 ? (
                      <div className="selected-products-area">
                        <div className="selected-products-area--inner">
                          {selectedProductsObjects.map((el) => {
                            const { productName, productId } = el;
                            return (
                              <div className="product-element" key={productId}>
                                <span>{productName}</span>
                                {["add", "update"].includes(context) && (
                                  <div
                                    className="remove-product"
                                    onClick={() =>
                                      handleRemoveProduct(productId)
                                    }
                                  >
                                    <Icon path={mdiClose} size={0.75} />
                                  </div>
                                )}
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    ) : null}

                    {["add", "update"].includes(context) && (
                      <CustomButton
                        text="Ajouter des produits"
                        color="light"
                        onClick={() => setProductsModalIsOpen(true)}
                        className="add-product-button"
                        icon={<Icon path={mdiPlus} size={0.75} />}
                      />
                    )}
                  </div>
                </div>
                <Divider />
              </Col>

              <TextField
                control={control}
                inputName="comment"
                rules={{}}
                errors={errors}
                label={"Commentaire"}
                readOnly={context === "read"}
                textarea
                className="comment-field"
              />
            </>
          )}

          <Modal
            full
            open={participantsModalIsOpen}
            onClose={() => setParticipantsModalIsOpen(!participantsModalIsOpen)}
            dialogClassName="form-modal"
          >
            <Modal.Header>
              <Modal.Title>Ajouter des participants</Modal.Title>
            </Modal.Header>
            <Modal.Body className="persons-modal-body">
              <Nav appearance="subtle" className="persons-tab-navigation">
                {participantsTabs.length > 0 &&
                  participantsTabs.map(
                    (tab: ParticipantModalTabs, i: number) => (
                      <Nav.Item
                        className="tab-item"
                        eventKey={`${tab.key}`}
                        active={tab.key === activeParticipantsTab}
                        key={tab.key}
                        onSelect={(eventKey) =>
                          setActiveParticipantsTab(eventKey)
                        }
                      >
                        {`${tab.label} (${tab.data.length})`}
                      </Nav.Item>
                    )
                  )}
              </Nav>
              {participantsTabs.length > 0 &&
                participantsTabs.map((tab: ParticipantModalTabs, i: number) => {
                  return (
                    <PersonsField
                      key={i}
                      className={
                        tab.key === activeParticipantsTab
                          ? `isActive persons-field-tab ${tab.key}-field-tab`
                          : `persons-field-tab ${tab.key}-field-tab`
                      }
                      control={control}
                      errors={errors}
                      label={""}
                      inputName={tab.key}
                      data={tab.data}
                      handleAddExternal={handleAddExternal}
                    />
                  );
                })}
            </Modal.Body>
          </Modal>
          <Modal
            full
            open={productsModalIsOpen}
            onClose={() => setProductsModalIsOpen(!productsModalIsOpen)}
            dialogClassName="form-modal"
          >
            <Modal.Header>
              <Modal.Title>Ajouter des produits</Modal.Title>
            </Modal.Header>
            <Modal.Body className="products-modal-body">
              <Nav appearance="subtle" className="persons-tab-navigation">
                {productsTabs.length > 0 &&
                  productsTabs.map((tab: ProductsModalTabs, i: number) => (
                    <Nav.Item
                      className="tab-item"
                      eventKey={`${tab.key}`}
                      active={tab.key === activeProductsTab}
                      key={tab.key}
                      onSelect={(eventKey) => {
                        setActiveProductsTab(eventKey);
                        setHiddenProducts(tab.data);
                      }}
                    >
                      {`${tab.label}`}
                    </Nav.Item>
                  ))}
              </Nav>
              {hiddenProducts.length > 0 ? (
                <ProductsField
                  control={control}
                  errors={errors}
                  label={""}
                  inputName={"products"}
                  data={products.map((e) => ({
                    label: JSON.stringify(e),
                    value: e.productId,
                  }))}
                  hiddenProducts={hiddenProducts}
                />
              ) : (
                <Loader size="lg" />
              )}
            </Modal.Body>
          </Modal>
        </Form>
      )}
    </div>
  );
};

export default RpForm;
