import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { API_DEV } from "../../utils/apiRoute";
import { axiosPrivate } from "../../utils/interceptors";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { Target } from "../../types/Target.model";
import { Week } from "../../types/Week.model";
import { UpdateTarget } from "../../types/UpdateTarget.model";

export interface TargetState {
  requestState: "loading" | "idle";
  targets: Target[];
  targetByUserIdAndCycleId: Target | null;
  targetById: Target | null;
  targetByIdEdit: Target[];
  targetModalIsOpen: boolean;
  activeUpdatedState: Target | null;
  assignDone: boolean;
  selectedDoctors: number[];
  clipBoard: number[];
  undoIsActive: boolean;
  redoIsActive: boolean;
  resetIsActive: boolean;
  activeUpdatedStateIndex: number;
  errors: any;
}

export const prepareTarget = createAsyncThunk(
  "[Targets]__prepareTarget__",
  async (
    payload: {
      cycleId: number;
      userId: number;
    },
    { rejectWithValue }
  ) => {
    try {
      const response = await axiosPrivate.post(
        `${API_DEV}/Target?withResult=true`,
        payload
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(JSON.parse(JSON.stringify(error)));
    }
  }
);
export const fetchTargetById = createAsyncThunk(
  "[Targets]__fetchTargetById__",
  async (id: string, { rejectWithValue }) => {
    try {
      const response = await axiosPrivate.get(`${API_DEV}/Target/${id}`);
      return response.data;
    } catch (error) {
      return rejectWithValue(JSON.parse(JSON.stringify(error)));
    }
  }
);

export const assignDoctorsToTarget = createAsyncThunk(
  "[Targets]__assignDoctorsToTarget__",
  async (payload: UpdateTarget, { rejectWithValue }) => {
    try {
      const response = await axiosPrivate.put(
        `${API_DEV}/Target`,
        payload
      );
      return response.data;
    } catch (err:any) {
      return rejectWithValue(err.response.data);
    }
  }
);
 
export const updateTarget = createAsyncThunk(
  "[Targets]_update_",
  async (payload: Week[], { rejectWithValue }) => {
    try {
      let response = await axiosPrivate.put(`${API_DEV}/Target`, payload);
      return response.data;
    } catch (error) {
      return rejectWithValue(JSON.parse(JSON.stringify(error)));
    }
  }
);
const minIndex = 0;
const maxIndex = 20;
const initialState: TargetState = {
  requestState: "idle",
  targetModalIsOpen: false,
  targets: [],
  targetByUserIdAndCycleId: null,
  targetById: null,
  activeUpdatedState: null,
  targetByIdEdit: [],
  undoIsActive: false,
  redoIsActive: false,
  resetIsActive: false,
  errors: null,
  activeUpdatedStateIndex: 0,
  assignDone: false,
  selectedDoctors: [],
  clipBoard: [],
};

export const targetSlice = createSlice({
  name: "[Targets]",
  initialState,
  reducers: {
    toggleTargetModal: (state) => {
      state.targetModalIsOpen = !state.targetModalIsOpen;
    },
    closeTargetModal: (state) => {
      state.targetModalIsOpen = false;
    },
    selectDoctors: (state, action: PayloadAction<number[]>) => {
      state.selectedDoctors = action.payload;
    },
    copyDoctors: (state, action: PayloadAction<number[]>) => {
      state.assignDone = false;
      state.clipBoard = action.payload;
    },
    applyTargetChange: (state, action: PayloadAction<Target>) => {
      if (state.activeUpdatedStateIndex < maxIndex) {
        state.targetByIdEdit.push(action.payload);
        state.activeUpdatedStateIndex++;
      } else {
        state.targetByIdEdit.shift();
        state.targetByIdEdit.push(action.payload);
        state.activeUpdatedStateIndex++;
      }
      state.assignDone = false;
      state.activeUpdatedState = action.payload;
      state.undoIsActive = state.activeUpdatedStateIndex > minIndex;
      state.resetIsActive = state.activeUpdatedStateIndex > minIndex;
    },
    undoTargetChange: (state) => {
      if (state.activeUpdatedStateIndex > minIndex) {
        state.activeUpdatedState =
          state.targetByIdEdit[state.activeUpdatedStateIndex - 1];
        state.activeUpdatedStateIndex--;
      }
      state.assignDone = false;
      state.undoIsActive = state.activeUpdatedStateIndex > minIndex;
      state.resetIsActive = state.activeUpdatedStateIndex > minIndex;
      state.redoIsActive = state.activeUpdatedStateIndex < maxIndex;
    },
    redoTargetChange: (state) => {
      if (state.activeUpdatedStateIndex < maxIndex) {
        state.activeUpdatedState =
          state.targetByIdEdit[state.activeUpdatedStateIndex + 1];
        state.activeUpdatedStateIndex++;
      }
      state.assignDone = false;
      state.redoIsActive =
        state.activeUpdatedStateIndex < state.targetByIdEdit.length - 1;
      state.resetIsActive = state.activeUpdatedStateIndex > minIndex;
      state.undoIsActive = state.activeUpdatedStateIndex > minIndex;
    },
    resetTargetState: (state) => {
      state.assignDone = false;
      state.activeUpdatedState = state.targetById;
      state.targetByIdEdit = state.targetById ? [state.targetById] : [];
      state.undoIsActive = state.activeUpdatedStateIndex > minIndex;
      state.resetIsActive = state.activeUpdatedStateIndex > minIndex;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(prepareTarget.pending, (state) => {
      state.requestState = "loading";
      state.errors = null;
    });
    builder.addCase(prepareTarget.fulfilled, (state, { payload }) => {
      state.requestState = "idle";
      state.targetByUserIdAndCycleId = payload;
      state.errors = null;
    });
    builder.addCase(prepareTarget.rejected, (state, { payload }) => {
      state.requestState = "idle";
      state.errors = payload;
    });
    builder.addCase(fetchTargetById.pending, (state) => {
      state.requestState = "loading";
      state.errors = null;
    });
    builder.addCase(fetchTargetById.fulfilled, (state, { payload }) => {
      state.requestState = "idle";
      state.targetById = payload;
      state.targetByIdEdit = [payload];
      state.activeUpdatedState = payload;
      state.errors = null;
    });
    builder.addCase(fetchTargetById.rejected, (state, { payload }) => {
      state.requestState = "idle";
      state.errors = payload;
    });
    builder.addCase(updateTarget.pending, (state) => {
      state.requestState = "loading";
      state.errors = null;
    });
    builder.addCase(updateTarget.fulfilled, (state, { payload }) => {
      state.requestState = "idle";
      state.errors = null;
    });
    builder.addCase(updateTarget.rejected, (state, { payload }) => {
      state.requestState = "idle";
      state.errors = payload;
    });
    builder.addCase(assignDoctorsToTarget.pending, (state) => {
      state.requestState = "loading";
      state.assignDone = false;
      state.errors = null;
    });
    builder.addCase(assignDoctorsToTarget.fulfilled, (state, { payload }) => {
      state.requestState = "idle";
      state.assignDone = true;
      state.errors = null;
    });
    builder.addCase(assignDoctorsToTarget.rejected, (state, { payload }) => {
      state.requestState = "idle";
      state.assignDone = false;
      state.errors = payload;
    });
  },
});

export const {
  toggleTargetModal,
  selectDoctors,
  copyDoctors,
  closeTargetModal,
  applyTargetChange,
  undoTargetChange,
  redoTargetChange,
  resetTargetState,
} = targetSlice.actions;

export default targetSlice.reducer;
