import { IconButton } from "@material-ui/core";
import { Delete, Edit } from "@material-ui/icons";
import React from "react";
import {
  SalesTargetUsedInFormInterface,
} from "../../../../../types/SalesTarget.model";
import "./PhalconCell.scss";

type Props = {
  setOpenDeleteModal: React.Dispatch<React.SetStateAction<boolean>>;
  setListOfSalesTargetIds: React.Dispatch<
    React.SetStateAction<
      {
        label: string;
        value: number | null;
      }[]
    >
  >;
  handleOpenSalesTargetModal: () => void;
  setUpdateSalesTargetObject?: React.Dispatch<
    React.SetStateAction<{
      productId: number;
      salesObject: SalesTargetUsedInFormInterface;
      monthsId: {label: number | string, value: number | string | null}[];
    } | null>
  >;
  objectParams: any;
};

export default function ActionButtonSalesTargetCell({
  setOpenDeleteModal,
  setListOfSalesTargetIds,
  handleOpenSalesTargetModal,
  setUpdateSalesTargetObject,
  objectParams,
}: Props) {
  return (
    <div className="action-cell-sales-target-wrapper">
      <IconButton
        aria-describedby={`simple-popover`}
        size="small"
        className="action-cell-sales-target-edit"
        onClick={() => {
          handleOpenSalesTargetModal();
          setUpdateSalesTargetObject &&
            setUpdateSalesTargetObject({
              productId: objectParams.productId,
              salesObject: {
                M1: `${objectParams.january}`,
                M2: `${objectParams.february}`,
                M3: `${objectParams.march}`,
                M4: `${objectParams.april}`,
                M5: `${objectParams.may}`,
                M6: `${objectParams.june}`,
                M7: `${objectParams.july}`,
                M8: `${objectParams.august}`,
                M9: `${objectParams.september}`,
                M10: `${objectParams.october}`,
                M11: `${objectParams.november}`, 
                M12: `${objectParams.december}`,
                product: objectParams.productId,
                total: `${objectParams.totalQuantity}`,
              },
              monthsId: objectParams.monthIds
            });
        }}
      >
        <Edit />
      </IconButton>
      <IconButton
        size="small"
        aria-describedby={`simple-popover`}
        className="action-cell-sales-target-delete"
        onClick={() => {
          setListOfSalesTargetIds(objectParams.monthIds);
          setOpenDeleteModal(true);
        }}
      >
        <Delete />
      </IconButton>
    </div>
  );
}
