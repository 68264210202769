import { createTheme } from "@material-ui/core/styles";
import { frFR } from "@material-ui/core/locale";
 
export const theme = createTheme(
  {
    palette: {
      primary: { main: "#20abe3" },
      secondary: { main: "#adb8c1" },
      success: { main: "#49cc90" },
      error: { main: "#f93e3e" },
      warning: { main: "#fca130" },
      info: { main: "#61affe" },
    },
  },
  frFR
);
