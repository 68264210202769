import { ThunkDispatch } from "@reduxjs/toolkit";
import { AnyAction, Middleware } from "redux";
import {
  doctorMessageHandler,
  facilityMessageHandler,
  sellerMessageHandler,
  cycleMessageHandler,
  planificationMessageHandler,
  salesTargetMessageHandler,
  usersMessageHandler,
  toggleFaciltyMessageHandler,
  specMsgHandler
} from "../../utils/apiErrorMessageHandler";
import errorToast from "../../utils/errorToast";
export const errorMiddleware: Middleware<
  {},
  unknown,
  ThunkDispatch<unknown, unknown, AnyAction>
> = (storeApi) => (next) => (action) => {
  next(action);

  switch (action.type) {
    case "[User]_updatePassword_/rejected":
      errorToast("Mot de passe incorrect");
      break;
    case "[User]_updateInfo_/rejected":
      errorToast("Mot de passe incorrect");
      break;
    case "[Sellers]_add_/rejected":
      sellerMessageHandler(action.payload.message);
      break;
    case "[Sellers]_update_/rejected":
      sellerMessageHandler(action.payload.message);
      break;
    case "[Sellers]_delete_/rejected":
      sellerMessageHandler(action.payload.message);
      break;
    case "[Facilities]_add_/rejected":
      facilityMessageHandler(action.payload.message);
      break;
    case "[Facilities]_update_/rejected":
      facilityMessageHandler(action.payload.message);
      break;
    case "[Facilities]_delete_/rejected":
      facilityMessageHandler(action.payload.message);
      break;
    case "[Doctors]_add_/rejected":
      doctorMessageHandler(action.payload.message);
      break;
    case "[Doctors]_update_/rejected":
      doctorMessageHandler(action.payload.message);
      break;
    case "[Doctors]_delete_/rejected":
      doctorMessageHandler(action.payload.message);
      break;
    case "[Approvals]_ApproveRequest_/rejected":
      errorToast("Une erreur s'est produite, veuillez réessayer");
      break;
    case "[Cycles]_add_/rejected":
      cycleMessageHandler(action.payload.message);
      break;
    case "[Cycles]_delete_/rejected":
      cycleMessageHandler(action.payload.message);
      break;
    case "[Cycles]__associateDelegates__/rejected":
      cycleMessageHandler(action.payload.message);
      break;
    case "[Cycles]__dissociateDelegates__/rejected":
      cycleMessageHandler(action.payload.message);
      break;
    case "[Targets]__assignDoctorsToTarget__/rejected":
      errorToast("Une erreur s'est produite, veuillez réessayer");
      break;
    case "[Planifications]_update_/rejected":
      errorToast("Une erreur s'est produite, veuillez réessayer");
      break;
    case "[Planifications]__createPlanification__/rejected":
      planificationMessageHandler(action.payload.message);
      break;
    case "[User]_updatePhoto_/rejected":
      errorToast("Une erreur s'est produite, veuillez réessayer");
      break;
    case "[SalesTarget]_AddSalesTarget_/rejected":
      salesTargetMessageHandler(action.payload.message);
      break;
    case "[SalesTarget]_deleteSalesTarget_/rejected":
      salesTargetMessageHandler(action.payload.message);
      break;
    case "cc/rejected":
      salesTargetMessageHandler(action.payload.message);
      break;
    case "[Products]_addProduct_/rejected":
      errorToast("Une erreur s'est produite, veuillez réessayer");
      break;
    case "[Products]_ToggleProd_/rejected":
      errorToast("Une erreur s'est produite, veuillez réessayer");
      break;
    case "[Users]_createUser_/rejected":
      console.log(action.payload.message)
      usersMessageHandler(action.payload.message)
      break;
    case "[Toggle]_ToggleFacility_/rejected":
      toggleFaciltyMessageHandler(action.payload.message)
      break;
    case "[create]_CreateFacilityTypeThunk_/rejected":
      toggleFaciltyMessageHandler(action.payload.message)
      break;
    case "[update]_updateFacilityTypeByIdThunk_/rejected":
      errorToast("Nom d'etablissement existe deja");
      break;
    case "[spec]_CreateSpecThunk_/rejected":
      specMsgHandler(action.payload.message)
      break;
    case "[spec]_updateSpecByIdThunk_/rejected":
      specMsgHandler(action.payload.message)
      break;
    case "[spec]_deleteSpec_/rejected":
      specMsgHandler(action.payload.message)

      break;
    case "[EADV]_uplaodeadv_/rejected":
      errorToast("Une erreur s'est produite")
      break;

    default:
      break;
  }
};
