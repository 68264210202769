import { createSlice } from "@reduxjs/toolkit";
import { API_DEV } from "../../utils/apiRoute";
import { axiosPrivate } from "../../utils/interceptors";
import { createAsyncThunk } from "@reduxjs/toolkit";


export interface users {
    users: []
}


const initialState: users = {
    users: []
}

export const createUserThunk = createAsyncThunk("[Users]_createUser_",
    async (data, { rejectWithValue }) => {
        try {
            let response = await axiosPrivate.post(`${API_DEV}/User`, data);
            // console.log(response.data)
            return response.data;
        } catch (error: any) {
            // console.log(JSON.parse(JSON.stringify(error.response.data)));
            return rejectWithValue(JSON.parse(JSON.stringify(error.response.data)));
        }
    })

export const getAllUsersThunk = createAsyncThunk("[Users]_getUers_",
    async (_, { rejectWithValue }) => {
        try {
            let response = await axiosPrivate.post(`${API_DEV}/User/all`, {
                "wordSearch": null,
                "status": null,
                "pagination": {
                    "pageNumber": 1,
                    "pageSize": 2000
                }
            });
            // console.log(response.data)
            return response.data;
        } catch (error) {
            console.log(JSON.parse(JSON.stringify(error)));
            return rejectWithValue(JSON.parse(JSON.stringify(error)));
        }
    })

export const deleteUserById = createAsyncThunk("[Users]_DisableUser_",
    async (id, { rejectWithValue }) => {
        try {
            let response = await axiosPrivate.delete(`${API_DEV}/User/${id}`);
            // console.log(response.data)
            return response.data;
        } catch (error: any) {
            console.log(JSON.parse(JSON.stringify(error)));
            return rejectWithValue(JSON.parse(JSON.stringify(error.response.data)));
        }
    })

export const UsersSlice = createSlice({
    name: "[Targets]",
    initialState,
    reducers: {
    },
    extraReducers: (builder) => {
        builder.addCase(getAllUsersThunk.fulfilled, (state, { payload }) => {
            state.users = payload.userProfileDTOs
        });
        builder.addCase(deleteUserById.fulfilled, (state, { payload }) => {
            
        }).addCase(deleteUserById.rejected, (state, payload) => { console.log(payload) });
        builder.addCase(createUserThunk.fulfilled, (state, { payload }) => {
            
        }).addCase(createUserThunk.rejected, (state:any,action :any) => {
            

        })
    },
});

export const { } = UsersSlice.actions;// eslint-disable-line


export default UsersSlice.reducer;
