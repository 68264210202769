import React, { useEffect } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { useAppSelector } from "../../../redux/hooks";
import { RootState } from "../../../redux/store";
import { useRoutingConfig } from "../../../utils/useRoutingConfig";
import { Header } from "../../05-layouts/Header/Header";
import "./CycleManagementPage.scss";

interface Props {}
export const CycleManagementPage = (props: Props) => {
  const facility = useLocation();
  const navigate = useNavigate();
  useEffect(() => {
    facility.pathname === "/cycles-management" &&
      navigate("/cycles-management/general");
  }, [facility, navigate]);

  const currentRoute = useRoutingConfig().find((e) => e.key === "3");
  const subRoutes =
    typeof currentRoute !== "undefined" ? currentRoute.children : [];

  const { redirectToTargetPage } = useAppSelector(
    (state: RootState) => state.UI
  );
  const { targetByUserIdAndCycleId } = useAppSelector(
    (state: RootState) => state.target
  );
  useEffect(() => {
    if (targetByUserIdAndCycleId && redirectToTargetPage)
      navigate(`/target/${targetByUserIdAndCycleId.targetId}`);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [redirectToTargetPage, targetByUserIdAndCycleId]);

  return (
    <>
      <Header
        leftItem={
          <div className="cycle-management__header__title">
            Gestion des cycles
          </div>
        }
        subRoutes={subRoutes}
      />
      <Outlet />
    </>
  );
};
