import React, { useEffect, useState } from "react";
import { Calendar } from "rsuite";
import {
  formatAgendaData,
  FormatedAgenda,
} from "../../../../helpers/dataFormator";
import { fetchSelectedUserAgenda } from "../../../../redux/Agenda/agendaSlice";
import { useAppDispatch, useAppSelector } from "../../../../redux/hooks";
import { RootState } from "../../../../redux/store";
import {  Popover, Whisper } from "rsuite";
import User from "../../../../types/User.model";
import { getcurrentDate } from "../../../../utils/dateHelpers";
import "./DelegateAgenda.scss";
type Props = {
  user: User | null;
};

export const DelegateAgenda = ({ user }: Props) => {
  const [selectedMonth, setSelectedMounth] = useState<string>(
    getcurrentDate(undefined)
  );
  const dispatch = useAppDispatch();
  const { agendaData, requestState } = useAppSelector(
    (state: RootState) => state.agenda
  );

  useEffect(() => {
    if (user && selectedMonth) {
      let { userId } = user;
      dispatch(
        fetchSelectedUserAgenda({
          calendarView: 2,
          date: selectedMonth,
          userId: userId,
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedMonth.slice(0,7), user]);

  // Function that render the schedual inside each calendar Cell
  const renderCell = (data: FormatedAgenda) => {
    let { schedual, isHolidy } = data;
    if (schedual && schedual.length) {
      const displayList = schedual.filter((item, index) => index < 1);
      const moreCount = schedual.length - displayList.length;
      const moreItem = (
        <li >
          <Whisper
            
            trigger="click"
            speaker={
              <Popover style={{maxHeight: "140px", overflowY:"auto"}}>
                {schedual.map((item, index) => (
                  <p key={index}>
                   <span style={{fontWeight: "bold"}}>{item.time}</span> - {item.description}
                  </p>
                ))}
              </Popover>
            }
          >
            <span style={{fontSize: "10px",  color: "#20abe3", fontWeight: "bold"}} >{moreCount} Plus</span>
          </Whisper>
        </li>
      );
      return (
        <ul className="calendar-todo-list">
          {displayList.map((item, index) => (
            <li
              key={index}
              style={{
                fontSize: "10px",
                display: "flex", 
                columnGap: "4px",
                whiteSpace: "nowrap",
                
              }}
            >
               <span style={{fontWeight: "bold"}}>{item.time}</span > - {item.description}
            </li>
          ))}
          {moreCount ? moreItem : null}
        </ul>
      );
    } else if (isHolidy) {
      return <div>Jour férié</div>;
    } else return "";
  };

  const handleRenderCell = (date: Date) => {
    let list: FormatedAgenda[] = formatAgendaData(agendaData);
    if (requestState=== "idle" && list && list.length) {
      return list.map((data) => {
        if (data.date === getcurrentDate(date)) return renderCell(data);
        else return "";
      });
    } else {
      return "";
    }
  };

  return (
    <div className="delegate-agenda__wrapper">
      <Calendar
        bordered
        className="delegate-agenda"
        isoWeek
        onChange={(date: Date) => {          
          date && setSelectedMounth(getcurrentDate(date));
        }}
        renderCell={(date: Date) => {
          return handleRenderCell(date);
        }}
      />
    </div>
  );
};
