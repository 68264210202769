import React from "react";
import { useTranslation } from "react-i18next";
import Agenda from "../../04-templates/Agenda/Agenda";
import { Header } from "../../05-layouts/Header/Header";

type Props = {};

const AgendaPage = (props: Props) => {
  const { t } = useTranslation();
  const headerLeftItem = <h2>{t("agenda_page.title")}</h2>;
  return (
    <div>
      <Header leftItem={headerLeftItem} />
      <Agenda />
    </div>
  );
};

export default AgendaPage;
