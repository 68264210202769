import { BusinessUnit } from "../types/BusinessUnit.model";
import { Facility } from "../types/Facility.model";
import { FacilityType } from "../types/FacilityType.model";
import { Potential } from "../types/Potential.model";
import { ServiceType } from "../types/ServiceType.model";
import { Speciality } from "../types/Speciality.model";
import { Tag } from "../types/Tag.model";
import { API_DEV } from "../utils/apiRoute";
import { axiosPrivate } from "../utils/interceptors";
import { Team } from "../types/Team.model";
import _ from "lodash";
import { DoctorSummary } from "../types/DoctorSummary.model";
import { SellerType } from "../types/SellerType.model";
import { Product } from "../types/Product.model";

type TeamMember = {
  userId: number;
  firstName: string;
  lastName: string;
  registrantionNumber: string;
  gender: number;
  photoUrl: string;
  role: number;
  businessUnits: BusinessUnit[];
};

type facilitieservices = {
  serviceId: number;
  serviceType: {
    serviceTypeId: number;
    serviceTypeName: string;
  };
};
export const phoneTypeOptions = [
  { key: 0, label: "Mobile", value: 0 },
  { key: 1, label: "Fixe", value: 2 },
  { key: 2, label: "Fax", value: 1 },
];
export const titleOptions = [
  { key: 0, label: "Dr", value: "Dr" },
  { key: 1, label: "Pr", value: "Pr" },
];

/**
 * Helper Function that sets the PotentialsOptions state based
 * on Potentials array fetched in the store [generalData]
 *
 * @param potentials - Array - Potentials fetched with redux
 */
export const preparePotentialsOptions = (ressource: Potential[]) =>
  ressource.map((potential: Potential) => ({
    key: potential.potentialId,
    label: potential.potentialName,
    value: potential.potentialId,
  }));

/**
 * Helper Function that sets the BusinessUnitsOptions state based
 * on BusinessUnits array fetched in the store [generalData]
 *
 * @param businessUnits - Array - BusinessUnits fetched with redux
 */
export const prepareBusinessUnitsOptions = (ressource: BusinessUnit[]) =>
  ressource.map((businessUnit: BusinessUnit) => ({
    key: businessUnit.businessUnitId,
    label: businessUnit.businessUnitName,
    value: businessUnit.businessUnitId,
  }));

/**
 * Helper Function that sets the TagsOptions state based
 * on Tags array fetched in the store [generalData]
 *
 * @param tags - Array - Tags fetched with redux
 */
export const prepareTagsOptions = (ressource: Tag[]) =>
  ressource.map((tag: Tag) => ({
    key: tag.tagId,
    label: tag.tagName,
    value: tag.tagId,
  }));

/**
 * Helper Function that sets the sellerTypesOptions state based
 * on sellerTypes array fetched in the store [generalData]
 *
 * @param sellerTypes - Array - sellerTypes fetched with redux
 */
export const prepareSellerTypesOptions = (ressource: SellerType[]) =>
  ressource.map((sellerType: SellerType) => ({
    key: sellerType.sellerTypeId,
    label: sellerType.sellerTypeName,
    value: sellerType.sellerTypeId,
  }));

/**
 * Helper Function that sets the facilityTypesOptions state based
 * on facilityTypes array fetched in the store [generalData]
 *
 * @param FacilityType - Array - facilityTypes fetched with redux
 */
export const preparefacilityTypesOptions = (ressource: FacilityType[]) =>
  ressource.map((facilityType: FacilityType) => ({
    key: facilityType.facilityTypeId,
    label: facilityType.facilityTypeName,
    value: facilityType.facilityTypeId,
  }));

/**
 * Helper Function that sets the SpecialitiesOptions state based
 * on Specialities array fetched in the store [generalData]
 *
 * @param specialities - Array - Specialities fetched with redux
 */
export const prepareSpecialitiesOptions = (ressource: Speciality[]) =>
  ressource.map((speciality: Speciality) => ({
    key: speciality.specialityId,
    label: speciality.specialityName,
    value: speciality.specialityId,
  }));

/**
 * Helper Function that sets the ServicesOptions based on all the services
 *fetched from database [generalData]
 * @param serviceType - Array - services fetched with redux
 */
export const prepareServicesOptions = (ressource: ServiceType[]) =>
  ressource.map((serviceType: ServiceType) => ({
    key: serviceType.serviceTypeId,
    label: serviceType.serviceTypeName,
    value: serviceType.serviceTypeId,
  }));

/**
 * Helper Function that fetch facilities based on both the selected facilityTypeId
 * and the index of the input (usefieldarray) and sets the state preparefacilitiesOptions
 * as an array of arrays
 *
 * @param id - Number - id of the selected facilityTypeId
 * @param index - Number - index of the actual input
 */
export const preparefacilitiesOptions = async (
  id: number,
  index: number,
  facilitiesOptions: { key: number; label: string; value: number }[][],
  setfacilitiesOptions: React.Dispatch<
    React.SetStateAction<
      {
        key: number;
        label: string;
        value: number;
      }[][]
    >
  >
) => {
  try {
    let response = await axiosPrivate.get(
      `${API_DEV}/Facility/GetFacilitiesByType/${id}`
    );

    if (response.data.length > 0) {
      let array = [...facilitiesOptions];
      array[index] = response.data.map((facility: Facility) => ({
        key: facility.facilityId,
        label: facility.facilityName,
        value: facility.facilityId,
      }));
      setfacilitiesOptions(array);
    }
  } catch (error) {
    console.log(JSON.parse(JSON.stringify(error)));
  }
};

/**
 * Helper Function that fetch Services based on both the selected facilityId
 * and the index of the input (usefieldarray), then sets the state servicesOptions
 * as an array of arrays
 *
 * @param id - Number - id of the selected facilityId
 * @param index - Number - index of the actual input
 */
export const prepareServicesByfacilityOptions = async (
  id: number,
  index: number,
  servicesOptions: { key: number; label: string; value: number }[][],
  setServicesOptions: React.Dispatch<
    React.SetStateAction<
      {
        key: number;
        label: string;
        value: number;
      }[][]
    >
  >
) => {
  try {
    let response = await axiosPrivate.get(`${API_DEV}/Facility/${id}/Services`);
    if (response.data.length > 0) {
      let array = [...servicesOptions];
      array[index] = response.data.map(
        (facilitieservice: facilitieservices) => ({
          key: facilitieservice.serviceId,
          label: facilitieservice.serviceType.serviceTypeName,
          value: facilitieservice.serviceId,
        })
      );
      setServicesOptions(array);
    }
  } catch (error) {
    console.log(JSON.parse(JSON.stringify(error)));
  }
};

/**
 * Helper Function that sets the inPortfolioOfOption (users of the same team) state based
 * on Teams array fetched in the store [generalData]
 *
 * @param  - Array - Teams fetched with redux
 */
export const prepareInPortfolioOfOptions = (ressource: Team[]) => {
  let formatedRessources = _.uniqWith(
    [...new Set(ressource.flatMap((item: Team) => item.members))],
    _.isEqual
  );
  return formatedRessources.map((team: TeamMember) => ({
    key: team.userId,
    label: `${team.firstName} ${team.lastName}`,
    value: team.userId,
  }));
};

/**
 * Helper Function that sets the DoctorSummaryOptions state based
 * on DoctorSummary array fetched in the store [doctor]
 *
 * @param DoctorSummary - Array - DoctorSummary fetched with redux
 */
export const prepareDoctorSummaryOptions = (ressource: DoctorSummary[]) =>
  ressource.map((doctor: DoctorSummary) => ({
    key: doctor.doctorId,
    label: `${doctor.title} ${doctor.firstName} ${doctor.lastName}`,
    value: doctor.doctorId,
  }));

/**
 * Helper Function that sets the producrOption state based
 * on products array fetched in the store [general]
 * Hint : the virtual prodcut aren not returned in function
 * @param Products - Array - DoctorSummary fetched with redux
 */
export const prepareProductOptions = (ressource: Product[]) => {
  if (ressource && typeof ressource === "object" && ressource.length) {
    return ressource
      .filter((nonVirtualProduct) => !nonVirtualProduct.isVirtual)
      // eslint-disable-next-line array-callback-return
      .map((product: Product) => {
        if (product && typeof product === "object") {
          return {
            key: product.productId,
            label: product.productName,
            value: product.productId,
          }; 
        }
      });
  } else return [];
};
