import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../../redux/hooks";
import { RootState } from "../../../../redux/store";
import {
  toggleAddDoctorDrawer,
  toggleUpdateDoctorDrawer,
} from "../../../../redux/UI/uiSlice";
import { BusinessUnit } from "../../../../types/BusinessUnit.model";
import { Speciality } from "../../../../types/Speciality.model";
import { Tag } from "../../../../types/Tag.model";
import { Doctor } from "../../../../types/Doctor.model";
import {
  prepareBusinessUnitsOptions,
  preparePotentialsOptions,
  prepareSpecialitiesOptions,
  prepareTagsOptions,
  prepareInPortfolioOfOptions,
} from "../../../../helpers/GlobalFormHelpers";
import { AdditionalInfo } from "../../../../types/AdditionalInfo.model";
import { getDefaultLocalitiesOptions } from "../../../../redux/Locality/localitySlice";
import { Phone } from "../../../../types/Phone.model";
import { LocalitySummary } from "../../../../types/Locality.model";

export interface DoctorFormInputs {
  doctorId?: number | null;
  title: string;
  firstName: string;
  lastName: string;
  email: string;
  reference: string;
  patientsPerDay: string;
  potential: number | null;
  businessUnits: number[];
  specialities: number[];
  tags: (number | string)[];
  facilities: {
    serviceId: number | null;
    facilityTypeId: number | null;
    facilityId: number | null;
    isPrimaryFacility: boolean;
  }[];
  offices: {
    officeId: number | null;
    phones: Phone[];
    address: {
      addressId: number | null;
      streetName: string;
      addressNote: string;
      localities: LocalitySummary[];
    };
    isPrimary: boolean;
  }[];
  principalPhone: Phone;
  phones: Phone[];
  additionalInfos: AdditionalInfo[];
  inPortfolioOf: (number | string | null)[];
}

export const prepareDoctorFormPayload = (formOutput: DoctorFormInputs) => {
  return {
    doctorId: formOutput.doctorId,
    title: formOutput.title,
    firstName: formOutput.firstName,
    lastName: formOutput.lastName,
    email: formOutput.email,
    reference: formOutput.reference,
    patientsPerDay: parseInt(formOutput.patientsPerDay || ""),
    facilities: formOutput?.facilities?.map((e) => ({
      serviceId: e.serviceId,
      facilityId: e.facilityId,
      isPrimary: e.isPrimaryFacility,
    })),
    offices: formOutput.offices
      ? formOutput.offices?.map((office) => ({
          officeId: office?.officeId,
          phones: office.phones,
          address: {
            addressId: office.address?.addressId || 0,
            streetName: office.address?.streetName,
            addressNote: office.address?.addressNote,
            localities: office.address?.localities,
          },
          isPrimary: office.isPrimary,
        }))
      : [],
    potential: { potentialId: formOutput.potential },
    specialities: formOutput.specialities.map((e, index) =>
      index !== 0
        ? { specialityId: e, isPrincipal: false }
        : { specialityId: e, isPrincipal: true }
    ),
    businessUnits: formOutput.businessUnits.map((e) => ({ businessUnitId: e })),
    phones: [...formOutput.phones, formOutput.principalPhone],
    tags: formOutput?.tags?.map(
      (
        value //to do when  backend add it in  the object
      ) => (typeof value === "number" ? { tagId: value } : { tagName: value })
    ) || [],
    additionalInfos: formOutput.additionalInfos,
    inPortfolioOf: formOutput.inPortfolioOf?.map((e) => ({ userId: e })) || [],
  };
};

export const fieldIsRequired = "Ce champ est requis";
type Props = {
  formContext: "add" | "update";
};
type FormOptions = {
  potentialsOptions: {
    key: number;
    label: string;
    value: number | string;
  }[];
  businessUnitsOptions: {
    key: number;
    label: string;
    value: number | string;
  }[];
  specialitiesOptions: {
    key: number;
    label: string;
    value: number | string;
  }[];
  tagsOptions: {
    key: number;
    label: string;
    value: number | string;
  }[];
  titleOptions: {
    key: number;
    label: string;
    value: number | string;
  }[];
  inPortfolioOfOption: {
    key: number;
    label: string;
    value: number | string;
  }[];
};

const useDoctorForm = ({ formContext }: Props) => {
  const dispatch = useAppDispatch();
  const { addDoctorDrawerIsOpen, updateDoctorDrawerIsOpen } = useAppSelector(
    (state: RootState) => state.UI
  );
  const { doctorById } = useAppSelector((state: RootState) => state.doctor);
  const { specialities, businessUnits, tags, potentials, teams } =
    useAppSelector((state: RootState) => state.general);
  const [initialValues, setInitialValues] = useState<DoctorFormInputs>({
    title: "Dr",
    firstName: "",
    lastName: "",
    email: "",
    reference: "",
    patientsPerDay: "",
    potential: null,
    businessUnits: [],
    specialities: [],
    tags: [],
    additionalInfos: [],
    facilities: [
      {
        facilityId: null,
        facilityTypeId: null,
        serviceId: null,
        isPrimaryFacility: false,
      },
    ],
    offices: [],
    principalPhone: {
      phoneId: null,
      phoneType: null,
      phoneNumber: "",
      phoneDescription: "",
      isPrincipal: true,
    },
    phones: [],
    inPortfolioOf: [],
  });

  const [formIsReady, setFormIsReady] = useState(false);

  const [formOptions, setFormOptions] = useState<FormOptions | null>(null);

  //Methods
  const defineFormUI: (formCtx: "add" | "update") => {
    formIsOpen: boolean;
    toggleForm: () => unknown;
  } = (formCtx: "add" | "update") => {
    switch (formCtx) {
      case "add":
        return {
          formIsOpen: addDoctorDrawerIsOpen,
          toggleForm: () => dispatch(toggleAddDoctorDrawer()),
        };

      case "update":
        return {
          formIsOpen: updateDoctorDrawerIsOpen,
          toggleForm: () => dispatch(toggleUpdateDoctorDrawer()),
        };

      default:
        return {
          formIsOpen: false,
          toggleForm: () => null,
        };
    }
  };

  const defineInitialValues = (
    formContext: "add" | "update",
    doctorRecord: Doctor | null
  ): DoctorFormInputs => {
    const doctorInitialRawData = formContext === "update" ? doctorRecord : null;
    if (formContext === "update") {
      doctorInitialRawData?.offices[0] &&
        doctorInitialRawData?.offices[0].address.localities.forEach((loc) => {
          dispatch(getDefaultLocalitiesOptions(loc.localityId.toString()));
        });
    }
    return {
      doctorId: doctorInitialRawData?.doctorId || null,
      title: doctorInitialRawData?.title || "Dr",
      firstName: doctorInitialRawData?.firstName || "",
      lastName: doctorInitialRawData?.lastName || "",
      email: doctorInitialRawData?.email || "",
      reference: doctorInitialRawData?.reference || "",
      patientsPerDay: doctorInitialRawData?.patientsPerDay.toString() || "",
      potential: doctorInitialRawData?.potential.potentialId || null,
      businessUnits:
        doctorInitialRawData?.businessUnits.map(
          (businessUnit: BusinessUnit) => businessUnit.businessUnitId
        ) || [],
      specialities:
        doctorInitialRawData?.specialities.map(
          (specialtiy: Speciality) => specialtiy.specialityId
        ) || [],
      tags: doctorInitialRawData?.tags.map((tag: Tag) => tag.tagId) || [],
      additionalInfos: doctorInitialRawData?.additionalInfos || [],
      facilities:
        doctorInitialRawData?.facilities.map((e) => ({
          serviceId: e.services[0]?.serviceId || null,
          facilityTypeId: e.facilityType?.facilityTypeId || 0,
          facilityId: e.facilityId,
          isPrimaryFacility: e.isPrimaryFacility,
        })) || [],
      offices:
        doctorInitialRawData && doctorInitialRawData?.offices.length > 0
          ? doctorInitialRawData?.offices.map((office) => ({
              officeId: office.officeId || 0,
              phones: office.phones,
              address: {
                addressId: office?.address?.addressId || 0,
                streetName: office?.address?.streetName || "",
                addressNote: office?.address?.addressNote || "",
                localities:
                  office?.address?.localities &&
                  office?.address?.localities?.length > 0
                    ? [...office.address.localities].sort(
                        (a, b) => a.level - b.level
                      )
                    : [],
              },
              isPrimary: office.isPrimary || false,
            }))
          : [],
      principalPhone: (doctorInitialRawData?.phones &&
        doctorInitialRawData?.phones.length > 0 &&
        doctorInitialRawData?.phones.find((p) => p.isPrincipal === true)) || {
        phoneId: null,
        phoneType: null,
        phoneNumber: "",
        phoneDescription: "",
        isPrincipal: true,
      },

      phones: doctorInitialRawData?.phones.filter((p) => !p.isPrincipal) || [],
      inPortfolioOf:
        doctorInitialRawData?.inPortfolioOf.map((user) => user.userId) || [],
    };
  };

  //Global data preparation

  useEffect(() => {
    const potentialsOptions = preparePotentialsOptions(potentials);
    const businessUnitsOptions = prepareBusinessUnitsOptions(businessUnits);
    const specialitiesOptions = prepareSpecialitiesOptions(specialities);
    const tagsOptions = prepareTagsOptions(tags);
    const titleOptions = [
      { key: 0, label: "Dr", value: "Dr" },
      { key: 1, label: "Pr", value: "Pr" },
    ];
    const inPortfolioOfOption = prepareInPortfolioOfOptions(teams);
    if (
      potentialsOptions.length > 0 &&
      businessUnitsOptions.length > 0 &&
      specialitiesOptions.length > 0
    ) {
      setFormOptions({
        potentialsOptions,
        businessUnitsOptions,
        specialitiesOptions,
        tagsOptions,
        titleOptions,
        inPortfolioOfOption,
      });
    }
  }, [businessUnits, potentials, specialities, tags, teams]);

  useEffect(() => {
    if (formContext === "add") {
      formOptions ? setFormIsReady(true) : setFormIsReady(false);
    } else {
      doctorById && formOptions ? setFormIsReady(true) : setFormIsReady(false);
    }
  }, [formIsReady, formOptions, doctorById, formContext]);

  //Methods execution
  const formUI = defineFormUI(formContext);

  useEffect(() => {
    const init = defineInitialValues(formContext, doctorById);
    setInitialValues(init);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [doctorById, formContext]);

  return { initialValues, formUI, formIsReady, formOptions, setFormIsReady };
};

export default useDoctorForm;
