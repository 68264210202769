import React, { createRef, useState } from "react";
import { Controller } from "react-hook-form";
import { Form, Col, CheckTreePicker, Input, Row, Divider } from "rsuite";
import { CustomButton } from "../../../01-atoms/Buttons/Buttons";
import { CustomAvatar } from "../../../01-atoms/CustomAvatar/CustomAvatar";
import { CustomErrorMessage } from "../ErrorMessage/CustomErrorMessage";
import "./PersonsField.scss";

type PersonItem = {
  label: string;
  value: number;
};
type Props = {
  control: any;
  errors: any;
  label: string;
  inputName: string;
  xs?: number;
  md?: number;
  lg?: number;
  data: PersonItem[];
  className?: string;
  handleAddExternal: (
    fullName: string,
    email: string,
    phone: string,
    context: number,
    description: string
  ) => void;
};

export const PersonsField = ({
  label,
  control,
  inputName,
  errors,
  xs,
  md,
  lg,
  data,
  className,
  handleAddExternal,
}: Props) => {
  const tagsInputRef: any = createRef();

  const renderElement = (element: any) => {
    const { photoUrl, fullName } = JSON.parse(element.label);
    return (
      <div className="person-element">
        {["myTeams", "otherTeams"].includes(inputName) && (
          <CustomAvatar
            image={{
              src: photoUrl,
              alt: `${fullName}'s avatar`,
            }}
            style={{ width: "25px", height: "25px" }}
          />
        )}
        <span>{fullName}</span>
      </div>
    );
  };

  const [fullName, setFullName] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [phone, setPhone] = useState<string>("");
  const [description, setDescription] = useState<string>("");

  const onSubmitAddExternal = () => {
    let context = inputName === "externals" ? 2 : 3;
    handleAddExternal(fullName, email, phone, context, description);
    setFullName("");
    setEmail("");
    setPhone("");
    setDescription("");
  };
  return (
    <Col
      xs={xs || 24}
      md={md}
      lg={lg}
      style={{ marginBottom: "1rem" }}
      className={className}
    >
      <Form.Group>
        <Form.ControlLabel>{label}</Form.ControlLabel>
        {["externals", "moderators"].includes(inputName) && (
          <>
            <Row style={{ margin: "0", padding: "0 .5rem" }}>
              <Col xs={24} md={8}>
                <Input
                  size="lg"
                  type="text"
                  name="fullName"
                  value={fullName}
                  placeholder="Nom et prénom"
                  onChange={(value) => setFullName(value)}
                />
              </Col>
              <Col xs={24} md={8}>
                <Input
                  size="lg"
                  type="text"
                  name="email"
                  value={email}
                  placeholder="Email"
                  onChange={(value) => setEmail(value)}
                />
              </Col>
              <Col xs={24} md={8}>
                <Input
                  size="lg"
                  type="text"
                  name="phone"
                  value={phone}
                  placeholder="Téléphone"
                  onChange={(value) => setPhone(value)}
                />
              </Col>
              <Col xs={24} md={20} style={{ marginTop: ".5rem" }}>
                <Input
                  size="lg"
                  type="text"
                  name="description"
                  value={description}
                  placeholder="Description"
                  onChange={(value) => setDescription(value)}
                />
              </Col>
              <Col xs={24} md={4} style={{ marginTop: ".5rem" }}>
                <CustomButton
                  text="Ajouter"
                  color="dark"
                  className="add-external-button"
                  onClick={() => onSubmitAddExternal()}
                />
              </Col>
            </Row>
            <Divider />
          </>
        )}
        <Controller
          control={control}
          name={inputName}
          render={({ field: { onChange, name, value } }) => (
            <Form.Control
              ref={tagsInputRef}
              accepter={CheckTreePicker}
              name={name}
              value={value}
              searchable
              cascade={false}
              open
              className="input-full-width persons-field"
              style={{ width: "100%" }}
              container={() => tagsInputRef.current}
              preventOverflow
              data={data}
              renderTreeNode={(nodeData: any) => renderElement(nodeData)}
              placement="autoHorizontal"
              onChange={(e: any) => {
                onChange(e);
              }}
            />
          )}
        />
        <CustomErrorMessage errors={errors} name={inputName} />
      </Form.Group>
    </Col>
  );
};
