import React from "react";
import { Table } from "rsuite";
import ClientCell from "./Cells/ClientCell";
import DateCell from "./Cells/DateCell";
import PersonCell from "./Cells/PersonCell";
import PhoneCell from "./Cells/PhoneCell";
import TextCell from "./Cells/TextCell";
import BudgetCell from "./Cells/BudgetCell"; 
import ParticipantsCell from "./Cells/ParticipantsCell";
import BusinessUnitsCell from "./Cells/BusinessUnitsCell";
import TagsCell from "./Cells/TagsCell";
import SpecialityCell from "./Cells/SpecialityCell";
import AddressCell from "./Cells/AddressCell";

type Props = {
  dataKey: string;
  rowData?: any;
  type: string;
};

const TableCellProvider = ({ rowData, dataKey, type, ...props }: Props) => {
  const rowName = `${rowData["title"] ? rowData["title"] + ". " : ""}${
    rowData["firstName"] ? rowData["firstName"] + " " : ""
  }${rowData["lastName"] ? rowData["lastName"] + " " : ""}${
    rowData["name"] || ""
  }`;

  const renderCell = (item: any, colType: string) => {
    switch (colType) {
      case "personCell":
        return <PersonCell item={item} />;
      case "clientCell":
        return <ClientCell item={item} />;
      case "phoneCell":
        return <PhoneCell item={item} rowName={rowName} />;
      case "dateCell":
        return <DateCell item={item} />;
      case "budgetCell":
        return <BudgetCell item={item} />;
      case "textCell":
        return <TextCell item={item} />;
      case "participantsCell":
        return <ParticipantsCell item={item} rowName={rowName} />;
      case "businessUnitsCell":
        return <BusinessUnitsCell item={item} />;
      case "tagsCell":
        return <TagsCell item={item} rowName={rowName} />;
      case "specialityCell":
        return <SpecialityCell item={item} rowName={rowName} />;
        case "addressCell":
          return <AddressCell streetName={item.streetName} addressNote={item.addressNote} />;
      default:
        return <TextCell item={item} />;
    }
  };
  return (
    <Table.Cell {...props}>{renderCell(rowData[dataKey], type)}</Table.Cell>
  );
};

export default TableCellProvider;
