import { RootState } from "./../../../../redux/store";
import { getBiRpData } from "./../../../../redux/Bi/biSlice";
import { useAppDispatch, useAppSelector } from "./../../../../redux/hooks";
import _ from "lodash";
import { useEffect, useMemo } from "react";
import { RpDataSource } from "../../../../redux/Bi/biSlice";

interface Props {}
interface RpOptions {
  data: RpDataSource[];
}

const prepareRpByCriteria = (data: RpDataSource[], criteria: string) => {
  const chartData = _.countBy(data, criteria);

  return {
    labels: Object.keys(chartData),
    data: Object.values(chartData),
  };
};

const prepareRpTypeByProduct = (options: RpOptions) => {
  const { data } = options;
  const groupedBy = _.mapValues(_(data).groupBy("typeRp").value(), (values) =>
    _.mapKeys(
      _(values).countBy("productId").value(),
      (value, key) =>
        data.find((el) => el.productId === parseInt(key))?.productName
    )
  );
  const xAxis = _.uniq(
    _.flattenDeep(_.map(groupedBy, (value, key) => Object.keys(value)))
  );

  const series = _.map(groupedBy, (value, key) => ({
    name: key,
    data: Object.values(value),
  }));
  return {
    xAxis,
    series,
  };
};
const prepareRpTypeByUser = (options: RpOptions) => {
  const { data } = options;
  const groupedBy = _.mapValues(_(data).groupBy("typeRp").value(), (values) =>
    _.mapKeys(
      _(values).countBy("createdByUserId").value(),
      (value, key) =>
        data.find((el) => el.createdByUserId === parseInt(key))?.organisator
    )
  );
  const xAxis = _.uniq(
    _.flattenDeep(_.map(groupedBy, (value, key) => Object.keys(value)))
  );

  const series = _.map(groupedBy, (value, key) => ({
    name: key,
    data: Object.values(value),
  }));
  return {
    xAxis,
    series,
  };
};

const useBiRp = (props: Props) => {
  const dispatch = useAppDispatch();
  const { biRpData } = useAppSelector((state: RootState) => state.bi);

  useEffect(() => {
    dispatch(getBiRpData());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const RpByType = useMemo(
    () => prepareRpByCriteria(biRpData || [], "typeRp"),
    [biRpData]
  );
  const RpTypeByProduct = useMemo(
    () =>
      prepareRpTypeByProduct({
        data: biRpData || [],
      }),
    [biRpData]
  );

  const RpTypeByUser = useMemo(
    () =>
      prepareRpTypeByUser({
        data: biRpData || [],
      }),
    [biRpData]
  );

  return {
    RpByType,
    RpTypeByProduct,
    RpTypeByUser,
  };
};

export default useBiRp;
