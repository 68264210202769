import { axiosPrivate } from "../../utils/interceptors";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import User from "../../types/User.model";
import { API_DEV } from "../../utils/apiRoute";
import jwtDecode from "jwt-decode";
import { getAccessToken } from "../../utils/currentUserToken";
import { Team } from "../../types/Team.model";
const initialUser: User = {
  userId: 0,
  firstName: "",
  lastName: "",
  email: "",
  birthDate: "",
  userName: "",
  role: 2,
  hireDate: "",
  releaseDate: "",
  registrantionNumber: "",
  gender: 0,
  photoUrl: "",
  phones: [],
  note: "",
  address: { addressId: 0, streetName: "", addressNote: "", localities: [] },
  businessUnits: [],
  planification: {
    planificationId: null,
    targetId: null,
  },
};
export interface UserState {
  fetchCurrentUserState: "loading" | "success" | "fail" | "";
  fetchByIdState: "loading" | "success" | "fail" | "";
  updateInfosRequestState: "loading" | "success" | "fail" | "";
  updatePasswordRequestState: "loading" | "success" | "fail" | "";
  updatePhotoRequestState: "loading" | "success" | "fail" | "";
  teams: Team[];
  currentUser: User;
  delegateById: User | null;
  error: any;
}

export const fetchCurrentUser = createAsyncThunk(
  "[User]_fetchCurrent_",
  async (_, { rejectWithValue }) => {
    let token = getAccessToken();
    let decodedToken: any = token && jwtDecode(token);
    try {
      let response = await axiosPrivate.get(
        `${API_DEV}/User/${decodedToken.id}`
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(JSON.parse(JSON.stringify(error)));
    }
  }
);
export const fetchDelegateById = createAsyncThunk(
  "[User]_fetchById_",
  async (payload: { id: number; signal: any }, { rejectWithValue }) => {
    try {
      let response = await axiosPrivate.get(`${API_DEV}/User/${payload.id}`, {
        signal: payload.signal,
      });

      return response.data;
    } catch (error) {
      return rejectWithValue(JSON.parse(JSON.stringify(error)));
    }
  }
);

export const updateCurrentUserInfo = createAsyncThunk(
  "[User]_updateInfo_",
  async (payload: any, { rejectWithValue }) => {
    try {
      let response = await axiosPrivate.put(
        `${API_DEV}/User?withResult=true`,
        payload.newUser,
        { headers: { Password: payload.password } }
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(JSON.parse(JSON.stringify(error)));
    }
  }
);

export const updateCurrentUserPassword = createAsyncThunk(
  "[User]_updatePassword_",
  async (
    payload: {
      actualPassword: string;
      newPassword: string;
    },
    { rejectWithValue }
  ) => {
    try {
      let response = await axiosPrivate.put(
        `${API_DEV}/User/Password`,
        payload
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(JSON.parse(JSON.stringify(error)));
    }
  }
);

export const updateCurrentUserPhoto = createAsyncThunk(
  "[User]_updatePhoto_",
  async (file: any, { rejectWithValue }) => {
    try {
      let headers = {
        "Content-Type": "multipart/form-data",
      };
      let response = await axiosPrivate.put(`${API_DEV}/User/Photo`, file, {
        headers,
      });
      return response.data;
    } catch (err:any) {
      return rejectWithValue(err.response.data);
    }
  }
);

const initialState: UserState = {
  fetchCurrentUserState: "",
  fetchByIdState: "",
  updateInfosRequestState: "",
  updatePasswordRequestState: "",
  updatePhotoRequestState: "",
  currentUser: initialUser,
  delegateById: initialUser,
  teams: [],
  error: "",
};

export const userSlice = createSlice({
  name: "[User]",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchCurrentUser.pending, (state, { payload }) => {
      state.fetchCurrentUserState = "loading";
      state.fetchByIdState = "";
      state.updateInfosRequestState = "";
      state.updatePasswordRequestState = "";
      state.updatePhotoRequestState = "";
      state.error = "";
    });
    builder.addCase(fetchCurrentUser.fulfilled, (state, { payload }) => {
      state.fetchCurrentUserState = "success";
      state.fetchByIdState = "";
      state.currentUser = payload;
      state.updateInfosRequestState = "";
      state.updatePasswordRequestState = "";
      state.updatePhotoRequestState = "";
      state.error = "";
    });
    builder.addCase(fetchCurrentUser.rejected, (state, { payload }) => {
      state.fetchCurrentUserState = "fail";
      state.fetchByIdState = "";
      state.updateInfosRequestState = "";
      state.updatePasswordRequestState = "";
      state.updatePhotoRequestState = "";
      state.error = payload;
    });
    builder.addCase(fetchDelegateById.pending, (state, { payload }) => {
      state.fetchByIdState = "loading";
      state.delegateById = null;
      state.updateInfosRequestState = "";
      state.updatePasswordRequestState = "";
      state.updatePhotoRequestState = "";
      state.error = "";
    });
    builder.addCase(fetchDelegateById.fulfilled, (state, { payload }) => {
      state.fetchByIdState = "success";
      state.delegateById = payload;
      state.updateInfosRequestState = "";
      state.updatePasswordRequestState = "";
      state.updatePhotoRequestState = "";
      state.error = "";
    });
    builder.addCase(fetchDelegateById.rejected, (state, { payload }) => {
      state.fetchByIdState = "fail";
      state.updateInfosRequestState = "";
      state.updatePasswordRequestState = "";
      state.updatePhotoRequestState = "";
      state.error = payload;
    });
    builder.addCase(updateCurrentUserInfo.pending, (state, { payload }) => {
      state.fetchByIdState = "";
      state.updateInfosRequestState = "loading";
      state.updatePasswordRequestState = "";
      state.updatePhotoRequestState = "";
      state.error = "";
    });
    builder.addCase(updateCurrentUserInfo.fulfilled, (state, { payload }) => {
      state.fetchByIdState = "";
      state.currentUser = payload;
      state.updateInfosRequestState = "success";
      state.updatePasswordRequestState = "";
      state.updatePhotoRequestState = "";
      state.error = "";
    });
    builder.addCase(updateCurrentUserInfo.rejected, (state, { payload }) => {
      state.fetchByIdState = "";
      state.updateInfosRequestState = "fail";
      state.updatePasswordRequestState = "";
      state.updatePhotoRequestState = "";
      state.error = payload;
    });
    builder.addCase(updateCurrentUserPhoto.pending, (state, { payload }) => {
      state.fetchByIdState = "";
      state.updateInfosRequestState = "";
      state.updatePasswordRequestState = "";
      state.updatePhotoRequestState = "loading";
      state.error = "";
    });
    builder.addCase(updateCurrentUserPhoto.fulfilled, (state, { payload }) => {
      state.fetchByIdState = "";
      state.updateInfosRequestState = "";
      state.updatePasswordRequestState = "";
      state.updatePhotoRequestState = "success";
      state.error = "";
    });
    builder.addCase(updateCurrentUserPhoto.rejected, (state, { payload }) => {
      state.fetchByIdState = "";
      state.updateInfosRequestState = "";
      state.updatePasswordRequestState = "";
      state.updatePhotoRequestState = "fail";
      state.error = payload;
      
    });
    builder.addCase(updateCurrentUserPassword.pending, (state, { payload }) => {
      state.fetchByIdState = "";
      state.updateInfosRequestState = "";
      state.updatePasswordRequestState = "loading";
      state.updatePhotoRequestState = "";
      state.error = "";
    });
    builder.addCase(
      updateCurrentUserPassword.fulfilled,
      (state, { payload }) => {
        state.fetchByIdState = "";
        state.updateInfosRequestState = "";
        state.updatePasswordRequestState = "success";
        state.updatePhotoRequestState = "";
        state.error = "";
      }
    );
    builder.addCase(
      updateCurrentUserPassword.rejected,
      (state, { payload }) => {
        state.fetchByIdState = "";
        state.updateInfosRequestState = "";
        state.updatePasswordRequestState = "fail";
        state.updatePhotoRequestState = "";
        state.error = payload;
      }
    );
  },
});

export default userSlice.reducer;
