import React, { useEffect, useState } from "react";

import "./Sidebar.scss";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import { toggleSidebar } from "../../../redux/UI/uiSlice";
import CloseOutlineIcon from "@rsuite/icons/CloseOutline";
import ArrowRightLineIcon from "@rsuite/icons/ArrowRightLine";
import { RouteConfig, useRoutingConfig } from "../../../utils/useRoutingConfig";
import { Link, useLocation } from "react-router-dom";
import { useHandleResize } from "../../../utils/useHandleResize";
import Icon from "@mdi/react";
import { mdiMenu } from "@mdi/js";
export interface SidebarProps {}

const Sidebar = (props: SidebarProps) => {
  const dispatch = useAppDispatch();
  const sidebarIsOpen = useAppSelector((state) => state.UI.sidebarIsOpen);
  const routingConfig = useRoutingConfig();
  const [dropdownKey, setdropdownKey] = useState<string[]>([]);
  const deviceSize = useHandleResize();
  const { pathname } = useLocation();
  const [activeLink, setActiveLink] = useState("");
  useEffect(() => {
    setdropdownKey([]);
  }, [sidebarIsOpen]);
  useEffect(() => {
    const active = routingConfig.find((route) => route.path === pathname);
    active && setActiveLink(active.key);
  }, [pathname, routingConfig]);
  return (
    <div className={`sidebar__wrapper ${sidebarIsOpen ? "active" : ""}`}>
      <div className="sidebar">
        <div className="sidebar__top" onClick={() => dispatch(toggleSidebar())}>
          <div className="sidebar__top--left">
            <div className="menu-icon">
              <Icon path={mdiMenu} size={1} />
            </div>

            <h4 className="logo">GALDERMA</h4>
          </div>
          <div className="sidebar__top--right">
            <div className="close-button">
              <CloseOutlineIcon />
            </div>
          </div>
        </div>

        <ul className="sidebar__list">
          {routingConfig
            .filter((route: RouteConfig) => route.isSidebar === true)
            .map((route: RouteConfig) =>
              route.children ? (
                <li key={route.key} className="sidebar__list--item">
                  <div className="sidebar__list--link">
                    <Link
                      to={route.path}
                      onClick={() =>
                        ["Mobile", "Tablet"].includes(deviceSize) &&
                        dispatch(toggleSidebar())
                      }
                      className={`${
                        activeLink === route.key ? "link-is-active" : ""
                      }`}
                    >
                      <div className="icon">{route.icon}</div>
                      <div className="title">{route.title}</div>
                    </Link>
                    {route.children.length > 0 && (
                      <div
                        className="dropdown-trigger"
                        onClick={() => {
                          if (!dropdownKey.includes(route.key)) {
                            setdropdownKey([...dropdownKey, route.key]);
                          } else {
                            let newDropdownKeys = dropdownKey.filter(
                              (e) => e !== route.key
                            );
                            setdropdownKey(newDropdownKeys);
                          }
                        }}
                      >
                        <div
                          className={`dropdown-icon ${
                            dropdownKey.includes(route.key) ? "isOpen" : ""
                          }`}
                        >
                          <ArrowRightLineIcon />
                        </div>
                      </div>
                    )}
                  </div>
                  {route.children && (
                    <ul
                      className={`sidebar__dropdown ${
                        dropdownKey.includes(route.key) ? "isOpen" : ""
                      }`}
                    >
                      {route.children.map((child: any, i: number) => (
                        <li key={i} className="sidebar__dropdown--item">
                          <Link
                            to={
                              [pathname, "/"].includes(route.path)
                                ? child.path
                                : `${route.path}/${child.path}`
                            }
                            onClick={() =>
                              ["Mobile", "Tablet"].includes(deviceSize) &&
                              dispatch(toggleSidebar())
                            }
                            className={`${
                              activeLink === child.key ? "link-is-active" : ""
                            }`}
                          >
                            {child.title}
                          </Link>
                        </li>
                      ))}
                    </ul>
                  )}
                </li>
              ) : (
                <li key={route.key} className="sidebar__list--item">
                  <div className="sidebar__list--link">
                    <Link
                      to={route.path}
                      onClick={() =>
                        ["Mobile", "Tablet"].includes(deviceSize) &&
                        dispatch(toggleSidebar())
                      }
                      className={`${
                        activeLink === route.key ? "link-is-active" : ""
                      }`}
                    >
                      <div className="icon">{route.icon}</div>
                      <div className="title">{route.title}</div>
                    </Link>
                  </div>
                </li>
              )
            )}
        </ul>
      </div>
      <div className="pattern"></div>
    </div>
  );
};

export default Sidebar;
