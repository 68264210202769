import React, { useEffect, useState } from "react";
import { Loader } from "rsuite";
import { Target } from "../../../types/Target.model";
import { TargetWeek } from "../../../types/TargetWeek.model";
import { TargetWeekCard } from "../../02-molecules/target-week-card/TargetWeekCard";
import "./TargetWeeksSidebar.scss";
type Props = {
  activeUpdatedState: Target | null;
  activeTargetWeek: TargetWeek | null;
  setActiveTargetWeek: React.Dispatch<React.SetStateAction<TargetWeek | null>>;
  handleCopy: (innerWeekSelection: number[]) => void;
  handleCut: (
    activeTargetWeek: TargetWeek,
    innerWeekSelection: number[]
  ) => void;
  handlePaste: (activeTargetWeek: TargetWeek) => void;
  handleReset: (activeTargetWeek: TargetWeek) => void;
};
export const TargetWeeksSidebar = ({
  activeTargetWeek,
  activeUpdatedState,
  setActiveTargetWeek,
  handleCopy,
  handleCut,
  handlePaste,
  handleReset,
}: Props) => {
  const [weekNumber, setWeekNumber] = useState<number>(0);

  useEffect(() => {
    activeTargetWeek && setWeekNumber(activeTargetWeek.weekNumber);
    activeUpdatedState &&
      setActiveTargetWeek(activeUpdatedState.targetWeeks[weekNumber - 1]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeUpdatedState, weekNumber]);
  return (
    <div className="week-sidebar__wrapper">
      {activeUpdatedState && activeUpdatedState?.targetWeeks.length > 0 ? (
        activeUpdatedState?.targetWeeks.map((targetWeek) => (
          <TargetWeekCard
            handleCopy={handleCopy}
            handleCut={handleCut}
            handlePaste={handlePaste}
            handleReset={handleReset}
            active={activeTargetWeek?.targetWeekId === targetWeek.targetWeekId}
            activeTargetWeek={targetWeek}
            key={targetWeek.targetWeekId}
            onClick={() => {
              setActiveTargetWeek(targetWeek);
              setWeekNumber(targetWeek.weekNumber);
            }}
          />
        ))
      ) : (
        <Loader />
      )}
    </div>
  );
};
