import React from 'react';
import "./Tag.scss"
type Props = {
    tagName: string;
}

export default function Tag({tagName}: Props) {
  return (
    <div className={`tag-style`} >#{tagName}</div>
  )
}
