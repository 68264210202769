import { createSlice } from "@reduxjs/toolkit";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { orderService } from "../../services/order";
import { Delivery } from "../../types/Delivery.modal";
import { Order } from "../../types/Order.model";
import { OrderTracking } from "../../types/OrderTracking.model";
import { Pagination } from "../../types/Pagination.model";
import { Payment } from "../../types/Payment.model";

export type FilteredOrders = {
  orderResponseList: Order[];
  pagination: Pagination
};

export type FiltredOrderTrackingList = {
  orderResponseList: OrderTracking[];
  pagination: Pagination
}

export type AnalyticsOrderFilters = {
  wordSearch: string | null,
  statusDelivry: string[];
  products: number[];
  startDate: string | null;
  endDate: string | null;
  pagination: {
    pageNumber: number;
    pageSize: number;
  };
}
export interface OrderState {
  requestState: "idle" | "loading";
  listIsLoaded: "idle" | "loading";
  orderTrackingListIsLoaded: "idle" | "loading";
  orderTrackingList : FiltredOrderTrackingList;
  orderTrackingListExport : FiltredOrderTrackingList;
  orderList: FilteredOrders;
  orderListForExport: FilteredOrders;
  errors: any;
  paymentDetails: Payment | null;
  orderDetails: Order | null;
  deliveryDetails: Delivery[] | null;
}
const communObject = {
  orderResponseList: [],
  pagination: {
    pageNumber: 1,
    pageSize: 10,
    totalCount: 0,
    totalPages: 0,
  },
}

export const getFiltredOrders = createAsyncThunk(
  "[Order]_getOrdersList_",
  async (payload: any, { rejectWithValue }) => {
    try {
      const response = await orderService.getOrders(payload);
      return response;
    } catch (err: any) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const getOrderListForExport = createAsyncThunk(
  "[Order]_getOrderListForExport_",
  async (
    payload: { pageSize: number; startDate: Date | null; endDate: Date | null },
    { rejectWithValue }
  ) => {
    try {
      const response = await orderService.getOrders({
        wordSearch: "",
        statusDelivry: [],
        statusPaymenet: [],
        startDate: payload.startDate,
        endDate: payload.endDate,
        pagination: {
          pageNumber: 1,
          pageSize: payload.pageSize,
        },
      });
      return response;
    } catch (err: any) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const getOrderTrackingListForExport = createAsyncThunk(
  "[Order]_getOrderTrackingListForExport_",
  async (
    payload: { pageSize: number; startDate: Date | null; endDate: Date | null; products: number[] },
    { rejectWithValue }
  ) => {
    try {
      const response = await orderService.getOrderTracking({
        wordSearch: "",
        products: payload.products,
        startDate: payload.startDate,
        endDate: payload.endDate,
        pagination: {
          pageNumber: 1,
          pageSize: payload.pageSize,
        },
      });
      return response;
    } catch (err: any) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const getOrderDetails = createAsyncThunk(
  "[Order]_getOrderDetails_",
  async (id: number, { rejectWithValue }) => {
    try {
      const response = await orderService.getOrderDetails(id);
      return response;
    } catch (err: any) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const getPaymentrDetails = createAsyncThunk(
  "[Order]_getPaymentDetails_",
  async (id: number, { rejectWithValue }) => {
    try {
      const response = await orderService.getPaymentDetails(id);
      return response;
    } catch (err: any) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const getDeliveryDetails = createAsyncThunk(
  "[Order]_getDeliveryDetails_",
  async (id: number, { rejectWithValue }) => {
    try {
      const response = await orderService.getDeliveryDetails(id);
      return response;
    } catch (err: any) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const getOrderTracking = createAsyncThunk(
  "[Order]_getOrderTrackingList_",
  async (
    payload: AnalyticsOrderFilters,
    { rejectWithValue }
  ) => {
    try {
      const response = await orderService.getOrderTracking(payload);
      return response;
    } catch (err: any) {
      return rejectWithValue(err.response.data);
    }
  }
);

const initialState: OrderState = {
  requestState: "idle",
  listIsLoaded: "idle",
  orderTrackingListIsLoaded: "idle",
  orderTrackingList: communObject,
  orderList: communObject,
  orderListForExport: communObject,
  orderTrackingListExport :communObject,
  errors: null,
  orderDetails: null,
  paymentDetails: null,
  deliveryDetails: null,
};

export const orderSlice = createSlice({
  name: "[Orders]",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getFiltredOrders.pending, (state) => {
      state.requestState = "loading";
      state.listIsLoaded = "loading";
      state.errors = null;
    });
    builder.addCase(getFiltredOrders.fulfilled, (state, { payload }) => {
      state.requestState = "idle";
      state.listIsLoaded = "idle";
      state.orderList = payload;
      state.errors = null;
    });
    builder.addCase(getFiltredOrders.rejected, (state, { payload }) => {
      state.requestState = "idle";
      state.listIsLoaded = "idle";
      state.orderList = {
        orderResponseList: [],
        pagination: {
          pageNumber: 1,
          pageSize: 10,
          totalCount: 0,
          totalPages: 0,
        },
      };
      state.errors = payload;
    });
    builder.addCase(getOrderListForExport.pending, (state) => {
      state.requestState = "loading";
      state.errors = null;
    });
    builder.addCase(getOrderListForExport.fulfilled, (state, { payload }) => {
      state.requestState = "idle";
      state.orderListForExport = payload;
      state.errors = null;
    });
    builder.addCase(getOrderListForExport.rejected, (state, { payload }) => {
      state.requestState = "idle";
      state.orderListForExport = {
        orderResponseList: [],
        pagination: {
          pageNumber: 1,
          pageSize: 10,
          totalCount: 0,
          totalPages: 0,
        },
      };
      state.errors = payload;
    });
    builder.addCase(getOrderDetails.pending, (state) => {
      state.requestState = "loading";
      state.errors = null;
    });
    builder.addCase(getOrderDetails.fulfilled, (state, { payload }) => {
      state.requestState = "idle";
      state.orderDetails = payload;
      state.errors = null;
    });
    builder.addCase(getOrderDetails.rejected, (state, { payload }) => {
      state.requestState = "idle";
      state.orderDetails = null;
      state.errors = payload;
    });
    builder.addCase(getPaymentrDetails.pending, (state) => {
      state.requestState = "loading";
      state.errors = null;
    });
    builder.addCase(getPaymentrDetails.fulfilled, (state, { payload }) => {
      state.requestState = "idle";
      state.paymentDetails = payload;
      state.errors = null;
    });
    builder.addCase(getPaymentrDetails.rejected, (state, { payload }) => {
      state.requestState = "idle";
      state.paymentDetails = null;
      state.errors = payload;
    });
    builder.addCase(getDeliveryDetails.pending, (state) => {
      state.requestState = "loading";
      state.errors = null;
    });
    builder.addCase(getDeliveryDetails.fulfilled, (state, { payload }) => {
      state.requestState = "idle";
      state.deliveryDetails = payload;
      state.errors = null;
    });
    builder.addCase(getDeliveryDetails.rejected, (state, { payload }) => {
      state.requestState = "idle";
      state.deliveryDetails = null;
      state.errors = payload;
    });
    builder.addCase(getOrderTracking.pending, (state) => {
      state.requestState = "loading";
      state.orderTrackingListIsLoaded = "loading";
      state.errors = null;
    });
    builder.addCase(getOrderTracking.fulfilled, (state, { payload }) => {
      state.requestState = "idle";
      state.orderTrackingListIsLoaded = "idle";
      state.orderTrackingList = payload;
      state.errors = null;
    });
    builder.addCase(getOrderTracking.rejected, (state, { payload }) => {
      state.requestState = "idle";
      state.orderTrackingListIsLoaded = "idle";
      state.orderTrackingList = {
        orderResponseList: [],
        pagination: {
          pageNumber: 1,
          pageSize: 10,
          totalCount: 0,
          totalPages: 0,
        },
      };
      state.errors = payload;
    });
  },
});

export default orderSlice.reducer;
