import { Tooltip } from "@material-ui/core";
import _ from "lodash";
import React from "react";
import { Col } from "rsuite";
import { StandardIcon } from "../../../01-atoms/Icons/Icons";
import "./AnalyticsCard.scss";
type Props = {
  mainMetric: {
    icon: string;
    count: number;
    title: string;
    unit?: string;
  };
  secondaryMetric?: {
    icon: string;
    count: number;
    title: string;
  };
  potentials?: any;
  xs?: number;
  sm?: number;
  md?: number;
  lg?: number;
  toolTipeTitle?: string | undefined 
};

const AnalyticsCard = ({
  mainMetric,
  secondaryMetric,
  potentials,
  xs,
  sm, 
  md,
  lg,
  toolTipeTitle,
}: Props) => {
  return (
    <Col xs={xs || 24} sm={sm} md={md} lg={lg} style={{ marginBottom: "1rem" }}>
      <div className="analytics-card__wrapper">
        <div className="analytics-card__inner">
          <div className="analytics-card__main-metric">
            <Tooltip title={toolTipeTitle ? toolTipeTitle : ""} disableHoverListener={toolTipeTitle ? false: true}>
              <div>
                <StandardIcon icon={mainMetric.icon} />
              </div>
            </Tooltip>

            <div className="analytics-card__main-metric--data">
              <dl>
                <dt>
                  {mainMetric.unit
                    ? mainMetric.count + " " + mainMetric.unit
                    : mainMetric.count}
                </dt>
                <dd>{mainMetric.title}</dd>
              </dl>
            </div>
          </div>
          {secondaryMetric ? (
            <div className="analytics-card__secondary-metric">
              <div className="analytics-card__secondary-metric--icon">
                <StandardIcon icon={secondaryMetric.icon} />
              </div>
              <div className="analytics-card__secondary-metric--data">
                <dl>
                  <dt>{secondaryMetric.count}</dt>
                  <dd>{secondaryMetric.title}</dd>
                </dl>
              </div>
            </div>
          ) : null}
          {!_.isEmpty(potentials) ? (
            <div className="analytics-card__potentials">
              {potentials.map(
                (e: {
                  potentialId: number;
                  count: number;
                  potentialName: string;
                }) => (
                  <dl key={e.potentialId}>
                    <dt>{e.potentialName}</dt>
                    <dl className={`pot-${e.potentialName}`}>{e.count}</dl>
                  </dl>
                )
              )}
            </div>
          ) : null}
        </div>
      </div>
    </Col>
  );
};

export default AnalyticsCard;
