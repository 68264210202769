import { mdiChevronDown } from "@mdi/js";
import Icon from "@mdi/react";
import React, { createRef } from "react";
// import { useTranslation } from "react-i18next";
import { DOMHelper, Divider, Row } from "rsuite";
import { CustomAvatar } from "../../../01-atoms/CustomAvatar/CustomAvatar";
import { Request } from "../../../../types/Request.model";
import { getLocalDate } from "../../../../utils/dateHelpers";
import { getSatisfactionIconPath } from "../../../../utils/getSatisfactionIconPath";
type Props = {
  req: Request;
}; 

export const RequestCard = ({req}: Props) => {
  // const { t } = useTranslation();
  const expandRef: any = createRef();
  const { toggleClass } = DOMHelper;
  return (
    <>
      <div className="standard-card-info__wrapper standard-card-info__wrapper__left-border standard-card-info__wrapper__left-border--blue">
        <div className="standard-card-info__main">
          <div className="standard-card-info__content">
            <div className="standard-card-info__content--top">
              <div className="objection-user user-avatar">
              <CustomAvatar   
                    image={{
                      src: `${req?.assignedTo?.photoUrl || ""}`,
                      alt: `${req?.assignedTo?.photoUrl || ""}`,
                    }}
                    style={{ width: "24px", height: "24px" }}
                  />
               <div className="fullname">{req?.assignedTo ? `${req?.assignedTo?.firstName} ${req?.assignedTo?.lastName}` : "-"}</div>
              </div>
              <div className="standard-card-info-status">
                <div className="status-label">Priorité</div>
                <div className="status-description status-description">
                  {req?.priority?.priorityName ? req.priority.priorityName : "-" }
                </div>
              </div>
              <div className="standard-card-info-status">
                <div className="status-label">Status</div>
                <div className={`status-description ${
                    req?.isTreated ? "--green" : "--red"
                  }`}>{req?.isTreated ? "Traitée" : "Non traitée"}</div>
              </div>
            </div>
            <div className="standard-card-info__content--bottom">
           {`${req?.requestValue?.requestValueType?.requestValueTypeName} : ${req?.requestValue?.description ? req.requestValue.description : "-"}`}
            </div>
            <div className="standard-card-info__content--bottom"> 
              <div className="standard-card-info-status">
                <div className="status-label">Initiée le</div>
                <div className="status-description ">{req?.openedOn ? getLocalDate(req?.openedOn) : "-"}</div>
              </div>
              <div className="standard-card-info-status">
                <div className="status-label">Clôturé le </div>
                <div className="status-description ">{req.closedOn ? getLocalDate(req?.closedOn) : "-"}</div>
              </div>
              <div className="standard-card-info-status">
                <div className="status-label">Clôturé par</div>
                <div className=" status-description"> {req.closedBy ?`${req?.closedBy?.firstName} ${req?.closedBy?.lastName}` :  "-" }</div>
              </div>
              <div className="standard-card-info-status">
              <div className="status-label"><Icon path={getSatisfactionIconPath(req?.isSatisfied) } size={1}/></div>
              </div>
            </div>
          </div>
          <div className="visit-card__button">
            <div
              className="icon"
              onClick={() => toggleClass(expandRef.current, "isOpen")}
            >
              <Icon path={mdiChevronDown} size={0.75} />
            </div>
          </div>
        </div>
        <div className="visit-card__expand" ref={expandRef}>
          <Divider style={{ margin: "0 1.5rem" }} />
          <div className="visit-card__expand--inner">
            <Row>
              <dl className="">
                <dd>
                Réponse : {req?.response ? req.response : "-"}
                </dd>
              </dl>
            </Row>
          </div>
        </div>
      </div>
    </>
  );
};
