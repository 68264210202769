import React from "react";
import { HomeTemplate } from "../../04-templates/home-template/HomeTemplate";
import { useTranslation } from "react-i18next";
import { Header } from "../../05-layouts/Header/Header";
import { useRoutingConfig } from "../../../utils/useRoutingConfig";

interface Props {}

export const HomePage = (props: Props) => {
  const { t } = useTranslation();
  const currentRoute = useRoutingConfig().find((e) => e.key === "1");
  const subRoutes =
    typeof currentRoute !== "undefined" ? currentRoute.children : [];

  const mockHeaderLeftItem = <h4>{t("home_page.title")}</h4>;

  return (
    <>
      <Header leftItem={mockHeaderLeftItem} subRoutes={subRoutes} />

      <HomeTemplate />
    </>
  );
};
