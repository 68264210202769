import React, { createRef, useEffect } from "react";
import { Controller } from "react-hook-form";
import { Form, Col, SelectPicker, FlexboxGrid } from "rsuite";

import { Locality } from "../../../../types/Locality.model";
import { CustomErrorMessage } from "../../../02-molecules/forms/ErrorMessage/CustomErrorMessage";

import { TextField } from "../../../02-molecules/forms/TextField/TextField";
import { useAddressBlock } from "./useAddressBlock";

type Props = {
  localitiesField: string;
  streetNameField: string;
  noteAdressField: string;
  fieldIsRequired: string;
  initialLocalities?: any[] | Locality[];
  noteAddress: boolean;
  control: any;
  errors: any;
  setValue: any;
  formContext: "add" | "update";
};

const AddressBlock = ({
  localitiesField,
  streetNameField,
  noteAdressField,
  fieldIsRequired,
  initialLocalities,
  noteAddress = true,
  control,
  errors,
  setValue,
  formContext,
}: Props) => {
  const govInputRef: any = createRef();
  const delegInputRef: any = createRef();
  const postalCodeInputRef: any = createRef();
  const locInputRef: any = createRef();

  const { localities, localitiesOptions, handleSelectLocality } =
    useAddressBlock({ initialLocalities });

  useEffect(() => {
    setValue(localitiesField, localities);
  }, [localities, localitiesField, setValue]);

  return (
    <FlexboxGrid align="bottom">
      <Col xs={24} sm={24} md={6} style={{ marginBottom: "1rem" }}>
        <Form.Group>
          <Form.ControlLabel>Gouvernorat</Form.ControlLabel>
          <Controller
            control={control}
            name={`${localitiesField}.${0}`}
            rules={{ required: fieldIsRequired }}
            render={({ field: { onChange, name, value } }) => {
              return (
                <Form.Control
                  ref={govInputRef}
                  container={() => govInputRef.current}
                  style={{ width: "100%", position: "relative" }}
                  className="input-full-width"
                  menuClassName="picker-fixed-top"
                  name={name}
                  accepter={SelectPicker}
                  menuMaxHeight={200}
                  value={
                    formContext === "update"
                      ? initialLocalities &&
                        initialLocalities[0] &&
                        localitiesOptions[0] &&
                        localitiesOptions[0].length > 0
                        ? JSON.stringify(
                            localitiesOptions[0].filter(
                              (e, i) => e.localityId === value?.localityId
                            )[0]
                          )
                        : undefined
                      : `${value}`
                  }
                  data={
                    localitiesOptions[0] && localitiesOptions[0].length > 0
                      ? localitiesOptions[0].map((e) => ({
                          label: e.localityName,
                          key: e.localityId,
                          value: JSON.stringify(e),
                        }))
                      : []
                  }
                  cleanable={false}
                  onChange={(e: any) => {
                    handleSelectLocality(JSON.parse(e));
                    onChange(e);
                  }}
                />
              );
            }}
          />
          <CustomErrorMessage
            errors={errors}
            name={`${localitiesField}.${0}`}
          />
        </Form.Group>
      </Col>
      <Col xs={24} sm={24} md={6} style={{ marginBottom: "1rem" }}>
        <Form.Group>
          <Form.ControlLabel>Délégation</Form.ControlLabel>
          <Controller
            control={control}
            name={`${localitiesField}.${1}`}
            rules={{ required: fieldIsRequired }}
            render={({ field: { onChange, name, value } }) => {
              return (
                <Form.Control
                  ref={delegInputRef}
                  container={() => delegInputRef.current}
                  style={{ width: "100%", position: "relative" }}
                  className="input-full-width"
                  menuClassName="picker-fixed-top"
                  menuMaxHeight={200}
                  name={name}
                  accepter={SelectPicker}
                  value={
                    formContext === "update"
                      ? initialLocalities &&
                        initialLocalities[1] &&
                        localitiesOptions[1] &&
                        localitiesOptions[1].length > 0
                        ? JSON.stringify(
                            localitiesOptions[1].filter(
                              (e, i) => e.localityId === value?.localityId
                            )[0]
                          )
                        : undefined
                      : `${value}`
                  }
                  data={
                    localitiesOptions[1] && localitiesOptions[1].length > 0
                      ? localitiesOptions[1].map((e) => ({
                          label: e.localityName,
                          key: e.localityId,
                          value: JSON.stringify(e),
                        }))
                      : []
                  }
                  cleanable={false}
                  onChange={(e: any) => {
                    handleSelectLocality(JSON.parse(e));
                    onChange(e);
                  }}
                />
              );
            }}
          />
          <CustomErrorMessage
            errors={errors}
            name={`${localitiesField}.${1}`}
          />
        </Form.Group>
      </Col>
      <Col xs={24} sm={24} md={6} style={{ marginBottom: "1rem" }}>
        <Form.Group>
          <Form.ControlLabel>Code Postal</Form.ControlLabel>
          <Controller
            control={control}
            name={`${localitiesField}.${2}`}
            render={({ field: { onChange, name, value } }) => {
              return (
                <Form.Control
                  ref={postalCodeInputRef}
                  container={() => postalCodeInputRef.current}
                  style={{ width: "100%", position: "relative" }}
                  className="input-full-width"
                  menuClassName="picker-fixed-top"
                  menuMaxHeight={200}
                  name={name}
                  accepter={SelectPicker}
                  value={
                    formContext === "update"
                      ? initialLocalities &&
                        initialLocalities[2] &&
                        localitiesOptions[2] &&
                        localitiesOptions[2].length > 0
                        ? JSON.stringify(
                            localitiesOptions[2].filter(
                              (e, i) => e.localityId === value?.localityId
                            )[0]
                          )
                        : undefined
                      : `${value}`
                  }
                  data={
                    localitiesOptions[2] && localitiesOptions[2].length > 0
                      ? localitiesOptions[2].map((e) => ({
                          label: e.localityName,
                          key: e.localityId,
                          value: JSON.stringify(e),
                        }))
                      : []
                  }
                  cleanable={false}
                  onChange={(e: any) => {
                    handleSelectLocality(JSON.parse(e));
                    onChange(e);
                  }}
                />
              );
            }}
          />
          <CustomErrorMessage
            errors={errors}
            name={`${localitiesField}.${2}`}
          />
        </Form.Group>
      </Col>
      <Col xs={24} sm={24} md={6} style={{ marginBottom: "1rem" }}>
        <Form.Group>
          <Form.ControlLabel>Localité</Form.ControlLabel>
          <Controller
            control={control}
            name={`${localitiesField}.${3}`}
            render={({ field: { onChange, name, value } }) => {
              return (
                <Form.Control
                  ref={locInputRef}
                  container={() => locInputRef.current}
                  style={{ width: "100%", position: "relative" }}
                  className="input-full-width"
                  menuClassName="picker-fixed-top"
                  menuMaxHeight={200}
                  name={name}
                  accepter={SelectPicker}
                  value={
                    formContext === "update"
                      ? initialLocalities &&
                        initialLocalities[3] &&
                        localitiesOptions[3] &&
                        localitiesOptions[3].length > 0
                        ? JSON.stringify(
                            localitiesOptions[3].filter(
                              (e, i) => e.localityId === value?.localityId
                            )[0]
                          )
                        : undefined
                      : `${value}`
                  }
                  data={
                    localitiesOptions[3] && localitiesOptions[3].length > 0
                      ? localitiesOptions[3].map((e) => ({
                          label: e.localityName,
                          key: e.localityId,
                          value: JSON.stringify(e),
                        }))
                      : []
                  }
                  cleanable={false}
                  onChange={(e: any) => {
                    handleSelectLocality(JSON.parse(e));
                    onChange(e);
                  }}
                />
              );
            }}
          />
          <CustomErrorMessage
            errors={errors}
            name={`${localitiesField}.${3}`}
          />
        </Form.Group>
      </Col>
      <Col xs={24} sm={24} md={24} className="mt-1">
        <TextField
          label={"Adresse"}
          inputName={streetNameField}
          rules={{ required: fieldIsRequired }}
          control={control}
          errors={errors}
        />
      </Col>
      {noteAddress ? (
        <Col xs={24} sm={24} md={24} className="mt-1">
          <TextField
            label={"Complément d'adresse"}
            inputName={noteAdressField}
            control={control}
            errors={errors}
          />
        </Col>
      ) : null}
    </FlexboxGrid>
  );
};

export default AddressBlock;
