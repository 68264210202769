import { mdiTune } from "@mdi/js";
import Icon from "@mdi/react";
import React, { createRef } from "react";
import { Controller, useForm } from "react-hook-form";
import { CheckTree, DateRangePicker, Form, Popover, Whisper } from "rsuite";
import { useHandleResize } from "../../../../utils/useHandleResize";
import { CustomButton } from "../../../01-atoms/Buttons/Buttons";
import { CustomErrorMessage } from "../../../02-molecules/forms/ErrorMessage/CustomErrorMessage";
import "./ObjectionFilter.scss";
type Props = {
  captureFilter: (data: ObjectionFilterInputs) => void;
  satisfactionLevelValue: number[];
  dateValue: [Date, Date] | undefined;
};
type ObjectionFilterInputs = {
  satisfactionLevel: string[];
  date: [Date, Date] | undefined;
  isClosed: string[];
};

const satisfactionLevelData = [
  { label: "VeryDisatisfied", value: "0" },
  { label: "Disatisfied", value: "1" },
  { label: "Ok", value: "2" },
  { label: "Satisfied", value: "3" },
  { label: "VerySatisfied", value: "4" },
];

const isClosedData = [
  { label: "Clôturée", value: "true" },
  { label: "Non Clôturée", value: "false" },
];

export const ObjectionFilter = ({
  captureFilter,
  satisfactionLevelValue,
  dateValue,
}: Props) => {
  const deviceSize = useHandleResize();
  const initForm: ObjectionFilterInputs = {
    satisfactionLevel: satisfactionLevelValue?.map((el) => el.toString()),
    date: dateValue,
    isClosed: [],
  };
  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<ObjectionFilterInputs>({
    defaultValues: initForm,
    criteriaMode: "all",
    reValidateMode: "onChange",
  });

  const onSubmit = handleSubmit((data) => {
    captureFilter(data);
  });

  const datePickerRangeRef: any = createRef();
  const renderMenu = ({ onClose, left, top, className }: any, ref: any) => {
    return (
      <Popover
        ref={ref}
        className={className + " objections-filter__whisper"}
        style={{ left, top }}
        full
      >
        <Form>
          <div className="objections-filter__inner">
            <div className="priorities-area">
              <Form.Group className="priority">
                <Controller
                  control={control}
                  name="satisfactionLevel"
                  render={({ field: { onChange, name, value } }) => (
                    <Form.Control
                      name={name}
                      accepter={CheckTree}
                      data={satisfactionLevelData}
                      value={value}
                      onChange={(e: any, val: any) => {
                        onChange(e);
                        onSubmit();
                      }}
                    />
                  )}
                />
              </Form.Group>
            </div>
            <div className="priorities-area">
              <Form.Group className="isClosed__Objection">
                <Controller
                  name="isClosed"
                  control={control}
                  render={({ field: { onChange, name, value } }) => {
                    return (
                      <Form.Control
                        name={name}
                        accepter={CheckTree}
                        data={isClosedData}
                        value={value}
                        onChange={(e: any, val: any) => {
                          onChange(e);
                          onSubmit();
                        }}
                      />
                    );
                  }}
                />
              </Form.Group>
            </div>
            <div className="dates-area">
              <Form.Group>
                <Controller
                  control={control}
                  name="date"
                  render={({ field: { onChange, name, value } }) => (
                    <Form.Control
                      ref={datePickerRangeRef}
                      accepter={DateRangePicker}
                      name={name}
                      isoWeek
                      value={value}
                      format={"yyyy-LL-dd"}
                      style={{ width: "100%" }}
                      container={() => datePickerRangeRef.current}
                      placement="autoHorizontal"
                      onChange={(e: any) => {
                        onChange(e);
                        onSubmit();
                      }}
                    />
                  )}
                />
                <CustomErrorMessage errors={errors} name="date" />
              </Form.Group>
            </div>
          </div>
        </Form>
      </Popover>
    );
  };
  return (
    <div className="objections-filter__wrapper">
      <Whisper
        placement="autoVerticalEnd"
        trigger="click"
        speaker={renderMenu}
        preventOverflow
      >
        <div>
          <CustomButton
            text={["Mobile", "Tablet"].includes(deviceSize) ? "" : "Filtrer"}
            icon={<Icon path={mdiTune} size={1} />}
          />
        </div>
      </Whisper>
    </div>
  );
};
