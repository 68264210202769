import {
  makeStyles,
  Theme,
  createStyles,
  Paper,
  Box,
  Typography,
} from "@material-ui/core";
import { useState } from "react";
import { GlobalInsightsData } from "./GlobalInsights";

interface GlobalInsightsItemProps {
  data: GlobalInsightsData;
}

const GlobalInsightsItem = ({ data }: GlobalInsightsItemProps) => {
  const [isHover, setIsHover] = useState<boolean>(false);
  const { count, color, icon, text } = data;

  const useStyles = makeStyles((theme: Theme) =>
    createStyles({
      paper: {
        height: "100%",
        padding: theme.spacing(2),
        display: "flex",
        cursor: "pointer",
        alignItems: "center",
        borderLeft: `4px solid ${color}`,
      },
      typography: {
        marginLeft: theme.spacing(2),
        lineHeight: 1.4,
      },
      iconBox: {
        backgroundColor: color,
        height: "32px",
        padding: theme.spacing(1),
        width: "32px",
        borderRadius: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        color: "white",
      },
    })
  );
  const classes = useStyles();

  return (
    <Paper
      className={classes.paper}
      elevation={isHover ? 3 : 1}
      onMouseEnter={() => setIsHover(true)}
      onMouseLeave={() => setIsHover(false)}
      style={{cursor: "auto"}}
    >
      <Box className={classes.iconBox}>{icon}</Box>
      <Box className={classes.typography}>
        <Typography variant="caption" color="textPrimary">
          {count}
        </Typography>
        <Typography variant="caption" color="textPrimary">
          {" "}
          {text}
        </Typography>
      </Box>
    </Paper>
  );
};

export default GlobalInsightsItem;
