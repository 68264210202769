import React from "react";
import "./PlainMessage.scss"
interface Props {
    text : string;
}
 

// For pages with empty data

const PlainMessage = ({text}: Props) => {

    return (
        <div className="plain-message__wrapper">
            {text}
        </div>
    )
}

export default PlainMessage;