import React from "react";
import { Placeholder } from "rsuite";
import "./Placeholder.scss";
type Props = {
  rows?: number;
  withCircle?: boolean;
  style?: any;
};

export const ProfileInfoItemPlaceholder = ({
  rows,
  withCircle = false,
  style,
}: Props) => {
  return (
    <div className="profile-header-placeholder" style={style}>
      {withCircle && (
        <Placeholder.Paragraph
          active
          graph="circle"
          className="avatar-placeholder"
        />
      )}
      <Placeholder.Paragraph
        active
        rowMargin={8}
        rowHeight={8}
        rows={rows || 2}
        style={{
          width: "60%",
          marginLeft: `${withCircle ? "1rem" : "0"}`,
          marginRight: "auto",
        }}
      />
    </div>
  );
};
