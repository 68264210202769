import React, { useState } from "react";

import { Tag, Modal, List, Avatar, Whisper, Tooltip } from "rsuite";
type Props = {
  item: {
    id: number;
    name: string;
  }[];
  listName: string;
  className?: string;
  numberOfDisplayedProduct: number;
};

const ListStack = ({ item, listName, className, numberOfDisplayedProduct }: Props) => {
  const [modalIsOpen, setmodalIsOpen] = useState(false);
  const handleOpen = () => setmodalIsOpen(true);
  const handleClose = () => setmodalIsOpen(false);

  const renderModal = (items: { id: number; name: string }[]) => (
    <Modal open={modalIsOpen} onClose={handleClose}>
      <Modal.Header>
        <Modal.Title>{listName}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <List>
          {items && items.length > 0
            ? items.map((e, i) => (
                <List.Item
                  key={e.id}
                  className="phalcon-speciality-cell-list-item"
                >
                  <div className="speciality-item">{e.name || "-"}</div>
                </List.Item>
              ))
            : null}
        </List>
      </Modal.Body>
    </Modal>
  );

  return (
    <div className="phalcon-speciality-cell">
      {item.slice(0, numberOfDisplayedProduct).map((e) => (
        <Whisper
          key={e.id}
          trigger="hover"
          placement="top"
          speaker={<Tooltip>{e.name}</Tooltip>}
        >
          <Tag
            size="lg"
            style={{
              userSelect: "none",
              whiteSpace: "nowrap",
              maxWidth: "220px",
              overflow: "hidden",
            }} 
          >
            {e?.name.length < 21
              ? e.name
              : e?.name.length > 20
              ? e.name.slice(0, 15) + " ..."
              : ""}
          </Tag>
        </Whisper>
      ))}
      {item && item.length > numberOfDisplayedProduct ? (
        <div className="extra-specialities" onClick={handleOpen}>
          <Avatar size="xs" className="extra-specialities--count" circle>
            +{item.length - numberOfDisplayedProduct}
          </Avatar>
        </div>
      ) : null}
      {renderModal(item)}
    </div>
  );
};

export default ListStack;
