import React from "react";
import { useAppSelector } from "../../../redux/hooks";
import { RootState } from "../../../redux/store";

import { PlanificationWeek } from "../../../types/PlanificationWeek.model";
import "./PlanificationWeekCard.scss";
type Props = {
  active?: boolean;
  activePlanificationWeek: PlanificationWeek;
  onClick: any;
};

export const PlanificationWeekCard = ({
  active,
  activePlanificationWeek,
  onClick,
}: Props) => {
  const { potentials } = useAppSelector((state: RootState) => state.general);

  return (
    <div className="planificationWeek-card__wrapper" onClick={onClick}>
      <div className={`planificationWeek-card ${active ? "isActive" : ""}`}>
        <div className="planificationWeek-card__top">
          <div className="planificationWeek-number">
            S{activePlanificationWeek.weekNumberPlanification}
          </div>
          <div className="planificationWeek-number">
            S{activePlanificationWeek.weekNumberInYear} de l'année
          </div>
        </div>
        <div className="planificationWeek-card__bottom">
          <div className="planificationWeek-frequencies-count">
            {potentials.map((e) => (
              <dl key={e.potentialId}>
                <dt>
                  {
                    activePlanificationWeek.weekContent.filter(
                      (item) =>
                        item.doctor.potential.potentialId === e.potentialId
                    ).length
                  }
                </dt>
                <dd>Pot. {e.potentialName}</dd>
              </dl>
            ))}
          </div>
          <div className="planificationWeek-total-count">
            <dl>
              <dt>{activePlanificationWeek.weekContent.length}</dt>
              <dd>Total</dd>
            </dl>
          </div>
        </div>
      </div>
    </div>
  );
};
