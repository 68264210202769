import { Tooltip } from "@material-ui/core";
import {
  mdiDoctor,
  // mdiTruckCargoContainer,
  mdiMortarPestlePlus,
} from "@mdi/js";
import Icon from "@mdi/react";
import React from "react";
import { Link } from "react-router-dom";
import "./PhalconCell.scss";
type Props = {
  item: any;
};

type ClientData = {
  doctorId?: string;
  // type: string;
  firstName?: string;
  sellerId?: string;
  sellerName?: string;
  lastName?: string;
};

const handleRenderClientComponent = (
  fullName: string,
  clientId: string | number,
  icon: string,
  context: "seller" | "doctor"
) => {
  return (
    <Link to={`/${context}/${clientId}`} className="phalcon-client-cell">
      <div className="icon">
        <Icon path={icon} size={1} />
      </div>
      {fullName.length > 20 ? (
        <Tooltip title={fullName}>
          <span>{fullName}</span>
        </Tooltip>
      ) : (
        <span>{fullName}</span>
      )}
    </Link>
  );
};

const ClientCell = ({ item }: Props) => {
  const client: ClientData = JSON.parse(item);
  const { firstName, lastName, doctorId, sellerId, sellerName } = client;

  if (firstName && lastName && doctorId) {
    const fullName = firstName + " " + lastName;
    return (
      <>
        {handleRenderClientComponent(fullName, doctorId, mdiDoctor, "doctor")}
      </>
    );
  } else if (sellerId && sellerName) {
    return (
      <>
        {handleRenderClientComponent(
          sellerName,
          sellerId,
          mdiMortarPestlePlus,
          "seller"
        )}
      </>
    );
  } else {
    return <span>-</span>;
  }
};

export default ClientCell;
