import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Message } from "rsuite";
import { verifyToken } from "../../../../redux/Authentication/authenticationSlice";
import { useAppDispatch, useAppSelector } from "../../../../redux/hooks";
import { RootState } from "../../../../redux/store";
import { useAuthErrors } from "../../../../utils/useAuthErrors";

interface Props {}

export const TokenVerification = (props: Props) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { token } = useParams();
  const { verifyTokenState, error } = useAppSelector(
    (state: RootState) => state.auth
  );
  useEffect(() => {
    token && dispatch(verifyToken(token));
  }, [dispatch, token]);
  useEffect(() => {
    verifyTokenState === "success" &&
      setTimeout(() => {
        navigate("/reset-password");
      }, 2000);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [verifyTokenState]);

  //Errors handling
  let errorMessage = useAuthErrors({
    page: "token_verification_page",
    status: error.status,
  });
  return (
    <>
      {verifyTokenState === "loading" && (
        <Message showIcon type="success">
          {t("token_verification_page.verification")}
        </Message>
      )}
      {verifyTokenState === "fail" && (
        <>
          <Message showIcon type="error">
            {errorMessage}
            <br />
            <Link to={"/forgot-password"} style={{ color: "#1e70bf" }}>
              {t("token_verification_page.try_again")}
            </Link>
          </Message>
        </>
      )}
      {verifyTokenState === "success" && (
        <>
          <Message
            showIcon
            type="success"
            header={t("token_verification_page.success_header")}
          >
            {t("token_verification_page.success_body")}
          </Message>
        </>
      )}
    </>
  );
};
