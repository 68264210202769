import * as React from "react";
import {
  DataGrid,
  GridCellParams,
  GridColDef,
  GridSortModel,
} from "@material-ui/data-grid";
import { RequestTableData } from "../../../04-templates/profile/requests-list/RequestsList";
import ClientCell from "../common/Cells/ClientCell";
import PersonCell from "../common/Cells/PersonCell";
import DateCell from "../common/Cells/DateCell";
import { IconButton } from "rsuite";
import Icon from "@mdi/react";
import { mdiEye } from "@mdi/js";
import { RequestExpandRow } from "./RequestExpandRow";
import "./RequestsTable.scss";
import TextCell from "../common/Cells/TextCell";
import localizedTextsMap from "../../../../utils/dataGridColumnMenuLanguage";

type RequestsListContext = "doctor" | "pharmacy" | "seller" | "user" | "global";
type Props = {
  requests: RequestTableData[];
  context: RequestsListContext;
  loading: boolean;
  sortColumn?: string;
  sortType: any;
  onSortColumn: (column: any, type: any) => void;
  totalCount: number;
  pageSize: number;
  currentPageNumber: number;
  captureLimitTarget: (page: number) => void;
  capturePageTarget: (page: number) => void;
};

type RequestResponse = {
  id: number;
  requestValue: string;
  response: string;
  isClosed: boolean;
};
const getPriorityLevel = (el: number) => {
  switch (el) {
    case 3:
      return <div className="low_priotity"> Faible </div>;
    case 2:
      return <div className="medium_priotity"> Moyenne </div>;
    case 1:
      return <div className="high_priotity"> Haute </div>;

    default:
      return "-";
  }
};

export function RequestsTable({
  requests,
  sortType,
  sortColumn,
  context,
  loading,
  totalCount,
  onSortColumn,
  pageSize,
  currentPageNumber,
  captureLimitTarget,
  capturePageTarget,
}: Props) {
  const [openRequestResponeModal, setOpenRequestResponseModal] =
    React.useState<boolean>(false);
  const [rowPramas, setRowParams] = React.useState<RequestResponse>({
    id: 0,
    requestValue: "",
    response: "",
    isClosed: false,
  });
  const [sortModel, setSortModel] = React.useState<GridSortModel>([
    {
      field: "openedOn",
      sort: "desc",
    },
  ]);
  const handleClickOpen = () => {
    setOpenRequestResponseModal(true);
  };

  const handleClose = () => {
    setOpenRequestResponseModal(false);
  };

  const columns: GridColDef[] = [
    { field: "id", headerName: "ID", width: 70, hide: true },
    {
      field: "customer",
      headerName: "Client",
      sortable: false,
      filterable: false,
      width: 320,
      renderCell: (params: GridCellParams) => {
        return <ClientCell item={params.row.customer} />;
      },
    },
    {
      field: "requesType",
      headerName: "Demande",
      sortable: false,
      filterable: false,
      width: 170,
      renderCell: (params: GridCellParams) => {
        return <TextCell item={params.row.requestType} />;
      },
    },
    {
      field: "priority",
      headerName: "Priorité",
      filterable: false,
      width: 170,
      renderCell: (params: GridCellParams) => {
        return getPriorityLevel(params.row.priority);
      },
    },
    {
      field: "assignedTo",
      headerName: "Assignée à",
      sortable: false,
      filterable: false,
      width: 270,
      renderCell: (params: GridCellParams) => {
        return <PersonCell item={params.row.assignedTo} />;
      },
    },
    // { field: 'createdBy', headerName: 'Initiée par', width: 130},

    {
      field: "openedOn",
      headerName: "Initiée le",
      filterable: false,
      width: 170,
      hide: false,
      renderCell: (params: GridCellParams) => {
        return <DateCell item={params.row.openedOn} />;
      },
    },
    {
      field: "isTreated",
      headerName: "Traitée",
      width: 130,
      sortable: false,
      filterable: false,
      renderCell: (params: GridCellParams) => {
        return (
          <div className="isClosed-cell">
            {params.row.isTreated ? "Oui" : "Non"}
          </div>
        );
      },
    },
    {
      field: "treatedBy",
      headerName: "Traitée par",
      sortable: false,
      filterable: false,
      hide: true,
      width: 270,
      renderCell: (params: GridCellParams) => {
        return <PersonCell item={params.row.treatedBy} />;
      },
    },
    {
      field: "closedBy",
      headerName: "Clôturée par",
      sortable: false,
      filterable: false,
      hide: false,
      width: 270,
      renderCell: (params: GridCellParams) => {
        return <PersonCell item={params.row.closedBy} />;
      },
    },
    {
      field: "closedOn",
      headerName: "Clôturée le",
      width: 170,
      filterable: false,
      hide: false,
      renderCell: (params: GridCellParams) => {
        return <DateCell item={params.row.closedOn} />;
      },
    },
    {
      field: "isClosed",
      headerName: "Etat",
      filterable: false,
      width: 130,
      renderCell: (params: GridCellParams) => {
        return (
          <div className="isClosed-cell">
            {params.row.isClosed ? "Cloturée" : "Non cloturée"}
          </div>
        );
      },
    },
    {
      field: "isSatisfied",
      headerName: "Status",
      filterable: false,
      width: 130,
      renderCell: (params: GridCellParams) => {
        return (
          <div className="isClosed-cell">
            {params.row.isSatisfied ? "Satisfaite" : "Non satisfaite"}
          </div>
        );
      },
    },
    {
      field: "request",
      headerName: "Reponse",
      width: 100,
      sortable: false,
      disableColumnMenu: true,
      renderCell: (params: GridCellParams) => {
        return (
          <IconButton
            appearance="subtle"
            style={{ color: "#61affe" }}
            className="action-cell"
            icon={<Icon path={mdiEye} size={1} />}
            onClick={() => {
              setRowParams({
                id: params.row.id,
                requestValue: params.row.requestValue,
                response: params.row.response,
                isClosed: params.row.isClosed,
              });
              setOpenRequestResponseModal(true);
            }}
          />
        );
      },
    },
  ];

  return (
    <div className="objection_table_dimension">
      <DataGrid
        rows={requests}
        columns={columns}
        pageSize={pageSize}
        rowsPerPageOptions={[10, 50, 100]}
        checkboxSelection={false}
        rowCount={totalCount}
        pagination
        loading={loading}
        paginationMode="server"
        sortingMode="server"
        page={currentPageNumber - 1}
        onPageChange={capturePageTarget}
        onPageSizeChange={captureLimitTarget}
        onSortModelChange={(model: GridSortModel) => {
          //to improve
          if (JSON.stringify(model) !== JSON.stringify(sortModel)) {
            let column: any = model[0]?.field;
            let type: any = model[0]?.sort;
            setSortModel(model);
            onSortColumn(column, type);
          }
        }}
        localeText={localizedTextsMap}
      />
      <RequestExpandRow
        open={openRequestResponeModal}
        handleClickOpen={handleClickOpen}
        handleClose={handleClose}
        rowPramas={rowPramas}
      />
    </div>
  );
}
