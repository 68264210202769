import approvalSlice from "./Approval/approvalSlice";
import trackingSlice from "./Tracking/trackingSlice";
import visitSlice from "./Visit/visitSlice";
import commonslice from "./Common/commonslice";
import localitiesSlice from "./Locality/localitySlice";
import generalDataSlice from "./General/generalDataSlice";
import { setupInterceptor } from "./../utils/interceptors";
import { configureStore, ThunkAction, Action } from "@reduxjs/toolkit";
import authenticationSlice from "./Authentication/authenticationSlice";
import uiSlice from "./UI/uiSlice";
import { successMiddleware } from "./middlewares/successMiddleware";
import { errorMiddleware } from "./middlewares/errorMiddleware";
import userSlice from "./User/userSlice";
import pharmacySlice from "./Pharmacy/pharmacySlice";
import facilitySlice from "./Facility/facilitySlice";
import sellerSlice from "./Seller/sellerSlice";
import doctorSlice from "./Doctor/doctorSlice";
import cycleSlice from "./Cycle/cycleSlice";
import rpSlice from "./Rp/rpSlice";
import requestSlice from "./Request/requestSlice";
import objectionSlice from "./Objections/objectionSlice";
import productSlice from "./Product/productSlice";
import targetSlice from "./Target/targetSlice";
import { rpMiddleware } from "./middlewares/rpMiddleware";
import biSlice from "./Bi/biSlice";
import planificationSlice from "./Planification/planificationSlice";
import dashboardSlice from "./Dashboard/dashboardSlice";
import authorizationSlice from "./Authorization/authorizationSlice";
import notificationSlice from "./Notification/notificationSlice";
import agendaSlice from "./Agenda/agendaSlice";
import orderSlice from "./Order/orderSlice";
import salesTargetSlice from "./SalesTarget/salesTargetSlice";
import productsSlice from "./Products/Products";
import UsersSlice from "./users/users";
import FacilityTypeSlice from "./FacilityType/FacilityTypeSlice";
import SpecsSlice from "./Speciality/SpecialitySlice";
import UploadEadvSlice from "./uplaodEadv/EADV";
export const store = configureStore({
  reducer: {
    UI: uiSlice,
    EADV: UploadEadvSlice,
    auth: authenticationSlice,
    facilityType: FacilityTypeSlice,
    user: userSlice,
    specs: SpecsSlice,
    general: generalDataSlice,
    locality: localitiesSlice,
    facility: facilitySlice,
    pharmacy: pharmacySlice,
    seller: sellerSlice,
    doctor: doctorSlice,
    cycle: cycleSlice,
    rpEvent: rpSlice,
    request: requestSlice,
    objection: objectionSlice,
    product: productSlice,
    target: targetSlice,
    planification: planificationSlice,
    common: commonslice,
    prods: productsSlice,
    visit: visitSlice,
    bi: biSlice,
    dashboard: dashboardSlice,
    tracking: trackingSlice,
    authoriation: authorizationSlice,
    approval: approvalSlice,
    notification: notificationSlice,
    agenda: agendaSlice,
    order: orderSlice,
    salesTarget: salesTargetSlice,
    users: UsersSlice
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware()
      .concat(successMiddleware)
      .concat(errorMiddleware)
      .concat(rpMiddleware),
});
setupInterceptor(store);

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
