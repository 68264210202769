import { useAppDispatch } from "../../../../redux/hooks";
import moment from "moment";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { fetchFilteredRpEvents } from "../../../../redux/Rp/rpSlice";

interface UseFilterRpProps {
  context: "doctor" | "manager" | "delegate" | "global";
}

type RpFilterData = {
  type: number[];
  date: [Date, Date] | undefined;
};
export type GlobalRpFilterState = {
  search: {
    searchText: string;
    startDate: string | Date;
    endDate: string | Date;
    type: number[];
    delegateId: number | null;
    doctorId: number | null;
  };
  pagination: {
    currentPageNumber: number;
    pageSize: number;
    totalCount: number;
    totalPagesBySize: number;
  };
  orderBy: {
    value: string;
    order: string;
  };
};
export const useFilterRp = ({ context }: UseFilterRpProps) => {
  const dispatch = useAppDispatch();
  const params = useParams();

  const [globalFilterState, setGlobalFilterState] =
    useState<GlobalRpFilterState>({
      search: {
        searchText: "",
        startDate: "",
        endDate: "",
        type: [],
        delegateId:
          params.id && ["manager", "delegate"].includes(context)
            ? parseInt(params.id)
            : null,
        doctorId:
          params.id && context === "doctor" ? parseInt(params.id) : null,
      },
      pagination: {
        currentPageNumber: 1,
        pageSize: 10,
        totalCount: 0,
        totalPagesBySize: 0,
      },
      orderBy: {
        value: "",
        order: "asc",
      },
    });

  const captureSearchTextValue = (searchTerm: string) => {
    setGlobalFilterState({
      search: {
        ...globalFilterState.search,
        searchText: searchTerm,
      },
      pagination: {
        ...globalFilterState.pagination,
      },
      orderBy: {
        ...globalFilterState.orderBy,
      },
    });
  };

  const captureFilter = (data: RpFilterData) => {
    const { date, type } = data;
    setGlobalFilterState({
      search: {
        ...globalFilterState.search,
        type,
        startDate: date ? moment(date[0]).utc().toDate() : "",
        endDate: date ? moment(date[1]).utc().toDate() : "",
      },
      pagination: {
        ...globalFilterState.pagination,
      },
      orderBy: {
        ...globalFilterState.orderBy,
      },
    });
  };

  const capturePageTarget = (page: number) => {
    setGlobalFilterState({
      search: {
        ...globalFilterState.search,
      },
      pagination: {
        ...globalFilterState.pagination,
        currentPageNumber: page,
      },
      orderBy: {
        ...globalFilterState.orderBy,
      },
    });
  };

  const captureLimitTarget = (limit: number) => {
    setGlobalFilterState({
      search: {
        ...globalFilterState.search,
      },
      pagination: {
        ...globalFilterState.pagination,
        currentPageNumber: 1,
        pageSize: limit,
      },
      orderBy: {
        ...globalFilterState.orderBy,
      },
    });
  };

  const captureOrderBy = (sortColumn: string, sortType: string) => {
    setGlobalFilterState({
      search: {
        ...globalFilterState.search,
      },
      pagination: {
        ...globalFilterState.pagination,
      },
      orderBy: {
        value: sortColumn,
        order: sortType,
      },
    });
  };

  useEffect(() => {
    dispatch(fetchFilteredRpEvents(globalFilterState));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [globalFilterState]);

  return {
    globalFilterState,
    captureSearchTextValue,
    captureOrderBy,
    captureFilter,
    capturePageTarget,
    captureLimitTarget,
  };
};
