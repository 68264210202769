import { ExternalMember } from "./../../types/ExternalMember.model";
import { getAccessToken } from "./../../utils/currentUserToken";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { API_DEV } from "../../utils/apiRoute";
import { axiosPrivate } from "../../utils/interceptors";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { RpEvent } from "../../types/RpEvent.model";
// import User from "../../types/User.model";
// import { Doctor } from "../../types/Doctor.model";
import jwtDecode from "jwt-decode";

type FilteredEvents = {
  content: RpEvent[];
  pagination: {
    currentPageNumber: number;
    pageSize: number;
    totalCount: number;
    totalPagesBySize: number;
  };
};
export interface GlobalFilterStateI {
  search: {
    searchText: string;
    type: number[];
    startDate: string;
    endDate: string;
    doctorId: number | null;
    externalId: number | null;
    organizerId: number | null;
  };
  pagination: {
    currentPageNumber: number;
    pageSize: number;
  };
  orderBy: {
    value: string;
    order: string;
  };
}

export type PersonItem = {
  id: number;
  fullName: string;
  photoUrl: string;
  occupation: string;
  link?: string;
  businessUnit?: string[];
};

type Persons = {
  users: PersonItem[];
  doctors: PersonItem[];
  externals: PersonItem[];
  moderators: PersonItem[];
};
export interface RpState {
  requestState: "loading" | "idle";
  rpEventById: RpEvent | null;
  filteredRpEvents: FilteredEvents;
  globalFilterState: GlobalFilterStateI;
  addModalIsOpen: boolean;
  viewModalIsOpen: boolean;
  updateModalIsOpen: boolean;
  persons: Persons;
  errors: any;
}

export const addRpEvent = createAsyncThunk(
  "[RpEvents]_add_",
  async (payload: any, { rejectWithValue }) => {
    try {
      const response = await axiosPrivate.post(`${API_DEV}/RpEvent`, payload);
      return response;
    } catch (error) {
      console.log(JSON.parse(JSON.stringify(error)));
      return rejectWithValue(JSON.parse(JSON.stringify(error)));
    }
  }
);
export const fetchRpEventById = createAsyncThunk(
  "[RpEvents]__fetchById__",
  async (id: string, { rejectWithValue }) => {
    try {
      const response = await axiosPrivate.get(`${API_DEV}/RpEvent/${id}`);
      return response.data;
    } catch (error) {
      console.log(JSON.parse(JSON.stringify(error)));
      return rejectWithValue(JSON.parse(JSON.stringify(error)));
    }
  }
);

export const fetchFilteredRpEvents = createAsyncThunk(
  "[RpEvents]_fetchFiltered_",
  async (payload: any, { rejectWithValue }) => {
    try {
      let response = await axiosPrivate.post(
        `${API_DEV}/RpEvent/GetAllWithFilters`,
        payload
      );
      return response.data;
    } catch (error) {
      console.log(JSON.parse(JSON.stringify(error)));
      return rejectWithValue(JSON.parse(JSON.stringify(error)));
    }
  }
);

export const updateRpEvent = createAsyncThunk(
  "[RpEvents]_update_",
  async (payload: any, { rejectWithValue }) => {
    try {
      let response = await axiosPrivate.put(`${API_DEV}/RpEvent`, payload);
      return response.data;
    } catch (error) {
      console.log(JSON.parse(JSON.stringify(error)));
      return rejectWithValue(JSON.parse(JSON.stringify(error)));
    }
  }
);
export const deleteRpEvent = createAsyncThunk(
  "[RpEvents]_delete_",
  async (id: number, { rejectWithValue }) => {
    try {
      let response = await axiosPrivate.delete(`${API_DEV}/RpEvent/${id}`);
      return response.data;
    } catch (error) {
      console.log(JSON.parse(JSON.stringify(error)));
      return rejectWithValue(JSON.parse(JSON.stringify(error)));
    }
  }
);
export const deleteRpEventRange = createAsyncThunk(
  "[RpEvents]_deleteRange_",
  async (payload: any, { rejectWithValue }) => {
    try {
      let response = await axiosPrivate.delete(`${API_DEV}/RpEvent/Range`, {
        data: payload,
        headers: { "Content-Type": "application/json;charset=utf-8" },
      });
      return response.data;
    } catch (error) {
      console.log(JSON.parse(JSON.stringify(error)));
      return rejectWithValue(JSON.parse(JSON.stringify(error)));
    }
  }
);

export const getPersons = createAsyncThunk(
  "[RpEvents]_getPersons_",
  async (_, { rejectWithValue }) => {
    try {
      let externalsPayload: any = {
        search: {
          context: [0, 1, 2],
        },
      };
      let moderatorsPayload: any = {
        search: {
          context: [3],
        },
      };
      let token = getAccessToken();
      const decoded: any = token && jwtDecode(token);
      const myTeamsResponse = axiosPrivate.get(
        `${API_DEV}/User/GetUserTeams/${decoded.id}`
      );
      const otherTeamsResponse = axiosPrivate.get(
        `${API_DEV}/User/GetOtherUsers/${decoded.id}`
      );
      const doctorsResponse = axiosPrivate.get(`${API_DEV}/Doctor`);
      const externalsResponse = axiosPrivate.post(
        `${API_DEV}/External/GetAllWithFilters`,
        externalsPayload
      );
      const moderatorsResponse = axiosPrivate.post(
        `${API_DEV}/External/GetAllWithFilters`,
        moderatorsPayload
      );
      const [myTeams, otherTeams, doctors, externals, moderators] =
        await Promise.all([
          myTeamsResponse,
          otherTeamsResponse,
          doctorsResponse,
          externalsResponse,
          moderatorsResponse,
        ]);

      const payload = {
        myTeams: myTeams.data,
        otherTeams: otherTeams.data,
        doctors: doctors.data,
        externals: externals.data,
        moderators: moderators.data,
      };
      return payload;
    } catch (error) {
      console.log(JSON.parse(JSON.stringify(error)));
      return rejectWithValue(JSON.parse(JSON.stringify(error)));
    }
  }
);

export const getExternals = createAsyncThunk(
  "[RpEvents]_getExternals_",
  async (_, { rejectWithValue }) => {
    try {
      let externalsPayload: any = {
        search: {
          context: [0, 1, 2],
        },
      };
      let moderatorsPayload: any = {
        search: {
          context: [3],
        },
      };

      let externalsResponse = await axiosPrivate.post(
        `${API_DEV}/External/GetAllWithFilters`,
        externalsPayload
      );
      let moderatorsResponse = await axiosPrivate.post(
        `${API_DEV}/External/GetAllWithFilters`,
        moderatorsPayload
      );

      let externals =
        externalsResponse.data.length > 0
          ? externalsResponse.data.map((el: ExternalMember) => ({
              id: el.externalId,
              fullName: el.fullName,
              photoUrl: "",
              occupation: "",
              link: "",
            }))
          : [];
      let moderators =
        moderatorsResponse.data.length > 0
          ? moderatorsResponse.data.map((el: ExternalMember) => ({
              id: el.externalId,
              fullName: el.fullName,
              photoUrl: "",
              occupation: "",
              link: "",
            }))
          : [];

      let payload = {
        externals,
        moderators,
      };
      return payload;
    } catch (error) {
      console.log(JSON.parse(JSON.stringify(error)));
      return rejectWithValue(JSON.parse(JSON.stringify(error)));
    }
  }
);

export const addExternal = createAsyncThunk(
  "[RpEvents]__addExternal__",
  async (
    payload: {
      fullName: string;
      email: string;
      phone: string;
      context: number;
      description: string;
    },
    { rejectWithValue }
  ) => {
    try {
      let response = await axiosPrivate.post(`${API_DEV}/External`, payload);

      return response.data;
    } catch (error) {
      console.log(JSON.parse(JSON.stringify(error)));
      return rejectWithValue(JSON.parse(JSON.stringify(error)));
    }
  }
);

const initialState: RpState = {
  requestState: "idle",
  globalFilterState: {
    search: {
      searchText: "",
      type: [],
      startDate: "",
      endDate: "",
      doctorId: null,
      externalId: null,
      organizerId: null,
    },
    pagination: {
      currentPageNumber: 1,
      pageSize: 10,
    },
    orderBy: {
      value: "",
      order: "",
    },
  },
  rpEventById: null,
  addModalIsOpen: false,
  viewModalIsOpen: false,
  updateModalIsOpen: false,
  persons: {
    users: [],
    doctors: [],
    externals: [],
    moderators: [],
  },
  filteredRpEvents: {
    content: [],
    pagination: {
      currentPageNumber: 0,
      pageSize: 0,
      totalCount: 0,
      totalPagesBySize: 0,
    },
  },
  errors: null,
};

export const rpSlice = createSlice({
  name: "[RpEvents]",
  initialState,
  reducers: {
    captureSearchTextValue: (state, action: PayloadAction<string>) => {
      state.globalFilterState.search.searchText = action.payload;
    },
    captureFilterValue: (
      state,
      action: PayloadAction<{
        type: number[];
        startDate: string;
        endDate: string;
        doctorId: number | null;
        externalId: number | null;
        organizerId: number | null;
      }>
    ) => {
      console.log("filter changed");
      state.globalFilterState.search = {
        ...state.globalFilterState.search,
        ...action.payload,
      };
    },
    captureOrderBy: (
      state,
      action: PayloadAction<{ value: string; order: string }>
    ) => {
      state.globalFilterState.orderBy = action.payload;
    },
    capturePageTarget: (state, action: PayloadAction<number>) => {
      state.globalFilterState.pagination.currentPageNumber = action.payload;
    },
    captureLimitTarget: (state, action: PayloadAction<number>) => {
      state.globalFilterState.pagination.pageSize = action.payload;
    },

    /* UI Reducers */

    toggleAddRpModal: (state) => {
      state.addModalIsOpen = !state.addModalIsOpen;
    },
    toggleUpdateRpModal: (state) => {
      state.updateModalIsOpen = !state.updateModalIsOpen;
    },
    toggleViewRpModal: (state) => {
      state.viewModalIsOpen = !state.viewModalIsOpen;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(addRpEvent.pending, (state) => {
      state.requestState = "loading";
      state.errors = null;
    });
    builder.addCase(addRpEvent.fulfilled, (state) => {
      state.requestState = "idle";
      state.errors = null;
    });
    builder.addCase(addRpEvent.rejected, (state, { payload }) => {
      state.requestState = "idle";
      state.errors = payload;
    });
    builder.addCase(fetchRpEventById.pending, (state) => {
      state.requestState = "loading";
      state.errors = null;
    });
    builder.addCase(
      fetchRpEventById.fulfilled,
      (state, { payload }: { payload: any }) => {
        state.requestState = "idle";
        state.rpEventById = payload;
        state.errors = null;
      }
    );
    builder.addCase(fetchRpEventById.rejected, (state, { payload }) => {
      state.requestState = "idle";
      state.errors = payload;
    });
    builder.addCase(updateRpEvent.pending, (state) => {
      state.requestState = "loading";
      state.errors = null;
    });
    builder.addCase(updateRpEvent.fulfilled, (state) => {
      state.requestState = "idle";
      state.errors = null;
    });
    builder.addCase(updateRpEvent.rejected, (state, { payload }) => {
      state.requestState = "idle";
      state.errors = payload;
    });
    builder.addCase(deleteRpEvent.pending, (state) => {
      state.requestState = "loading";
      state.errors = null;
    });
    builder.addCase(deleteRpEvent.fulfilled, (state) => {
      state.requestState = "idle";
      state.errors = null;
    });
    builder.addCase(deleteRpEvent.rejected, (state, { payload }) => {
      state.requestState = "idle";
      state.errors = payload;
    });
    builder.addCase(deleteRpEventRange.pending, (state) => {
      state.requestState = "loading";
      state.errors = null;
    });
    builder.addCase(deleteRpEventRange.fulfilled, (state) => {
      state.requestState = "idle";
      state.errors = null;
    });
    builder.addCase(deleteRpEventRange.rejected, (state, { payload }) => {
      state.requestState = "idle";
      state.errors = payload;
    });
    builder.addCase(fetchFilteredRpEvents.pending, (state) => {
      state.requestState = "loading";
      state.errors = null;
    });
    builder.addCase(fetchFilteredRpEvents.fulfilled, (state, { payload }) => {
      state.requestState = "idle";
      state.filteredRpEvents = payload;
      state.errors = null;
    });
    builder.addCase(fetchFilteredRpEvents.rejected, (state, { payload }) => {
      state.requestState = "idle";
      state.errors = payload;
    });
    builder.addCase(getPersons.pending, (state) => {
      state.requestState = "loading";
      state.errors = null;
    });
    builder.addCase(
      getPersons.fulfilled,
      (state, { payload }: { payload: any }) => {
        state.requestState = "idle";
        state.persons = payload;
        state.errors = null;
      }
    );
    builder.addCase(getPersons.rejected, (state, { payload }) => {
      state.requestState = "idle";
      state.errors = payload;
    });
    builder.addCase(getExternals.pending, (state) => {
      state.requestState = "loading";
      state.errors = null;
    });
    builder.addCase(getExternals.fulfilled, (state, { payload }) => {
      state.requestState = "idle";
      state.persons.externals = payload.externals;
      state.persons.moderators = payload.moderators;
      state.errors = null;
    });
    builder.addCase(getExternals.rejected, (state, { payload }) => {
      state.requestState = "idle";
      state.errors = payload;
    });
    builder.addCase(addExternal.pending, (state) => {
      state.requestState = "loading";
      state.errors = null;
    });
    builder.addCase(addExternal.fulfilled, (state, { payload }) => {
      state.requestState = "idle";
      state.errors = null;
    });
    builder.addCase(addExternal.rejected, (state, { payload }) => {
      state.requestState = "idle";
      state.errors = payload;
    });
  },
});

export const {
  captureSearchTextValue,
  captureFilterValue,
  captureOrderBy,
  capturePageTarget,
  captureLimitTarget,
  toggleAddRpModal,
  toggleUpdateRpModal,
  toggleViewRpModal,
} = rpSlice.actions;

export default rpSlice.reducer;
