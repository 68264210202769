// import { mdiMagnify, mdiTune } from "@mdi/js";
// import Icon from "@mdi/react";
import { CircularProgress } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Row, Col } from "rsuite";
import { useAppDispatch, useAppSelector } from "../../../../redux/hooks";
import { RootState } from "../../../../redux/store";
import {
  fetchDoctorVisits,
  fetchUserVisits,
  fetchSellerVisits,
} from "../../../../redux/Visit/visitSlice";
import { ReportSummary } from "../../../../types/ReportSummary.model";
import Pagination from "../../../01-atoms/pagination/Pagination";
import PlainMessage from "../../../01-atoms/PlainMessage/PlainMessage";
// import { useHandleResize } from "../../../../utils/useHandleResize";
// import { CustomButton } from "../../../01-atoms/Buttons/Buttons";
import { VisitCard } from "../../../02-molecules/cards/visit-card/VisitCard";
import { useFilterReports } from "../../Reports/useReportFilters";
import "./VisitsList.scss";
interface Props {
  context: "doctor" | "pharmacy" | "seller" | "user";
}

export const VisitsList = ({ context }: Props) => {
  // const deviceSize = useHandleResize();
  const dispatch = useAppDispatch();
  const { id } = useParams();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [searchTerm, setSearchTerm] = useState("");
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [searchFieldIsLoading, setSearchFieldIsLoading] = useState(false);
  const { userVisits, doctorVisits, sellerVisites, requestStateVisit } =
    useAppSelector((state: RootState) => state.visit);

  const [data, setData] = useState<ReportSummary[]>();

  const { capturePageTarget, captureLimitTarget, globalFilterState } =
    useFilterReports();

  useEffect(() => {
    if (context === "doctor") {
      id &&
        dispatch(
          fetchDoctorVisits({
            id: parseInt(id),
            startDate: null,
            endDate: null,
            pagination: {
              pageNumber: globalFilterState?.pagination?.pageNumber || 1,
              pageSize: globalFilterState?.pagination?.pageSize || 10,
            },
          })
        );
    }
    if (context === "user") {
      id &&
        dispatch(
          fetchUserVisits({
            id: parseInt(id),
            startDate: null,
            endDate: null,
            pagination: {
              pageNumber: globalFilterState?.pagination?.pageNumber || 1,
              pageSize: globalFilterState?.pagination?.pageSize || 10,
            },
          })
        );
    }
    if (context === "seller") {
      id && dispatch(fetchSellerVisits(parseInt(id)));
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [globalFilterState]);

  useEffect(() => {
    if (context === "doctor") {
      setData(doctorVisits?.content || []);
    }
    if (context === "user") {
      setData(userVisits?.content || []);
    }
    if (context === "seller") {
      setData(sellerVisites);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userVisits, doctorVisits, sellerVisites]);
  return (
    <div className="visits-list__wrapper">
      {/* <div className="top-area">
        <InputGroup inside className="profile-search-input">
          <Input
            size="lg"
            type="text"
            name="search"
            placeholder="Recherche"
            onChange={(value) => setSearchTerm(value)}
          />
          <InputGroup.Button>
            {searchFieldIsLoading ? (
              <Loader />
            ) : (
              <Icon path={mdiMagnify} size={1} />
            )}
          </InputGroup.Button>
        </InputGroup>

        <CustomButton
          text={["Mobile", "Tablet"].includes(deviceSize) ? "" : "Filtrer"}
          icon={<Icon path={mdiTune} size={1} />}
          onClick={() => console.log("filter clicked")} 
        />
      </div> */}
      <Row className="visits-list-row">
        {data && data.length && requestStateVisit === "idle" ? (
          data.map((visit) => (
            <Col xs={24} className="visits-list-col" key={visit.reportId}>
              <VisitCard
                visit={visit}
                numberOfDisplayedProduct={window.innerWidth < 522 ? 0 : 2}
                customizedWidth={"500px"}
              />
            </Col>
          ))
        ) : data && !data.length && requestStateVisit === "idle" ? (
          <PlainMessage text={"Aucun rapport"} />
        ) : (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              height: "77vh",
              alignItems: "center",
            }}
          >
            <CircularProgress size={50} />
          </div>
        )}
      </Row>
      <div style={{ paddingBottom: "3rem" }}>
        <Pagination
          captureLimitTarget={captureLimitTarget}
          capturePageTarget={capturePageTarget}
          pageCount={
            context === "doctor"
              ? doctorVisits?.pagination?.totalCount || 0
              : userVisits?.pagination?.totalCount || 0
          }
        />
      </div>
    </div>
  );
};
