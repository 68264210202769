import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import "./AuthTemplate.scss";
interface Props {
  form?: React.ReactElement;
}

export const AuthTemplate = ({ form }: Props) => {
  const currentYear = new Date().getUTCFullYear();
  const { t } = useTranslation();
  return (
    <div className="login__wrapper">
      <div className="login__box">
        <div className="login__header">
          <Link to={"/"}>
            <img
              src="/images/Galderma-Logo-White.svg"
              width={230}
              height={46}
              alt="Galderma logo"
              className="white__logo"
            />
          </Link>
          <Link to={"/"}>
            <img
              src="/images/GALDERMA_LOGO_BLACK_RGB.svg"
              width={160}
              height={46}
              alt="Galderma logo"
              className="black__logo"
            />
          </Link>
        </div>
        {/********************************************/}
        {/*    This div will hold all of the 
                authentication forms in different 
              authentication pages                  */}
        <div className="login__form">
          {form}
          <div className="Phalcon__logo">
          <img
              src="/images/Logo-Phalcon.svg"
              width={150}
              height={46}
              alt="Phalcon CRM logo"
            />
            </div>
        </div>

        {/********************************************/}
        <div className="login__footer">
        <img
              src="/images/Logo-Phalcon.svg"
              width={150}
              height={46}
              alt="Phalcon CRM logo"
              className="footer__phalcon__logo"
            />
        <div className="h-divider"></div>
          <div className="copyright">
            Copyright © {currentYear}{" "}
            <a href="https://chambieagle.com/" target={"blanck"}>
              Chambi Eagle Technology{" "}
            </a>{" "}
            
            {t("login_page.copyright")}
          </div>
        </div>
      </div>
    </div>
  );
};
