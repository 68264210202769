import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Button, Divider, FlexboxGrid, Form, Loader } from "rsuite";
import { TextField } from "../../../02-molecules/forms/TextField/TextField";
import { useAppDispatch, useAppSelector } from "../../../../redux/hooks";
import { PhonesBlock } from "../phonesBlock/PhonesBlock";
import AddressBlock from "../addressBlock/AddressBlock";
import useUserForm, {
  fieldIsRequired,
  UserFormInputs,
  prepareUserFormPayload,
} from "./useUserForm";
import { updateCurrentUserInfo } from "../../../../redux/User/userSlice";
import { PasswordField } from "../../../02-molecules/forms/PasswordField/PasswordField";
import { RootState } from "../../../../redux/store";
import { phoneTypeOptions } from "../../../../helpers/GlobalFormHelpers";
import { SelectField } from "../../../02-molecules/forms/SelectField/SelectField";
import { LocalitySummary } from "../../../../types/Locality.model";
type Props = {};

const UserForm = (props: Props) => {
  const dispatch = useAppDispatch();
  const { formIsReady, initialValues } = useUserForm();
  const { updateInfosRequestState } = useAppSelector(
    (state: RootState) => state.user
  );
  const {
    control,
    handleSubmit,
    reset,
    setValue,
    watch,
    formState: { errors },
  } = useForm<UserFormInputs>({
    defaultValues: initialValues,
    criteriaMode: "all",
    reValidateMode: "onChange",
  });

  useEffect(() => {
    reset(initialValues);
  }, [initialValues, reset]);

  const onSubmit = handleSubmit((data) => {
    const payload = {
      newUser: prepareUserFormPayload(data),
      password: data.password,
    };

    dispatch(updateCurrentUserInfo(payload));
  });

  const [initialLocalities, setInitialLocalities] = useState<LocalitySummary[]>(
    []
  );
  useEffect(() => {
    if (!(initialLocalities && initialLocalities.length > 0)) {
      setInitialLocalities(initialValues?.address?.localities);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialValues]);

  return (
    <>
      {formIsReady ? (
        <Form>
          <div className="custom-form-section">
            <h3 className="form-block-title">Informations générales</h3>
            <Divider style={{ margin: "1rem 0" }} />
            <FlexboxGrid align="bottom">
              {/* firstName */}
              <TextField
                label={"Nom"}
                inputName="firstName"
                rules={{ required: fieldIsRequired }}
                control={control}
                errors={errors}
                xs={24}
                sm={12}
                md={12}
              />
              {/* lastName */}
              <TextField
                label={"Prénom"}
                inputName="lastName"
                rules={{ required: fieldIsRequired }}
                control={control}
                errors={errors}
                xs={24}
                sm={12}
                md={12}
              />
              {/* email */}
              <TextField
                label={"Email"}
                inputName="email"
                rules={{
                  required: fieldIsRequired,
                  validate: (value: any) =>
                    /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(value) ||
                    "Email non valide",
                }}
                control={control}
                errors={errors}
                xs={24}
                sm={24}
                md={24}
              />
              <SelectField
                label={"Type du téléphone"}
                inputName={`principalPhone.phoneType`}
                rules={{ required: fieldIsRequired }}
                control={control}
                errors={errors}
                searchable={false}
                cleanable={false}
                data={phoneTypeOptions}
                xs={24}
                sm={6}
                md={6}
              />
              <TextField
                label={"Numéro"}
                inputName={`principalPhone.phoneNumber`}
                rules={{ required: fieldIsRequired }}
                control={control}
                errors={errors}
                inputMode="tel"
                xs={24}
                sm={6}
                md={6}
              />
              <TextField
                label={"Description"}
                inputName={`principalPhone.phoneDescription`}
                control={control}
                errors={errors}
                xs={24}
                sm={12}
                md={12}
              />
              {/* businessUnits
              <TagsField
                control={control}
                errors={errors}
                inputName={`businessUnits`}
                rules={{ required: fieldIsRequired }}
                xs={24}
                sm={12}
                md={12}
                disabled={true}
                data={formOptions ? formOptions.businessUnitsOptions : []}
                cleanable={false}
                label={"Business Unit"}
              /> */}
            </FlexboxGrid>

            <h3 className="form-block-title">Adresse</h3>
            <Divider style={{ margin: "1rem 0" }} />
            {/* adress Block */}
            <AddressBlock
              localitiesField={`address.localities`}
              streetNameField={`address.streetName`}
              noteAdressField={`address.addressNote`}
              initialLocalities={initialLocalities}
              fieldIsRequired={fieldIsRequired}
              control={control}
              errors={errors}
              noteAddress={false}
              setValue={setValue}
              formContext={"update"}
            />
          </div>
          <PhonesBlock
            fieldIsRequired={fieldIsRequired}
            control={control}
            errors={errors}
            watch={watch}
            setValue={setValue}
            extra
          />
          <div className="custom-form-section">
            <FlexboxGrid align="bottom">
              <PasswordField
                label={
                  "Veuillez insérer votre mot de passe afin de confirmer les modifications"
                }
                inputName="password"
                rules={{
                  required: fieldIsRequired,
                }}
                control={control}
                errors={errors}
                passwordCheck={true}
                xs={24}
                md={12}
                lg={12}
              />
              <Button
                disabled={watch("password") === ""}
                loading={updateInfosRequestState === "loading"}
                type="submit"
                appearance="primary"
                onClick={onSubmit}
                className="submit-button"
                style={{ marginBottom: "1rem" }}
              >
                Sauvegarder
              </Button>
            </FlexboxGrid>
          </div>
        </Form>
      ) : (
        <Loader size="lg" center content="loading" />
      )}
    </>
  );
};

export default UserForm;
