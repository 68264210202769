import React, { useState, useEffect } from "react";
import { Panel, Nav } from "rsuite";
import { useAppSelector } from "../../../../redux/hooks";
import { RootState } from "../../../../redux/store";
import { Team } from "../../../../types/Team.model";
import { TeamMemberList } from "../team-members-list/TeamMemberList";
import "./TeamList.scss";
interface Props {}

export const TeamList = (props: Props) => { 
  const {teams} = useAppSelector(
    (state: RootState) => state.general
  ); 
 
  const [activeTab, setActiveTab] = useState<string | undefined>("");
  useEffect(() => {
    if (teams && teams.length > 0) {
      setActiveTab(teams[0].teamId.toString());
    }
  }, [teams]);

  return (
    <div className="team-list__wrapper">
      {teams && teams.length > 0 ? (
        <>
          <Nav appearance="subtle" className="tab-navigation">
            {teams.map((team: Team, i: number) => (
              <Nav.Item
                className="tab-item"
                eventKey={`${team.teamId}`}
                active={team.teamId.toString() === activeTab}
                key={i}
                onSelect={(eventKey) => setActiveTab(eventKey)}
              >{`Equipe ${team.teamName}`}</Nav.Item>
            ))} 
          </Nav>
          <TeamMemberList
            team={teams.find(
              (team: Team) => team.teamId.toString() === activeTab
            )}
          />
        </>
      ) : (
        <Panel>No teams</Panel>
      )}
    </div>
  );
};
