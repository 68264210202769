import React, { Suspense, useEffect } from "react";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Routes, Route } from "react-router-dom";

import "./App.scss";
import { HomePage } from "./components/06-pages/home-page/HomePage";
import { LoginPage } from "./components/06-pages/login-page/LoginPage";
import { ForgotPasswordPage } from "./components/06-pages/forgot-password-page/ForgotPasswordPage";
import { ResetPasswordPage } from "./components/06-pages/reset-password-page/ResetPasswordPage";
import { TokenVerificationPage } from "./components/06-pages/token-verification-page/TokenVerificationPage";
import { ProtectedRoute } from "./utils/ProtectedRoute";
import { Database } from "./components/06-pages/database/Database";
import { useAppDispatch, useAppSelector } from "./redux/hooks";
import { fetchGeneralData } from "./redux/General/generalDataSlice";
import { RootState } from "./redux/store";
import { CycleManagementPage } from "./components/06-pages/cycle-management-page/CycleManagementPage";
import { CycleManagementGeneral } from "./components/04-templates/cycle-management-template/general/CycleManagementGeneral";
import { CustomProvider } from "rsuite";
import { useRsuiteTranstion } from "./helpers/useRsuiteTranslation";
import moment from "moment";
import { UserPage } from "./components/06-pages/user-page/UserPage";
import { TeamList } from "./components/04-templates/profile/team-list/TeamList";
import { VisitsList } from "./components/04-templates/profile/visits-list/VisitsList";
import { UserSettings } from "./components/04-templates/profile/settings/UserSettings";
import { DoctorPage } from "./components/06-pages/doctor-page/DoctorPage";
import { RequestsList } from "./components/04-templates/profile/requests-list/RequestsList";
import { UserProfileInfo } from "./components/04-templates/profile/profile-infos/UserProfileInfo";
import { DoctorProfileInfo } from "./components/04-templates/profile/profile-infos/DoctorProfileInfo";
import { SellerPharmacyProfileInfo } from "./components/04-templates/profile/profile-infos/SellerPharmacyProfileInfo";
import { SellerPage } from "./components/06-pages/seller-page/SellerPage";
import { PharmacyPage } from "./components/06-pages/pharmacy-page/PharmacyPage";
import { ObjectionsList } from "./components/04-templates/profile/objections-list/ObjectionsList";
import { RequestsObjectionsPage } from "./components/06-pages/requests-objections-page/RequestsObjectionsPage";
import { DelegateAgenda } from "./components/04-templates/profile/delegate-agenda/DelegateAgenda";
import RpModule from "./components/06-pages/rp-page/RpModule";
import { TargetPage } from "./components/06-pages/target-page/TargetPage";
import TrackingPage from "./components/06-pages/tracking-page/TrackingPage";
import ValidationPage from "./components/06-pages/validation-page/ValidationPage";
import DoctorValidation from "./components/04-templates/validation/DoctorValidation";
import PharmacyValidation from "./components/04-templates/validation/PharmacyValidation";
import SellerValidation from "./components/04-templates/validation/SellerValidation";
import FacilityValidation from "./components/04-templates/validation/FacilityValidation";
import RpValidation from "./components/04-templates/validation/RpValidation";
import ReportsPage from "./components/06-pages/reports-page/ReportsPage";
import OrdersPage from "./components/06-pages/orders-page/OrdersPage";
import AnalyticsPage from "./components/06-pages/analytics-page/AnalyticsPage";
import AgendaPage from "./components/06-pages/agenda-page/AgendaPage";
import VrAnalytics from "./components/04-templates/analytics/VrAnalytics";
import { getGovernorates } from "./redux/Locality/localitySlice";
import DoctorTable from "./components/03-organisms/tables/DoctorTable/DoctorTable";
// import PharmacyTable from "./components/03-organisms/tables/PharmacyTable/PharmacyTable";
import SellerTable from "./components/03-organisms/tables/SellerTable/SellerTable";
import FacilityTable from "./components/03-organisms/tables/FacilityTable/FacilityTable";
import ScrollToTop from "./components/02-molecules/ScrollToTop/ScrollToTop";
import InboxTemplate from "./components/04-templates/inbox/InboxTemplate";
import NewRpTable from "./components/03-organisms/tables/RpTable/NewRpTable";
import BiGeneral from "./components/04-templates/analytics/BiGeneral/BiGeneral";
import BiVisits from "./components/04-templates/analytics/BiVisit/BiVisits";
import BiEadv from "./components/04-templates/analytics/BiEadv";
import BiObjections from "./components/04-templates/analytics/BiObjections/BiObjections";
import BiRequests from "./components/04-templates/analytics/BiRequests/BiRequests";
import BiRp from "./components/04-templates/analytics/BiRp/BiRp";
import BiOrders from "./components/04-templates/analytics/BiOrders";
import RpAddPage from "./components/06-pages/rp-page/RpAddPage";
import RpListPage from "./components/06-pages/rp-page/RpListPage";
import RpEditPage from "./components/06-pages/rp-page/RpEditPage";
import RpSinglePage from "./components/06-pages/rp-page/RpSinglePage";
import { PlanificationPage } from "./components/06-pages/planification-page/PlanificationPage";
import { ThemeProvider } from "@material-ui/core";
import { theme } from "./utils/muiTheme";
import { ReportsListe } from "./components/04-templates/Reports/Report";
import { AuthorizationManagement } from "./components/04-templates/authorization-management/AuthorizationManagement";
import DoctorValidationAddRequest from "./components/04-templates/validation/validation-requests/doctors/DoctorValidationAddRequest";
import DoctorValidationDeleteRequest from "./components/04-templates/validation/validation-requests/doctors/DoctorValidationDeleteRequest";
import DoctorValidationUpdateRequest from "./components/04-templates/validation/validation-requests/doctors/DoctorValidationUpdateRequest";
import Orders from "./components/04-templates/orders/Orders";
import OrdeAnalytics from "./components/04-templates/orders/order-analytics/OrdeAnalytics";
import SalesTargetPage from "./components/04-templates/sales-target/SalesTargetPage";
import Users from "./components/04-templates/users/Users";
import Products from "./components/04-templates/products/Products";
import  FacilityType from "./components/04-templates/FacilityType/FacilityTypeTemplate";
import Speciality from "./components/04-templates/speciality/Speciality";
// import useFirebase from "./utils/useFirebase";
// import { getNotifications } from "./redux/Notification/notificationSlice";
// import useFirebase from "./utils/useFirebase";
function App() {
  moment.locale("fr");
  const locale = useRsuiteTranstion();
  const dispatch = useAppDispatch();
  const { isAuth } = useAppSelector((state: RootState) => state.auth);
  const { delegateById, currentUser } = useAppSelector(
    (state: RootState) => state.user
  );
  const { doctorById } = useAppSelector((state: RootState) => state.doctor);
  const { sellerById } = useAppSelector((state: RootState) => state.seller);
  // const { pharmacyById } = useAppSelector((state: RootState) => state.pharmacy);
  // const [initialFired, setInitialFired] = useState<boolean>(false);
  // const { requestForToken } = useFirebase();

  // useEffect(() => {
  //   navigator.serviceWorker.addEventListener("message", (message) => {
  //     console.log(message);
  //     setTimeout(() => {
  //       console.log("firedListener");
  //       // dispatch(getNotifications({ limit: 10 }));
  //     }, 3000);
  //   });
  //   return () => {
  //     navigator.serviceWorker.removeEventListener("message", () => {});
  //   };
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);
  useEffect(() => {
    if (isAuth) {
      dispatch(fetchGeneralData());
      dispatch(getGovernorates());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuth]);

  return (
    <Suspense fallback=" ">
      <CustomProvider locale={locale}>
        <ThemeProvider theme={theme}>
          <Routes>
            {/* Home Page */}
            <Route
              path="/"
              element={<ProtectedRoute element={<HomePage />} />}
            />

            {/* Manager Profile Page */}
            <Route
              path="/account"
              element={
                <ProtectedRoute element={<UserPage user={currentUser} />} />
              }
            >
              <Route
                path="infos"
                element={<UserProfileInfo user={currentUser} />}
              />
              <Route path="teams" element={<TeamList />} />
              <Route path="visits" element={<VisitsList context="user" />} />
              <Route path="rp" element={<NewRpTable context="manager" />} />
              <Route path="settings" element={<UserSettings />} />
            </Route>

            {/* Delegate Profile Page */}
            <Route
              path="/user/:id"
              element={
                <ProtectedRoute element={<UserPage user={delegateById} />} />
              }
            >
              <Route
                path="infos"
                element={<UserProfileInfo user={delegateById} />}
              />
              <Route
                path="agenda"
                element={<DelegateAgenda user={delegateById} />}
              />
              <Route path="visits" element={<VisitsList context="user" />} />
              <Route
                path="requests"
                element={<RequestsList context="user" />}
              />
              <Route
                path="objections"
                element={<ObjectionsList context="user" />}
              />
              <Route path="rp" element={<NewRpTable context="delegate" />} />
              <Route path="orders" element={<div>orders</div>} />
            </Route>

            {/* Doctor Profile Page */}
            <Route
              path="/doctor/:id"
              element={
                <ProtectedRoute element={<DoctorPage doctor={doctorById} />} />
              }
            >
              <Route
                path="infos"
                element={<DoctorProfileInfo doctor={doctorById} />}
              />
              <Route path="visits" element={<VisitsList context="doctor" />} />
              <Route
                path="requests"
                element={<RequestsList context="doctor" />}
              />
              <Route
                path="objections"
                element={<ObjectionsList context="doctor" />}
              />
              <Route path="orders" element={<div>orders</div>} />
              <Route path="rp" element={<NewRpTable context="doctor" />} />
            </Route>
            {/* Seller Profile Page */}
            <Route
              path="/seller/:id"
              element={<ProtectedRoute element={<SellerPage />} />}
            >
              <Route
                path="infos"
                element={
                  <SellerPharmacyProfileInfo sellerPharmacy={sellerById} />
                }
              />
              <Route path="visits" element={<VisitsList context="seller" />} />
              <Route
                path="requests"
                element={<RequestsList context="seller" />}
              />
              <Route
                path="objections"
                element={<ObjectionsList context="seller" />}
              />
              <Route path="orders" element={<div>orders</div>} />
              <Route path="rp" element={<div>PR</div>} />
            </Route>
            {/* Pharmacy Profile Page */}
            <Route
              path="/pharmacy/:id"
              element={<ProtectedRoute element={<PharmacyPage />} />}
            >
              {/* <Route
                path="infos"
                element={
                  <SellerPharmacyProfileInfo sellerPharmacy={pharmacyById} />
                }
              /> */}
              <Route
                path="visits"
                element={<VisitsList context="pharmacy" />}
              />
              <Route
                path="requests"
                element={<RequestsList context="pharmacy" />}
              />
              <Route
                path="objections"
                element={<ObjectionsList context="pharmacy" />}
              />
              <Route path="orders" element={<div>orders</div>} />
              <Route path="rp" element={<div>PR</div>} />
            </Route>

            {/* Database Page */}
            <Route
              path="/data-base"
              element={<ProtectedRoute element={<Database />} />}
            >
              <Route path="doctors" element={<DoctorTable />} />
              {/* <Route path="pharmacies" element={<PharmacyTable />} />*/}
              <Route path="sellers" element={<SellerTable />} />
              <Route path="facilities" element={<FacilityTable />} />
              <Route path="users" element={<Users />} />
              <Route path="products" element={<Products />} />
              <Route path="facilitytype" element={<FacilityType />} />
              <Route path="Speciality" element={<Speciality />} />
              
            </Route>

            {/* Cycle Management Page */}
            <Route
              path="/cycles-management/"
              element={<ProtectedRoute element={<CycleManagementPage />} />}
            >
              <Route path="general" element={<CycleManagementGeneral />} />
            </Route>
            <Route
              path="/target/:id"
              element={<ProtectedRoute element={<TargetPage />} />}
            />
            <Route
              path="/planification/:id"
              element={<ProtectedRoute element={<PlanificationPage />} />}
            />

            {/* Requests / Objections Page */}
            <Route
              path="/requests"
              element={<ProtectedRoute element={<RequestsObjectionsPage />} />}
            >
              <Route
                path="requests"
                element={<RequestsList context={"global"} />}
              />
              <Route
                path="objections"
                element={<ObjectionsList context={"global"} />}
              />
            </Route>
            {/* Rp Page */}
            <Route
              path="/rp"
              element={<ProtectedRoute element={<RpModule />} />}
            >
              <Route path="" element={<RpListPage />} />
              <Route path=":id" element={<RpSinglePage />} />
              <Route path="add" element={<RpAddPage />} />
              <Route path="edit/:id" element={<RpEditPage />} />
            </Route>
            {/* Tracking Page */}
            <Route
              path="/activity-tracking"
              element={<ProtectedRoute element={<TrackingPage />} />}
            />

            {/* Validation Page */}
            <Route
              path="/validation"
              element={<ProtectedRoute element={<ValidationPage />} />}
            >
              <Route path="doctors" element={<DoctorValidation />} />
              <Route
                path="doctors/add/:id"
                element={<DoctorValidationAddRequest />}
              />
              <Route
                path="doctors/delete/:id"
                element={<DoctorValidationDeleteRequest />}
              />
              <Route
                path="doctors/update/:id"
                element={<DoctorValidationUpdateRequest />}
              />
              <Route path="pharmacies" element={<PharmacyValidation />} />
              <Route path="sellers" element={<SellerValidation />} />
              <Route path="facilities" element={<FacilityValidation />} />
              <Route path="rps" element={<RpValidation />} />
            </Route>
            {/* Reports Page */}
            <Route
              path="/reports"
              element={<ProtectedRoute element={<ReportsPage />} />}
            >
              <Route path="report" element={<ReportsListe />} />
              <Route
                path="authorisationsManagement"
                element={<AuthorizationManagement />}
              />
            </Route>
            {/* Orders Page */}
            <Route
              path="/orders"
              element={<ProtectedRoute element={<OrdersPage />} />}
            >
              <Route index element={<Orders />} />
              <Route path="/orders/commande" element={<Orders />} />
              <Route path="/orders/analytiques/commande" element={<OrdeAnalytics />} />
              <Route path="/orders/objectif-de-vente" element={<SalesTargetPage  />} />
            </Route>
            {/* Analytics Page */}
            <Route
              path="/analytics"
              element={<ProtectedRoute element={<AnalyticsPage />} />}
            >
              <Route path="phalcon-analytics/general" element={<BiGeneral />} />
              <Route path="phalcon-analytics/visits" element={<BiVisits />} />
              <Route path="phalcon-analytics/eadv" element={<BiEadv />} />
              <Route
                path="phalcon-analytics/objections"
                element={<BiObjections />}
              />
              <Route
                path="phalcon-analytics/requests"
                element={<BiRequests />}
              />
              <Route path="phalcon-analytics/rp" element={<BiRp />} />
              <Route path="phalcon-analytics/orders" element={<BiOrders />} />

              <Route path="vr-analytics" element={<VrAnalytics />} />
            </Route>
            {/* Agenda Page */}
            <Route
              path="/agenda"
              element={<ProtectedRoute element={<AgendaPage />} />}
            />

            {/* Inbox */}

            <Route
              path="/inbox"
              element={<ProtectedRoute element={<InboxTemplate />} />}
            />

            {/* Auth Pages */}
            <Route path="/login" element={<LoginPage />} />
            <Route path="/forgot-password" element={<ForgotPasswordPage />} />
            <Route path="/reset-password" element={<ResetPasswordPage />} />
            <Route
              path="/verify-token/:token"
              element={<TokenVerificationPage />}
            />

            {/* 
           
*/}
          </Routes>
          <ToastContainer position="bottom-right" />
          <ScrollToTop />
        </ThemeProvider>
      </CustomProvider>
    </Suspense>
  );
}

export default App;
