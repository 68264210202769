import { mdiChevronDown } from "@mdi/js";
import Icon from "@mdi/react";
import React, { createRef } from "react";
// import { useTranslation } from "react-i18next";
import { DOMHelper, Divider, Row } from "rsuite";
import { Objection } from "../../../../types/Objection.model";
import { getLocalDate } from "../../../../utils/dateHelpers";
import { getSatisfactionIconPath } from "../../../../utils/getSatisfactionIconPath";
import { CustomAvatar } from "../../../01-atoms/CustomAvatar/CustomAvatar";

type Props = {
  objection: Objection;
};

export const ObjectionCard = ({ objection }: Props) => {
  // const { t } = useTranslation();
  const expandObjRef: any = createRef();
  const { toggleClass } = DOMHelper;

  return (
    <>
      <div className="standard-card-info__wrapper standard-card-info__wrapper__left-border standard-card-info__wrapper__left-border--orange">
        <div className="standard-card-info__main">
          <div className="standard-card-info__content">
            <div className="standard-card-info__content--top">
              <div className="objection-product nowrap-product">
                {objection?.productObjection?.product?.productName
                  ? objection?.productObjection?.product?.productName
                  : "-"}
              </div>
              <div className="objection-user user-avatar">
                <CustomAvatar
                  image={{
                    src: `${objection?.assignedTo?.photoUrl || ""}`,
                    alt: `${objection?.assignedTo?.photoUrl || ""}`, 
                  }}
                  style={{ width: "24px", height: "24px" }}
                  isToolTip={
                    objection?.productObjection?.product?.productName.length >
                    24
                      ? true
                      : false
                  } 
                  toolTipTitle={
                    objection?.assignedTo
                      ? `${objection?.assignedTo.firstName} ${objection?.assignedTo.lastName}`
                      : "-"
                  }
                />

                {objection?.productObjection?.product?.productName.length >
                24 ? (
                  ""
                ) : (
                  <div className="fullname">
                    {objection?.assignedTo
                      ? `${objection?.assignedTo.firstName} ${objection?.assignedTo.lastName}`
                      : "-"}
                  </div>
                )}
              </div>

              <div className="standard-card-info-status">
                <div className="status-label">Status</div>
                <div
                  className={`status-description ${
                    objection?.isTreated ? "--green" : "--red"
                  } nowrap-status`}
                >
                  {objection?.isTreated ? "Traitée" : "Non traitée"}
                </div>
              </div>
            </div>
            <div className="standard-card-info__content--bottom">
              {`${
                objection?.productObjection?.productObjectionType
                  ?.productObjectionTypeName
              } : ${
                objection?.productObjection?.description
                  ? objection.productObjection.description
                  : "-"
              }`}
            </div>
            <div className="standard-card-info__content--bottom">
              <div className="standard-card-info-status">
                <div className="status-label">Initié le</div>
                <div className="status-description ">
                  {objection?.openedOn
                    ? getLocalDate(objection?.openedOn)
                    : "-"}
                </div>
              </div>
              <div className="standard-card-info-status">
                <div className="status-label">Clôturé le</div>
                <div className="status-description ">
                  {objection?.closedOn
                    ? getLocalDate(objection?.closedOn)
                    : "-"}
                </div>
              </div>
              <div className="standard-card-info-status">
                <div className="status-label"> Clôturé par</div>
                <div className=" status-description">
                  {objection?.closedBy
                    ? `${objection?.closedBy?.firstName} ${objection?.closedBy?.lastName}`
                    : "-"}
                </div>
              </div>
              <div className="standard-card-info-status">
                <div className="status-label">
                  <Icon
                    path={getSatisfactionIconPath(objection?.satisfactionLevel)}
                    size={1}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="visit-card__button">
            <div
              className="icon"
              onClick={() => toggleClass(expandObjRef.current, "isOpen")}
            >
              <Icon path={mdiChevronDown} size={0.75} />
            </div>
          </div>
        </div>
        <div className="visit-card__expand" ref={expandObjRef}>
          <Divider style={{ margin: "0 1.5rem" }} />
          <div className="visit-card__expand--inner">
            <Row>
              <dl className="">
                <dd>
                  Réponse : {objection?.response ? objection?.response : "-"}
                </dd>
              </dl>
            </Row>
          </div>
        </div>
      </div>
    </>
  );
};
