import axios from "axios";
import jwtDecode from "jwt-decode";
import { removeSession } from "../redux/Authentication/authenticationSlice";
import { API_DEV } from "./apiRoute";
const baseURL = API_DEV;
axios.defaults.withCredentials = true;
export const axiosPublic = axios.create({
  baseURL,
});
export const axiosPrivate = axios.create({
  baseURL,
});

export const setupInterceptor = (store: any) => {
  let refreshTokenTriggered = false;
  axiosPrivate.interceptors.request.use(async (config) => {
    try {
      let accessTokenFromLocal: string =
        localStorage.getItem("accessToken") || "";
      let accessTokenFromSession: string =
        sessionStorage.getItem("accessToken") || "";

      if (accessTokenFromLocal) {
        const decodedToken: { exp: number } = jwtDecode(accessTokenFromLocal);
        let currentDate = new Date();
        let isExpired = decodedToken.exp * 1000 < currentDate.getTime();

        if (
          isExpired &&
          axiosPrivate.defaults.url !== "/Authentication/refresh-token" &&
          !refreshTokenTriggered
        ) {
          refreshTokenTriggered = true;
          let response = await axiosPublic.post(
            `${baseURL}/Authentication/refresh-token`,
            { refreshToken: null }
          );
          console.log("error from interceptor");
          localStorage.setItem("accessToken", response.data.accessToken);
          accessTokenFromLocal = response.data.accessToken;
          refreshTokenTriggered = false;
        }
        if (config?.headers) {
          config.headers["Authorization"] = `${accessTokenFromLocal}`;
        }
      }
      if (accessTokenFromSession) {
        const decodedToken: { exp: number } = jwtDecode(accessTokenFromSession);
        let currentDate = new Date();
        let isExpired = decodedToken.exp * 1000 < currentDate.getTime();

        if (
          isExpired &&
          axiosPrivate.defaults.url !== "/Authentication/refresh-token" &&
          !refreshTokenTriggered
        ) {
          refreshTokenTriggered = true;

          let response = await axiosPublic.post(
            `${baseURL}/Authentication/refresh-token`,
            { refreshToken: null }
          );
          sessionStorage.setItem("accessToken", response.data.accessToken);
          accessTokenFromSession = response.data.accessToken;
          refreshTokenTriggered = false;
        }
        if (config?.headers) {
          config.headers["Authorization"] = `${accessTokenFromSession}`;
        }
      }

      return config;
    } catch (error) {
      if (JSON.parse(JSON.stringify(error)).status === 400) {
        store.dispatch(removeSession());
        return Promise.reject(error);
      } else {
        console.log(
          "error from interceptor",
          JSON.parse(JSON.stringify(error))
        );
        store.dispatch(removeSession());
        return Promise.reject(error);
      }
    }
  });
};
