import React from "react";
import "./PhalconCell.scss";
type Props = {
  item: string;
};

const BudgetCell = ({ item }: Props) => {
  return <div className="phalcon-budget-cell">{item} TND</div>;
};

export default BudgetCell;
