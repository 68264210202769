import { useAppDispatch } from "../../../../redux/hooks";
import moment from "moment";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { ObjectionsListContext } from "../../../04-templates/profile/objections-list/ObjectionsList";
import { fetchFilteredObjection } from "../../../../redux/Objections/objectionSlice";

interface UseFilterObjectionProps {
  context: ObjectionsListContext;
}
interface ObjectionOrderBy {
  [isClosed: string]: number;
  isClosed_desc: number;
  satisfactionLevel: number;
  satisfactionLevel_desc: number;
  oponedOn: number;
  oponedOn_desc: number;
  closedOn: number;
  closedOn_desc: number;
}
type ObjectionFilterData = {
  satisfactionLevel: string[];
  isClosed: boolean | null | string[];
  date: [Date, Date] | undefined;
};
export type GlobalObjectionFilterState = {
  search: {
    searchText: string;
    isClosed: boolean | null | string[];
    startDate: string | Date | null;
    endDate: string | Date | null;
    // priority: number[];
    satisfactionLevel: number[];
    productId: number | null;
    userId: number | null;
    doctorId: number | null;
    sellerId: number | null;
    pharmacyId: number | null;
  } ;
  pagination: { 
    pageNumber: number;
    pageSize: number;
    // totalCount: number;
    // totalPages: number;
  };
  orderBy: number;
};
export const useFilterObjection = ({ context }: UseFilterObjectionProps) => {
  const dispatch = useAppDispatch();
  const params = useParams();

  const [globalFilterState, setGlobalFilterState] =
    useState<GlobalObjectionFilterState>({
      search: {
        searchText: "",
        isClosed: null,
        startDate: null,
        endDate: null,
        productId: null,
        satisfactionLevel: [],
        // priority: [],
        userId: params.id && context === "user" ? parseInt(params.id) : null,
        doctorId:
          params.id && context === "doctor" ? parseInt(params.id) : null,
        sellerId:
          params.id && context === "seller" ? parseInt(params.id) : null,
        pharmacyId:
          params.id && context === "pharmacy" ? parseInt(params.id) : null,
      },
      pagination: {
        pageNumber: 1,
        pageSize: 10,
      },
      orderBy: 0,
    });

  const captureSearchTextValue = (searchTerm: string) => {
    setGlobalFilterState({
      search: {
        ...globalFilterState.search,
        searchText: searchTerm,
      },
      pagination: {
        ...globalFilterState.pagination,
      },
      orderBy: globalFilterState.orderBy,
    });
  };

  const captureFilter = (data: ObjectionFilterData) => {
    const { date, satisfactionLevel, isClosed } = data;
    setGlobalFilterState({
      search: {
        ...globalFilterState.search,
        satisfactionLevel: satisfactionLevel?.map((item) => parseInt(item)),
        isClosed:
          typeof isClosed !== "boolean" &&
          (!isClosed?.length || isClosed?.length === 2)
            ? null
            : typeof isClosed !== "boolean" && isClosed[0] === "true"
            ? true
            : false,
        startDate: date ? moment(date[0]).utc().toDate() : null,
        endDate: date ? moment(date[1]).utc().toDate() : null,
      },
      pagination: {
        ...globalFilterState.pagination,
      },
      orderBy: globalFilterState.orderBy,
    });
  };

  const capturePageTarget = (page: number) => {
    setGlobalFilterState({
      search: {
        ...globalFilterState.search,
      },
      pagination: {
        ...globalFilterState.pagination,
        pageNumber: page+1,
      },
      orderBy: globalFilterState.orderBy,
    });
  };

  const captureLimitTarget = (limit: number) => {
    setGlobalFilterState({
      search: {
        ...globalFilterState.search,
      },
      pagination: {
        ...globalFilterState.pagination,
        pageNumber: 1,
        pageSize: limit,
      },
      orderBy: globalFilterState.orderBy,
    });
  };
  const captureOrderBy = (sortColumn: string, sortType: string) => {
    let orderByValue = `${
      sortType === "desc" ? sortColumn + "_" + sortType : sortColumn
    }`;
    const objectionOrderBy: ObjectionOrderBy = {
      isClosed: 0,
      isClosed_desc: 1,
      satisfactionLevel: 2,
      satisfactionLevel_desc: 3,
      oponedOn: 4,
      oponedOn_desc: 5,
      closedOn: 6,
      closedOn_desc: 7,
    };
    setGlobalFilterState({
      search: {
        ...globalFilterState.search,
      },
      pagination: {
        ...globalFilterState.pagination,
      },
      orderBy: objectionOrderBy[orderByValue],
    });
  };

  useEffect(() => {
    dispatch(fetchFilteredObjection(globalFilterState));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [globalFilterState]);

  return {
    globalFilterState,
    captureSearchTextValue,
    captureOrderBy,
    captureFilter,
    capturePageTarget,
    captureLimitTarget,
  };
};
