import React, { createRef } from "react";
import { Controller } from "react-hook-form";
import { Form, SelectPicker, Col } from "rsuite";
import { CustomErrorMessage } from "../ErrorMessage/CustomErrorMessage";
type Props = {
  control: any;
  errors: any;
  label: string;
  inputName: string;
  rules: any;
  searchable?: boolean;
  data: any[];
  xs?: number;
  sm?: number;
  md?: number;
  lg?: number;
  cleanable?: boolean;
  readOnly?: boolean;
  disabled?: boolean;
  index?: number;
  disabledItemValues?: any;
  prepareNextData?: (e: any, index: number) => void;
  handleLocalityChange?: (e: string) => void;
  handlefacilityTypeChange?: (index: number) => void;
  placeholder? : (string & (boolean | React.ReactChild | React.ReactFragment | React.ReactPortal | null));
  onSubmit?: () => void
};

export const SelectField = ({
  label,
  control,
  inputName,
  rules,
  errors,
  searchable,
  data,
  xs,
  sm,
  md,
  lg,
  cleanable,
  readOnly,
  disabled,
  index,
  disabledItemValues,
  prepareNextData,
  handleLocalityChange,
  handlefacilityTypeChange,
  placeholder,
  onSubmit
}: Props) => {
  const selectInputRef: any = createRef();
  return (
    <Col xs={xs || 24} sm={sm} md={md} lg={lg} style={{ marginBottom: "1rem" }}>
      <Form.Group>
        <Form.ControlLabel>{label}</Form.ControlLabel>
        <Controller
          control={control}
          name={inputName}
          rules={rules}
          render={({ field: { onChange, name, value } }) => (
            <Form.Control
              ref={selectInputRef}
              container={() => selectInputRef.current}
              style={{ width: "100%", position: "relative" }}
              className="input-full-width"
              menuClassName="picker-fixed-top"
              menuMaxHeight={100}
              name={name}
              cleanable={cleanable}
              readOnly={readOnly}
              accepter={SelectPicker}
              value={value}
              disabledItemValues={disabledItemValues}
              disabled={disabled}
              searchable={searchable}
              placeholder={placeholder || undefined}
              data={data}
              onChange={(e: any) => {
                onChange(e);
                if (handlefacilityTypeChange && typeof index === "number") {
                  handlefacilityTypeChange(index);
                }
                if (prepareNextData && typeof index === "number") {
                  prepareNextData(e, index);
                }
                handleLocalityChange && handleLocalityChange(e);
                onSubmit && onSubmit()
              }}
              
            />
          )}
        />
        <CustomErrorMessage errors={errors} name={inputName} />
      </Form.Group>
    </Col>
  );
};
