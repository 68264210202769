
import React from "react";
import "./PhalconCell.scss";
import { useAppSelector } from "../../../../../redux/hooks";
import { RootState } from "../../../../../redux/store";
type Props = {
  item: number | null;
  size?: number;
};

interface ChannelIcon  {
    [key: number] : string;
}

const channelIcons : ChannelIcon = {
    1: "Face à face" ,
    2: "Téléphone",
    3: "Whatsapp", 
    4: "Messenger",
    5: "Zoom",
    6: "Teams",
};

const ChannelCell = ({ item, size }: Props) => {
  const { channels } = useAppSelector((state: RootState) => state.general);

  if (
    item &&
    channels &&
    channels.length &&
    channels.find((channel) => channel.channelId === item)
  ) { 

    let iconPath : string = channelIcons[item] 
    return <span> {iconPath} </span>
    // return <Icon path={iconPath} size={size ? size : 1} />;
  } else {
    return <span> - </span>;
  }
};

export default ChannelCell;
