import { mdiFileClockOutline } from "@mdi/js";
import React, { useEffect, useState } from "react";
import { Payment } from "../../../../types/Payment.model";
import { getLocalDate } from "../../../../utils/dateHelpers";
import InfoWithIconTag from "../../../01-atoms/InfoWithIconTag/InfoWithIconTag";
import CustomizedTables from "../../../02-molecules/tables/CustomizedTable";

type Props = {
  requestState: "loading" | "idle";
  paymentDetailsList: Payment | null;
};

const ordredCommendColumnsTitle: {
  title: string;
  align: "left" | "right" | "inherit" | "center" | "justify" | undefined;
}[] = [
  { title: "Date", align: "left" },
  { title: "montant", align: "left" },
  { title: "type de paiement", align: "left" },
  { title: "Date d'échéance", align: "left" },
];


export default function PaymentDetails({
  requestState,
  paymentDetailsList,
}: Props) {
  const [paymentRows, setPaymentRows] = useState<any[]>([])
  const paymentDetailFormatedArray = () => {
    if (
      requestState === "idle" &&
      paymentDetailsList &&
      paymentDetailsList.payementDetails &&
      paymentDetailsList.payementDetails.length 
    ) {
      return paymentDetailsList.payementDetails.map((el) => {
        return {
          date: el.payementDate ? getLocalDate(el.payementDate) : "-",
          amount: el.amount ? el.amount : "-",
          paymentType: el.statusPayementType
            ? el.statusPayementType.statusName
            : "-", 
          dateDeadLine: el.dateDeadLine ? getLocalDate(el.dateDeadLine) : "-",
        };
      });
    } else return []
  };

  useEffect(() => {
    let formatedRows : any[]= paymentDetailFormatedArray()
    setPaymentRows(formatedRows)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [requestState, paymentDetailsList])
  
  return (
    <div className="order-detail-body-section2">
      <section>
        <InfoWithIconTag
          info={null}
          label={"Historique paiement"}
          icon={mdiFileClockOutline}
          IconSize={0.9}
          labelClassName={"style3"}
          infoClassName={"style2"}
        />
      </section>
      <section>
        <CustomizedTables
          columns={ordredCommendColumnsTitle}
          rows={paymentRows}
        /> 
      </section>
    </div>
  );
}
