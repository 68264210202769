import { mdiTag , mdiBarcode, mdiWeb } from "@mdi/js";
import Icon from "@mdi/react";
import { t } from "i18next";
import React, { useEffect, useState } from "react";
import { Divider } from "rsuite";
import { Locality } from "../../../../types/Locality.model";
import { Phone } from "../../../../types/Phone.model";
import { Seller } from "../../../../types/Seller.model";
import { ProfileInfoItemPlaceholder } from "../../../02-molecules/placeholders/Placeholder";
import { ProfileHeader } from "../../../03-organisms/user-account/profile-header/ProfileHeader";
import { InfoItemEmail } from "./InfoItemEmail";
import { InfoItemLocality } from "./InfoItemLocality";
import { InfoItemPhone } from "./InfoItemPhone";
import { InfoItemTags } from "./InfoItemTags";
import { StandardInfo } from "./StantardInfo";

type Props = {
  sellerPharmacy: Seller | null;
};
type ProfileData = {
  localities: Locality[];
  streetName: string;
  noteAdress: string;
  phones: Phone[];
  email: string;
  reference: string;
  tags: string[];
  shift: string;
  webSite: string;
};
export const SellerPharmacyProfileInfo = ({ sellerPharmacy }: Props) => {
  const [profileData, setprofileData] = useState<ProfileData>({
    localities: [],
    streetName: "",
    noteAdress: "",
    phones: [],
    email: "",
    reference: "",
    tags: [],
    shift: "",
    webSite: "",
  });
  const prepareData = () => ({
    localities: sellerPharmacy?.address?.localities || [],
    streetName: sellerPharmacy?.address?.streetName || "",
    noteAdress: sellerPharmacy?.address?.addressNote || "",
    phones: sellerPharmacy?.phones || [],
    email: sellerPharmacy?.email || "_",
    reference: sellerPharmacy?.reference || "_",
    tags: sellerPharmacy?.tags?.map((tag) => tag.tagName) || [],
    shift:
      sellerPharmacy && sellerPharmacy.shift === 0
        ? "Jour"
        : sellerPharmacy && sellerPharmacy.shift === 1
        ? "Nuit"
        : "_",
    webSite: sellerPharmacy?.website || "_",
  });

  useEffect(() => {
    const data = prepareData();
    setprofileData(data);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sellerPharmacy]);

  return (
    <div className="profile-info__wrapper">
      <div className="profile-infos">
        <div className="profile_avatar_rwd">
          <ProfileHeader context="seller" person={sellerPharmacy} />
          <Divider />
        </div>

        {/* Locality Item */}
        {profileData.localities.length > 0 && (
          <InfoItemLocality
            loading={false}
            localities={profileData.localities}
            streetName={profileData.streetName}
            noteAdress={profileData.noteAdress}
          />
        )}
        {/* Phone Item */}
        <InfoItemPhone phones={profileData.phones} loading={false} />
        {/* Email Item */}
        <InfoItemEmail email={profileData.email} loading={false} />
        <Divider hidden />
        <Divider />
        <Divider hidden />

        <StandardInfo
          loading={false}
          info={`Référence : ${profileData.reference}`}
          icon={mdiBarcode}
        />
        <StandardInfo
          loading={false}
          info={profileData.webSite}
          icon={mdiWeb}
        />

        <Divider hidden />
        <Divider />
        <Divider hidden />
        <div className="extra">
          {sellerPharmacy ? (
            <div className="extra__heading">
              <div className="icon">
                <Icon path={mdiTag } size={1} />
              </div>
              <dl className="content">
                <dt>{t("account_page.user_infos.tags")}</dt>
              </dl>
            </div>
          ) : (
            <ProfileInfoItemPlaceholder withCircle rows={1} />
          )}

          {profileData.tags && profileData.tags.length > 0 && (
            <InfoItemTags loading={false} tags={profileData.tags} />
          )}
        </div>
      </div>
    </div>
  );
};
