import React, { useEffect } from "react";
import { Navigate } from "react-router-dom";
import { Layout } from "../components/05-layouts/Layout";
import { useAppDispatch, useAppSelector } from "../redux/hooks";
import { RootState } from "../redux/store";
import { fetchCurrentUser } from "../redux/User/userSlice";

interface Props {
  element: any;
}

export const ProtectedRoute = ({ element }: Props) => {
  const { isAuth } = useAppSelector((state: RootState) => state.auth);
  const dispatch = useAppDispatch();
  useEffect(() => {
    isAuth && dispatch(fetchCurrentUser());
  }, [dispatch, isAuth]);
  return <Layout>{isAuth ? element : <Navigate to="/login" />}</Layout>;
};
