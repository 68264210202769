import React, { useEffect, useState } from "react";
import { Input } from "rsuite";
import { useAppDispatch } from "../../../../redux/hooks";
import { updateObjectionResponse } from "../../../../redux/Objections/objectionSlice";
import { CustomButton } from "../../../01-atoms/Buttons/Buttons";
import CloseIcon from '@material-ui/icons/Close';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton,
} from "@material-ui/core";

type Props = {
  handleClickOpen: any;
  open: boolean;
  handleClose: any; 
  rowPramas: {
    id: number;
    objectionValue: string;
    response: string;
    isClosed: boolean;
  };
};

export const ObjectionExpandRow = ({
  handleClickOpen,
  open,
  handleClose,
  rowPramas,
}: Props) => {
  const [objectionResponse, setObjectionResponse] = useState(rowPramas.response);
  const dispatch = useAppDispatch();

  useEffect(() => {
    setObjectionResponse(rowPramas.response); 
  }, [rowPramas.response]);

  return (
    
    <Dialog open={open} onClose={handleClose} fullWidth>
      <DialogTitle>
      <IconButton
          // aria-label="close"
          onClick={handleClose}
          style={{
            position: 'absolute',
            right: 8,
            top: 8,
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
       <div
      className="request-expand-row"
      style={{ height: "fit-content !important" }}
    >
      <div className="request-value">
        <h4 className="request-value__title">Objection</h4>
        <div className="request-value__body">{rowPramas.objectionValue}</div>
      </div>
      <div className="request-response">
        <h4 className="request-response__title">Réponse</h4>
        {rowPramas.isClosed ? (
          <Input
            as="textarea"
            className="request-response__body"
            rows={3}
            placeholder="Textarea"
            readOnly
            value={objectionResponse}
            onChange={(e) => setObjectionResponse(e)}
          />
        ) : (
          <>
            <Input
              as="textarea"
              className="request-response__body"
              rows={3}
              placeholder="Textarea"
              value={objectionResponse}
              onChange={(e) => setObjectionResponse(e)}
            />
            <CustomButton
              text="Traiter"
              className="submit-button"
              onClick={() =>
                dispatch(
                  updateObjectionResponse({
                    objectionId: rowPramas.id,  
                    response: objectionResponse,
                  })
                )
              }
            />
          </>
        )}
      </div>
    </div>
      </DialogContent>
    </Dialog>
  );
};
