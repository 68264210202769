import React from "react";
import { useAppSelector } from "../../../../redux/hooks";
import { RootState } from "../../../../redux/store";
import { Doctor } from "../../../../types/Doctor.model";
import { Seller } from "../../../../types/Seller.model";

import { getLocalDate } from "../../../../utils/dateHelpers";
import "./SubHeader.scss";
interface Props {
  person: Doctor | Seller | null;
  context: "doctor" | "seller";
}

export const SubHeader = ({ person, context }: Props) => {
  const { totalVisits,lastVisitDate, requestState } = useAppSelector(
    (state: RootState) => state.visit
  );
  return (
    <div className="sub-header__wrapper">
      <div className="sub-header--left">
        <dl className="info-item">
          <dt>Potentiel</dt>
          <dd>{person?.potential.potentialName}</dd>
        </dl>
        { context === "doctor" && <dl className="info-item">
          <dt>Patients/jour</dt>
          <dd>{person?.patientsPerDay || "-"}</dd>
        </dl> }
        <dl className="info-item">
          <dt>Visites Effectuées</dt>
          <dd>{totalVisits && requestState === "idle" ? totalVisits : "-"}</dd>
        </dl>
      </div>
      <div className="sub-header--right">
        <dl className="info-item">
          <dt>Dernière visite effectuée</dt>
          <dd>
            {lastVisitDate && requestState === "idle"
              ? getLocalDate(lastVisitDate)
              : "-"}
          </dd>
        </dl>
      </div>
    </div>
  );
};
