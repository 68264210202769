import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { useRoutingConfig } from "../../../utils/useRoutingConfig";
import AnalyticsSubHeader from "../../03-organisms/analytics/AnalyticsSubHeader";
import { Header } from "../../05-layouts/Header/Header";

type Props = {};

const AnalyticsPage = (props: Props) => {
  const { t } = useTranslation();
  const headerLeftItem = <h2>{t("analytics_page.title")}</h2>;
  const currentRoute = useRoutingConfig().find((e) => e.key === "11");
  const subRoutes =
    typeof currentRoute !== "undefined" ? currentRoute.children : [];
  const { pathname } = useLocation();
  const navigate = useNavigate();
  useEffect(() => {
    pathname === "/analytics" &&
      navigate("/analytics/phalcon-analytics/general");
  }, [navigate, pathname]);
  return (
    <div>
      <Header leftItem={headerLeftItem} subRoutes={subRoutes} />
      <AnalyticsSubHeader />
      <Outlet />
    </div>
  );
};

export default AnalyticsPage;
