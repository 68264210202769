import { mdiTune } from "@mdi/js";
import Icon from "@mdi/react";
import React, { createRef } from "react";
import { Controller, useForm } from "react-hook-form";
import { CheckTree, DateRangePicker, Form, Popover, Whisper } from "rsuite";
import { useAppSelector } from "../../../../redux/hooks";
import { RootState } from "../../../../redux/store";
import { getUtcDate, getServerDateFormat } from "../../../../utils/dateHelpers";
import { useHandleResize } from "../../../../utils/useHandleResize";
import { CustomButton } from "../../../01-atoms/Buttons/Buttons";
import { CustomErrorMessage } from "../../../02-molecules/forms/ErrorMessage/CustomErrorMessage";
import "./RpFilter.scss";
type Props = {
  captureFilter: (data: any) => void;
};
type RpFilterInputs = {
  type: number[];
  date: [Date, Date] | undefined;
};

const typeData = [
  { label: "Table Ronde", value: 0 },
  { label: "EPU", value: 1 },
  { label: "Lancement", value: 2 },
];
export const RpFilter = ({ captureFilter }: Props) => {
  const deviceSize = useHandleResize();
  const { search } = useAppSelector(
    (state: RootState) => state.rpEvent.globalFilterState
  );
  const initForm: RpFilterInputs = {
    type: search.type,
    date:
      search.startDate && search.endDate
        ? [getUtcDate(search.startDate), getUtcDate(search.endDate)]
        : undefined,
  };
  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<RpFilterInputs>({
    defaultValues: initForm,
    criteriaMode: "all",
    reValidateMode: "onChange",
  });

  const onSubmit = handleSubmit(({ type, date }) => {
    let payload = {
      type: type,
      startDate: date ? getServerDateFormat(date[0]).toString() : "",
      endDate: date ? getServerDateFormat(date[1]).toString() : "",
      doctorId: null,
      externalId: null,
      organizerId: null,
    };
    captureFilter(payload);
  });

  const datePickerRangeRef: any = createRef();
  const renderMenu = ({ onClose, left, top, className }: any, ref: any) => {
    return (
      <Popover
        ref={ref}
        className={className + " rp-filter__whisper"}
        style={{ left, top }}
        full
      >
        <Form>
          <div className="rp-filter__inner">
            <div className="types-area">
              <Form.Group className="type-field">
                <Controller
                  control={control}
                  name="type"
                  render={({ field: { onChange, name, value } }) => (
                    <Form.Control
                      name={name}
                      accepter={CheckTree}
                      data={typeData}
                      value={value}
                      onChange={(e: any, val: any) => {
                        onChange(e);
                        onSubmit();
                      }}
                    />
                  )}
                />
              </Form.Group>
            </div>
            <div className="dates-area">
              <Form.Group>
                <Controller
                  control={control}
                  name="date"
                  render={({ field: { onChange, name, value } }) => (
                    <Form.Control
                      ref={datePickerRangeRef}
                      accepter={DateRangePicker}
                      name={name}
                      value={value}
                      format={"yyyy-LL-dd"}
                      style={{ width: "100%" }}
                      isoWeek
                      container={() => datePickerRangeRef.current}
                      placement="autoHorizontal"
                      onChange={(e: any) => {
                        onChange(e);
                        onSubmit();
                      }}
                    />
                  )}
                />
                <CustomErrorMessage errors={errors} name="date" />
              </Form.Group>
            </div>
          </div>
        </Form>
      </Popover>
    );
  };
  return (
    <div className="rp-filter__wrapper">
      <Whisper
        placement="autoVerticalEnd"
        trigger="click"
        speaker={renderMenu}
        preventOverflow
      >
        <div>
          <CustomButton
            text={["Mobile", "Tablet"].includes(deviceSize) ? "" : "Filtrer"}
            icon={<Icon path={mdiTune} size={1} />}
          />
        </div>
      </Whisper>
    </div>
  );
};
