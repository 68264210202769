import React, { useEffect } from "react";
import { Outlet, useLocation, useNavigate, useParams } from "react-router-dom";
import { fetchDoctorById } from "../../../redux/Doctor/doctorSlice";
import { useAppDispatch } from "../../../redux/hooks";
import { Doctor } from "../../../types/Doctor.model";
import { useHandleResize } from "../../../utils/useHandleResize";
import { useRoutingConfig } from "../../../utils/useRoutingConfig";
import { ProfileHeader } from "../../03-organisms/user-account/profile-header/ProfileHeader";
import { SubHeader } from "../../03-organisms/user-account/sub-header/SubHeader";
import { DoctorProfileInfo } from "../../04-templates/profile/profile-infos/DoctorProfileInfo";
import { Header } from "../../05-layouts/Header/Header";
import "./DoctorPage.scss";
type Props = {
  doctor: Doctor | null;
};

export const DoctorPage = ({ doctor }: Props) => {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const { id } = useParams();
  const dispatch = useAppDispatch();

  //Responsiveness variables
  const deviceSize = useHandleResize();
  const isMobile = deviceSize === "Mobile";
  const isTablet = deviceSize === "Tablet";
  const isDesktop = !["Mobile", "Tablet"].includes(deviceSize);

  const currentRoute = useRoutingConfig().find((e) => e.key === "14");
  const generalSubRoutes =
    typeof currentRoute !== "undefined" ? currentRoute.children : [];
  const subRoutes =
    isMobile || isTablet
      ? generalSubRoutes.filter((e) => e.path !== "settings")
      : generalSubRoutes.filter((e) => e.path !== "infos");

  const manageDoctorPageRoutes = (deviceSize: string, path: string) => {
    const showDoctorInfos = isMobile && path === `/doctor/${id}`;
    const hideDoctorInfos = isDesktop && path === `/doctor/${id}/infos`;
    const showDoctorVisits = isDesktop && path === `/doctor/${id}`;

    showDoctorInfos && navigate(`/doctor/${id}/infos`);
    hideDoctorInfos && navigate(`/doctor/${id}`);
    showDoctorVisits && navigate(`/doctor/${id}/visits`);
  };

  useEffect(() => {
    manageDoctorPageRoutes(deviceSize, pathname);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deviceSize, pathname, navigate]);

  // Fetch Doctor on load
  useEffect(() => {
    const abortController = new AbortController();
    id &&
      dispatch(
        fetchDoctorById({ id: parseInt(id), signal: abortController.signal })
      );
    return () => {
      abortController.abort();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);
  return (
    <>
      <Header
        leftItem={<ProfileHeader context="doctor" person={doctor} />}
        subRoutes={subRoutes}
      />
      {deviceSize !== "Mobile" ? (
        <div className="user-page__inner">
          <div className="user-page__inner__left">
            <DoctorProfileInfo doctor={doctor} />
          </div>
          <div className="user-page__inner__main">
            <SubHeader person={doctor} context="doctor"/>
            <Outlet />
          </div>
        </div>
      ) : (
        <Outlet />
      )}
    </>
  );
};
