import React, { useEffect, useState } from "react";
import { useFieldArray } from "react-hook-form";
import { Col, Divider, FlexboxGrid } from "rsuite";
import {
  prepareServicesOptions,
  prepareDoctorSummaryOptions,
  phoneTypeOptions,
} from "../../../../helpers/GlobalFormHelpers";
import { useAppSelector } from "../../../../redux/hooks";
import { RootState } from "../../../../redux/store";
import { SelectField } from "../../../02-molecules/forms/SelectField/SelectField";
import { TextField } from "../../../02-molecules/forms/TextField/TextField";
import { AddBlockButton } from "../addBlockButton/AddBlockButton";
import { RemoveBlockButton } from "../removeBlockButton/RemoveBlockButton";

type ServicePhonesBlockProps = {
  fieldIsRequired: string;
  control: any;
  errors: any;
};

type ServicesOptions = {
  key: number | null;
  label: string;
  value: number | null;
}[];

type DoctorSummaryOptions = {
  key: number;
  label: string;
  value: number | string;
}[];

const ServicePhonesBlock = ({
  fieldIsRequired,
  control,
  errors,
}: ServicePhonesBlockProps) => {
  const servicePhones = useFieldArray({
    control,
    name: "services",
  });

  const [servicesOptions, setServicesOptions] = useState<ServicesOptions>([]);
  const [DoctorSummaryOptions, setDoctorSummaryOptions] = useState<
    DoctorSummaryOptions | []
  >([]);
  const { services } = useAppSelector((state: RootState) => state.general);
  const { doctorSummary } = useAppSelector((state: RootState) => state.doctor);

  useEffect(() => {
    const options = prepareServicesOptions(services);
    options.length > 0 && setServicesOptions(options);
  }, [services]);
  useEffect(() => {
    const options = prepareDoctorSummaryOptions(doctorSummary);
    options.length > 0 && setDoctorSummaryOptions(options);
  }, [doctorSummary]);
  return (
    <div className="custom-form-section">
      <h3 className="form-block-title">Services </h3>
      <Divider />
      {servicePhones.fields.map((field, index) => (
        <FlexboxGrid align="bottom" key={field.id}>
          {/* serviceType */}
          <SelectField
            label={"Service"}
            inputName={`services.${index}.serviceType.serviceTypeId`}
            rules={{ required: fieldIsRequired }}
            control={control}
            errors={errors}
            searchable={true}
            cleanable={false}
            data={servicesOptions}
            xs={24}
            sm={12}
            md={12}
          />
          {/* chief */}
          <SelectField
            label={"Chef de service"}
            inputName={`services.${index}.chiefId`}
            rules={{ required: false }}
            control={control}
            errors={errors}
            searchable={true}
            cleanable={false}
            data={DoctorSummaryOptions}
            xs={24}
            sm={11}
            md={11}
          />
          <RemoveBlockButton
            contextArray={servicePhones}
            index={index}
            style={{ marginBottom: "1rem" }}
            removeAll={true}
          />
          <Col xs={24}>
            {/* servicePhone */}
            <SelectField
              label={"Type"}
              inputName={`services.${index}.servicePhone.phoneType`}
              rules={{ required: fieldIsRequired }}
              control={control}
              errors={errors}
              searchable={false}
              cleanable={false}
              data={phoneTypeOptions}
              xs={24}
              sm={6}
              md={6}
            />
            <TextField
              label={"Numéro"}
              inputName={`services.${index}.servicePhone.phoneNumber`}
              rules={{ required: fieldIsRequired }}
              control={control}
              errors={errors}
              inputMode="tel"
              xs={24}
              sm={6}
              md={6}
            />
            <TextField
              label={"Description"}
              inputName={`services.${index}.servicePhone.phoneDescription`}
              control={control}
              errors={errors}
              xs={24}
              sm={11}
              md={11}
            />
          </Col>
        </FlexboxGrid>
      ))}
      <AddBlockButton context="service" contextArray={servicePhones} />
    </div>
  );
};
export default ServicePhonesBlock;
