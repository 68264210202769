import Activity from "../types/Activity.model";
import { Agenda } from "../types/Agenda.model";
import { Appointment } from "../types/Appointments.model";
import { Locality } from "../types/Locality.model";
import { Office } from "../types/Office.model";
import { Phone } from "../types/Phone.model";
import { Potential } from "../types/Potential.model";
import { ExpectedSalesTargetTableRowsInterface } from "../types/SalesTarget.model";
import { Team } from "../types/Team.model";
import { getcurrentDate, getSubstractedTime } from "../utils/dateHelpers";

/**
 * A function that reduces the offices phones array
 * @offices Office[]
 * @output flat array
 */

export const reducesPhoneOffices = (offices: Office[]) => {
  if (offices && Array.isArray(offices) && offices.length) {
    return offices.reduce((acc: any, el) => {
      if (el.phones && Array.isArray(el.phones) && el.phones.length) {
        return acc.concat(el.phones);
      } else return [];
    }, []);
  } else {
    return [];
  }
};

/**
 * A function that reduces the Teams members list into one list
 * @teams Team[]
 * @output flat array of users
 */
export const reducesTeamMemberLists = (teams: Team[]) => {
  if (teams && Array.isArray(teams) && teams.length) {
    return teams.reduce((acc: any, el) => {
      if (el.members && Array.isArray(el.members) && el.members.length) {
        return acc.concat(el.members);
      } else return [];
    }, []);
  } else {
    return [];
  }
};

/**
 * A function that concat the phones of the doctor with the doctor's offices phones
 * @doctorPhones array
 * @offices Office[]
 * @output flat array
 */

export const doctorPhones = (
  doctorPhones: Phone[] | null,
  offices: Office[] | null
) => {
  if (!doctorPhones && !offices) {
    return [];
  } else if (doctorPhones && (!offices || !offices.length)) {
    return doctorPhones;
  } else if (
    (!doctorPhones || !doctorPhones.length) &&
    offices &&
    offices.length
  ) {
    return reducesPhoneOffices(offices);
  } else if (doctorPhones && doctorPhones.length && offices && offices.length) {
    return reducesPhoneOffices(offices).concat(doctorPhones);
  }
};

/**
 * A function that return an address as a string
 * @localities Locality[]
 * @streetName string
 * @output string
 */

export const addressString = (
  localities: Locality[] | null | undefined,
  streetName: string | null | undefined
) => {
  let addressListe: string[] = [];

  if (localities && localities.length > 0) {
    addressListe = [...localities]
      .sort((a, b) => {
        return a.level - b.level;
      })
      .reduce((acc: string[], el: Locality) => {
        if (el.localityName && el.localityName.length) {
          return [...acc, el.localityName];
        } else {
          return acc;
        }
      }, []);
  }
  if (streetName && streetName.length) addressListe.unshift(streetName);
  if (addressListe.length) {
    return addressListe.join(", ");
  } else {
    return "_";
  }
};

/**
 * A function that format the agenda []
 *
 */

export interface FormatedAgenda {
  date: string;
  isHolidy: boolean;
  schedual: { time: string; description: string; type: string }[];
}

export const formatAgendaData = (agenda: Agenda[]): FormatedAgenda[] => {
  if (agenda && Array.isArray(agenda) && agenda.length) {
    return agenda.reduce((acc: any, el) => {
      let activitiesFormatedData = formatAgendaActivitiesData(
        el?.activities || []
      );
      let appointmentsFormatedData = formatAgendaAppointmentsData(
        el?.appointments || []
      );
      return acc.concat({
        date: getcurrentDate(el.date),
        isHoliday: el.isHoliday,
        schedual: appointmentsFormatedData.concat(activitiesFormatedData),
      });
    }, []);
  } else return [];
};

/**
 * A function used in formatAgendaData, it format agenda activities
 *
 */

export const formatAgendaActivitiesData = (activities: Activity[]) => {
  if (activities && Array.isArray(activities) && activities.length) {
    return activities.reduce((acc: any, el) => {
      return acc.concat({
        time: !el.duration
          ? ""
          : el.duration === 0.5
          ? "Demi journée"
          : `${el?.duration && el.duration < 1 ? el.duration : 1} jour(s)`,
        description: el?.activityType?.activityTypeName,
        type: "activity",
      });
    }, []);
  } else {
    return [];
  }
};

/**
 * A function used in formatAgendaData, it format agenda appoinments
 *
 */

export const formatAgendaAppointmentsData = (appointments: Appointment[]) => {
  if (appointments && Array.isArray(appointments) && appointments.length) {
    return appointments
      .reduce((acc: any, el) => {
        return acc.concat({
          type: "appointment",
          time: getSubstractedTime(el.date),
          description: `RDV ${el?.weekContent?.doctor?.firstName || ""} ${
            el?.weekContent?.doctor?.lastName || ""
          }`,
        });
      }, [])
      .sort((a: { time: string }, b: { time: string }) => {
        if (a.time > b.time) {
          return 1;
        } else if (a.time < b.time) {
          return -1;
        } else return 0;
      });
  } else return [];
};

/**
 * A function used to get the id of the potential
 *
 */

export const getPotentialId = (
  potentials: Potential[],
  potentialName: string
) => {
  if (potentials && typeof potentials === "object" && potentials.length) {
    return (
      potentials.find((el) => el.potentialName === potentialName)
        ?.potentialId || ""
    );
  } else return "";
};

/**
 * A function used to remove duplicate data in a string
 *
 */

export const removeDuplicateDataFromString = (data: string) => {
  if (data && typeof data === "string") {
    return Array.from(new Set(data.split(", "))).join(", ");
  } else return "";
};

// return the product list  into a string

export const formatProductNameIntoAStringList = (
  list: { productId: number; productName: string }[]
) => {
  let arrayList: string[] = [];
  let result: string = "";
  if (list && typeof list === "object" && list.length) {
    // eslint-disable-next-line array-callback-return
    list.map((el, index) => {
      const { productName } = el;
      !index
        ? productName && arrayList.push(productName)
        : productName && arrayList.push(` ${productName}`);
    });
  } else {
    return "-";
  }
  if (arrayList && arrayList.length) {
    return (result = [...new Set(arrayList)].toString());
  } else return result;
};

// return the an array list  into a string

export const formatArrayListIntoAStringList = (
  list: any[],
  name: string,
  secondName?: string | null,
  separator?: string | null
) => {
  let arrayList: string[] = [];
  let result: string = "";

  if (list && typeof list === "object" && list.length && name && !separator) {
    // eslint-disable-next-line array-callback-return
    list.map((el, index) => {
      !index
        ? secondName
          ? el[name][secondName] && arrayList.push(el[name][secondName])
          : el[name] && arrayList.push(el[name])
        : secondName
        ? el[name][secondName] && arrayList.push(` ${el[name][secondName]}`)
        : el[name] && arrayList.push(` ${el[name]}`);
    });
  } else if (
    list &&
    typeof list === "object" &&
    list.length &&
    name &&
    separator &&
    secondName
  ) {
    // eslint-disable-next-line array-callback-return
    list.map((el) => {
      el[name] &&
        el[secondName] &&
        arrayList.push(`${el[name]}${separator}${el[secondName]}`);
    });
  } else {
    return "-";
  }
  if (arrayList && arrayList.length) {
    return (result = [...new Set(arrayList)].toString());
  } else return result;
};

export const updateValues = (
  listToUpdate: any[],
  valueGroup: string,
  value: string
) => {
  // If array empty add first object

  if (listToUpdate.length === 0) {
    return [{ valueGroup: valueGroup, value: value }];
  } else {
    // look if valueGroup is already there
    const obj = listToUpdate.find((find) => find.valueGroup === valueGroup);
    if (!obj) {
      // if no add new valueGroup with first values
      return [...listToUpdate, { valueGroup: valueGroup, value: value }];
    } else {
      if (Array.isArray(obj.value) && obj.value.includes(value)) {
        // yes, remove value from values
        return [
          ...listToUpdate.filter((object) => object.valueGroup !== valueGroup),
          {
            ...obj,
            value:
              Array.isArray(obj.value) &&
              obj.value.filter((filter: any) => filter !== value),
          },
        ];
      } else {
        // no, add value to values
        return [
          ...listToUpdate.filter((object) => object.valueGroup !== valueGroup),
          { ...obj, value: value },
        ];
      }
    }
  }
};

// used to calculate the spread out of sales objective total
export const salesObjectiveCalculator = (total: number) => {
  let divisedTotal: number = 0;
  let rest: number = 0;
  if (!(total % 12)) {
    divisedTotal = total / 12;
  } else {
    rest = total % 12;
    divisedTotal = (total - rest) / 12;
  }
  return {
    rest: rest,
    divisedTotal: divisedTotal,
  };
};

// used to calculate the spread out of sales objective total
export const reduceArrayFromArrayOfObjectsToArrayOfNumbers = (
  list: { label: string | number; value: number | null }[]
) => {
  let result: number[] = [];
  if (list && typeof list === "object" && list.length) {
    result = list.reduce(
      (
        acc: number[],
        current: { label: string | number; value: number | null }
      ) => {
        let { value } = current;
        if (value) {
          return acc.concat([value]);
        } else return acc;
      },
      []
    );
  }
  return result;
};

export const handleColorAccordingTotheDiffBetweenValueForSalesTarget = (
  id: number,
  list: ExpectedSalesTargetTableRowsInterface[],
  value: number | string,
  month:
    | "april"
    | "august"
    | "december"
    | "february"
    | "january"
    | "july"
    | "june"
    | "march"
    | "may"
    | "november"
    | "october"
    | "september"
    | "totalQuantity"
) => {
  let result: string = "#373737";
  if (id && list && list.length) {
    let element: any = list.find((e: { productId: number }) => e.productId === id);
    if (element && (element[month] === value || element[month] < value)) {
    //  month === "totalQuantity" && console.log(element, value , month)
      result = "green";
    } else {
      result = "red";
    }
  } else {
    return result;
  }
  return result;
};
