import { mdiTune } from "@mdi/js";
import Icon from "@mdi/react";
import React, { createRef } from "react";
import { Controller, useForm } from "react-hook-form";
import { DateRangePicker, Form, Popover, Whisper } from "rsuite";
import { useHandleResize } from "../../../../utils/useHandleResize";
import { CustomButton } from "../../../01-atoms/Buttons/Buttons";
import { CustomErrorMessage } from "../../../02-molecules/forms/ErrorMessage/CustomErrorMessage";
import { useFilterBi } from "./useBiFilter";
import "./BiFilter.scss";
import moment from "moment";

type Props = {
  context : string;
};
type BiObjectionFilterInputs = {
  date: [Date, Date] | undefined;
};

export const BiFilter = ({context} : Props ) => {
  const deviceSize = useHandleResize();
  const initForm: BiObjectionFilterInputs = {
    date: undefined,
  };
  const { 
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<BiObjectionFilterInputs>({
    defaultValues: initForm,
    criteriaMode: "all",
    reValidateMode: "onChange", 
  });
  const { 
    captureFilter, 
  } = useFilterBi({context});

  const onSubmit = handleSubmit((data) => {
   captureFilter(data);
  });

  const datePickerRangeRef: any = createRef();
  const renderMenu = ({ onClose, left, top, className }: any, ref: any) => {
    return (
      <Popover
        ref={ref}
        className={className + " bi-filter__whisper"}
        style={{ left, top }}
        full
      >
        <Form>
          <div className="bi-filter__inner">
            <div className="dates-area">
              <Form.Group>
                <Controller
                  control={control}
                  name="date"
                  render={({ field: { onChange, name, value } }) => (
                    <Form.Control
                      ref={datePickerRangeRef} 
                      accepter={DateRangePicker}
                      disabledDate={(date: Date)=>moment(date.toString()).isAfter()} 
                      name={name}
                      value={value}
                      isoWeek
                      format={"yyyy-LL-dd"}
                      style={{ width: "100%" }}
                      container={() => datePickerRangeRef.current}
                      placement="autoHorizontal"
                      onChange={(e: any) => {
                        onChange(e);
                        onSubmit();
                      }}
                    />
                  )}
                />
                <CustomErrorMessage errors={errors} name="date" />
              </Form.Group>
            </div>
          </div>
        </Form>
      </Popover>
    );
  };
  return (
    <div className="bi-filter__wrapper">
      <Whisper
        placement="autoVerticalEnd"
        trigger="click"
        speaker={renderMenu}
        preventOverflow
      >
        <div>
          <CustomButton
            text={["Mobile", "Tablet"].includes(deviceSize) ? "" : "Filtrer"}
            icon={<Icon path={mdiTune} size={1} />}
            style={{ backgroundColor: "#20ABE3", color: "white" }}
          />
        </div>
      </Whisper>
    </div>
  );
};
