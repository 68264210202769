import {
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Avatar,
  Tooltip,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  Box,
  TablePagination,
  Typography,
  makeStyles,
  createStyles,
  Theme,
} from "@material-ui/core";
import VisibilityIcon from "@material-ui/icons/Visibility";
import { useEffect, useState } from "react";
import ObjectionSummary from "../../../types/ObjectionSummary.model";
import { getLocalDate } from "../../../utils/dateHelpers";

interface UntreatedObjectionsTableProps {
  untreatedObjections: ObjectionSummary[];
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    tableTitle: {
      padding: theme.spacing(2),
    },
    spacing: {
      marginTop: theme.spacing(2),
    },
    cellCenter: {
      marginLeft: "auto",
      marginRight: "auto",
    },
  })
);

const UntreatedObjectionsTable = ({
  untreatedObjections,
}: UntreatedObjectionsTableProps) => {
  const [rowStatus, setRowStatus] = useState<{
    rowId: number | null;
    isOpen: boolean;
  }>({ rowId: null, isOpen: true });
  const classes = useStyles();
  const handleToggle = (id: number) => {
    rowStatus.rowId !== id && setRowStatus({ rowId: id, isOpen: true });
  };
  const [page, setPage] = useState(0);
  const rowsPerPage = 10;
  const [dataToShow, setDataToShow] = useState<ObjectionSummary[]>([]);
  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPage(newPage);
  };

  useEffect(() => {
    const startIndex = rowsPerPage * page;
    const endIndex = startIndex + rowsPerPage;
    const data = untreatedObjections.filter(
      (_, index) => index >= startIndex && index <= endIndex
    );
    setDataToShow(data);
  }, [untreatedObjections, page, rowsPerPage]);

  return (
    <TableContainer component={Paper} className={classes.spacing}>
      <Box className={classes.tableTitle}>
        <Typography variant="subtitle2">Objections non traitées</Typography>
      </Box>
      <Table aria-label="simple table" size="small">
        <TableHead>
          <TableRow>
            <TableCell align="left" width={40}>
              Délégué
            </TableCell>
            <TableCell align="left">Médecin</TableCell>
            <TableCell align="left">Produit</TableCell>
            <TableCell align="left">Type d'objection</TableCell>
            <TableCell align="left">Date de l'objection</TableCell>
            <TableCell align="left">Assignée à</TableCell>
            <TableCell align="center">Aperçu</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {dataToShow &&
            dataToShow.length > 0 &&
            dataToShow.map((item: ObjectionSummary) => (
              <TableRow key={item.objectionId}>
                <TableCell component="th" scope="row" align="left">
                  <Tooltip
                    placement="right"
                    title={
                      item.createdBy.firstName + " " + item.createdBy.lastName
                    }
                    aria-label={
                      item.createdBy.firstName + " " + item.createdBy.lastName
                    }
                  >
                    <Avatar
                      alt={
                        item.createdBy.firstName + " " + item.createdBy.lastName
                      }
                      src={item.createdBy.photoUrl}
                    />
                  </Tooltip>
                </TableCell>
                <TableCell align="left" style={{whiteSpace: "nowrap"}}>{item.client.name}</TableCell>
                <TableCell align="left"> 
                  {item.productObjection.product.productName}
                </TableCell>

                <TableCell align="left">
                  {
                    item?.productObjection?.productObjectionType
                      ?.productObjectionTypeName
                  }
                </TableCell>
                <TableCell align="left">
                  {getLocalDate(item.createdOn)}
                </TableCell>
                <TableCell align="left">
                  <Tooltip
                    placement="right"
                    title={
                      item.assignedTo.firstName + " " + item.assignedTo.lastName
                    }
                    aria-label={
                      item.assignedTo.firstName + " " + item.assignedTo.lastName
                    }
                  >
                    <Avatar
                      alt={
                        item.assignedTo.firstName +
                        " " +
                        item.assignedTo.lastName
                      }
                      src={item.assignedTo.photoUrl}
                    />
                  </Tooltip>
                </TableCell>
                <TableCell align="center">
                  <IconButton
                    onClick={() => {
                      handleToggle(item.objectionId);
                    }}
                  >
                    <VisibilityIcon fontSize="small" />
                  </IconButton>
                  <ObjectionModal
                    requestValue={item?.productObjection.description || "-"}
                    rowStatus={rowStatus}
                    objectionId={item.objectionId}
                    setRowStatus={setRowStatus}
                  />
                </TableCell>
              </TableRow>
            ))}
        </TableBody>
      </Table>
      <TablePagination
        component="div"
        rowsPerPageOptions={[10]}
        count={untreatedObjections.length}
        page={page}
        onPageChange={handleChangePage}
        rowsPerPage={rowsPerPage}
      />
    </TableContainer>
  );
};

export default UntreatedObjectionsTable;

const ObjectionModal = ({
  requestValue,
  objectionId,
  setRowStatus,
  rowStatus,
}: {
  requestValue: string;
  objectionId: number;
  setRowStatus: any;
  rowStatus: { rowId: number | null; isOpen: boolean };
}) => {
  return (
    <>
      {rowStatus.rowId &&
      rowStatus.rowId === objectionId &&
      rowStatus.isOpen ? (
        <Dialog
          open={true}
          onClose={() => {
            setRowStatus({ rowId: null, isOpen: false });
          }}
          maxWidth="lg"
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">{"Description"}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              {requestValue}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => {
                setRowStatus({ rowId: null, isOpen: false });
              }}
              color="primary"
              autoFocus
            >
              Fermer
            </Button>
          </DialogActions>
        </Dialog>
      ) : (
        ""
      )}
    </>
  );
};
