import { createSlice } from "@reduxjs/toolkit";
import { API_DEV } from "../../utils/apiRoute";
import { axiosPrivate } from "../../utils/interceptors";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { Doctor, DoctorExportListInterface } from "../../types/Doctor.model";
import { DoctorSummary } from "../../types/DoctorSummary.model";

type filteredDoctors = {
  data: Doctor[];
  pagination: {
    pageNumber: number;
    pageSize: number;
    totalCount: number;
    totalPages: number;
  };
};

export interface DoctorState {
  requestState: "idle" | "loading";
  filteredDoctorsSummariesRequestState : "idle" | "loading";
  requestStateForm : boolean;
  doctorSummary: DoctorSummary[];
  doctors: Doctor[];
  doctorById: Doctor | null;
  doctorByIdUpdate: Doctor | null;
  filteredDoctors: filteredDoctors;
  filteredDoctorsSummaries: DoctorSummary[];
  existantDoctors: Doctor[] | null;
  errors: any;
  doctorExportList : DoctorExportListInterface[] | null;
}

export const addDoctor = createAsyncThunk(
  "[Doctors]_add_",
  async (payload: any, { rejectWithValue }) => {
    try {
      await axiosPrivate.post(`${API_DEV}/Doctor`, payload);
    } catch (err:any) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const fetchDoctors = createAsyncThunk(
  "[Doctors]_fetchAll_",
  async (_, { rejectWithValue }) => {
    try {
      let response = await axiosPrivate.get(`${API_DEV}/Doctor`);
      return response.data;
    } catch (error) {
      return rejectWithValue(JSON.parse(JSON.stringify(error)));
    }
  }
);
export const fetchDoctorById = createAsyncThunk(
  "[Doctors]_fetchById_",
  async (payload: { id: number; signal: any }, { rejectWithValue }) => {
    try {
      let response = await axiosPrivate.get(`${API_DEV}/Doctor/${payload.id}`, {
        signal: payload.signal,
      });
      return response.data;
    } catch (error) {
      return rejectWithValue(JSON.parse(JSON.stringify(error)));
    }
  }
);

export const getFilteredDoctor = createAsyncThunk(
  "[Doctors]_filter_",
  async (options: any, { rejectWithValue }) => {
    try {
      let response = await axiosPrivate.post(
        `${API_DEV}/Doctor/Filters`,
        options
      );

      return response.data;
    } catch (error) {
      return rejectWithValue(JSON.parse(JSON.stringify(error)));
    }
  }
);

export const getFilteredDoctorSummaries = createAsyncThunk(
  "[Doctors]_filterSummaries_",
  async (options: any, { rejectWithValue }) => {
    try {
      let response = await axiosPrivate.post(
        `${API_DEV}/Doctor/SummaryFilters`,
        options
      );

      return response.data;
    } catch (error) {
      return rejectWithValue(JSON.parse(JSON.stringify(error)));
    }
  }
);

export const fetchDoctorByIdUpdate = createAsyncThunk(
  "[Doctors]_fetchByIdUpdate_",
  async (id: number, { rejectWithValue }) => {
    try {
      let response = await axiosPrivate.get(
        `${API_DEV}/Doctor/GetDoctorSingleRecordUpdate/${id}`
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(JSON.parse(JSON.stringify(error)));
    }
  }
);

export const updateDoctor = createAsyncThunk(
  "[Doctors]_update_",
  async (payload: any, { rejectWithValue }) => {
    try {
      let response = await axiosPrivate.put(`${API_DEV}/Doctor`, payload);
      return response.data;
    } catch (err:any) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const deleteDoctor = createAsyncThunk(
  "[Doctors]_delete_",
  async (
    payload: { itemId: number; password: string },
    { rejectWithValue }
  ) => {
    try {
      let response = await axiosPrivate.post(
        `${API_DEV}/Doctor/Delete`,
        payload
      );
      return response.data;
    } catch (err:any) {
      return rejectWithValue(err.response.data);
    }
  }
);
export const deleteDoctorRange = createAsyncThunk(
  "[Doctors]_deleteRange_",
  async (payload: any, { rejectWithValue }) => {
    try {
      let response = await axiosPrivate.delete(`${API_DEV}/Doctor/Range`, {
        data: payload,
        headers: { "Content-Type": "application/json;charset=utf-8" },
      });
      return response.data;
    } catch (error) {
      return rejectWithValue(JSON.parse(JSON.stringify(error)));
    }
  }
);
export const doctorCheckExistance = createAsyncThunk(
  "[Doctors]_checkExistance_",
  async (payload: any, { rejectWithValue }) => {
    try {
      let response = await axiosPrivate.post(
        `${API_DEV}/Doctor/CheckExistence`,
        payload
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(JSON.parse(JSON.stringify(error)));
    }
  }
);

export const fetchDoctorSummary = createAsyncThunk(
  "[Doctors]_fetchDoctorSummary_",
  async (_, { rejectWithValue }) => {
    try {
      let response = await axiosPrivate.get(
        `${API_DEV}/Doctor/GetDoctorsSummary`
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(JSON.parse(JSON.stringify(error)));
    }
  }
);

export const fetchDoctorListToExport = createAsyncThunk(
  "[Doctors]_fetchDoctorListToExport_",
  async (_, { rejectWithValue }) => {
    try {
      let response =await axiosPrivate.get(`${API_DEV}/Doctor/exportDoctors` );
      return response.data
    } catch (err:any) {
      return rejectWithValue(err.response.data);
    }
  }
);

const initialState: DoctorState = {
  requestState: "idle",
  requestStateForm: false,
  doctorExportList : null,
  filteredDoctorsSummariesRequestState: "idle",
  doctors: [],
  doctorSummary: [],
  doctorById: null,
  doctorByIdUpdate: null,
  filteredDoctors: {
    data: [],
    pagination: {
      pageNumber: 0,
      pageSize: 0,
      totalCount: 0,
      totalPages: 0,
    },
  },
  filteredDoctorsSummaries: [],
  existantDoctors: null,
  errors: null,
};

export const doctorSlice = createSlice({
  name: "[Doctors]",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(addDoctor.pending, (state) => {
      state.requestState = "loading";
      state.errors = null;
    });
    builder.addCase(addDoctor.fulfilled, (state) => {
      state.requestStateForm = !state.requestStateForm;
      state.errors = null;
    });
    builder.addCase(addDoctor.rejected, (state, { payload }) => {
      state.requestState = "idle";
      state.errors = payload; 
    });

    builder.addCase(fetchDoctors.pending, (state) => {
      state.requestState = "loading";
      state.errors = null;
    });
    builder.addCase(fetchDoctors.fulfilled, (state, { payload }) => {
      state.requestState = "idle";
      state.doctors = payload;
      state.errors = null;
    });
    builder.addCase(fetchDoctors.rejected, (state, { payload }) => {
      state.requestState = "idle";
      state.errors = payload;
    });

    builder.addCase(fetchDoctorById.pending, (state) => {
      state.requestState = "loading";
      state.errors = null;
    });
    builder.addCase(fetchDoctorById.fulfilled, (state, { payload }) => {
      state.requestState = "idle";
      state.doctorById = payload;
      state.errors = null;
    });
    builder.addCase(fetchDoctorById.rejected, (state, { payload }) => {
      state.requestState = "idle";
      state.errors = payload;
    });
    builder.addCase(fetchDoctorByIdUpdate.pending, (state) => {
      state.requestState = "loading";
      state.errors = null;
    });
    builder.addCase(fetchDoctorByIdUpdate.fulfilled, (state, { payload }) => {
      state.requestState = "idle";
      state.doctorByIdUpdate = payload;
      state.errors = null;
    });
    builder.addCase(fetchDoctorByIdUpdate.rejected, (state, { payload }) => {
      state.requestState = "idle";
      state.errors = payload;
    });
    builder.addCase(getFilteredDoctor.pending, (state) => {
      state.requestState = "loading";
      state.errors = null;
    });
    builder.addCase(getFilteredDoctor.fulfilled, (state, { payload }) => {
      state.requestState = "idle";
      state.filteredDoctors = payload;
      state.errors = null;
    });
    builder.addCase(getFilteredDoctor.rejected, (state, { payload }) => {
      state.requestState = "idle";
      state.filteredDoctors = {
        data: [],
        pagination: {
          pageNumber: 0,
          pageSize: 0,
          totalCount: 0,
          totalPages: 0,
        },
      };
      state.errors = payload;
    });
    builder.addCase(getFilteredDoctorSummaries.pending, (state) => {
      state.filteredDoctorsSummariesRequestState = "loading";
      state.errors = null;
    });
    builder.addCase(
      getFilteredDoctorSummaries.fulfilled,
      (state, { payload }) => {
        state.filteredDoctorsSummariesRequestState = "idle";
        state.filteredDoctorsSummaries = payload;
        state.errors = null;
      }
    );
    builder.addCase(
      getFilteredDoctorSummaries.rejected,
      (state, { payload }) => {
        state.filteredDoctorsSummariesRequestState = "idle";
        state.filteredDoctorsSummaries = [];
        state.errors = payload;
      }
    );

    builder.addCase(updateDoctor.pending, (state) => {
      state.requestState = "loading";
      state.errors = null;
    });
    builder.addCase(updateDoctor.fulfilled, (state, { payload }) => {
      state.requestStateForm = !state.requestStateForm;
      state.requestState= "idle";
      state.errors = null;
    });
    builder.addCase(updateDoctor.rejected, (state, { payload }) => {
      state.requestState = "idle";
      state.errors = payload;
    });

    builder.addCase(deleteDoctor.pending, (state) => {
      state.requestState = "loading";
      state.errors = null;
    });
    builder.addCase(deleteDoctor.fulfilled, (state, { payload }) => {
      state.requestState = "idle";
      state.errors = null;
    });
    builder.addCase(deleteDoctor.rejected, (state, { payload }) => {
      state.requestState = "idle";
      state.errors = payload;
    });
    builder.addCase(deleteDoctorRange.pending, (state) => {
      state.requestState = "loading";
      state.errors = null;
    });
    builder.addCase(deleteDoctorRange.fulfilled, (state, { payload }) => {
      state.requestState = "idle";
      state.errors = null;
    });
    builder.addCase(deleteDoctorRange.rejected, (state, { payload }) => {
      state.requestState = "idle";
      state.errors = payload;
    });
    builder.addCase(doctorCheckExistance.pending, (state) => {
      state.requestState = "loading";
      state.errors = null;
    });
    builder.addCase(doctorCheckExistance.fulfilled, (state, { payload }) => {
      state.requestState = "idle";
      state.errors = null;
    });
    builder.addCase(doctorCheckExistance.rejected, (state, { payload }) => {
      state.requestState = "idle";
      state.errors = payload;
    });
    builder.addCase(fetchDoctorSummary.pending, (state) => {
      state.requestState = "loading";
      state.errors = null;
    });
    builder.addCase(fetchDoctorSummary.fulfilled, (state, { payload }) => {
      state.requestState = "idle";
      state.doctorSummary = payload;
      state.errors = null;
    });
    builder.addCase(fetchDoctorSummary.rejected, (state, { payload }) => {
      state.requestState = "idle";
      state.errors = payload;
    });
    builder.addCase(fetchDoctorListToExport.pending, (state) => {
      state.errors = null;
    });
    builder.addCase(fetchDoctorListToExport.fulfilled, (state, { payload }) => {
      state.doctorExportList = payload;
      state.errors = null;
    });
    builder.addCase(fetchDoctorListToExport.rejected, (state, { payload }) => {
      state.errors = payload;
      state.doctorExportList = null;
    });
  },
});

export default doctorSlice.reducer;
