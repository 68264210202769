import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Modal, Nav, List } from "rsuite";
import { Doctor } from "../../../../types/Doctor.model";
import { ExternalMember } from "../../../../types/ExternalMember.model";
import User from "../../../../types/User.model";
import { isManager } from "../../../../utils/enumHelpers";
import { CustomAvatar } from "../../../01-atoms/CustomAvatar/CustomAvatar";
import { ParticipantState } from "./EventCard";
import "./EventParticipantModal.scss";

type Props = {
  participantModalIsOpen: boolean;
  participantsState: ParticipantState;
  toggleParticipantModal: () => void;
};

interface ParticipantModalTabs {
  key: string;
  label: string;
  data: (Doctor | User | ExternalMember)[];
}

const ListItem = ({
  element,
  ...props
}: {
  element: Doctor | User | ExternalMember;
}) => {
  const getFullName = (element: any) => {
    if (element.title && element.firstName && element.lastName) {
      return `${element.title}. ${element.firstName} ${element.lastName}`;
    }
    if (element.firstName && element.lastName) {
      return `${element.firstName} ${element.lastName}`;
    } else {
      return element.fullName;
    }
  };

  const getOccupation = (element: Doctor | User | ExternalMember) => {
    const occupation =
      "userId" in element
        ? isManager(element)
          ? "Manager"
          : "Délégué"
        : "doctorId" in element
        ? "Médecin"
        : "Externe";
    return occupation;
  };

  const getLink = (element: Doctor | User | ExternalMember) => {
    const link =
      "userId" in element
        ? `/user/${element.userId}`
        : "doctorId" in element
        ? `/doctor/${element.doctorId}`
        : "";
    return link;
  };

  return (
    <List.Item className="participant-item">
      <Link to={getLink(element)} className="participant-item">
        <CustomAvatar
          style={{ width: "25px", height: "25px" }} 
          image={{
            src: "userId" in element ? element.photoUrl : "",
            alt: `${getFullName(element)}'s avatar`,
          }}
        />
        <div className="participant-fullName">{getFullName(element)}</div>
        <div className="participant-occupation">{getOccupation(element)}</div>
      </Link>
    </List.Item>
  );
};

export const EventParticipantModal = ({
  participantModalIsOpen,
  toggleParticipantModal,
  participantsState,
}: Props) => {
  const tabs = [
    {
      key: "myTeams",
      label: "Mes équipes",
      data: participantsState.users,
    },
    {
      key: "doctors",
      label: "Médecins",
      data: participantsState.doctors,
    },
    {
      key: "externals",
      label: "Externes",
      data: participantsState.externals,
    },
    {
      key: "externals",
      label: "Externes",
      data: participantsState.moderators,
    },
  ];
  const [activeTab, setActiveTab] = useState<string | undefined>(tabs[0].key);
  return (
    <Modal
      size="sm"
      open={participantModalIsOpen}
      onClose={toggleParticipantModal}
      className="event-participant-modal__wrapper"
    >
      <Modal.Header>
        <Modal.Title>Participants</Modal.Title>
        <Nav appearance="subtle" className="tab-navigation">
          {tabs.length > 0 &&
            tabs.map((tab: ParticipantModalTabs, i: number) => (
              <Nav.Item
                className="tab-item"
                eventKey={`${tab.key}`}
                active={tab.key === activeTab}
                key={tab.key}
                onSelect={(eventKey) => setActiveTab(eventKey)}
              >
                {`${tab.label} (${tab.data.length})`}
              </Nav.Item>
            ))}
        </Nav>
      </Modal.Header>
      <Modal.Body>
        {tabs.length > 0 &&
          tabs
            .filter(
              (tab: ParticipantModalTabs, i: number) => tab.key === activeTab
            )
            .map((tab: ParticipantModalTabs, i: number) => (
              <List key={tab.key}>
                {tab.data.map((element, i: number) => (
                  <ListItem element={element} key={i} />
                ))}
              </List>
            ))}
      </Modal.Body>
    </Modal>
  );
};
