import React from "react";
import DoctorValidationTable from "../../03-organisms/tables/ValidationTables/DoctorValidationTable";
import "./ValidationTemplate.scss";
type Props = {};

const DoctorValidation = (props: Props) => {
  return <div className="validation-template__wrapper">
    <DoctorValidationTable />
  </div>;
};

export default DoctorValidation;
