import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { API_DEV } from "../../utils/apiRoute";
import { axiosPrivate } from "../../utils/interceptors";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { Product } from "../../types/Product.model";

export interface GlobalFilterStateI {
  search: {
    searchText: string;
    businessUnits: number[];
  };
  pagination: {
    currentPageNumber: number;
    pageSize: number;
  };
}

export interface ProductState {
  requestState: "loading" | "idle";
  products: Product[];
  separatedProducts: {
    myProducts: Product[];
    othersProducts: Product[];
  };
  filteredProducts: {
    content: Product[];
    pagination: {
      currentPageNumber: number;
      pageSize: number;
      totalCount: number;
      totalPagesBySize: number;
    };
  };
  globalFilterState: GlobalFilterStateI;
  productById: Product | null;
  errors: any;
}

export const addProduct = createAsyncThunk(
  "[Products]_add_",
  async (payload: any, { rejectWithValue }) => {
    try {
      await axiosPrivate.post(`${API_DEV}/Product`, payload);
    } catch (error) {
      console.log(JSON.parse(JSON.stringify(error)));
      return rejectWithValue(JSON.parse(JSON.stringify(error)));
    }
  }
);

export const fetchProducts = createAsyncThunk(
  "[Products]_fetchAll_",
  async (_, { rejectWithValue }) => {
    try {
      let response = await axiosPrivate.get(`${API_DEV}/Product`);
      return response.data;
    } catch (error) {
      console.log(JSON.parse(JSON.stringify(error)));
      return rejectWithValue(JSON.parse(JSON.stringify(error)));
    }
  }
);

export const fetchProductById = createAsyncThunk(
  "[Products]_fetchById_",
  async (id: number, { rejectWithValue }) => {
    try {
      let response = await axiosPrivate.get(`${API_DEV}/Product/${id}`);
      return response.data;
    } catch (error) {
      console.log(JSON.parse(JSON.stringify(error)));
      return rejectWithValue(JSON.parse(JSON.stringify(error)));
    }
  }
);

export const updateProduct = createAsyncThunk(
  "[Products]_update_",
  async (payload: any, { rejectWithValue }) => {
    try {
      let response = await axiosPrivate.put(`${API_DEV}/Product`, payload);
      return response.data;
    } catch (error) {
      console.log(JSON.parse(JSON.stringify(error)));
      return rejectWithValue(JSON.parse(JSON.stringify(error)));
    }
  }
);

export const deleteProduct = createAsyncThunk(
  "[Products]_delete_",
  async (id: number, { rejectWithValue }) => {
    try {
      let response = await axiosPrivate.delete(`${API_DEV}/Product/${id}`);

      return response.data;
    } catch (error) {
      console.log(JSON.parse(JSON.stringify(error)));
      return rejectWithValue(JSON.parse(JSON.stringify(error)));
    }
  }
);

export const fetchFilteredProduct = createAsyncThunk(
  "[Products]_GetAllWithFilters_",
  async (options: any, { rejectWithValue }) => {
    try {
      let response = await axiosPrivate.post(
        `${API_DEV}/Product/GetAllWithFilters`,
        options
      );
      return response.data;
    } catch (error) {
      console.log(JSON.parse(JSON.stringify(error)));
      return rejectWithValue(JSON.parse(JSON.stringify(error)));
    }
  }
);
export const fetchSeparatedProducts = createAsyncThunk(
  "[Products]_GetSeparatedProducts_",
  async (options: { ids: number[] }, { rejectWithValue }) => {
    try {
      let response = await axiosPrivate.post(
        `${API_DEV}/Product/GetProductsByBusinessUnit`,
        options
      );
      return response.data;
    } catch (error) {
      console.log(JSON.parse(JSON.stringify(error)));
      return rejectWithValue(JSON.parse(JSON.stringify(error)));
    }
  }
);

const initialState: ProductState = {
  requestState: "idle",
  products: [],
  separatedProducts: {
    myProducts: [],
    othersProducts: [],
  },
  filteredProducts: {
    content: [],
    pagination: {
      currentPageNumber: 1,
      pageSize: 10,
      totalCount: 0,
      totalPagesBySize: 0,
    },
  },
  productById: null,
  errors: null,
  globalFilterState: {
    search: {
      searchText: "",
      businessUnits: [],
    },
    pagination: {
      currentPageNumber: 1,
      pageSize: 10,
    },
  },
};

export const productSlice = createSlice({
  name: "[Products]",
  initialState,
  reducers: {
    captureFilterValue: (
      state,
      action: PayloadAction<{ searchText: string; businessUnits: number[] }>
    ) => {
      state.globalFilterState.search.searchText = action.payload.searchText;
      state.globalFilterState.search.businessUnits =
        action.payload.businessUnits;
    },
    capturePageTarget: (state, action: PayloadAction<number>) => {
      state.globalFilterState.pagination.currentPageNumber = action.payload;
    },
    captureLimitTarget: (state, action: PayloadAction<number>) => {
      state.globalFilterState.pagination.pageSize = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(addProduct.pending, (state) => {
      state.requestState = "loading";
      state.errors = null;
    });
    builder.addCase(addProduct.fulfilled, (state) => {
      state.requestState = "idle";
      state.errors = null;
    });
    builder.addCase(addProduct.rejected, (state, { payload }) => {
      state.requestState = "idle";
      state.errors = payload;
    });
    builder.addCase(fetchProducts.pending, (state) => {
      state.requestState = "loading";
      state.errors = null;
    });
    builder.addCase(fetchProducts.fulfilled, (state, { payload }) => {
      state.requestState = "idle";
      state.products = payload;
      state.errors = null;
    });
    builder.addCase(fetchProducts.rejected, (state, { payload }) => {
      state.requestState = "idle";
      state.errors = payload;
    });
    builder.addCase(deleteProduct.pending, (state) => {
      state.requestState = "loading";
      state.errors = null;
    });
    builder.addCase(deleteProduct.fulfilled, (state, { payload }) => {
      state.requestState = "idle";
      state.errors = null;
    });
    builder.addCase(deleteProduct.rejected, (state, { payload }) => {
      state.requestState = "idle";
      state.errors = payload;
    });
    builder.addCase(fetchProductById.pending, (state) => {
      state.requestState = "loading";
      state.errors = null;
    });
    builder.addCase(fetchProductById.fulfilled, (state, { payload }) => {
      state.requestState = "idle";
      state.productById = payload;
      state.errors = null;
    });
    builder.addCase(fetchProductById.rejected, (state, { payload }) => {
      state.requestState = "idle";
      state.errors = payload;
    });
    builder.addCase(updateProduct.pending, (state) => {
      state.requestState = "loading";
      state.errors = null;
    });
    builder.addCase(updateProduct.fulfilled, (state) => {
      state.requestState = "idle";
      state.errors = null;
    });
    builder.addCase(updateProduct.rejected, (state, { payload }) => {
      state.requestState = "idle";
      state.errors = payload;
    });
    builder.addCase(fetchFilteredProduct.pending, (state) => {
      state.requestState = "loading";
      state.errors = null;
    });
    builder.addCase(fetchFilteredProduct.fulfilled, (state, { payload }) => {
      state.requestState = "idle";
      state.filteredProducts = payload;
      state.errors = null;
    });
    builder.addCase(fetchFilteredProduct.rejected, (state, { payload }) => {
      state.requestState = "idle";
      state.errors = payload;
    });
    builder.addCase(fetchSeparatedProducts.pending, (state) => {
      state.requestState = "loading";
      state.errors = null;
    });
    builder.addCase(fetchSeparatedProducts.fulfilled, (state, { payload }) => {
      state.requestState = "idle";
      state.separatedProducts = payload;
      state.errors = null;
    });
    builder.addCase(fetchSeparatedProducts.rejected, (state, { payload }) => {
      state.requestState = "idle";
      state.errors = payload;
    });
  },
});

export const { captureFilterValue, capturePageTarget, captureLimitTarget } =
  productSlice.actions;

export default productSlice.reducer;
