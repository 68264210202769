import React from "react";
import { Outlet } from "react-router-dom";

import "./RpPage.scss";
type Props = {};

const RpModule = (props: Props) => {
  return <Outlet />;
};

export default RpModule;
