import {
  makeStyles,
  createStyles,
  Theme,
  List,
  ListItem,
  Box,
  Paper,
  ListItemText,
  Typography,
} from "@material-ui/core";
import _ from "lodash";
import { DashboardActivitiesItem } from "../../../types/Dashboard.model";
import { getLocalDate, getLocalDateMonth } from "../../../utils/dateHelpers";

interface TeamActivitiesProps {
  data: DashboardActivitiesItem[];
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: "100%",
      backgroundColor: "white",
      borderRadius: "4px",
      boxShadow: "0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)",
    },
    inline: {
      display: "inline",
    },
    separation: {
      marginTop: theme.spacing(1),
      marginLeft: theme.spacing(2),  
      borderLeft: "4px solid #FF9357",
    },
    listTitle: {
      paddingTop: theme.spacing(1),
      paddingBottom: theme.spacing(1),
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
    },
  })
);

const TeamActivities = ({ data }: TeamActivitiesProps) => {
  const classes = useStyles();
  return (
    <List className={classes.root}>
      <Box className={classes.listTitle}>
        <Typography variant="subtitle2">Activités hors terrain</Typography>
      </Box>
      {data &&
        data.length > 0 &&
        data.map(({ date, activities }) => (
          <div key={date} style={{ width: "94%" }}>
            <Box className={classes.listTitle}>
              <Typography variant="subtitle2">{getLocalDate(date)}</Typography>
            </Box>
            {activities &&
              activities.length > 0 &&
              activities.map((item, index) => (
                <Paper key={item.activityId} className={classes.separation}>
                  <ListItem>
                    <ListItemText
                      primary={
                        _.capitalize(item.delegate.firstName) +
                        " " +
                        _.capitalize(item.delegate.lastName)
                      }
                      secondary={
                        <>
                          {"—"}
                          <br />
                          <Typography
                            component="span"
                            variant="body2"
                            className={classes.inline}
                            color="textSecondary"
                          >
                            {item.activityType.activityTypeName}
                          </Typography>
                          <br />
                          <Typography
                            component="span"
                            variant="body2"
                            className={classes.inline}
                            color="textSecondary"
                          >
                            {item.start !== item.end
                              ? getLocalDateMonth(item.start) +
                                "-" +
                                getLocalDateMonth(item.end)
                              : ""}
                          </Typography>
                        </>
                      }
                    />
                  </ListItem>
                </Paper>
              ))}
          </div>
        ))}
    </List>
  );
};

export default TeamActivities;
