import React from "react";
import { Tag as RsuiteTag, TagGroup } from "rsuite";
import { ProfileInfoItemPlaceholder } from "../../../02-molecules/placeholders/Placeholder";

type Props = {
  loading: boolean;
  tags: string[];
};

export const InfoItemTags = ({ loading, tags }: Props) => {
  return (
    <>
      {!loading ? (
        <div style={{ marginLeft: "3.25rem", marginTop: "1rem" }}>
          <TagGroup>
            {tags.map((item, index) => (
              <RsuiteTag key={index} size="lg">
                {item}
              </RsuiteTag>
            ))}
          </TagGroup>
        </div>
      ) : (
        <ProfileInfoItemPlaceholder rows={2} />
      )}
    </>
  );
};
