import { createSlice } from "@reduxjs/toolkit";
import { API_DEV } from "../../utils/apiRoute";
import { axiosPrivate } from "../../utils/interceptors";
import { createAsyncThunk } from "@reduxjs/toolkit";



interface FbyId {
    "facilityTypeId": number,
    "active": number,
    "facilityTypeName": string,
    "isUtility": boolean
}

const initialState: any = {
    Ftypes: [],
    FbyId: {} as FbyId
}



export const getAllFacilitiesTypesThunk = createAsyncThunk("[Ftypes]_getAllFacilitiesTypes_",
    async (_, { rejectWithValue }) => {
        try {
            let response = await axiosPrivate.get(`${API_DEV}/FacilityType`);
            // console.log(response.data)
            return response.data;
        } catch (error) {
            console.log(JSON.parse(JSON.stringify(error)));
            return rejectWithValue(JSON.parse(JSON.stringify(error)));
        }
    })

export const toggleFacility = createAsyncThunk("[Toggle]_ToggleFacility_",
    async (data, { rejectWithValue }) => {
        // console.log(data)
        try {
            let response: any = await axiosPrivate.put(`${API_DEV}/FacilityType/UpdateActive`, data);
            // console.log(response.data)
            return response.data;
        } catch (error: any) {
            console.log(JSON.parse(JSON.stringify(error)));
            return rejectWithValue(JSON.parse(JSON.stringify(error.response.data)));
        }
    })

export const CreateFacilityTypeThunk = createAsyncThunk("[create]_CreateFacilityTypeThunk_",
    async (data, { rejectWithValue }) => {
        try {
            let response:any = await axiosPrivate.post(`${API_DEV}/FacilityType`, data);
            return response.data;
        } catch (error: any) {
            console.log(JSON.parse(JSON.stringify(error)));
            return rejectWithValue(JSON.parse(JSON.stringify(error.response.data)));
        }
    })

export const getFacilityTypeByIdThunk = createAsyncThunk("[FacilityById]_getFacilityTypeByIdThunk_",
    async (id, { rejectWithValue }) => {
        try {
            let response = await axiosPrivate.get(`${API_DEV}/FacilityType/${id}`);
            return response.data;
        } catch (error: any) {
            console.log(JSON.parse(JSON.stringify(error)));
            return rejectWithValue(JSON.parse(JSON.stringify(error.response.data)));
        }
    })  

    
    export const updateFacilityTypeByIdThunk = createAsyncThunk("[update]_updateFacilityTypeByIdThunk_",
    async (data, { rejectWithValue }) => {
        try {
            let response = await axiosPrivate.put(`${API_DEV}/FacilityType`,data);
            return response.data;
        } catch (error: any) {
            console.log(JSON.parse(JSON.stringify(error)));
            return rejectWithValue(JSON.parse(JSON.stringify(error.response.data)));
        }
    })

export const facilityTypeSlice = createSlice({
    name: "[Ftypes]",
    initialState,
    reducers: {
    },
    extraReducers: (builder) => {
        builder.addCase(getAllFacilitiesTypesThunk.fulfilled, (state, { payload }) => {
            console.log(payload)
            state.Ftypes = payload
        });
        builder.addCase(toggleFacility.fulfilled, (state, { payload }) => {
            console.log(payload)
        }).addCase(toggleFacility.rejected, (state, { payload }) => {
            console.log(payload)
        })
        builder.addCase(CreateFacilityTypeThunk.fulfilled, (state, { payload }) => {
            console.log(payload)
        }).addCase(CreateFacilityTypeThunk.rejected, (state, { payload }) => {
            console.log(payload)
        })
        builder.addCase(getFacilityTypeByIdThunk.fulfilled, (state, { payload }) => {
            state.FbyId = payload
        }).addCase(getFacilityTypeByIdThunk.rejected, (state, { payload }) => {
            console.log(payload)
        })
        builder.addCase(updateFacilityTypeByIdThunk.fulfilled, (state, { payload }) => {
            console.log(payload)
        }).addCase(updateFacilityTypeByIdThunk.rejected, (state, { payload }) => {
            console.log(payload)
        })
        
        // getFacilityTypeByIdThunk
    },
});

export const { } = facilityTypeSlice.actions;// eslint-disable-line


export default facilityTypeSlice.reducer;
