import { useEffect, useState } from "react";
import { WeekContent } from "../../../types/WeekContent.model";

interface Props {
  weekContents: WeekContent[];
}
export const useActivityTracking = ({ weekContents }: Props) => {

  const [rows, setRows] = useState<any[]>([]);
  // console.log("weekContents", weekContents, requestState);
  const getRows = (weekContents: WeekContent[]) =>
    weekContents?.map((item) => ({
      id: item?.weekContentId || 0,
      createReportOn: item?.report?.createReportOn || "",  
      client: {
        title: item?.doctor?.title || "",
        firstName: item?.doctor?.firstName || "",
        lastName: item?.doctor?.lastName || "",
        doctorId: item?.doctor?.doctorId || "",
      },
      specialities: item?.doctor?.specialities?.map((spec) => ({
        abreviation: spec?.abreviation || "",
        specialityName: spec?.specialityName || "",
        specialityId: spec?.specialityId || 0,
        isPrincipal: spec?.isPrincipal || false,
      })) || [],
      potential: {
        potentialName: item?.doctor?.potential?.potentialName || "",
        potentialId: item?.doctor?.potential?.potentialId || 0,
      },
      principalAddress: {
        address: {
          streetName: item?.doctor?.principalAddress?.address?.streetName || "",
          addressNote: item?.doctor?.principalAddress?.address?.addressNote || "",
          localities: item?.doctor?.principalAddress?.address?.localities?.map(
            (loc) => ({
              localityId: loc?.localityId || 0,
              localityName: loc?.localityName || "",
              level: loc?.level,
              levelName: loc?.levelName || "",
              parent: null,
              subLocalities: null,
            })
          ) || [],
        },
      },
      channel: item.report?.channel?.channelId || 0, 
      weekContentStatus: item?.weekContentStatus || 0,
    })) || [];
  useEffect(() => {
    const data = getRows(weekContents);
    setRows(data);
  }, [weekContents]);

  return {
    rows,
  };
};
