import React from "react";
import { ProfileInfoItemPlaceholder } from "../../../02-molecules/placeholders/Placeholder";
import { mdiAt } from "@mdi/js";
import { StandardIcon } from "../../../01-atoms/Icons/Icons";

type Props = {
  email: string;
  loading: boolean;
};

export const InfoItemEmail = ({ email, loading }: Props) => {
  return (
    <>
      {!loading ? (
        <div className="email-item">
          <StandardIcon icon={mdiAt} />
          <dl className="content">
            <dt>{email || "N/A"}</dt>
          </dl>
        </div>
      ) : (
        <div className="info-item">
          <ProfileInfoItemPlaceholder withCircle />
        </div>
      )}
    </>
  );
};
