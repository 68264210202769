import React, { useEffect, useState, useRef } from "react";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import {
  applyTargetChange,
  closeTargetModal,
  selectDoctors,
} from "../../../redux/Target/targetSlice";
import { RootState } from "../../../redux/store";
import "./TargetModal.scss";

import { TargetWeek } from "../../../types/TargetWeek.model";
import TargetGroupBy from "./TargetGroupBy";
import { DoctorSummary } from "../../../types/DoctorSummary.model";
import {
  AppBar,
  createStyles,
  Dialog,
  makeStyles,
  Theme,
  Toolbar,
  Typography,
  Button,
  Grid,
} from "@material-ui/core";
import { NewTargetFilter } from "../filter/FilterDrawer/NewTargetFilter";
import errorToast from "../../../utils/errorToast";

type Props = {
  doctorSummary: DoctorSummary[];
  activeTargetWeek: TargetWeek | null;
};

const TargetModal = ({ activeTargetWeek, doctorSummary }: Props) => {
  const dispatch = useAppDispatch();
  const isSelectedDoctor = useRef<any[] | null>();
  const updateIsSelectDoctor = (items: any[]) => {
    isSelectedDoctor.current = items;
  };
  const { targetModalIsOpen, activeUpdatedState, selectedDoctors } =
    useAppSelector((state: RootState) => state.target);

  const [existingDoctors, setExistingDoctors] = useState<number[]>([]);
  useEffect(() => {
    if (activeTargetWeek) {
      dispatch(selectDoctors(activeTargetWeek.doctors.map((e) => e.doctorId)));
      setExistingDoctors(activeTargetWeek.doctors.map((doc) => doc.doctorId));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeTargetWeek]);

  const handleAddDoctor = () => {
    activeUpdatedState &&
      activeTargetWeek &&
      dispatch(
        applyTargetChange({
          ...activeUpdatedState,
          targetWeeks:
            activeUpdatedState &&
            activeUpdatedState.targetWeeks &&
            activeUpdatedState.targetWeeks.length > 0
              ? activeUpdatedState?.targetWeeks.map((e) => {
                  if (
                    e.targetWeekId === (activeTargetWeek?.targetWeekId || 0)
                  ) {
                    return {
                      ...e,
                      doctors: [
                        ...doctorSummary.filter((doc) =>
                          existingDoctors.includes(doc.doctorId)
                        ),
                        ...doctorSummary.filter((doc) =>
                          selectedDoctors.includes(doc.doctorId)
                        ),
                      ],
                    };
                  } else return e;
                })
              : [],
        })
      );
    dispatch(closeTargetModal());
  };

  const useStyles = makeStyles((theme: Theme) =>
    createStyles({
      appBar: {
        position: "relative",
        backgroundColor: "#181b24",
        color: "#efefef",
        height: theme.spacing(6),
      },
      toolBar: {
        height: theme.spacing(6),
        minHeight: theme.spacing(6),
      },
      title: {
        marginLeft: theme.spacing(2),
        flex: 1,
      },
    })
  );
  const classes = useStyles();

  return (
    <div className="target-modal">
      <Dialog
        fullScreen
        open={targetModalIsOpen}
        onClose={() => dispatch(closeTargetModal())}
        disableEnforceFocus
      >
        <AppBar className={classes.appBar}>
          <Toolbar className={classes.toolBar}>
            <Typography variant="body1" className={classes.title}>
              Recherche de médecins
            </Typography>
            <Button
              autoFocus
              color="inherit"
              onClick={() => {
                if (isSelectedDoctor.current) {
                  handleAddDoctor();
                } else {
                  errorToast("Aucun médecin n'a été sélectionner");
                }
              }}
            >
              Ajouter
            </Button>
            <Button
              color="inherit"
              onClick={() => dispatch(closeTargetModal())}
              aria-label="close"
            >
              Quitter
            </Button>
          </Toolbar>
        </AppBar>
        <Grid container spacing={0} style={{ padding: ".5rem" }}>
          <Grid item xs={3}>
            <NewTargetFilter />
          </Grid>
          <Grid item xs={9}>
            <TargetGroupBy
              updateIsSelectDoctor={updateIsSelectDoctor}
              activeTargetDoctors={
                activeTargetWeek &&
                activeTargetWeek.doctors &&
                activeTargetWeek.doctors.length
                  ? activeTargetWeek.doctors
                  : null
              }
            />
          </Grid>
        </Grid>
      </Dialog>
    </div>
  );
};

export default TargetModal;
