import { getFilteredSellers } from "./../Seller/sellerSlice";
import { getFilteredFacilities } from "../Facility/facilitySlice";
import { getFilteredDoctor } from "./../Doctor/doctorSlice";
import { getFilteredPharmacies } from "./../Pharmacy/pharmacySlice";
import { fetchProducts } from "../Products/Products";
import { ThunkDispatch } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import { AnyAction, Middleware } from "redux";
import {
  toggleRedirectToTargetPage,
  toggleAddDoctorDrawer,
  toggleAddfacilityDrawer,
  toggleAddPharmacyDrawer,
  toggleAddSellerDrawer,
  toggleUpdateDoctorDrawer,
  toggleUpdatefacilityDrawer,
  toggleUpdatePharmacyDrawer,
  toggleUpdatePhotoModal,
  toggleUpdateSellerDrawer,
  toggleRedirectToPlanificationPage,
  toggleAddAuthorizationDrawer,
} from "../UI/uiSlice";
import { fetchFilteredCycle } from "../Cycle/cycleSlice";
import { fetchCurrentUser } from "../User/userSlice";
import { getExternals } from "../Rp/rpSlice";
import { closeTargetModal } from "../Target/targetSlice";
import { closePlanificationConfigModal } from "../Planification/planificationSlice";
import { fetchFilteredObjection } from "../Objections/objectionSlice";
import { fetchFilteredRequest } from "../Request/requestSlice";
import { fetchAuthorizations } from "../Authorization/authorizationSlice";
import { toggleNavigateToValidationTable } from "../Approval/approvalSlice";
import { getThExpectedSalesTarget } from "../SalesTarget/salesTargetSlice";
import Cookies from "cookies-ts";
// import { getConnectedUserId } from "../../utils/getConnectedData";
import { getAllUsersThunk } from "../users/users";

const cookies = new Cookies();



export const successMiddleware: Middleware<
  {},
  unknown,
  ThunkDispatch<unknown, unknown, AnyAction>
> = (storeApi) => (next) => (action) => {
  const dispatch = (thunkAction: any) => storeApi.dispatch(thunkAction);
  const state: any = storeApi.getState();
  const successToast = (message: string) =>
    toast.success(message, {
      position: "bottom-right",
      autoClose: 5000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  next(action);

  switch (action.type) {
    case "[User]_updatePassword_/fulfilled":
      successToast("Mot de passe modifié avec succès!");
      break;
    case "[User]_updatePhoto/fulfilled":
      successToast("photo modifiée avec succès!");
      dispatch(toggleUpdatePhotoModal());
      dispatch(fetchCurrentUser());
      break;
    case "[User]_updateInfo_/fulfilled":
      successToast("Profil modifié avec succès");
      break;
    case "[Facilities]_add_/fulfilled": //to do
      successToast("Etablissement Ajouté avec succès");
      dispatch(
        getFilteredFacilities({
          search: {},
          orderBy: 0,
          pagination: {
            pageNumber: 1,
            pageSize: 10,
          },
        })
      );
      dispatch(toggleAddfacilityDrawer());
      break;
    case "[Facilities]_update_/fulfilled": //to do
      successToast("Etablissement Modifié avec succès");
      dispatch(
        getFilteredFacilities({
          search: {},
          orderBy: 0,
          pagination: {
            pageNumber: 1,
            pageSize: 10,
          },
        })
      );
      dispatch(toggleUpdatefacilityDrawer());
      break;
    case "[Facilities]_delete_/fulfilled": //to do
      successToast("Etablissement supprimé avec succès");
      dispatch(
        getFilteredFacilities({
          search: {},
          orderBy: 0,
          pagination: {
            pageNumber: 1,
            pageSize: 10,
          },
        })
      );
      break;
    case "[Facilities]_deleteRange_/fulfilled":
      successToast("Etablissement supprimées avec succès");
      dispatch(
        getFilteredFacilities({
          search: {},
          orderBy: 0,
          pagination: {
            pageNumber: 1,
            pageSize: 10,
          },
        })
      );
      break;
    case "[Pharmacies]_add_/fulfilled":
      successToast("Pharmacie Ajoutée avec succès");
      dispatch(
        getFilteredPharmacies({
          search: {},
          orderBy: 0,
          pagination: {
            pageNumber: 1,
            pageSize: 10,
          },
        })
      );
      dispatch(toggleAddPharmacyDrawer());
      break;
    case "[Pharmacies]_update_/fulfilled":
      successToast("Pharmacie Modifiée avec succès");
      dispatch(
        getFilteredPharmacies({
          search: {},
          orderBy: 0,
          pagination: {
            pageNumber: 1,
            pageSize: 10,
          },
        })
      );
      dispatch(toggleUpdatePharmacyDrawer());
      break;
    case "[Pharmacies]_delete_/fulfilled":
      successToast("Pharmacie supprimée avec succès");
      dispatch(
        getFilteredPharmacies({
          search: {},
          orderBy: 0,
          pagination: {
            pageNumber: 1,
            pageSize: 10,
          },
        })
      );
      break;
    case "[Pharmacies]_deleteRange_/fulfilled":
      successToast("Pharmacies supprimées avec succès");
      dispatch(
        getFilteredPharmacies({
          search: {},
          orderBy: 0,
          pagination: {
            pageNumber: 1,
            pageSize: 10,
          },
        })
      );
      break;
    case "[Sellers]_add_/fulfilled":
      successToast("Seller Ajoutée avec succès");
      dispatch(
        getFilteredSellers({
          search: {},
          orderBy: 0,
          pagination: {
            pageNumber: 1,
            pageSize: 10,
          },
        })
      );
      dispatch(toggleAddSellerDrawer());
      break;
    case "[Sellers]_update_/fulfilled":
      successToast("Seller Modifiée avec succès");
      dispatch(
        getFilteredSellers({
          search: {},
          orderBy: 0,
          pagination: {
            pageNumber: 1,
            pageSize: 10,
          },
        })
      );
      dispatch(toggleUpdateSellerDrawer());
      break;
    case "[Sellers]_delete_/fulfilled":
      successToast("Seller supprimée avec succès");
      dispatch(
        getFilteredSellers({
          search: {},
          orderBy: 0,
          pagination: {
            pageNumber: 1,
            pageSize: 10,
          },
        })
      );
      break;
    case "[Sellers]_deleteRange_/fulfilled":
      successToast("Sellers supprimées avec succès");
      dispatch(
        getFilteredSellers({
          search: {},
          orderBy: 0,
          pagination: {
            pageNumber: 1,
            pageSize: 10,
          },
        })
      );
      break;
    case "[Doctors]_add_/fulfilled":
      successToast("Médecin Ajoutée avec succès");
      dispatch(
        getFilteredDoctor({
          search: {},
          orderBy: 0,
          pagination: {
            pageNumber: 1,
            pageSize: 10,
          },
        })
      );
      dispatch(toggleAddDoctorDrawer());
      break;
    case "[Doctors]_update_/fulfilled":
      successToast("Médecin Modifiée avec succès");
      dispatch(
        getFilteredDoctor({
          search: {},
          orderBy: 0,
          pagination: {
            pageNumber: 1,
            pageSize: 10,
          },
        })
      );
      dispatch(toggleUpdateDoctorDrawer());
      break;
    case "[Doctors]_delete_/fulfilled":
      successToast("Médecin supprimée avec succès");
      dispatch(
        getFilteredDoctor({
          search: {},
          orderBy: 0,
          pagination: {
            pageNumber: 1,
            pageSize: 10,
          },
        })
      );
      break;
    case "[Doctors]_deleteRange_/fulfilled":
      successToast("Médecins supprimés avec succès");
      dispatch(
        getFilteredDoctor({
          search: {},
          orderBy: 0,
          pagination: {
            pageNumber: 1,
            pageSize: 10,
          },
        })
      );
      break;
    case "[Cycles]_add_/fulfilled":
      successToast("Cycle Ajouté avec succès");
      dispatch(fetchFilteredCycle(state.cycle.globalFilterState));
      break;
    case "[Cycles]_delete_/fulfilled":
      successToast("Cycle supprimé avec succès");
      dispatch(fetchFilteredCycle(state.cycle.globalFilterState));
      break;
    case "[Cycles]_update_/fulfilled":
      successToast("Cycle modifié avec succès");
      dispatch(fetchFilteredCycle(state.cycle.globalFilterState));
      break;
    case "[Cycles]__associateDelegates__/fulfilled":
      successToast("Délégués associés avec succès");
      dispatch(fetchFilteredCycle(state.cycle.globalFilterState));
      break;
    case "[Cycles]__dissociateDelegates__/fulfilled":
      successToast("Délégué dissocié avec succès");
      dispatch(fetchFilteredCycle(state.cycle.globalFilterState));
      break;
    case "[RpEvents]__addExternal__/fulfilled":
      dispatch(getExternals());
      break;
    case "[Targets]__prepareTarget__/fulfilled":
      dispatch(toggleRedirectToTargetPage(true));
      break;
    case "[Targets]__assignDoctorsToTarget__/fulfilled":
      successToast("Ciblage sauvegardé avec succès");
      dispatch(closeTargetModal());
      break;

    case "[Planifications]__createPlanification__/fulfilled":
      successToast("planification sauvegardé avec succès");
      dispatch(toggleRedirectToPlanificationPage(true));
      dispatch(closePlanificationConfigModal());
      break;
    case "[Objection]_updateResponse_/fulfilled":
      successToast("Reponse ajoutée avec succès");
      dispatch(
        fetchFilteredObjection({
          search: {
            searchText: "",
            isClosed: null,
            startDate: null,
            endDate: null,
            satisfactionLevel: [],
            productId: null,
            userId: null,
            doctorId: null,
            sellerId: null,
            pharmacyId: null,
          },
          orderBy: 0,
          pagination: {
            pageNumber: 1,
            pageSize: 10,
          },
        })
      );
      break;
    case "[Request]_updateResponse_/fulfilled":
      successToast("Reponse ajoutée avec succès");
      dispatch(
        fetchFilteredRequest({
          search: {
            searchText: "",
            isClosed: null,
            startDate: null,
            endDate: null,
            priorities: [],
            userId: null,
            doctorId: null,
            sellerId: null,
            pharmacyId: null,
          },
          orderBy: 0,
          pagination: {
            pageNumber: 1,
            pageSize: 10,
          },
        })
      );
      break;
    case "[Authorization]_add_/fulfilled":
      successToast("Autorisation ajoutée avec succès");
      dispatch(toggleAddAuthorizationDrawer());
      dispatch(fetchAuthorizations());
      break;
    case "[Authorization]_active_/fulfilled":
      successToast("Autorisation activée avec succès");
      dispatch(fetchAuthorizations());
      break;
    case "[Authorization]_deactive_/fulfilled":
      successToast("Autorisation désactivée avec succès");
      dispatch(fetchAuthorizations());
      break;

    case "[Approvals]_ApproveRequest_/fulfilled":
      successToast("Demande approuvée");
      dispatch(toggleNavigateToValidationTable(true));
      break;

    case "[Approvals]_RejectRequest_/fulfilled":
      successToast("Demande rejetée");
      dispatch(toggleNavigateToValidationTable(true));
      break;
    case "[Planifications]_update_/fulfilled":
      successToast("Planification modifiée avec succès");
      break;
    case "[SalesTarget]_AddSalesTarget_/fulfilled":
      let salesTargetGlobaleState1 = cookies.get("salesTargetFilter");
      successToast("Objectif de vente ajouté avec succès");
      dispatch(getThExpectedSalesTarget(salesTargetGlobaleState1));
      break;
    case "[SalesTarget]_deleteSalesTarget_/fulfilled":
      let salesTargetGlobaleState2 = cookies.get("salesTargetFilter");
      successToast("Objectif de vente supprimé avec succès");
      dispatch(getThExpectedSalesTarget(salesTargetGlobaleState2));
      break;
    case "[SalesTarget]_updateSalesTarget_/fulfilled":
      let salesTargetGlobaleState = cookies.get("salesTargetFilter");
      successToast("Objectif de vente modifié avec succès");
      dispatch(getThExpectedSalesTarget(salesTargetGlobaleState));
      break;
    case "[Products]_addProduct_/fulfilled":
      successToast("Produit ajouté avec succès");
      dispatch(fetchProducts());
      break;
    case "[Products]_ToggleProd_/fulfilled":
      successToast("Statut Produit Modifié avec succès");
      dispatch(fetchProducts());
      break;
    case "[Users]_createUser_/fulfilled":
      successToast("Utilisateur ajouté avec succès");
      dispatch(getAllUsersThunk())
      break;
    case "[Toggle]_ToggleFacility_/fulfilled":
      successToast("Statut modifié  avec succes");
      break;
    case "[create]_CreateFacilityTypeThunk_/fulfilled":
      successToast("Type d'etablissement ajouté avec succés");
      break;
    case "[spec]_CreateSpecThunk_/fulfilled":
      successToast("specialité ajoutée avec succés");
      break;
    case "[update]_updateFacilityTypeByIdThunk_/fulfilled":
      successToast("Type Établissement modifié avec succés");
      break;
    case "[spec]_updateSpecByIdThunk_/fulfilled":
      successToast("specialité modifiée avec succés");
      break;
    case "[spec]_deleteSpec_/fulfilled":
      successToast("specialité supprimée avec succés");
      break;
    case "[Users]_DisableUser_/fulfilled":
      successToast("Statut utilisateur modifié avec succès");
      break;
    case "[EADV]_uplaodeadv_/fulfilled":
      successToast("le fichier a été televersé avec succès");
      break;


    // [spec]_updateSpecByIdThunk_
    default:
      break;
  }
};
