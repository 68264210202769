import { createSlice } from "@reduxjs/toolkit";
import { API_DEV } from "../../utils/apiRoute";
import { axiosPrivate } from "../../utils/interceptors";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { Visit } from "../../types/Visit.model";
import { Pagination } from "../../types/Pagination.model";
import { FreeSampleListToBeExported, Report } from "../../types/Report.model";
import { ReportSummary } from "../../types/ReportSummary.model";

interface VisitsGlobalFilter {
  search: {
    searchText: string;
    startDate: string;
    endDate: string;
    doctorId: number | null;
    pharmacyId: number | null;
    sellerId: number | null;
    delegateId: number | null;
    filterBy: number | null;
    isClosed: boolean;
    isVisit: boolean;
  };
  pagination: {
    pageNumber: number;
    pageSize: number;
  };
}
interface filterPayload {
  id: number;
  startDate: null | string;
  endDate: null;
  pagination: {
    pageNumber: number;
    pageSize: number;
  };
}
export interface VisitState {
  requestState: "loading" | "idle";
  requestStateVisit: "loading" | "idle";
  visits: ReportSummary[];
  totalVisits: number;
  lastVisitDate: string;
  userVisits: {
    content: ReportSummary[];
    pagination: Pagination;
  };
  sellerVisites: ReportSummary[];
  doctorVisits: {
    content: ReportSummary[];
    pagination: Pagination;
  };
  externalVisits: Visit[];
  visitById: Report | null;
  filteredVisits: {
    content: ReportSummary[];
    pagination: Pagination;
  };
  filteredVisitsToExport: {
    content: ReportSummary[];
    pagination: Pagination;
  };
  globalFilterState: VisitsGlobalFilter;
  errors: any;
  SampleList: FreeSampleListToBeExported[];
}

export const fetchAllVisits = createAsyncThunk(
  "[Visits]__fetchAll__",
  async (payload: any, { rejectWithValue }) => {
    try {
      const response = await axiosPrivate.post(
        `${API_DEV}/Report/getAll`,
        payload
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(JSON.parse(JSON.stringify(error)));
    }
  }
);

export const fecthToBeExportedReportList = createAsyncThunk(
  "[Visits]__fecthToBeExportedReportList__",
  async (payload: any, { rejectWithValue }) => {
    try {
      const response = await axiosPrivate.post(
        `${API_DEV}/Report/getAll`,
        payload
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(JSON.parse(JSON.stringify(error)));
    }
  }
);
export const fetchUserVisits = createAsyncThunk(
  "[Visits]__fetchUserVisits__",
  async (payload: filterPayload, { rejectWithValue }) => {
    try {
      const response = await axiosPrivate.post(
        `${API_DEV}/Report/Users`,
        payload
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(JSON.parse(JSON.stringify(error)));
    }
  }
);
export const fetchDoctorVisits = createAsyncThunk(
  "[Visits]__fetchDoctorVisits__",
  async (payload: filterPayload, { rejectWithValue }) => {
    try {
      const response = await axiosPrivate.post(`${API_DEV}/Report/Doctor`, payload);
      return response.data;
    } catch (error) {
      return rejectWithValue(JSON.parse(JSON.stringify(error)));
    }
  }
);

//to do, we don't have the api yet
export const fetchSellerVisits = createAsyncThunk(
  "[Visits]__fetchSellerVisits__",
  async (id: number, { rejectWithValue }) => {
    try {
      const response = await axiosPrivate.get(`${API_DEV}/Report/Seller/${id}`);
      return response.data;
    } catch (error) {
      return rejectWithValue(JSON.parse(JSON.stringify(error)));
    }
  }
);

export const fetchExternalVisits = createAsyncThunk(
  "[Visits]__fetchExternalVisits__",
  async (id: string, { rejectWithValue }) => {
    try {
      const response = await axiosPrivate.get(
        `${API_DEV}/Visit/External/${id}`
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(JSON.parse(JSON.stringify(error)));
    }
  }
);
export const fetchVisitById = createAsyncThunk(
  "[Visits]__fetchVisitById__",
  async (id: number, { rejectWithValue }) => {
    try {
      const response = await axiosPrivate.get(`${API_DEV}/Report/${id}`);
      return response.data;
    } catch (error) {
      return rejectWithValue(JSON.parse(JSON.stringify(error)));
    }
  }
);

export const fetchFilteredVisits = createAsyncThunk(
  "[Visits]__fetchFilteredVisits__",
  async (payload: VisitsGlobalFilter, { rejectWithValue }) => {
    try {
      const response = await axiosPrivate.post(
        `${API_DEV}/Visit/GetAllWithFilters`,
        payload
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(JSON.parse(JSON.stringify(error)));
    }
  }
);

export const fetchFreeSampleList = createAsyncThunk(
  "[Visits]__fetchFreeSampleList__",
  async (payload: any, { rejectWithValue }) => {
    try {
      const response = await axiosPrivate.post(
        `${API_DEV}/Product/Echantillons`,
        payload
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(JSON.parse(JSON.stringify(error)));
    }
  }
);

const initialState: VisitState = {
  requestState: "idle",
  requestStateVisit: "idle",
  lastVisitDate: "",
  totalVisits: 0,
  visits: [],
  userVisits: {
    content: [],
    pagination: {
      pageNumber: 1,
      pageSize: 10,
      totalCount: 0,
      totalPages: 0,
    },
  },
  doctorVisits: {
    content: [],
    pagination: {
      pageNumber: 1,
      pageSize: 10,
      totalCount: 0,
      totalPages: 0,
    },
  },
  sellerVisites: [],
  externalVisits: [],
  visitById: null,
  filteredVisitsToExport: {
    content: [],
    pagination: {
      pageNumber: 1,
      pageSize: 10,
      totalCount: 0,
      totalPages: 0,
    },
  },
  filteredVisits: {
    content: [],
    pagination: {
      pageNumber: 1,
      pageSize: 10,
      totalCount: 0,
      totalPages: 0,
    },
  },
  globalFilterState: {
    search: {
      searchText: "",
      startDate: "",
      endDate: "",
      doctorId: null,
      pharmacyId: null,
      sellerId: null,
      delegateId: null,
      filterBy: null,
      isClosed: false,
      isVisit: false,
    },
    pagination: {
      pageNumber: 1,
      pageSize: 10,
    },
  },
  errors: null,
  SampleList: [],
};

export const visitSlice = createSlice({
  name: "[Visits]",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchAllVisits.pending, (state) => {
      state.requestState = "loading";
      state.errors = null;
      state.filteredVisits = {
        content: [],
        pagination: {
          pageNumber: 1,
          pageSize: 10,
          totalCount: 0,
          totalPages: 0,
        },
      };
    });
    builder.addCase(fetchAllVisits.fulfilled, (state, { payload }) => {
      state.requestState = "idle";
      state.filteredVisits = payload;
      state.errors = null;
    });
    builder.addCase(fetchAllVisits.rejected, (state, { payload }) => {
      state.requestState = "idle";
      state.errors = payload;
    });
    builder.addCase(fetchUserVisits.pending, (state) => {
      state.requestState = "loading";
      state.requestStateVisit = "loading";
      state.errors = null;
    });
    builder.addCase(fetchUserVisits.fulfilled, (state, { payload }) => {
      state.requestState = "idle";
      state.requestStateVisit = "idle";
      state.userVisits = payload;
      state.totalVisits = payload && payload.length;
      state.lastVisitDate = payload && payload.length && payload[0].date;
      state.errors = null;
    });
    builder.addCase(fetchUserVisits.rejected, (state, { payload }) => {
      state.requestState = "idle";
      state.requestStateVisit = "idle";
      state.errors = payload;
    });
    builder.addCase(fetchDoctorVisits.pending, (state) => {
      state.requestState = "loading";
      state.requestStateVisit = "loading";
      state.errors = null;
    });
    builder.addCase(fetchDoctorVisits.fulfilled, (state, { payload }) => {
      state.requestState = "idle";
      state.requestStateVisit = "idle";
      state.doctorVisits = payload;
      state.totalVisits = payload && payload.length;
      state.lastVisitDate = payload && payload.length && payload[0].date;
      state.errors = null;
    });
    builder.addCase(fetchDoctorVisits.rejected, (state, { payload }) => {
      state.requestState = "idle";
      state.requestStateVisit = "idle";
      state.errors = payload;
    });
    builder.addCase(fetchSellerVisits.pending, (state) => {
      state.requestState = "loading";
      state.requestStateVisit = "loading";
      state.errors = null;
    });
    builder.addCase(fetchSellerVisits.fulfilled, (state, { payload }) => {
      state.requestState = "idle";
      state.requestStateVisit = "idle";
      state.sellerVisites = payload;
      state.totalVisits = payload && payload.length;
      state.lastVisitDate = payload && payload.length && payload[0].date;
      state.errors = null;
    });
    builder.addCase(fetchSellerVisits.rejected, (state, { payload }) => {
      state.requestState = "idle";
      state.requestStateVisit = "idle";
      state.errors = payload;
    });
    builder.addCase(fetchExternalVisits.pending, (state) => {
      state.requestState = "loading";
      state.errors = null;
    });
    builder.addCase(fetchExternalVisits.fulfilled, (state, { payload }) => {
      state.requestState = "idle";
      state.externalVisits = payload;
      state.errors = null;
    });
    builder.addCase(fetchExternalVisits.rejected, (state, { payload }) => {
      state.requestState = "idle";
      state.errors = payload;
    });
    builder.addCase(fetchVisitById.pending, (state) => {
      state.requestState = "loading";
      state.errors = null;
    });
    builder.addCase(fetchVisitById.fulfilled, (state, { payload }) => {
      state.requestState = "idle";
      state.visitById = payload;
      state.errors = null;
    });
    builder.addCase(fetchVisitById.rejected, (state, { payload }) => {
      state.requestState = "idle";
      state.errors = payload;
    });
    builder.addCase(fetchFilteredVisits.pending, (state) => {
      state.requestState = "loading";
      state.errors = null;
    });
    builder.addCase(fetchFilteredVisits.fulfilled, (state, { payload }) => {
      state.requestState = "idle";
      state.filteredVisits = payload;
      state.errors = null;
    });
    builder.addCase(fetchFilteredVisits.rejected, (state, { payload }) => {
      state.requestState = "idle";
      state.errors = payload;
    });
    builder.addCase(fetchFreeSampleList.pending, (state) => {
      state.requestState = "loading";
      state.SampleList = [];
      state.errors = null;
    });
    builder.addCase(fetchFreeSampleList.fulfilled, (state, { payload }) => {
      state.requestState = "idle";
      state.SampleList = payload;
      state.errors = null;
    });
    builder.addCase(fetchFreeSampleList.rejected, (state, { payload }) => {
      state.requestState = "idle";
      state.errors = payload;
    });
    builder.addCase(fecthToBeExportedReportList.pending, (state) => {
      state.requestState = "loading";
      state.errors = null;
    });
    builder.addCase(
      fecthToBeExportedReportList.fulfilled,
      (state, { payload }) => {
        state.requestState = "idle";
        state.filteredVisitsToExport = payload;
        state.errors = null;
      }
    );
    builder.addCase(
      fecthToBeExportedReportList.rejected,
      (state, { payload }) => {
        state.requestState = "idle";
        state.errors = payload;
      }
    );
  },
});

export default visitSlice.reducer;
