import { Seller } from "./../../../../types/Seller.model";
import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../../redux/hooks";
import { RootState } from "../../../../redux/store";
import {
  toggleAddSellerDrawer,
  toggleUpdateSellerDrawer,
} from "../../../../redux/UI/uiSlice";
import { Tag } from "../../../../types/Tag.model";
import {
  prepareBusinessUnitsOptions,
  preparePotentialsOptions,
  prepareSellerTypesOptions,
  prepareSpecialitiesOptions,
  prepareTagsOptions,
} from "../../../../helpers/GlobalFormHelpers";
// import { getDefaultLocalitiesOptions } from "../../../../redux/Locality/localitySlice";
import { Phone } from "../../../../types/Phone.model";
import { Locality } from "../../../../types/Locality.model";
import { getConnectedUserBu } from "../../../../utils/getConnectedData";

export interface SellerFormInputs {
  sellerId: number | null;
  sellerName: string;
  reference: string | null;
  email: string | null;
  webSite: string | null;
  potentialId: number | null;
  sellerTypeId: number | null;
  shift: number | null;
  tags: (number | string)[];
  phones: Phone[];
  businessUnits: number[];
  address: {
    addressId: number | null;
    streetName: string;
    addressNote: string | null;
    localities: Locality[];
  };
}

export const prepareSellerFormPayload = (formOutput: SellerFormInputs) => {
  return {
    sellerId: formOutput.sellerId,
    sellerName: formOutput.sellerName,
    reference: formOutput.reference,
    website: formOutput.webSite,
    email: formOutput.email,
    shift: formOutput.shift,
    sellerType: { sellerTypeId: formOutput.sellerTypeId },
    potential: { potentialId: formOutput.potentialId },
    businessUnits: formOutput.businessUnits.map((e) => ({ businessUnitId: e })),
    phones: formOutput.phones,
    address: {
      addressId: formOutput.address?.addressId,
      streetName: formOutput.address?.streetName,
      addressNote: formOutput.address?.addressNote,
      localities: formOutput.address?.localities,
    },
    tags: formOutput?.tags?.map((tag) => {
      return typeof tag === "number" ? { tagId: tag } : { tagName: tag };
    })|| [],
  };
};

export const fieldIsRequired = "Ce champ est requis";
type Props = {
  formContext: "add" | "update";
};
type FormOptions = {
  potentialsOptions: {
    key: number;
    label: string;
    value: number | string;
  }[];
  businessUnitsOptions: {
    key: number;
    label: string;
    value: number | string;
  }[];
  specialitiesOptions: {
    key: number;
    label: string;
    value: number | string;
  }[];
  tagsOptions: {
    key: number;
    label: string;
    value: number | string;
  }[];

  sellerTypeOptions: {
    key: number;
    label: string;
    value: number | string;
  }[];

  shiftOptions: {
    key: number;
    label: string;
    value: number | string;
  }[];
};

const useSellerForm = ({ formContext }: Props) => {
  const dispatch = useAppDispatch();
  const { addSellerDrawerIsOpen, updateSellerDrawerIsOpen } = useAppSelector(
    (state: RootState) => state.UI
  );
  const { sellerById } = useAppSelector((state: RootState) => state.seller);
  const { specialities, businessUnits, tags, potentials, sellerTypes } =
    useAppSelector((state: RootState) => state.general);

  const [initialValues, setInitialValues] = useState<SellerFormInputs>({
    sellerId: null,
    sellerName: "",
    reference: null,
    email: null,
    potentialId: null,
    tags: [],
    phones: [
      {
        phoneId: null,
        phoneType: null,
        phoneNumber: "",
        phoneDescription: "",
        isPrincipal: true,
      },
    ],
    address: {
      addressId: null,
      streetName: "",
      addressNote: "",
      localities: [],
    },
    businessUnits: [],
    shift: 0,
    sellerTypeId: null,
    webSite: null,
  });

  const [formIsReady, setFormIsReady] = useState(false);
  const [formOptions, setFormOptions] = useState<FormOptions | null>(null);

  //Methods
  const defineFormUI: (formCtx: "add" | "update") => {
    formIsOpen: boolean;
    toggleForm: () => unknown;
  } = (formCtx: "add" | "update") => {
    switch (formCtx) {
      case "add":
        return {
          formIsOpen: addSellerDrawerIsOpen,
          toggleForm: () => dispatch(toggleAddSellerDrawer()),
        };

      case "update":
        return {
          formIsOpen: updateSellerDrawerIsOpen,
          toggleForm: () => dispatch(toggleUpdateSellerDrawer()),
        };

      default:
        return {
          formIsOpen: false,
          toggleForm: () => null,
        };
    }
  };

  const defineInitialValues = (
    formContext: "add" | "update",
    sellerRecord: Seller | null
  ): SellerFormInputs => {
    const sellerInitialRawData = formContext === "update" ? sellerRecord : null;
    return {
      sellerId: sellerInitialRawData?.sellerId || null,
      sellerName: sellerInitialRawData?.sellerName || "",
      webSite: sellerInitialRawData?.website || "",
      reference: sellerInitialRawData?.reference || null,
      email: sellerInitialRawData?.email || "",
      shift: sellerInitialRawData?.shift || 0,
      potentialId: sellerInitialRawData?.potential?.potentialId || null,
      sellerTypeId: sellerInitialRawData?.sellerType?.sellerTypeId || null,
      tags: sellerInitialRawData?.tags.map((tag: Tag) => tag.tagId) || [],
      phones: sellerInitialRawData?.phones || [
        {
          phoneId: null,
          phoneType: null,
          phoneNumber: "",
          phoneDescription: "",
          isPrincipal: true,
        },
      ],

      address: {
        addressId: sellerInitialRawData?.address?.addressId || null,
        localities: sellerInitialRawData?.address?.localities && sellerInitialRawData?.address?.localities?.length >0 
          ? [...sellerInitialRawData.address.localities].sort(
              (a, b) => a.level - b.level
            )
          : [],
        streetName: sellerInitialRawData?.address?.streetName || "",
        addressNote: sellerInitialRawData?.address?.addressNote || null,
      },
      businessUnits:
        sellerInitialRawData?.businessUnits?.map((e) => e.businessUnitId) || getConnectedUserBu(),
    };
  };

  //Global data preparation

  useEffect(() => {
    const potentialsOptions = preparePotentialsOptions(potentials);
    const businessUnitsOptions = prepareBusinessUnitsOptions(businessUnits);
    const specialitiesOptions = prepareSpecialitiesOptions(specialities); 
    const tagsOptions = prepareTagsOptions(tags);    
    const sellerTypeOptions = prepareSellerTypesOptions(sellerTypes);
    const shiftOptions = [
      { key: 0, label: "Jour", value: 0 },
      { key: 1, label: "Nuit", value: 1 },
    ];
    if (
      potentialsOptions.length > 0 &&
      businessUnitsOptions.length > 0 &&
      specialitiesOptions.length > 0
    ) {
      setFormOptions({
        potentialsOptions,
        businessUnitsOptions,
        specialitiesOptions,
        tagsOptions,
        sellerTypeOptions,
        shiftOptions,
      });
    }
  }, [businessUnits, potentials, specialities, tags, sellerTypes]);

  useEffect(() => {
    if (formContext === "add") {
      formOptions ? setFormIsReady(true) : setFormIsReady(false);
    } else {
      sellerById && formOptions ? setFormIsReady(true) : setFormIsReady(false);
    }
  }, [formIsReady, formOptions, sellerById, formContext]);

  //Methods execution
  const formUI = defineFormUI(formContext);

  useEffect(() => {
    const init = defineInitialValues(formContext, sellerById);
    setInitialValues(init);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sellerById, formContext]);

  return { initialValues, formUI, formIsReady, formOptions, setFormIsReady };
};

export default useSellerForm;
