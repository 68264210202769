import { Divider, IconButton, Tooltip } from "@material-ui/core";
import {
  mdiContentCopy,
  mdiContentCut,
  mdiContentPaste,
  mdiEraser,
  mdiMapMarkerOutline,
  mdiPencil,
  mdiPlus,
  mdiRedo,
  mdiUndo,
} from "@mdi/js";
import { Button, ButtonGroup } from "rsuite";
import { useAppDispatch } from "../../../redux/hooks";
import { togglePlanificationConfigModal } from "../../../redux/Planification/planificationSlice";
import { toggleTargetModal } from "../../../redux/Target/targetSlice";

import { Frequency } from "../../../types/Frequency.model";
import { Target } from "../../../types/Target.model";
import { TargetWeek } from "../../../types/TargetWeek.model";
import { CustomIconButton } from "../../01-atoms/Buttons/Buttons";
import { StandardIcon } from "../../01-atoms/Icons/Icons";
import "./TargetSubheader.scss";
type Props = {
  userAddress: string;
  activeUpdatedState: Target | null;
  activeWeek: TargetWeek | null;
  innerSelected: number[];
  setInnerSelected: React.Dispatch<React.SetStateAction<number[]>>;
  cycleConfig: {
    label: string;
    frequencies: Frequency[];
    weeksNumber: number | null;
  };
  handleCopy: (innerWeekSelection: number[]) => void;
  handleCut: (
    activeTargetWeek: TargetWeek,
    innerWeekSelection: number[]
  ) => void;
  handlePaste: (activeTargetWeek: TargetWeek) => void;
  handleReset: (activeTargetWeek: TargetWeek) => void;
  handleRedo: () => void;
  handleUndo: () => void;
  handleAssign: () => void;
  redoIsActive: boolean;
  undoIsActive: boolean;
  assignDone: boolean;
  requestState: "idle" | "loading";
};

const TargetSubheader = ({
  userAddress,
  cycleConfig,
  activeWeek,
  handleCopy,
  handleCut,
  handlePaste,
  handleReset,
  innerSelected,
  setInnerSelected,
  handleRedo,
  handleUndo,
  redoIsActive,
  undoIsActive,
  handleAssign,
  assignDone,
  activeUpdatedState,
  requestState,
}: Props) => {
  const dispatch = useAppDispatch();
  return (
    <div className="target-subheader">
      <div className="target-subheader--left">
        <StandardIcon icon={mdiMapMarkerOutline} />
        <div className="userAddress">{userAddress}</div>
      </div>
      <div className="target-subheader--right">
        <div className="week-infos-area">
          <div className="week-number">S{activeWeek?.weekNumber || "-"}</div>
          <div className="week-number">Total {activeWeek?.doctors.length}</div>
        </div>
        <div className="week-actions-button">
          <ButtonGroup className="week-button__inner">
            <Tooltip title="Vider la semaine actuelle">
              <IconButton size="small">
                <CustomIconButton
                  icon={mdiEraser}
                  onClick={() => {
                    activeWeek && handleReset(activeWeek);
                  }}
                />
              </IconButton>
            </Tooltip>
            <Divider
              orientation="vertical"
              flexItem
              style={{ marginLeft: "1rem", marginRight: "1rem" }}
            />
            <Tooltip title="Couper">
              <IconButton size="small">
                <CustomIconButton
                  icon={mdiContentCut}
                  onClick={() => {
                    setInnerSelected([]);
                    activeWeek && handleCut(activeWeek, innerSelected);
                  }}
                />
              </IconButton>
            </Tooltip>
            <Tooltip title="Copier">
              <IconButton size="small">
                <CustomIconButton
                  icon={mdiContentCopy}
                  onClick={() => {
                    handleCopy(innerSelected);
                  }}
                />
              </IconButton>
            </Tooltip>
            <Tooltip title="Coller">
              <IconButton size="small">
                <CustomIconButton
                  icon={mdiContentPaste}
                  onClick={() => {
                    activeWeek && handlePaste(activeWeek);
                  }}
                />
              </IconButton>
            </Tooltip>
            <Tooltip title="Modifier">
              <IconButton size="small">
                <CustomIconButton
                  icon={
                    activeWeek && activeWeek.doctors.length === 0
                      ? mdiPlus
                      : mdiPencil
                  }
                  onClick={() => {
                    dispatch(toggleTargetModal());
                  }}
                />
              </IconButton>
            </Tooltip>

            <Divider
              orientation="vertical"
              flexItem
              style={{ marginLeft: "1rem", marginRight: "1rem" }}
            />
            <Tooltip title="Annuler">
              <IconButton size="small">
                <CustomIconButton
                  icon={mdiUndo}
                  disabled={!undoIsActive}
                  onClick={() => {
                    handleUndo();
                  }}
                />
              </IconButton>
            </Tooltip>
            <Tooltip title="Refaire">
              <IconButton size="small">
                <CustomIconButton
                  icon={mdiRedo}
                  disabled={!redoIsActive}
                  onClick={() => {
                    handleRedo();
                  }}
                />
              </IconButton>
            </Tooltip>

            <Divider
              orientation="vertical"
              flexItem
              style={{ marginLeft: "1rem", marginRight: "1rem" }}
            />
          </ButtonGroup>
          <Button
            className="database-submit-button"
            type="submit"
            appearance="primary"
            onClick={() => {
              if (!assignDone && activeUpdatedState) {
                handleAssign();
              } else {
                dispatch(togglePlanificationConfigModal());
              }
            }}
            loading={requestState !== "idle"}
          >
            {assignDone ? "Passer à la planification" : "Sauvegarder"}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default TargetSubheader;
