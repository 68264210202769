import { useAppDispatch } from "../../../../redux/hooks";
import { useEffect, useState } from "react";
import {
  getBiObjectionData,
  getBiRequestData,
  getBiVisitData,
} from "../../../../redux/Bi/biSlice";
import { getCurrentMonthToCurrentDay, getdateWithFormatedtTimeZoneToZero } from "../../../../utils/dateHelpers";

 interface UseFilterObjectionProps {
  context: string;
}
type BiFilterData = {
  date: [Date, Date] | undefined; 
};
export type GlobalBiFilterState = {
  startDate: string | Date | null;
  endDate: string | Date | null;
};
export const useFilterBi = ({ context }: UseFilterObjectionProps) => {
  const dispatch = useAppDispatch();
  const [globalFilterState, setGlobalFilterState] =
    useState<GlobalBiFilterState>(getCurrentMonthToCurrentDay());

  const captureFilter = (data: BiFilterData) => {
    const { date } = data;
    setGlobalFilterState({
      startDate: date ? getdateWithFormatedtTimeZoneToZero(date[0])  : "",
      endDate: date ? getdateWithFormatedtTimeZoneToZero(date[1]) : "", 
    });
  };

  useEffect(() => {
    // context === "objection" &&
    !globalFilterState.startDate &&
      setGlobalFilterState(getCurrentMonthToCurrentDay());
    context === "objection" &&
      globalFilterState.startDate &&
      dispatch(getBiObjectionData(globalFilterState));
    context === "request" &&
      globalFilterState.startDate &&
      dispatch(getBiRequestData(globalFilterState));
    context === "visit" &&
      globalFilterState.startDate &&
      dispatch(getBiVisitData(globalFilterState));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [globalFilterState]);

  return {
    globalFilterState,
    captureFilter,
  };
};
