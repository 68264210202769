import { useEffect, useState } from "react";
import "./TargetGroupBy.scss";
import { RootState } from "../../../redux/store";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import { DoctorSummary } from "../../../types/DoctorSummary.model";
import { selectDoctors } from "../../../redux/Target/targetSlice";
import { DataGrid, GridColumns } from "@material-ui/data-grid";
import { createStyles, makeStyles, Theme } from "@material-ui/core";
import localizedTextsMap from "../../../utils/dataGridColumnMenuLanguage";
interface Props {
  activeTargetDoctors: DoctorSummary[] | null;
  updateIsSelectDoctor: (items: any[]) => void;
}
const TargetGroupBy = ({
  activeTargetDoctors,
  updateIsSelectDoctor,
}: Props) => {
  type sourceItem = {
    id: number;
    name: string;
    potential: string;
    speciality: string;
    specialityName: string;
    level1: string;
    level2: string;
    facilityName: string;
    facilityType: string;
  };
  const dispatch = useAppDispatch();
  const { filteredDoctorsSummaries, requestState } = useAppSelector(
    (state: RootState) => state.doctor
  );

  // Data formator
  const TargetDataFactory = (filteredDoctorsSummaries: DoctorSummary[]) => {
    if (
      !activeTargetDoctors &&
      filteredDoctorsSummaries &&
      filteredDoctorsSummaries.length
    ) {
      return filteredDoctorsSummaries.map((e) => ({
        id: e.doctorId,
        name: `${e.title} ${e.firstName} ${e.lastName}`,
        potential: e.potential?.potentialName || "",
        speciality: e.specialities[0]?.abreviation || "",
        specialityName: e.specialities[0]?.specialityName || "",
        facilityName: e.principalAddress?.facilityName || "",
        facilityType:
          e.principalAddress?.facilityType?.facilityTypeName || "Cabinet",
        level1: e.principalAddress?.address?.localities[0]?.localityName || "",
        level2: e.principalAddress?.address?.localities[1]?.localityName || "",
      }));
    } else if (
      filteredDoctorsSummaries &&
      filteredDoctorsSummaries.length &&
      activeTargetDoctors
    ) {
      return filteredDoctorsSummaries
        .filter((el) => {
          return !activeTargetDoctors.find((element) => {
            return element.doctorId === el.doctorId;
          });
        })
        .map((e) => ({
          id: e.doctorId,
          name: `${e.title} ${e.firstName} ${e.lastName}`,
          potential: e.potential?.potentialName || "",
          speciality: e.specialities[0]?.abreviation || "",
          specialityName: e.specialities[0]?.specialityName || "",
          facilityName: e.principalAddress?.facilityName || "",
          facilityType:
            e.principalAddress?.facilityType?.facilityTypeName || "Cabinet",
          level1:
            e.principalAddress?.address?.localities[0]?.localityName || "",
          level2:
            e.principalAddress?.address?.localities[1]?.localityName || "",
        }));
    } else {
      return [];
    }
  };
  const [source, setSource] = useState<sourceItem[]>([]);
  const [rowsPerPage, setRowsPerPage] = useState<number>(25);

  const columns: GridColumns = [
    {
      field: "name",
      headerName: "Nom et Prénom",
      minWidth: 300,
    },
    {
      field: "potential",
      headerName: "Pot.",
      width: 110,
      description: "Potentiel",
    },
    {
      field: "speciality",
      headerName: "Spéc.",
      width: 115,
      description: "Spécialité",
    },
    {
      field: "facilityType",
      headerName: "Type Etab.",
      width: 150,
      description: "Type d'établissement",
    },
    {
      field: "level1",
      headerName: "Gouv.",
      width: 180,
      description: "Gouvernorat",
    },
    {
      field: "level2",
      headerName: "Délég.",
      width: 180,
      description: "Délégation",
    },
    {
      field: "facilityName",
      headerName: "Etab.",
      minWidth: 300,
      description: "Etablissement",
    },
  ];
  const useStyles = makeStyles((theme: Theme) =>
    createStyles({
      table: {
        minHeight: `calc(100vh - 64px)`,
        maxHeight: `calc(100vh - 64px)`,
      },
    })
  );
  const classes = useStyles();
  useEffect(() => {
    const data = TargetDataFactory(filteredDoctorsSummaries);
    setSource(data);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filteredDoctorsSummaries]);

  return (
    <DataGrid
      loading={requestState === "loading"}
      headerHeight={52}
      rows={source}
      className={classes.table}
      columns={columns}
      pageSize={rowsPerPage}
      rowsPerPageOptions={[10, 25, 50, 100]}
      onPageSizeChange={(newPageSize) => setRowsPerPage(newPageSize)}
      checkboxSelection
      onSelectionModelChange={(selectionModel) => {
        dispatch(selectDoctors(selectionModel as number[]));
        updateIsSelectDoctor(selectionModel);
      }}
      localeText={localizedTextsMap}
    />
  );
};

export default TargetGroupBy;
