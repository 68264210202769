import React from "react";
import {
  DataGrid,
  GridColDef,
  GridValueGetterParams,
  GridCellParams,
} from "@material-ui/data-grid";
import "./AuthorizationManagementTable.scss";
import PersonCell from "../common/Cells/PersonCell";
import DateCell from "../common/Cells/DateCell";
import StatusToggleCell from "../common/Cells/StatusToggleCell";
import localizedTextsMap from "../../../../utils/dataGridColumnMenuLanguage";
import useAuthorizationManagementTable from "./useAuthorizationManagementTable";

const AuthorizationManagementTable = () => {
  const { data, dataResquestState, handleAuthorizationStatus } =
    useAuthorizationManagementTable();
  const columns: GridColDef[] = [
    { field: "id", headerName: "ID", width: 90, hide: true },
    {
      field: "delegate",
      headerName: "Délégué",
      width: 650,
      renderCell: (params: GridCellParams) => {
        return (
          <PersonCell
            item={JSON.stringify({
              name: `${params?.row?.delegate?.firstName || "-"} ${params?.row?.delegate?.lastName || ""}`,
              userId: params.row.delegate.userId,
              photoUrl: params.row.delegate.photoUrl,
            })}
          />
        );
      },
      valueGetter: (params: GridValueGetterParams) =>
        `${params.row.delegate.firstName} ${params.row.delegate.lastName}`,
    },
    {
      field: "startDate",
      headerName: "Date Début",
      minWidth: 200,
      flex: 1,
      resizable: true,
      renderCell: (params: GridCellParams) => {
        return <DateCell item={params.row.startDate} />;
      },
      valueGetter: (params: GridValueGetterParams) => params.row.startDate,
    },
    {
      field: "endDate",
      headerName: "DateFin",
      minWidth: 200,
      flex: 1,
      resizable: true,
      renderCell: (params: GridCellParams) => {
        return <DateCell item={params.row.endDate} />;
      },
      valueGetter: (params: GridValueGetterParams) => params.row.endDate,
    },
    {
      field: "expirationDate",
      headerName: "Date Expiration",
      minWidth: 200,
      flex: 1,
      resizable: true,
      renderCell: (params: GridCellParams) => {
        return <DateCell item={params.row.expirationDate} />;
      },
      valueGetter: (params: GridValueGetterParams) => params.row.expirationDate,
    },
    {
      field: "status",
      minWidth: 200,
      flex: 1,
      resizable: true,
      headerName: "Statut",
      // width: 200,
      renderCell: (params: GridCellParams) => {
        return (
          <StatusToggleCell
            item={params.row.isActive}
            isClicked={() => {
              handleAuthorizationStatus(params.row.isActive, params.row.id);
            }}
          />
        );
      },
      valueGetter: (params: GridValueGetterParams) => {
        if (params.row.isActive) {
          return "active";
        } else {
          return "inactive";
        }
      },
    },
  ];
  return (
    <div className="authorization-table__wrapper">
      <DataGrid
        rows={data}
        columns={columns}
        pageSize={8}
        rowsPerPageOptions={[7]}
        checkboxSelection={false}
        disableSelectionOnClick
        localeText={localizedTextsMap}
        loading={dataResquestState === "loading" ? true : false}
      />
    </div>
  );
};

export default AuthorizationManagementTable;
