import * as React from 'react';
import Close from '@material-ui/icons/Close';
import {  IconButton, DialogTitle } from '@material-ui/core';

export interface DialogTitleProps {
    id: string;
    children?: React.ReactNode;
    iconButtonStyle?: {};
    onClose: () => void; 
  }
  
export  const CustomizedDialogTitle = (props: DialogTitleProps) => {
    const { children, onClose,iconButtonStyle, ...other } = props;
  
    return (
      <DialogTitle style={{ margin: 0, padding: 2 }} {...other}>
        {children} 
        {onClose ? (
          <IconButton
            aria-label="close"
            onClick={onClose}
            style={iconButtonStyle}
          >
            <Close />
          </IconButton>
        ) : null}
      </DialogTitle>
    );
  };
  