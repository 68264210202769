import { RootState } from "../../../../redux/store";
import { useAppSelector } from "../../../../redux/hooks";
import { useMemo } from "react";
import { VisitDataSource, VisitsFrequenciesByDelegate, VisitsFrequenciesByDoctorSpecial, VisitsFrequenciesByGovernerat } from "../../../../types/VisitDataSource.model";
import { Potential } from "../../../../types/Potential.model";
import { Serie } from "../BiGeneral/useBiGeneral";

const prepareDaysCount = (data: VisitDataSource) => {
  return {
    total:
      data.dayInsights.daysByType?.reduce((prv, crr) => prv + crr.count, 0) ||
      0,
    workinDays:
      data.dayInsights.daysByType?.find(
        (el) => el.type === "WorkingDays" && el.count
      )?.count || 0,
    activityDays:
      data.dayInsights.daysByType?.find(
        (el) => el.type === "ActivityDays" && el.count
      )?.count || 0,
    holidays:
      data.dayInsights.daysByType?.find(
        (el) => el.type === "Holidays" && el.count
      )?.count || 0,
  };
};

const prepareVisitsByTypes = (data: VisitDataSource, criteria: string) => {
  let labels: string[] = [
    "Jours Terrain",
    "Jours Hors Terrain",
    "Jours Fériés",
  ];
  let series = data.dayInsights.daysByType?.map(({ count }) => count);
  return {
    labels: labels,
    series: series,
  };
};

const prepareVisitsByActivityTypes = (
  data: VisitDataSource,
  criteria: string
) => {
  let labels: string[] = data.dayInsights.activityDaysByType?.map(
    ({ activityTypeName }) => activityTypeName
  );
  let series = data.dayInsights.activityDaysByType?.map(({ count }) => count);
  return {
    labels: labels,
    series: series,
  };
};

const prepareVisitCount = (data: VisitDataSource) => {
  let globalFrequency: number  = data?.visitsInsights?.visitFrequencies?.globalFrequency ? data?.visitsInsights?.visitFrequencies?.globalFrequency/100  : 0
  return {
    totalVisit: data?.visitsInsights?.visitsCount || 0,
    frequenciesbyPotential: {
      total:( + globalFrequency?.toFixed(2) ) || 0,
      potential:
        data?.visitsInsights?.visitFrequencies?.frequenciesbyPotential?.map(
          (el) => ({
            potentialId: el.potentialId, 
            count: + el?.potentialFrequency?.toFixed(2) || 0,
            potentialName: el.potentialName,
          })
        ) || [], 
    },
    coveragePercentage: + data?.visitsInsights?.coveragePercentage?.toFixed(2) || 0,
  };
};

const prepareVisitCountByDelegate = (data: VisitsFrequenciesByDelegate,potentials:Potential[]) => {
   if (data && typeof data === "object" && data.length && potentials && potentials.length) {
    let seriesData : Serie[] = potentials.map(potential => {
      let serie : Serie = {
        name : `${potential.potentialName}`,
        data: data.map(portfolioPotential => {
          let result  = portfolioPotential?.frequenciesbyPotential?.find((potentialSet => potentialSet?.potentialId  === potential.potentialId))
          if(result !== undefined){
            return result?.countVisit || 0
          } else {
            return 0
          }
        })
      } 
      return serie
    })
    return {
      xAxis: data.map(({delegate})=> `${delegate?.firstName || ""} ${delegate?.lastName || ""}`),
      series: seriesData
    };
   } else return {
    xAxis: [],
    series: []
  }
}; 

const prepareVisitsFrequenciesByGovernerat = (data: VisitsFrequenciesByGovernerat) => {
   if (data && typeof data === "object" && data.length ) {
    return data.reduce((acc : {localityId: number; localityName: string; potentials : {potentialName: string; potentialId: number; count: number}[]}[], currentValue) => acc.concat([{
      localityId: currentValue?.locality?.localityId || 0,
      localityName: currentValue?.locality?.localityName ||  "",
      potentials : 
      currentValue?.frequenciesbyPotential?.length ? 
       currentValue.frequenciesbyPotential.reduce((accumulator : {potentialName: string; potentialId: number; count: number}[], curr) => accumulator.concat([{
          potentialName: curr?.potentialName  || "",
           potentialId: curr?.potentialId || 0 ,
          count : curr?.potentialFrequency || 0,
        }]) , []) : []
       }]), []).sort((a,b) => {
        if (a.localityName > b.localityName) {
          return 1;
        }
        if (a.localityName < b.localityName) {
          return -1;
        }
        return 0;
       })
   } else return [];
}

const prepareVisitAverageAndVisitCovrageByDelegate = (data: VisitsFrequenciesByDelegate) => {
  if (data && typeof data === "object" && data.length) {
     return data.reduce((acc:{delegate: string, coveragePercentage: string, visitAverage: string}[], val)=> acc.concat([{
      delegate: `${val?.delegate?.firstName || "" } ${val?.delegate?.lastName || ""}`,
      coveragePercentage: val?.coveragePercentage?.toFixed(2).toString() || "",
      visitAverage: val?.globalFrequency?.toFixed(2).toString() || ""
     }]) , [])
  } else return [];
}

const prepareVisitsFrequenciesByDoctorSpecial = (data: VisitsFrequenciesByDoctorSpecial, potentials :Potential[] ) => {
  let seriesData : Serie[] = potentials.map(potential => {
    let serie : Serie = {
      name : potential.potentialName,
      data: data.map(portfolioPotential => {
        let result  = portfolioPotential?.frequenciesbyPotential?.find((potentialSet => potentialSet?.potentialId  === potential.potentialId))
        if(result !== undefined){
          return result?.countVisit || 0
        } else {
          return 0
        }
      })
    } 
    return serie
  })
  return {
    xAxis: data.map(({special})=> `${special.specialityName}`),
    series: seriesData
  };
}; 

const prepareDoctorCoverageBySpeciality = (data: VisitsFrequenciesByDoctorSpecial ) => {
  if (data && typeof data === "object" && data.length) {
   return data.map((element)=> {
       return {
        speciality: element?.special?.specialityName || "",
        coveragePercentage: element?.coveragePercentage.toFixed(2).toString() || "",
       }
    })
  } else return [];
}; 

interface Props {}
const useBiRequests = (props: Props) => {
  const { biVisitData } = useAppSelector((state: RootState) => state.bi);
  const { potentials } = useAppSelector((state: RootState) => state.general);


  const daysCount = useMemo(() => prepareDaysCount(biVisitData), [biVisitData]);
  const visitCount = useMemo(
    () => prepareVisitCount(biVisitData),
    [biVisitData]
  );
  const visitByDayType = useMemo(
    () => prepareVisitsByTypes(biVisitData, "dayType"),
    [biVisitData]
  );

  const visitByActivityType = useMemo(
    () => prepareVisitsByActivityTypes(biVisitData, "activityType"),
    [biVisitData]
  );

  const visitsFrequenciesByGovernerat = useMemo(
    ()=> prepareVisitsFrequenciesByGovernerat(biVisitData?.visitsInsights?.visitsFrequenciesByGovernerat || []), [biVisitData]
  )

  const visitAverageAndVisitCovrageByDelegate = useMemo(
    ()=> prepareVisitAverageAndVisitCovrageByDelegate(biVisitData?.visitsInsights?.visitsFrequenciesByDelegate || []), [biVisitData]
  )

 const visitBydoctorSpecilaity = useMemo(
  ()=> prepareVisitsFrequenciesByDoctorSpecial(biVisitData?.visitsInsights?.visitsFrequenciesByDoctorSpecial || [], potentials), [biVisitData, potentials]
 )

 const couverageVisitsByDoctorSpeciality = useMemo(
  ()=> prepareDoctorCoverageBySpeciality(biVisitData?.visitsInsights?.visitsFrequenciesByDoctorSpecial || []), [biVisitData]
 )

 const visitCountByDelegate = useMemo(
  ()=> prepareVisitCountByDelegate(biVisitData?.visitsInsights?.visitsFrequenciesByDelegate || [], potentials), [biVisitData, potentials]
 )

  return {
    visitByDayType,
    daysCount,
    visitByActivityType,
    visitCount,
    visitsFrequenciesByGovernerat,
    visitAverageAndVisitCovrageByDelegate,
    visitBydoctorSpecilaity,
    couverageVisitsByDoctorSpeciality,
    visitCountByDelegate,
  };
};

export default useBiRequests;
