
//@ts-nocheck
/* eslint-disable */
import React, { useEffect } from 'react'
import { Button, Grid } from "@material-ui/core"
import { TextField, } from '@material-ui/core';
import { useFormik } from 'formik';
import { getConnectedUserBu } from "../../../../utils/getConnectedData";
import { useAppDispatch, useAppSelector } from '../../../../redux/hooks';
import * as yup from 'yup';
import { CreateFacilityTypeThunk } from '../../../../redux/FacilityType/FacilityTypeSlice';
import { getAllFacilitiesTypesThunk } from '../../../../redux/FacilityType/FacilityTypeSlice';
import { updateFacilityTypeByIdThunk } from '../../../../redux/FacilityType/FacilityTypeSlice';
export default function AddForm({ SetFById, closeForm, FbyId }: any) {
    const dispatch = useAppDispatch()


    const validationSchema = yup.object({
        facilityTypeName: yup
            .string()
            .required('Nom est obligatoire'),


    });

    const formik = useFormik({
        initialValues: FbyId ? { "facilityTypeName": FbyId.facilityTypeName } : {
            "facilityTypeName": ""
        },
        validationSchema: validationSchema,
        onSubmit: async (values) => {
            if (!FbyId.facilityTypeName) {
                await dispatch(CreateFacilityTypeThunk(values))
                await dispatch(getAllFacilitiesTypesThunk())
            }
            else {
                await dispatch(updateFacilityTypeByIdThunk({
                    "facilityTypeId": FbyId.facilityTypeId,
                    "facilityTypeName": values.facilityTypeName
                }))
                await dispatch(getAllFacilitiesTypesThunk())
            }
            SetFById({})
            closeForm(false)
        },
    });


    return (
        <>
            <div style={{ zIndex: 2, height: "100%", width: "100%", backgroundColor: "black", opacity: 0.3, position: "fixed", display: "flex", justifyContent: "center", alignItems: "center" }} className='cont'></div>
            <Grid style={{ marginTop: 90, display: "flex", position: "absolute", zIndex: 3, flex: 1, justifyContent: "center", alignItems: "center", width: "100%", overflow: "hidden" }} container>
                <div className='GridStart' style={{ marginTop: "1%", zIndex: 9, color: "white", backgroundColor: "white", width: "80vw", borderRadius: 9, }}>
                    <Grid style={{ minHeight: 70, maxHeight: 70, borderBottom: "1px solid black", display: "flex", justifyContent: "center", alignItems: "center" }}>
                        <div style={{ color: "black", fontSize: "20px" }}>{!FbyId.facilityTypeName ? "Ajouter Type D'etablissement" : "Modifier Type D'établissement"}</div>
                    </Grid>
                    <Grid style={{ height: "20vh", overflow: "hidden", scrollbarWidth: 0 }}>
                        <Grid container wrap="wrap" style={{ flex: 1, justifyContent: "space-around", marginTop: 40, padding: 20 }}>
                            <Grid xs={12} item>
                                <TextField fullWidth id="facilityTypeName"
                                    name="facilityTypeName"
                                    value={formik.values.facilityTypeName}
                                    onChange={formik.handleChange} label="Type D'etablissement"
                                    error={formik.touched.facilityTypeName && Boolean(formik.errors.facilityTypeName)}
                                    variant="outlined" />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid container style={{ flex: 1, justifyContent: "center", alignItems: "center", marginTop: 40, paddingBottom: 30 }}>
                        <Button style={{ color: "black", backgroundColor: "#e5e5ea", marginRight: 30, width: 120 }} variant="contained" color="primary" onClick={() => {
                            SetFById({})
                            closeForm(false)
                        }}>annuler</Button>
                        <Button style={{ color: "white", backgroundColor: "black", width: 120 }} variant="contained" color="primary" onClick={() => formik.handleSubmit()}>{!FbyId.facilityTypeName ? "Ajouter" : "modifier"}</Button>
                    </Grid>
                </div>
            </Grid>

        </>


    )
}


