import React from "react";

import "./Layout.scss";
import Sidebar from "./Sidebar/Sidebar";
interface Props {
  children?: React.ReactElement;
}
export const Layout = ({ children }: Props) => {
  return (
    <div className="main-layout">
      <Sidebar />
      <div className="content-area">{children}</div>
    </div>
  );
};
