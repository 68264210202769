import React, { useEffect, useState } from "react";
import { Row, Col } from "rsuite";
import { VisitCard } from "../../02-molecules/cards/visit-card/VisitCard";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import { RootState } from "../../../redux/store";
import {
  fetchFreeSampleList,
  fetchAllVisits,
  fecthToBeExportedReportList,
} from "../../../redux/Visit/visitSlice";
import { CircularProgress } from "@material-ui/core";
import PlainMessage from "../../01-atoms/PlainMessage/PlainMessage";
import { useFilterReports } from "./useReportFilters";
import { ReportFilter } from "../../03-organisms/filter/report-filter/ReportFilter";
import Pagination from "../../01-atoms/pagination/Pagination";
import FilterList from "../../02-molecules/filterList/FilterList";
import moment from "moment";
import { FreeSampleListToBeExported } from "../../../types/Report.model";
import { FileExporter } from "../../02-molecules/Exoort-File/FileExporter";
import "./ReportListStyle.scss";
import { ReportSummary } from "../../../types/ReportSummary.model";
import {
  addressString,
  formatProductNameIntoAStringList,
} from "../../../helpers/dataFormator";
interface Props {}

export const ReportsListe = (props: Props) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const dispatch = useAppDispatch();
  const initialDateValue: string[] = [
    moment().startOf("month").format("L"),
    moment().format("L"),
  ];
  // const [searchTerm, setSearchTerm] = useState("");
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  // const [searchFieldIsLoading, setSearchFieldIsLoading] = useState(false);
  const [appliedFilters, setAppliedFilters] = useState<string[]>([
    initialDateValue[0],
    initialDateValue[1],
  ]);
  const { filteredVisits, requestState } = useAppSelector(
    (state: RootState) => state.visit
  );

  const {
    globalFilterState,
    capturePageTarget,
    captureLimitTarget,
    captureFilter,
  } = useFilterReports();

  useEffect(() => {
    dispatch(fetchAllVisits(globalFilterState));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [globalFilterState]);

  // for sample export
  const handleDispatchFreeSampleList = async () => {
    let res = await dispatch(
      fetchFreeSampleList({
        startDate: globalFilterState?.startDate || null,
        endDate: globalFilterState?.endDate || null,
      })
    );
    return res.payload;
  };

  //Format sample list  data that will be exported
  const formatSampleListExportedData = (
    exportToCSV: (exportedData: any, filteName: string) => void
  ) => {
    handleDispatchFreeSampleList().then((res: FreeSampleListToBeExported[]) => {
      if (res && res.length) {
        let result = res.map((item) => ({
          "Date de remise": item?.dateReport
            ? moment(item.dateReport).format("L")
            : "-",
          "Nom et Prénom  du client": item?.clientName || "-",
          "Nom du produit": item?.productName || "-",
          Quantité: item?.quantity || "-",
          "Nom et Prénom du délégué": item?.delegateName || "-",
        }));
        exportToCSV(result, "Liste des échantillons");
      } else {
        exportToCSV([{ Commentaire: "liste vide" }], "Liste des échantillons");
      }
    });
  };

  // for report export
  const handleDispatchReportExport = async () => {
    let res = await dispatch(
      fecthToBeExportedReportList({
        startDate: globalFilterState?.startDate || null,
        endDate: globalFilterState?.endDate || null,
        pagination: {
          pageNumber: 1,
          pageSize: filteredVisits?.pagination?.totalCount || 500,
        },
      })
    );
    return res.payload;
  };

  //Format report list  data that will be exported
  const formatReportExportList = (
    exportToCSV: (exportedData: any, filteName: string) => void
  ) => {
    handleDispatchReportExport().then((res: { content: ReportSummary[] }) => {
      let { content } = res;
      if (content && typeof content === "object" && content.length) {
        let result = content.map((el) => {
          return {
            Date: el.date ? moment(el.date).format("L") : "-",
            Délégué: `${el?.delegate?.firstName || "-"} ${
              el?.delegate?.lastName || "-"
            }`,
            Client: `${el.weekContent?.doctor?.firstName || "-"} ${
              el.weekContent?.doctor?.lastName || "-"
            }`,
            Potentiel: el?.weekContent?.doctor?.potential?.potentialName || "-",
            Spécialité:
              el?.weekContent?.doctor?.specialities &&
              el?.weekContent?.doctor?.specialities.length
                ? el?.weekContent?.doctor?.specialities?.find(
                    (e) => e.isPrincipal === true
                  )?.specialityName
                : "-",
            Durée: el.duration ? el.duration : "-",
            "Nom établissement": el?.location?.facilityName || "-",
            Addresse: addressString(
              el?.location?.address?.localities,
              el?.location?.address?.streetName
            ),
            Gouvernorat:
              el?.location?.address?.localities &&
              el?.location?.address?.localities.length
                ? el?.location?.address?.localities.find((e) => e.level === 1)
                    ?.localityName
                : "-",
            Délégation:
              el?.location?.address?.localities &&
              el?.location?.address?.localities.length
                ? el?.location?.address?.localities.find((e) => e.level === 2)
                    ?.localityName
                : "-",
            "Objectif de la visite / Produit(s) présenté(s)":
              formatProductNameIntoAStringList(el.presentedProducts),
            "Status du rapport":
              el?.weekContent?.weekContentStatus &&
              el.weekContent.weekContentStatus === 4
                ? "Rapport clôturé"
                : el?.weekContent?.weekContentStatus
                ? "Rapport non clôturé"
                : "-",
          };
        });
        exportToCSV(result, "Liste des rapports");
      } else {
        exportToCSV([{ Commentaire: "liste vide" }], "Liste des rapports");
      }
    });
  };

  return (
    <div className="authorization-management__wrapper">
      <div className="top-area" style={{ justifyContent: "space-between" }}>
        {/* <InputGroup inside className="profile-search-input">
          <Input
            size="lg"
            type="text"
            name="search"
            placeholder="Recherche"
            onChange={(value) => setSearchTerm(value)}
          />
          <InputGroup.Button>
            {searchFieldIsLoading ? (
              <Loader />
            ) : (
              <Icon path={mdiMagnify} size={1} />
            )}
          </InputGroup.Button>
        </InputGroup> */}
        <FilterList
          filterList={appliedFilters}
          styleName="report-filter-list-width"
        />
        <div className="report-page_filter-export-btns">
          <ReportFilter
            captureFilter={captureFilter}
            setAppliedFilters={setAppliedFilters}
          />
          <FileExporter
            getDataAndFormatDataFunction={formatSampleListExportedData}
            withModal={true}
            modalMenuItem={[
              {
                label: "Exporter les échantillons",
                exportFunction: formatSampleListExportedData,
              },
              {
                label: "Exporter les rapports",
                exportFunction: formatReportExportList,
              },
            ]}
            textClassName="file-export-style"
          />
        </div>
      </div>
      {filteredVisits &&
      filteredVisits.content &&
      filteredVisits.content.length ? (
        filteredVisits.content.map((visit) => {
          return (
            <Row className="visits-list-row" key={visit.reportId}>
              <Col xs={24} className="visits-list-col">
                <VisitCard
                  visit={visit}
                  customizedWidth={"600px"}
                  numberOfDisplayedProduct={window.innerWidth < 650 ? 0 : 3}
                />
              </Col>
            </Row>
          );
        })
      ) : filteredVisits &&
        filteredVisits.content &&
        !filteredVisits.content.length &&
        requestState === "idle" ? (
        <PlainMessage text={"Aucun rapport"} />
      ) : (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            height: "77vh",
            alignItems: "center",
          }}
        >
          <CircularProgress size={50} />
        </div>
      )}
      <div style={{ paddingBottom: "3rem" }}>
        <Pagination
          captureLimitTarget={captureLimitTarget}
          capturePageTarget={capturePageTarget}
          pageCount={filteredVisits?.pagination?.totalCount || 0}
        />
      </div>
    </div>
  );
};
