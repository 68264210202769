import React from "react";
import { getLocalDate } from "../../../../../utils/dateHelpers";
import "./PhalconCell.scss";
type Props = {
  item: string;
};

const DateCell = ({ item }: Props) => {
  return <div className="phalcon-date-cell">{item? getLocalDate(item) :  "-"}</div>;
};

export default DateCell;
