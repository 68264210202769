import React, { useState } from "react";

import { Tag, Whisper, Tooltip, Modal, List, Avatar } from "rsuite";
import "./PhalconCell.scss";
type Props = {
  item: string;
  rowName: string;
};
type SpecialityData = {
  specialityId: string;
  specialityName: string;
  abreviation: string;
};

const SpecialityCell = ({ item, rowName }: Props) => {
  const specialities: SpecialityData[] = JSON.parse(item);
  const [modalIsOpen, setmodalIsOpen] = useState(false);
  const handleOpen = () => setmodalIsOpen(true);
  const handleClose = () => setmodalIsOpen(false);

  const renderModal = (specialities: SpecialityData[]) => (
    <Modal open={modalIsOpen} onClose={handleClose}>
      <Modal.Header>
        <Modal.Title>Spécialités de {rowName}</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <List>
          {specialities.length > 0
            ? specialities.map((e, i) => (
                <List.Item
                  key={e.specialityId}
                  className="phalcon-speciality-cell-list-item"
                >
                  <div className="speciality-item">{e.specialityName || "-"}</div>
                </List.Item>
              ))
            : null}
        </List>
      </Modal.Body>
    </Modal>
  );

  return (
    <div className="phalcon-speciality-cell">
      {specialities.slice(0, 2).map((e) => (
        <Whisper
          key={e.specialityId}
          trigger="hover"
          placement="top"
          speaker={<Tooltip>{e.specialityName}</Tooltip>}
        >
          <Tag size="lg" style={{ userSelect: "none" }}>
            {e.abreviation}
          </Tag>
        </Whisper>
      ))}
      {specialities.length > 2 ? (
        <div className="extra-specialities" onClick={handleOpen}>
          <Avatar size="xs" className="extra-specialities--count" circle>
            +{specialities.length - 1}
          </Avatar>
        </div>
      ) : null}
      {renderModal(specialities)}
    </div>
  );
};

export default SpecialityCell;
