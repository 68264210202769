import * as React from "react";
import { InputGroup, Input, Loader } from "rsuite";
import { mdiMagnify } from "@mdi/js";
import { Icon } from "@mdi/react";
import "./SearchBar.scss";
interface Props {
  placeHolder?: string;
  typeClasse?: string;
  containerClass?: string;
  searchFieldIsLoading?: boolean;
  setSearchTerm: (value: string) => void;
}

export const SearchBar = ({
  placeHolder,
  typeClasse,
  containerClass,
  setSearchTerm,
  searchFieldIsLoading,
}: Props) => {
  return (
    <div className={`search-bar__wrapper ${containerClass}`}>
      <InputGroup inside size="lg">
        <Input
          className={typeClasse}
          placeholder={placeHolder}
          name="search"
          onChange={setSearchTerm}
        />
        <InputGroup.Addon>
          {searchFieldIsLoading ? (
            <Loader />
          ) : (
            <Icon path={mdiMagnify} size={1} />
          )}
        </InputGroup.Addon>
      </InputGroup>
    </div>
  );
};
