import React from "react";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import Chart from "react-apexcharts";
import { Col } from "rsuite";

ChartJS.register(ArcElement, Tooltip, Legend);

type Props = {
  title: string;
  series: { name: string; data: number[] }[];
  xAxisCategories: string[];
  yAxis: string;
  stacked?: boolean;
  toolbar?: boolean;
  xs?: number;
  sm?: number;
  md?: number;
  lg?: number;
};
const PhalconBarChart = ({
  stacked = false,
  title,
  xs,
  sm,
  md,
  lg,
  yAxis,
  series,
  xAxisCategories,
  toolbar = false,
}: Props) => {
  
  const state: any = {
    series: series,
    options: {
      chart: {
        type: "bar",
        stacked: stacked,
        toolbar: {
          show: toolbar,
        },
      },
      title: {
        text: title,
        align: "center",
      },
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: "55%",
          endingShape: "rounded",
          dataLabels: {
            position: "top",
          },
        },
      },
      dataLabels: {
        enabled: true,
        textAnchor: "middle",
      },
      stroke: {
        show: true,
        width: 2,
        colors: ["transparent"],
      },
      xaxis: {
        categories: xAxisCategories,
      },
      yaxis: {
        title: {
          text: yAxis,
        },
      },
      fill: {
        opacity: 1,
      },
      tooltip: {
        y: {
          formatter: (val: string) => val,
        },
      },
    },
  };
  return (
    <Col xs={xs || 24} sm={sm} md={md} lg={lg}>
      <div className="phalcon-bar__wrapper">
        <div className="phalcon-bar__inner" style={{maxHeight: "385px"}}>
          <Chart
            options={state.options}
            series={state.series}
            type="bar"
            height={385} 
            width="100%"
          />
        </div>
      </div>
    </Col>
  );
};
export default PhalconBarChart;
