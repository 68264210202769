import React, { useEffect } from "react";
import { Outlet, useLocation, useNavigate, useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import { fetchPharmacyById } from "../../../redux/Pharmacy/pharmacySlice";
import { RootState } from "../../../redux/store";
import { useHandleResize } from "../../../utils/useHandleResize";
import { useRoutingConfig } from "../../../utils/useRoutingConfig";
import { ProfileHeader } from "../../03-organisms/user-account/profile-header/ProfileHeader";
// import { SellerPharmacyProfileInfo } from "../../04-templates/profile/profile-infos/SellerPharmacyProfileInfo";
import { Header } from "../../05-layouts/Header/Header";

type Props = {};

export const PharmacyPage = (props: Props) => {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const { id } = useParams();
  const dispatch = useAppDispatch();
  //Responsiveness variables
  const deviceSize = useHandleResize();
  const isMobile = deviceSize === "Mobile";
  const isTablet = deviceSize === "Tablet";
  const isDesktop = !["Mobile", "Tablet"].includes(deviceSize);
  const currentRoute = useRoutingConfig().find((e) => e.key === "14");
  const generalSubRoutes =
    typeof currentRoute !== "undefined" ? currentRoute.children : [];
  const subRoutes =
    isMobile || isTablet
      ? generalSubRoutes.filter((e) => !["settings", "rp"].includes(e.path))
      : generalSubRoutes.filter(
          (e) => !["settings", "rp", "infos"].includes(e.path)
        );

  const { pharmacyById } = useAppSelector((state: RootState) => state.pharmacy);
  const managePharmacyPageRoutes = (deviceSize: string, path: string) => {
    const showPharmacyInfos = isMobile && path === `/pharmacy/${id}`;
    const hidePharmacyInfos = isDesktop && path === `/pharmacy/${id}/infos`;
    const showPharmacyVisits = isDesktop && path === `/pharmacy/${id}`;

    showPharmacyInfos && navigate(`/pharmacy/${id}/infos`);
    hidePharmacyInfos && navigate(`/pharmacy/${id}`);
    showPharmacyVisits && navigate(`/pharmacy/${id}/visits`);
  };

  useEffect(() => {
    managePharmacyPageRoutes(deviceSize, pathname);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deviceSize, pathname, navigate]);
  useEffect(() => {
    const abortController = new AbortController();
    id &&
      dispatch(
        fetchPharmacyById({ id: parseInt(id), signal: abortController.signal })
      );
    return () => {
      abortController.abort();
    };
  }, [dispatch, id]);
  return (
    <>
      <Header
        leftItem={<ProfileHeader context="pharmacy" person={pharmacyById} />}
        subRoutes={subRoutes}
      />
      {deviceSize !== "Mobile" ? (
        <div className="user-page__inner">
          <div className="user-page__inner__left">
            {/* <SellerPharmacyProfileInfo sellerPharmacy={pharmacyById} /> */}
          </div>
          <div className="user-page__inner__main">
            <Outlet />
          </div>
        </div>
      ) : (
        <Outlet />
      )}
    </>
  );
};
