import React from "react";
import { Loader } from "rsuite";
import { Planification } from "../../../types/Planification.model";
import { PlanificationWeek } from "../../../types/PlanificationWeek.model";
import { PlanificationWeekCard } from "../../02-molecules/planification-week-card copy/PlanificationWeekCard";
import "./PlanificationWeeksSidebar.scss";
type Props = {
  activeUpdatedPlanificationState: Planification | null;
  activeWeek?: PlanificationWeek;
  setActiveWeekCallBack: React.Dispatch<
    React.SetStateAction<PlanificationWeek | undefined>
  >;
};
export const PlanificationWeeksSidebar = ({
  activeUpdatedPlanificationState,
  activeWeek,
  setActiveWeekCallBack,
}: Props) => {
  const isActive = (weekId: number) => activeWeek?.weekId === weekId;

  return (
    <div className="week-sidebar__wrapper">
      {activeUpdatedPlanificationState &&
      activeUpdatedPlanificationState?.planificationWeeks.length > 0 ? (
        activeUpdatedPlanificationState?.planificationWeeks.map(
          (planificationWeek) => (
            <PlanificationWeekCard
              key={planificationWeek.weekId}
              active={isActive(planificationWeek.weekId)}
              activePlanificationWeek={planificationWeek}
              onClick={() => setActiveWeekCallBack(planificationWeek)}
            />
          )
        )
      ) : (
        <Loader />
      )}
    </div>
  );
};
