import React from "react";
import { Tooltip } from "@material-ui/core";
import "./FilterList.scss";
type Props = {
  filterList: string[];
  styleName?: string;
};

export default function FilterList({ filterList, styleName }: Props) {
  return (
    <div className={`filter-list__wrapper ${styleName}`}>
      {filterList && filterList.length
        ? filterList.map((el, index) => {
            return (
              <Tooltip
                title={el}
                placement="top"
                disableHoverListener={el && el.length > 12 ? false : true}
                key={index}
              >
                <span key={index + el} className="filter-element__style">
                  {el && el.length > 12
                    ? `${el.slice(0, 3)} ... ${el.slice(
                        el.length - 9,
                        el.length
                      )}`
                    : el || ""}
                </span>
              </Tooltip>
            );
          })
        : ""}
    </div>
  );
}
