import React from "react";
import { Modal, Button } from "rsuite";
import "./AlertDeleteModal.scss";
interface Props {
  open?: boolean;
  handleClose?: () => void; 
  handleDelete?: () => void;
  name?: string;
}


export const AlertDeleteModal = ({
  open, 
  handleClose,
  handleDelete,
  name,
}: Props) => {
  return ( 
      <Modal size="sm" open={open} onClose={handleClose} className="modal__delete">
        <Modal.Header closeButton={false}> 
          <Modal.Title as="h3" className="modal__delete__title">Supprimer {name}</Modal.Title>  
        </Modal.Header>
        <Modal.Body className="modal__delete__body"> Êtes-vous certain de vouloir supprimer {name} ?</Modal.Body>
        <Modal.Footer className="model__delete__footer"> 
          <Button  onClick={handleClose} appearance="ghost" style= {{color: "#181B24", borderColor:"#181B24"}} className="modal__delet__btn" size="lg"> 
            Annuler
          </Button>
          <Button  onClick={handleDelete} appearance="primary" color="red"  className="modal__delet__btn" size="lg">
            Supprimer
          </Button>
        </Modal.Footer> 
      </Modal>
  );
};
