import React, { useEffect, useState } from "react";
import { Table } from "rsuite";

import { DatabaseTableActionCell } from "../../../02-molecules/DatabaseTableActionCell/DatabaseTableActionCell";
import TableCellProvider from "./TableCellProvider";

export type Column = {
  key: string;
  label: string;
  width: number;
  fixed: boolean;
  type: string;
  fullWidth?: boolean;
  sortable?: boolean;
};
type Props = {
  data: any;
  columns: Column[];
  captureOrderBy: (sortColumn: string, sortType: string) => void;
  dataResquestState: string;
  dataBaseContext: "doctor" | "pharmacy" | "seller" | "facility" | "rp";
};

const TablePhalcon = ({
  data,
  columns,
  captureOrderBy,
  dataResquestState,
  dataBaseContext,
}: Props) => {
  const rowKey = "id";
  const [sortColumn, setSortColumn] = useState();
  const [sortType, setSortType] = useState();
  const [tableIsLoading, setTableIsLoading] = useState(true);
  const handleSortColumn = (column: any, type: any) => {
    captureOrderBy(column, type);
    setSortColumn(column);
    setSortType(type);
  };
  useEffect(() => {
    setTableIsLoading(dataResquestState === "loading");
  }, [dataResquestState]);
  return (
    <Table
      bordered
      cellBordered
      data={data}
      autoHeight
      rowHeight={60}
      headerHeight={60}
      loading={tableIsLoading}
      sortColumn={sortColumn}
      sortType={sortType}
      onSortColumn={handleSortColumn}
      rowKey={rowKey}
    >
      {columns.map((column: Column) => {
        const { key, label, fixed, type, fullWidth, sortable, ...rest } =
          column;
        return (
          <Table.Column
            key={key}
            flexGrow={fullWidth ? 1 : undefined}
            sortable={sortable}
            resizable
            {...rest}
          >
            <Table.HeaderCell className="header-cell">{label}</Table.HeaderCell>

            <TableCellProvider dataKey={key} type={type} />
          </Table.Column>
        );
      })}
      <Table.Column width={100} align="center" fixed="right">
        <Table.HeaderCell>Actions</Table.HeaderCell>
        <DatabaseTableActionCell
          dataKey={"id"}
          dataBaseContext={dataBaseContext}
        />
      </Table.Column>
    </Table>
  );
};

export default TablePhalcon;
