import { useEffect, useState } from "react";
import { prepareInPortfolioOfOptions } from "../../../../helpers/GlobalFormHelpers";
import { useAppSelector } from "../../../../redux/hooks";
import { RootState } from "../../../../redux/store";
import moment from "moment";

export interface AuthorizationFormInput {
    delegatesId : number | null;
    startDate: Date | null;
    endDate: Date | null; 
    expirationDate : Date | null;
} 

export const fieldIsRequired = "Ce champs est obligatoire";

export const prepareAuthorisationFormPayload = (formOutPut: AuthorizationFormInput ) => {
    return {
        userId: formOutPut.delegatesId,
        startDate: moment(formOutPut.startDate).utc().toDate(), 
        endDate: moment(formOutPut.endDate).utc().toDate(),
        expirationDate: moment(formOutPut.expirationDate).utc().toDate(),
    }
}

type FormOptions = {
    teamMembersOptions: {
      key: number;
      label: string;
      value: number | string;
    }[];
}
const useAuthorisationForm=() => {
    const { teams } = useAppSelector((state: RootState) => state.general);
    const [formOptions, setFormOptions] = useState<FormOptions | null>(null);
    const initialValues = {
        delegatesId: null,
        startDate: null,
        endDate: null,
        expirationDate: null,
      }
    useEffect(()=> {
        const teamMembersOptions = prepareInPortfolioOfOptions(teams);
        setFormOptions({teamMembersOptions: teamMembersOptions})
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    return {initialValues, formOptions, prepareAuthorisationFormPayload}
}

export default useAuthorisationForm
