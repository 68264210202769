import { mdiPlus } from "@mdi/js";
import React from "react";
import { Panel, Badge } from "rsuite";
import { StandardIcon } from "../../../01-atoms/Icons/Icons";

import "./InboxChannels.scss";
type Props = {};

type AddChannelButtonProps = {
  onClick?: () => void;
};

const randomNumber = (min: number, max: number) =>
  Math.floor(Math.random() * (max - min + 1)) + min;

const AddChannelButton = ({ onClick }: AddChannelButtonProps) => (
  <div className="add-channel-button" onClick={onClick}>
    <StandardIcon icon={mdiPlus} />
    <span>Ajouter une chaîne</span>
  </div>
);
type ChannelItemProps = {
  title: string;
  active: boolean;
  newCount: any;
};
const ChannelItem = ({ title, active, newCount }: ChannelItemProps) => {
  return (
    <div
      className={`phalcon-inbox__channels--item ${active ? "isActive" : ""}`}
    >
      <div>
        <span className="channel-item__hash">#</span>
        <span className="channel-item__title">{title}</span>
      </div>
      {newCount() ? <Badge content={newCount()} /> : null}
    </div>
  );
};
const InboxChannels = (props: Props) => {
  return (
    <div className="phalcon-inbox__channels">
      <Panel
        key={"channels"}
        header="Cannaux"
        defaultExpanded
        className="phalcon-inbox__channels"
      >
        {[
          "Général",
          "Objections",
          "Demandes",
          "Evènements",
          "Objectifs de la semaine",
          "Objectifs du cycle",
          "Commandes",
          "Objectifs de ventes",
        ].map((item, index) => (
          <ChannelItem
            title={item}
            key={index}
            active={index === 0}
            newCount={() => randomNumber(0, 4)}
          />
        ))}
        <AddChannelButton />
      </Panel>
    </div>
  );
};

export default InboxChannels;
