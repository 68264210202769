import { Icon } from "@mdi/react";
import React from "react";
import "./Icons.scss";

interface standardIconProps {
  dataDirection?: string;
  icon: string;
  data?: number;
  width?: string;
  justify?: string;
  className?: string;
  style?: React.CSSProperties | undefined;
  clickable?: boolean;
  onClick?: () => void;
  isNotWrapped?: boolean;
  size?: number;
  //TO DO
}
export const StandardIcon = ({
  dataDirection,
  icon,
  data,
  style,
  className,
  onClick,
  clickable = false,
  isNotWrapped,
  size,
}: standardIconProps) => {
  if (!isNotWrapped) {
    return (
      <div
        className={`icon__wrapper ${clickable ? "isClickable" : ""} ${
          className ? className : ""
        }`}
        style={style} 
        onClick={onClick}
      >
        {dataDirection === "left" ? <span>{data ? data : "0"}</span> : ""}
        <Icon path={icon} size={size? size : 1} />
        {dataDirection === "right" ? <span>{data ? data : "0"}</span> : ""}
      </div>
    );
  } else { 
    return <Icon path={icon} size={size? size : 1} />;
  }
};
