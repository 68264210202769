import { useEffect } from "react";
import { useFieldArray, useForm } from "react-hook-form";
import { Modal, Button, Form, FlexboxGrid, Divider } from "rsuite";
import { addCycle } from "../../../../redux/Cycle/cycleSlice";
import { useAppDispatch, useAppSelector } from "../../../../redux/hooks";
import { RootState } from "../../../../redux/store";
import { Potential } from "../../../../types/Potential.model";
import { getConnectedUserId } from "../../../../utils/getConnectedData";
import { NumberField } from "../../../02-molecules/forms/NumberField/NumberField";
import { TextField } from "../../../02-molecules/forms/TextField/TextField";

type Props = {
  formModalIsOpen: boolean;
  toggleFormModal: () => void;
};
 
interface CycleFormInputs {
  cycleName: string;
  cycleDescription: string;
  frequencies: { potentialId: number; potentialName: string; freq: number | null }[];
  nbDays: number;
  managerId: number | null;
  nbWeek: number | null;
  nbVisitWeek: number | null;
}

export const NewCycleForm = ({ formModalIsOpen, toggleFormModal }: Props) => {
  const { potentials } = useAppSelector((state: RootState) => state.general);
  const dispatch = useAppDispatch();
  const prepareForm = () => {
    const initForm: CycleFormInputs = {
      cycleName: "",
      cycleDescription: "",
      nbDays: 1,
      managerId: parseInt(getConnectedUserId()),
      frequencies:
        potentials.map((item: Potential) => ({
          potentialId: item.potentialId,
          potentialName: item.potentialName,
          freq: null,
        })) || [],

      nbWeek: null,
      nbVisitWeek: null,
    };
    return initForm;
  };
  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<CycleFormInputs>({
    criteriaMode: "all",
    reValidateMode: "onChange",
  });
  const frequencies = useFieldArray({
    control,
    name: "frequencies",
  });

  const onsubmit = handleSubmit((data) => {
    const payload = { ...data, delegates: [] };
    dispatch(addCycle(payload));
    toggleFormModal();
  });

  useEffect(() => {
    if (potentials.length > 0) {
      const init = prepareForm();
      reset(init);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [potentials]);

  return (
    <Modal
      full
      open={formModalIsOpen}
      onClose={toggleFormModal}
      dialogClassName="form-modal"
    >
      <Modal.Header>
        <Modal.Title>Ajouter un cycle</Modal.Title>
      </Modal.Header>
      <Modal.Body className="custom-form-modal">
        <Form>
          <div className="custom-form-section">
            <FlexboxGrid align="bottom">
              <TextField
                control={control}
                errors={errors}
                label={"Titre"}
                inputName={"cycleName"}
                md={12}
                rules={{ required: "Ce champ est requis" }}
              />
              <Divider vertical style={{ marginBottom: "1.5rem" }} />
              {frequencies.fields.map((field, index) => (
                <NumberField
                  key={field.potentialId}
                  control={control}
                  errors={errors}
                  label={field.potentialName}
                  md={2}
                  inputName={`frequencies.${index}.freq`}
                  step={0.5}
                  rules={{ required: "Ce champ est requis" }} 
                />
              ))}
              <Divider vertical style={{ marginBottom: "1.5rem" }} />
              <NumberField
                control={control}
                errors={errors}
                label={"Semaines"}
                min={1} 
                md={2}
                inputName={`nbWeek`}
                rules={{ required: "Ce champ est requis" }} 
              />
              <NumberField
                control={control}
                errors={errors}
                label={"Visites/Semaine"}
                min={1} 
                md={2}
                inputName={`nbVisitWeek`}
                rules={{ required: "Ce champ est requis" }} 
              />
              <TextField
                control={control}
                errors={errors}
                label={"Description"}
                inputName={"cycleDescription"}
                textarea
                md={24}
                rules={{}}
              />
            </FlexboxGrid>
          </div>
        </Form>
      </Modal.Body>
      <div className="cycle-managemnt-card__wrapper"></div>

      <Modal.Footer style={{ paddingTop: "1rem" }}>
        <Button onClick={toggleFormModal}>Annuler</Button>
        <Button
          className="database-submit-button"
          type="submit"
          appearance="primary"
          onClick={() => onsubmit()}
        >
          Enregistrer
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
