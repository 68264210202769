import {
  mdiHandBackLeftOutline,
  mdiCheckCircle,
  mdiCloseCircle,
} from "@mdi/js";
// import _ from "lodash";
import React from "react";
import { FlexboxGrid } from "rsuite";
import AnalyticsCard from "../../../03-organisms/analytics/cards/AnalyticsCard";
import PhalconBarChart from "../../../03-organisms/charts/PhalconBarChart";
import PhalconPieChart from "../../../03-organisms/charts/PhalconPieChart";
import { BiFilter } from "../../../03-organisms/filter/BiFilter/BiFilter";
import useBiObjections from "./useBiObjections";

type Props = {};

export type ChartDefinition = {
  title: string;
  type:
    | "pie"
    | "donut"
    | "line"
    | "area"
    | "bar"
    | "histogram"
    | "radialBar"
    | "scatter"
    | "bubble"
    | "heatmap"
    | "treemap"
    | "boxPlot"
    | "candlestick"
    | "radar"
    | "polarArea"
    | "rangeBar";
  labels?: string[];
  series: number[] | { name: string; data: number[] }[];
  xAxisCategories?: string[];
  stacked?: boolean;
  md?: number;
  yAxis?: string;
};

const BiObjections = (props: Props) => {
  const {
    ObjectionsCount,
    objectionsBySatisfaction,
    objectionsStatusByUser,
    objectionsStatusByProduct,
    // objectionsPotentialByValue,
  } = useBiObjections({});

  
  const charts: ChartDefinition[] = [
    {
      title:
        "Répartition objections traitées selon les indices de satisfaction",
      type: "donut",
      labels: objectionsBySatisfaction.labels, 
      series: objectionsBySatisfaction.series, 
      md: 12,
    },
    {
      title: "Répartition objections | Délégués | Statut",
      type: "bar",
      xAxisCategories: objectionsStatusByUser.xAxis,
      series: objectionsStatusByUser.series,
      stacked: true,
      md: 12,
      yAxis: "Objections",
    },
    {
      title: "Répartition objections Produit | Statut",
      type: "bar",
      xAxisCategories: objectionsStatusByProduct.xAxis,
      series: objectionsStatusByProduct.series,
      stacked: false,
      md: 24,
      yAxis: "Objections",
    },
    // {
    //   title:
    //     "Produit - Objections | Nombre des objections de chaque produit selon le type d'objection",
    //   type: "bar",
    //   xAxisCategories: objectionsPotentialByValue.xAxis,
    //   series: objectionsPotentialByValue.series,
    //   stacked: true,
    //   md: 24,
    //   yAxis: "Objections",
    // },
  ];
 
  return (
    <div className="phalcon-bi-general__wrapper ">
      <div style={{display: "flex", justifyContent: "end"}}>
      <BiFilter context={"objection"}/>
      </div> 
      <div className="phalcon-bi-general__cards-area top-space">
        <FlexboxGrid align="bottom">
          <AnalyticsCard
            mainMetric={{
              icon: mdiHandBackLeftOutline,
              count: ObjectionsCount.total,
              title: "Objections", 
            }}
            md={4}
          /> 
          <AnalyticsCard
            mainMetric={{
              icon: mdiCheckCircle,
              count: ObjectionsCount.closedCount,
              title: "Objections traitées",
            }}
            md={10}
          />
          <AnalyticsCard
            mainMetric={{
              icon: mdiCloseCircle, 
              count: ObjectionsCount.notClosed.total,
              title: "Objections non traitées",
            }}
            potentials={ObjectionsCount.notClosed.potential}
            md={10}
          />
        </FlexboxGrid>
      </div>

      <div className="phalcon-bi-general__charts-area">
        <FlexboxGrid align="bottom">
          {charts.map((chart: any, index: number) => {
            if (["pie", "donut"].includes(chart.type)) {
              return (
                <PhalconPieChart
                  key={index}
                  title={chart.title}
                  type={chart.type}
                  labels={chart.labels}
                  series={chart.series}
                  md={chart.md}
                />
              );
            }
            if (chart.type === "bar") {
              return (
                <PhalconBarChart
                  key={index}
                  title={chart.title}
                  series={chart.series}
                  xAxisCategories={chart.xAxisCategories}
                  stacked={chart.stacked}
                  md={chart.md}
                  yAxis={chart.yAxis}
                />
              );
            } else {
              return null;
            }
          })}
        </FlexboxGrid>
      </div>
    </div>
  );
};

export default BiObjections;


