import React, { useEffect, useRef } from "react";
import { Divider } from "rsuite";
import { CustomAvatar } from "../../../01-atoms/CustomAvatar/CustomAvatar";
import "./MessageItem.scss";
type Props = {
  last?: boolean;
};

const MessageItem = ({ last }: Props) => {
  const lastMessageRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    last && lastMessageRef.current && lastMessageRef.current.scrollIntoView();
  }, [last]);
  return (
    <div className="phalcon-inbox-message-item__wrapper" ref={lastMessageRef}>
      <div className="phalcon-inbox-message-item__top">
        <div className="phalcon-inbox-message-item__top--sender">
          <CustomAvatar
            image={{
              src: "",
              alt: "",
            }}
            style={{ width: "25px", height: "25px" }}
          />
          <span>Chikhaoui Mohamed Khalil</span>
        </div>
        <Divider vertical />
        <div className="phalcon-inbox-message-item__top--time">9:23</div>
      </div>
      <div className="phalcon-inbox-message-item__bottom">
        <div className="phalcon-message-content">
          Lorem ipsum, dolor sit amet consectetur adipisicing elit. Ipsam, quos
          pariatur deleniti ratione nobis at fugit numquam labore ut accusantium
          harum dolore perferendis.
        </div>
      </div>
    </div>
  );
};

export default MessageItem;
