import React, { useEffect, useState } from "react";
import { SearchBar } from "../../02-molecules/searchBar/SearchBar";
import {
  OrderFilters,
  orderStatus,
} from "../../03-organisms/filter/orderFilters/OrderFilters";
import "./Order.scss";
import OrderTables from "./orderTables/OrderTables";
import Details from "./details/Details";
import FilterList from "../../02-molecules/filterList/FilterList";
import {
  GlobalOrderFilterState,
  useFilterOrder,
} from "../../03-organisms/filter/orderFilters/useFilterOrder";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import { RootState } from "../../../redux/store";
import moment from "moment";
import { FormatedDataOrderList } from "../../../types/Order.model";
import {
  FilteredOrders,
  getOrderListForExport,
} from "../../../redux/Order/orderSlice";
import { FileExporter } from "../../02-molecules/Exoort-File/FileExporter";
import { getCurrentMonthToCurrentDay, getLocalDate } from "../../../utils/dateHelpers";

export default function Orders() {
  const initialDateValue: string[] = [
    moment().startOf("month").format("L"),
    moment().format("L"),
  ];
  const dispatch = useAppDispatch();
  const [context, setContext] = useState<
    "paymentDetails" | "orderDetails" | "deliveryDetails" | null
  >(null);
  const [clientInfo, setClientInfo] = useState<string>("");
  const [isDetailsModalOpen, setIsDetailsModalOpen] = useState<boolean>(false);
  const [appliedFilters, setAppliedFilters] = useState<string[]>([initialDateValue[0], initialDateValue[1]]);
  const [searchTerm, setSearchTerm] = useState("");
  const [searchFieldIsLoading, setSearchFieldIsLoading] = useState(false);

  const {
    requestState,
    listIsLoaded,
    errors,
    orderList,
    orderDetails,
    paymentDetails,
    deliveryDetails,
  } = useAppSelector((state: RootState) => state.order);
  const { potentials } = useAppSelector((state: RootState) => state.general);
  //Hooks that handle the filters to get the order list
  const {
    globalFilterState,
    captureFilter,
    capturePageTarget,
    captureLimitTarget,
    captureSearchTextValue,
    captureDtaeFilter,
  } = useFilterOrder();
 
  //For searchText
  useEffect(() => {
    
    searchTerm.length && setSearchFieldIsLoading(true);
    const delayDebounceFn = setTimeout(() => {
      captureSearchTextValue(searchTerm);
    }, 500);
    return () => {
      clearTimeout(delayDebounceFn);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchTerm]);
  useEffect(() => {
    if (requestState === "idle" && !errors) {
      setSearchFieldIsLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [requestState]);

  const dispatchOrderListForTheExportButton = async () => {
    let res = await dispatch(
      getOrderListForExport({
        pageSize: totalCount ? totalCount : 300,
        startDate:
          captureDtaeFilter && captureDtaeFilter[0]
            ? captureDtaeFilter[0]
            :  getCurrentMonthToCurrentDay()?.startDate || null,
        endDate:
          captureDtaeFilter && captureDtaeFilter[1]
            ? captureDtaeFilter[1]
            :  getCurrentMonthToCurrentDay()?.endDate || null,
      })
    );
    return res.payload;
  };
  //Get date value
  const getDateValue = (globalFilterState: GlobalOrderFilterState) => {
    const { startDate, endDate } = globalFilterState;
    const dateValue: [Date, Date] | undefined =
      startDate && endDate
        ? [moment(startDate).utc().toDate(), moment(endDate).utc().toDate()]
        : undefined;

    return dateValue;
  };

  //Format the data for the table
  const formatData = (): FormatedDataOrderList[] => {
    if (
      orderList &&
      orderList.orderResponseList &&
      orderList.orderResponseList.length
    ) {
      return orderList.orderResponseList.map((item) => ({
        id: item.orderId,
        canceledOrder: item.cancelOrder,
        delegate: item.delegate
          ? JSON.stringify({
              userId: item.delegate.userId,
              name: `${item.delegate.firstName} ${item.delegate.lastName}`,
              photoUrl: item.delegate.photoUrl,
            })
          : JSON.stringify({}),
        client: item.doctor
          ? JSON.stringify({ ...item.doctor })
          : item.seller
          ? JSON.stringify({ ...item.seller })
          : JSON.stringify({}),
        facNumb: item?.numFact || "-",
        orderCreationDate: item.dateCreation,
        potential:
          potentials && potentials.length
            ? item?.doctor?.potentialId
              ? potentials.find(() => item?.doctor?.potentialId)
                  ?.potentialName || "-"
              : item?.seller?.potential
              ? potentials.find(() => item?.seller?.potential)?.potentialName ||
                "-"
              : "-"
            : "-",
        desiredDeliveryDate: item.dateDeadLineDelivery,
        deadLineDate: item.dateDeadLine,
        delivery: item.statutDelivery,
        payment: item.codeStatusPayment,
        priceHT: +(item?.priceHT?.toFixed(3) || 0),
        priceTTC: +(item?.priceTTC?.toFixed(3) || 0),
        reference: item?.reference ? item?.reference : "-",
        afterDiscount:
          item.priceTTC && item.remise
            ? ( item.priceTTC - (item.priceTTC * item.remise) / 100).toFixed(3)
            : "-",
        unpaidAmount: item.payements && item.payements.remainingAmount
          ? item.payements?.remainingAmount.toFixed(3) || item.payements?.remainingAmount.toFixed(3)
          : item.priceTTC && item.remise
          ? (item.priceTTC - (item.priceTTC * item.remise) / 100).toFixed(3)
          : "-",
      }));
    } else return [];
  };
  const prepareData = () => ({
    data: formatData(),
    currentPageNumber: orderList?.pagination?.pageNumber || 1,
    pageSize: orderList?.pagination?.pageSize || 0,
    totalCount: orderList?.pagination?.totalCount || 0,
  });
  const { data, currentPageNumber, pageSize, totalCount } = prepareData();

  //Format the data that will be exported and export it
  const formatExportedData = (
    exportToCSV: (exportedData: any, filteName: string) => void
  ) => {
    dispatchOrderListForTheExportButton().then((res: FilteredOrders) => {
      let { orderResponseList } = res;
      if (orderResponseList && orderResponseList.length) {
        let result = orderResponseList.map((item) => ({
          "Référence commande": item?.reference ? item?.reference : "-",
          "Etat commande": item.cancelOrder ? "Commande annulée" : "-",
          Délégué: item.delegate
            ? `${item.delegate.firstName} ${item.delegate.lastName}`
            : "-",
          Client: item.doctor
            ? ` ${item.doctor.title} ${item.doctor.firstName} ${item.doctor.lastName}`
            : item.seller
            ? item.seller.sellerName
            : "-",
          "N° Facture": item.numFact,
          "Date commande":  getLocalDate(item?.dateCreation || undefined ),
          "Date de livraison souhaitée":  getLocalDate(item?.dateDeadLineDelivery || undefined ),
          "Date d'échance":  getLocalDate(item?.dateDeadLine || undefined ),
          "Etat livraison": orderStatus[item.statutDelivery.codeStatus],
          "Etat payment ": orderStatus[item.codeStatusPayment.codeStatus],
          "Prix HT": item?.priceHT?.toFixed(3),
          "Prix TTC": item?.priceTTC?.toFixed(3),
          Remise: item.remise,
          "Prix TTC après remise":
            item.priceTTC && item.remise
              ? (item.priceTTC - (item.priceTTC * item.remise) / 100).toFixed(3)
              : "-",
          "Reste à payer": item.payements && item.payements.remainingAmount
            ? (item.payements.remainingAmount).toFixed(3)
            : item?.priceTTC?.toFixed(3) || "-",
        }));
        exportToCSV(result, "Liste des commandes");
      } else {
        exportToCSV([{ Commentaire: "liste vide" }], "Liste des commandes");
      }
    });
  };

  return (
    <div className="order-template__wrapper">
      <div className="top-area">
        <SearchBar
          placeHolder="Recherche"
          setSearchTerm={setSearchTerm}
          searchFieldIsLoading={searchFieldIsLoading}
          typeClasse="order-search-bar"
        />
        <div className="filter-export-btns">
          <OrderFilters 
            captureFilter={captureFilter}
            setAppliedFilters={setAppliedFilters}
            dateValue={getDateValue(globalFilterState)}
          />
          <FileExporter getDataAndFormatDataFunction={formatExportedData} />
        </div>
      </div>
      <FilterList filterList={appliedFilters} />
      <div className="table-area">
        <OrderTables
          orderRows={data}
          setIsDetailsModalOpen={setIsDetailsModalOpen}
          setContext={setContext}
          capturePageTarget={capturePageTarget}
          pageSize={pageSize}
          currentPageNumber={currentPageNumber}
          captureLimitTarget={captureLimitTarget}
          totalCount={totalCount}
          listIsLoaded={listIsLoaded}
          actionButtonContext={context}
          setClientInfo={setClientInfo}
        />
      </div>
      <Details
        isModalOpen={isDetailsModalOpen}
        setIsModalOpen={setIsDetailsModalOpen}
        context={context}
        requestState={requestState}
        orderDetails={orderDetails}
        paymentDetails={paymentDetails}
        deliveryDetails={deliveryDetails}
        clientInfo={clientInfo}
      />
    </div>
  );
}

