import React from "react";
import { UseFormHandleSubmit } from "react-hook-form";
import { Form } from "rsuite";
import { updateValues } from "../../../../../helpers/dataFormator";
import { TextField } from "../../../../02-molecules/forms/TextField/TextField";
import "../AddSalesTargetForm.scss";
type Props = {
  inputName: string;
  label: string;
  rules?: any;
  setTotal?: React.Dispatch<React.SetStateAction<number>>;
  setSalesQuantityByMonth?: React.Dispatch<React.SetStateAction<any[]>>;
  salesQuantityByMonth?: any[];
  control: any;
  handleSubmit: UseFormHandleSubmit<any>;
  errors: any;
  color?:string;
  inputboldness?:string;
};

export default function AddSalesInput({
  inputName,
  label,
  rules,
  setTotal,
  setSalesQuantityByMonth,
  salesQuantityByMonth,
  handleSubmit,
  control,
  errors,
  color,
  inputboldness,
}: Props) {

 
  const onSubmit = handleSubmit((data) => {
    setTotal && data?.total && setTotal(data.total);
    setSalesQuantityByMonth && setSalesQuantityByMonth(updateValues(salesQuantityByMonth|| [], inputName, data[inputName]));
  });
  return (
    <Form>
      <TextField
        label={label}
        inputName={inputName}
        rules={rules}
        control={control}
        errors={errors}
        xs={24}
        sm={7}
        md={7}
        onSubmit={onSubmit}
        className="salest-Target-Text-Input"
        color={color}
        inputboldness={inputboldness}
        
      />
    </Form>
  );
}
