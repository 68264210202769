import React from "react";
import DateSeparator from "../../../02-molecules/inbox/DateSeparator/DateSeparator";
import MessageItem from "../../../02-molecules/inbox/MessageItem/MessageItem";
import "./ChannelContent.scss";
type Props = {};

const ChannelContent = (props: Props) => {
  return (
    <div className="phalcon-channel-content__wrapper">
      <div className="phalcon-channel-content__inner">
        <div className="phalcon-channel-content__inner--header">
          <div className="phalcon-channel-title">Général</div>
          <div className="phalcon-channel-members">stack</div>
        </div>
        <div className="phalcon-channel-content__inner--main phalcon-scroll-bar">
          <MessageItem />
          <MessageItem />
          <MessageItem />
          <MessageItem />
          <DateSeparator date={new Date()} />
          <MessageItem />
          <MessageItem />
          <MessageItem />
          <MessageItem />
          <MessageItem last />
        </div>
      </div>
    </div>
  );
};

export default ChannelContent;
