import { getBiGeneralData } from "./../../../../redux/Bi/biSlice";
import { useAppSelector, useAppDispatch } from "./../../../../redux/hooks";
import { useEffect, useMemo } from "react";
import { GeneralDataSource } from "../../../../types/GeneralDataSource.model";
import { RootState } from "../../../../redux/store";
import { Potential } from "../../../../types/Potential.model";
import _ from "lodash";

export interface Serie {
    name: string,
    data: number[],
}
 

const prepareGenralsDoctorsBySpeciality = (
  data: GeneralDataSource,
  criteria: string
) => {
  let labels : string []= data.doctorsBySpeciality.map(doc=> doc.specialityName);
  let series = data.doctorsBySpeciality.map(
    ({count}) => count
  );
  return {
    labels : labels, 
    series : series,
  }
};


const prepareGenraFacilityByType = (
  data: GeneralDataSource,
  criteria: string
) => {
  let labels : string []= data.facilitiesByType.map(facility=> facility.facilityTypeName);
  let series = data.facilitiesByType.map(
    ({count}) => count
  );
  return {
    labels : labels, 
    series : series,
  }
};
interface GeneralOptions {
  data: GeneralDataSource;
  criteria: {
    field: string;
    displayField: string;
  };
}

const prepareGeneralsDoctorDelegatesByPotential = (options: GeneralOptions, potentials :Potential[] ) => {
  const { data } = options;
  let seriesData : Serie[] = potentials.map(potential => {
    let serie : Serie = {
      name : potential.potentialName,
      data: data.portfoliosByPotential.map(portfolioPotential => {
        let result  = portfolioPotential.potentials.find((potentialSet => potentialSet.potential.potentialId  === potential.potentialId))
        if(result !== undefined){
          return result.count
        } else {
          return 0
        }
      })
    } 
    return serie
  })
  return {
    xAxis: data.portfoliosByPotential.map(({delegate})=> `${delegate.firstName} ${delegate.lastName}`),
    series: seriesData
  };
}; 


const prepareGeneralsCount = (data: GeneralDataSource) => {
  return { 
    totalDelegate: data.delegatesCount,
    doctorCountByPotential: {
        total: data.doctorsByPotential.reduce((prev, current) => current.count + prev,0),
        potential: data.doctorsByPotential
    },
   totalFacilities : data.facilitiesCount, 
   totalOffices: data.officesCount,
  };
};

const preparePotentialByLocality = (data: GeneralDataSource) => _.orderBy(data.doctorsGeographics, [locality => locality.localityName.toLowerCase()], ['asc']) 

interface props {}

const useGeneralData = (props: props) => {
  const dispatch = useAppDispatch();
  const { biGeneralData } = useAppSelector((state: RootState) => state.bi);
  const { potentials } = useAppSelector((state: RootState) => state.general);

  useEffect(() => {
    dispatch(
        getBiGeneralData() 
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const GeneralsCount = useMemo(
    () => prepareGeneralsCount(biGeneralData),
    [biGeneralData]
  );

  const generalsDoctorBySpeciality = useMemo(
    () => prepareGenralsDoctorsBySpeciality(biGeneralData, "doctorBySpeciality"),
    [biGeneralData]
  );

  const generalsFacilitiesByType = useMemo(
    () => prepareGenraFacilityByType(biGeneralData, "facilitiesByType"),
    [biGeneralData]
  );
  const generalsDoctorDelegatesByPotential = useMemo(
    () =>
    prepareGeneralsDoctorDelegatesByPotential({
        data: biGeneralData,
        criteria: { 
          field: "potentialId",
          displayField: "potentialName",
        },
      }, potentials),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [biGeneralData]
  );
  const generalPotentialByLocality = useMemo(
    () =>
    preparePotentialByLocality(biGeneralData),
    [biGeneralData]
  );

  return {
    GeneralsCount,
    generalsDoctorBySpeciality,
    generalsFacilitiesByType,
    generalsDoctorDelegatesByPotential,
    generalPotentialByLocality

  };
};

export default useGeneralData;

