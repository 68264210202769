import { DoctorSummary } from "./../../../../types/DoctorSummary.model";
import { RootState } from "./../../../../redux/store";
import { useAppDispatch, useAppSelector } from "./../../../../redux/hooks";
import { useEffect, useState } from "react";
import { GetActiveRequests } from "../../../../redux/Approval/approvalSlice";
import { Approval } from "../../../../types/Approval.model";
import UserSummary from "../../../../types/UserSummary.model";

const useValidation = () => {
  const dispatch = useAppDispatch();
  const { DoctorApprovals, requestState } = useAppSelector(
    (state: RootState) => state.approval
  );

  useEffect(() => {
    dispatch(GetActiveRequests());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [approvalData, setApprovalData] = useState<any>([]);

  const getDoctorFullName = (doctor: DoctorSummary) =>
    doctor.title + ". " + doctor.firstName + " " + doctor.lastName;

  const getDoctorPrimarySpeciality = (doctor: DoctorSummary) => {
    const primary = doctor.specialities.find((spec) => spec.isPrincipal);

    return {
      specialityName: primary?.specialityName,
      abreviation: primary?.abreviation,
    };
  };

  const getDoctorPotential = (doctor: DoctorSummary) => ({
    potentialId: doctor.potential.potentialId,
    potentialName: doctor.potential.potentialName,
  });

  const getDelegate = (user: UserSummary) => ({
    firstName: user?.firstName || "-",
    lastName: user?.lastName || "-",
    userId: user?.userId || 0,
    photoUrl: user?.photoUrl || "-",
  });

  const getFacility = (doctor: DoctorSummary) => {
    let result = {
      facility: doctor?.principalAddress?.facilityName || "",
      governorate:
        doctor?.principalAddress?.address?.localities?.find(
          (loc) => loc.level === 1
        )?.localityName || "",
      delegation:
        doctor?.principalAddress?.address?.localities?.find(
          (loc) => loc.level === 2
        )?.localityName || "",
    };

    return result;
  };

  const getPhone = (doctor: DoctorSummary) =>
    doctor?.principalPhone?.phoneNumber || "";

  const formatData = (doctors: Approval[]) =>
    doctors &&
    doctors.length > 0 &&
    doctors.map((req) => {
      const { facility, governorate, delegation } = getFacility(
        req.doctor as DoctorSummary
      );
      return {
        id: req.approvalRequestId,
        doctor: {
          fullName: getDoctorFullName(req.doctor as DoctorSummary),
          doctorId: req.doctor.doctorId,
        },
        speciality: getDoctorPrimarySpeciality(req.doctor as DoctorSummary),
        potential: getDoctorPotential(req.doctor as DoctorSummary),
        facility,
        governorate,
        delegation,
        phoneNumber: getPhone(req.doctor as DoctorSummary),
        delegate: getDelegate(req.requestedBy),
        type: req.type,
      };
    });

  useEffect(() => {
    if (
      DoctorApprovals &&
      DoctorApprovals.length > 0 &&
      requestState === "idle"
    ) {
      const data = formatData(DoctorApprovals);
      setApprovalData(data);
    } 
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [DoctorApprovals, requestState]);

  return {
    approvalData,
    requestState,
    getDoctorFullName,
    getDoctorPrimarySpeciality,
    getDoctorPotential,
    getDelegate,
    getFacility,
    getPhone,
  };
};

export default useValidation;
