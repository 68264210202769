import { Box } from "@material-ui/core";
import { DataGrid, GridCellParams, GridColDef } from "@material-ui/data-grid";
import React, { useState } from "react";
import {
  handleColorAccordingTotheDiffBetweenValueForSalesTarget,
  reduceArrayFromArrayOfObjectsToArrayOfNumbers,
} from "../../../../helpers/dataFormator";
import { useAppDispatch } from "../../../../redux/hooks";
import { deleteSalesTarget } from "../../../../redux/SalesTarget/salesTargetSlice";
import {
  ExpectedSalesTargetTableRowsInterface,
  SalesTargetUsedInFormInterface,
} from "../../../../types/SalesTarget.model";
import localizedTextsMap from "../../../../utils/dataGridColumnMenuLanguage";
import { AlertDeleteModal } from "../../../02-molecules/modals/AlertDeleteModal/AlertDeleteModal";
import ActionButtonSalesTargetCell from "../../../03-organisms/tables/common/Cells/ActionButtonSalesTargetCell";
import TextCell from "../../../03-organisms/tables/common/Cells/TextCell";

type Props = {
  data: any[];
  salesTargetIsLoaded: "idle" | "loading";
  context: "achievedSales" | "estimatedSales";
  captureLimitTarget: (
    limit: number,
    context: "achievedSales" | "estimatedSales"
  ) => void;
  capturePageTarget: (
    page: number,
    context: "achievedSales" | "estimatedSales"
  ) => void;
  pageSize: number;
  totalCount: number;
  handleOpenSalesTargetModal: () => void;
  listToCompare?: ExpectedSalesTargetTableRowsInterface[];
  setUpdateSalesTargetObject?: React.Dispatch<
    React.SetStateAction<{
      productId: number;
      salesObject: SalesTargetUsedInFormInterface;
      monthsId: { label: number | string; value: number | string | null }[];
    } | null>
  >;
  setFormContext?: React.Dispatch<
    React.SetStateAction<"addSalesTarget" | "updateSalesTarget">
  >;
};

export default function SalesTargetTable({
  data,
  salesTargetIsLoaded,
  context,
  capturePageTarget,
  captureLimitTarget,
  totalCount,
  pageSize,
  handleOpenSalesTargetModal,
  setUpdateSalesTargetObject,
  setFormContext,
  listToCompare,
}: Props) {
  const dispatch = useAppDispatch();
  const [openDeleteModal, setOpenDeleteModal] = useState<boolean>(false);
  const [listOfSalesTargetIds, setListOfSalesTargetIds] = useState<
    { label: string; value: number | null }[]
  >([]);

  const columns: GridColDef[] = [
    {
      field: "id",
      headerName: "ID",
      width: 0,
      hide: true,
      sortable: false,
      filterable: false,
    },
    {
      field: "productName",
      headerName: "Produit",
      width: 300,
      flex: 1,
      sortable: false,
      filterable: false,
      renderCell: (params: GridCellParams) => {
        return <TextCell item={params.row.productName} toolTipeText={params?.row?.productName || ""}/>;
      },
    },
    {
      field: "january",
      headerName: "Janv.",
      width: 100,
      sortable: false,
      filterable: false,
      renderCell: (params: GridCellParams) => {
        return (
          <TextCell
            item={params.row.january}
            color={
              listToCompare
                ? handleColorAccordingTotheDiffBetweenValueForSalesTarget(
                    params.row.productId,
                    listToCompare,
                    params.row.january,
                    "january"
                  )
                : undefined
            }
          />
        );
      },
    },
    {
      field: "february",
      headerName: "Févr.",
      width: 90,
      sortable: false,
      filterable: false,
      renderCell: (params: GridCellParams) => {
        return (
          <TextCell
            item={params.row.february}
            color={
              listToCompare
                ? handleColorAccordingTotheDiffBetweenValueForSalesTarget(
                    params.row.productId,
                    listToCompare,
                    params.row.february,
                    "february"
                  )
                : undefined
            }
          />
        );
      },
    },
    {
      field: "march",
      headerName: "Mars",
      width: 90,
      sortable: false,
      filterable: false,
      renderCell: (params: GridCellParams) => {
        return (
          <TextCell
            item={params.row.march}
            color={
              listToCompare
                ? handleColorAccordingTotheDiffBetweenValueForSalesTarget(
                    params.row.productId,
                    listToCompare,
                    params.row.march,
                    "march"
                  )
                : undefined
            }
          />
        );
      },
    },
    {
      field: "april",
      headerName: "Avr.",
      width: 90,
      sortable: false,
      filterable: false,
      renderCell: (params: GridCellParams) => {
        return (
          <TextCell
            item={params.row.april}
            color={
              listToCompare
                ? handleColorAccordingTotheDiffBetweenValueForSalesTarget(
                    params.row.productId,
                    listToCompare,
                    params.row.april,
                    "april"
                  )
                : undefined
            }
          />
        );
      },
    },
    {
      field: "may",
      headerName: "Mai",
      width: 90,
      sortable: false,
      filterable: false,
      renderCell: (params: GridCellParams) => {
        return (
          <TextCell
            item={params.row.may}
            color={
              listToCompare
                ? handleColorAccordingTotheDiffBetweenValueForSalesTarget(
                    params.row.productId,
                    listToCompare,
                    params.row.may,
                    "may"
                  )
                : undefined
            }
          />
        );
      },
    },
    {
      field: "june",
      headerName: "Juin",
      width: 90,
      sortable: false,
      filterable: false,
      renderCell: (params: GridCellParams) => {
        return (
          <TextCell
            item={params.row.june}
            color={
              listToCompare
                ? handleColorAccordingTotheDiffBetweenValueForSalesTarget(
                    params.row.productId,
                    listToCompare,
                    params.row.june,
                    "june"
                  )
                : undefined
            }
          />
        );
      },
    },
    {
      field: "july",
      headerName: "Juill.",
      width: 90,
      sortable: false,
      filterable: false,
      renderCell: (params: GridCellParams) => {
        return (
          <TextCell
            item={params.row.july}
            color={
              listToCompare
                ? handleColorAccordingTotheDiffBetweenValueForSalesTarget(
                    params.row.productId,
                    listToCompare,
                    params.row.july,
                    "july"
                  )
                : undefined
            }
          />
        );
      },
    },
    {
      field: "august",
      headerName: "Août",
      width: 90,
      sortable: false,
      filterable: false,
      renderCell: (params: GridCellParams) => {
        return (
          <TextCell
            item={params.row.august}
            color={
              listToCompare
                ? handleColorAccordingTotheDiffBetweenValueForSalesTarget(
                    params.row.productId,
                    listToCompare,
                    params.row.august,
                    "august"
                  )
                : undefined
            }
          />
        );
      },
    },
    {
      field: "september",
      headerName: "Sept.",
      width: 90,
      sortable: false,
      filterable: false,
      renderCell: (params: GridCellParams) => {
        return (
          <TextCell
            item={params.row.september}
            color={
              listToCompare
                ? handleColorAccordingTotheDiffBetweenValueForSalesTarget(
                    params.row.productId,
                    listToCompare,
                    params.row.september,
                    "september"
                  )
                : undefined
            }
          />
        );
      },
    },
    {
      field: "october",
      headerName: "Oct.",
      width: 90,
      sortable: false,
      filterable: false,
      renderCell: (params: GridCellParams) => {
        return (
          <TextCell
            item={params.row.october}
            color={
              listToCompare
                ? handleColorAccordingTotheDiffBetweenValueForSalesTarget(
                    params.row.productId,
                    listToCompare,
                    params.row.october,
                    "october"
                  )
                : undefined
            }
          />
        );
      },
    },
    {
      field: "november",
      headerName: "Nov.",
      width: 90,
      sortable: false,
      filterable: false,
      renderCell: (params: GridCellParams) => {
        return (
          <TextCell
            item={params.row.november}
            color={
              listToCompare
                ? handleColorAccordingTotheDiffBetweenValueForSalesTarget(
                    params.row.productId,
                    listToCompare,
                    params.row.november,
                    "november"
                  )
                : undefined
            }
          />
        );
      },
    },
    {
      field: "december",
      headerName: "Déc.",
      width: 90,
      sortable: false,
      filterable: false,
      renderCell: (params: GridCellParams) => {
        return (
          <TextCell
            item={params.row.december}
            color={
              listToCompare
                ? handleColorAccordingTotheDiffBetweenValueForSalesTarget(
                    params.row.productId,
                    listToCompare,
                    params.row.december,
                    "december"
                  )
                : undefined
            }
          />
        );
      },
    },
    {
      field: "totalQuantity",
      headerName: "Total",
      width: 90,
      sortable: false,
      filterable: false,
      renderCell: (params: GridCellParams) => {
        return <TextCell item={params.row.totalQuantity} textBoldness="800"
        color={
          listToCompare
            ? handleColorAccordingTotheDiffBetweenValueForSalesTarget(
                params.row.productId,
                listToCompare,
                params.row.totalQuantity, 
                "totalQuantity"
              )
            : undefined
        }
        />;
      },
    },
  ];

  let columnsByContext =
    context === "achievedSales"
      ? columns
      : columns.concat({
          field: "action",
          headerName: "Action",
          width: 90,
          sortable: false,
          filterable: false,
          renderCell: (params: GridCellParams) => {
            return (
              <ActionButtonSalesTargetCell
                setListOfSalesTargetIds={setListOfSalesTargetIds}
                setOpenDeleteModal={setOpenDeleteModal}
                key={params.row.productId}
                objectParams={params.row}
                handleOpenSalesTargetModal={() => {
                  setFormContext && setFormContext("updateSalesTarget");
                  handleOpenSalesTargetModal();
                }}
                setUpdateSalesTargetObject={setUpdateSalesTargetObject}
              />
            );
          },
        });

  // delete target sales
  const handleSalesTargetDelete = () => {
    let ids: number[] =
      reduceArrayFromArrayOfObjectsToArrayOfNumbers(listOfSalesTargetIds);
    dispatch(deleteSalesTarget(ids));
  };

  return (
    <>
      <Box sx={{ height: 320, width: "100%", bgcolor: "white" }}>
        <DataGrid
          rows={data}
          columns={columnsByContext}
          loading={salesTargetIsLoaded === "loading"}
          rowsPerPageOptions={[10, 50, 100]}
          disableSelectionOnClick
          localeText={localizedTextsMap}
          paginationMode="server"
          onPageChange={(pageNumber: number) => {
            capturePageTarget(pageNumber, context);
          }}
          onPageSizeChange={(limit: number) => {
            captureLimitTarget(limit, context);
          }}
          pageSize={pageSize}
          rowCount={totalCount}
        />
      </Box>
      <AlertDeleteModal
        open={openDeleteModal}
        handleClose={() => {
          setOpenDeleteModal(false);
        }}
        name={"l'objectif de vente"}
        handleDelete={() => {
          handleSalesTargetDelete();
          setOpenDeleteModal(false);
        }}
      />
    </>
  );
}
