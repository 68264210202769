import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import { Button, Divider, FlexboxGrid, Form, Loader, Modal } from "rsuite";

import { SelectField } from "../../../02-molecules/forms/SelectField/SelectField";
import { TagsField } from "../../../02-molecules/forms/TagsField/TagsField";
import { TextField } from "../../../02-molecules/forms/TextField/TextField";

import { useAppDispatch, useAppSelector } from "../../../../redux/hooks";
import useSellerForm, {
  fieldIsRequired,
  prepareSellerFormPayload,
  SellerFormInputs,
} from "./useSellerForm";
import { addSeller, updateSeller } from "../../../../redux/Seller/sellerSlice";
import { PhonesBlock } from "../phonesBlock/PhonesBlock";
import AddressBlock from "../addressBlock/AddressBlock";
import { RootState } from "../../../../redux/store";

type Props = {
  formContext: "add" | "update";
};

const SellerForm = ({ formContext }: Props) => {
  const dispatch = useAppDispatch();
  const { requestStateForm } = useAppSelector((state: RootState) => state.seller);

  const { formIsReady, formOptions, formUI, initialValues, setFormIsReady } =
    useSellerForm({ formContext });

  const {
    control,
    handleSubmit,
    reset,
    setValue,
    formState: { errors },
  } = useForm<SellerFormInputs>({
    defaultValues: initialValues,
    criteriaMode: "all",
    reValidateMode: "onChange",
  });

  useEffect(() => {
    reset(initialValues);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialValues, requestStateForm]);

  const onSubmit = handleSubmit((data) => {

    
    const payload = prepareSellerFormPayload(data);
    console.log(payload, "data");
    formContext === "add" && dispatch(addSeller(payload));
    formContext === "update" && dispatch(updateSeller(payload));
  });
  return (
    <Form className="add__doctor__form__wrapper">
      <Modal
        full
        open={formUI?.formIsOpen}
        onClose={formUI?.toggleForm}
        backdrop="static"
        onExited={() => setFormIsReady(false)}
        dialogClassName="form-modal"
      >
        <Modal.Header>
          <Modal.Title>
            {formContext === "add" ? "Ajouter" : "Modifier"} un Pharma
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="custom-modal-body">
          {formIsReady ? (
            <>
              <div className="custom-form-section">
                <h3 className="form-block-title">Informations générales</h3>
                <Divider />
                <FlexboxGrid align="bottom">
                  {/* sellerType */}
                  <SelectField
                    control={control}
                    errors={errors}
                    inputName="sellerTypeId"
                    rules={{ required: fieldIsRequired }}
                    xs={24}
                    sm={24}
                    md={5}
                    data={formOptions?.sellerTypeOptions || []}
                    cleanable={false}
                    searchable={false}
                    label={"Type"}
                  />
                  {/* sellerName */}
                  <TextField
                    label={"Nom de l'établissement"}
                    inputName="sellerName"
                    rules={{ required: fieldIsRequired }}
                    control={control}
                    errors={errors}
                    xs={24}
                    sm={24}
                    md={8}
                  />
                  {/* potentialId */}
                  <SelectField
                    control={control}
                    errors={errors}
                    inputName="potentialId"
                    rules={{ required: fieldIsRequired }}
                    xs={24}
                    sm={24}
                    md={4}
                    data={formOptions?.potentialsOptions || []}
                    cleanable={false}
                    searchable={false}
                    label={"Potentiel"}
                  />
                  {/* email */}
                  <TextField
                    label={"Email"}
                    inputName="email"
                    control={control}
                    rules={{
                      validate: (value: any) => {
                        if (value)
                          return (
                            /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(
                              value
                            ) || "Email non valide"
                          );
                      },
                    }}
                    errors={errors}
                    xs={24}
                    sm={24}
                    md={7}
                  />
                </FlexboxGrid>

                <FlexboxGrid align="bottom">
                  {/* businessUnits */}
                  <TagsField
                    control={control}
                    errors={errors}
                    inputName="businessUnits"
                    rules={{ required: fieldIsRequired }}
                    xs={24}
                    sm={24}
                    md={6}
                    data={formOptions?.businessUnitsOptions || []}
                    cleanable={false}
                    label={"Business Unit"}
                  />
                  {/* reference */}
                  <TextField
                    label={"Référence"}
                    inputName="reference"
                    control={control}
                    errors={errors}
                    xs={24}
                    sm={24}
                    md={6}
                  />
                  {/* shift */}
                  <SelectField
                    control={control}
                    errors={errors}
                    inputName="shift"
                    rules={{ required: fieldIsRequired }}
                    xs={24}
                    sm={24}
                    md={4}
                    data={formOptions?.shiftOptions || []}
                    cleanable={false}
                    searchable={false}
                    label={"Shift"}
                  />
                  {/* webSite */}
                  <TextField
                    label={"Site Web"}
                    inputName="webSite"
                    control={control}
                    errors={errors}
                    xs={24}
                    sm={24}
                    md={8}
                  />
                </FlexboxGrid>
                {/* adress Block */}
                <AddressBlock
                  localitiesField={`address.localities`}
                  streetNameField={`address.streetName`}
                  noteAdressField={`address.addressNote`}
                  initialLocalities={
                    initialValues?.address?.localities || undefined
                  }
                  fieldIsRequired={fieldIsRequired}
                  control={control}
                  errors={errors}
                  noteAddress
                  setValue={setValue}
                  formContext={formContext}
                />
              </div>
                {/* Tags Block */}
                <div className="custom-form-section">
                <FlexboxGrid align="bottom">
                  <TagsField
                    control={control}
                    errors={errors}
                    creatable
                    data={formOptions ? formOptions.tagsOptions : []}
                    xs={24}
                    label={"Tags"}
                    inputName={"tags"}
                    
                  />
                </FlexboxGrid>
              </div>
              <PhonesBlock
                fieldIsRequired={fieldIsRequired}
                control={control}
                errors={errors}
              />
            
            </>
          ) : (
            <Loader size="lg" center content="loading" />
          )}
        </Modal.Body>

        <Modal.Footer style={{ paddingTop: "1rem" }}>
          <Button onClick={formUI?.toggleForm}>Annuler</Button>
          <Button
            className="database-submit-button"
            type="submit"
            appearance="primary"
            onClick={() => onSubmit()}
          >
            Enregistrer
          </Button>
        </Modal.Footer>
      </Modal>
    </Form>
  );
};

export default SellerForm;
