import React from "react";
import { mdiChevronRight } from "@mdi/js";
import { Locality } from "../../../../types/Locality.model";
import { StandardIcon } from "../../../01-atoms/Icons/Icons";

type Props = {
  localities: Locality[];
  streetName: string;
  loading: boolean;
  noteAdress: string;
  facilityName?: string;
  service?: { serviceName: string; isChief: boolean }[] | null;
};

export const InfoItemExtraAddress = ({ 
  localities,
  streetName,
  loading,
  noteAdress,
  facilityName,
  service,
 }: Props) => {
  return (
    <div className="extra-address-item">
          <dl className="content">
            <dt> {facilityName ? facilityName : ""}</dt>
            <dd>
              {localities
                .slice(0, 3)
                .map((loc: Locality) => loc.localityName)
                .join(", ")}
            </dd>
            <dd>
              {localities
                .slice(3, 4)
                .map((loc: Locality) => loc.localityName)
                .join(", ")}
            </dd>
            <dd>{streetName}</dd>
            {service &&
              service.length > 0 &&
              service.map((el) => (
                <dd className="service-info-item">
                  <StandardIcon
                    icon={mdiChevronRight}
                    isNotWrapped={true}
                    size={0.6}
                  />
                  {el.serviceName} {el.isChief && "(Chef)"}
                </dd>
              ))}
          </dl>
        </div>
  )
};
