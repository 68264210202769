import React, { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import { Modal, Button, Form, Divider, FlexboxGrid, Toggle } from "rsuite";
import {
  closePlanificationConfigModal,
  createPlanification,
} from "../../../redux/Planification/planificationSlice";
import { useForm } from "react-hook-form";
import { RootState } from "../../../redux/store";
import "./PlanificationModal.scss";
import { DateField } from "../../02-molecules/forms/DateField/DateField";
import { getUtcDate } from "../../../utils/dateHelpers";
import { NumberField } from "../../02-molecules/forms/NumberField/NumberField";
import moment from "moment";
type PlanificationConfigFormInputs = {
  targetId: number;
  startDate: Date | null;
  endDate: Date | null;
  nbrOccurence: number | null;
};
type Props = {};

const PlanificationConfigModal = (props: Props) => {
  const dispatch = useAppDispatch();
  const { planificationConfigModalIsOpen , requestState} = useAppSelector(
    (state: RootState) => state.planification
  );
  const { targetById } = useAppSelector((state: RootState) => state.target);
  const [selectStartDate, setSlectedStartDate] = useState<boolean>(true);
  const initialValues = {
    targetId: targetById ? targetById?.targetId : 0,
    startDate: null,
    endDate: null,
    nbrOccurence: null,
  };

  const {
    control,
    handleSubmit,
    reset,
    setValue,
    getValues,
    formState: { errors },
  } = useForm<PlanificationConfigFormInputs>({
    defaultValues: initialValues,
    criteriaMode: "all",
    reValidateMode: "onChange",
  });
  const [isOccurence, setIsOccurence] = useState<boolean>(false);
  const onSubmit = handleSubmit((data) => {
    dispatch(
      createPlanification({
        targetId: data.targetId,
        startDate: data.startDate ? getUtcDate(data.startDate) : null,
        endDate: data.endDate ? getUtcDate(data.endDate) : null,
        nbrOccurence: data.nbrOccurence,
      })
    );
  });
  const handleSelectedStartDate = (
    date: string | Date | undefined | null,
    name: string
  ) => {
    if (date && name === "startDate") {
      setSlectedStartDate(false);
    } else if (!date && name === "startDate") {
      setSlectedStartDate(true);
    }
  };
  useEffect(() => {
    let starDateValue = getValues("startDate");
    if (!starDateValue) {
      let valuesAfterStartDateClear = getValues();
      valuesAfterStartDateClear.endDate = null;
      reset(valuesAfterStartDateClear);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getValues("startDate")]);
  useEffect(() => {
    isOccurence && setValue("endDate", null);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOccurence]);
  useEffect(() => {
    reset(initialValues);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [targetById]);
  return (
    <div className="planification-modal">
      <Form className="add__doctor__form__wrapper">
        <Modal
          size="sm"
          open={planificationConfigModalIsOpen}
          onClose={() => dispatch(closePlanificationConfigModal())}
        >
          <Modal.Header closeButton={false} style={{ padding: "0" }}>
            <Modal.Title
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <div>Configuration de La planification</div>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body style={{  overflow: "hidden" }} >
            <div className="custom-form-section" style={{ height: "600px" }}>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-around",
                }}
              >
                <div>Occurences</div>
                <Toggle
                  size="md"
                  defaultChecked
                  onChange={(checked) => setIsOccurence(!isOccurence)}
                />
                <div>Date fin</div>
              </div>
              <Divider />

              <FlexboxGrid align="bottom">
                <DateField
                  control={control}
                  errors={errors}
                  rules={{ required: "Ce champ est requis" }}
                  key={"startDate"}
                  inputName="startDate"
                  label="Date début"
                  xs={12}
                  handleDateSelection={handleSelectedStartDate}
                />
                {isOccurence ? (
                  <NumberField
                    label={"Nombre d'occurences"}
                    inputName={"nbrOccurence"}
                    min ={1}
                    rules={
                      isOccurence
                        ? { required: "Ce champ est requis" }
                        : undefined
                    }
                    control={control}
                    xs={12}
                    errors={errors}
                  />
                ) : (
                  <DateField
                    control={control}
                    errors={errors}
                    disabled={selectStartDate}
                    rules={
                      !isOccurence
                        ? { required: "Ce champ est requis" }
                        : undefined
                    }
                    key={"endDate"}
                    inputName="endDate"
                    label="Date fin"
                    xs={12}
                    disabledDate={(date: Date) => {
                      if (control?._fields?.startDate?._f?.value) {
                        return moment(date.toString()).isBefore(
                          moment(control._fields.startDate._f.value).subtract(
                            0,
                            "days"
                          )
                        );
                      } else {
                        return false;
                      }
                    }}
                  />
                )}
              </FlexboxGrid>
            </div>
          </Modal.Body>

          <Modal.Footer style={{ paddingTop: "1rem" }}>
            <Button onClick={() => dispatch(closePlanificationConfigModal())}>
              Annuler
            </Button>
            <Button
              className="database-submit-button"
              type="submit"
              appearance="primary"
              onClick={() => onSubmit()}
              loading={requestState === "loading"}
            >
              Enregistrer 
            </Button>
          </Modal.Footer>
        </Modal>
      </Form>
    </div>
  );
};

export default PlanificationConfigModal;
