import React from "react";
import { useForm } from "react-hook-form";
import { Form } from "rsuite";
import { prepareInPortfolioOfOptions } from "../../../../helpers/GlobalFormHelpers";
import { useAppSelector } from "../../../../redux/hooks";
import { RootState } from "../../../../redux/store";
import { SelectField } from "../../../02-molecules/forms/SelectField/SelectField";

type Props = {
  captureDelegate: ((delegateId: number) => void) | undefined;
};

export default function DelegateSelectorButton({ captureDelegate }: Props) {
  const { teams } = useAppSelector((state: RootState) => state.general);

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<any>({
    defaultValues: [],
    criteriaMode: "all",
    reValidateMode: "onChange",
  });
  const onSubmit = handleSubmit((data) => {
    captureDelegate && captureDelegate(data.delegate);
  });
  return (
    <Form>
      <SelectField
        control={control}
        data={prepareInPortfolioOfOptions(teams) || []}
        errors={errors}
        inputName="delegate"
        label=""
        rules={null}
        placeholder="Choisir un délégué"
        onSubmit={onSubmit}
      />
    </Form>
  );
}
