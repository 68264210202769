import { mdiChevronUp } from "@mdi/js";
import Icon from "@mdi/react";
import React, { useEffect, useState } from "react";
import "./ScrollToTop.scss";
type Props = {};

const ScrollToTop = (props: Props) => {
  const handleScrollTop = () => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  };
  const [y, setY] = useState(window.scrollY);
  const [buttonIsVisible, setButtonIsVisible] = useState(false);

  const handleNavigation = (e: any) => {
    setY(window.scrollY);
  };

  useEffect(() => {
    window.addEventListener("scroll", (e) => handleNavigation(e));
    setButtonIsVisible(y !== 0);
    return () => {
      // return a cleanup function to unregister our function since its gonna run multiple times
      window.removeEventListener("scroll", (e) => handleNavigation(e));
    };
  }, [y]);

  return (
    <div
      className={`scroll-to-top ${buttonIsVisible ? "isVisible" : ""}`}
      onClick={handleScrollTop}
    >
      <Icon path={mdiChevronUp} size={1} />
    </div>
  );
};

export default ScrollToTop;
