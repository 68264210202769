import { useTranslation } from "react-i18next";
import Icon from "@mdi/react";
import {
  mdiCalendar,
  mdiCalendarStar,
  mdiCartOutline,
  mdiChartBox,
  mdiChartLine,
  mdiDatabase,
  mdiFileDocumentOutline,
  mdiInformationOutline,
  mdiTextBoxCheckOutline,
  mdiUpdate,
  mdiViewGridOutline,
} from "@mdi/js";
import { useEffect, useState } from "react";
import { getConnectedUserBu } from "./getConnectedData";
export type RouteConfig = {
  key: string;
  path: string;
  title: string;
  icon: any;
  children: { key: string; path: string; title: string }[];
  isSidebar: boolean;
};
export const useRoutingConfig = () => {
  const { t } = useTranslation();
  const bu: number | string = getConnectedUserBu()?.[0]|| ""
  let config: RouteConfig[] = [
    {
      key: "1",
      path: "/",
      title: t("drawer.home"),
      icon: <Icon path={mdiViewGridOutline} size={1} />,
      children: [
        {
          key: "1-1",
          path: "/",
          title: t("home_page.link_labels.general"),
        },
      ],
      isSidebar: true,
    },
    {
      key: "2",
      path: "/activity-tracking",
      title: t("drawer.activity_tracking"),
      icon: <Icon path={mdiChartBox} size={1} />,
      children: [],
      isSidebar: true,
    },
    {
      key: "3",
      path: "/cycles-management",
      title: t("drawer.cycles_management"),
      icon: <Icon path={mdiUpdate} size={1} />,
      children: [
        {
          key: "3-1",
          path: "general",
          title: t("cycle_management.link_labels.general"),
        },
      ],
      isSidebar: true,
    },
    {
      key: "4",
      path: "/agenda",
      title: t("drawer.agenda"),
      icon: <Icon path={mdiCalendar} size={1} />,
      children: [],
      isSidebar: false,
    },
    {
      key: "5",
      path: "/reports",
      title: t("drawer.reports"),
      icon: <Icon path={mdiFileDocumentOutline} size={1} />,
      children: [
        {
          key: "5-1",
          path: "report",
          title: t("reports_page.sub_routes.reports"),
        },
        {
          key: "5-2",
          path: "authorisationsManagement",
          title: t("reports_page.sub_routes.authorisationsManagement"),
        },
      ],
      isSidebar: true,
    },
    {
      key: "6",
      path: "/requests",
      title: t("drawer.requests"),
      icon: <Icon path={mdiInformationOutline} size={1} />,
      children: [
        {
          key: "6-1",
          path: "requests",
          title: "Demandes",
        },
        {
          key: "6-2",
          path: "objections",
          title: "Objections",
        },
      ],
      isSidebar: true,
    },

    {
      key: "7",
      path: "/rp",
      title: t("drawer.professional_relationship"),
      icon: <Icon path={mdiCalendarStar} size={1} />,
      children: [],
      isSidebar: false,
    },
    {
      key: "8",
      path: "/orders",
      title: t("drawer.orders"),
      icon: <Icon path={mdiCartOutline} size={1} />,
      children: [
        {
          key: "8-1",
          path: "commande",
          title: "Commandes",
        },
        {
          key: "8-2",
          path: "analytiques/commande",
          title: "Analytiques commande",
        },
        {
          key: "8-3",
          path: "objectif-de-vente",
          title: "Objectifs des ventes",
        },
      ],
      isSidebar: false,
    },
    {
      key: "9",
      path: "/validation",
      title: t("drawer.validation"),
      icon: <Icon path={mdiTextBoxCheckOutline} size={1} />,
      children: [
        {
          key: "9-1",
          path: "doctors",
          title: t("validation_page.sub_routes.doctors"),
        },
        // {
        //   key: "9-2",
        //   path: "pharmacies",
        //   title: t("validation_page.sub_routes.pharmacies"),
        // },
        // {
        //   key: "9-3",
        //   path: "sellers",
        //   title: t("validation_page.sub_routes.sellers"),
        // },
        // {
        //   key: "9-4",
        //   path: "facilities",
        //   title: t("validation_page.sub_routes.facilities"),
        // },
        // {
        //   key: "9-5",
        //   path: "rps",
        //   title: t("validation_page.sub_routes.rps"),
        // },
      ],
      isSidebar: true,
    },
    {
      key: "10",
      path: "/data-base",
      title: t("drawer.data_base"),
      icon: <Icon path={mdiDatabase} size={1} />,
      children: [
        {
          key: "10-1",
          path: "doctors",
          title: t("database_page.sub_routes.doctors"),
        },
        // {
        //   key: "10-2",
        //   path: "pharmacies",
        //   title: t("database_page.sub_routes.pharmacies"),
        // },
        // {
        //   key: "10-3",
        //   path: "sellers",
        //   title: t("database_page.sub_routes.sellers"),
        // },
        {
          key: "10-4",
          path: "facilities",
          title: t("database_page.sub_routes.facilities"),
        },
        {
          key: "10-5",
          path: "users",
          title: "Utilisateurs",
        },
        {
          key: "10-6",
          path: "products",
          title: "Produits/Actions",
        },
        {
          key: "10-7",
          path: "facilitytype",
          title: "Types d'établissements",
        },
        {
          key: "10-8",
          path: "Speciality",
          title: "Spécialités",
        },
   
      ],
      isSidebar: true,
    },

    {
      key: "11",
      path: "/analytics",
      title: t("drawer.analytics"),
      icon: <Icon path={mdiChartLine} size={1} />,
      children: [
        {
          key: "11-1",
          path: "phalcon-analytics/general",
          title: "BI",
        } /* ,
        {
          key: "11-2",
          path: "vr-analytics",
          title: "VR",
        }, */,
      ],
      isSidebar: true,
    },

    {
      key: "12",
      path: "/account",
      title: t("drawer.user_account"),
      icon: null,
      children: [
        {
          key: "12-1",
          path: "infos",
          title: t("account_page.link_labels.infos"),
        },
        {
          key: "12-2",
          path: "teams",
          title: t("account_page.link_labels.teams"),
        },
        {
          key: "12-3",
          path: "visits",
          title: t("account_page.link_labels.visits"),
        },
        // {
        //   key: "12-4",
        //   path: "rp",
        //   title: t("account_page.link_labels.rp"),
        // },
        {
          key: "12-5",
          path: "settings",
          title: t("account_page.link_labels.settings"),
        },
      ],
      isSidebar: false,
    },
    {
      key: "13",
      path: "/user",
      title: "",
      icon: null,
      children: [
        {
          key: "13-1",
          path: "infos",
          title: t("user_page.link_labels.infos"),
        },
        {
          key: "13-2",
          path: "agenda",
          title: t("user_page.link_labels.agenda"),
        },
        {
          key: "13-3",
          path: "visits",
          title: t("user_page.link_labels.visits"),
        },
        {
          key: "13-4",
          path: "requests",
          title: t("user_page.link_labels.requests"),
        },
        {
          key: "13-5",
          path: "objections",
          title: t("user_page.link_labels.objections"),
        },
        // {
        //   key: "13-6",
        //   path: "rp",
        //   title: t("user_page.link_labels.rp"),
        // },
        // {
        //   key: "13-7",
        //   path: "orders",
        //   title: t("user_page.link_labels.orders"),
        // },
      ],
      isSidebar: false,
    },
    {
      key: "14",
      path: "/doctor",
      title: "",
      icon: null,
      children: [
        {
          key: "14-1",
          path: "infos",
          title: t("user_page.link_labels.infos"),
        },
        {
          key: "14-2",
          path: "visits",
          title: t("user_page.link_labels.visits"),
        },
        {
          key: "14-3",
          path: "requests",
          title: t("user_page.link_labels.requests"),
        },
        {
          key: "14-4",
          path: "objections",
          title: t("user_page.link_labels.objections"),
        },
        // {
        //   key: "14-5",
        //   path: "rp",
        //   title: t("user_page.link_labels.rp"),
        // },
        // {
        //   key: "14-6",
        //   path: "orders",
        //   title: t("user_page.link_labels.orders"),
        // },
      ],
      isSidebar: false,
    },
  ];
  const [newConfig, setNewConfig] = useState<RouteConfig[]>(config)
  
 useEffect(() => {
  bu === 1 && setNewConfig(newConfig.map((item)=> item.key==="8" ? {...item, isSidebar: true} : item))
 // eslint-disable-next-line react-hooks/exhaustive-deps
 }, [])
  
  return newConfig;
};
