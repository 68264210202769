import { RootState } from "./../../../../redux/store";
import { getGovernorates } from "./../../../../redux/Locality/localitySlice";
import { useAppDispatch, useAppSelector } from "./../../../../redux/hooks";

import { useCallback, useEffect, useState } from "react";
import { Locality } from "./../../../../types/Locality.model";
import { axiosPrivate } from "../../../../utils/interceptors";
import { API_DEV } from "../../../../utils/apiRoute";
interface Props {
  initialLocalities?: Locality[];
}
export const useAddressBlock = ({ initialLocalities }: Props) => {
  const dispatch = useAppDispatch();

  const { governorates } = useAppSelector((state: RootState) => state.locality);
  const [localities, setLocalities] = useState<Locality[]>([]);
  const [localitiesOptions, setLocalitiesOptions] = useState<Locality[][]>([]);
  useEffect(() => {
    governorates.length === 0 && dispatch(getGovernorates());

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const prepareOptions = async (initialLocalities: Locality[]) => {
      let options: Locality[][] = [];
      for (let index = 0; index < initialLocalities.length; index++) {
        const element = initialLocalities[index];
        if (element.level < 4) {
          const sub = await getOptions(element.localityId);
          options = [...options, sub.subLocalities];
        }
      }
      return options;
    };
    if (
      initialLocalities !== undefined &&
      initialLocalities.length > 0 &&
      governorates.length > 0
    ) {
      prepareOptions(initialLocalities)
        .then((options) => {
          setLocalities(initialLocalities);
          setLocalitiesOptions([governorates, ...options]);
        })
        .catch(console.error);

      // setLocalitiesOptions(initialOptions);
    } else {
      setLocalitiesOptions([governorates]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [governorates, initialLocalities]);

  const getOptions = async (localityId: number) => {
    const response = await axiosPrivate
      .get(`${API_DEV}/Locality/Sublocalities/${localityId}`)
      .then((response: { data: any }) => {
        return response.data as Locality;
      })
      .catch((err: Error) => {
        throw err;
      });
    return response;
  };

  const handleSelectLocality = useCallback(
    async (item: Locality) => {
      let newLocalities = [...localities];
      newLocalities.splice(item.level - 1, newLocalities.length, item);
      setLocalities(newLocalities);

      if (item.level < 4) {
        const options = await getOptions(item.localityId);
        let newLocalitiesOptions = [...localitiesOptions];
        newLocalitiesOptions.splice(
          item.level,
          newLocalitiesOptions.length,
          options.subLocalities
        );
        setLocalitiesOptions(newLocalitiesOptions);
      } else {
        let newLocalitiesOptions = [...localitiesOptions];
        newLocalitiesOptions.splice(item.level, newLocalitiesOptions.length);
        setLocalitiesOptions(newLocalitiesOptions);
        // setLocalitiesOptions(newState);
      }

      // eslint-disable-next-line react-hooks/exhaustive-deps
    },
    [localities, localitiesOptions]
  );

  useEffect(() => {
    // console.log("localites", localities);
    // console.log("localitiesOptions", localitiesOptions);
  }, [localities, localitiesOptions]);

  return { localities, localitiesOptions, handleSelectLocality };
};
