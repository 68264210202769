import { SalesTargetInterface } from "../types/SalesTarget.model";
import { API_DEV } from "../utils/apiRoute";
import { axiosPrivate } from "../utils/interceptors";

export const salesTargetServices = {
  addSalesTarget: async (
    payload: SalesTargetInterface[],
    rejectWithValue: any
  ) => {
    try {
      const resp = await axiosPrivate.post(
        `${API_DEV}/SalesTarget/CreateMoreSalesTargets`,
        payload
      );
      return resp.data;
    } catch (err: any) {
      return rejectWithValue(err.response.data);
    }
  },
  getSalesTargetList: async (
    payload: any,
    rejectWithValue: any
  ) => {
    try {
      const resp = await axiosPrivate.post(
        `${API_DEV}/SalesTarget/GetSalesTargetsAsManager`,
        payload
      );
      return resp.data;
    } catch (err: any) {
      return rejectWithValue(err.response.data);
    }
  },
  getSalesTargetAchivedListToBeExported: async (
    payload: any,
    rejectWithValue: any
  ) => {
    try {
      const resp = await axiosPrivate.post(
        `${API_DEV}/SalesTarget/GetSalesTargetsAchieved`,
        payload
      );
      return resp.data;
    } catch (err: any) {
      return rejectWithValue(err.response.data);
    }
  },
  getEstimatedSalesTargetListToBeExported: async (
    payload: any,
    rejectWithValue: any
  ) => {
    try {
      const resp = await axiosPrivate.post(
        `${API_DEV}/SalesTarget/GetSalesTargets`,
        payload
      );
      return resp.data;
    } catch (err: any) {
      return rejectWithValue(err.response.data);
    }
  },
  deleteSalesTarget: async (
    payload: any,
    rejectWithValue: any
  ) => {
    try {
      const resp = await axiosPrivate.delete(
        `${API_DEV}/SalesTarget`,
        {data: payload}
      );
      return resp.data;
    } catch (err: any) {
      return rejectWithValue(err.response.data);
    }
  },
  updateSalesTarget: async (
    payload: any,
    rejectWithValue: any
  ) => {
    try {
      const resp = await axiosPrivate.put(
        `${API_DEV}/SalesTarget/UpdateMoreSalesTargets`,
        payload
      );
      return resp.data;
    } catch (err: any) {
      return rejectWithValue(err.response.data);
    }
  }
};
