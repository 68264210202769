import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useAppDispatch } from "../../../redux/hooks";
import { fetchRpEventById } from "../../../redux/Rp/rpSlice";
import RpForm from "../../03-organisms/forms/rp/RpForm";
import { Header } from "../../05-layouts/Header/Header";

import "./RpPage.scss";
type Props = {};

const RpEditPage = (props: Props) => {
  const dispatch = useAppDispatch();
  const params = useParams();
  const { id } = params;
  useEffect(() => {
    id && dispatch(fetchRpEventById(id));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Header
        leftItem={
          <div className="cycle-management__header__title">Modifier RP</div>
        }
        subRoutes={[]}
      />
      <div className="rp-page__inner">
        <RpForm context="update" />
      </div>
    </>
  );
};

export default RpEditPage;
