import moment from "moment";

/**
 * Convert  date to  the following format  "YYYY/MM/DD"
 * @date to convert into string
 */
export const getcurrentDate = (date: Date | string | undefined): string => {
  if (date) {
    return moment(date).format().split("T")[0];
  } else {
    return moment().format().split("T")[0];
  }
};

/**
 * Convert  date to  the following format "D"
 * @date to convert into string
 */
export const getDay = (date: Date | string | undefined): string => {
  if (date) {
    return moment(date).format("D");
  } else {
    return "";
  }
};

/**
 * Convert  date to  Time string in the following format  "hh:mm" while subtracting one hour
 * @date  Date to convert
 */
export const getSubstractedTime = (date: Date | string | undefined): string => {
  let dateString = "";
  if (date) {
    dateString = moment(date).subtract(1, "hours").format("LT");
  }

  return dateString;
};

/**
 * Convert UTC date to local date string in the following format  "DD/MM/YYYY"
 * @date UTC Date to convert
 */
export const getLocalDate = (date: Date | string | undefined): string => {
  let localDateString = "";
  if (date) {
    localDateString = moment.utc(date).local().format("ll");
  }

  return localDateString;
};

/**
 * Convert UTC date to local date string in the following format  "MMM Do"
 * @date UTC Date to convert
 */
export const getLocalDateMonth = (date: Date | string | undefined): string => {
  let localDateString = "";
  if (date) {
    localDateString = moment.utc(date).local().format("MMM Do");
  }

  return localDateString;
};
/**
 * Convert UTC date to local Time string in the following format  "hh:mm"
 * @date UTC Date to convert
 */
export const getLocalTime = (date: Date | string | undefined): string => {
  let localDateString = "";
  if (date) {
    localDateString = moment.utc(date).local().format("hh:mm");
  }

  return localDateString;
};
/**
 * Convert local date to UTC date string in the following format
 * @date local Date to convert
 */
export const getUtcDate = (date: Date | string | undefined): Date => {
  let utcDate = new Date();
  if (date) {
    utcDate = moment.utc(date).toDate();
  }

  return utcDate;
};

export const getServerDateFormat = (
  date: Date | string | undefined
): string => {
  return moment.utc(date).format();
};

/**
 * Get quarter
 * @date
 */
export const getQuarter = (number: number) => ({
  startDate: moment().quarter(number).startOf("quarter").format("MM-DD-YYYY"),
  endDate: moment().quarter(number).endOf("quarter").format("MM-DD-YYYY"),
});

/**
 * Get current  Month
 * @date
 */
export const getCurrentMonth = () => ({
  startDate: moment().startOf("month").format("MM-DD-YYYY"),
  endDate: moment().endOf("month").format("MM-DD-YYYY"),
});

/**
 * Get current  Month to UTC
 * @date  //TO improve it's hard coded
 */
export const getCurrentMonthUtcFormat = () => ({
  startDate: `${moment().startOf("month").format("YYYY-MM-DD")}T01:13:31.473Z`,
  endDate: `${moment().endOf("month").format("YYYY-MM-DD")}T01:13:31.473Z`,
});

/**
 * Get current year
 * @date
 */
export const getYear = () => ({
  startDate: moment().startOf("year").format("MM-DD-YYYY"),
  endDate: moment().endOf("year").format("MM-DD-YYYY"),
});

/**
 * Get current  current week dates ordred in an array ["YYYY-MM-DD"]
 *
 */
export const getCurrentWeek = () => {
  let curr = new Date();
  let week = [];

  for (let i = 1; i <= 7; i++) {
    let first = curr.getDate() - curr.getDay() + i;
    let day = new Date(curr.setDate(first)).toISOString().slice(0, 10);
    week.push(day);
  }

  return week;
};

/**
 * Get current  date in the followinf format 'dddd do mmmm'
 * @date
 */
export const getDayName = (date: string | Date | undefined) => {
  if (date) {
    return moment(date).format("dddd, Do MMMM");
  } else {
    return "";
  }
};

/**
 * Get date with time zone = T00:0:000Z
 * @date
 */
export const getdateWithFormatedtTimeZoneToZero = (date: Date) => {
  if (date) {
    return `${moment(date).format("YYYY-MM-DD")}T00:00:00.000Z`;
  } else return null;
};

/**
 *
 *
 */
export const getCurrentMonthToCurrentDay = () => {
  let endDate: Date = moment().endOf("month").toDate();
  let currentDate: Date = moment().toDate();
  if (endDate > currentDate) {
    return {
      startDate: `${moment()
        .startOf("month")
        .format("YYYY-MM-DD")}T00:00:00.000Z`,
      endDate: `${moment().format("YYYY-MM-DD")}T00:00:00.000Z`,
    };
  } else {
    return {
      startDate: `${moment()
        .startOf("month")
        .format("YYYY-MM-DD")}T00:00:00.000Z`,
      endDate: `${moment().endOf("month").format("YYYY-MM-DD")}T00:00:00.000Z`,
    };
  }
};

/**
 * Get a specific month (startDate and endDate) by giving the year and the month number
 *
 */
export const getMonthDateRange = (year: number, month: number) => {
  if (year && month) {
    let startDate = `${moment([year, month - 1]).format(
      "YYYY-MM-DD"
    )}T10:00:00.000Z`;
    let endDate = `${moment(startDate)
      .endOf("month")
      .format("YYYY-MM-DD")}T10:00:00.000Z`;
    return {
      startDate: startDate,
      endDate: endDate,
    };
  } else
    return {
      startDate: null,
      endDate: null,
    };
};

/**
 * Get current year
 *
 */

export const getCurrentYear = (format: string) => {
  return moment().startOf("year").format(format);
};
