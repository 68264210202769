import React from "react";
import { UseFormHandleSubmit } from "react-hook-form";
import { Form } from "rsuite";
import { SelectField } from "../../../02-molecules/forms/SelectField/SelectField";
import { TagsField } from "../../../02-molecules/forms/TagsField/TagsField";
import { useOrderAnalyticFilter } from "../../../03-organisms/filter/orderFilters/order-analytics-filter/useOrderAnalyticsFilter";

type Props = {
  context: "check" | "select";
  setSelectedProductId?: React.Dispatch<React.SetStateAction<number>>;
  control: any;
  errors: any;
  handleSubmit: UseFormHandleSubmit<any>;
  captureProducts?: ((products: number[]) => void) | undefined;
  disabled?: boolean;
};

export default function ProductFilterButton({
  context,
  setSelectedProductId,
  control,
  errors,
  handleSubmit,
  captureProducts,
  disabled,
}: Props) {
  const { formOptions } = useOrderAnalyticFilter();
  const onSubmit = handleSubmit((data) => {
    setSelectedProductId && setSelectedProductId(data.product);
    captureProducts && captureProducts(data.products);
  });

  if (context === "check") {
    return (
      <div className="sales-target-product-filter">
        <Form>
          <TagsField
            errors={errors}
            control={control}
            inputName={"products"}
            data={formOptions || []}
            label={""}
            height="2.3rem"
            overflowY="auto"
            placeholder="Aucun produit sélectionné"
            onSubmit={onSubmit}
          />
        </Form>
      </div>
    );
  } else {
    return (
      <Form>
        <SelectField
          control={control}
          data={formOptions || []}
          errors={errors}
          inputName="product"
          label=""
          rules={null}
          placeholder="Choisir un produit"
          onSubmit={onSubmit}
          disabled={disabled}
        />
      </Form>
    );
  }
}
