import { createSlice } from "@reduxjs/toolkit";
import { API_DEV } from "../../utils/apiRoute";
import { axiosPrivate } from "../../utils/interceptors";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { Agenda } from "../../types/Agenda.model";


export interface AgendaState {
    requestState: "loading" | "idle";
    errors: any;
    agendaData: Agenda[];
}


export const fetchSelectedUserAgenda = createAsyncThunk(
  "[Agenda]_fetchSelectedUserAgenda_",
  async (payload: any, { rejectWithValue }) => {
    try {
     let response = await axiosPrivate.post(`${API_DEV}/Agenda/GetAgenda`, payload);
     return response.data;
    } catch (err: any) {
      return rejectWithValue(err.response.data); 
    }
  }
);


const initialState: AgendaState = {
 errors: null,
 requestState: "idle",
 agendaData: [],
};

export const agendaSlice = createSlice({
  name: "[Agenda]",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchSelectedUserAgenda.pending, (state) => {
      state.requestState = "loading";
      state.errors = null;
    });
    builder.addCase(fetchSelectedUserAgenda.fulfilled, (state, { payload }) => {
      state.requestState = "idle";
      state.errors = null;
      state.agendaData= payload;
    });
    builder.addCase(fetchSelectedUserAgenda.rejected, (state, { payload }) => {
      state.requestState = "idle";
      state.errors = payload;
    });
  },
});


export default agendaSlice.reducer;
