import { Box, Modal } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { salesObjectiveCalculator } from "../../../../helpers/dataFormator";
import { useAppDispatch } from "../../../../redux/hooks";
import {
  addSalesTarget,
  updateSalesTargetApi,
} from "../../../../redux/SalesTarget/salesTargetSlice";
import {
  SalesTargetInterface,
  SalesTargetUsedInFormInterface,
} from "../../../../types/SalesTarget.model";
import {
  getCurrentYear,
  getMonthDateRange,
} from "../../../../utils/dateHelpers";
import errorToast from "../../../../utils/errorToast";
import { getConnectedUserBu } from "../../../../utils/getConnectedData";
import { CustomButton } from "../../../01-atoms/Buttons/Buttons";
import ProductSelecter from "../../../04-templates/sales-target/sales-target-buttons/ProductSelecter";
import AddSalesInput from "./add-sales-targe-input/AddSalesInput";
import "./AddSalesTargetForm.scss";

type Props = {
  formTargetSalesModalOpen: boolean;
  setFormTargetSalesModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  updateSalesTargetObject: {
    productId: number;
    salesObject: SalesTargetUsedInFormInterface;
    monthsId: { label: number | string; value: number | string | null }[];
  } | null;
  setUpdateSalesTargetObject: React.Dispatch<
    React.SetStateAction<{
      productId: number;
      salesObject: SalesTargetUsedInFormInterface;
      monthsId: { label: number | string; value: number | string | null }[];
    } | null>
  >;
  formContext: "addSalesTarget" | "updateSalesTarget";
  selectYear: string | null | number;
};
export default function AddSalesTarget({
  formTargetSalesModalOpen,
  setFormTargetSalesModalOpen,
  setUpdateSalesTargetObject,
  updateSalesTargetObject,
  formContext,
  selectYear,
}: Props) {
  const monthList: {
    label: string;
    value: null | number | string;
    key: string;
  }[] = [
    { label: "Janv.", value: null, key: "1" },
    { label: "Févr.", value: null, key: "2" },
    { label: "Mars", value: null, key: "3" },
    { label: "Avr.", value: null, key: "4" },
    { label: "Mai", value: null, key: "5" },
    { label: "Juin", value: null, key: "6" },
    { label: "Juill.", value: null, key: "7" },
    { label: "Août", value: null, key: "8" },
    { label: "Sept.", value: null, key: "9" },
    { label: "Oct.", value: null, key: "10" },
    { label: "Nov.", value: null, key: "11" },
    { label: "Déc.", value: null, key: "12" },
  ];
  const dispatch = useAppDispatch();
  const [total, setTotal] = useState<number>(0);
  const [salesQuantityByMonth, setSalesQuantityByMonth] = useState<any[]>([]);
  const [selectedProductId, setSelectedProductId] = useState<number>(0);
  const handleCloseAddSalesTargetModal = () => {
    setFormTargetSalesModalOpen(false);
  };
  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
    setValue,
    getValues,
  } = useForm<any>({
    defaultValues: {
      M1: "",
      M2: "",
      M3: "",
      M4: "",
      M5: "",
      M6: "",
      M7: "",
      M8: "",
      M9: "",
      M10: "",
      M11: "",
      M12: "",
      product: 0,
      total: "",
    },
    criteriaMode: "all",
    reValidateMode: "onChange",
  });
  const handleTotal = () => {
    if (salesQuantityByMonth && salesQuantityByMonth.length) {
      // eslint-disable-next-line array-callback-return
      let monthValues = getValues([ "M1", "M2", "M3", "M4", "M5","M6", "M7","M8","M9","M10","M11","M12"]);
      let newTotal: number = monthValues && monthValues.length ? monthValues.filter((el)=> el).reduce((acc,cuu)=> acc = acc+ +cuu , 0) :0
     setTotal(newTotal)
     setValue("total", newTotal);
    }
  };

  useEffect(() => {
    handleTotal(); 
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [salesQuantityByMonth]);

  const handleResetFormFields = () => {
    reset();
  };
  const handleSubmitform = () => {
    if (salesQuantityByMonth && !salesQuantityByMonth.length) {
      errorToast("Veuillez remplir les champs !");
    } else if (!selectedProductId) {
      errorToast("Veuillez sélectionner un produit");
    } else if (
      selectedProductId &&
      salesQuantityByMonth &&
      salesQuantityByMonth.length
    ) {
      let payload: SalesTargetInterface[] = salesQuantityByMonth.reduce(
        (acc: any[], curr: { valueGroup: string; value: string }) => {
          let monthNumber: number | undefined =
            curr?.valueGroup && curr.valueGroup.length > 1
              ? parseInt(curr?.valueGroup.slice(1))
              : undefined;
          let year = selectYear ? selectYear : getCurrentYear("YYYY");
          let month = getMonthDateRange(+year, monthNumber || 0);
          let bu: number | null = getConnectedUserBu()[0] || null;
          let salesTargetIdValue =
            formContext === "updateSalesTarget" &&
            updateSalesTargetObject &&
            updateSalesTargetObject.monthsId
              ? updateSalesTargetObject.monthsId.find((e) => {
                  return e.label === parseInt(curr?.valueGroup.slice(1));
                })?.value
              : null;
          return acc.concat({
            salesTargetId: salesTargetIdValue ? salesTargetIdValue : 0,
            year: +year,
            startDate: month.startDate,
            endDate: month.endDate,
            quantity: +curr.value,
            productId: selectedProductId,
            businessUnitId: bu,
            assignIdTo: null,
          });
        },
        []
      );
      formContext === "addSalesTarget" &&
        dispatch(addSalesTarget(payload)).then((res) => {
          if (!res.payload.message) {
            // eslint-disable-next-line array-callback-return
            handleResetFormFields();
            setSelectedProductId(0);
            setSalesQuantityByMonth([]);
            setTotal(0);
          }
        });

      formContext === "updateSalesTarget" &&
        dispatch(
          updateSalesTargetApi({
            salesTargetRequest: payload,
            removeSalesTargetIds: [],
          })
        ).then((res) => {
          if (!res.payload.message) {
            // eslint-disable-next-line array-callback-return
            handleResetFormFields();
            setSelectedProductId(0);
            setSalesQuantityByMonth([]);
            setTotal(0);
            handleCloseAddSalesTargetModal();
          }
        });
    }
  };
//Used when we want to update the sale target
  const handleSetFormValueFromToBeUpdatedObject = (
    salesTargetToUpdateObject: any
  ) => {
    // eslint-disable-next-line array-callback-return
    let valueList: { valueGroup: string; value: number | string }[] = [];
    // eslint-disable-next-line array-callback-return
    monthList.map((el) => {
      const elementKey: string = `M${el.key}`;
      if (salesTargetToUpdateObject && salesTargetToUpdateObject[elementKey]) {
        setValue(elementKey, salesTargetToUpdateObject[elementKey]);
        valueList = valueList.concat([
          {
            valueGroup: elementKey,
            value: salesTargetToUpdateObject[elementKey],
          },
        ]);
      }
    });
    setSalesQuantityByMonth(valueList);
    setTotal(salesTargetToUpdateObject.total);
    setSelectedProductId(salesTargetToUpdateObject.product);
    setValue("total", salesTargetToUpdateObject.total);
    setValue("product", salesTargetToUpdateObject.product);
  };

  useEffect(() => {
    updateSalesTargetObject &&
      updateSalesTargetObject.salesObject &&
      handleSetFormValueFromToBeUpdatedObject(
        updateSalesTargetObject?.salesObject || null
      );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateSalesTargetObject]);

  return (
    <>
      <Modal
        open={formTargetSalesModalOpen}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box className="sales-target-form__wrapper">
          <section className="sales-target-form_top">
            <div className="sales-target-form_top-products">
              <ProductSelecter
                context="select"
                setSelectedProductId={setSelectedProductId}
                control={control}
                errors={errors}
                handleSubmit={handleSubmit}
                disabled={formContext==="updateSalesTarget" ? true : false}
              />
            </div>
            <div className="sales-target-form_top-btns">
              <CustomButton
                text="Annuler"
                className="sales-target-form_top_cancel-btn"
                onClick={() => {
                  handleCloseAddSalesTargetModal();
                  handleResetFormFields();
                }}
              />
              <CustomButton
                text="Sauvegarder"
                color="dark"
                onClick={handleSubmitform}
              />
            </div>
          </section>
          <section className="form-separator"></section>
          <section className="sales-target-form_bottom">
            <div className="sales-target-form-bottom-style-wrapper">
              <div className="salesTarget-bottom-style">
                <div>Mois</div>
                <div className="text-style-padding">
                  <div>Objectif</div>
                </div>
              </div>
              {monthList.map((el, index) => {
                return (
                  <div
                    className="salesTarget-bottom-style month-list-inputs "
                    key={index}
                  >
                    <div>{el.label}</div>
                    <div>
                      <AddSalesInput
                        inputName={"M" + el.key}
                        label=""
                        setSalesQuantityByMonth={setSalesQuantityByMonth}
                        salesQuantityByMonth={salesQuantityByMonth}
                        control={control}
                        errors={errors}
                        handleSubmit={handleSubmit}
                        rules={{
                          validate: (value: any) =>
                            /^\s*\d*\s*$/.test(value) || "Non valide",
                        }}
                      />
                    </div>
                  </div>
                );
              })}
              <div className="salesTarget-bottom-style month-list-inputs total-btn-style">
                <div className="total-btn-margin">
                  <CustomButton
                    text="Étaler"
                    color="customized"
                    className="sales-target-form_bottom-month-btn"
                    onClick={() => {
                      let amount =
                        salesObjectiveCalculator(total)?.divisedTotal || 0;
                      let rest = salesObjectiveCalculator(total)?.rest || 0;
                      let monthValueList: {
                        valueGroup: string;
                        value: number | string;
                      }[] = [];
                      // eslint-disable-next-line array-callback-return
                      monthList &&
                        // eslint-disable-next-line array-callback-return
                        monthList.map((item, index) => {
                          if (index === 11) {
                            setValue(`M12`, `${amount + rest}`);
                            monthValueList = monthValueList.concat({
                              valueGroup: "M12",
                              value: amount + rest,
                            });
                          } else {
                            setValue(`M${item.key}`, `${amount}`);
                            monthValueList = monthValueList.concat({
                              valueGroup: `M${item.key}`,
                              value: amount,
                            });
                          }
                          setSalesQuantityByMonth(monthValueList);
                        });
                    }}
                  />
                </div>
                <div>
                  <AddSalesInput
                    inputName="total"
                    label=""
                    color="#20abe3"
                    inputboldness="700"
                    setTotal={setTotal}
                    control={control}
                    errors={errors}
                    handleSubmit={handleSubmit}
                    rules={{
                      validate: (value: any) =>
                        /^\s*\d*\s*$/.test(value) || "Non valide",
                    }}
                  />
                </div>
              </div>
            </div>
          </section>
        </Box>
      </Modal>
    </>
  );
}
