   const localizedTextsMap = {
    columnMenuUnsort: "Détrier",
    columnMenuSortAsc: "Trier par ASC",
    columnMenuSortDesc: "Trier par DESC",
    columnMenuFilter: "Filtrer",
    columnMenuHideColumn: "Cacher",
    columnMenuShowColumns: "Afficher Colonnes",
    noRowsLabel: "Aucune donnée à afficher",
    columnsPanelShowAllButton: "",
    columnsPanelHideAllButton: "",
    columnsPanelTextFieldLabel: 'Trouver une colonne',
    columnsPanelTextFieldPlaceholder: 'Title de la colonne',
  };

  export default localizedTextsMap;