import { useState } from "react";
import { useEffect } from "react";

export const useHandleResize = () => {
  type CurrentDevice =
    | "Mobile"
    | "Tablet"
    | "Computer"
    | "LargeScreen"
    | "WideScreen"
    | "";
  const [currentDevice, setCurrentDevice] = useState<CurrentDevice>("");
  useEffect(() => {
    const handleResize = () => {
      window.innerWidth < 768
        ? setCurrentDevice("Mobile")
        : window.innerWidth < 992
        ? setCurrentDevice("Tablet")
        : window.innerWidth < 1200
        ? setCurrentDevice("Computer")
        : setCurrentDevice("LargeScreen");
    };
    window.addEventListener("resize", handleResize);
    handleResize();
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  return currentDevice;
};
