import { mdiPlus } from "@mdi/js";
import Icon from "@mdi/react";
import React, { useEffect } from "react";
import { useAppDispatch } from "../../../redux/hooks";
import {
  selectDoctors,
  toggleTargetModal,
} from "../../../redux/Target/targetSlice";
import { TargetWeek } from "../../../types/TargetWeek.model";
import TargetGroupByInner from "./TargetGroupByInner";
import "./WeekContent.scss";
type Props = {
  targetWeek: TargetWeek;
  innerSelected: number[];
  setInnerSelected: React.Dispatch<React.SetStateAction<number[]>>;
};

export const WeekContent = ({
  targetWeek,
  innerSelected,
  setInnerSelected,
}: Props) => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    targetWeek &&
      dispatch(selectDoctors(targetWeek.doctors.map((e) => e.doctorId)));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [targetWeek]);

  return (
    <div className="week-content__wrapper">
      <div className="week-content__inner">
        {targetWeek.doctors.length > 0 ? (
          <TargetGroupByInner
            targetWeek={targetWeek}
            innerSelected={innerSelected}
            setInnerSelected={setInnerSelected}
          />
        ) : (
          <div className="week-content__inner--bottom">
            <div
              className="add-content-box"
              onClick={() => dispatch(toggleTargetModal())}
            >
              <Icon path={mdiPlus} size={1} />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
