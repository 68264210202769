import React, { useState } from "react";
import {
  mdiPhone,
  mdiCellphone,
  mdiContentCopy,
  mdiFax,
  mdiPhoneClassic,
} from "@mdi/js";
import { Modal, List, Divider, Avatar } from "rsuite";
import { Phone } from "../../../../types/Phone.model";
import { StandardIcon } from "../../../01-atoms/Icons/Icons";
import { ProfileInfoItemPlaceholder } from "../../../02-molecules/placeholders/Placeholder";
import { toast } from "react-toastify";

interface InfoItemProps {
  loading: boolean;
  phones: Phone[];
}
export const InfoItemPhone = ({ phones, loading }: InfoItemProps) => {
  const [modalIsOpen, setmodalIsOpen] = useState(false);
  const handleOpen = () => setmodalIsOpen(true);
  const handleClose = () => setmodalIsOpen(false);
  const getPhoneIcon = (phone: Phone) =>
  {
    if (phone) {
      return phone.phoneType === 0 
        ? mdiCellphone
        : phone?.phoneType === 2
        ? mdiPhoneClassic
        : mdiFax;
    } else {
      return mdiPhoneClassic;
    }
  }; 

  const renderModal = (phones: Phone[]) => (
    <Modal open={modalIsOpen} onClose={handleClose}>
      <Modal.Header>
        <Modal.Title>Numéros de téléphone</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <List>
          {phones.length > 0
            ? phones.map((e) => (
                <List.Item
                  key={e.phoneId}
                  className="phalcon-phone-cell-list-item"
                >
                  <div className="main-phone--icon">
                    <StandardIcon icon={getPhoneIcon(e)} />
                  </div>
                  <div className="main-phone--number">
                    {e.phoneNumber || "-"} 
                  </div>
                  <Divider vertical />
                  <div className="main-phone--description">
                    {e.phoneDescription || "-"}
                  </div>
                  <StandardIcon
                    clickable
                    className="phone-copy-action"
                    icon={mdiContentCopy}
                    onClick={() => {
                      navigator.clipboard.writeText(e.phoneNumber);
                      toast.success("Numéro copié", {
                        position: "bottom-right",
                        autoClose: 2000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                      });
                    }}
                  />
                </List.Item>
              ))
            : null}
        </List>
      </Modal.Body>
    </Modal>
  );
  return (
    <>
      {!loading ? (
        <div className="phones-item">
          <StandardIcon
            icon={phones.length > 0 ? getPhoneIcon(phones[0]) : mdiPhone}
          />
          <dl>
            <dt className="content">{phones[0]?.phoneNumber || "-"}</dt>
          </dl>
          {phones.length > 1 ? (
            <div className="extra-phones" onClick={handleOpen}>
              <Avatar size="xs" className="extra-phones--count" circle>
                +{phones.length - 1}
              </Avatar>
            </div>
          ) : null}
          {renderModal(phones)}
        </div>
      ) : (
        <div className="info-item">
          <ProfileInfoItemPlaceholder withCircle />
        </div>
      )}
    </>
  );
};
