import React from "react";
import { useTranslation } from "react-i18next";
import { Outlet } from "react-router-dom";
import { useRoutingConfig } from "../../../utils/useRoutingConfig";
import { Header } from "../../05-layouts/Header/Header";

type Props = {};

const OrdersPage = (props: Props) => {

    
  const { t } = useTranslation();
  const headerLeftItem = <h2>{t("orders_page.title")}</h2>;
  const currentRoute = useRoutingConfig().find((e) => e.key === "8");
  const subRoutes =
    typeof currentRoute !== "undefined" ? currentRoute.children : [];
  return (
    <div>
      <Header subRoutes={subRoutes} leftItem={headerLeftItem} />
      <Outlet />
    </div>
  );
};

export default OrdersPage;
