import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useAppDispatch } from "../../../redux/hooks";
import { toggleRedirectToPlanificationPage } from "../../../redux/UI/uiSlice";
import PlanificationSubheader from "../../03-organisms/planification-subheader/PlanificationSubheader";
import PlanificationModal from "../../03-organisms/planification/PlanificationModal";
import { PlanificationWeekContent } from "../../03-organisms/planification/PlanificationWeekContent";
import { PlanificationWeeksSidebar } from "../../03-organisms/planification/PlanificationWeeksSidebar";
import { ProfileHeader } from "../../03-organisms/user-account/profile-header/ProfileHeader";
import { Header } from "../../05-layouts/Header/Header";
import "./PlanificationPage.scss";
import { fetchDoctorSummary } from "../../../redux/Doctor/doctorSlice";
import usePlanification from "./usePlanification";
import { fetchPlanificationById } from "../../../redux/Planification/planificationSlice";

type Props = {};

export const PlanificationPage = (props: Props) => {
  const dispatch = useAppDispatch();
  const params = useParams();
  const {
    activeUpdatedPlanificationState,
    handleCopy,
    handleCut,
    handlePaste,
    handleReset,
    handleRedo,
    handleUndo,
    redoIsActive,
    undoIsActive,
    requestState,
    copyIsActive,
    cutIsActive,
    resetIsActive,
    pasteIsActive,
    assignPlanificationDone,
    activeWeek,
    setActiveWeek,
    innerSelected,
    setInnerSelected,
    addToWeek,
  } = usePlanification();

  const userfacility = activeUpdatedPlanificationState?.target?.user
    ? activeUpdatedPlanificationState?.target?.user.address.localities
        .filter((e: { level: number }) => e.level !== 3)
        .map((e: { localityName: any }) => e.localityName)
        .join(", ")
    : "";

  const cycleConfig = {
    label: activeUpdatedPlanificationState?.target?.cycle
      ? activeUpdatedPlanificationState?.target?.cycle.cycleName
      : "",
    frequencies: activeUpdatedPlanificationState?.target?.cycle
      ? activeUpdatedPlanificationState?.target?.cycle.frequencies
      : [],
    weeksNumber: activeUpdatedPlanificationState?.target?.cycle
      ? activeUpdatedPlanificationState?.target?.cycle.nbWeek
      : null,
  };
  useEffect(() => {
    dispatch(toggleRedirectToPlanificationPage(false));
    params.id && dispatch(fetchPlanificationById(params.id));
    dispatch(fetchDoctorSummary());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getExistingDoctors = () =>
    activeWeek && activeWeek.weekContent.length > 0
      ? activeWeek.weekContent.map((wc) => wc.doctor)
      : [];

  return (
    <div className="planification-page__wrapper">
      <Header
        leftItem={
          <ProfileHeader
            person={activeUpdatedPlanificationState?.target?.user || null}
            context={"user"}
          />
        }
        middleItem={
          <div className="target-header">
            <div className="cycle-params">
              <div className="cycle-label">
                Planification | {cycleConfig.label || "-"}{" "}
              </div>

              <div className="cycle-config">
                {cycleConfig.frequencies.length > 0
                  ? cycleConfig.frequencies.map((e) => (
                      <div
                        key={e.potential.potentialId}
                        className="cycle-config--freq"
                      >
                        <div className="freq-label">
                          Fréq. <span>{e.potential.potentialName}</span>
                        </div>
                        <div className="freq-value">{e.freq}</div>
                      </div>
                    ))
                  : null}
                <div className="cycle-config--freq">
                  <div className="freq-label">Nombre de semaines</div>
                  <div className="freq-value">
                    {cycleConfig.weeksNumber || "-"}
                  </div>
                </div>
              </div>
            </div>
          </div>
        }
      />
      <PlanificationSubheader
        cycleConfig={cycleConfig}
        userAddress={userfacility}
        activeWeek={activeWeek}
        copyIsActive={copyIsActive}
        cutIsActive={cutIsActive}
        pasteIsActive={pasteIsActive}
        resetIsActive={resetIsActive}
        handleCopy={handleCopy}
        handleCut={handleCut}
        handlePaste={handlePaste}
        handleReset={handleReset}
        setInnerSelected={setInnerSelected}
        handleRedo={handleRedo}
        handleUndo={handleUndo}
        innerSelected={innerSelected}
        redoIsActive={redoIsActive}
        undoIsActive={undoIsActive}
        assignDone={assignPlanificationDone}
        activeUpdatedState={activeUpdatedPlanificationState}
        requestState={requestState}
      />
      <div className="planification-page__inner">
        <div className="planification-page__inner__left">
          <PlanificationWeeksSidebar
            activeUpdatedPlanificationState={activeUpdatedPlanificationState}
            activeWeek={activeWeek}
            setActiveWeekCallBack={setActiveWeek}
          />
        </div>
        <div className="planification-page__inner__main">
          <PlanificationWeekContent
            activeUpdatedPlanificationState={activeUpdatedPlanificationState}
            planificationWeek={activeWeek}
            innerSelected={innerSelected}
            setInnerSelected={setInnerSelected}
          />
        </div>
      </div>

      <PlanificationModal
        existingDoctors={getExistingDoctors()}
        addToWeek={addToWeek}
      />
    </div>
  );
};
