import React, { useState, useRef, useEffect } from "react";
import {
  mdiMagnify,
  mdiPlusCircleOutline,
  mdiFullscreenExit,
  mdiFullscreen,
} from "@mdi/js";
import Icon from "@mdi/react";
import { CheckPicker, InputGroup, Loader, Pagination, Input } from "rsuite";
import { useHandleResize } from "../../../../utils/useHandleResize";
import { CustomButton } from "../../../01-atoms/Buttons/Buttons";
import { RpFilter } from "../../filter/RpFilter/RpFilter";
import TablePhalcon from "../common/TablePhalcon";
import useRpTable from "./useRpTable";
import { toggleClass } from "rsuite/esm/DOMHelper";
import { getPersons } from "../../../../redux/Rp/rpSlice";
import { useAppDispatch, useAppSelector } from "../../../../redux/hooks";
import { RootState } from "../../../../redux/store";
import {
  fetchProducts,
  fetchSeparatedProducts,
} from "../../../../redux/Product/productSlice";
import { useFilterRp } from "../../filter/RpFilter/useFilterRp";
import "./RpTable.scss";
import { NavLink } from "react-router-dom";

type Column = {
  key: string;
  label: string;
  width: number;
  fixed: boolean;
  type: string;
};
type Props = {
  context: "doctor" | "manager" | "delegate" | "global";
};

const NewRpTable = ({ context }: Props) => {
  const dispatch = useAppDispatch();
  const deviceSize = useHandleResize();
  const [isFullWidth, setIsFullWidth] = useState<boolean>(false);
  const expandRef: any = useRef(null);
  const colPickerRef: any = useRef(null);

  const {
    columns,
    currentPageNumber,
    data,
    dataResquestState,
    errors,
    pageSize,
    totalCount,
  } = useRpTable();

  const {
    captureSearchTextValue,
    captureOrderBy,
    captureFilter,
    capturePageTarget,
    captureLimitTarget,
  } = useFilterRp({ context });

  const [searchTerm, setSearchTerm] = useState("");
  const [searchFieldIsLoading, setSearchFieldIsLoading] = useState(false);

  useEffect(() => {
    setSearchFieldIsLoading(true);
    const delayDebounceFn = setTimeout(() => {
      captureSearchTextValue(searchTerm);
    }, 500);
    return () => {
      clearTimeout(delayDebounceFn);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchTerm]);

  useEffect(() => {
    if (dataResquestState === "idle" && !errors) setSearchFieldIsLoading(false);
  }, [dataResquestState, errors]);

  const [columnsOptions, setColumnsOptions] = useState<Column[]>([]);
  const [columnKeys, setColumnKeys] = React.useState<string[]>([]);

  const columnsToShow = columns.filter((column) =>
    columnKeys.some((key) => key === column.key)
  );

  useEffect(() => {
    setColumnsOptions(columns);
    let defaultColsKeys = columns.map((column) => column.key);
    setColumnKeys(defaultColsKeys);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const businessUnits = useAppSelector(
    (state: RootState) => state.user.currentUser.businessUnits
  );
  const myBus = businessUnits.map((e) => e.businessUnitId);

  useEffect(() => {
    dispatch(fetchSeparatedProducts({ ids: myBus }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [myBus]);

  useEffect(() => {
    dispatch(getPersons());

    dispatch(fetchProducts());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <div className="rp-list__wrapper" ref={expandRef}>
      <div className="top-area">
        <InputGroup inside className="profile-search-input">
          <Input
            size="lg"
            type="text"
            name="search"
            placeholder="Recherche"
            onChange={(value) => setSearchTerm(value)}
          />
          <InputGroup.Button>
            {searchFieldIsLoading ? (
              <Loader />
            ) : (
              <Icon path={mdiMagnify} size={1} />
            )}
          </InputGroup.Button>
        </InputGroup>

        <div className="buttons-area">
          <RpFilter captureFilter={captureFilter} />
          <NavLink to="add">
            <CustomButton
              text={["Mobile", "Tablet"].includes(deviceSize) ? "" : "Ajouter"}
              icon={<Icon path={mdiPlusCircleOutline} size={1} />}
              /* onClick={() => dispatch(toggleAddRpModal())} */
            />
          </NavLink>

          {context !== "global" && (
            <CustomButton
              className="fullscreen-button"
              icon={
                <Icon
                  path={isFullWidth ? mdiFullscreenExit : mdiFullscreen}
                  size={1}
                />
              }
              onClick={() => {
                toggleClass(expandRef.current, "full-screen");
                setIsFullWidth(!isFullWidth);
              }}
            />
          )}
        </div>
      </div>
      <div className="pagination-area">
        <Pagination
          prev
          next
          first
          last
          ellipsis
          boundaryLinks
          size="sm"
          layout={
            deviceSize !== "Mobile"
              ? ["total", "-", "limit", "|", "pager", "skip"]
              : ["total", "-", "pager"]
          }
          total={totalCount}
          limitOptions={[10, 20, 50]}
          limit={pageSize}
          activePage={currentPageNumber}
          onChangePage={(page: number) => capturePageTarget(page)}
          onChangeLimit={(limit: number) => captureLimitTarget(limit)}
        />
      </div>
      <div className="table-area">
        <div className="col-selecter">
          <div ref={colPickerRef} className="col-selecter--input">
            <CheckPicker
              sticky
              name="colPicker"
              data={columnsOptions}
              labelKey="label"
              valueKey="key"
              value={columnKeys}
              onChange={setColumnKeys}
              cleanable={false}
              container={() => colPickerRef.current}
              style={{ width: "100%" }}
            />
          </div>
        </div>
        <TablePhalcon
          data={data}
          dataBaseContext={"rp"}
          columns={columnsToShow}
          dataResquestState={dataResquestState}
          captureOrderBy={captureOrderBy}
        />
      </div>
    </div>
  );
};

export default NewRpTable;
