import { mdiCellphone, mdiContentCopy, mdiFax, mdiPhoneClassic } from "@mdi/js";
import React, { useState } from "react";
import { toast } from "react-toastify";
import { Divider, List, Modal, Avatar } from "rsuite";
import { StandardIcon } from "../../../../01-atoms/Icons/Icons";
import "./PhalconCell.scss";
type Props = {
  item: string;
  rowName: string;
};
type PhoneData = {
  phoneType: number;
  phoneNumber: string;
  phoneDescription: string;
};

const PhoneCell = ({ item, rowName }: Props) => { 
  const phones: PhoneData[] = JSON.parse(item);
  const [modalIsOpen, setmodalIsOpen] = useState(false);
  const handleOpen = () => setmodalIsOpen(true);
  const handleClose = () => setmodalIsOpen(false);

  const getPhoneIcon = (phone: PhoneData) => {
    if (phone) {
      return phone.phoneType === 0 
        ? mdiCellphone
        : phone?.phoneType === 2
        ? mdiPhoneClassic
        : mdiFax;
    } else {
      return mdiPhoneClassic;
    }
  };

  const renderModal = (phones: PhoneData[]) => (
    <Modal open={modalIsOpen} onClose={handleClose}>
      <Modal.Header>
        <Modal.Title>Téléphones de {rowName}</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <List>
          {phones.length > 0
            ? phones.map((e, i) => (
                <List.Item key={i} className="phalcon-phone-cell-list-item">
                  <div className="main-phone--icon">
                    <StandardIcon icon={getPhoneIcon(e)} />
                  </div>
                  <div className="main-phone--number">
                    {e.phoneNumber || "-"}
                  </div>
                  <Divider vertical />
                  <div className="main-phone--description">
                    {e.phoneDescription || "-"}
                  </div>
                  <StandardIcon
                    clickable
                    className="phone-copy-action"
                    icon={mdiContentCopy}
                    onClick={() => {
                      navigator.clipboard.writeText(e.phoneNumber);
                      toast.success("Numéro copié", {
                        position: "bottom-right",
                        autoClose: 2000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                      });
                    }}
                  />
                </List.Item>
              ))
            : null}
        </List>
      </Modal.Body>
    </Modal>
  );

  return (
    <div className="phalcon-phone-cell">
      <div className="main-phone">
        <div className="main-phone--icon">
          <StandardIcon icon={getPhoneIcon(phones[0])} />
        </div>
        <div className="main-phone--number">
          {phones[0]?.phoneNumber || "-"}
        </div>
      </div>
      {phones.length > 1 ? (
        <div className="extra-phones" onClick={handleOpen}>
          <Avatar size="xs" className="extra-phones--count" circle>
            +{phones.length - 1}
          </Avatar>
        </div>
      ) : null}
      {renderModal(phones)}
    </div>
  );
};

export default PhoneCell;
