import {
  mdiAccount ,
  mdiHospitalBox,
  mdiMedicalBag,
  mdiHospitalBuilding 
} from "@mdi/js";
// import _ from "lodash";
import React from "react";
import { FlexboxGrid } from "rsuite";
import AnalyticsCard from "../../../03-organisms/analytics/cards/AnalyticsCard";
import AnalyticsTable from "../../../03-organisms/analytics/tables/AnalyticsTable";
import PhalconBarChart from "../../../03-organisms/charts/PhalconBarChart";
import PhalconPieChart from "../../../03-organisms/charts/PhalconPieChart";
import useBiObjections from "./useBiGeneral";

type Props = {};

export type ChartDefinition = {
  title: string;
  type:
    | "pie"
    | "donut"
    | "line"
    | "area" 
    | "bar"
    | "histogram"
    | "radialBar"
    | "scatter"
    | "bubble"
    | "heatmap"
    | "treemap"
    | "boxPlot"
    | "candlestick"
    | "radar"
    | "polarArea"
    | "rangeBar";
  labels?: string[];
  series: number[] | { name: string; data: number[] }[];
  xAxisCategories?: string[];
  stacked?: boolean;
  md?: number;
  yAxis?: string;
};

const BiGeneral = (props: Props) => {
  const {
    GeneralsCount,
    generalsDoctorBySpeciality,
    generalsFacilitiesByType,
    generalsDoctorDelegatesByPotential,
    generalPotentialByLocality,
  } = useBiObjections({});

  const charts: ChartDefinition[] = [
    {
      title:
        "Répartition médecins selon spécilité",
      type: "donut",
      labels: generalsDoctorBySpeciality.labels, 
      series: generalsDoctorBySpeciality.series, 
      md: 12,
    },
    {
      title:
        "Répartition établissements selon type",
      type: "donut",
      labels: generalsFacilitiesByType.labels, 
      series: generalsFacilitiesByType.series, 
      md: 12,
    },
    {
      title: "Répartition des médecins | Délégués | Potentiels",
      type: "bar",
      xAxisCategories: generalsDoctorDelegatesByPotential.xAxis,
      series: generalsDoctorDelegatesByPotential.series, 
      stacked: true,
      md: 24,
      yAxis: "",
    },
  ]; 
  return (
    <div className="phalcon-bi-general__wrapper">
      <div className="phalcon-bi-general__cards-area">
        <FlexboxGrid align="bottom">
          <AnalyticsCard
            mainMetric={{
              icon: mdiAccount, 
              count: GeneralsCount.totalDelegate,
              title: "Délégués",  
            }} 
            md={4}
          /> 
          <AnalyticsCard
            mainMetric={{
              icon: mdiMedicalBag , 
              count: GeneralsCount.doctorCountByPotential.total,
              title: "Médecins",
            }}
            potentials={GeneralsCount.doctorCountByPotential.potential} 
            md={10}
          />
           <AnalyticsCard
            mainMetric={{
              icon: mdiHospitalBuilding , 
              count: GeneralsCount.totalFacilities,
              title: "Établissement",  
            }} 
            md={5}
          /> 
           <AnalyticsCard
            mainMetric={{
              icon: mdiHospitalBox , 
              count: GeneralsCount.totalOffices,
              title: "Cabinets",  
            }} 
            md={5}
          /> 
        </FlexboxGrid>
      </div>

      <div className="phalcon-bi-general__charts-area">
        <FlexboxGrid align="bottom">
          {charts.map((chart: any, index: number) => {
            if (["pie", "donut"].includes(chart.type)) {
              return ( 
                <PhalconPieChart
                  key={index}
                  title={chart.title}
                  type={chart.type}
                  labels={chart.labels}
                  series={chart.series}
                  md={chart.md}
                />
              );
            }
            if (chart.type === "bar") {
              return (
                <PhalconBarChart
                  key={index}
                  title={chart.title}
                  series={chart.series}
                  xAxisCategories={chart.xAxisCategories}
                  stacked={chart.stacked}
                  md={chart.md}
                  yAxis={chart.yAxis}
                />
              );
            } else {
              return null;
            }
          })}
        </FlexboxGrid>
        <FlexboxGrid>
          <AnalyticsTable generalPotentialByLocality={generalPotentialByLocality} tableTitle="Répartition médecins | Localités | Potentiels"/>
        </FlexboxGrid>
      </div>
    </div>
  );
};

export default BiGeneral;


