import { createSlice } from "@reduxjs/toolkit";
import { API_DEV } from "../../utils/apiRoute";
import { axiosPrivate } from "../../utils/interceptors";
import { createAsyncThunk } from "@reduxjs/toolkit";





const initialState: any = {
    AllSpecs: [],
    FbyId: {} 
}



export const getAllSpecialitiesThunk = createAsyncThunk("[Spec]_getAllSpecialitiesThunk_",
    async (_, { rejectWithValue }) => {
        try {
            let response = await axiosPrivate.get(`${API_DEV}/Speciality`);
            // console.log(response.data)
            return response.data;
        } catch (error) {
            console.log(JSON.parse(JSON.stringify(error)));
            return rejectWithValue(JSON.parse(JSON.stringify(error)));
        }
    })
    // /api/Speciality/{id}
export const deleteSpec = createAsyncThunk("[spec]_deleteSpec_",
    async (data, { rejectWithValue }) => {
        // console.log(data)
        try {
            let response: any = await axiosPrivate.delete(`${API_DEV}/Speciality/${data}`);
            // console.log(response.data)
            return response.data;
        } catch (error: any) {
            console.log(JSON.parse(JSON.stringify(error)));
            return rejectWithValue(JSON.parse(JSON.stringify(error.response.data)));
        }
    })

export const CreateSpecThunk = createAsyncThunk("[spec]_CreateSpecThunk_",
    async (data, { rejectWithValue }) => {
        try {
            let response:any = await axiosPrivate.post(`${API_DEV}/Speciality`, data);
            return response.data;
        } catch (error: any) {
            console.log(JSON.parse(JSON.stringify(error)));
            return rejectWithValue(JSON.parse(JSON.stringify(error.response.data)));
        }
    })

    export const getSpecByIdThunk = createAsyncThunk("[Spec]_getSpecByIdThunk_",
    async (id, { rejectWithValue }) => {
        try {
            let response = await axiosPrivate.get(`${API_DEV}/Speciality/${id}`);
            return response.data;
        } catch (error: any) {
            console.log(JSON.parse(JSON.stringify(error)));
            return rejectWithValue(JSON.parse(JSON.stringify(error.response.data)));
        }
    }) 
    export const updateSpecByIdThunk = createAsyncThunk("[spec]_updateSpecByIdThunk_",
    async (data, { rejectWithValue }) => {
        try {
            let response = await axiosPrivate.put(`${API_DEV}/Speciality`,data);
            return response.data;
        } catch (error: any) {
            console.log(JSON.parse(JSON.stringify(error)));
            return rejectWithValue(JSON.parse(JSON.stringify(error.response.data)));
        }
    })
// /api/FacilityType/{id}
export const SpecsSlice = createSlice({
    name: "[Specs]",
    initialState,
    reducers: {
    },
    extraReducers: (builder) => {
        builder.addCase(getAllSpecialitiesThunk.fulfilled, (state, { payload }) => {
            console.log(payload)
            state.AllSpecs = payload
        });
        builder.addCase(deleteSpec.fulfilled, (state, { payload }) => {
            console.log(payload)
        }).addCase(deleteSpec.rejected, (state, { payload }) => {
            console.log(payload)
        })
        builder.addCase(CreateSpecThunk.fulfilled, (state, { payload }) => {
            console.log(payload)
        }).addCase(CreateSpecThunk.rejected, (state, { payload }) => {
            console.log(payload)
        })
        builder.addCase(getSpecByIdThunk.fulfilled, (state, { payload }) => {
            // console.log(payload)
            state.FbyId = payload
            // console.log("state",state.FbyId)
        }).addCase(getSpecByIdThunk.rejected, (state, { payload }) => {
            console.log(payload)
        })
        builder.addCase(updateSpecByIdThunk.fulfilled, (state, { payload }) => {
            console.log(payload)
        }).addCase(updateSpecByIdThunk.rejected, (state, { payload }) => {
            console.log(payload)
        })
        
        // getSpecByIdThunk
    },
});

export const { } = SpecsSlice.actions;// eslint-disable-line


export default SpecsSlice.reducer;
