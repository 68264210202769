import React, { useState } from "react";
import { Tag as RsuiteTag, Avatar, Modal, Stack } from "rsuite";
import { Tag } from "../../../../../types/Tag.model";

type Props = {
  item: any;
  rowName: string;
};

const TagsCell = ({ item, rowName }: Props) => {
  const tags: Tag[] = JSON.parse(item);

  const [modalIsOpen, setmodalIsOpen] = useState(false);
  const handleOpen = () => setmodalIsOpen(true);
  const handleClose = () => setmodalIsOpen(false);
  const renderModal = (tags: Tag[]) => (
    <Modal open={modalIsOpen} onClose={handleClose}>
      <Modal.Header>
        <Modal.Title>Tags de {rowName}</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <Stack wrap spacing={6}>
          {tags.length > 0
            ? tags.map((tag) => (
                <RsuiteTag size="lg" key={tag.tagId}>
                  {tag.tagName}
                </RsuiteTag>
              ))
            : null}
        </Stack>
      </Modal.Body>
    </Modal>
  );
  return (
    <div className="phalcon-tags-cell">
      {tags.slice(0, 1).map((tag) => (
        <RsuiteTag size="lg" key={tag.tagId}>
          {tag.tagName}
        </RsuiteTag>
      ))}
      {tags.length > 1 ? (
        <div className="extra-tags" onClick={handleOpen}>
          <Avatar size="xs" className="extra-tags--count" circle>
            +{tags.length - 1}
          </Avatar>
        </div>
      ) : null}
      {renderModal(tags)}
    </div>
  );
};

export default TagsCell;
