import { useEffect, useState } from "react";
import { getFilteredDoctorSummaries } from "../redux/Doctor/doctorSlice";
import { useAppDispatch, useAppSelector } from "../redux/hooks";
import { RootState } from "../redux/store";
import { BusinessUnit } from "../types/BusinessUnit.model";
import { Facility } from "../types/Facility.model";
import { FacilityType } from "../types/FacilityType.model";
import { Locality } from "../types/Locality.model";
import { Potential } from "../types/Potential.model";
import { ServiceType } from "../types/ServiceType.model";
import { Speciality } from "../types/Speciality.model";
import { Tag } from "../types/Tag.model";

interface FilterValues {
  localities: {
    localityId: number;
  }[];
  facilityTypes: { facilityTypeId: number }[];
  serviceTypes: { serviceTypeId: number }[];
  potentials: { potentialId: number }[];
  tags: { tagId: number }[];
  businessUnits: { businessUnitId: number }[];
  specialities: { specialityId: number }[];
  facilities: { facilityId: number }[];
  cycleId?: number | null;
}
export type FilterTagItem = {
  label: string;
  type: string;
};
type CheckTreeData = {
  value: number;
  label: string;
}[];
type CheckTreeDataWithChildren = {
  value: number;
  label: string;
  children: CheckTreeDataWithChildren;
}[];
interface DoctorOrderBy {
  [firstName: string]: number;
  firstName_desc: number;
  lastName: number;
  lastName_desc: number;
  specialities: number;
  specialities_desc: number;
  potential: number;
  potential_desc: number;
  locality1: number;
  locality1_desc: number;
  locality2: number;
  locality2_desc: number;
  locality3: number;
  locality3_desc: number;
  locality4: number;
  locality4_desc: number;
}
const doctorOrderBy: DoctorOrderBy = {
  firstName: 0,
  firstName_desc: 1,
  lastName: 2,
  lastName_desc: 3,
  specialities: 4,
  specialities_desc: 5,
  potential: 6,
  potential_desc: 7,
  locality1: 8,
  locality1_desc: 9,
  locality2: 10,
  locality2_desc: 11,
  locality3: 12,
  locality3_desc: 13,
  locality4: 14,
  locality4_desc: 15,
  facilityType: 18,
  facilityType_desc: 19,
};
const useGetFiltersRawData = () => {
  const { facilities } = useAppSelector((state: RootState) => state.facility);

  const {
    firstLevelLocalities,
    services,
    facilityTypes,
    potentials,
    tags,
    businessUnits,
    specialities,
    requestState,
    errors,
  } = useAppSelector((state: RootState) => state.general);

  const [localitiesData, setLocalitiesData] =
    useState<CheckTreeDataWithChildren>([]);
  const [servicesData, setServicesData] = useState<CheckTreeData>([]);
  const [facilityTypesData, setfacilityTypesData] = useState<CheckTreeData>([]);
  const [potentialsData, setPotentialsData] = useState<CheckTreeData>([]);
  const [tagsData, setTagsData] = useState<CheckTreeData>([]);
  const [businessUnitsData, setBusinessUnitsData] = useState<CheckTreeData>([]);
  const [specialitiesData, setSpecialitiesData] = useState<CheckTreeData>([]);
  const [facilitiesData, setfacilitiesData] = useState<CheckTreeData>([]);
  useEffect(() => {
    if (requestState === "idle" && !errors) {
      if (facilities) {
        let locs = facilities.map((loc: Facility) => ({
          label: loc.facilityName,
          value: loc.facilityId,
          type: "facility",
        }));
        setfacilitiesData(locs);
      }
      if (specialities) {
        let specs = specialities.map((spec: Speciality) => ({
          label: spec.specialityName,
          value: spec.specialityId,
          type: "speciality",
        }));
        setSpecialitiesData(specs);
      }
      if (businessUnits) {
        let bus = businessUnits.map((bu: BusinessUnit) => ({
          label: bu.businessUnitName,
          value: bu.businessUnitId,
          type: "businessUnit",
        }));
        setBusinessUnitsData(bus);
      }
      if (tags) {
        let t = tags.map((tag: Tag) => ({
          label: tag.tagName,
          value: tag.tagId,
          type: "tag",
        }));
        setTagsData(t);
      }
      if (potentials) {
        let pots = potentials.map((potential: Potential) => ({
          label: potential.potentialName,
          value: potential.potentialId,
          type: "potential",
        }));
        setPotentialsData(pots);
      }
      if (firstLevelLocalities) {
        let govs = firstLevelLocalities.map((gov: Locality) => ({
          label: gov.localityName,
          value: gov.localityId,
          type: "locality",
          children: [],
        }));
        setLocalitiesData(govs);
      }
      if (services) {
        let obj = services.map((serviceType: ServiceType) => ({
          label: serviceType.serviceTypeName,
          value: serviceType.serviceTypeId,
          type: "service",
        }));
        setServicesData(obj);
      }
      if (facilityTypes) {
        let obj = facilityTypes.map((facilityType: FacilityType) => ({
          label: facilityType.facilityTypeName,
          value: facilityType.facilityTypeId,
          type: "facilityType",
        }));
        setfacilityTypesData(obj);
      }
    }
  }, [
    businessUnits,
    errors,
    firstLevelLocalities,
    facilityTypes,
    facilities,
    potentials,
    requestState,
    services,
    specialities,
    tags,
  ]);

  return {
    localitiesData,
    servicesData,
    facilityTypesData,
    potentialsData,
    tagsData,
    businessUnitsData,
    specialitiesData,
    facilitiesData,
  };
};

export const useTargetFilter = () => {
  const dispatch = useAppDispatch();

  //Raw Data
  const filtersDisplayData = useGetFiltersRawData();

  //Filters
  const [filterValues, setFilterValues] = useState<FilterValues>({
    potentials: [],
    localities: [],
    facilityTypes: [],
    specialities: [],
    serviceTypes: [],
    tags: [],
    businessUnits: [],
    facilities: [],
    cycleId: null,
  });
  const captureFilterInputs = (data: FilterValues) => {
    setFilterValues(data);
  };

  //Pagination
  const [pagination, setPagination] = useState({
    pageNumber: 1,
    pageSize: 10,
  });
  const capturePagination = (page: number) => {
    setPagination({ ...pagination, pageNumber: page });
  };
  const capturePageSize = (limit: number) => {
    let pagination = { pageNumber: 1, pageSize: limit };

    setPagination(pagination);
  };

  //OrderBy
  const [orderBy, setOrderBy] = useState<number>(0);

  const captureOrderBy = (sortColumn: string, sortType: string) => {
    let orderByValue = `${
      sortType === "desc" ? sortColumn + "_" + sortType : sortColumn
    }`;

    setOrderBy(doctorOrderBy[orderByValue]);
  };

  useEffect(() => {
    dispatch(getFilteredDoctorSummaries({ ...filterValues }));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterValues, pagination, orderBy]);

  return {
    filtersDisplayData,
    filterValues,
    captureFilterInputs,
    pagination,
    capturePagination,
    capturePageSize,
    orderBy,
    captureOrderBy,
  };
};
