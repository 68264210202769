import { mdiMagnify, mdiPlusCircleOutline } from "@mdi/js";
import Icon from "@mdi/react";
import React, { useEffect, useState } from "react";
import {
  Input,
  InputGroup,
  Loader,
  Pagination,
  FlexboxGrid,
  Col,
} from "rsuite";
import {
  captureLimitTarget,
  capturePageTarget,
  captureSearchTextValue,
  fetchFilteredCycle,
} from "../../../../redux/Cycle/cycleSlice";
import { useAppDispatch, useAppSelector } from "../../../../redux/hooks";
import { RootState } from "../../../../redux/store";
import { Cycle } from "../../../../types/Cycle.model";
import { useHandleResize } from "../../../../utils/useHandleResize";
import { CustomButton } from "../../../01-atoms/Buttons/Buttons";
import { CycleManagementCard } from "../../../02-molecules/cards/cycle-management-card/CycleManagementCard";
import { NewCycleForm } from "../../../03-organisms/forms/cycle/NewCycleForm";

import "./CycleManagementGeneral.scss";

interface Props {}

export const CycleManagementGeneral = (props: Props) => {
  const dispatch = useAppDispatch();
  const [searchFieldIsLoading, setSearchFieldIsLoading] = useState(false);
  const { filteredCycles, globalFilterState } = useAppSelector(
    (state: RootState) => state.cycle
  );
  const [searchTerm, setSearchTerm] = useState(
    globalFilterState.search.searchText
  );
  const deviceSize = useHandleResize();
  const [formModalIsOpen, setFormModalIsOpen] = useState(false);
  const toggleFormModal = () => setFormModalIsOpen(!formModalIsOpen);

  useEffect(() => {
    setSearchFieldIsLoading(true);
    const delayDebounceFn = setTimeout(() => {
      dispatch(captureSearchTextValue(searchTerm));
      setSearchFieldIsLoading(false);
    }, 500);
    return () => {
      clearTimeout(delayDebounceFn);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchTerm]);

  useEffect(() => {
    dispatch(fetchFilteredCycle(globalFilterState));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [globalFilterState]);

  return (
    <div className="cycle-management__wrapper">
      <div className="top-area">
        <InputGroup inside className="profile-search-input">
          <Input
            size="lg"
            type="text"
            name="search"
            placeholder="Recherche"
            value={searchTerm}
            onChange={(value) => setSearchTerm(value)}
          />
          <InputGroup.Addon>
            {searchFieldIsLoading ? (
              <Loader />
            ) : (
              <Icon path={mdiMagnify} size={1} />
            )}
          </InputGroup.Addon>
        </InputGroup>

        <div className="buttons-area">
          <CustomButton
            text={["Mobile", "Tablet"].includes(deviceSize) ? "" : "Ajouter"}
            icon={<Icon path={mdiPlusCircleOutline} size={1} />}
            onClick={toggleFormModal}
          />
        </div>
      </div>
      <div className="pagination-area">
        <Pagination
          prev
          next
          first
          last
          ellipsis
          boundaryLinks
          size="sm"
          layout={["total", "-", "limit", "|", "pager", "skip"]}
          total={filteredCycles.pagination.totalCount}
          limitOptions={[10, 50, 100]}
          limit={filteredCycles.pagination.pageSize}
          activePage={filteredCycles.pagination.pageNumber}
          onChangePage={(page: number) => dispatch(capturePageTarget(page))}
          onChangeLimit={(limit: number) => dispatch(captureLimitTarget(limit))}
        />
      </div>
      <div className="cards-area">
        <div className="cards-area__headers">
          <FlexboxGrid align="top">
            <Col xs={10} className="card-description">
              <h4 className="card-description__title">Libellé</h4>
            </Col>
            <Col xs={8} className="card-settings">
              <h4 className="card-settings__title">Configuration</h4>
            </Col>
            <Col xs={4} className="card-delegates">
              <h4 style={{ width: "fit-content", marginLeft: "auto" }}>
                Délégués associés
              </h4>
            </Col>
            <Col xs={2}>
              <h4 style={{ width: "fit-content", marginLeft: "auto" }}>
                Actions
              </h4>
            </Col>
          </FlexboxGrid>
        </div>
        {filteredCycles.data.length > 0 &&
          filteredCycles.data.map((cycle: Cycle) => (
            <div key={cycle.cycleId} className="card-item">
              <CycleManagementCard cycle={cycle} />
            </div>
          ))}
      </div>

      <NewCycleForm
        formModalIsOpen={formModalIsOpen}
        toggleFormModal={toggleFormModal}
      />
    </div>
  );
};
