import { IconButton } from "@material-ui/core";
import { KeyboardArrowLeft, KeyboardArrowRight } from "@material-ui/icons";
import moment from "moment";
import React, { useState } from "react";
import "./SalesTargetButtons.scss";

type Props = {
  captureYear: ((year: number | string) => void) | undefined
}

export default function YearSetterButton({captureYear}:Props) {
  const [selectedYear, setSelectedYear] = useState<string>(
    moment().startOf("year").format("YYYY")
  );
  
  return (
    <div className="year-setter-button__wrapper">
      <IconButton
        aria-describedby={`simple-popover`}
        size="small"
        color="primary"
        onClick={() => { 
          setSelectedYear(`${+selectedYear - 1}`);
          captureYear && captureYear(`${+selectedYear - 1}`)
        }}
      >
        <KeyboardArrowLeft />
      </IconButton>
      {selectedYear}
      <IconButton
        aria-describedby={`simple-popover`}
        size="small"
        color="primary"
        onClick={() => {
          setSelectedYear(`${+selectedYear + 1}`);
          captureYear && captureYear(`${+selectedYear + 1}`)
        }}
      >
        <KeyboardArrowRight />
      </IconButton>
    </div>
  );
}
