export const getAccessToken = () =>
  localStorage.getItem("accessToken") ||
  sessionStorage.getItem("accessToken") ||
  "";

/* interface AccessTokenI {
  id: string;
  role: string;
  UserName: string;
  Email: string;
  code: number;
  nbf: number;
  exp: number;
  iat: number;
}

export const currentUserDecodedToken: AccessTokenI =
  jwtDecode(currentUserToken); */
