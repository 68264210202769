import { RootState } from "./../../../../redux/store";
import {  useAppSelector } from "./../../../../redux/hooks";
import {  useMemo } from "react";
import { RequestDataSource } from "../../../../types/RequestDataSource.model";

const prepareRequestsCount = (data: RequestDataSource) => {
  return {
    total: data.totalCount,
    closedCount: data.treatedCount,
    notClosed: {
      total: data.unTreatedRequests.total,
      potential : data.unTreatedRequests.unTreatedRequestsByPotential,
    },
  };
};

const prepareRequestsByCriteria = (
  data: RequestDataSource,
  criteria: string
) => {
  let labels : string []=  data.requestsByType.map(req=> req.requestValueType.requestValueTypeName);
  let series = data.requestsByType.map(
    ({treated}) => treated
  ); 
  return {
    labels : labels, 
    series : series,
  }
};

interface RequestOptions {
  data: RequestDataSource;
  criteria: {
    field: string;
    displayField: string;
  };
}
const prepareRequestsStatusByCriteria = (options: RequestOptions) => {
  const { data } = options;
  return {
    xAxis: data.requestsByType.map(({requestValueType}) => requestValueType.requestValueTypeName),
    series: [
      {
        name: "Traitées",
        data: data.requestsByType.map(({treated})=> treated ),
      },
      {
        name: "Non traitées",
        data: data.requestsByType.map(({unTreated})=> unTreated ),
      },
    ],
  };
};

const prepareRequestsStatusByDelegate = (options: RequestOptions) => {
  const { data } = options;
 
  return {
    xAxis: data.requestsByDelegate.map(({delegate})=> `${delegate.firstName} ${delegate.lastName}`),
    series: [
      {
        name: "Traitées",
        data: data.requestsByDelegate.map(({treated})=> treated ),
      },
      { 
        name: "Non traitées",
        data: data.requestsByDelegate.map(({unTreated})=> unTreated ),
      },
    ], 
  };
};
interface Props {}
const useBiRequests = (props: Props) => {
  const { biRequestData } = useAppSelector((state: RootState) => state.bi);
  const RequestsCount = useMemo(
    () => prepareRequestsCount(biRequestData ),
    [biRequestData]
  );

  const requestsByValue = useMemo(
    () => prepareRequestsByCriteria(biRequestData , "value"),
    [biRequestData]
  );

  const requestsStatusByValue = useMemo(
    () =>
      prepareRequestsStatusByCriteria({
        data: biRequestData ,
        criteria: {
          field: "value",
          displayField: "value",
        },
      }),
    [biRequestData]
  );
  const requestsStatusByUser = useMemo(
    () =>
    prepareRequestsStatusByDelegate({
        data: biRequestData ,
        criteria: {
          field: "duserid",
          displayField: "dfirstname",
        },
      }),
    [biRequestData]
  );

  return {
    RequestsCount,
    requestsByValue,
    requestsStatusByValue,
    requestsStatusByUser,
  };
};

export default useBiRequests;
