import React, { useEffect, useState } from "react";
import {
  Divider,
  List,
  FlexboxGrid,
  Col,
  Checkbox,
  Modal,
  Button,
  Input,
  InputGroup,
} from "rsuite";
import VisibleIcon from "@rsuite/icons/Visible";
import UnvisibleIcon from "@rsuite/icons/Unvisible";
import {
  associateDelegates,
  // associateDelegates,
  deleteCycle,
  dissociateDelegates,
  // dissociateDelegates,
} from "../../../../redux/Cycle/cycleSlice";
import { useAppDispatch, useAppSelector } from "../../../../redux/hooks";
import { RootState } from "../../../../redux/store";
import { Cycle } from "../../../../types/Cycle.model";
import { Frequency } from "../../../../types/Frequency.model";
import User from "../../../../types/User.model";

import {
  AssociateButton,
  DeleteButton,
  ProfileButton,
  CyclesButton,
} from "../../../01-atoms/Buttons/Buttons";
import { CustomAvatar } from "../../../01-atoms/CustomAvatar/CustomAvatar";
import { UserStack } from "../../user-stack/UserStack";

import "./CycleManagementCard.scss";
import errorToast from "../../../../utils/errorToast";
import { reducesTeamMemberLists } from "../../../../helpers/dataFormator";

interface Props {
  cycle: Cycle;
}

export const CycleManagementCard = ({ cycle }: Props) => {
  const dispatch = useAppDispatch();
  const [associationModalIsOpen, setAssociationModalIsOpen] =
    useState<boolean>(false);

  const { teams } = useAppSelector((state: RootState) => state.general);
  const { requestState } = useAppSelector((state: RootState) => state.target);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [allDelegates, setAllDelegates] = useState<User[]>([]);

  const [
    delegatesNotAssociateDtoThisCycle,
    setDelegatesNotAssociateDtoThisCycle,
  ] = useState<User[]>([]);

  const handleDelete = () => {
    setConsentModalIsOpen(true);
  };
  const [consentModalIsOpen, setConsentModalIsOpen] = useState<boolean>(false);
  const [consentPassword, setconsentPassword] = useState<string>("");
  useEffect(() => {
    if (teams.length > 0) {
      setAllDelegates(reducesTeamMemberLists(teams));
    }
  }, [teams]);

  useEffect(() => {
    if (allDelegates.length > 0) {
      const associatedIds = cycle.delegates.map((el) => el.userId);
      setDelegatesNotAssociateDtoThisCycle(
        allDelegates.filter((el) => !associatedIds.includes(el.userId))
      );
    }
  }, [allDelegates, cycle]);

  useEffect(() => {
    setconsentPassword("");
  }, [consentModalIsOpen]);
  const [checkedKeys, setCheckedKeys] = useState<number[]>([]);
  const handleCheck = (value: number, isChecked: boolean) => {
    const key = isChecked
      ? [...checkedKeys, value]
      : checkedKeys.filter((item) => item !== value);
    setCheckedKeys(key);
  };

  const handleAssociate = () => {
    if (checkedKeys && checkedKeys.length) {
      dispatch(associateDelegates({ cycle, delegatesIds: checkedKeys }));
      setCheckedKeys([]);
      setAssociationModalIsOpen(false);
    } else {
      errorToast("Veuillez sélectionner un ou plusieurs délégués");
    }
  };
  const removeAssociation = (cycleId: number, userId: number) => {
    dispatch(dissociateDelegates({ cycleId, userId }));
  };

  const [visible, setVisible] = React.useState(false);

  const handleChange = () => {
    setVisible(!visible);
  };

  return (
    <>
      <div className="cycle-managemnt-card__wrapper">
        <div className="cycle-management-card__bottom">
          <FlexboxGrid align="top">
            <Col xs={9} className="card-description">
              <p>{cycle.cycleName || "-"}</p>
            </Col>
            <Col xs={9} className="card-settings">
              <div className="card-settings__data">
                <div className="card-settings__data--freq">
                  {cycle.frequencies.map((item: Frequency) => (
                    <dl key={item.potential.potentialId}>
                      <dt>{item.freq}</dt>
                      <dd>Fréq. {item.potential.potentialName}</dd>
                    </dl>
                  ))}
                </div>
                <div className="card-settings__data--others">
                  <Divider vertical />
                  <dl>
                    <dt>{cycle.nbWeek}</dt>
                    <dd>Semaines</dd>
                  </dl>
                  <dl>
                    <dt>{cycle.nbVisitWeek}</dt>
                    <dd>Visites/Semaine</dd>
                  </dl>
                </div>
              </div>
            </Col>
            <Col xs={4} className="card-delegates">
              <div className="card-delegates__inner">
                <div className="card-delegates__stack">
                  <UserStack
                    isCycle
                    removeAssociation={removeAssociation}
                    requestState= {requestState}
                    modalTitle="Délégués associés"
                    cycleId={cycle.cycleId}
                    persons={
                      cycle.delegates.length > 0
                        ? cycle.delegates.map((e) => ({
                            id: e.userId,
                            firstName: e.firstName,
                            lastName: e.lastName,
                            image: {
                              src: e.photoUrl,
                              alt: `${e.firstName} ${e.lastName}`,
                            },
                            cycleCount: e.cycleCount,
                            planification: e.planification,
                          }))
                        : []
                    }
                  />
                </div>
              </div>
            </Col>
            <Col xs={2} className="card-actions">
              <div className="card-actions__inner">
                <AssociateButton
                  disabled={
                    !delegatesNotAssociateDtoThisCycle ||
                    delegatesNotAssociateDtoThisCycle.length === 0
                  }
                  onClick={() => {
                    setAssociationModalIsOpen(true);
                  }}
                />
                <DeleteButton
                  disabled={cycle.delegates && cycle.delegates.length > 0}
                  onClick={() => handleDelete()}
                />
              </div>
            </Col>
            <p
              style={{
                paddingLeft: "0.4rem",
                paddingTop: "1rem",
                lineHeight: "1.2",
              }}
            >
              {cycle?.cycleDescription || ""}
            </p>
          </FlexboxGrid>
        </div>
        <Modal
          size="lg"
          open={associationModalIsOpen}
          onClose={() => setAssociationModalIsOpen(false)}
        >
          <Modal.Header>
            <Modal.Title>Associer des délégués à ce cycle</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <List style={{ marginTop: "1rem" }} size="sm">
              {delegatesNotAssociateDtoThisCycle &&
              delegatesNotAssociateDtoThisCycle.length > 0
                ? delegatesNotAssociateDtoThisCycle.map((e) => (
                    <List.Item key={e.userId}>
                      <FlexboxGrid align="middle">
                        <Col xs={1} className="checkbox-area">
                          <Checkbox
                            value={e.userId}
                            onChange={(value: any, checked) =>
                              handleCheck(value, checked)
                            }
                            checked={checkedKeys.some(
                              (item: any) => item === e.userId
                            )}
                          />
                        </Col>
                        <Col md={8} className="user-associated-person-area">
                          <div className="user-associated-person-area__inner">
                            <CustomAvatar
                              image={{
                                src: e.photoUrl,
                                alt: `${e.firstName} ${e.lastName}`,
                              }}
                              style={{ width: "2rem", height: "2rem" }}
                            />
                            <div className="fullName">
                              {e.firstName + " " + e.lastName}
                            </div>
                          </div>
                        </Col>

                        <Col xs={11} className="user-associated-buttons-area">
                          <div className="user-associated-buttons-area__inner">
                            <CyclesButton number={e.cycleCount || 0} />
                            <ProfileButton to={`/user/${e.userId}`} />
                          </div>
                        </Col>
                      </FlexboxGrid>
                    </List.Item>
                  ))
                : null}
            </List>
          </Modal.Body>
          <Modal.Footer>
            <Button
              appearance="subtle"
              onClick={() => setAssociationModalIsOpen(false)}
            >
              Annuler
            </Button>
            <Button
              className="database-submit-button"
              type="submit"
              appearance="primary"
              onClick={() => handleAssociate()}
            >
              Associer
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
      <Modal
        backdrop={"static"}
        keyboard={false}
        open={consentModalIsOpen}
        onClose={() => setConsentModalIsOpen(false)}
      >
        <Modal.Header>
          <Modal.Title>Supprimer le cycle : {cycle.cycleName}</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <p className={"delete-message"}>
            Veuillez saisir votre mot de passe afin de confirmer
          </p>
          <InputGroup inside style={{ width: "100%" }}>
            <Input
              style={{ width: "100%" }}
              className="input-full-width"
              autoComplete="none"
              placeholder="********"
              type={visible ? "text" : "password"}
              name={"pwd"}
              value={consentPassword}
              onChange={(value) => setconsentPassword(value)}
            />
            <InputGroup.Button onClick={handleChange}>
              {visible ? <UnvisibleIcon /> : <VisibleIcon />}
            </InputGroup.Button>
          </InputGroup>
        </Modal.Body>
        <Modal.Footer>
          <Button
            onClick={() => setConsentModalIsOpen(false)}
            className="cycle-remove-cancel-button"
            appearance="primary"
          >
            Annuler
          </Button>
          <Button 
            disabled={!consentPassword}
            className="cycle-remove-submit-button"
            onClick={() => {
              if (consentPassword) {
                dispatch(
                  deleteCycle({
                    itemId: cycle.cycleId,
                    password: consentPassword,
                  })
                );
                setConsentModalIsOpen(false);
              }
            }}
            appearance="primary"
          >
            Supprimer
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};
