import { useState } from "react";
import {
  getCurrentMonthToCurrentDay,
  getdateWithFormatedtTimeZoneToZero,
} from "../../../utils/dateHelpers";
// import { useParams } from "react-router-dom";

export type ReportFilterType = {
  date: [Date, Date] | undefined;
};

export type GlobalReportFilterState = {
  startDate:string | null;
  endDate: string | null;
  pagination: {
    pageNumber: number;
    pageSize: number;
  };
};
export const useFilterReports = () => {
  const [captureDateFilter, setCaptureDateFilter] = useState<any[]>();
  const [globalFilterState, setGlobalFilterState] =
    useState<GlobalReportFilterState>({
      startDate:  getCurrentMonthToCurrentDay()?.startDate || null,
      endDate:  getCurrentMonthToCurrentDay()?.endDate || null,
      pagination: {
        pageNumber: 1,
        pageSize: 10,
      },
    });



  const captureFilter = (data: ReportFilterType) => {
 
    const { date } = data;
    let startDateValue = date
      ? getdateWithFormatedtTimeZoneToZero(date[0])
      : getCurrentMonthToCurrentDay()?.startDate || null;
    let endDateValue = date
      ? getdateWithFormatedtTimeZoneToZero(date[1])
      : getCurrentMonthToCurrentDay()?.endDate || null;
    setGlobalFilterState({
      startDate: startDateValue,
      endDate: endDateValue,
      pagination: {
        ...globalFilterState.pagination,
      },
    });
    setCaptureDateFilter([startDateValue, endDateValue]);
    
  };

  const capturePageTarget = (page: number) => { 
    setGlobalFilterState({
      startDate: globalFilterState.startDate,
      endDate: globalFilterState.endDate,
      pagination: {
        ...globalFilterState.pagination,
        pageNumber: page + 1,
      },
    });
  };

  const captureLimitTarget = (limit: number) => {
    setGlobalFilterState({
      startDate: globalFilterState.startDate,
      endDate: globalFilterState.endDate,
      pagination: {
        ...globalFilterState.pagination,
        pageNumber: 1,
        pageSize: limit,
      },
    });
  };
  return {
    globalFilterState,
    captureFilter,
    capturePageTarget,
    captureLimitTarget,
    captureDateFilter,
  };
};
