import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useAppSelector } from "../../../redux/hooks";
import { RootState } from "../../../redux/store";
import { LoginForm } from "../../03-organisms/forms/authentication/LoginForm";
import { AuthTemplate } from "../../04-templates/authentication-template/AuthTemplate";
interface Props {}
 
export const LoginPage = (props: Props) => {
  const navigate = useNavigate();
  const isAuth = useAppSelector((state: RootState) => state.auth.isAuth);
  useEffect(() => {
    isAuth && navigate("/");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuth]);
  return <AuthTemplate form={<LoginForm />} />;
};
