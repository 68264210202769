import React, { createRef, useState } from "react";
import { SelectPicker } from "rsuite";
import { useAppSelector } from "../../../redux/hooks";
import { RootState } from "../../../redux/store";
import { Team } from "../../../types/Team.model";
import User from "../../../types/User.model";
import ActivityTrackingContent from "../../03-organisms/activity-tracking/ActivityTrackingContent";
import ActivityTrackingSidebar from "../../03-organisms/activity-tracking/ActivityTrackingSidebar";
import { Header } from "../../05-layouts/Header/Header";
import "./TrackingPage.scss";
type Props = {};

const TrackingPage = (props: Props) => {
  const { teams } = useAppSelector((state: RootState) => state.general);
  const [selectedTeam, setSelectedTeam] = useState<number>(0);
  const [activeUser, setActiveUser] = useState<User | null>(null);

  const selectPickerRef: any = createRef();

  //Function that format teams data into {key: teamId, label: teamName, key:teamId}[]
  const handleSelectPickerData = (teams: Team[]) => {
    if (teams && Array.isArray(teams) && teams.length ) {
      return teams.map((el: Team, index:number) => {
        return {
          key: el.teamId,
          label: el.teamName,
          value: index,
        };
      });
    } else {
      return [];
    }
  };
  const subRoutes = [
    {
      key: "team1",
      path: "",
      title: "Equipe 1",
    },
  ];

  return (
    <div className="tracking-page__wrapper">
      <Header
        leftItem={
          <div className="tracking-page__header--left">
            <div className="cycle-management__header__title">
              Suivi d'activité
            </div>
          </div>
        }
        subRoutes={subRoutes}
      />
      <div className="tracking-page__inner">
        <div className="tracking-page__inner__left">
          {teams && teams.length > 1 ? (
            <div
              ref={selectPickerRef}
              className="tracking-page__inner__left__select-team"
            >
              <SelectPicker
                defaultValue={
                  handleSelectPickerData(teams) &&
                  handleSelectPickerData(teams).length &&
                  handleSelectPickerData(teams)[0].value
                }
                searchable={false}
                cleanable={false}
                container={() => selectPickerRef.current}
                style={{ width: "100%", boxShadow: "none" }}
                data={handleSelectPickerData(teams)}
                onChange= {(value)=> {
                  setSelectedTeam(value)
                }}
              />
            </div>
          ) : (
            ""
          )}
          <ActivityTrackingSidebar
            teamMembers={teams[selectedTeam]?.members}
            activeUser={activeUser}
            setActiveUser={setActiveUser}
          />
        </div>
        <div className="tracking-page__inner__main">
          <ActivityTrackingContent />
        </div>
      </div>
    </div>
  );
};

export default TrackingPage;
