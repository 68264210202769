import moment from "moment";
import React, { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../../redux/hooks";
import {
  FiltredOrderTrackingList,
  getOrderTracking,
  getOrderTrackingListForExport,
} from "../../../../redux/Order/orderSlice";
import { RootState } from "../../../../redux/store";
import { FormatedDataOrderTrackingList, OrderTracking } from "../../../../types/OrderTracking.model";
import { getCurrentMonthToCurrentDay } from "../../../../utils/dateHelpers";
import { uniqueId } from "../../../../utils/utils";
import { FileExporter } from "../../../02-molecules/Exoort-File/FileExporter";
import FilterList from "../../../02-molecules/filterList/FilterList";
import { SearchBar } from "../../../02-molecules/searchBar/SearchBar";
import { OrderAnalyticsFilter } from "../../../03-organisms/filter/orderFilters/order-analytics-filter/OrderAnalyticsFilter";
import { useOrderAnalyticFilter } from "../../../03-organisms/filter/orderFilters/order-analytics-filter/useOrderAnalyticsFilter";
import AnalyticsOrderTable from "../orderTables/AnalyticsOrderTable";
import "./OrderAnalytics.scss";

export default function OrdeAnalytics() {
  const dispatch = useAppDispatch();
  const [searchTerm, setSearchTerm] = useState("");
  const [searchFieldIsLoading, setSearchFieldIsLoading] = useState(false);
  const [orderGlobalInfo, setOrderGlobalInfo] = useState<{
    globalTurnover: number;
    globalTurnoverAfterDiscount: number;
    globalFreeSamplesQuantity: number;
    globalQuantity: number;
  } | null>(null);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const initialDateValue: string[] = [
    moment().startOf("month").format("L"),
    moment().format("L"),
  ];
  const [appliedFilters, setAppliedFilters] = useState<string[]>([
    initialDateValue[0],
    initialDateValue[1],
  ]);
  const { errors, orderTrackingList, orderTrackingListIsLoaded } =
    useAppSelector((state: RootState) => state.order);

  useEffect(() => {
    searchTerm.length && setSearchFieldIsLoading(true);
    const delayDebounceFn = setTimeout(() => {
      captureSearchTextValue(searchTerm);
    }, 500);
    return () => {
      clearTimeout(delayDebounceFn);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchTerm]);

  //get the global quantity , global turnover and glovabel free samples

  const getGlobalData = () => {
    let globalTurnoverValue = 0;
    let globalFreeSamplesQuantityValue = 0;
    let globalQuantityValue = 0;
    let globalTurnoverAfterDiscountValue = 0;
    if (
      orderTrackingList &&
      orderTrackingList.orderResponseList &&
      orderTrackingList.orderResponseList.length
    ) {
      orderTrackingList.orderResponseList.forEach((el: OrderTracking) => {
        globalTurnoverValue =
          globalTurnoverValue + el?.productOrderTraking?.totalPriceHT || 0;
        globalTurnoverAfterDiscountValue =
          globalTurnoverAfterDiscountValue +
            el?.productOrderTraking?.totalPriceHTAfterRemise || 0;
        globalFreeSamplesQuantityValue =
          globalFreeSamplesQuantityValue + el?.totalQuantityFreeProducts || 0;
        globalQuantityValue =
          globalQuantityValue + el?.totalQuantityProducts || 0;
      });
    }
    setOrderGlobalInfo({
      globalTurnover: globalTurnoverValue,
      globalFreeSamplesQuantity: globalFreeSamplesQuantityValue,
      globalQuantity: globalQuantityValue,
      globalTurnoverAfterDiscount: globalTurnoverAfterDiscountValue,
    });
  };
  useEffect(() => {
    getGlobalData();
    if (orderTrackingListIsLoaded === "idle" && !errors) {
      setSearchFieldIsLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orderTrackingListIsLoaded]);

  const {
    captureFilter,
    capturePageTarget,
    captureLimitTarget,
    captureSearchTextValue,
    captureDateProductFilter,
    formOptions,
    globalFilterState,
  } = useOrderAnalyticFilter();

  useEffect(() => {
    dispatch(getOrderTracking(globalFilterState));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [globalFilterState]);
  const dispatchOrderTrackingListForTheExportButton = async () => {
    let res = await dispatch(
      getOrderTrackingListForExport({
        products:
          captureDateProductFilter && captureDateProductFilter[2]
            ? captureDateProductFilter[2]
            : [],
        pageSize: totalCount ? totalCount : 300,
        startDate:
          captureDateProductFilter && captureDateProductFilter[0]
            ? captureDateProductFilter[0]
            : getCurrentMonthToCurrentDay()?.startDate || null,
        endDate:
          captureDateProductFilter && captureDateProductFilter[1]
            ? captureDateProductFilter[1]
            : getCurrentMonthToCurrentDay()?.endDate || null,
      })
    );
    return res.payload;
  };
  //Format the data for the table
  const formatData = (): FormatedDataOrderTrackingList[] => {
    if (
      orderTrackingList &&
      orderTrackingList.orderResponseList &&
      orderTrackingList.orderResponseList.length
    ) {
      return orderTrackingList.orderResponseList.map((item) => ({
        id: `${item?.doctorId} ${uniqueId()}` || 0,
        client: `${item?.title + "." || "Dr."} ${item?.doctorName || ""}`,
        potential: item?.potentialName || "",
        doctorSpeciality: item?.doctorSpecialityName || "-",
        governorate: item?.governorat || "-",
        delegation: item?.delegation || "-",
        tags: item?.tagName || "",
        products: item?.productOrderTraking?.productName || "-",
        productQuantities: item?.totalQuantityProducts || "-",
        freeSamples: item?.totalQuantityFreeProducts || "-",
        turnover: item?.productOrderTraking?.totalPriceHT?.toFixed(3) || "-",
        turnoverAfterDiscount:
          item?.productOrderTraking?.totalPriceHTAfterRemise?.toFixed(3) || "-",
      }));
    } else return [];
  };
  const prepareData = () => ({
    data: formatData(),
    currentPageNumber: orderTrackingList?.pagination?.pageNumber || 1,
    pageSize: orderTrackingList?.pagination?.pageSize || 0,
    totalCount: orderTrackingList?.pagination?.totalCount || 0,
  });
  const { data, currentPageNumber, pageSize, totalCount } = prepareData();

  //Format the data that will be exported and export it
  const formatExportedTruckingOrderData = (
    exportToCSV: (exportedData: any, filteName: string) => void
  ) => {
    dispatchOrderTrackingListForTheExportButton().then(
      (res: FiltredOrderTrackingList) => {
        let { orderResponseList } = res;
        if (orderResponseList && orderResponseList.length) {
          let result = orderResponseList.map((item) => ({
            Client: item?.doctorName || "-",
            Potentiel: item?.potentialName || "-",
            Spécailité: item?.doctorSpecialityName || "-",
            Gouvernorat: item?.governorat || "-",
            Délégation: item?.delegation || "-",
            Produits: item?.productOrderTraking?.productName || "-",
            Quantité: item?.totalQuantityProducts || "-",
            Gratuité: item?.totalQuantityFreeProducts || "-",
            "Chiffre d'affaire HT (DT)":
              item?.productOrderTraking?.totalPriceHT?.toFixed(3) || "-",
            "Chiffre d'affaire HT après remise (DT)":
              item?.productOrderTraking?.totalPriceHTAfterRemise?.toFixed(3) ||
              "-",
          }));
          exportToCSV(result, "Analytique commandes");
        } else {
          exportToCSV([{ Commentaire: "liste vide" }], "Liste des commandes");
        }
      }
    );
  };

  return (
    <div className="order-template__wrapper">
      <div className="top-area">
        <SearchBar
          placeHolder="Recherche"
          setSearchTerm={setSearchTerm}
          searchFieldIsLoading={searchFieldIsLoading}
          typeClasse="order-search-bar"
        />
        <div className="filter-export-btns">
          <OrderAnalyticsFilter
            captureFilter={captureFilter}
            setAppliedFilters={setAppliedFilters}
            dateValue={undefined}
            productOptions={formOptions}
          />
          <FileExporter
            getDataAndFormatDataFunction={formatExportedTruckingOrderData}
          />
        </div>
      </div>
      <FilterList filterList={appliedFilters} />

      <div className="Genereal-information-order-analytics__wrapper">
        <div>
          Quantités :
          <span className="quanty-pice-text-style">
            {" " + orderGlobalInfo?.globalQuantity || " -"}
          </span>
        </div>
        <div>
          Gratuité :
          <span className="quanty-pice-text-style">
            {" " + orderGlobalInfo?.globalFreeSamplesQuantity || " -"}
          </span>
        </div>
        <div>
          C.A HT :
          <span className="quanty-pice-text-style">
            {` ${orderGlobalInfo?.globalTurnover?.toFixed(3) || " -"} DT`}
          </span>
        </div>
        <div>
          C.A HT <span style={{ fontSize: "12px" }}>après remise</span> :
          <span className="quanty-pice-text-style">
            {` ${
              orderGlobalInfo?.globalTurnoverAfterDiscount?.toFixed(3) || " -"
            } DT`}
          </span>
        </div>
      </div>

      <div className="table-area">
        <AnalyticsOrderTable
          data={data}
          orderTrackingListIsLoaded={orderTrackingListIsLoaded}
          pageSize={pageSize}
          totalCount={totalCount}
          currentPageNumber={currentPageNumber}
          captureLimitTarget={captureLimitTarget}
          capturePageTarget={capturePageTarget}
        />
      </div>
    </div>
  );
}
