import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import { RootState } from "../../../redux/store";
import { DoctorSummary } from "../../../types/DoctorSummary.model";
import {
  applyPlanificationChange,
  copyDoctors,
  redoPlanificationChange,
  selectDoctors,
  undoPlanificationChange,
} from "../../../redux/Planification/planificationSlice";
import { PlanificationWeek } from "../../../types/PlanificationWeek.model";
import { WeekContent } from "../../../types/WeekContent.model";
import { Planification } from "../../../types/Planification.model";
import { useState, useEffect } from "react";

const usePlanification = () => {
  const dispatch = useAppDispatch();
  const {
    planificationById,
    planificationByIdEdit,
    clipBoard,
    assignPlanificationDone,
    selectedDoctors,
    activeUpdatedPlanificationState,
    activeUpdatedPlanificationStateIndex,
    redoIsActive,

    undoIsActive,
    requestState,
  } = useAppSelector((state: RootState) => state.planification);

  const [innerSelected, setInnerSelected] = useState<DoctorSummary[]>([]);
  const [activeWeek, setActiveWeek] = useState<PlanificationWeek>();
  const [isLoaded, setIsLoaded] = useState<boolean>(false);
  const [copyIsActive, setCopyIsActive] = useState<boolean>(false);
  const [cutIsActive, setCutIsActive] = useState<boolean>(false);
  const [pasteIsActive, setPasteIsActive] = useState<boolean>(false);
  const [resetIsActive, setResetIsActive] = useState<boolean>(false);

  useEffect(() => {
    if (activeUpdatedPlanificationState) {
      const weekIndex =
        activeUpdatedPlanificationState.planificationWeeks.findIndex(
          (w) => w.weekId === activeWeek?.weekId
        );
      const week =
        activeUpdatedPlanificationState.planificationWeeks[weekIndex];
      if (week && week.weekContent.length > 0) {
        setResetIsActive(true);
      } else {
        setResetIsActive(false);
      }
    }

    if (selectedDoctors.length > 0) {
      dispatch(selectDoctors([]));
    }

    if (innerSelected.length > 0) {
      setInnerSelected([]);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeWeek, activeUpdatedPlanificationState]);

  useEffect(() => {
    if (innerSelected && innerSelected.length > 0) {
      setCopyIsActive(true);
      setCutIsActive(true);
    } else {
      setCopyIsActive(false);
      setCutIsActive(false);
    }
  }, [innerSelected]);

  useEffect(() => {
    if (clipBoard.length > 0) {
      setPasteIsActive(true);
    } else {
      setPasteIsActive(false);
    }
  }, [clipBoard]);

  useEffect(() => {
    if (
      activeUpdatedPlanificationState &&
      activeUpdatedPlanificationState.planificationWeeks.length > 0 &&
      !isLoaded
    ) {
      setActiveWeek(activeUpdatedPlanificationState?.planificationWeeks[0]);
      setIsLoaded(true);
    } else if (
      activeUpdatedPlanificationState &&
      activeUpdatedPlanificationState.planificationWeeks.length > 0 &&
      isLoaded
    ) {
      const weekIndex =
        activeUpdatedPlanificationState.planificationWeeks.findIndex(
          (w) => w.weekId === activeWeek?.weekId
        );
      setActiveWeek(
        activeUpdatedPlanificationState.planificationWeeks[weekIndex]
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeUpdatedPlanificationState]);

  const addToWeek = (weekContents: WeekContent[]) => {
    let index = activeUpdatedPlanificationState?.planificationWeeks.findIndex(
      (week) => week.weekId === activeWeek?.weekId
    );
    let planificationCopy = JSON.parse(
      JSON.stringify(activeUpdatedPlanificationState)
    ) as Planification;
    if (index !== undefined && index !== -1) {
      planificationCopy.planificationWeeks[index].weekContent =
        planificationCopy.planificationWeeks[index].weekContent.concat(
          weekContents
        );

      dispatch(applyPlanificationChange(planificationCopy));
    }
  };

  const handleCopy = (innerWeekSelection: DoctorSummary[]) => {
    dispatch(copyDoctors(innerWeekSelection));
  };

  const handleCut = (
    activeWeek: PlanificationWeek,
    innerWeekSelection: DoctorSummary[]
  ) => {
    dispatch(copyDoctors(innerWeekSelection));
    let newWeekContent = activeWeek.weekContent.filter(
      (item) =>
        !(
          innerWeekSelection.findIndex(
            (e) => e.doctorId === item.doctor.doctorId
          ) !== -1
        )
    );

    let index = activeUpdatedPlanificationState?.planificationWeeks.findIndex(
      (week) => week.weekId === activeWeek?.weekId
    );
    let planificationCopy = JSON.parse(
      JSON.stringify(activeUpdatedPlanificationState)
    ) as Planification;
    if (index !== undefined && index !== -1) {
      planificationCopy.planificationWeeks[index].weekContent = newWeekContent;

      dispatch(applyPlanificationChange(planificationCopy));
    }
  };

  const handlePaste = (activeWeek: PlanificationWeek) => {
    const existingDoctors = activeWeek.weekContent.map((e) => e.doctor);
    const pastable: DoctorSummary[] = clipBoard.filter(
      (item) =>
        existingDoctors.findIndex((doc) => doc.doctorId === item.doctorId) ===
        -1
    );

    let newWeekContent = [
      ...activeWeek.weekContent,
      ...pastable.map((e) => ({ doctor: e })),
    ];
    let index = activeUpdatedPlanificationState?.planificationWeeks.findIndex(
      (week) => week.weekId === activeWeek?.weekId
    );
    let planificationCopy = JSON.parse(
      JSON.stringify(activeUpdatedPlanificationState)
    ) as Planification;
    if (index !== undefined && index !== -1) {
      planificationCopy.planificationWeeks[index].weekContent = newWeekContent;

      dispatch(applyPlanificationChange(planificationCopy));
    }
  };
  const handleReset = (activeWeek: PlanificationWeek) => {
    let index = activeUpdatedPlanificationState?.planificationWeeks.findIndex(
      (week) => week.weekId === activeWeek?.weekId
    );
    let planificationCopy = JSON.parse(
      JSON.stringify(activeUpdatedPlanificationState)
    ) as Planification;
    if (index !== undefined && index !== -1) {
      planificationCopy.planificationWeeks[index].weekContent = [];

      dispatch(applyPlanificationChange(planificationCopy));
    }
  };

  const handleUndo = () => {
    dispatch(undoPlanificationChange());
  };
  const handleRedo = () => {
    dispatch(redoPlanificationChange());
  };

  return {
    planificationById,
    planificationByIdEdit,
    clipBoard,
    assignPlanificationDone,
    selectedDoctors,
    activeUpdatedPlanificationState,
    activeUpdatedPlanificationStateIndex,
    redoIsActive,
    resetIsActive,
    undoIsActive,
    copyIsActive,
    cutIsActive,
    pasteIsActive,
    handleCopy,
    handleCut,
    handlePaste,
    handleReset,
    handleUndo,
    handleRedo,
    requestState,
    innerSelected,
    setInnerSelected,
    activeWeek,
    setActiveWeek,
    addToWeek,
  };
};

export default usePlanification;
