import { ServiceType } from "../../types/ServiceType.model";
import { createSlice } from "@reduxjs/toolkit";
import { axiosPrivate } from "./../../utils/interceptors";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { Speciality } from "./../../types/Speciality.model";
import { Potential } from "./../../types/Potential.model";
import { FacilityType } from "../../types/FacilityType.model";
import { API_DEV } from "../../utils/apiRoute";
import { BusinessUnit } from "../../types/BusinessUnit.model";
import { Tag } from "../../types/Tag.model";
import { Locality } from "../../types/Locality.model";
import { Team } from "../../types/Team.model";
import { getConnectedUserId } from "../../utils/getConnectedData";
import { Channel } from "../../types/Channel.model";
import { SellerType } from "../../types/SellerType.model";
import { Product } from "../../types/Product.model";

export interface GeneralDataState {
  requestState: "loading" | "idle";
  businessUnits: BusinessUnit[];
  tags: Tag[];
  firstLevelLocalities: Locality[];
  filterLocalities: { label: string; value: number; children?: any }[];
  facilityTypes: FacilityType[];
  potentials: Potential[];
  specialities: Speciality[];
  products :Product[];
  services: ServiceType[];
  channels: Channel[];
  teams: Team[];
  sellerTypes: SellerType[];
  errors: any;
}

export const fetchGeneralData = createAsyncThunk(
  "[GeneralData]_fetch_",
  async (_, { rejectWithValue }) => {
    try {
      const facilityTypesResponse = axiosPrivate.get(`${API_DEV}/FacilityType`);
      const channelResponse = axiosPrivate.get(`${API_DEV}/Channel`);
      const potentialsResponse = axiosPrivate.get(`${API_DEV}/Potential`);
      const specialitiesResponse = axiosPrivate.get(`${API_DEV}/Speciality`);
      const servicesResponse = axiosPrivate.get(`${API_DEV}/ServiceType`);
      const businessUnitsResponse = axiosPrivate.get(`${API_DEV}/BusinessUnit`);
      const tagsResponse = axiosPrivate.get(`${API_DEV}/Tag`);
      const teamsResponse = axiosPrivate.get(
        `${API_DEV}/User/${getConnectedUserId()}/Teams`
      );
      const firstLevelLocalitiesResponse = axiosPrivate.get(
        `${API_DEV}/Locality/level/${1}?subLevel=1`
      );
      const sellerTypeResponse = axiosPrivate.get(`${API_DEV}/SellerType`);
      const productpeResponse = axiosPrivate.get(`${API_DEV}/Product`);
      const [
        facilityTypes,
        potentials,
        specialities,
        services,
        businessUnits,
        tags,
        firstLevelLocalities,
        teams,
        channels,
        sellerTypes,
        products,
      ] = await Promise.all([
        facilityTypesResponse,
        potentialsResponse,
        specialitiesResponse,
        servicesResponse,
        businessUnitsResponse,
        tagsResponse,
        firstLevelLocalitiesResponse,
        teamsResponse,
        channelResponse,
        sellerTypeResponse,
        productpeResponse,
      ]);

      return {
        facilityTypes: facilityTypes.data,
        potentials: potentials.data,
        specialities: specialities.data,
        services: services.data,
        businessUnits: businessUnits.data,
        tags: tags.data,
        firstLevelLocalities: firstLevelLocalities.data,
        teams: teams.data,
        channels: channels.data,
        sellerTypes: sellerTypes.data,
        products: products.data,
      };
    } catch (error) {
      console.log(JSON.parse(JSON.stringify(error)));
      return rejectWithValue(JSON.parse(JSON.stringify(error)));
    }
  }
);

const initialState: GeneralDataState = {
  requestState: "idle",
  businessUnits: [],
  tags: [],
  firstLevelLocalities: [],
  filterLocalities: [],
  facilityTypes: [],
  potentials: [],
  specialities: [],
  products: [],
  services: [],
  teams: [],
  channels: [],
  sellerTypes: [],
  errors: null,
};

export const generalDataSlice = createSlice({
  name: "[GeneralData]",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchGeneralData.pending, (state) => {
      state.requestState = "loading";
      state.errors = null;
    });
    builder.addCase(fetchGeneralData.fulfilled, (state, { payload }) => {
      state.requestState = "idle";
      state.errors = null;
      state.facilityTypes = payload.facilityTypes;
      state.potentials = payload.potentials;
      state.specialities = payload.specialities;
      state.services = payload.services;
      state.businessUnits = payload.businessUnits;
      state.tags = payload.tags;
      state.firstLevelLocalities = payload.firstLevelLocalities;
      state.teams = payload.teams;
      state.channels = payload.channels;
      state.sellerTypes = payload.sellerTypes;
      state.products =payload.products;
    });
    builder.addCase(
      fetchGeneralData.rejected,
      (state, { payload }: { payload: any }) => {
        state.requestState = "idle";
        state.errors = payload;
        state.facilityTypes = [];
        state.potentials = [];
        state.specialities = [];
        state.services = [];
        state.businessUnits = [];
        state.teams = [];
        state.channels = []; 
        state.sellerTypes = [];
        state.products=[];
      }
    );
  },
});

export default generalDataSlice.reducer;
