import React from "react";
import ChannelContent from "./ChannelContent";
import MessageInput from "./MessageInput";
import "./MessagesArea.scss";
type Props = {};

const MessagesArea = (props: Props) => {
  return (
    <div className="phalcon-inbox__message">
      <div className="phalcon-inbox__message--inner">
        <ChannelContent />
        <MessageInput />
      </div>
    </div>
  );
};

export default MessagesArea;
