//@ts-nocheck
import React, { useEffect, useState } from 'react'
import { useAppDispatch, useAppSelector } from '../../../redux/hooks'
// import { fetchProducts } from '../../../redux/Products/Products'
import { Box, Grid } from '@material-ui/core';
import { DataGrid, GridCellParams, GridColDef } from "@material-ui/data-grid";
import { SearchBar } from "../../02-molecules/searchBar/SearchBar"
import Button from '@material-ui/core/Button';
import AddForm from './FaciltyTypesForms/SpecsForm';
import localizedTextsMap from '../../../utils/dataGridColumnMenuLanguage';
import { getAllSpecialitiesThunk } from '../../../redux/Speciality/SpecialitySlice';
import { mdiPencil, mdiDelete } from "@mdi/js";
import Icon from "@mdi/react";
import errorToast from '../../../utils/errorToast';
import { getSpecByIdThunk } from '../../../redux/Speciality/SpecialitySlice';
import { deleteSpec } from '../../../redux/Speciality/SpecialitySlice';
import { mdiPlusCircleOutline } from '@mdi/js';

// import errorToast from '../../../utils/errorToast';

export default function Speciality() {
    const { AllSpecs } = useAppSelector(state => state.specs)
    const { FbyId } = useAppSelector(state => state.specs)
    const dispatch = useAppDispatch()
    const [gridRows, setGridRows] = useState([])
    const [searchQuery, setSearchQuery] = useState('');
    const [showAddForm, setShowAddForm] = useState(false)
    const [FacilityId, SetFById] = useState()
    useEffect(() => {
        dispatch(getAllSpecialitiesThunk())
    }, [dispatch])

    useEffect(() => {
        // console.log(Ftypes)
        const newGridRows = AllSpecs.map((item: any, i) => ({
            Name: item.specialityName,
            abreviation: item.abreviation,
            id: item.specialityId,
            toggle: item.active,
            Update: { utility: item.isUtility, id: item.specialityId }
        }));
        let filteredRows = newGridRows
        setGridRows(filteredRows)
    }, [AllSpecs])
    useEffect(() => {
        SetFById(FbyId)
    }, [FbyId])


    const renderUpdateCell = (params: GridCellParams) => {
        // console.log(params)
        return (<div style={{ display: "flex", justifyContent: "space-around", alignItems: "center", flex: 1 }}>
            <div style={{ display: "flex", justifyContent: "space-around", alignItems: "center", flex: 1 }} onClick={async () => {
                if (params.value.utility) {
                    return errorToast('specialité est utilisé')
                }
                else {
                    await dispatch(getSpecByIdThunk(params.value.id))
                    // console.log(FacilityId)
                    setShowAddForm(true)
                }
            }}   >
                <Icon style={{ color: "#61affe", cursor: "pointer" }} path={mdiPencil} size={1} />
            </div>
            <div style={{ display: "flex", justifyContent: "space-around", alignItems: "center", flex: 1 }} onClick={async () => {
                await dispatch(deleteSpec(params.value.id))
                dispatch(getAllSpecialitiesThunk())
            }}   >
                <Icon style={{ color: "red", cursor: "pointer" }} path={mdiDelete} size={1} />
            </div>
        </div>)
    };
    const handleSearchChange = (value: any) => {
        setSearchQuery(value);
    };

    const filteredRows = gridRows.filter(row => {
        return row.Name.toLowerCase().includes(searchQuery.toLowerCase());
    });

    const columns: GridColDef[] = [
        { field: 'Name', headerName: "Nom du specialité", minWidth: 190, flex: 1 },
        { field: 'abreviation', headerName: "Abreviation", minWidth: 190, flex: 1 },

        {
            field: 'Update',
            headerName: 'Actions',
            width: 110,
            renderCell: renderUpdateCell,
        },

    ];


    return (
        <>
            {showAddForm ? <AddForm SetFById={SetFById} FbyId={FacilityId} closeForm={setShowAddForm} /> : null}
            <Box sx={{ height: 600, width: "100%" }}>
                <Grid style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }} container >
                    <SearchBar containerClass='productSearchBar' placeHolder='Recherche' setSearchTerm={handleSearchChange} />
                    <Button onClick={() => setShowAddForm(true)} style={{ padding: 20, color: "white", width: "8rem", height: 10, marginRight: "1rem" }} variant="contained" size="large" color="primary" >
                        ajouter <div style={{ display: "flex", justifyContent: "center", alignItems: "center", marginLeft: 7 }}><Icon path={mdiPlusCircleOutline} size={1.2} /></div>
                    </Button>
                </Grid>
                {gridRows.length > 0 ? (
                    <DataGrid
                        rowsPerPageOptions={[5, 10, 20]}
                        localeText={localizedTextsMap}
                        style={{ backgroundColor: "white", marginRight: 12, marginLeft: 17 }}
                        rows={filteredRows}
                        columns={columns}
                        autoPageSize={10}
                        disableColumnMenu
                    />
                ) : null}
            </Box>
        </>
    )
}
