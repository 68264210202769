import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Checkbox,
  FormControlLabel,
  FormGroup,
  List,
  ListItem,
  Typography,
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import { TreeItem, TreeView } from "@material-ui/lab";
import React, { useEffect } from "react";
import { Controller } from "react-hook-form";
import { useTargetFilter } from "../../../../helpers/useTargetFilter";

type Props = {};
export interface FilterInputs {
  potentials: number[];
  localities: number[];
  facilityTypes: number[];
  specialities: number[];
  serviceTypes: number[];
  tags: number[];
  businessUnits: number[];
  facilities: number[];
  cycleId: number | null;
}
export const NewTargetFilter = (props: Props) => {
  const { filtersDisplayData, filterValues, captureFilterInputs } =
    useTargetFilter();

  const [expanded, setExpanded] = React.useState<string[]>([]);
  const [selected, setSelected] = React.useState<string[]>([]);
  const drawerCustomizedStyle = {maxHeight: "260px", overflow: "auto"};
  const handleToggle = (event: React.ChangeEvent<{}>, nodeIds: string[]) => {
    setExpanded(nodeIds);
  };

  const handleSelect = (event: React.ChangeEvent<{}>, nodeIds: string[]) => {
    const first = nodeIds[0];
    if (selected.includes(first)) {
      setSelected(selected.filter((id) => id !== first));
    } else {
      setSelected([first, ...selected]);
    }
  };

  useEffect(() => {
    captureFilterInputs({
      ...filterValues,
      localities: selected.map((e) => ({ localityId: parseInt(e) })),
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selected]);
  const handlePotentialChange = (checked: boolean, potential: any) => {
    checked
      ? captureFilterInputs({
          ...filterValues,
          potentials: [
            ...filterValues.potentials,
            { potentialId: potential.value },
          ],
        })
      : captureFilterInputs({
          ...filterValues,
          potentials: filterValues.potentials.filter(
            (e) => e.potentialId !== potential.value
          ),
        });
  };
  const handleSpecialityChange = (checked: boolean, speciality: any) => {
    checked
      ? captureFilterInputs({
          ...filterValues,
          specialities: [
            ...filterValues.specialities,
            { specialityId: speciality.value },
          ],
        })
      : captureFilterInputs({
          ...filterValues,
          specialities: filterValues.specialities.filter(
            (e) => e.specialityId !== speciality.value
          ),
        });
  };
  // const handleServiceTypeChange = (checked: boolean, serviceType: any) => {
  //   checked
  //     ? captureFilterInputs({
  //         ...filterValues,
  //         serviceTypes: [
  //           ...filterValues.serviceTypes,
  //           { serviceTypeId: serviceType.value },
  //         ],
  //       })
  //     : captureFilterInputs({
  //         ...filterValues,
  //         serviceTypes: filterValues.serviceTypes.filter(
  //           (e) => e.serviceTypeId !== serviceType.value
  //         ),
  //       });
  // };
  const handleTagChange = (checked: boolean, tag: any) => {
    checked
      ? captureFilterInputs({
          ...filterValues,
          tags: [...filterValues.tags, { tagId: tag.value }],
        })
      : captureFilterInputs({
          ...filterValues,
          tags: filterValues.tags.filter((e) => e.tagId !== tag.value),
        });
  };
  const handleFacilityTypeChange = (checked: boolean, facilityType: any) => {
    checked
      ? captureFilterInputs({
          ...filterValues,
          facilityTypes: [
            ...filterValues.facilityTypes,
            { facilityTypeId: facilityType.value },
          ],
        })
      : captureFilterInputs({
          ...filterValues,
          facilityTypes: filterValues.facilityTypes.filter(
            (e) => e.facilityTypeId !== facilityType.value
          ),
        });
  };

  return (
    <>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography>Localités</Typography>
        </AccordionSummary>
        <AccordionDetails  style={drawerCustomizedStyle}>
          <List>
            <TreeView
              defaultCollapseIcon={<ExpandMoreIcon />}
              defaultExpandIcon={<ChevronRightIcon />}
              expanded={expanded}
              selected={selected}
              onNodeToggle={handleToggle}
              onNodeSelect={handleSelect}
              multiSelect
            >
              {filtersDisplayData.localitiesData.map((loc) => (
                <TreeItem
                key={loc.value}
                  nodeId={loc.value.toString()}
                  label={
                    <FormGroup>
                      <FormControlLabel
                        label={loc.label}
                        control={
                          <Checkbox
                            size="small"
                            color="primary"
                            value={loc.value}
                            checked={selected.includes(loc.value.toString())}
                          />
                        }
                      />
                    </FormGroup>
                  }
                >
                  {loc.children.length > 0 &&
                    loc.children.map((loc2) => (
                      <TreeItem
                        nodeId={loc2.value.toString()}
                        label={loc2.label}
                          key={loc.value}
                      />
                    ))}
                </TreeItem>
              ))}
            </TreeView>
          </List>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography>Potentiel</Typography>
        </AccordionSummary>
        <AccordionDetails style={drawerCustomizedStyle} >
          <List>
            {filtersDisplayData.potentialsData.map((potential, index) => {
              return (
                <ListItem dense key={index}>
                  <FormGroup >
                    <FormControlLabel
                      label={potential.label}
                      control={
                        <Checkbox
                          size="small"
                          color="primary"
                          name={"potentials"}
                          value={potential.value}
                          onChange={(event: any, checked: boolean) =>
                            handlePotentialChange(checked, potential)
                          }
                        />
                      }
                    />
                  </FormGroup>
                </ListItem>
              );
            })}
          </List>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography>Spécialité</Typography>
        </AccordionSummary>
        <AccordionDetails style={drawerCustomizedStyle}>
          <List>
            {filtersDisplayData.specialitiesData.map((speciality, index) => {
              return (
                <ListItem dense key={index}>
                  <FormGroup >
                    <FormControlLabel
                      label={speciality.label}
                      control={
                        <Checkbox
                          size="small"
                          color="primary"
                          name={"specialities"}
                          value={speciality.value}
                          onChange={(event: any, checked: boolean) =>
                            handleSpecialityChange(checked, speciality)
                          }
                        />
                      }
                    />
                  </FormGroup>
                </ListItem>
              );
            })}
          </List>
        </AccordionDetails>
      </Accordion>
      {/* {serviceType is missing in the api} */}
      {/* <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography>Service</Typography>
        </AccordionSummary>
        <AccordionDetails style={drawerCustomizedStyle}>
          <List>
            {filtersDisplayData.servicesData.map((serviceType, index) => {
              return (
                <FormGroup key={index}>
                  <FormControlLabel
                    label={serviceType.label}
                    control={
                      <Checkbox
                        size="small"
                        color="primary"
                        name={"specialities"}
                        value={serviceType.value}
                        onChange={(event: any, checked: boolean) =>
                          handleServiceTypeChange(checked, serviceType)
                        }
                      />
                    }
                  />
                </FormGroup>
              );
            })}
          </List>
        </AccordionDetails>
      </Accordion> */}
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography>Tags</Typography>
        </AccordionSummary>
        <AccordionDetails style={drawerCustomizedStyle}>
          <List>
            {filtersDisplayData.tagsData.map((tag, index) => {
              return (
                <FormGroup key={index}>
                  <FormControlLabel
                    label={tag.label}
                    control={
                      <Checkbox
                        size="small"
                        color="primary"
                        name={"specialities"}
                        value={tag.value}
                        onChange={(event: any, checked: boolean) =>
                          handleTagChange(checked, tag)
                        }
                      />
                    }
                  />
                </FormGroup>
              );
            })}
          </List>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography>Type d'établissement</Typography>
        </AccordionSummary>
        <AccordionDetails style={drawerCustomizedStyle}>
          <List>
            {filtersDisplayData.facilityTypesData.map((facilityType, index) => {
              return (
                <FormGroup key={index}>
                  <FormControlLabel
                    label={facilityType.label}
                    control={
                      <Checkbox
                        size="small"
                        color="primary"
                        name={"specialities"}
                        value={facilityType.value}
                        onChange={(event: any, checked: boolean) =>
                          handleFacilityTypeChange(checked, facilityType)
                        }
                      />
                    }
                  />
                </FormGroup>
              );
            })}
          </List>
        </AccordionDetails>
      </Accordion>
    </>
  );
};

export const FormCheckBox = ({
  name,
  value,
  register,
  control,
  onSubmit,
}: any) => {
  return (
    <FormControlLabel
      value={value}
      control={
        <Controller
          render={({ field: { name, value, onChange } }) => (
            <Checkbox
              name={name}
              value={value}
              ref={register}
              onChange={() => {
                onChange();
                onSubmit();
              }}
            />
          )}
          control={control}
          name={name}
        />
      }
      label={name}
    />
  );
};
