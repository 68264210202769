import { createSlice } from "@reduxjs/toolkit";
import { axiosPrivate } from "../../utils/interceptors";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { API_DEV } from "../../utils/apiRoute";
import { GeneralDataSource } from "../../types/GeneralDataSource.model";
import { RequestDataSource } from "../../types/RequestDataSource.model";
import { ObjectionDataSource } from "../../types/ObjectionDataSource.model";
import { VisitDataSource } from "../../types/VisitDataSource.model";
export interface RpDataSource {
  rpEventId: number;
  subject: string;
  productId: number;
  productName: string;
  createdByUserId: number;
  organisator: string;
  managerId: number;
  buid: number;
  buname: string;
  typeRp: string;
  startDate: string;
  endDate: string;
  consumedBudget: number;
  estimatedBudget: number;
  totalGuests: number;
  totalAttendees: number;
  statusRpEventId: number;
  statusRp: string;
  active: number;
}
export interface BiState {
  requestState: "idle" | "loading";
  biRpData: RpDataSource[];
  biVisitData: VisitDataSource;
  biGeneralData: GeneralDataSource;
  biRequestData: RequestDataSource;
  biObjectionData: ObjectionDataSource;
  errors: any;
}

export const getBiRpData = createAsyncThunk(
  "[Bi]__getBiRpByProductData__",
  async (_, { rejectWithValue }) => {
    try {
      const response = await axiosPrivate.get("http://localhost:3004/RpView");
      return response.data;
    } catch (error) {
      console.log(JSON.parse(JSON.stringify(error)));
      return rejectWithValue(JSON.parse(JSON.stringify(error)));
    }
  }
);
export const getBiRequestData = createAsyncThunk(
  "[Bi]__getBiRequestAnalytics__",
  async (options: any, { rejectWithValue }) => {
    try {
      const response = await axiosPrivate.post(
        `${API_DEV}/Analytics/GetRequestsAnalytics`,
        options
      );
      return response.data;
    } catch (error) {
      console.log(JSON.parse(JSON.stringify(error)));
      return rejectWithValue(JSON.parse(JSON.stringify(error)));
    }
  }
);
export const getBiObjectionData = createAsyncThunk(
  "[Bi]__getBiObjectionsAnalytics__",
  async (options: any, { rejectWithValue }) => {
    try {
      const response = await axiosPrivate.post(
        `${API_DEV}/Analytics/GetObjectionsAnalytics`,
        options
      );
      return response.data;
    } catch (error) {
      console.log(JSON.parse(JSON.stringify(error)));
      return rejectWithValue(JSON.parse(JSON.stringify(error)));
    }
  }
);

export const getBiGeneralData = createAsyncThunk(
  "[Bi]__getBiGeneralData__",
  async (_, { rejectWithValue }) => {
    try {
      const response = await axiosPrivate.get(
        `${API_DEV}/Analytics/GetGeneralAnalytics`
      );
      return response.data;
    } catch (error) {
      console.log(JSON.parse(JSON.stringify(error)));
      return rejectWithValue(JSON.parse(JSON.stringify(error)));
    }
  }
);
export const getBiVisitData = createAsyncThunk(
  "[Bi]__getVisitData__",
  async (options: any, { rejectWithValue }) => {
    try {
      const daysInsightsResponse = axiosPrivate.post(
        `${API_DEV}/Analytics/Visits/DaysInsights`,
        options
      );
      const visitsInsightsResponse = axiosPrivate.post(
        `${API_DEV}/Analytics/Visits/GetVisitsInsightsV2`,
        options
      );
      const [daysInsights, visitsInsights] = await Promise.all([
        daysInsightsResponse,
        visitsInsightsResponse,
      ]);

      return {
        dayInsights: daysInsights.data,
        visitsInsights: visitsInsights.data,
      };
    } catch (error) {
      console.log(JSON.parse(JSON.stringify(error)));
      return rejectWithValue(JSON.parse(JSON.stringify(error)));
    }
  }
);

const initialState: BiState = {
  requestState: "idle",
  biVisitData: {
    dayInsights: {
      daysByType: [],
      activityDaysByType: [],
    },
    visitsInsights: {
      coveragePercentage: 0,
      visitsCount: 0,
      doctorsVisitsCount: 0,
      visitsFrequenciesByDelegate: [],
      visitsFrequenciesByDoctorSpecial: [],
      visitsFrequenciesByGovernerat: [],
      visitFrequencies: {
        frequenciesbyPotential: [],
        globalFrequency: 0,
      },
    },
  },
  biRpData: [],
  biGeneralData: {
    facilitiesCount: 0,
    officesCount: 0,
    delegatesCount: 0,
    facilitiesByType: [],
    doctorsGeographics: [],
    doctorsBySpeciality: [],
    doctorsByPotential: [],
    portfoliosByPotential: [],
  },
  biRequestData: {
    requestsByType: [],
    treatedCount: 0,
    requestsByDelegate: [],
    totalCount: 0,
    unTreatedRequests: {
      unTreatedRequestsByPotential: [],
      total: 0,
    },
  },
  biObjectionData: {
    objectionsByDelegate: [],
    treatedCount: 0,
    treatedObjectionsBySatisfactionLevel: [],
    totalCount: 0,
    unTreatedObjections: {
      unTreatedObjectionsByPotential: [],
      total: 0,
    },
    objectionsByProduct: [],
  },
  errors: null,
};

export const commonslice = createSlice({
  name: "[Common]",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getBiRpData.pending, (state) => {
      state.requestState = "loading";
      state.errors = null;
    });
    builder.addCase(getBiRpData.fulfilled, (state, { payload }) => {
      state.requestState = "idle";
      state.biRpData = payload;
      state.errors = null;
    });
    builder.addCase(getBiRpData.rejected, (state, { payload }) => {
      state.requestState = "idle";
      state.errors = payload;
    });
    builder.addCase(getBiRequestData.pending, (state) => {
      state.requestState = "loading";
      state.errors = null;
    });
    builder.addCase(getBiRequestData.fulfilled, (state, { payload }) => {
      state.requestState = "idle";
      state.biRequestData = payload;
      state.errors = null;
    });
    builder.addCase(getBiRequestData.rejected, (state, { payload }) => {
      state.requestState = "idle";
      state.errors = payload;
    });
    builder.addCase(getBiObjectionData.pending, (state) => {
      state.requestState = "loading";
      state.errors = null;
    });
    builder.addCase(getBiObjectionData.fulfilled, (state, { payload }) => {
      state.requestState = "idle";
      state.biObjectionData = payload;
      state.errors = null;
    });
    builder.addCase(getBiObjectionData.rejected, (state, { payload }) => {
      state.requestState = "idle";
      state.errors = payload;
    });
    builder.addCase(getBiGeneralData.pending, (state) => {
      state.requestState = "loading";
      state.errors = null;
    });
    builder.addCase(getBiGeneralData.fulfilled, (state, { payload }) => {
      state.requestState = "idle";
      state.biGeneralData = payload;
      state.errors = null;
    });
    builder.addCase(getBiGeneralData.rejected, (state, { payload }) => {
      state.requestState = "idle";
      state.errors = payload;
    });
    builder.addCase(getBiVisitData.pending, (state) => {
      state.requestState = "loading";
      state.errors = null;
    });
    builder.addCase(getBiVisitData.fulfilled, (state, { payload }) => {
      state.requestState = "idle";
      state.biVisitData = payload;
      state.errors = null;
    });
    builder.addCase(getBiVisitData.rejected, (state, { payload }) => {
      state.requestState = "idle";
      state.errors = payload;
    });
  },
});

export default commonslice.reducer;
