import * as React from "react";
import { Link } from "react-router-dom";
import User from "../../../../types/User.model";
import { CustomAvatar } from "../../../01-atoms/CustomAvatar/CustomAvatar";

import "./TeamMemberCard.scss";
interface Props {
  member: User;
}

export const TeamMemberCard = ({ member }: Props) => {
  return (
    <Link to={`/user/${member.userId}`}> 
      <div className="team-member__card ">
        <CustomAvatar
          image={{
            src: member.photoUrl,
            alt: `${member.firstName} ${member.lastName}'s avatar`,
          }}
          style={{ width: "75px", height: "75px" }}
        />

        <div className="content"> 
          <div className="fullname">
            {`${member.firstName} ${member.lastName}`}
          </div>
          <div className="occupation">{`${
            member.role === 3 ? "Délégué" : "Manager"
          }`}</div>
        </div>
      </div> 
    </Link>
  );
};
