import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Button, Divider, FlexboxGrid, Form, Loader, Modal } from "rsuite";
import { SelectField } from "../../../02-molecules/forms/SelectField/SelectField";
import { TextField } from "../../../02-molecules/forms/TextField/TextField";
import { useAppDispatch, useAppSelector } from "../../../../redux/hooks";
import useFacilityForm, {
  fieldIsRequired,
  preparefacilityFormPayload,
  facilityFormInputs,
} from "./useFacilityForm";
import { 
  addFacility,
  updateFacility,
} from "../../../../redux/Facility/facilitySlice";
import { PhonesBlock } from "../phonesBlock/PhonesBlock";
import AddressBlock from "../addressBlock/AddressBlock";
import "./FormModal.scss";
import ServicePhonesBlock from "./ServicePhonesBlock";
import { TagsField } from "../../../02-molecules/forms/TagsField/TagsField";
import { Locality } from "../../../../types/Locality.model";
import { RootState } from "../../../../redux/store";
type Props = {
  formContext: "add" | "update";
};

const FacilityForm = ({ formContext }: Props) => {
  const dispatch = useAppDispatch();
  const sectorOptions = [
    {
      key: 0,
      label: "Public",
      value: 0,
    },
    {
      key: 1,
      label: "Privé",
      value: 1,
    },
  ];
  const { formIsReady, formOptions, formUI, initialValues, setFormIsReady } =
    useFacilityForm({ formContext });

  const {
    control,
    handleSubmit,
    reset,
    watch,
    setValue,
    formState: { errors },
  } = useForm<facilityFormInputs>({
    defaultValues: initialValues,
    criteriaMode: "all",
    reValidateMode: "onChange",
  });
  const { requestStateForm } = useAppSelector((state: RootState) => state.facility);

  const [initialLocalities, setInitialLocalities] = useState<Locality[]>([]);
  useEffect(() => {
    reset(initialValues);
    initialValues?.address?.localities.length >0 && setInitialLocalities(initialValues?.address?.localities);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialValues,requestStateForm]);

  const onSubmit = handleSubmit((data) => {
    const payload = preparefacilityFormPayload(data);
    formContext === "add" && dispatch(addFacility(payload));
    formContext === "update" && dispatch(updateFacility(payload));
  });
  return (
    <Modal
      full
      open={formUI?.formIsOpen}
      backdrop="static"
      onClose={formUI?.toggleForm}
      onExited={() => setFormIsReady(false)}
      dialogClassName="form-modal"
    >
      <Modal.Header>
        <Modal.Title>
          {formContext === "add" ? "Ajouter" : "Modifier"} un Etablissement
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="custom-modal-body">
        {formIsReady ? (
          <Form>
            <div className="custom-form-section">
              <h3 className="form-block-title">Informations générales</h3>
              <Divider style={{ margin: "1rem 0" }} />
              <FlexboxGrid align="bottom">
                {/* facilityType */}
                <SelectField
                  control={control}
                  errors={errors}
                  label={"Type d'établissement"}
                  inputName="facilityType.facilityTypeId"
                  rules={{ required: fieldIsRequired }}
                  data={formOptions?.facilityTypesOptions || []}
                  xs={24}
                  sm={24}
                  md={4}
                  cleanable={false}
                  searchable={false}
                />
                {/* sector */}
                <SelectField
                  control={control}
                  errors={errors}
                  label={"Secteur"}
                  inputName="sector"
                  rules={{ required: fieldIsRequired }}
                  data={sectorOptions}
                  xs={24}
                  sm={24}
                  md={4}
                  cleanable={false}
                  searchable={false}
                />
                {/* name */}
                <TextField
                  label={"Nom de l'établissement"}
                  inputName="facilityName"
                  rules={{ required: fieldIsRequired }}
                  control={control}
                  errors={errors}
                  xs={24}
                  sm={24}
                  md={16}
                />
              </FlexboxGrid>
              {/* adress Block */}
              <AddressBlock
                localitiesField={`address.localities`}
                streetNameField={`address.streetName`}
                noteAdressField={`address.addressNote`}
                initialLocalities={initialLocalities}
                fieldIsRequired={fieldIsRequired}
                control={control}
                errors={errors}
                noteAddress
                setValue={setValue}
                formContext={formContext}
              />
            </div>
            <PhonesBlock
              fieldIsRequired={fieldIsRequired}
              control={control}
              errors={errors}
              watch={watch}
            />
            {/* Tags Block */}
            <div className="custom-form-section">
              <FlexboxGrid align="bottom">
                <TagsField
                  control={control}
                  errors={errors}
                  creatable
                  data={formOptions ? formOptions.tagsOptions : []}
                  xs={24}
                  label={"Tags"}
                  inputName={"tags"}
                />
              </FlexboxGrid>
            </div>
            <ServicePhonesBlock
              fieldIsRequired={fieldIsRequired}
              control={control}
              errors={errors}
              
            />
          </Form>
        ) : (
          <Loader size="lg" center content="loading" />
        )}
      </Modal.Body>

      <Modal.Footer style={{ paddingTop: "1rem" }}>
        <Button onClick={formUI?.toggleForm}>Annuler</Button>
        <Button
          className="database-submit-button"
          type="submit"
          appearance="primary"
          onClick={() => onSubmit()}
        >
          Enregistrer
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default FacilityForm;
