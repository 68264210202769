import React, { createRef } from "react";
import { Controller } from "react-hook-form";
import { DatePicker, Form, Col } from "rsuite";
import { getUtcDate } from "../../../../utils/dateHelpers";
import { CustomErrorMessage } from "../ErrorMessage/CustomErrorMessage";
type Props = {
  control: any;
  errors: any;
  label: string;
  inputName: string;
  rules: any;
  xs?: number;
  md?: number;
  lg?: number;
  cleanable?: boolean;
  readOnly?: boolean;
  disabledDate?: any;
  disabled?: boolean;
  handleDateSelection?: any;
};

export const DateField = ({
  label,
  control,
  inputName,
  errors,
  rules,
  xs,
  md,
  lg,
  cleanable,
  readOnly,
  disabledDate,
  disabled,
  handleDateSelection,
}: Props) => {
  const datePickerRef: any = createRef();
  return (
    <Col xs={xs || 24} md={md} lg={lg} style={{ marginBottom: "1rem" }}>
      <Form.Group>
        <Form.ControlLabel>{label}</Form.ControlLabel>
        <Controller
          rules={rules}
          control={control}
          name={inputName}
          render={({ field: { onChange, name, value } }) => (
            <Form.Control
              ref={datePickerRef}
              accepter={DatePicker}
              name={name}
              value={value}
              oneTap
              className="input-full-width"
              disabledDate={disabledDate}
              disabled={disabled}
              format={"yyyy-LL-dd"}
              style={{ width: "100%" }}
              cleanable={cleanable}
              readOnly={readOnly}
              container={() => datePickerRef.current}
              preventOverflow
              menuClassName="picker-fixed-top"
              placement="autoVerticalStart"
              isoWeek
              onChange={(e: any) => {
                const date = typeof e == "string" ? getUtcDate(e) : e;
                onChange(date);
                handleDateSelection && handleDateSelection(date, name);
              }}
            />
          )}
        />
        <CustomErrorMessage errors={errors} name={inputName} />
      </Form.Group>
    </Col>
  );
};
