import React from "react";
import "./ActivityTrackingVisitTable.scss";
import {
  DataGrid,
  GridCellParams,
  GridColDef,
  GridValueGetterParams,
} from "@material-ui/data-grid";
import { useActivityTracking } from "./useActivityTraking";
import ChannelCell from "../tables/common/Cells/ChannelCell";
import WeekStatusCell from "../tables/common/Cells/WeekStatusCell";
import { weekStatusEnum } from "../../../Enumeration/WeekContentStatusEnum";
import { WeekContent } from "../../../types/WeekContent.model";
import localizedTextsMap from "../../../utils/dataGridColumnMenuLanguage";
import DateCell from "../tables/common/Cells/DateCell";

type Props = {
  weekContents: WeekContent[];
};

const ActivityTrackingVisitTable = ({ weekContents }: Props) => {
  const { rows } = useActivityTracking({ weekContents });

  const columns: GridColDef[] = [
    { field: "id", headerName: "ID", width: 90, hide: true },
    {
      field: "createReportOn", 
      headerName: "Date visite",
      minWidth: 170,
      flex: 1,
      resizable: true,
      renderCell: (params: GridCellParams) => {
        return <DateCell item={params.row.createReportOn} />;
      },
    },
    {
      field: "client",
      headerName: "Client",
      minWidth: 300,
      flex: 1,
      resizable: true,
      valueGetter: (params: GridValueGetterParams) =>
        `${params?.row?.client?.title || ""} ${
          params?.row?.client?.firstName || ""
        } ${params?.row?.client?.lastName || ""}`,
    },
    {
      field: "speciality",
      headerName: "Spécialité",
      width: 160,
      valueGetter: (params: GridValueGetterParams) =>
        `${params?.row?.specialities[0]?.abreviation || ""} `,
    },
    {
      field: "potential",
      headerName: "Potentiel",
      // align: "center",
      // sortable: false,
      width: 150,
      renderCell: (params: GridCellParams) => {
        return (
          <div
            className={`potential-color pot${params?.row?.potential?.potentialId || ""} `}
          >
            {params?.row?.potential?.potentialName || ""}
          </div>
        );
      },
      valueGetter: (params: GridValueGetterParams) =>
        `${params?.row?.potential?.potentialName || ""}`,
    },
    {
      field: "principalAddress-Gov",
      headerName: "Gouvernerat",
      width: 170,
      valueGetter: (params: GridValueGetterParams) =>
        `${
          params?.row?.principalAddress?.address?.localities[0]?.localityName || ""
        } `,
    },
    {
      field: "principalAddress-Del",
      headerName: "Delegation",
      width: 200,
      valueGetter: (params: GridValueGetterParams) =>
        ` ${
          params?.row?.principalAddress?.address?.localities[1]?.localityName || ""
        } `,
    },
    {
      field: "channel",
      headerName: "Canal de visite",
      sortable: false,
      disableColumnMenu: true,
      width: 155,
      renderCell: (params: GridCellParams) => {
        return <ChannelCell item={params.row.channel} />;
      },
      valueGetter: (params: GridValueGetterParams) =>
        `${params?.row?.channel?.channelName}`,
    },
    {
      field: "weekContentStatus",
      headerName: "Avancement",
      minWidth: 200,
      flex: 1,
      resizable: true,
      renderCell: (params: GridCellParams) => {
        return <WeekStatusCell item={params?.row?.weekContentStatus || 0} />;
      },
      valueGetter: (params: GridValueGetterParams) =>
        `${weekStatusEnum[params.row.weekContentStatus].status}`,
    },
  ];

  return (
    <div className="activity-tracking-card__wrapper">
      <DataGrid
        rows={rows}
        columns={columns}
        pageSize={100}
        rowsPerPageOptions={[10]}
        checkboxSelection={false}
        disableSelectionOnClick
        localeText={localizedTextsMap}
      />
    </div>
  );
};

export default ActivityTrackingVisitTable;
