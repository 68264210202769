import {
  TableContainer,
  Table,
  Paper,
  TableHead,
  TableBody,
  TableCell,
  TableRow,
  Avatar,
  Tooltip,
  Typography,
  Box,
  makeStyles,
  createStyles,
  Theme,
  styled,
} from "@material-ui/core";
import { removeDuplicateDataFromString } from "../../../helpers/dataFormator";
import imageEnvironmentUrl from "../../../imageEnvironmentUrl";
import {
  DailyVisitStatistics,
  DelegateDailyVisits,
} from "../../../types/Dashboard.model";
import { getDayName } from "../../../utils/dateHelpers";
import "./Dashboard.scss";

interface TeamVisitsTableProps {
  data: DailyVisitStatistics[];
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    progressCell: {
      display: "flex",
      alignItems: "center",
      width: "100%",
    },
    progressLabel: {
      whiteSpace: "nowrap",
      marginLeft: theme.spacing(2),
    },
    progressBar: {
      height: ".75rem",
      borderRadius: "2rem",
      width: "100%",
    },
    tableTitle: {
      padding: theme.spacing(2),
    },
    totalCell: {
      backgroundColor: "#49cc90",
      height: theme.spacing(4),
      width: theme.spacing(4),
      color: "white",
      padding: theme.spacing(1),
      borderRadius: "100%",
      marginLeft: "auto",
      marginRight: "auto",
    },
  })
);

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(2n)": {
    backgroundColor: "#F5F6FA",
  },
  "&:nth-of-type(odd)": {
    backgroundColor: "#FFFFFF",
  },
}));

const listOfColumns: {
  label: string;
  align: "left" | "right" | "inherit" | "center" | "justify" | undefined;
}[] = [
  { label: "Date", align: "left" },
  { label: "Délégué", align: "left" },
  { label: "EADV", align: "left" },
  { label: "En attente", align: "left" },
  { label: "Clôturée", align: "left" },
  { label: "Gouvernorats", align: "left" },
  { label: "Délégation", align: "left" },
  { label: "Pot. A", align: "left" },
  { label: "Pot. B", align: "left" },
  { label: "Pot. C", align: "left" },
];

const handleRuturnedRow = (
  element: any,
  key: number | string,
  className: string,
  c?: string
) => (
  <div key={key} className={className}>
    <span className={c || ""}>{element} </span>
  </div>
);

// function that handle the render of rows info
const handledelegateInfoRow = (
  columnName: string,
  delegatesDailyVisits: DelegateDailyVisits[]
) =>
  delegatesDailyVisits && delegatesDailyVisits.length ? (
    <TableCell style={{ whiteSpace: "nowrap" }}>
      {delegatesDailyVisits.map(
        (information: DelegateDailyVisits, index: number) => {
          let className: string =
            delegatesDailyVisits.length > 1
              ? "daily-visit-dasboard-section with-padding"
              : "daily-visit-dasboard-section";
          if (columnName === "delegateNameColumn") {
            return (
              <div
                key={information?.userId || index + "#0123"}
                className={className}
              >
                <Tooltip
                  placement="right"
                  title={information?.delegateName || "-"}
                  aria-label={information?.delegateName || "-"}
                >
                  <Avatar
                    alt={information?.delegateName || "-"}
                    src={
                      information?.photoUrl
                        ? `${imageEnvironmentUrl.baseUrl}${information.photoUrl}`
                        : "-"
                    }
                  />
                </Tooltip>
              </div>
            );
          } else if (columnName === "eadvColumn") {
            return handleRuturnedRow(
              information?.countEADV || 0,
              information?.userId || index + "#01123",
              className,
              "visit-status-dashboard eadv"
            );
          } else if (columnName === "pendingColumn") {
            return handleRuturnedRow(
              information?.countPending || 0,
              information?.userId || index + "#01223",
              className,
              "visit-status-dashboard pending"
            );
          } else if (columnName === "closedVisitColumn") {
            return handleRuturnedRow(
              information?.countCloture || 0,
              information?.userId || index + "#03223",
              className,
              "visit-status-dashboard closed"
            );
          } else if (columnName === "govColumn") {
            let uniqueGov: string = removeDuplicateDataFromString(
              information?.governorate || ""
            );
            return handleRuturnedRow(
              uniqueGov || 0,
              information?.userId || index + "#05223",
              className
            );
          } else if (columnName === "delegationColumn") {
            let uniqueDelegation: string = removeDuplicateDataFromString(
              information?.delegation || ""
            ); 
            return handleRuturnedRow(
              uniqueDelegation || 0,
              information?.userId || index + "#07223",
              className
            );
          } else if (columnName === "potAColumn") {
            return handleRuturnedRow(
              information?.countPoteantialA || 0,
              information?.userId || index + "#08223",
              className,
              "visit-status-dashboard"
            );
          } else if (columnName === "potBColumn") {
            return handleRuturnedRow(
              information?.countPoteantialB || 0,
              information?.userId || index + "#09223",
              className,
              "visit-status-dashboard"
            );
          } else if (columnName === "potCColumn") {
            return handleRuturnedRow(
              information?.countPoteantialC || 0,
              information?.userId || index + "#06223",
              className,
              "visit-status-dashboard"
            );
          } else {
            return <div> - </div>;
          }
        }
      )}
    </TableCell>
  ) : (
    <TableCell> - </TableCell>
  );

const TeamDailyVisitsTable = ({ data }: TeamVisitsTableProps) => {
  const classes = useStyles();

  return (
    <TableContainer component={Paper} style={{ marginTop: "1rem" }}>
      <Box className={classes.tableTitle}>
        <Typography variant="subtitle2">
          Activité journalière de l'équipe selon le statut de la visite
          (semanine courante)
        </Typography>
      </Box>
      <Box style={{ maxHeight: "700px", maxWidth: "90vw" }}>
        <Table aria-label="simple table" size="small">
          <TableHead
            style={{ backgroundColor: "#F5F6FA", whiteSpace: "nowrap" }}
          >
            {listOfColumns.map((el, index) => {
              return (
                <TableCell align={el.align} key={index}>
                  {el.label}
                </TableCell>
              );
            })}
          </TableHead>
          <TableBody>
            {data && data.length
              ? data
                  .filter((dailyStatistics) => dailyStatistics.dateVisite)
                  .map((el: DailyVisitStatistics, index) => {
                    return (
                      <StyledTableRow key={index}>
                        <TableCell
                          align="left"
                          style={{
                            textTransform: "capitalize",
                            verticalAlign: "text-top",
                            paddingTop: "1.7rem",
                            whiteSpace: "nowrap",
                          }}
                        >
                          {getDayName(el?.dateVisite || undefined)}
                        </TableCell>
                        {handledelegateInfoRow(
                          "delegateNameColumn",
                          el.delegateDashboards
                        )}
                        {handledelegateInfoRow(
                          "eadvColumn",
                          el.delegateDashboards
                        )}
                        {handledelegateInfoRow(
                          "pendingColumn",
                          el.delegateDashboards
                        )}
                        {handledelegateInfoRow(
                          "closedVisitColumn",
                          el.delegateDashboards
                        )}
                        {handledelegateInfoRow(
                          "govColumn",
                          el.delegateDashboards
                        )}
                        {handledelegateInfoRow(
                          "delegationColumn",
                          el.delegateDashboards
                        )}
                        {handledelegateInfoRow(
                          "potAColumn",
                          el.delegateDashboards
                        )}
                        {handledelegateInfoRow(
                          "potBColumn",
                          el.delegateDashboards
                        )}
                        {handledelegateInfoRow(
                          "potCColumn",
                          el.delegateDashboards
                        )}
                      </StyledTableRow>
                    );
                  })
              : ""}
          </TableBody>
        </Table>
      </Box>
    </TableContainer>
  );
};

export default TeamDailyVisitsTable;
