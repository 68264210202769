import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import { fetchTargetById } from "../../../redux/Target/targetSlice";
import { toggleRedirectToTargetPage } from "../../../redux/UI/uiSlice";
import { TargetWeek } from "../../../types/TargetWeek.model";
import TargetSubheader from "../../03-organisms/target-subheader/TargetSubheader";
import TargetModal from "../../03-organisms/target/TargetModal";
import { WeekContent } from "../../03-organisms/target/WeekContent";
import { TargetWeeksSidebar } from "../../03-organisms/target/TargetWeeksSidebar";
import { ProfileHeader } from "../../03-organisms/user-account/profile-header/ProfileHeader";
import { Header } from "../../05-layouts/Header/Header";
import "./TargetPage.scss";
import {
  fetchDoctorSummary,
  getFilteredDoctorSummaries,
} from "../../../redux/Doctor/doctorSlice";
import useTarget from "./useTarget";
import PlanificationConfigModal from "../../03-organisms/planification/PlanificationConfigModal";
import { RootState } from "../../../redux/store";
import {} from "@mdi/js";
type Props = {};

export const TargetPage = (props: Props) => {
  const dispatch = useAppDispatch();
  const params = useParams();
  const {
    activeUpdatedState,
    handleCopy,
    handleCut,
    handlePaste,
    handleReset,
    handleRedo,
    handleUndo,
    redoIsActive,
    undoIsActive,
    handleAssign,
    assignDone,
    // resetIsActive,
    // selectedDoctors,
    // targetById,
    // targetByIdEdit,
    doctorSummary,
    requestState,
  } = useTarget();
  const [activeWeek, setActiveWeek] = useState<TargetWeek | null>(null);
  const [weekNumber, setWeekNumber] = useState<number>(0);
  const [innerSelected, setInnerSelected] = useState<number[]>([]);

  const navigate = useNavigate();
  useEffect(() => {
    if (weekNumber < 2) {
      activeUpdatedState?.targetWeeks &&
        activeUpdatedState?.targetWeeks.length > 0 &&
        setActiveWeek(activeUpdatedState?.targetWeeks[0]);
    }
    setWeekNumber(weekNumber + 1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeUpdatedState]);

  const userAddress = activeUpdatedState?.user
    ? activeUpdatedState?.user.address.localities
        .filter((e) => e.level !== 3)
        .map((e) => e.localityName)
        .join(", ")
    : "";
  const { redirectToPlanificationPage } = useAppSelector(
    (state: RootState) => state.UI
  );
  const { planificationById } = useAppSelector(
    (state: RootState) => state.planification
  );
  const cycleConfig = {
    label: activeUpdatedState?.cycle ? activeUpdatedState?.cycle.cycleName : "",
    frequencies: activeUpdatedState?.cycle
      ? activeUpdatedState?.cycle.frequencies
      : [],
    weeksNumber: activeUpdatedState?.cycle
      ? activeUpdatedState?.cycle.nbWeek
      : null,
  };

  useEffect(() => {
    dispatch(toggleRedirectToTargetPage(false));
    params.id && dispatch(fetchTargetById(params.id));
    dispatch(fetchDoctorSummary());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    dispatch(
      getFilteredDoctorSummaries({
        cycleId: activeUpdatedState?.cycle.cycleId,
      })
    );

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeUpdatedState]);

  useEffect(() => {
    if (planificationById && redirectToPlanificationPage)
      navigate(`/planification/${planificationById.planificationId}`);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [redirectToPlanificationPage, planificationById]);
  return (
    <div className="target-page__wrapper">
      <Header
        leftItem={
          <ProfileHeader
            person={activeUpdatedState?.user || null}
            context={"user"}
          />
        }
        middleItem={
          <div className="target-header">
            <div className="cycle-params">
              <div className="cycle-label">
                Ciblage | {cycleConfig.label || "-"}{" "}
              </div>

              <div className="cycle-config">
                {cycleConfig.frequencies.length > 0
                  ? cycleConfig.frequencies.map((e) => (
                      <div
                        key={e.potential.potentialId}
                        className="cycle-config--freq"
                      >
                        <div className="freq-label">
                          Fréq. <span>{e.potential.potentialName}</span>
                        </div>
                        <div className="freq-value">{e.freq}</div>
                      </div>
                    ))
                  : null}
                <div className="cycle-config--freq">
                  <div className="freq-label">Nombre de semaines</div>
                  <div className="freq-value">
                    {cycleConfig.weeksNumber || "-"}
                  </div>
                </div>
              </div>
            </div>
          </div>
        }
      />
      <TargetSubheader
        userAddress={userAddress}
        cycleConfig={cycleConfig}
        activeWeek={activeWeek}
        handleCopy={handleCopy}
        handleCut={handleCut}
        handlePaste={handlePaste}
        handleReset={handleReset}
        innerSelected={innerSelected}
        setInnerSelected={setInnerSelected}
        handleRedo={handleRedo}
        handleUndo={handleUndo}
        redoIsActive={redoIsActive}
        undoIsActive={undoIsActive}
        handleAssign={handleAssign}
        assignDone={assignDone}
        activeUpdatedState={activeUpdatedState}
        requestState={requestState}
      />
      <div className="target-page__inner">
        <div className="target-page__inner__left">
          <TargetWeeksSidebar
            handleCopy={handleCopy}
            handleCut={handleCut}
            handlePaste={handlePaste}
            handleReset={handleReset}
            activeTargetWeek={activeWeek}
            activeUpdatedState={activeUpdatedState}
            setActiveTargetWeek={setActiveWeek}
          />
        </div>
        <div className="target-page__inner__main">
          {activeWeek ? (
            <WeekContent
              targetWeek={activeWeek}
              innerSelected={innerSelected}
              setInnerSelected={setInnerSelected}
            />
          ) : null}
        </div>
      </div>

      <TargetModal
        doctorSummary={doctorSummary}
        activeTargetWeek={activeWeek}
      />
      <PlanificationConfigModal />
    </div>
  );
};
