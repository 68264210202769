import { Tooltip } from "@material-ui/core";
import React from "react";
import { useAppSelector } from "../../../redux/hooks";
import { RootState } from "../../../redux/store";

import { TargetWeek } from "../../../types/TargetWeek.model";
import { WeekButton } from "../../01-atoms/Buttons/Buttons";
import "./TargetWeekCard.scss";
type Props = {
  active?: boolean;
  activeTargetWeek: TargetWeek;
  onClick: any;
  handleCopy: (innerWeekSelection: number[]) => void;
  handleCut: (
    activeTargetWeek: TargetWeek,
    innerWeekSelection: number[]
  ) => void;
  handlePaste: (activeTargetWeek: TargetWeek) => void;
  handleReset: (activeTargetWeek: TargetWeek) => void;
};

export const TargetWeekCard = ({
  active,
  activeTargetWeek,
  onClick,
  handleCopy,
  handleCut,
  handlePaste,
  handleReset,
}: Props) => {
  const { potentials } = useAppSelector((state: RootState) => state.general);
  const { selectedDoctors } = useAppSelector(
    (state: RootState) => state.target
  );

  const onlyUnique = (value: any, index: number, self: any) => {
    return self.indexOf(value) === index;
  };
  const localitiesToVisit = activeTargetWeek?.doctors
    .map((e) => e.principalAddress?.address?.localities[0]?.localityName)
    .filter(onlyUnique);
  const MAX_LENGTH = 3;
  return (
    <div className="targetWeek-card__wrapper" onClick={onClick}>
      <div className={`targetWeek-card ${active ? "isActive" : ""}`}>
        <div className="targetWeek-card__top">
          <div className="targetWeek-number">
            S{activeTargetWeek.weekNumber}
          </div>
          <Tooltip title={localitiesToVisit.join("/")} placement="top">
            <div className="targetWeek-name">
              {localitiesToVisit.length > MAX_LENGTH
                ? localitiesToVisit.filter((e, i) => i < MAX_LENGTH).join("/") +
                  ` +${localitiesToVisit.length - MAX_LENGTH}`
                : localitiesToVisit.join("/")}
            </div>
          </Tooltip>

          <div className="targetWeek-actions-button">
            <WeekButton
              handleCopy={() => handleCopy(selectedDoctors)}
              handleCut={() => handleCut(activeTargetWeek, selectedDoctors)}
              handlePaste={() => handlePaste(activeTargetWeek)}
              handleReset={() => handleReset}
            />
          </div>
        </div>
        <div className="targetWeek-card__bottom">
          <div className="targetWeek-frequencies-count">
            {potentials.map((e) => (
              <dl key={e.potentialId}>
                <dt>
                  {
                    activeTargetWeek.doctors.filter(
                      (item) => item.potential.potentialId === e.potentialId
                    ).length
                  }
                </dt>
                <dd>Pot. {e.potentialName}</dd>
              </dl>
            ))}
          </div>
          <div className="targetWeek-total-count">
            <dl>
              <dt>{activeTargetWeek.doctors.length}</dt>
              <dd>Total</dd>
            </dl>
          </div>
        </div>
      </div>
    </div>
  );
};
