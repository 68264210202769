import { RootState } from "./../../../redux/store";
import { getDashboardData } from "./../../../redux/Dashboard/dashboardSlice";
import { useAppDispatch, useAppSelector } from "./../../../redux/hooks";
import { useState, useEffect } from "react";
import {
  DashboardActivitiesItem,
  DashboardGlobalInsights,
  DashboardVisitsInsights,
  VisitStatisiticsByWeek,
  DailyVisitStatistics,
} from "./../../../types/Dashboard.model";
import RequestSummary from "../../../types/RequestSummary.model";
import ObjectionSummary from "../../../types/ObjectionSummary.model";

const useDashboard: () => [
  DashboardGlobalInsights,
  DashboardVisitsInsights,
  VisitStatisiticsByWeek[],
  RequestSummary[],
  ObjectionSummary[],
  DashboardActivitiesItem[],
  DailyVisitStatistics[],
  boolean,
  string,
] = () => {
  const dispatch = useAppDispatch();
  const { dashboardData, requestState } = useAppSelector(
    (state: RootState) => state.dashboard
  );
  const [ready, setReady] = useState<boolean>(false);

  useEffect(() => {
    dispatch(getDashboardData());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    requestState === "idle" && setReady(true);
  }, [dashboardData, requestState]);

  const {
    globalInsights,
    visitsInsights,
    visitsStatisticsByWeek,
    untreatedRequests,
    untreatedObjections,
    activitiesInsights,
    dailyVisitsStatistics,
  } = dashboardData;
  return [
    globalInsights,
    visitsInsights,
    visitsStatisticsByWeek,
    untreatedRequests,
    untreatedObjections,
    activitiesInsights,
    dailyVisitsStatistics,
    ready,
    requestState,
  ];
};
export default useDashboard;
