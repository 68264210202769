import React from "react";
import "./InfoWithTag.scss";
import { Icon } from "@mdi/react";


type Props = {
  label: string | undefined;
  info: string | null;
  icon: string;
  labelClassName?:  string;
  infoClassName?:  string;
  rowGap?: string;
  IconSize?: number;
};

export default function InfoWithIconTag({ label, info, icon, labelClassName, infoClassName, rowGap, IconSize }: Props) {
  return (
    <div className="info-icon-tag__wrapper">
      <div className="icon-tag__wrapper"><Icon path={icon} size={IconSize ? IconSize : 1} /></div>
      <div className="info-con-label__wrapper" style={{rowGap: rowGap ? rowGap : "5px"}}>
        <p className={`info-with-icon-tag-text ${labelClassName}`}>{label ? label : ""}</p>
        {info && <span className={`info-with-icon-tag-text ${infoClassName}`}> {info}</span>}
      </div>
    </div>
  );
}
