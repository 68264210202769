import { useAppDispatch } from "./../../../../redux/hooks";
import { useEffect, useState } from "react";
import { getFiltredOrders } from "../../../../redux/Order/orderSlice";
import { getCurrentMonthToCurrentDay, getdateWithFormatedtTimeZoneToZero } from "../../../../utils/dateHelpers";
// import { useParams } from "react-router-dom";

export type OrderFilterData = {
  deliveryStatus: string[];
  paymentStatus: string[];
  date: [Date, Date] | undefined;
};
export type GlobalOrderFilterState = {
  statusDelivry: string[];
  statusPaymenet: string[];
  wordSearch: null | string;
  startDate: string | null;
  endDate: string | null;
  pagination: {
    pageNumber: number;
    pageSize: number;
  };
};
export const useFilterOrder = () => {
  const dispatch = useAppDispatch();
  const [captureDtaeFilter, setCaptureDateFilter] = useState<any[]>()
  const [globalFilterState, setGlobalFilterState] =
    useState<GlobalOrderFilterState>({
      statusPaymenet: [],
      statusDelivry: [],
      wordSearch: null,
      startDate:  getCurrentMonthToCurrentDay()?.startDate || null,
      endDate:  getCurrentMonthToCurrentDay()?.endDate || null,
      pagination: {
        pageNumber: 1,
        pageSize: 10,
      },
    });

  const captureSearchTextValue = (searchTerm: string) => {
    setGlobalFilterState({
      wordSearch: searchTerm,
      statusPaymenet: globalFilterState.statusPaymenet,
      statusDelivry: globalFilterState.statusDelivry,
      startDate: globalFilterState.startDate,
      endDate: globalFilterState.endDate,
      pagination: {
        ...globalFilterState.pagination,
      },
    });
  };

  const captureFilter = (data: OrderFilterData) => {
    const { date, paymentStatus, deliveryStatus } = data;
    let startDateValue  = date ? getdateWithFormatedtTimeZoneToZero(date[0]) :  getCurrentMonthToCurrentDay()?.startDate || null;
    let endDateValue  = date ?getdateWithFormatedtTimeZoneToZero(date[1]) :  getCurrentMonthToCurrentDay()?.endDate || null;
    setGlobalFilterState({
      wordSearch: globalFilterState.wordSearch,
      startDate: startDateValue,
      endDate: endDateValue,
      statusPaymenet: paymentStatus,
      statusDelivry: deliveryStatus,
      pagination: {
        ...globalFilterState.pagination,
      }, 
    });
    setCaptureDateFilter([startDateValue, endDateValue]) 
  };

  const capturePageTarget = (page: number) => {
    setGlobalFilterState({
      wordSearch: globalFilterState.wordSearch,
      statusPaymenet: globalFilterState.statusPaymenet,
      statusDelivry: globalFilterState.statusDelivry,
      startDate: globalFilterState.startDate,
      endDate: globalFilterState.endDate,
      pagination: {
        ...globalFilterState.pagination,
        pageNumber: page + 1,
      },
    });
  };

  const captureLimitTarget = (limit: number) => {
    setGlobalFilterState({
      wordSearch: globalFilterState.wordSearch,
      statusPaymenet: globalFilterState.statusPaymenet,
      statusDelivry: globalFilterState.statusDelivry,
      startDate: globalFilterState.startDate,
      endDate: globalFilterState.endDate,
      pagination: {
        ...globalFilterState.pagination,
        pageNumber: 1,
        pageSize: limit,
      },
    });
  };
  useEffect(() => {
    dispatch(getFiltredOrders(globalFilterState));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [globalFilterState]);

  return {
    globalFilterState,
    captureFilter,
    capturePageTarget,
    captureLimitTarget,
    captureSearchTextValue,
    captureDtaeFilter
  };
};
