import React, { useEffect, useState } from "react";
import { Input } from "rsuite";
import { useAppDispatch } from "../../../../redux/hooks";
import { updateRequestResponse } from "../../../../redux/Request/requestSlice";
import { CustomButton } from "../../../01-atoms/Buttons/Buttons";
import CloseIcon from '@material-ui/icons/Close';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton,
} from "@material-ui/core";

type Props = {
  handleClickOpen: any;
  open: boolean;
  handleClose: any; 
  rowPramas: {
    id: number;
    requestValue: string;
    response: string;
    isClosed: boolean;
  };
};
export const RequestExpandRow = ({rowPramas, handleClickOpen, open, handleClose}: Props) => {
  const [requestResponse, setRequestResponse] = useState(rowPramas.response);
  const dispatch = useAppDispatch();

  useEffect(() => {
    setRequestResponse(rowPramas.response);
  }, [rowPramas.response]);

  return (
    <Dialog open={open} onClose={handleClose} fullWidth>
    <DialogTitle>
    <IconButton
        // aria-label="close"
        onClick={handleClose}
        style={{
          position: 'absolute',
          right: 8,
          top: 8,
        }}
      >
        <CloseIcon />
      </IconButton>
    </DialogTitle>
    <DialogContent>
    <div
      className="request-expand-row"
      style={{ height: "fit-content !important" }}
    >
      <div className="request-value"> 
        <h4 className="request-value__title">Demande</h4>
        <div className="request-value__body">{rowPramas.requestValue}</div>
      </div>
      <div className="request-response">
        <h4 className="request-response__title">Réponse</h4>
        {rowPramas.isClosed ? (
          <Input
            as="textarea"
            className="request-response__body"
            rows={3}
            placeholder="Textarea"
            readOnly
            value={requestResponse}
            onChange={(e) => setRequestResponse(e)}
          />
        ) : (
          <>
            <Input
              as="textarea"
              className="request-response__body"
              rows={3}
              placeholder="Textarea"
              value={requestResponse}
              onChange={(e) => setRequestResponse(e)}
            />
            <CustomButton
              text="Traiter"
              className="submit-button"
              onClick={() =>
                dispatch(
                  updateRequestResponse({
                    requestId: rowPramas.id, 
                    response: requestResponse,
                  })
                )
              }
            />
          </>
        )}
      </div>
    </div>
    </DialogContent>
    </Dialog>
  );
};


