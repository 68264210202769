import React, { useState, useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import { closePlanificationModal } from "../../../redux/Planification/planificationSlice";
import { RootState } from "../../../redux/store";
import "./PlanificationModal.scss";

import PlanificationGroupBy from "./PlanificationGroupBy";
import { DoctorSummary } from "../../../types/DoctorSummary.model";
import { WeekContent } from "../../../types/WeekContent.model";
import {
  AppBar,
  createStyles,
  Dialog,
  makeStyles,
  Theme,
  Toolbar,
  Typography,
  Button,
  Grid,
} from "@material-ui/core";
import { getFilteredDoctorSummaries } from "../../../redux/Doctor/doctorSlice";
import { NewTargetFilter } from "../filter/FilterDrawer/NewTargetFilter";
import errorToast from "../../../utils/errorToast";

type Props = {
  existingDoctors: DoctorSummary[];
  addToWeek: (weekContents: WeekContent[]) => void;
};

const PlanificationModal = ({ existingDoctors, addToWeek }: Props) => {
  const dispatch = useAppDispatch();
  const { planificationModalIsOpen } = useAppSelector(
    (state: RootState) => state.planification
  );

  const { filteredDoctorsSummaries } = useAppSelector(
    (state: RootState) => state.doctor
  );
  const { activeUpdatedPlanificationState } = useAppSelector(
    (state: RootState) => state.planification
  );

  const [doctorsToAdd, setDoctorsToAdd] = useState<DoctorSummary[]>([]);

  const useStyles = makeStyles((theme: Theme) =>
    createStyles({
      appBar: {
        position: "relative",
        backgroundColor: "#181b24",
        color: "#efefef",
        height: theme.spacing(6),
      },
      toolBar: {
        height: theme.spacing(6),
        minHeight: theme.spacing(6),
      },
      title: {
        marginLeft: theme.spacing(2),
        flex: 1,
      },
    })
  );
  const classes = useStyles();

  const getFilteredDoctors = () => {
    return filteredDoctorsSummaries.filter(
      (doctor) =>
        existingDoctors.findIndex(
          (existing) => existing.doctorId === doctor.doctorId
        ) === -1
    );
  };

  const confirmSelection = () => {
    if (doctorsToAdd.length > 0) {
      const weekContents: WeekContent[] = doctorsToAdd.map((doctor) => {
        let weekContent: WeekContent = { doctor };
        return weekContent;
      });
      addToWeek(weekContents);
      dispatch(closePlanificationModal());
    } else {
      errorToast("Aucun médecin n'a été sélectionner")
    }
    
  };

  useEffect(() => {
    planificationModalIsOpen &&
      dispatch(
        getFilteredDoctorSummaries({
          cycleId: activeUpdatedPlanificationState?.target?.cycle?.cycleId,
        })
      );

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeUpdatedPlanificationState]);
 
  return (
    <div className="planification-modal">
      <Dialog
        fullScreen
        open={planificationModalIsOpen}
        onClose={() => dispatch(closePlanificationModal())}
        disableEnforceFocus
      >
        <AppBar className={classes.appBar}>
          <Toolbar className={classes.toolBar}>
            <Typography variant="body1" className={classes.title}>
              Recherche de médecins
            </Typography>
            <Button autoFocus color="inherit" onClick={confirmSelection}>
              Ajouter
            </Button>
            <Button
              color="inherit"
              onClick={() => dispatch(closePlanificationModal())}
              aria-label="close"
            >
              Quitter
            </Button>
          </Toolbar>
        </AppBar>
        <Grid container spacing={0} style={{ padding: ".5rem" }}>
          <Grid item xs={3}>
            <NewTargetFilter />
            {/* {we also have  <NewPlanificationFilter /> componenet but I'm not sure why it exists } */}
          </Grid>
          <Grid item xs={9}>
            <PlanificationGroupBy
              data={getFilteredDoctors()}
              selectDoctorsCallBack={setDoctorsToAdd}
            />
          </Grid>
        </Grid>
      </Dialog>
    </div>
  );
};

export default PlanificationModal;
