import React from "react";
import { ForgotPasswordForm } from "../../03-organisms/forms/authentication/ForgotPasswordForm";

import { AuthTemplate } from "../../04-templates/authentication-template/AuthTemplate";

interface Props {}

export const ForgotPasswordPage = (props: Props) => {
  return <AuthTemplate form={<ForgotPasswordForm />} />;
};
