import React, { useEffect } from "react";
import { Header } from "../../05-layouts/Header/Header";
import { useParams } from "react-router-dom";
import { useAppDispatch } from "../../../redux/hooks";
import { fetchRpEventById } from "../../../redux/Rp/rpSlice";

import "./RpPage.scss";
type Props = {};

const RpSinglePage = (props: Props) => {
  const dispatch = useAppDispatch();
  const params = useParams();
  const { id } = params;
  useEffect(() => {
    id && dispatch(fetchRpEventById(id));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <>
      <Header
        leftItem={<div className="cycle-management__header__title">Rp</div>}
        subRoutes={[]}
      />
      <div className="rp-page__inner">single</div>
    </>
  );
};

export default RpSinglePage;
