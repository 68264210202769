import React, { createRef } from "react";
import { Controller } from "react-hook-form";
import { Form, Col, TagPicker } from "rsuite";
import { CustomErrorMessage } from "../ErrorMessage/CustomErrorMessage";
type Props = {
  control: any;
  errors: any;
  label: string;
  inputName: string;
  xs?: number;
  sm?: number;
  md?: number;
  lg?: number;
  data: any[];
  creatable?: boolean;
  readOnly?: boolean;
  rules?: any;
  cleanable?: boolean;
  disabled?: boolean;
  onSubmit?: ()=> void
  height?: string;
  overflowY?: "visible" | "hidden" | "clip" | "scroll" | "auto";
  placeholder? : (string & (boolean | React.ReactChild | React.ReactFragment | React.ReactPortal | null));

};
 
export const TagsField = ({
  label,
  height,
  control,
  inputName,
  errors,
  xs,
  sm,
  md,
  disabled,
  lg,
  data,
  creatable,
  rules,
  readOnly,
  cleanable,
  onSubmit,
  overflowY,
  placeholder,
}: Props) => {
  const tagsInputRef: any = createRef();
  return (
    <Col xs={xs || 24} sm={sm} md={md} lg={lg} style={{ marginBottom: "1rem" }}>
      <Form.Group>
        <Form.ControlLabel>{label}</Form.ControlLabel>
        <Controller
          control={control}
          name={inputName}
          rules={rules}
          render={({ field: { onChange, name, value } }) => {
            return (
              <Form.Control
                ref={tagsInputRef}
                accepter={TagPicker}
                name={name}
                value={value}
                menuMaxHeight={200} 
                disabled={disabled}
                className="input-full-width"
                style={{ width: "100%", position: "relative" , height: height || undefined, overflowY: overflowY || undefined }}
                container={() => tagsInputRef.current}
                preventOverflow
                readOnly={readOnly}
                menuClassName="picker-fixed-top"
                data={data}
                cleanable={cleanable}
                placeholder= {placeholder || undefined}
                creatable={creatable}
                placement="autoHorizontal"
                onChange={(e: any) => {
                  onChange(e);
                  onSubmit && onSubmit();
                }}
              />
            ); 
          }}
        />
        <CustomErrorMessage errors={errors} name={inputName} />
      </Form.Group>
    </Col>
  );
};
