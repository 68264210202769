import { createSlice } from "@reduxjs/toolkit";
/* import { createSlice } from "@reduxjs/toolkit"; */
import { API_DEV } from "../../utils/apiRoute";
import { axiosPrivate } from "../../utils/interceptors";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { Objection } from "../../types/Objection.model";
import { GlobalObjectionFilterState } from "../../components/03-organisms/filter/ObjectionFilter/useFilterObjection";

type FilteredObjections = {
  data: Objection[];
  pagination: {
    pageNumber: number;
    pageSize: number;
    totalCount: number;
    totalPages: number;
  };
};

export interface ObjectionState {
  requestState: "idle" | "loading";
  objectionById: Objection | null;
  filteredObjections: FilteredObjections;
  errors: any;
}

export const addObjection = createAsyncThunk(
  "[Objection]_add_",
  async (payload: any, { rejectWithValue }) => {
    try {
      const response = await axiosPrivate.post(`${API_DEV}/Objection`, payload);
      return response.data;
    } catch (error) {
      console.log(JSON.parse(JSON.stringify(error)));
      return rejectWithValue(JSON.parse(JSON.stringify(error)));
    }
  }
);
export const updateObjectionResponse = createAsyncThunk(
  "[Objection]_updateResponse_",
  async (payload: any, { rejectWithValue }) => { 
    try {
      const response = await axiosPrivate.post(`${API_DEV}/Objection/RespondToObjection`, payload);
      return response.data;
    } catch (error) {
      console.log(JSON.parse(JSON.stringify(error)));
      return rejectWithValue(JSON.parse(JSON.stringify(error)));
    }
  }
);

export const deleteObjection = createAsyncThunk(
  "[Objection]_delete_",
  async (id: number, { rejectWithValue }) => {
    try {
      const response = await axiosPrivate.delete(`${API_DEV}/Objection/${id}`);
      return response.data;
    } catch (error) {
      console.log(JSON.parse(JSON.stringify(error)));
      return rejectWithValue(JSON.parse(JSON.stringify(error)));
    }
  }
);

export const fetchObjectionById = createAsyncThunk(
  "[Objection]_fetchById_",
  async (id: number, { rejectWithValue }) => {
    try {
      const response = await axiosPrivate.get(`${API_DEV}/Objection/${id}`);
      return response.data;
    } catch (error) {
      console.log(JSON.parse(JSON.stringify(error)));
      return rejectWithValue(JSON.parse(JSON.stringify(error)));
    }
  }
);
export const fetchFilteredObjection = createAsyncThunk(
  "[Objection]_fetchByFiltered_",
  async (payload: GlobalObjectionFilterState, { rejectWithValue }) => {
    try {
      const response = await axiosPrivate.post(
        `${API_DEV}/Objection/Filters`,
        payload
      );
      return response.data;
    } catch (error) {
      console.log(JSON.parse(JSON.stringify(error)));
      return rejectWithValue(JSON.parse(JSON.stringify(error)));
    }
  }
);

const initialState: ObjectionState = {
  requestState: "idle",
  objectionById: null,
  filteredObjections: {
    data: [],
    pagination: {
      pageNumber: 1,
      pageSize: 10,
      totalCount: 0,
      totalPages: 0,
    },
  },
  errors: null,
};

export const requestSlice = createSlice({
  name: "[Objection]",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(addObjection.pending, (state, { payload }) => {
      state.requestState = "loading";

      state.errors = null;
    });
    builder.addCase(addObjection.fulfilled, (state, { payload }) => {
      state.requestState = "idle";
      state.errors = null;
    });
    builder.addCase(addObjection.rejected, (state, { payload }) => {
      state.requestState = "idle";
      state.errors = payload;
    });
    builder.addCase(updateObjectionResponse.pending, (state, { payload }) => {
      state.requestState = "loading";
      state.errors = null;
    });
    builder.addCase(updateObjectionResponse.fulfilled, (state, { payload }) => {
      state.requestState = "idle";
      state.errors = null;
    });
    builder.addCase(updateObjectionResponse.rejected, (state, { payload }) => {
      state.requestState = "idle";
      state.errors = payload;
    });
    builder.addCase(deleteObjection.pending, (state, { payload }) => {
      state.requestState = "loading";
      state.errors = null;
    });
    builder.addCase(deleteObjection.fulfilled, (state, { payload }) => {
      state.requestState = "idle";
      state.errors = null;
    });
    builder.addCase(deleteObjection.rejected, (state, { payload }) => {
      state.requestState = "idle";
      state.errors = payload;
    });
    builder.addCase(fetchObjectionById.pending, (state, { payload }) => {
      state.requestState = "loading";
      state.objectionById = null;
      state.errors = null;
    });
    builder.addCase(
      fetchObjectionById.fulfilled,
      (state, { payload }: { payload: any }) => {
        state.requestState = "idle";
        state.objectionById = payload;
        state.errors = null;
      }
    );
    builder.addCase(fetchObjectionById.rejected, (state, { payload }) => {
      state.requestState = "idle";
      state.objectionById = null;
      state.errors = payload;
    });
    builder.addCase(fetchFilteredObjection.pending, (state, { payload }) => {
      state.requestState = "loading";
      state.filteredObjections = {
        data: [],
        pagination: {
          pageNumber: 1,
          pageSize: 10,
          totalCount: 0,
          totalPages: 0,
        },
      };
      state.errors = null;
    });
    builder.addCase(
      fetchFilteredObjection.fulfilled,
      (state, { payload }: { payload: any }) => {
        state.requestState = "idle";
        state.filteredObjections = payload;
        state.errors = null;
      }
    );
    builder.addCase(fetchFilteredObjection.rejected, (state, { payload }) => {
      state.requestState = "idle";
      state.filteredObjections = {
        data: [],
        pagination: {
          pageNumber: 1,
          pageSize: 10,
          totalCount: 0,
          totalPages: 0,
        },
      };
      state.errors = payload;
    });
  },
});

export default requestSlice.reducer;
