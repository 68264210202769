import React from "react";
import User from "../../../types/User.model";
import { CustomAvatar } from "../../01-atoms/CustomAvatar/CustomAvatar";
import "./ActivityTrackingUserCard.scss";
type Props = {
  user: User;
  onClick: () => void;
  active: boolean;
};

const ActivityTrackingUserCard = ({ user, onClick, active }: Props) => {
  return (
    <div
      className={`activity-tracking-user-card ${active ? "isActive" : ""}`}
      onClick={onClick}
    >
      <div className="avatar-area">
        <CustomAvatar
          image={{
            src: user.photoUrl || "",
            alt: `${user.firstName} ${user.lastName}`,
          }}
          style={{ width: "25px", height: "25px" }}
        />
        <div className="avatar-fullname">
          {user.firstName + " " + user.lastName}
        </div>
      </div>
    </div>
  );
};

export default ActivityTrackingUserCard;
