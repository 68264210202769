import { mdiFileClockOutline } from "@mdi/js";
import React, { useEffect, useState } from "react";
import { Delivery } from "../../../../types/Delivery.modal";
import { getLocalDate } from "../../../../utils/dateHelpers";
import InfoWithIconTag from "../../../01-atoms/InfoWithIconTag/InfoWithIconTag";
import CustomizedTables from "../../../02-molecules/tables/CustomizedTable";

type Props = {
  requestState: "loading" | "idle";
  deliveryDetailsList: Delivery[] | null;
};

const ordredCommendColumnsTitle: {
  title: string;
  align: "left" | "right" | "inherit" | "center" | "justify" | undefined;
}[] = [
  { title: "Date", align: "left" },
  { title: "Produit", align: "left" },
  { title: "Total", align: "left" },
  { title: "Livraison", align: "left" },
  { title: "Reste", align: "left" },
];

export default function DeliveryDetails({
  requestState,
  deliveryDetailsList,
}: Props) {
  const [deliveryRows, setDeliveryRows] = useState<any[]>([]);

  const deliveryFormatedArray = () => {
    if (
      requestState === "idle" &&
      deliveryDetailsList &&
      deliveryDetailsList.length
    ) {
      return deliveryDetailsList.map((el) => {
        return {
          deliveryDate: el.deliveryDate ? getLocalDate(el.deliveryDate) : "-",
          productName:
            el.deliveryDetails && el.deliveryDetails.length
              ? el.deliveryDetails.map((detail) => {
                  return { produit: detail?.product?.productName || "-" };
                })
              : [{ produit: "-" }],
          totalQuantity:
            el.deliveryDetails && el.deliveryDetails.length
              ? el.deliveryDetails.map((detail) => {
                  return { produit: detail?.totalQuantity || "-" };
                })
              : [{ produit: "-" }],
          livredQuantity:
            el.deliveryDetails && el.deliveryDetails.length
              ? el.deliveryDetails.map((detail) => {
                  return { produit: detail?.quantityDelivered || "-" };
                })
              : [{ produit: "-" }],
          amountToBeDelivered:
            el.deliveryDetails && el.deliveryDetails.length
              ? el.deliveryDetails.map((detail) => {
                  return {
                    produit:
                      detail.totalQuantity && detail.quantityDelivered
                        ? detail.totalQuantity - detail.quantityDelivered
                        : "-",
                  };
                })
              : [{ produit: "-" }],
        };
      });
    } else return [];
  };
  useEffect(() => {
    let formatedRows: any[] = deliveryFormatedArray();
    setDeliveryRows(formatedRows);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [requestState, deliveryDetailsList]);
  return (
    <div className="order-detail-body-section2">
      <section>
        <InfoWithIconTag
          info={null}
          label={"Historique Livraison"}
          icon={mdiFileClockOutline}
          IconSize={0.9}
          labelClassName={"style3"}
          infoClassName={"style2"}
        />
      </section>
      <section>
        <CustomizedTables
          rows={deliveryRows}
          columns={ordredCommendColumnsTitle}
        />
      </section>
    </div>
  );
}
