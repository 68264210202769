
//@ts-nocheck
/* eslint-disable */
import React, { useEffect } from 'react'
import { Button, Grid } from "@material-ui/core"
import { TextField, } from '@material-ui/core';
import { useFormik } from 'formik';
import { getConnectedUserBu } from "../../../../utils/getConnectedData";
import { useAppDispatch, useAppSelector } from '../../../../redux/hooks';
import * as yup from 'yup';
import { CreateSpecThunk } from '../../../../redux/Speciality/SpecialitySlice';
import { getAllSpecialitiesThunk } from '../../../../redux/Speciality/SpecialitySlice';
import { updateFacilityTypeByIdThunk } from '../../../../redux/FacilityType/FacilityTypeSlice';
import { updateSpecByIdThunk } from '../../../../redux/Speciality/SpecialitySlice';
export default function AddForm({ SetFById, closeForm, FbyId }: any) {
    const dispatch = useAppDispatch()

    useEffect(() => {
        // console.log(FbyId)
    }, [])
    const validationSchema = yup.object({
        specialityName: yup
            .string()
            .required('Nom est obligatoire'),
        abreviation: yup
            .string()
            .required('abreviation est obligatoire'),


    });

    const formik = useFormik({
        initialValues: FbyId ? { "specialityName": FbyId.specialityName, "abreviation": FbyId.abreviation } : {
            "specialityName": "",
            "abreviation": ""
        },
        validationSchema: validationSchema,
        onSubmit: async (values) => {
            if (!FbyId.specialityName) {
                await dispatch(CreateSpecThunk(values))
                await dispatch(getAllSpecialitiesThunk())
            }
            else {
                await dispatch(updateSpecByIdThunk({
                    "specialityId": FbyId.specialityId,
                    "specialityName": values.specialityName,
                    "abreviation": values.abreviation
                }))
                await dispatch(getAllSpecialitiesThunk())
            }
            SetFById({})
            closeForm(false)
        },
    });


    return (
        <>
            <div style={{ zIndex: 2, height: "100%", width: "100%", backgroundColor: "black", opacity: 0.3, position: "fixed", display: "flex", justifyContent: "center", alignItems: "center" }} className='cont'></div>
            <Grid style={{ marginTop: 90, display: "flex", position: "absolute", zIndex: 3, flex: 1, justifyContent: "center", alignItems: "center", width: "100%", overflow: "hidden" }} container>
                <div className='GridStart' style={{ marginTop: "1%", zIndex: 9, color: "white", backgroundColor: "white", width: "80vw", borderRadius: 9, }}>
                    <Grid style={{ minHeight: 70, maxHeight: 70, borderBottom: "1px solid black", display: "flex", justifyContent: "center", alignItems: "center" }}>
                        <div style={{ color: "black", fontSize: "20px" }}>{!FbyId.specialityName ?"Ajouter une specialité":"modifier une specialité"}</div>
                    </Grid>
                    <Grid style={{ height: "20vh", overflow: "hidden", scrollbarWidth: 0 }}>
                        <Grid container wrap="wrap" style={{ flex: 1, justifyContent: "space-around", marginTop: 40, padding: 20 }}>
                            <Grid xs={5} item>
                                <TextField fullWidth id="specialityName"
                                    name="specialityName"
                                    value={formik.values.specialityName}
                                    onChange={formik.handleChange} label="Nom du specialité"
                                    error={formik.touched.specialityName && Boolean(formik.errors.specialityName)}
                                    variant="outlined" />
                            </Grid>
                            <Grid xs={5} item>
                                <TextField fullWidth id="abreviation"
                                    name="abreviation"
                                    value={formik.values.abreviation}
                                    onChange={formik.handleChange} label="abreviation"
                                    error={formik.touched.abreviation && Boolean(formik.errors.abreviation)}
                                    variant="outlined" />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid container style={{ flex: 1, justifyContent: "center", alignItems: "center", marginTop: 40, paddingBottom: 30 }}>
                        <Button style={{ color: "black", backgroundColor: "#e5e5ea", marginRight: 30, width: 120 }} variant="contained" color="primary" onClick={() => {
                            SetFById({})
                            closeForm(false)
                        }}>annuler</Button>
                        <Button style={{ color: "white", backgroundColor: "black", width: 120 }} variant="contained" color="primary" onClick={() => formik.handleSubmit()}>{!FbyId.specialityName ?"Ajouter":"modifier"}</Button>
                    </Grid>
                </div>
            </Grid>

        </>


    )
}


