import * as React from "react";
import { GeographicsData } from "../../../../types/Dashboard.model";
import {
  TableContainer,
  Table,
  Paper,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
} from "@material-ui/core";
import { Col } from "rsuite";
import { RootState } from "../../../../redux/store";
import { useAppSelector } from "../../../../redux/hooks";

interface Props {
  generalPotentialByLocality: GeographicsData[];
  tableTitle: string;
}

export default function AnalyticsTable({ generalPotentialByLocality, tableTitle }: Props) {
  const { potentials } = useAppSelector((state: RootState) => state.general);    
  return (
    <>
      <Col style={{ marginBottom: "1rem" }} xs={24}>
        <div className="analytics-card__wrapper">
          <div
            className="analytics-card__inner"
            style={{ flexDirection: "column" }}
          >
            <div style={{ paddingBottom: "1rem" }}>
              <dl>
                <dt
                  style={{
                    fontFamily: "Helvetica, Arial, sans-serif",
                    fontSize: "14px",
                    fontWeight: "900",
                    color: "rgb(55,61,63)",
                  }}
                >
                  {tableTitle || "-"}
                </dt>
              </dl>
            </div>
            {generalPotentialByLocality &&
            generalPotentialByLocality.length > 0 ? (
              <TableContainer
                component={Paper}
                className="Georgraphic_Dashbord_Section"
                style={{ height: "100%"}}
              >
                <Table stickyHeader aria-label="sticky table" size="small">
                  <TableHead>
                    <TableRow>
                      <TableCell align="left" width={100}>
                        Gouvernorats
                      </TableCell>
                      {potentials &&
                        potentials.length > 0 &&
                        potentials.map((item, index) => (
                          <TableCell
                            key={item.potentialId}
                            align="center"
                            width={50}
                          >
                            {`Potentiel ${item.potentialName}`}
                          </TableCell>
                        ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {generalPotentialByLocality &&
                      generalPotentialByLocality.length > 0 &&
                      generalPotentialByLocality.map((item, index) => (
                        <TableRow key={index}>
                          <TableCell key={item.localityId} align="left">
                            {item.localityName}
                          </TableCell>
                          {item.potentials &&
                            item.potentials.length > 0 &&
                            item.potentials.map((pot) => (
                              <TableCell key={pot.potentialId} align="center">
                                {pot.count}
                              </TableCell>
                            ))}
                        </TableRow>
                      ))}
                  </TableBody>
                </Table>
              </TableContainer>
            ) : (
              <div>Loading</div>
            )}
          </div>
        </div>
      </Col>
    </>
  );
}
