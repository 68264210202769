import React, { createRef } from "react";
import {
  Drawer,
  Form,
  PanelGroup,
  Panel,
  Button,
  CheckTreePicker,
} from "rsuite";
import "./FilterDrawer.scss";
import ExpandOutlineIcon from "@rsuite/icons/ExpandOutline";
import CollaspedOutlineIcon from "@rsuite/icons/CollaspedOutline";
import { Locality } from "../../../../types/Locality.model";
import { API_DEV } from "../../../../utils/apiRoute";
import { axiosPrivate } from "../../../../utils/interceptors";
import { useHandleResize } from "../../../../utils/useHandleResize";
import {
  CheckTreeData,
  CheckTreeDataWithChildren,
  FilterValues,
} from "../../../../helpers/useFilter";
import { Controller, useForm } from "react-hook-form";
import { ItemDataType } from "rsuite/esm/@types/common";

interface Props {
  isOpen: boolean;
  onClose: () => void;
  filtersDisplayData: {
    localitiesData: CheckTreeDataWithChildren;
    servicesData: CheckTreeData;
    facilityTypesData: CheckTreeData;
    potentialsData: CheckTreeData;
    tagsData: CheckTreeData;
    businessUnitsData: CheckTreeData;
    specialitiesData: CheckTreeData;
    facilitiesData: CheckTreeData;
    sellerTypeData: CheckTreeData;
    shiftData: CheckTreeData;
  };
  captureFilterInputs: (data: FilterValues) => void;
  captureSelectedFilters: (data: any) => void;
  resetSelectedFilters: () => void;
  selectedFilters: any;
  dataBaseContext: "doctor" | "pharmacy" | "seller" | "facility";
}

export interface FilterInputs {
  localitiesValue: number[];
  facilityTypesValue: number[];
  servicesValue: number[];
  potentialsValue: number[];
  tagsValue: number[];
  businessUnitsValue: number[];
  specialitiesValue: number[];
  facilitiesValue: number[];
  sellerTypesValue: number[];
  shiftValue: number[];
}
const formatFilterData = (data: FilterInputs) => {
  return {
    localities:
      data.localitiesValue.length > 0
        ? data.localitiesValue.map((e) => ({
            localityId: e,
          }))
        : [],
    facilityTypes:
      data.facilityTypesValue.length > 0
        ? data.facilityTypesValue.map((e) => ({
            facilityTypeId: e,
          }))
        : [],
    serviceTypes:
      data.servicesValue.length > 0
        ? data.servicesValue.map((e) => ({
            serviceTypeId: e,
          }))
        : [],
    potentials:
      data.potentialsValue.length > 0
        ? data.potentialsValue.map((e) => ({
            potentialId: e,
          }))
        : [],
    tags:
      data.tagsValue.length > 0
        ? data.tagsValue.map((e) => ({
            tagId: e,
          }))
        : [],
    businessUnits:
      data.businessUnitsValue.length > 0
        ? data.businessUnitsValue.map((e) => ({
            businessUnitId: e,
          }))
        : [],
    specialities:
      data.specialitiesValue.length > 0
        ? data.specialitiesValue.map((e) => ({
            specialityId: e,
          }))
        : [],
    facilities:
      data.facilitiesValue.length > 0
        ? data.facilitiesValue.map((e) => ({
            facilityId: e,
          }))
        : [],
    sellerTypes:
      data.sellerTypesValue.length > 0
        ? data.sellerTypesValue.map((e) => ({
            sellerTypeId: e,
          }))
        : [],
    shift:
      data.shiftValue && data.shiftValue.length > 1
        ? null
        : data.shiftValue && data.shiftValue[0] === 0
        ? 0
        : data.shiftValue && data.shiftValue[0] === 1
        ? 1
        : null,
  };
};
export const FilterDrawer = ({
  isOpen,
  onClose,
  filtersDisplayData,
  captureFilterInputs,
  captureSelectedFilters,
  resetSelectedFilters,
  selectedFilters,
  dataBaseContext,
}: Props) => {
  const deviceSize = useHandleResize();

  const initForm: FilterInputs = {
    localitiesValue: [],
    facilityTypesValue: [],
    servicesValue: [],
    potentialsValue: [],
    tagsValue: [],
    businessUnitsValue: [],
    specialitiesValue: [],
    facilitiesValue: [],
    sellerTypesValue: [],
    shiftValue: [],
  };
  const { control, handleSubmit, reset } = useForm<FilterInputs>({
    defaultValues: initForm,
    criteriaMode: "all",
    reValidateMode: "onChange",
  });

  const onsubmit = handleSubmit((data) => {
    const search = formatFilterData(data);
    captureFilterInputs(search);
  });

  const localityInputRef: any = createRef();
  const serviceInputRef: any = createRef();
  const potentialsInputRef: any = createRef();
  const tagsInputRef: any = createRef();
  const specialitiesInputRef: any = createRef();
  const facilityTypesInputRef: any = createRef();
  const sellerTypesInutRef: any = createRef();
  const sellerShiftRef: any = createRef();
  return (
    <Form>
      <Drawer
        full={deviceSize === "Mobile" ? true : false}
        placement={deviceSize === "Mobile" ? "bottom" : "right"}
        open={isOpen}
        onClose={onClose}
        className="filter__form__wrapper"
      >
        <Drawer.Header>
          <Drawer.Title>Filtres</Drawer.Title>
          <Drawer.Actions>
            <Button
              onClick={() => {
                reset(initForm);
                resetSelectedFilters();
              }}
            >
              Réinitialiser
            </Button>
            <Button
              className="database-submit-button"
              type="submit"
              appearance="primary"
              onClick={() => {
                onsubmit();
                onClose();
              }}
            >
              Appliquer
            </Button>
          </Drawer.Actions>
        </Drawer.Header>
        <Drawer.Body>
          <PanelGroup bordered style={{ marginTop: "1rem" }}>
            <Panel header="Localités" defaultExpanded>
              <Form.Group>
                <Controller
                  control={control}
                  name="localitiesValue"
                  render={({ field: { onChange, value, name } }) => (
                    <Form.Control
                      ref={localityInputRef}
                      className="default-expanded-checkTreePicker"
                      name={name}
                      value={value}
                      accepter={CheckTreePicker}
                      style={{ width: "100%" }}
                      data={filtersDisplayData.localitiesData}
                      container={() => localityInputRef.current}
                      renderTreeIcon={(
                        nodeData: ItemDataType<string | number>
                      ) =>
                        nodeData.expand ? (
                          <CollaspedOutlineIcon />
                        ) : (
                          <ExpandOutlineIcon />
                        )
                      }
                      open
                      onSelect={(activeNode: ItemDataType<string | number>) => {
                        captureSelectedFilters(activeNode);
                      }}
                      onChange={(e: any) => {
                        onChange(e);
                      }}
                      getChildren={(activeNode: any) =>
                        axiosPrivate
                          .get(
                            `${API_DEV}/Locality/SubLocalities/${activeNode.value}`
                          )
                          .then((res) =>
                            res.data.children.map((locality: Locality) =>
                              [2, 3].includes(locality.level)
                                ? {
                                    label: locality.localityName,
                                    value: locality.localityId,
                                  }
                                : {
                                    label: locality.localityName,
                                    value: locality.localityId,
                                    children: [],
                                  }
                            )
                          )
                          .catch((err) => console.log(err))
                      }
                    />
                  )}
                />
              </Form.Group>
            </Panel>
            {dataBaseContext === "facility" && (
              <Panel header="Services" defaultExpanded>
                <Form.Group>
                  <Controller
                    control={control}
                    name="servicesValue"
                    render={({ field: { onChange, value, name } }) => (
                      <Form.Control
                        ref={serviceInputRef}
                        name={name}
                        className="default-expanded-checkTreePicker"
                        value={value}
                        searchable={false}
                        accepter={CheckTreePicker}
                        style={{ width: "100%" }}
                        data={filtersDisplayData.servicesData}
                        container={() => serviceInputRef.current}
                        open
                        onSelect={(activeNode: any) => {
                          captureSelectedFilters(activeNode);
                        }}
                        onChange={(e: any) => {
                          onChange(e);
                        }}
                      />
                    )}
                  />
                </Form.Group>
              </Panel>
            )}
            {dataBaseContext === "facility" && (
              <Panel header="Type d'établissement" defaultExpanded>
                <Form.Group>
                  <Controller
                    control={control}
                    name="facilityTypesValue"
                    render={({ field: { onChange, value, name } }) => (
                      <Form.Control
                        ref={facilityTypesInputRef}
                        className="default-expanded-checkTreePicker"
                        container={() => facilityTypesInputRef.current}
                        open
                        searchable={false}
                        name={name}
                        value={value}
                        accepter={CheckTreePicker}
                        style={{ width: "100%" }}
                        data={filtersDisplayData.facilityTypesData}
                        onSelect={(activeNode: any) => {
                          captureSelectedFilters(activeNode);
                        }}
                        onChange={(e: any) => {
                          onChange(e);
                        }}
                      />
                    )}
                  />
                </Form.Group>
              </Panel>
            )}
            {["seller", "pharmacy", "doctor"].includes(dataBaseContext) && (
              <>
                <Panel
                  header="Potentiel"
                  defaultExpanded
                  className="drawer-filter-potential__area"
                >
                  <Form.Group>
                    <Controller
                      control={control}
                      name="potentialsValue"
                      render={({ field: { onChange, value, name } }) => (
                        <Form.Control
                          ref={potentialsInputRef}
                          className="default-expanded-checkTreePicker"
                          container={() => potentialsInputRef.current}
                          open
                          name={name}
                          value={value}
                          searchable={false}
                          accepter={CheckTreePicker}
                          style={{ width: "100%" }}
                          data={filtersDisplayData.potentialsData}
                          onSelect={(activeNode: any) => {
                            captureSelectedFilters(activeNode);
                          }}
                          onChange={(e: any) => {
                            onChange(e);
                          }}
                        />
                      )}
                    />
                  </Form.Group>
                </Panel>
              </>
            )}
            {["seller", "pharmacy", "doctor", "facility"].includes(
              dataBaseContext
            ) && (
              <>
                <Panel header="Tags" defaultExpanded>
                  <Form.Group>
                    <Controller
                      control={control}
                      name="tagsValue"
                      render={({ field: { onChange, value, name } }) => (
                        <Form.Control
                          ref={tagsInputRef}
                          className="default-expanded-checkTreePicker"
                          container={() => tagsInputRef.current}
                          open
                          name={name}
                          value={value}
                          accepter={CheckTreePicker}
                          style={{ width: "100%" }}
                          data={filtersDisplayData.tagsData}
                          onSelect={(activeNode: any) => {
                            captureSelectedFilters(activeNode);
                          }}
                          onChange={(e: any) => {
                            onChange(e);
                          }}
                        />
                      )}
                    />
                  </Form.Group>
                </Panel>
              </>
            )}
            {dataBaseContext === "seller" && (
              <>
                <Panel header="Type de Pharma" defaultExpanded>
                  <Form.Group>
                    <Controller
                      control={control}
                      name="sellerTypesValue"
                      render={({ field: { onChange, value, name } }) => (
                        <Form.Control
                          ref={facilityTypesInputRef}
                          className="default-expanded-checkTreePicker"
                          container={() => facilityTypesInputRef.current}
                          open
                          searchable={false}
                          name={name}
                          value={value}
                          accepter={CheckTreePicker}
                          style={{ width: "100%" }}
                          data={filtersDisplayData.sellerTypeData}
                          onSelect={(activeNode: any) => {
                            captureSelectedFilters(activeNode);
                          }}
                          onChange={(e: any) => {
                            onChange(e);
                          }}
                        />
                      )}
                    />
                  </Form.Group>
                </Panel>
                <Panel header="Shift" defaultExpanded>
                  <Form.Group>
                    <Controller
                      control={control}
                      name="shiftValue"
                      render={({ field: { onChange, value, name } }) => (
                        <Form.Control
                          ref={sellerShiftRef}
                          className="default-expanded-checkTreePicker"
                          container={() => sellerShiftRef.current}
                          open
                          searchable={false}
                          name={name}
                          value={value}
                          accepter={CheckTreePicker}
                          style={{ width: "100%" }}
                          data={filtersDisplayData.shiftData}
                          onSelect={(activeNode: any) => {
                            captureSelectedFilters(activeNode);
                          }}
                          onChange={(e: any) => {
                            onChange(e);
                          }}
                        />
                      )}
                    />
                  </Form.Group>
                </Panel>
              </>
            )}
            {dataBaseContext === "doctor" && (
              <>
                <Panel header="Spécialités" defaultExpanded>
                  <Form.Group>
                    <Controller
                      control={control}
                      name="specialitiesValue"
                      render={({ field: { onChange, value, name } }) => (
                        <Form.Control
                          ref={specialitiesInputRef}
                          className="default-expanded-checkTreePicker"
                          container={() => specialitiesInputRef.current}
                          open
                          searchable={false}
                          name={name}
                          value={value}
                          accepter={CheckTreePicker}
                          style={{ width: "100%" }}
                          data={filtersDisplayData.specialitiesData}
                          onSelect={(activeNode: any) => {
                            captureSelectedFilters(activeNode);
                          }}
                          onChange={(e: any) => {
                            onChange(e);
                          }}
                        />
                      )}
                    />
                  </Form.Group>
                </Panel>
                <Panel header="Type d'établissement" defaultExpanded>
                  <Form.Group>
                    <Controller
                      control={control}
                      name="facilityTypesValue"
                      render={({ field: { onChange, value, name } }) => (
                        <Form.Control
                          ref={sellerTypesInutRef}
                          className="default-expanded-checkTreePicker"
                          container={() => sellerTypesInutRef.current}
                          open
                          searchable={false}
                          name={name}
                          value={value}
                          accepter={CheckTreePicker}
                          style={{ width: "100%" }}
                          data={filtersDisplayData.facilityTypesData}
                          onSelect={(activeNode: any) => {
                            captureSelectedFilters(activeNode);
                          }}
                          onChange={(e: any) => {
                            onChange(e);
                          }}
                        />
                      )}
                    />
                  </Form.Group>
                </Panel>
              </>
            )}
          </PanelGroup>
        </Drawer.Body>
      </Drawer>
    </Form>
  );
};
