import React from "react";
import { Controller } from "react-hook-form";
import { Form, Input, Col } from "rsuite";
import { CustomErrorMessage } from "../ErrorMessage/CustomErrorMessage";
import "./NumberField.scss";
type Props = {
  control: any;
  errors: any;
  label: string;
  inputName: string;
  rules?: any;
  step?: number;
  xs?: number;
  sm?: number;
  md?: number;
  lg?: number;
  mdOffset?: number;
  className?: string;
  min?: number | null;
  
};

export const NumberField = ({
  label,
  control,
  inputName,
  rules,
  errors, 
  step,
  xs,
  sm,
  md,
  lg,
  className,
  mdOffset,
  min,
}: Props) => {
  return (
    <Col
      xs={xs || 24}
      sm={sm}
      md={md}
      mdOffset={mdOffset}
      lg={lg}
      className={className}
      style={{ marginBottom: "1rem" }}
    >
      <Form.Group>
        <Form.ControlLabel>{label}</Form.ControlLabel>
        <Controller
          control={control}
          name={inputName}
          rules={rules}
          render={({ field: { onChange, name, value } }) => (
            <Form.Control
              style={{ width: "100%" }}
              className="input-full-width"
              name={name}
              accepter={Input} 
              value={value}
              type="number"
              step={step}
              min={min ? min : 0}
              onChange={(e: any) => onChange(e)}
            />
          )}
        />
        <CustomErrorMessage errors={errors} name={inputName} />
      </Form.Group>
    </Col>
  );
};
