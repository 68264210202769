import React from "react";
import PlusIcon from "@rsuite/icons/Plus";
import { IconButton } from "rsuite";
type AddBlockButtonProps = {
  contextArray: any;
  context:
    | "info"
    | "facility"
    | "phone"
    | "service"
    | "offices"
    | "servicePhones";
  onClick?: any;
};
export const AddBlockButton = ({
  contextArray,
  context,
}: AddBlockButtonProps) => {
  const onAddButton = () => {
    switch (context) {
      case "info":
        contextArray.append({
          label: "",
          description: "",
        });
        break;
      case "facility":
        contextArray.append({
          serviceId: null,
          facilityId: null,
          isPrimaryFacility: false,
        });
        break;
      case "phone":
        contextArray.append({
          phoneType: null,
          phoneNumber: "",
          phoneDescription: "",
          isPrincipal: false,
        });
        break;
      case "servicePhones":
        contextArray.append({
          phoneType: null,
          phoneNumber: "",
          phoneDescription: "",
          isPrincipal: false,
        });
        break;
      case "service":
        contextArray.append({
          phones: [
            {
              phoneType: null,
              phoneNumber: "",
              phoneDescription: "",
              isPrincipal: false,
            },
          ],
        });
        break;
      case "offices":
        contextArray.append({
          isPrimary: false,
          address: {
            streetName: "",
            addressNote: "",
            localities: [
              {
                localityId: null,
              },
            ],
          },
          phones: [
            {
              phoneType: null,
              phoneNumber: "",
              phoneDescription: "",
              isPrincipal: true,
            },
          ],
        });
        break;
      default:
        break;
    }
  };

  return (
    <IconButton
      className="mt-1"
      appearance="ghost"
      size="sm"
      style={{ marginLeft: ".4rem" }}
      icon={<PlusIcon />}
      onClick={onAddButton}
    />
  );
};
