//@ts-nocheck
import React, { useState } from 'react'
import { Button, Grid } from "@material-ui/core"
import { DropzoneArea } from 'material-ui-dropzone';
import { useAppDispatch } from '../../../../redux/hooks';
import { UploadEadv } from '../../../../redux/uplaodEadv/EADV';
import CircularProgress from '@mui/material/CircularProgress';

export default function Popup({ productId, closeForm }: any) {

    const dispatch = useAppDispatch()
    const [isUploading, setIsUploading] = useState(false)
    var formData = new FormData();
    let data = []
    const putData = (files) => {
        data = files
    }

    const submit = async () => {
        setIsUploading(true)
        formData.append('file', data[0])
        await dispatch(UploadEadv({ id: productId, formData }))
        formData = new FormData()
        setIsUploading(false)
        closeForm(false)
    }
    return (
        <>
            <div style={{ zIndex: 2, height: "100%", width: "100%", backgroundColor: "black", opacity: 0.3, position: "fixed", display: "flex", justifyContent: "center", alignItems: "center" }} className='cont'></div>
            <Grid style={{ display: "flex", position: "absolute", zIndex: 3, flex: 1, justifyContent: "center", alignItems: "center", width: "100%", overflow: "hidden" }} container>
                <div className='GridStart' style={{ marginTop: "8%", zIndex: 9, color: "white", backgroundColor: "white", width: "70%", borderRadius: 9 }}>
                    <Grid style={{ minHeight: 70, maxHeight: 70, borderBottom: "1px solid black", display: "flex", justifyContent: "center", alignItems: "center" }}>
                        <div style={{ color: "black", fontSize: "20px" }}>Importer un fichier EADV</div>
                    </Grid>
                    <Grid style={{ padding: 40 }}> <DropzoneArea showAlerts={false}
                        // onDelete={file => deleteFile(file)}
                        // onDrop={(files) => putData(files)}
                        filesLimit={1}
                        acceptedFiles={[".PDF", ".pdf"]}
                        maxFileSize={50000000} onChange={(files) => putData(files)}
                    /></Grid>
                    <Grid container style={{ flex: 1, justifyContent: "center", alignItems: "center", paddingBottom: 30 }}>
                        <Button style={{ color: "black", backgroundColor: "#e5e5ea", marginRight: 30, width: 120 }} variant="contained" color="primary" onClick={() => closeForm(false)}>annuler</Button>
                        {isUploading ? <Button disabled style={{ color: "white", backgroundColor: "black", width: 120 }} variant="contained"><CircularProgress size={26} sx={{ color: '#fff' }} /></Button> : <Button style={{ color: "white", backgroundColor: "black", width: 120 }} variant="contained" onClick={() => submit()} color="primary">ajouter</Button>}
                    </Grid>
                </div>
            </Grid>

        </>


    )
}


