import { createSlice } from "@reduxjs/toolkit";
import { API_DEV } from "../../utils/apiRoute";
import { axiosPrivate } from "../../utils/interceptors";
import { createAsyncThunk } from "@reduxjs/toolkit";





export interface ProductState { 
    products:[]
}

export const fetchProducts = createAsyncThunk(
    "[Products]_GetSeparatedProducts_",
    async (_, { rejectWithValue }) => {
        try {
            let response = await axiosPrivate.get(`${API_DEV}/Product`);
            // console.log(response.data)
            return response.data;
        } catch (error) {
            console.log(JSON.parse(JSON.stringify(error)));
            return rejectWithValue(JSON.parse(JSON.stringify(error)));
        }
    }
);
export const addProduct = createAsyncThunk(
    "[Products]_addProduct_",
    async (data:any, { rejectWithValue }) => {
        console.log('data', data)
        try {
            let response = await axiosPrivate.post(`${API_DEV}/Product`,data);
            console.log(response.data)
            return response.data;
        } catch (error:any) {
            // console.log(JSON.parse(JSON.stringify(error)));
            return rejectWithValue(error.response.data);
        }
    }
);

export const ToggleProduct = createAsyncThunk(
    "[Products]_ToggleProd_",
    async (data:any, { rejectWithValue }) => {
        // console.log('data', data)
        try {
            let response = await axiosPrivate.put(`${API_DEV}/Product/updateStatusProduct`,data);
            // console.log(response.data)
            return response.data;
        } catch (error) {
            console.log(JSON.parse(JSON.stringify(error)));
            return rejectWithValue(JSON.parse(JSON.stringify(error)));
        }
    }
);
const initialState: ProductState = {

    products: []
}


export const productsSlice = createSlice({
    name: "[Products]",
    initialState,
    // eslint-disable-next-line
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(fetchProducts.fulfilled, (state, { payload }) => {
            state.products = payload
        });
        builder.addCase(addProduct.fulfilled, (state, { payload }) => {
            // console.log("done",payload)
        }).addCase(addProduct.rejected,(state, { payload }) => {
            console.log("rejected",payload)
        });
        builder.addCase(ToggleProduct.fulfilled,()=>{
            // console.log("rejected",payload)
        }).addCase(ToggleProduct.rejected,()=>{
            // console.log("rejected",payload)
        })
       
    },
});
// eslint-disable-next-line
export const { } = productsSlice.actions;

export default productsSlice.reducer;
