import React, { useState, useEffect } from "react";
import { Controller, useFieldArray } from "react-hook-form";
import { FlexboxGrid, Form, Toggle, Divider } from "rsuite";
import { useAppSelector } from "../../../../redux/hooks";
import { RootState } from "../../../../redux/store";
import { SelectField } from "../../../02-molecules/forms/SelectField/SelectField";
import { AddBlockButton } from "../addBlockButton/AddBlockButton";
import {
  preparefacilitiesOptions,
  preparefacilityTypesOptions,
  prepareServicesByfacilityOptions,
} from "../../../../helpers/GlobalFormHelpers";
import { RemoveBlockButton } from "../removeBlockButton/RemoveBlockButton";
import { CustomErrorMessage } from "../../../02-molecules/forms/ErrorMessage/CustomErrorMessage";
import ToolTip from "../../../01-atoms/ToolTip/ToolTip";

type Props = {
  fieldIsRequired: string;
  control: any;
  initialfacilities?: {
    serviceId: number | null;
    facilityId: number | null;
    facilityTypeId: number | null;
    isPrimaryFacility: boolean;
  }[];
  errors: any;
  setValue: any;
  getValues: any;
  resetField: any;
  selectedPrimaryfacility: {
    office: boolean;
    facility: boolean;
  };
  setSelectedPrimaryfacility: React.Dispatch<
    React.SetStateAction<{
      office: boolean;
      facility: boolean;
    }>
  >;
};

const DoctorFacilityBlock = ({
  fieldIsRequired,
  control,
  errors,
  initialfacilities,
  setValue,
  resetField,
  getValues,
  selectedPrimaryfacility,
  setSelectedPrimaryfacility,
}: Props) => {
  const facilities = useFieldArray({
    control,
    name: "facilities",
  });

  const { facilityTypes } = useAppSelector((state: RootState) => state.general);
  const facilityTypesOptions = preparefacilityTypesOptions(facilityTypes);
  const [facilitiesOptions, setfacilitiesOptions] = useState<
    { key: number; label: string; value: number }[][]
  >([]);
  const [servicesOptions, setServicesOptions] = useState<
    { key: number; label: string; value: number }[][]
  >([]);

  useEffect(() => {
    if (selectedPrimaryfacility.office) {
      for (let i = 0; i < facilities.fields.length; i++) {
        let oldValues = getValues().facilities[i];
        facilities.update(i, {
          ...oldValues,
          isPrimary: false,
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedPrimaryfacility]);
  useEffect(() => {
    if (initialfacilities) {      
      initialfacilities.forEach((e, i) => {
        if (e.facilityTypeId && e.facilityId) {
          preparefacilitiesOptions(
            e.facilityTypeId,
            i,
            facilitiesOptions,
            setfacilitiesOptions
          );
          prepareServicesByfacilityOptions(
            e.facilityId,
            i,
            servicesOptions,
            setServicesOptions
          );
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialfacilities]);

  const handleDelete = (index: number) => {
    let newFacilities = facilitiesOptions.splice(index, 1);
    let newServices = servicesOptions.splice(index, 1);
    setfacilitiesOptions(
      facilitiesOptions.filter((e) => !newFacilities.includes(e))
    );
    setServicesOptions(servicesOptions.filter((e) => !newServices.includes(e)));
  };

  return (
    <div className="custom-form-section">
      <h3 className="form-block-title">Etablissements</h3>
      <Divider />
      {facilities.fields.map((item, index) => (
        <div key={item.id + index}>
          <Form.Group
            style={{
              marginBottom: "1rem",
              display: "flex",
              marginLeft: ".5rem",
            }}
          >
            <Controller
              control={control}
              name={`facilities.${index}.isPrimaryFacility`}
              render={({ field: { onChange, name, value } }) => {
                return (
                  <Form.Control
                    name={name}
                    value={value}
                    accepter={Toggle}
                    size={"sm"}
                    defaultChecked={value}
                    style={{ width: "100%" }}
                    onChange={(e: any) => {
                      onChange(e);
                      setSelectedPrimaryfacility({
                        office: false,
                        facility: e ? true : false,
                      });
                      for (let i = 0; i < facilities.fields.length; i++) {
                        if (i !== index) {
                          let oldValues = getValues().facilities[i];
                          facilities.update(i, {
                            ...oldValues,
                            isPrimary: false,
                          });
                        }
                      }
                    }}
                  />
                );
              }}
            />
            <ToolTip
              message="Ce bouton indique si c'est l'adresse principale"
              type="help"
              style={{
                width: "13px",
                color: "var(--rs-text-secondary)",
                marginLeft: "10px",
                display: "inline-flex",
                marginTop: "2px",
              }}
            />
            <CustomErrorMessage
              errors={errors}
              name={`facilities.${index}.isPrimaryFacility`}
            />
          </Form.Group>
          <FlexboxGrid align="bottom" key={item.id}>
            <SelectField
              label={"Type d'établissement"}
              inputName={`facilities[${index}].facilityTypeId`}
              rules={{ required: fieldIsRequired }}
              control={control}
              errors={errors}
              searchable={false}
              cleanable={false}
              data={facilityTypesOptions}
              xs={24}
              sm={8}
              index={index}
              prepareNextData={(e, index) => {
                setValue(`facilities[${index}].facilityId`, null);
                setValue(`facilities.${index}.serviceId`, null);
                let newFacilities = facilitiesOptions.splice(index, 1);
                let newServices = servicesOptions.splice(index, 1);
                setfacilitiesOptions(
                  facilitiesOptions.filter((e) => !newFacilities.includes(e))
                );
                setServicesOptions(
                  servicesOptions.filter((e) => !newServices.includes(e))
                );
                preparefacilitiesOptions(
                  e,
                  index,
                  facilitiesOptions,
                  setfacilitiesOptions
                );
              }}
              md={8}
            />
            <SelectField
              label={"Etablissement"}
              inputName={`facilities[${index}].facilityId`}
              rules={{ required: fieldIsRequired }}
              control={control}
              errors={errors}
              index={index}
              searchable={false}
              cleanable={false}
              data={facilitiesOptions[index]}
              xs={24}
              sm={8}
              md={8}
              prepareNextData={(e, index) => {
                setValue(`facilities.${index}.serviceId`, null);
                let newServices = servicesOptions.splice(index, 1);
                setServicesOptions(
                  servicesOptions.filter((e) => !newServices.includes(e))
                );
                prepareServicesByfacilityOptions(
                  e,
                  index,
                  servicesOptions,
                  setServicesOptions
                );
              }}
            />
            <SelectField
              label={"Service"}
              inputName={`facilities[${index}].serviceId`}
              rules={{ required: false }}
              control={control}
              errors={errors}
              searchable={false}
              cleanable={false}
              data={servicesOptions[index]}
              xs={24}
              sm={7}
              md={7}
            />
            <RemoveBlockButton
              contextArray={facilities}
              index={index}
              style={{ marginBottom: "1rem", width: "2.6%" }}
              removeAll
              callBack={() => handleDelete(index)}
            />
          </FlexboxGrid>
        </div>
      ))}
      <AddBlockButton context="facility" contextArray={facilities} />
    </div>
  );
};

export default DoctorFacilityBlock;
