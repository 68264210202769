import React, { useEffect, useState } from "react";
import { Navigate, useParams } from "react-router-dom";
import { Loader } from "rsuite";
import {
  ApproveRequest,
  GetActiveRequestSingle,
  RejectRequest,
} from "../../../../../redux/Approval/approvalSlice";
import { useAppDispatch, useAppSelector } from "../../../../../redux/hooks";
import { RootState } from "../../../../../redux/store";
import { Doctor } from "../../../../../types/Doctor.model";

import { CustomButton } from "../../../../01-atoms/Buttons/Buttons";
import RequestValidationCard from "../../../../02-molecules/cards/request-validation-card/RequestValidationCard";
import "./DoctorValidationRequest.scss";

const DoctorValidationDeleteRequest = () => {
  const dispatch = useAppDispatch();

  const { DoctorApprovalById, requestState, navigateToValidationTable } =
    useAppSelector((state: RootState) => state.approval);

  const { id } = useParams();
  useEffect(() => {
    id && dispatch(GetActiveRequestSingle(parseInt(id)));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const [isReady, setIsReady] = useState<boolean>(false);
  const [doctor, setDoctor] = useState<Doctor>();

  useEffect(() => {
    if (
      DoctorApprovalById &&
      DoctorApprovalById.doctor &&
      requestState === "idle"
    ) {
      setDoctor(DoctorApprovalById.doctor as Doctor);
      setIsReady(true);
    } else {
      setDoctor(undefined);
      setIsReady(false);
    }
  }, [DoctorApprovalById, requestState]);
  const handleReject = () => {
    DoctorApprovalById &&
      dispatch(RejectRequest(DoctorApprovalById.approvalRequestId));
  };

  const handleApprove = () => {
    DoctorApprovalById &&
      dispatch(ApproveRequest(DoctorApprovalById.approvalRequestId));
  };
  return (
    <>
      {navigateToValidationTable && (
        <Navigate to="/validation/doctors" replace />
      )}
      {isReady ? (
        <>
          <RequestValidationCard
            title={"Demande de suppression"}
            doctor={doctor}
          />
          {/* action buttons */}
          <section className="validation-request-action-buttons">
            <CustomButton
              text={"Refuser"}
              style={{
                height: "47px",
                width: "155px",
                border: "1px solid #D94141",
                justifyContent: "center",
                color: "#D94141",
              }}
              onClick={handleReject}
            />
            <CustomButton
              text={"Accepter"}
              color={"dark"}
              style={{
                height: "47px",
                width: "155px",
                justifyContent: "center",
              }}
              onClick={handleApprove}
            />
          </section>
        </>
      ) : (
        <div
        style={{
          display: "flex",
          justifyContent: "center",
          height: "60vh",
          alignItems: "center",
        }}
      >
        <Loader size="lg" />
      </div>
      )}
    </>
  );
};

export default DoctorValidationDeleteRequest;
