import React, { useEffect, useState } from "react";
import { useFieldArray, useForm } from "react-hook-form";
import { Button, Divider, FlexboxGrid, Form, Loader, Modal } from "rsuite";
import { SelectField } from "../../../02-molecules/forms/SelectField/SelectField";
import { TagsField } from "../../../02-molecules/forms/TagsField/TagsField";
import { TextField } from "../../../02-molecules/forms/TextField/TextField";
import { AddBlockButton } from "../addBlockButton/AddBlockButton";
import DoctorFacilityBlock from "./DoctorFacilityBlock";
import OfficeBlock from "./OfficeBlock";
import { PhonesBlock } from "../phonesBlock/PhonesBlock";
import { RemoveBlockButton } from "../removeBlockButton/RemoveBlockButton";
import { prepareDoctorFormPayload } from "./useDoctorForm";
import useDoctorForm, {
  DoctorFormInputs,
  fieldIsRequired,
} from "./useDoctorForm";
import { useAppDispatch, useAppSelector } from "../../../../redux/hooks";
import { addDoctor, updateDoctor } from "../../../../redux/Doctor/doctorSlice";
import DoctorCheckModal from "./DoctorCheckModal";
import { phoneTypeOptions } from "../../../../helpers/GlobalFormHelpers";
import { LocalitySummary } from "../../../../types/Locality.model";
import { RootState } from "../../../../redux/store";

type Props = {
  formContext: "add" | "update";
};

const DoctorForm = ({ formContext }: Props) => {
  const dispatch = useAppDispatch();
  const { formIsReady, formOptions, formUI, initialValues, setFormIsReady } =
    useDoctorForm({ formContext });
  const { teams } = useAppSelector((state: RootState) => state.general);
  const { requestStateForm } = useAppSelector((state: RootState) => state.doctor);

  const {
    control,
    handleSubmit,
    setValue,
    reset,
    getValues,
    resetField,
    setFocus,
    watch,
    formState: { errors },
  } = useForm<DoctorFormInputs>({
    defaultValues: initialValues,
    criteriaMode: "all",
    reValidateMode: "onChange",
  });
  

  useEffect(() => {
    reset(initialValues);
    
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialValues,requestStateForm]);

  const additionalInfos = useFieldArray({
    control,
    name: "additionalInfos",
  });

  const [selectedPrimaryfacility, setSelectedPrimaryfacility] = useState<{
    office: boolean;
    facility: boolean;
  }>({
    office: false,
    facility: false,
  });

  const [checkModalIsOpen, setCheckModalIsOpen] = useState(false);
 
  const [officeInitialLocalities, setOfficeInitialLocalities] = useState<
    LocalitySummary[]
  >([]);
  useEffect(() => {
    // if ( !(officeInitialLocalities && officeInitialLocalities.length > 0)) {
    //   console.log(initialValues, '77');
    //   setOfficeInitialLocalities(
    //     initialValues?.offices[0]?.address?.localities
    //   );
      
    // }
    initialValues?.offices[0]?.address.localities.length >0 &&  setOfficeInitialLocalities(
      initialValues?.offices[0]?.address?.localities )
    // eslint-disable-next-line react-hooks/exhaustive-deps 
  }, [initialValues]);

  
  const value = watch("inPortfolioOf");
  useEffect(() => {
    const team = teams.find((e) =>
      e.members.find((m) => m.userId === parseInt(value.toString()))
    );
    team && setValue("businessUnits", [team.businessUnit.businessUnitId]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  const onSubmit = handleSubmit((data) => {
    const payload = prepareDoctorFormPayload(data);
    formContext === "add" && dispatch(addDoctor(payload));
    formContext === "update" && dispatch(updateDoctor(payload));
  });
  return (
    <Form className="add__doctor__form__wrapper">
      <Modal
        full
        open={formUI?.formIsOpen}
        onClose={formUI?.toggleForm}
        backdrop="static"
        onExited={() => setFormIsReady(false)}
        className="form-modal"
      >
        <Modal.Header>
          <Modal.Title>
            {formContext === "add" ? "Ajouter" : "Modifier"} un Médecin
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="custom-form-modal">
          {formIsReady ? (
            <>
              <div className="custom-form-section">
                <h3 className="form-block-title">Informations générales</h3>
                <Divider />
                <FlexboxGrid align="bottom">
                  {/* title */}
                  <SelectField
                    control={control}
                    errors={errors}
                    inputName="title"
                    rules={{ required: fieldIsRequired }}
                    sm={2}
                    md={2}
                    data={formOptions ? formOptions.titleOptions : []}
                    cleanable={false}
                    label={"Titre"}
                    searchable={false}
                  />
                  {/* firstName */}
                  <TextField
                    label={"Nom"}
                    inputName={"lastName"}
                    rules={{ required: fieldIsRequired }}
                    control={control}
                    errors={errors}
                    xs={24}
                    sm={7}
                    md={7}
                  />
                  {/* lastName */}
                  <TextField
                    label={"Prénom"}
                    inputName={"firstName"}
                    rules={{ required: fieldIsRequired }}
                    control={control}
                    errors={errors}
                    xs={24}
                    sm={7}
                    md={7}
                  />
                  {/* email */}
                  <TextField
                    label={"Email"}
                    inputName={"email"}
                    setFocus={setFocus}
                    rules={{
                      required: fieldIsRequired,
                      validate: (value: any) =>
                        /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(
                          value
                        ) || "Email non valide",
                    }}
                    control={control}
                    errors={errors}
                    xs={24}
                    sm={8}
                    md={8}
                  />
                  {/* potentialId */}
                  <SelectField
                    control={control}
                    errors={errors}
                    inputName="potential"
                    rules={{ required: fieldIsRequired }}
                    xs={24}
                    sm={2}
                    md={2}
                    data={formOptions ? formOptions.potentialsOptions : []}
                    cleanable={false}
                    searchable={false}
                    label={"Potentiel"}
                  />
                  {/* patientsPerDay */}
                  <TextField
                    label={"Patients/Jour"}
                    inputName={"patientsPerDay"}
                    rules={{
                      required: fieldIsRequired,
                      validate: (value: any) =>
                        /^\d+$/gm.test(value) || "Non valide",
                    }}
                    inputMode="tel"
                    control={control}
                    xs={24}
                    sm={2}
                    md={4}
                    errors={errors}
                  />

                  {/* specialities */}
                  <TagsField
                    control={control}
                    errors={errors}
                    inputName="specialities"
                    rules={{ required: fieldIsRequired }}
                    xs={24}
                    sm={14}
                    md={14}
                    data={formOptions ? formOptions.specialitiesOptions : []}
                    cleanable={false}
                    label={"Spécialités"}
                  />
                  {/* reference */}
                  <TextField
                    label={"Référence"}
                    inputName={"reference"}
                    rules={{ required: false }}
                    control={control}
                    errors={errors}
                    xs={24}
                    sm={4}
                    md={4}
                  />
                  <SelectField
                    label={"Type téléphone"}
                    inputName={`principalPhone.phoneType`}
                    rules={{ required: fieldIsRequired }}
                    control={control}
                    errors={errors}
                    searchable={false}
                    cleanable={false}
                    data={phoneTypeOptions}
                    xs={24}
                    sm={6}
                    md={6}
                  />
                  <TextField
                    label={"Numéro"}
                    inputName={`principalPhone.phoneNumber`}
                    rules={{ required: fieldIsRequired }}
                    control={control}
                    errors={errors}
                    inputMode="tel"
                    xs={24}
                    sm={6}
                    md={6}
                  />
                  <TextField
                    label={"Description"}
                    inputName={`principalPhone.phoneDescription`}
                    control={control}
                    errors={errors}
                    xs={24}
                    sm={12}
                    md={12}
                  />
                </FlexboxGrid>
              </div>
              <OfficeBlock
                fieldIsRequired={fieldIsRequired}
                control={control}
                errors={errors}
                initialLocalities={officeInitialLocalities}
                setValue={setValue}
                getValues={getValues}
                resetField={resetField}
                selectedPrimaryfacility={selectedPrimaryfacility}
                setSelectedPrimaryfacility={setSelectedPrimaryfacility}
                formContext={formContext}
              />
              <DoctorFacilityBlock
                fieldIsRequired={fieldIsRequired}
                control={control}
                errors={errors}
                initialfacilities={
                  initialValues.facilities.length > 0
                    ? initialValues.facilities
                    : []
                }
                setValue={setValue}
                getValues={getValues}
                resetField={resetField}
                selectedPrimaryfacility={selectedPrimaryfacility}
                setSelectedPrimaryfacility={setSelectedPrimaryfacility}
              />

              <PhonesBlock
                fieldIsRequired={fieldIsRequired}
                control={control}
                errors={errors}
                extra
              />
              {/* Tags Block */}
              <div className="custom-form-section">
                <FlexboxGrid align="bottom">
                  <TagsField
                    control={control}
                    errors={errors}
                    creatable
                    data={formOptions ? formOptions.tagsOptions : []}
                    xs={24}
                    label={"Tags"}
                    inputName={"tags"}
                  />
                </FlexboxGrid>
              </div>
              {/* inPortfolio Block */}
              <div className="custom-form-section">
                <FlexboxGrid align="bottom">
                  <TagsField
                    control={control}
                    rules={{ required: fieldIsRequired }}
                    errors={errors}
                    data={formOptions ? formOptions.inPortfolioOfOption : []}
                    xs={24}
                    cleanable={false}
                    sm={12}
                    md={12}
                    label={"Délégués"}
                    inputName={"inPortfolioOf"}
                  />
                  {/* businessUnits */}
                  <TagsField
                    control={control}
                    errors={errors}
                    inputName="businessUnits"
                    rules={{ required: fieldIsRequired }}
                    xs={24}
                    sm={12}
                    md={12}
                    data={formOptions ? formOptions.businessUnitsOptions : []}
                    cleanable={false}
                    label={"Business Unit"}
                  />
                </FlexboxGrid>
              </div>
              <div className="custom-form-section">
                <h3 className="form-block-title">
                  Informations Complémentaires
                </h3>
                {additionalInfos.fields.map((field, index) => (
                  <div key={field.id}>
                    <Divider style={{ margin: "1rem 0" }} />
                    <FlexboxGrid align="bottom" key={field.id}>
                      <TextField
                        control={control}
                        errors={errors}
                        label={"Label"}
                        xs={24}
                        md={12}
                        inputName={`additionalInfos.${index}.label`}
                        rules={{ required: fieldIsRequired }}
                      />
                      <TextField
                        control={control}
                        errors={errors}
                        label={"Description"}
                        xs={24}
                        md={11}
                        inputName={`additionalInfos.${index}.description`}
                        rules={{ required: fieldIsRequired }}
                      />
                      <RemoveBlockButton
                        contextArray={additionalInfos}
                        index={index}
                        removeAll
                        style={{ marginBottom: "1rem" }}
                      />
                    </FlexboxGrid>
                  </div>
                ))}
                <AddBlockButton context="info" contextArray={additionalInfos} />
              </div>
            </>
          ) : (
            <Loader size="lg" center content="loading" />
          )}
        </Modal.Body>

        <Modal.Footer style={{ paddingTop: "1rem" }}>
          <Button onClick={formUI?.toggleForm}>Annuler</Button>
          <Button
            className="database-submit-button"
            type="submit"
            appearance="primary"
            onClick={() => onSubmit()}
          >
            Enregistrer
          </Button>
        </Modal.Footer>
      </Modal>
      <DoctorCheckModal
        isOpen={checkModalIsOpen}
        onClose={() => setCheckModalIsOpen(false)}
      />
    </Form>
  );
};

export default DoctorForm;
