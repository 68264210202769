import { useEffect, useState } from "react";
import "./TargetGroupBy.scss";
import { DoctorSummary } from "../../../types/DoctorSummary.model";
import { TargetWeek } from "../../../types/TargetWeek.model";
import { useAppSelector } from "../../../redux/hooks";
import { RootState } from "../../../redux/store";
import { DataGrid, GridColumns } from "@material-ui/data-grid";
import localizedTextsMap from "../../../utils/dataGridColumnMenuLanguage";
interface Props {
  targetWeek: TargetWeek | null;
  innerSelected: number[];
  setInnerSelected: React.Dispatch<React.SetStateAction<number[]>>;
}
const TargetGroupByInner = ({
  targetWeek,
  innerSelected,
  setInnerSelected,
}: Props) => {
  type sourceItem = {
    id: number;
    name: string;
    potential: string;
    speciality: string;
    specialityName: string;
    level1: string;
    level2: string;
    facilityName: string;
    facilityType: string;
  };
  const { activeUpdatedState } = useAppSelector(
    (state: RootState) => state.target
  );

  const TargetDataFactory = (filteredDoctorsSummaries: DoctorSummary[]) => {
    return filteredDoctorsSummaries.map((e) => ({
      id: e.doctorId,
      name: `${e.title} ${e.firstName} ${e.lastName}`,
      potential: e.potential?.potentialName || "",
      speciality: e.specialities[0]?.abreviation || "",
      specialityName: e.specialities[0]?.specialityName || "",
      facilityName: e.principalAddress?.facilityName || "",
      facilityType:
        e.principalAddress?.facilityType?.facilityTypeName || "Cabinet",
      level1: e.principalAddress?.address?.localities[0]?.localityName || "",
      level2: e.principalAddress?.address?.localities[1]?.localityName || "",
    }));
  };
  const [source, setSource] = useState<sourceItem[]>([]);
  const [rowsPerPage, setRowsPerPage] = useState<number>(25);

  const columns: GridColumns = [
    {
      field: "name",
      headerName: "Nom et Prénom",
      width: 300,
    },
    {
      field: "potential",
      headerName: "Pot.",
      width: 110,
      description: "Potentiel",
    },
    {
      field: "speciality",
      headerName: "Spéc.",
      width: 115,
      description: "Spécialité",
    },
    {
      field: "level1",
      headerName: "Gouv.",
      width: 180,
      description: "Gouvernorat",
    },
    {
      field: "level2",
      headerName: "Délég.",
      width: 180,
      description: "Délégation",
    },
    {
      field: "facilityType",
      headerName: "Type Etab.",
      width: 150,
      description: "Type d'établissement",
    },
    {
      field: "facilityName",
      headerName: "Etab.",
      width: 300,
      description: "Etablissement",
    },
  ];
  useEffect(() => {
    const data = targetWeek ? TargetDataFactory(targetWeek.doctors) : [];

    setSource(data);
  }, [targetWeek, activeUpdatedState]);

  return (
    <DataGrid
      headerHeight={52}
      rows={source}
      columns={columns}
      pageSize={rowsPerPage}
      rowsPerPageOptions={[10, 25, 50, 100]}
      onPageSizeChange={(newPageSize) => setRowsPerPage(newPageSize)}
      checkboxSelection
      onSelectionModelChange={(selectionModel) =>
        setInnerSelected(selectionModel as number[])
      }
      localeText={localizedTextsMap}
    />
  );
};

export default TargetGroupByInner;
