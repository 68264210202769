import React, { useEffect, useState } from "react";
import { Navigate, useParams } from "react-router-dom";
import { Loader } from "rsuite";
import {
  ApproveRequest,
  GetActiveRequestSingle,
  RejectRequest,
} from "../../../../../redux/Approval/approvalSlice";
import { useAppDispatch, useAppSelector } from "../../../../../redux/hooks";
import { RootState } from "../../../../../redux/store";
import { Doctor } from "../../../../../types/Doctor.model";

import { CustomButton } from "../../../../01-atoms/Buttons/Buttons";
import RequestValidationCard from "../../../../02-molecules/cards/request-validation-card/RequestValidationCard";
import "./DoctorValidationRequest.scss";

const DoctorValidationUpdateRequest = () => {
  const dispatch = useAppDispatch();

  const { DoctorApprovalById, requestState, navigateToValidationTable } =
    useAppSelector((state: RootState) => state.approval);

  const { id } = useParams();
  useEffect(() => {
    id && dispatch(GetActiveRequestSingle(parseInt(id)));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const [isReady, setIsReady] = useState<boolean>(false);
  const [doctor, setDoctor] = useState<Doctor>();
  const [updatedDoctor, setUpdatedDoctor] = useState<Doctor>();

  useEffect(() => {
    if (
      DoctorApprovalById &&
      DoctorApprovalById.doctor &&
      DoctorApprovalById.updates &&
      requestState === "idle"
    ) {
      DoctorApprovalById.updatedDoctor &&
        setUpdatedDoctor(DoctorApprovalById.updatedDoctor);
      setDoctor(DoctorApprovalById.doctor as Doctor);
      setIsReady(true);
    } else {
      setDoctor(undefined);
      setIsReady(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [DoctorApprovalById, requestState]);
  const handleReject = () => {
    DoctorApprovalById &&
      dispatch(RejectRequest(DoctorApprovalById.approvalRequestId));
  };

  const handleApprove = () => {
    DoctorApprovalById &&
      dispatch(ApproveRequest(DoctorApprovalById.approvalRequestId));
  };

  const getUpdates = (doctor: Doctor, updatedDoctor: Doctor) => {
    const result = {
      doctorId: doctor.doctorId,
      title: updatedDoctor.title || doctor.title,
      firstName: updatedDoctor.firstName || doctor.firstName,
      lastName: updatedDoctor.lastName || doctor.lastName,
      email: updatedDoctor.email || doctor.email,
      reference: updatedDoctor.reference || doctor.reference,
      patientsPerDay: updatedDoctor.patientsPerDay || doctor.patientsPerDay,
      businessUnits: updatedDoctor.businessUnits || doctor.businessUnits,
      additionalInfos: updatedDoctor.additionalInfos || doctor.additionalInfos,
      phones: updatedDoctor.phones || doctor.phones,
      tags: updatedDoctor.tags || doctor.tags,
      specialities: updatedDoctor.specialities || doctor.specialities,
      potential: updatedDoctor.potential || doctor.potential,
      offices: updatedDoctor.offices || doctor.offices,
      facilities: updatedDoctor.facilities || doctor.facilities,
      inPortfolioOf: updatedDoctor.inPortfolioOf || doctor.inPortfolioOf,
    };
    return result as Doctor;
  };

  return (
    <>
      {navigateToValidationTable && (
        <Navigate to="/validation/doctors" replace />
      )}
      {isReady ? (
        <>
          <div style={{ display: "flex", overflow: "auto" }}>
            <RequestValidationCard
              updateRequestClassName={{
                top: "update-requestStyle-top",
                inner: "update-requestStyle-middle",
                cardWidth: "update-card-width",
              }}
              title={"Profil existant"}
              doctor={doctor}
            />
            <RequestValidationCard
              updateRequestClassName={{
                top: "update-requestStyle-top",
                inner: "update-requestStyle-middle",
                cardWidth: "update-card-width",
              }}
              updateCopyClassName={"update-copy-border"}
              title={"Demande de modification"}
              doctor={
                doctor && updatedDoctor && getUpdates(doctor, updatedDoctor)
              }
            />
          </div>
          {/* action buttons */}
          <section className="validation-request-action-buttons">
            <CustomButton
              loading={requestState === "loading"}
              text={"Refuser"}
              style={{
                height: "47px",
                width: "155px",
                border: "1px solid #D94141",
                justifyContent: "center",
                color: "#D94141",
              }}
              onClick={handleReject}
            />
            <CustomButton
              loading={requestState === "loading"}
              text={"Accepter"}
              color={"dark"}
              style={{
                height: "47px",
                width: "155px",
                justifyContent: "center",
              }}
              onClick={handleApprove}
            />
          </section>
        </>
      ) : (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            height: "60vh",
            alignItems: "center",
          }}
        >
          <Loader size="lg" />
        </div>
      )}
    </>
  );
};

export default DoctorValidationUpdateRequest;
