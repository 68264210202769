import React from "react";
import { getLocalDate } from "../../../../utils/dateHelpers";
import "./DateSeparator.scss";
type Props = {
  date: Date;
};

const DateSeparator = ({ date }: Props) => {
  return (
    <div className="phalcon-inbox-date-separator">
      <div className="phalcon-inbox-date-separator__inner">
        <div className="phalcon-inbox-date-separator__inner--date">
          {getLocalDate(date)}
        </div>
      </div>
    </div>
  );
};

export default DateSeparator;
