import React from "react";
import { Panel, Row, Col } from "rsuite";
import { Team } from "../../../../types/Team.model";
import User from "../../../../types/User.model";
import { isManager } from "../../../../utils/enumHelpers";
import { TeamMemberCard } from "../../../02-molecules/cards/team-member-card/TeamMemberCard";
import "./TeamMemberList.scss";
interface Props {
  team?: Team;
}

export const TeamMemberList = ({ team }: Props) => {
  return (
    <div className="team-member-list__wrapper">
      {team ? (
        <>
          <div>
            <Row>
              <Col
                xs={24}
                sm={12}
                md={8}
                lg={8}
                className="team-member-list-col"
              ></Col>
              <Col
                xs={24}
                sm={12}
                md={8}
                lg={8}
                className="team-member-list-col"
              >
                <TeamMemberCard member={team.manager} />
              </Col>
              <Col
                xs={24}
                sm={12}
                md={8}
                lg={8}
                className="team-member-list-col"
              ></Col>
            </Row>
          </div>
          {team.members && team.members.length > 0 ? (
            <Row className="team-member-list-row" style={{ marginTop: "1rem" }}>
              {team.members
                .filter((member: User) => !isManager(member))
                .map((member: User, i: number) => (
                  <Col
                    key={i}
                    xs={24}
                    sm={12}
                    md={6}
                    lg={6}
                    className="team-member-list-col"
                  >
                    <TeamMemberCard member={member} />
                  </Col>
                ))}
            </Row>
          ) : (
            <span>no members</span>
          )}
        </>
      ) : (
        <Panel>no teams</Panel>
      )}
    </div>
  );
};
