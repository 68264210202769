import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import moment from "moment";
import {
  Avatar,
  CircularProgress,
  Dialog,
  DialogContent,
  styled,
} from "@material-ui/core";
import Icon from "@mdi/react";
import { CustomizedDialogTitle } from "../../../01-atoms/Dialog/CustomizedDialogTitle";
import {
  mdiAccountGroup,
  mdiCalendarRange,
  mdiClockOutline,
  mdiMedicalBag,
  mdiAvTimer,
  mdiAccountSwitch,
  mdiMapMarker,
  // mdiBookmark,
  mdiBullseyeArrow,
  mdiPackageVariantClosed,
  mdiBottleTonicOutline,
  mdiAccountTie,
} from "@mdi/js";
import "./VisitCard.scss";
import "./StandardCardInfo.scss";
import { ObjectionCard } from "../objection-card/ObjectionCard";
import { RequestCard } from "../request-card/RequestCard";
import { Report } from "../../../../types/Report.model";
import { Locations } from "../../../../types/Locations.model";
import { Locality } from "../../../../types/Locality.model";
type Props = {
  setVisitInfoCardIsOpen: Dispatch<SetStateAction<boolean>>;
  visitInfoCardIsOpen: boolean;
  visitDetails: Report | null;
  requestState: "idle" | "loading";
};

type PreparedData = {
  locationType: string;
  Address: string;
  localities: string;
};
const CustomizedDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
  "& .MuiDialog-paper": {
    width: "1000px",
  },
  "& .MuiDialog-paperWidthSm": {
    maxWidth: "1000px",
    minHeight: "400px",
    // maxHeight: "700px",
  },
}));

export const divider = (width: string) => {
  return (
    <div
      style={{
        width: width,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        borderTop: "1px solid #CACACA",
        margin: "auto",
      }}
    ></div>
  );
};

export const VisitInfoCard = ({
  visitInfoCardIsOpen,
  setVisitInfoCardIsOpen,
  visitDetails,
  requestState,
}: Props) => {
  const handleVisitInfoCardClose = () => {
    setVisitInfoCardIsOpen(false);
  };

  const [preparedData, setPreparedData] = useState<PreparedData>({
    locationType: "",
    Address: "",
    localities: "",
  });

  const prepareData = (visit: Report | null) => {
    if (!visit) return null;
    const getLocation = (location: Locations) => {
      if (location?.facilityId) {
        return {
          locationType: location.facilityType?.facilityTypeName || "Etab.",
          Address: location.address?.streetName || "",
          localities: location.address?.localities
            ?.slice(0, 4)
            .map((loc: Locality) => loc.localityName)
            .join(", "),
        };
      } else if (location?.officeId) {
        return {
          locationType: "Cabinet",
          Address: location.address?.streetName || "",
          localities: location.address?.localities
            ?.slice(0, 5)
            .map((loc: Locality) => loc.localityName)
            .join(", "),
        };
      } else if (location?.address) {
        return {
          locationType: "Autres",
          Address: location.address?.streetName || "",
          localities: location.address?.localities
            ?.slice(0, 5)
            .map((loc: Locality) => loc.localityName)
            .join(", "),
        };
      } else {
        return {
          locationType: "",
          Address: "",
          localities: "",
        };
      }
    };

    return getLocation(visit.location);
  };

  useEffect(() => {
    const data = prepareData(visitDetails);
    data && setPreparedData(data);
  }, [requestState, visitDetails]);

  return (
    <div className="visit-info__wrapper">
      <CustomizedDialog
        open={visitInfoCardIsOpen}
        onClose={handleVisitInfoCardClose}
      >
        <CustomizedDialogTitle
          id="customized-dialog-title"
          onClose={handleVisitInfoCardClose}
          iconButtonStyle={{
            position: "absolute",
            right: 8,
            width: "36px",
            height: "36px",
            top: 12,
            color: "white",
            backgroundColor: "#373737",
            "&:hover": {
              backgroundColor: "white",
            },
          }}
        >
          <div className="visit-info__title"> Rapport</div>
        </CustomizedDialogTitle>
        {requestState === "idle" ? (
          <DialogContent className="visit-info__content-wrapper">
            <div className="visit-info__content-wrapper--inner">
              <div className="visit-info__content-wrapper--inner__title">
                <Icon path={mdiMedicalBag} size={1.2} />
                <div className="client-info">
                  <div className="name">
                    {`${visitDetails?.weekContent?.doctor?.title} ${visitDetails?.weekContent?.doctor?.firstName} ${visitDetails?.weekContent?.doctor?.lastName}` ||
                      ""}
                  </div>
                  <span className="speciality">
                    {visitDetails?.weekContent?.doctor?.specialities?.find(
                      (speciality) => speciality.isPrincipal
                    )?.specialityName || ""}
                  </span>
                </div>
              </div>
              {/* divider */}
              {divider("91%")}
              <div className="info-body">
                {/* General + date info */}
                <section
                  className="info-body__general"
                  style={{ flexWrap: "wrap" }}
                >
                  <div className="visit-date" style={{ paddingTop: "0.6rem" }}>
                    <div className="icon">
                      <Icon path={mdiCalendarRange} size={1} />
                    </div>
                    <div className="content">
                      {visitDetails?.date
                        ? moment(visitDetails.date).format("L")
                        : "-"}
                    </div>
                  </div>
                  <div className="visit-date" style={{ paddingTop: "0.6rem" }}>
                    <div className="icon">
                      <Icon path={mdiClockOutline} size={1} />
                    </div>
                    <div className="content">
                      {visitDetails?.date
                        ? moment(visitDetails.date).format("LT")
                        : "-"}
                    </div>
                  </div>
                  <div className="visit-date" style={{ paddingTop: "0.6rem" }}>
                    <div className="icon">
                      <Icon path={mdiAvTimer} size={1} />
                    </div>
                    <div className="content">
                      {visitDetails?.duration
                        ? `${visitDetails.duration} min`
                        : "-"}
                    </div>
                  </div>
                  {/* <div className="visit-date" style={{ paddingTop: "0.6rem" }}>
                    <div className="icon">
                      <Icon path={mdiTag} size={1} />
                    </div>
                    <div className="content">plan de tournée</div>
                  </div> */}
                </section>
                {/* participants */}
                <section
                  className="info-body__general"
                  style={{ flexDirection: "column" }}
                >
                  <div>
                    <div className="visit-date">
                      <div className="icon">
                        <Icon path={mdiAccountGroup} size={1} />
                      </div>
                      <div className="content">
                        <div>Participants</div>
                      </div>
                    </div>
                  </div>
                  <div
                    style={{
                      flexDirection: "row",
                      display: "flex",
                      flexWrap: "wrap",
                    }}
                  >
                    {visitDetails?.participants &&
                    visitDetails?.participants.length
                      ? visitDetails.participants.map((participant) => {
                          return (
                            <div className="info-tags">
                              {`${participant.title} ${participant.firstName} ${participant.lastName}`}
                              <span className="speciality-role">
                                {participant.specialities?.find(
                                  (speciality) => speciality.isPrincipal
                                )?.specialityName || ""}
                              </span>
                            </div>
                          );
                        })
                      : ""}
                  </div>
                </section>
                {/* accompaniment */}
                <section
                  className="info-body__general"
                  style={{ flexDirection: "column" }}
                >
                  <div>
                    <div className="visit-date">
                      <div className="icon">
                        <Icon path={mdiAccountGroup} size={1} />
                      </div>
                      <div className="content">
                        <div>Accompagné(e) par</div>
                      </div>
                    </div>
                  </div>
                  <div style={{ flexDirection: "row", display: "flex" }}>
                    {/* Manager */}
                    {visitDetails?.accompaniment?.manager && (
                      <div className="info-tags nowrap-tags" > 
                        <div style={{ display: "flex", alignItems: "center" }}>
                          <Avatar
                            alt="Remy Sharp"
                            src={`${visitDetails.accompaniment.manager.photoUrl}`}
                            style={{ width: "25px", height: "25px" }}
                          />
                          <div className="user-info">
                            {`${
                              visitDetails?.accompaniment?.manager?.firstName ||
                              "-"
                            } ${
                              visitDetails?.accompaniment?.manager?.lastName ||
                              "-"
                            }`}
                            <span className="speciality-role user">
                              {" "}
                              Manager
                            </span>
                          </div>
                        </div>
                      </div>
                    )}
                    {/* Delegate */}
                    {visitDetails?.accompaniment?.delegates &&
                    visitDetails?.accompaniment?.delegates.length
                      ? visitDetails.accompaniment.delegates.map((delegate) => (
                          <div className="info-tags nowrap-tags">
                            <div
                              style={{ display: "flex", alignItems: "center" }}
                              key={delegate.userId}
                            >
                              <Avatar
                                alt="Remy Sharp"
                                src={`${delegate?.photoUrl}`}
                                style={{ width: "25px", height: "25px" }}
                              />
                              <div className="user-info">
                                {`${delegate?.firstName || "-"} ${
                                  delegate?.lastName || "-"
                                }`}
                                <span className="speciality-role user">
                                  Délégué
                                </span>
                              </div>
                            </div>
                          </div>
                        ))
                      : ""}
                    {/* {Others} */}
                    {visitDetails?.accompaniment?.otherAccompagnant && (
                      <div className="info-tags nowrap-tags">
                        <div style={{ display: "flex", alignItems: "center" }}>
                          <Icon path={mdiAccountTie} size={1} />
                          <div className="user-info">
                            {visitDetails.accompaniment.otherAccompagnant}
                            <span className="speciality-role user">
                              Invité(s)
                            </span>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </section>
                {/* substitute */}
                <section
                  className="info-body__general"
                  style={{ flexDirection: "column" }}
                >
                  <div>
                    <div className="visit-date">
                      <div className="icon">
                        <Icon path={mdiAccountSwitch} size={1} />
                      </div>
                      <div className="content">
                        <div>Rempalçant (e)</div>
                      </div>
                    </div>
                  </div>
                  <div style={{ flexDirection: "row", display: "flex" }}>
                    <div className="info-tags">
                      <div className="full-name">
                        {visitDetails?.substitute
                          ? `${visitDetails.substitute.title} ${visitDetails.substitute.firstName} ${visitDetails.substitute.lastName} `
                          : ""}
                      </div>
                      <span className="speciality-role">
                        {visitDetails?.substitute &&
                          visitDetails?.substitute?.specialities &&
                          visitDetails?.substitute?.specialities?.length &&
                          visitDetails?.substitute?.specialities.find(
                            (speciality) => speciality.isPrincipal
                          )?.specialityName}
                      </span>
                    </div>
                  </div>
                </section>
                {/* address */}
                {/* {to do } */}
                <section
                  className="info-body__general"
                  style={{ flexDirection: "column" }}
                >
                  <div>
                    <div className="visit-date">
                      <div className="icon">
                        <Icon path={mdiMapMarker} size={1} />
                      </div>
                      <div className="content">
                        <div>Addresse de visite</div>
                      </div>
                    </div>
                  </div>
                  <div style={{ flexDirection: "row", display: "flex" }}>
                    <div className="info-tags">
                      <div className="address-type">
                        {preparedData.locationType}
                        {/* <Icon
                        path={mdiBookmark}
                        size={0.6}
                        color={"#20ABE3"}
                        style={{ marginLeft: "0.7rem" }}
                      /> */}
                      </div>
                      <span className="address">
                        {preparedData?.Address && preparedData?.localities
                          ? `${preparedData?.Address}, ${preparedData?.localities}`
                          : preparedData?.Address && !preparedData?.localities
                          ? preparedData?.Address
                          : ""}
                      </span>
                    </div>
                  </div>
                </section>
                {/* Next Goal */}
                <section
                  className="info-body__general"
                  style={{ flexDirection: "column" }}
                >
                  <div>
                    <div className="visit-date">
                      <div className="icon">
                        <Icon path={mdiBullseyeArrow} size={1} />
                      </div>
                      <div className="content">
                        <div>Objectif de la prochaine visite / Commentaire</div>
                      </div>
                    </div>
                  </div>
                  <div className="info-tags">
                    <span className="address"> {visitDetails?.nextGoal}</span>
                  </div>
                </section>
                {/* presented products */}
                <section
                  className="info-body__general"
                  style={{ flexDirection: "column" }}
                >
                  <div>
                    <div className="visit-date">
                      <div className="icon">
                        <Icon path={mdiPackageVariantClosed} size={1} />
                      </div>
                      <div className="content">
                        <div>Produits présentés / Objectifs de la visite</div>
                      </div>
                    </div>
                  </div>

                  <div className="info-tags">
                    <div
                      style={{
                        flexDirection: "row",
                        display: "flex",
                        flexWrap: "wrap",
                      }}
                    >
                      {visitDetails?.presentedProducts &&
                      visitDetails.presentedProducts.length
                        ? visitDetails.presentedProducts.map((product) => (
                            <div
                              style={{
                                paddingTop: "0.6rem",
                                paddingBottom: "0.6rem",
                              }}
                            >
                              <span className="product">
                                {product.productName}
                              </span>
                            </div>
                          ))
                        : ""}
                    </div>
                  </div>
                </section>
                {/* simples */}
                <section
                  className="info-body__general"
                  style={{ flexDirection: "column" }}
                >
                  <div>
                    <div className="visit-date">
                      <div className="icon">
                        <Icon path={mdiBottleTonicOutline} size={1} />
                      </div>
                      <div className="content">
                        <div>Echantillons / Quantités</div>
                      </div>
                    </div>
                  </div>

                  <div className="info-tags">
                    <div style={{ flexDirection: "row", display: "flex" }}>
                      <table className="products-table">
                        <tbody>
                          {visitDetails?.samples && visitDetails?.samples.length
                            ? visitDetails.samples.map((sample) => (
                                <tr>
                                  <td>{sample.product?.productName}</td>
                                  <td>{sample.quantity}</td>
                                </tr>
                              ))
                            : ""}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </section>
                {/* Objections */}
                <section
                  className="info-body__general"
                  style={{ flexDirection: "column" }}
                >
                  <div className="datails">
                    <div className="content">
                      <div style={{ marginBottom: "0.85rem" }}>Objections</div>
                    </div>
                  </div>
                  {divider("96%")}
                  <div style={{ paddingTop: "1rem" }}>
                    {visitDetails?.objections && visitDetails.objections.length
                      ? visitDetails.objections.map((objection) => (
                          <ObjectionCard
                            objection={objection}
                            key={objection.objectionId}
                          />
                        ))
                      : ""}
                  </div>
                </section>
                {/* Request */}
                <section
                  className="info-body__general"
                  style={{ flexDirection: "column" }}
                >
                  <div className="datails">
                    <div className="content">
                      <div style={{ marginBottom: "0.85rem" }}>Demandes</div>
                    </div>
                  </div>
                  {divider("96%")}
                  <div style={{ paddingTop: "1rem" }}>
                    {visitDetails?.requests && visitDetails?.requests.length
                      ? visitDetails.requests.map((req) => (
                          <RequestCard req={req} key={req.requestId} />
                        ))
                      : ""}
                  </div>
                </section>
                {/* Order */}
                {/* <section
                  className="info-body__general"
                  style={{ flexDirection: "column" }}
                >
                  <div className="datails">
                    <div className="content">
                      <div style={{ marginBottom: "0.85rem" }}>Commande</div>
                    </div>
                  </div>
                  {divider("96%")}
                </section> */}
               
              </div>
            </div>
          </DialogContent>
        ) : (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              height: "77vh",
              alignItems: "center",
            }}
          >
            <CircularProgress size={50} />
          </div>
        )}
      </CustomizedDialog>
    </div>
  );
};
