import React from "react";
import "./InboxList.scss";
import { PanelGroup } from "rsuite";
import NewMessageButton from "../../../02-molecules/inbox/NewMessageButton/NewMessageButton";
import InboxChannels from "./InboxChannels";
import InboxDirectMessages from "./InboxDirectMessages";

type InboxListProps = {};

const InboxList = (props: InboxListProps) => {
  return (
    <div className="phalcon-inbox__list phalcon-scroll-bar">
      <div className="phalcon-inbox__list--header">
        <div className="phalcon-inbox__list--title">Rx</div>
        <NewMessageButton />
      </div>

      <PanelGroup accordion bordered={false}>
        <InboxChannels />
        <InboxDirectMessages />
      </PanelGroup>
    </div>
  );
};

export default InboxList;
