import { mdiPencilOutline } from "@mdi/js";
import Icon from "@mdi/react";
import React from "react";
import "./NewMessageButton.scss";
type Props = {};

const NewMessageButton = (props: Props) => {
  return (
    <div className="new-message-button">
      <Icon path={mdiPencilOutline} size={1} />
    </div>
  );
};

export default NewMessageButton;
