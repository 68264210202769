//@ts-nocheck
import React, { useEffect, useState } from 'react'
import { useAppDispatch, useAppSelector } from '../../../redux/hooks'
import { fetchProducts } from '../../../redux/Products/Products'
import { Box, Grid } from '@material-ui/core';
import { DataGrid, GridCellParams, GridColDef } from "@material-ui/data-grid";
import "./productsStyle.scss"
import { SearchBar } from "../../02-molecules/searchBar/SearchBar"
import Button from '@material-ui/core/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faToggleOn, faToggleOff } from '@fortawesome/free-solid-svg-icons';
import AddForm from './formsAndPopups/AddForm';
import { ToggleProduct } from '../../../redux/Products/Products';
import localizedTextsMap from '../../../utils/dataGridColumnMenuLanguage';
import { mdiPlusCircleOutline } from '@mdi/js';

import Popup from './formsAndPopups/popup';
import Icon from '@mdi/react';


export default function Products() {
  const { products } = useAppSelector(state => state.prods)
  const dispatch = useAppDispatch()
  const [gridRows, setGridRows] = useState([])
  const [searchQuery, setSearchQuery] = useState('');
  const [showAddForm, setShowAddForm] = useState(false)
  const [showPopup, setshowPopup] = useState(false)
  const [productId, setProductId] = useState()

  useEffect(() => {
    dispatch(fetchProducts())
  }, [dispatch])

  useEffect(() => {
    const newGridRows = products.map((item: any, i) => ({
      productName: item.productName,
      id: item.productId,
      capacity: item.isVirtual ? "-" : item.capacity,
      isVirtual: item.isVirtual,
      priceHT: item.isVirtual ? "-" : item.priceHT,
      priceTTC: item.isVirtual ? "-" : item.priceTTC,
      productId: item.productId,
      reference: item.isVirtual || !item.reference ? "-" : item.reference,
      tva: item.isVirtual ? "-" : item.tva,
      toggle: item.status
    }));

    setGridRows(newGridRows)
  }, [products])

  const renderVirtualCell = (params: GridCellParams) => (
    <div style={{ color: params.value ? 'black' : 'black' }}>
      {params.value ? 'Action' : 'Produit'}
    </div>
  );
  const controlUpload = (id) => {
    setProductId(id)
    setshowPopup(true)
  }
  const renderToggleCell = (params: GridCellParams) => {
    // console.log(params)
    return (<div style={{ display: 'flex', justifyContent: 'flex-end' }}>
      {params.value === 1 ? <FontAwesomeIcon onClick={() => {
        dispatch(ToggleProduct({
          "productId": params.row.id,
          "status": params.value === 0 ? 1 : 0
        }))
      }} style={{ fontSize: '2em', color: "#20abe3" }} icon={faToggleOn} /> : <FontAwesomeIcon onClick={() => {
        dispatch(ToggleProduct({
          "productId": params.row.id,
          "status": params.value === 0 ? 1 : 0
        }))
      }} style={{ fontSize: '2em', color: "#20abe3", opacity: 0.5 }} icon={faToggleOff} />}
    </div>)
  };
  const renderCell = (params: GridCellParams) => {
    return (<><div style={{ display: 'flex', alignItems: "center", justifyContent: "center" }} ><span onClick={() => { controlUpload(params.row.id) }} style={{ cursor: "pointer" }}>Importer</span></div></>)
  };
  const handleSearchChange = (value: any) => {
    setSearchQuery(value);
  };

  const filteredRows = gridRows.filter(row => {
    return row.productName.toLowerCase().includes(searchQuery.toLowerCase());
  });

  const columns: GridColDef[] = [
    { field: 'productName', headerName: 'Nom', minWidth: 190, flex: 1 },
    { field: 'reference', headerName: 'Référence', width: 150 },
    {
      field: 'isVirtual',
      headerName: 'Action',
      width: 150,
      renderCell: renderVirtualCell,
    },
    { field: 'priceHT', headerName: 'Prix HT', width: 150 },
    { field: 'tva', headerName: 'TVA (%)', width: 150 },
    { field: 'priceTTC', headerName: 'Prix TTC', width: 150 },
    // { field: 'productId', headerName: 'ID', width: 180 },
    { field: 'capacity', headerName: 'Capacité', width: 150 },
    {
      field: 'toggle',
      headerName: 'Statut',
      width: 150,
      renderCell: renderToggleCell,
    },
    {
      field: 'Action',
      headerName: 'Action',
      width: 150,
      renderCell: renderCell,
    },
  ];


  return (
    <>
      {showPopup ? <> <Popup productId={productId} closeForm={setshowPopup} /> </> : null}
      {showAddForm ? <AddForm closeForm={setShowAddForm} /> : null}
      <Box sx={{ height: 600, width: "100%" }}>
        <Grid style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }} container >
          <SearchBar containerClass='productSearchBar' placeHolder='Recherche' setSearchTerm={handleSearchChange} />
          <Button onClick={() => setShowAddForm(true)} style={{ padding: 20, color: "white", width: "8rem", height: 10, marginRight: "1rem" }} variant="contained" size="large" color="primary" >
            ajouter <div style={{ display: "flex", justifyContent: "center", alignItems: "center", marginLeft: 7 }}><Icon path={mdiPlusCircleOutline} size={1.2} /></div>
          </Button>
        </Grid>
        {gridRows.length > 0 ? (
          <DataGrid
            rowsPerPageOptions={[5, 10, 20]}
            localeText={localizedTextsMap}
            style={{ backgroundColor: "white", marginRight: 12, marginLeft: 17 }}
            rows={filteredRows}
            columns={columns}
            autoPageSize={10}
            disableColumnMenu
          />
        ) : null}
      </Box>
    </>
  )
}
