import { API_DEV } from "../utils/apiRoute";
import { axiosPrivate } from "../utils/interceptors";

export const orderService = {
  getOrders: async (payload: any) => {
    try {
      const resp = await axiosPrivate.post(`${API_DEV}/Order/Search`, payload);
      return resp.data;
    } catch (error) {
      return error;
    }
  },
  getOrderDetails: async (id: number) => {
    try {
      const resp = await axiosPrivate.get(`${API_DEV}/Order/${id}`);
      return resp.data;
    } catch (error) {
      return error;
    }
  },
  getDeliveryDetails: async (id: number) => {
    try {
      const resp = await axiosPrivate.get(`${API_DEV}/Delivery/byorder/${id}`);
      return resp.data;
    } catch (error) {
      return error;
    }
  },
  getPaymentDetails: async (id: number) => {
    try {
      const resp = await axiosPrivate.get(`${API_DEV}/Payement/byOrder/${id}`);
      return resp.data;
    } catch (error) {
      return error;
    }
  },
  getOrderTracking: async (payload: any) => {
    try {
      const resp = await axiosPrivate.post(
        `${API_DEV}/Order/ordersTraking`,
        payload
      );
      return resp.data;
    } catch (error) {
      return error;
    }
  },
};
