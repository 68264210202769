import { mdiCalendar } from "@mdi/js";
import Icon from "@mdi/react";
import React, { useEffect, useState, useRef  } from "react";
import {
  AvatarGroup,
  Avatar,
  Whisper,
  Tooltip,
  Modal,
  List,
  FlexboxGrid,
  Col,
  Checkbox,
  Button,
} from "rsuite";
import { useAppDispatch } from "../../../redux/hooks";
import { prepareTarget } from "../../../redux/Target/targetSlice";
import {
  CyclesButton,
  ProfileButton,
  DeleteButton,
} from "../../01-atoms/Buttons/Buttons";
import { CustomAvatar } from "../../01-atoms/CustomAvatar/CustomAvatar";
import "./UserStack.scss";
export interface person {
  id: number;
  firstName: string;
  lastName: string;
  image: { src: string; alt: string };
  phone?: string;
  cycleCount?: number;
  planification: {
    planificationId: number | null;
    targetId: number | null;
  };
}
interface Props {
  persons: person[];
  checkable?: boolean;
  isCycle?: boolean;
  cycleId: number;
  modalTitle: string;
  removeAssociation?: (cycleId: number, userId: number) => void;
  goToTarget?: (cycleId: number, userId: number) => void;
  requestState?: "loading" | "idle";
}

export const UserStack = ({
  persons,
  checkable,
  isCycle,
  cycleId,
  modalTitle,
  removeAssociation,
  goToTarget,
  requestState,
}: Props) => {
  const dispatch = useAppDispatch();

  const [modalIsOpen, setModalIsOpen] = useState(false);
  const handlePlanification = (userId: number) => {
    dispatch(prepareTarget({ cycleId, userId }));
  };
  const planificationButtonRef = useRef<number | undefined>();
  
  useEffect(() => {
    setModalIsOpen(false);
  }, [cycleId]);
  return (
    <div className="user__stack__wrapper">
      <>
        {persons.length > 0 ? (
          <AvatarGroup stack onClick={() => setModalIsOpen(true)}>
            {persons
              .filter((person, i) => i < 3)
              .map((person: person, i) => (
                <Whisper
                  key={person.id}
                  trigger="hover"
                  placement={"topStart"}
                  speaker={
                    <Tooltip>{`${person.firstName} ${person.lastName}`}</Tooltip>
                  }
                >
                  <div style={{ lineHeight: "0" }}>
                    <CustomAvatar
                      style={{ width: "2rem", height: "2rem" }}
                      image={{
                        src: person.image.src,
                        alt: person.image.alt,
                      }}
                    />
                  </div>
                </Whisper>
              ))}
            {persons.length > 3 && (
              <Avatar size="sm" circle className="user-stack__count">
                +{persons.length - 3}
              </Avatar>
            )}
          </AvatarGroup>
        ) : (
          <Avatar size="sm" circle className="user-stack__count">
            0
          </Avatar>
        )}
        <Modal
          size="lg"
          open={modalIsOpen}
          onClose={() => setModalIsOpen(false)}
        >
          <Modal.Header>
            <Modal.Title>{modalTitle}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <List hover>
              {persons.map((person: person, i: number) => (
                <List.Item key={person.id}>
                  <FlexboxGrid align="middle">
                    {checkable && (
                      <Col xs={1} className="checkbox-area">
                        <Checkbox
                          checked={false}
                          indeterminate={false}
                          onChange={() => console.log("checked !!!!!")}
                        />
                      </Col>
                    )}
                    <Col
                      md={checkable ? 4 : 5}
                      className="user-associated-person-area"
                    >
                      <div className="user-associated-person-area__inner">
                        <CustomAvatar
                          image={person.image}
                          style={{ width: "25px", height: "25px" }}
                        />
                        <div className="fullName">
                          {person.firstName + " " + person.lastName}
                        </div>
                      </div>
                    </Col>
                    <Col xs={4} className="user-associated-phone-area">
                      {person.phone || "-"}
                    </Col>
                    <Col xs={15} className="user-associated-buttons-area">
                      <div className="user-associated-buttons-area__inner">
                        {isCycle ? (
                          <>
                            <Button
                              className="planification-button"
                              type="submit"
                              appearance="primary"
                              onClick={() =>{ planificationButtonRef.current = person.id ; handlePlanification(person.id)}}
                              loading={planificationButtonRef.current === person.id && requestState === "loading"}
                              id={`planification-button-${person.id}`}
                            >
                              <span>Planification</span> 
                              <Icon path={mdiCalendar} size={0.75} />
                            </Button>
                            <CyclesButton number={person.cycleCount || 0} /> 
                            <ProfileButton to={`/user/${person.id}`} />
                            <DeleteButton
                              onClick={() =>
                                removeAssociation &&
                                removeAssociation(cycleId, person.id)
                              }
                            />
                          </>
                        ) : (
                          <ProfileButton to={`/user/${person.id}`} />
                        )}
                      </div>
                    </Col>
                  </FlexboxGrid>
                </List.Item>
              ))}
            </List>
          </Modal.Body>
        </Modal>
      </>
    </div>
  );
};
