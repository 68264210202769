import { createSlice } from "@reduxjs/toolkit";
import { axiosPrivate } from "../../utils/interceptors";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { Authorization } from "../../types/Authorization.model";
import { API_DEV } from "../../utils/apiRoute";

export interface AuthorizationState {
  requestState: "loading" | "idle";
  authorizations: Authorization[];
  authorizationById: Authorization | null;
  errors: any;
}

export const addAuthorization = createAsyncThunk(
  "[Authorization]_add_",
  async (payload: any, { rejectWithValue }) => {
    try {
      let response = await axiosPrivate.post(
        `${API_DEV}/UnlockAuthorization`,
        payload
      );
      return response.data;
    } catch (error) {
      console.log(JSON.parse(JSON.stringify(error)));
      return rejectWithValue(JSON.parse(JSON.stringify(error)));
    }
  }
);

export const fetchAuthorizations = createAsyncThunk(
  "[Authorization]_fetchAll_",
  async (_, { rejectWithValue }) => {
    try {
      let response = await axiosPrivate.get(`${API_DEV}/UnlockAuthorization`);
      return response.data;
    } catch (error) {
      console.log(JSON.parse(JSON.stringify(error)));
      return rejectWithValue(JSON.parse(JSON.stringify(error)));
    }
  }
);

export const fetchAuthorizationById = createAsyncThunk(
  "[Authorization]_fetchById_",
  async (id: number, { rejectWithValue }) => {
    try {
      let response = await axiosPrivate.get(
        `${API_DEV}/UnlockAuthorization/${id}`
      );
      return response.data;
    } catch (error) {
      console.log(JSON.parse(JSON.stringify(error)));
      return rejectWithValue(JSON.parse(JSON.stringify(error)));
    }
  }
);

export const activeAuthorozation = createAsyncThunk(
  "[Authorization]_active_",
  async (id: number, { rejectWithValue }) => {
    try {
      let response = await axiosPrivate.put(
        `${API_DEV}/UnlockAuthorization/Active/${id}`
      );
      return response.data;
    } catch (error) {
      console.log(JSON.parse(JSON.stringify(error)));
      return rejectWithValue(JSON.parse(JSON.stringify(error)));
    }
  }
);

export const deactiveAuthorozation = createAsyncThunk(
  "[Authorization]_deactive_",
  async (id: number, { rejectWithValue }) => {
    try {
      let response = await axiosPrivate.put(
        `${API_DEV}/UnlockAuthorization/Inactive/${id}`
      );
      return response.data;
    } catch (error) {
      console.log(JSON.parse(JSON.stringify(error)));
      return rejectWithValue(JSON.parse(JSON.stringify(error)));
    }
  }
);
const initialState: AuthorizationState = {
  requestState: "idle",
  authorizations: [],
  authorizationById: null,
  errors: null,
};

export const authorizationSlice = createSlice({
  name: "[Authorization]",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(addAuthorization.pending, (state) => {
      state.requestState = "loading";
      state.errors = null;
    });
    builder.addCase(addAuthorization.fulfilled, (state) => {
      state.requestState = "idle";
      state.errors = null;
    });
    builder.addCase(addAuthorization.rejected, (state, { payload }) => {
      state.requestState = "idle";
      state.errors = payload;
    });
    builder.addCase(fetchAuthorizationById.pending, (state) => {
      state.requestState = "loading";
      state.errors = null;
    });
    builder.addCase(fetchAuthorizationById.fulfilled, (state, { payload }) => {
      state.requestState = "idle";
      state.authorizationById = payload;
      state.errors = null;
    });
    builder.addCase(fetchAuthorizationById.rejected, (state, { payload }) => {
      state.requestState = "idle";
      state.errors = payload;
    });
    builder.addCase(fetchAuthorizations.pending, (state) => {
      state.requestState = "loading";
      state.errors = null;
    });
    builder.addCase(fetchAuthorizations.fulfilled, (state, { payload }) => {
      state.requestState = "idle";
      state.authorizations = payload;
      state.errors = null;
    });
    builder.addCase(fetchAuthorizations.rejected, (state, { payload }) => {
      state.requestState = "idle";
      state.errors = payload;
    });
    builder.addCase(activeAuthorozation.pending, (state) => {
      state.requestState = "loading";
      state.errors = null;
    });
    builder.addCase(activeAuthorozation.fulfilled, (state, { payload }) => {
      state.requestState = "idle";
      state.errors = null;
    });
    builder.addCase(activeAuthorozation.rejected, (state, { payload }) => {
      state.requestState = "idle";
      state.errors = payload;
    });
    builder.addCase(deactiveAuthorozation.pending, (state) => {
      state.requestState = "loading";
      state.errors = null;
    });
    builder.addCase(deactiveAuthorozation.fulfilled, (state, { payload }) => {
      state.requestState = "idle";
      state.errors = null;
    });
    builder.addCase(deactiveAuthorozation.rejected, (state, { payload }) => {
      state.requestState = "idle";
      state.errors = payload;
    });
  },
});

export default authorizationSlice.reducer;
