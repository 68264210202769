import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { useRoutingConfig } from "../../../utils/useRoutingConfig";
import { Header } from "../../05-layouts/Header/Header";
import "./ValidationPage.scss";
type Props = {};

const ValidationPage = (props: Props) => {
  const { t } = useTranslation();
  const headerLeftItem = <h2>{t("validation_page.title")}</h2>;
  const currentRoute = useRoutingConfig().find((e) => e.key === "9");
  const subRoutes =
    typeof currentRoute !== "undefined" ? currentRoute.children : [];
  const { pathname } = useLocation();
  const navigate = useNavigate();
  useEffect(() => {
    pathname === "/validation" && navigate("/validation/doctors");
  }, [navigate, pathname]);
  return (
    <div>
      <>
        <Header subRoutes={subRoutes} leftItem={headerLeftItem} />
        <Outlet />
      </>
    </div>
  );
};

export default ValidationPage;
