import React, { useEffect, useMemo } from "react";
import {
  mdiBookmark,
  mdiEmail,
  mdiMapMarker,
  mdiMedicalBag,
  mdiPhone,
  mdiMapMarkerPlus,
  mdiPound,
  mdiInformationVariant,
  mdiHumanQueue,
} from "@mdi/js";
import Icon from "@mdi/react";
import "../../../04-templates/validation/validation-requests/doctors/DoctorValidationRequest.scss";
import { Doctor } from "../../../../types/Doctor.model";
import { Phone } from "../../../../types/Phone.model";
import { Tag } from "../../../../types/Tag.model";
import { AdditionalInfo } from "../../../../types/AdditionalInfo.model";
import { addressString, doctorPhones } from "../../../../helpers/dataFormator";

type Props = {
  updateRequestClassName?: { inner: string; top: string; cardWidth: string };
  updateCopyClassName?: string;
  title: string;
  doctor?: Doctor;
};

interface Location {
  facility: string;
  facilityType: string;
  address: string;
}

const RequestValidationCard = ({
  updateRequestClassName,
  updateCopyClassName,
  title,
  doctor,
}: Props) => {
  const getDoctorFullName = useMemo(() => {
    return doctor
      ? doctor.title + ". " + doctor.firstName + " " + doctor.lastName
      : "-";
  }, [doctor]);
  const getSpecialities = useMemo(() => {
    return doctor
      ? doctor.specialities.map((spec) => spec.specialityName).join(" - ")
      : "-";
  }, [doctor]);

  const getBusinessUnits = useMemo(() => {
    return doctor && doctor.businessUnits && doctor.businessUnits.length > 0
      ? doctor.businessUnits.map((bu) => bu.businessUnitName).join(" ")
      : "-";
  }, [doctor]);

  const potential: string = useMemo(() => {
    return doctor?.potential?.potentialName || "-";
  }, [doctor]);

  const primaryLocation: Location = useMemo(() => {
    const primaryOffice = doctor?.offices.find((e) => e.isPrimary);
    const primaryFacility = doctor?.facilities.find((e) => e.isPrimaryFacility);

    let result = {
      facility: "",
      facilityType: "",
      address: "",
    };
    if (primaryOffice) {
      result = {
        facility: "Cabinet",
        facilityType: "Cabinet",
        address: addressString(
          primaryOffice?.address?.localities || null,
          primaryOffice?.address?.streetName || ""
        ),
      };
    }

    if (primaryFacility) {
      result = {
        facility: primaryFacility.facilityName,
        facilityType: primaryFacility.facilityType.facilityTypeName,
        address: addressString(
          primaryFacility?.address?.localities || null,
          primaryFacility?.address?.streetName || ""
        ),
      };
    }
    return result;
  }, [doctor]);

  const otherLocations: Location[] = useMemo(() => {
    const otherOffices = doctor?.offices
      .filter((office) => !office.isPrimary)
      .map((office) => ({
        facility: "Cabinet",
        facilityType: "Cabinet",
        address: addressString(
          office?.address?.localities || null,
          office?.address?.streetName || ""
        ),
      }));
    const otherFacilities = doctor?.facilities
      .filter((facility) => !facility.isPrimaryFacility)
      .map((facility) => ({
        facility: facility.facilityName,
        facilityType: facility.facilityType.facilityTypeName,
        address: addressString(
          facility?.address?.localities || null,
          facility?.address?.streetName || ""
        ),
      }));
    let result: Location[] = [];
    if (otherOffices && otherOffices.length > 0) {
      result = result.concat(otherOffices);
    }
    if (otherFacilities && otherFacilities.length > 0) {
      result = result.concat(otherFacilities);
    }
    return result;
  }, [doctor]);

  const phones: Phone[] = useMemo(() => {
    return doctorPhones(doctor?.phones || [], doctor?.offices || []);
  }, [doctor]);

  const patientPerDer: number = useMemo(() => {
    return doctor?.patientsPerDay || 0;
  }, [doctor]);

  const tags: Tag[] = useMemo(() => {
    return doctor?.tags || [];
  }, [doctor]);

  const additionalInfos: AdditionalInfo[] = useMemo(() => {
    return doctor?.additionalInfos || [];
  }, [doctor]);

  // const portfolio = useMemo(() => {
  //   return doctor?.inPortfolioOf.map((deleg) => ({
  //     fullName: deleg.firstName + " " + deleg.lastName,
  //     photoUrl: deleg.photoUrl,
  //   }));
  // }, [doctor]);

  useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [otherLocations]);

  return (
    <div
      className={`doctor-validation-request ${updateRequestClassName?.cardWidth} `}
    >
      <div className="doctor-validation-request__title">{title}</div>
      <div className="doctor-validation-request__content">
        <div className={`content-inner ${updateCopyClassName}`}>
          {/* client name and address */}
          <section
            className={`inner-top ${updateRequestClassName?.top}`}
            style={{ flexWrap: "wrap" }}
          >
            <div className="client-info-standard">
              <div className="icon icon icon-with-border">
                <Icon path={mdiMedicalBag} size={1} />
              </div>
              <div className="client-info">
                <div className="top">{getDoctorFullName}</div>
                <span className="bottom">
                  {getSpecialities} | {getBusinessUnits}
                </span>
              </div>
            </div>
            <div
              className={`client-info-standard customized-width ${updateRequestClassName?.top}`}
            >
              <div className="icon icon icon-with-border">
                <Icon path={mdiMapMarker} size={1} />
              </div>
              <div className="client-info">
                <div className="top">
                  {primaryLocation.facility !== primaryLocation.facilityType
                    ? primaryLocation.facility +
                      " (" +
                      primaryLocation.facilityType +
                      ")"
                    : primaryLocation.facility}
                </div>
                <span className="bottom">{primaryLocation.address}</span>
              </div>
            </div>
          </section>
          <section className={`inner-middle ${updateRequestClassName?.inner}`}>
            <div className="inner-middle__left">
              {/* mail section */}
              <div className="inner-element-box">
                <div className="client-info-standard">
                  <div className="icon icon icon-with-border">
                    <Icon path={mdiHumanQueue} size={1} />
                  </div>
                  <div className="client-info">
                    <div className="top">
                      {" "}
                      {patientPerDer + ` Patient(s) par jour -`}{" "}
                      <span
                        className={`top pot-${potential}`}
                        style={{ paddingLeft: "0.3rem" }}
                      >
                        {" "}
                        {potential}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              {/* phone section */}
              <div className="inner-element-box">
                <div className="client-info-standard">
                  <div className="icon icon-with-border">
                    <Icon path={mdiPhone} size={1} />
                  </div>
                  <div className="client-info">
                    <div className="top"> Numéros de téléphone</div>
                  </div>
                </div>
                {phones &&
                  phones.length > 0 &&
                  phones.map((phone) => (
                    <div className="phone-section" key={phone.phoneId}>
                      <div className="client-info">
                        <div className="top">
                          {phone.phoneNumber}
                          {phone.isPrincipal && (
                            <Icon
                              path={mdiBookmark}
                              size={0.6}
                              color={"#20ABE3"}
                              style={{ marginLeft: "0.7rem" }}
                            />
                          )}
                        </div>
                        <div className="bottom ">{phone.phoneDescription}</div>
                      </div>
                    </div>
                  ))}
              </div>
              {/* mail section */}
              <div className="inner-element-box">
                <div className="client-info-standard">
                  <div className="icon icon icon-with-border">
                    <Icon path={mdiEmail} size={1} />
                  </div>
                  <div className="client-info">
                    <div className="top"> {doctor?.email || "-"}</div>
                  </div>
                </div>
              </div>
              {/* address section */}
              <div className="inner-element-box">
                <div className="client-info-standard info-with-margin">
                  <div className="icon icon icon-with-border">
                    <Icon path={mdiMapMarkerPlus} size={1} />
                  </div>
                  <div className="client-info ">
                    <div className="top"> Autre Address</div>
                  </div>
                </div>
                {otherLocations && otherLocations.length > 0 ? (
                  otherLocations.map((location, index) => (
                    <div className="validation-address-info" key={index}>
                      <div className="top">
                        {location.facility !== location.facilityType
                          ? location.facility +
                            " (" +
                            location.facilityType +
                            ")"
                          : location.facility}
                      </div>
                      <div className="bottom">{location.address}</div>
                    </div>
                  ))
                ) : (
                  <div className="validation-address-info">
                    <div className="top">Aucune autre adresse</div>
                    <div className="middle"></div>
                    <div className="bottom"></div>
                  </div>
                )}
              </div>
            </div>
            <div
              className={`inner-middle__right ${updateRequestClassName?.inner}`}
            >
              {/* tags */}
              <div className="inner-element-box">
                <div className="client-info-standard">
                  <div className="icon icon-with-border">
                    <Icon path={mdiPound} size={1} />
                  </div>
                  <div className="client-info">
                    <div className="top">Tags</div>
                  </div>
                </div>
                <div className="validation-tags">
                  {tags && tags.length > 0 ? (
                    tags.map((tag) => (
                      <div className="tags-style" key={tag.tagId}>
                        {tag.tagName}
                      </div>
                    ))
                  ) : (
                    <div className="validation-address-info">
                      <div className="top">Aucun tag</div>
                    </div>
                  )}
                </div>
              </div>
              {/* additional Info */}
              <div className="inner-element-box">
                <div className="client-info-standard info-with-margin">
                  <div className="icon icon-with-border">
                    <Icon path={mdiInformationVariant} size={1} />
                  </div>
                  <div className="client-info ">
                    <div className="top">Informations complémentaire</div>
                  </div>
                </div>
                {additionalInfos && additionalInfos.length > 0 ? (
                  additionalInfos.map((info) => (
                    <div
                      className="validation-additional-info"
                      key={info.additionalInfoId}
                    >
                      {info.label + " : "}
                      {info.description}
                    </div>
                  ))
                ) : (
                  <div className="validation-address-info">
                    <div className="top" style={{ marginLeft: "3.6rem" }}>
                      Aucune information
                    </div>
                  </div>
                )}
              </div>
              {/* asigned delegate */}
              {/* {title !== "Demande de modification" ? (
                <div className="inner-element-box">
                  <div className="client-info-standard info-with-margin">
                    <div className="icon icon-with-border">
                      <Icon path={mdiBadgeAccountOutline} size={1} />
                    </div>
                    <div className="client-info ">
                      <div className="top">Assigné à</div>
                    </div>
                  </div>
                  <div className="validation-assignedTo">
                    {portfolio &&
                      portfolio.length > 0 &&
                      portfolio.map((deleg, index) => (
                        <div className="user-info" key={index}>
                          <CustomAvatar
                            image={{
                              src: `${deleg?.photoUrl || ""}`,
                              alt: `${deleg?.fullName || ""}`,
                            }}
                            style={{ width: "25px", height: "25px" }}
                          />
                          <div>{deleg.fullName}</div>
                        </div>
                      ))}
                  </div>
                </div>
              ) : (
                ""
              )} */}
            </div>
          </section>
        </div>
      </div>
    </div>
  );
};

export default RequestValidationCard;
