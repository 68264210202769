import React from "react";
import { Tag } from "rsuite";
import { BusinessUnit } from "../../../../../types/BusinessUnit.model";

type Props = {
  item: any;
};

const BusinessUnitsCell = ({ item }: Props) => {
  const businessUnits: BusinessUnit[] = JSON.parse(item);

  return (
    <div className="phalcon-businessUnits-cell">
      {businessUnits.map((e) => (
        <Tag size="lg" key={e.businessUnitId}>
          {e.businessUnitName}
        </Tag>
      ))}
    </div>
  );
};

export default BusinessUnitsCell;
