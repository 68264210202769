import React, { useEffect, useState, createRef } from "react";
import {
  Input,
  InputGroup,
  Loader,
  Whisper,
  Tooltip,
  Tag,
  Pagination,
  CheckPicker,
} from "rsuite";
import Icon from "@mdi/react";
import { mdiMagnify, mdiPlusCircleOutline, mdiTune } from "@mdi/js";
import { useHandleResize } from "../../../../utils/useHandleResize";
import { FilterTagItem, useFilter } from "../../../../helpers/useFilter";
import { CustomButton } from "../../../01-atoms/Buttons/Buttons";
import { FilterDrawer } from "../../filter/FilterDrawer/FilterDrawer";
import TablePhalcon from "../common/TablePhalcon";
import useSellerTable from "./useSellerTable";
import SellerForm from "../../forms/seller/SellerForm";

interface Props {}
type Column = {
  key: string;
  label: string;
  width: number;
  fixed: boolean;
  type: string;
};
const SellerTable = (props: Props) => {
  const dataBaseContext = "seller";
  const deviceSize = useHandleResize();
  const {
    filtersDisplayData,
    filterState,
    filterTagsArray,
    selectedFilters,
    captureFilterInputs,
    captureSearchText,
    captureSelectedFilters,
    capturePageTarget,
    captureLimitTarget,
    resetSelectedFilters,
    captureOrderBy,
  } = useFilter({
    dataBaseContext,
  });

  const {
    data,
    columns,
    dataResquestState,
    errors,
    currentPageNumber,
    pageSize,
    totalCount,
    filterDrawerIsOpen,
    toggleFilterDrawer,
    toggleAddDrawer,
  } = useSellerTable();

  const [searchTerm, setSearchTerm] = useState("");
  const [searchFieldIsLoading, setSearchFieldIsLoading] = useState(false);

  useEffect(() => {
    setSearchFieldIsLoading(true);
    const delayDebounceFn = setTimeout(() => {
      captureSearchText(searchTerm);
    }, 500);
    return () => {
      clearTimeout(delayDebounceFn);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchTerm]);

  useEffect(() => {
    if (dataResquestState === "idle" && !errors) setSearchFieldIsLoading(false);
  }, [dataResquestState, errors]);

  const [columnsOptions, setColumnsOptions] = useState<Column[]>([]);
  const [columnKeys, setColumnKeys] = React.useState<string[]>([]);

  const columnsToShow = columns.filter((column) =>
    columnKeys.some((key) => key === column.key)
  );
  const colPickerRef: any = createRef();

  useEffect(() => {
    setColumnsOptions(columns);
    let defaultColsKeys = columns
    .filter((col) => col.checked)
    .map((column) => column.key);
    setColumnKeys(defaultColsKeys);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="database-template__wrapper">
      <div className="top-area">
        <div className="top-area__right">
          <InputGroup inside className="database-search-input-group">
            <Input
              size="lg"
              type="text"
              name="search"
              placeholder="Recherche"
              onChange={(value) => setSearchTerm(value)}
              className="database-search-input"
            />
            <InputGroup.Addon>
              {searchFieldIsLoading ? (
                <Loader />
              ) : (
                <Icon path={mdiMagnify} size={1} />
              )}
            </InputGroup.Addon>
          </InputGroup>

          <CustomButton
            text={["Mobile", "Tablet"].includes(deviceSize) ? "" : "Filtrer"}
            icon={<Icon path={mdiTune} size={1} />}
            onClick={() => toggleFilterDrawer()}
            color={"customized"}
          />
          <CustomButton
            text={["Mobile", "Tablet"].includes(deviceSize) ? "" : "Ajouter"}
            icon={<Icon path={mdiPlusCircleOutline} size={1} />}
            onClick={() => toggleAddDrawer()}
            color={"customized"}
          />
        </div>
        <div className="top-area__left">
          <div className="filter-tags__area">
            <div className="filter-tags__title">Filtres Appliqués</div>
            {filterTagsArray.length === 0 && (
              <span className="empty-tags-message">Aucun filtre appliqué</span>
            )}

            {Object.values(filterState).map((filtersArr) => {
              if (filtersArr.length > 0) {
                return (
                  <Whisper
                    trigger="hover"
                    placement={"bottom"}
                    speaker={
                      <Tooltip>
                        <ul>
                          {filtersArr.map(
                            (item: FilterTagItem, index: number) => (
                              <li key={index}>{item.label}</li>
                            )
                          )}
                        </ul>
                      </Tooltip>
                    }
                  >
                    <Tag size="lg" onClick={() => toggleFilterDrawer()}>
                      {filtersArr[0].type === "locality"
                        ? "Localité"
                        : filtersArr[0].type === "facilityType"
                        ? "Type d'établissement"
                        : filtersArr[0].type === "service"
                        ? "Services"
                        : filtersArr[0].type === "potential"
                        ? "Potentiels"
                        : filtersArr[0].type === "tag"
                        ? "Tags"
                        : filtersArr[0].type === "businessUnit"
                        ? "Business Units"
                        : filtersArr[0].type === "speciality"
                        ? "Spécialités"
                        : filtersArr[0].type === "facility"
                        ? "Etablissements"
                        : filtersArr[0].type === "sellerType"
                        ? "Type"
                        : filtersArr[0].type === "shift"
                        ? "Shift"
                        : ""}
                      <span className="selected-tags-count">{` (${filtersArr.length})`}</span>
                    </Tag>
                  </Whisper>
                );
              } else return null;
            })}
          </div> 
        </div>
      </div>
      <div className="pagination-area">
        <Pagination
          prev
          next
          first
          last
          ellipsis
          boundaryLinks
          style={{zIndex: "99"}} 
          size="sm"
          layout={["total", "-", "limit", "|", "pager", "skip"]}
          total={totalCount}
          limitOptions={[10, 50, 100]}
          limit={pageSize}
          activePage={currentPageNumber}
          onChangePage={capturePageTarget}
          onChangeLimit={captureLimitTarget}
        />
      </div>
      <div className="table-area">
        <div className="col-selecter">
          <div ref={colPickerRef} className="col-selecter--input">
            <CheckPicker
              sticky
              menuMaxHeight={100}
              name="colPicker"
              data={columnsOptions}
              labelKey="label"
              valueKey="key"
              value={columnKeys}
              groupBy="role"
              onChange={setColumnKeys}
              cleanable={false}
              container={() => colPickerRef.current}
              style={{ width: "100%" }}
            />
          </div>
        </div>
        <TablePhalcon
          data={data}
          dataBaseContext={dataBaseContext}
          columns={columnsToShow}
          dataResquestState={dataResquestState}
          captureOrderBy={captureOrderBy}
        />
      </div>
      <SellerForm formContext="add" />
      <SellerForm formContext="update" />
      <FilterDrawer
        isOpen={filterDrawerIsOpen}
        onClose={() => toggleFilterDrawer()}
        filtersDisplayData={filtersDisplayData}
        captureFilterInputs={captureFilterInputs}
        captureSelectedFilters={captureSelectedFilters}
        selectedFilters={selectedFilters}
        dataBaseContext={dataBaseContext}
        resetSelectedFilters={resetSelectedFilters}
      />
    </div>
  );
};

export default SellerTable;
