import { DoctorSummary } from "./../../types/DoctorSummary.model";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { API_DEV } from "../../utils/apiRoute";
import { axiosPrivate } from "../../utils/interceptors";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { Planification, UpdatePlanification } from "../../types/Planification.model";

export interface PlanificationState {
  requestState: "loading" | "idle";
  planifications: Planification[];
  planificationByUserIdAndCycleId: Planification | null;
  planificationById: Planification | null;
  planificationByIdEdit: Planification[];
  planificationModalIsOpen: boolean;
  planificationConfigModalIsOpen: boolean;
  activeUpdatedPlanificationState: Planification | null;
  assignPlanificationDone: boolean;
  selectedDoctors: DoctorSummary[];
  clipBoard: DoctorSummary[];
  undoIsActive: boolean;
  redoIsActive: boolean;
  activeUpdatedPlanificationStateIndex: number;
  errors: any;
}

export const fetchPlanificationById = createAsyncThunk(
  "[Planifications]__fetchPlanificationById__",
  async (id: string, { rejectWithValue }) => {
    try {
      const response = await axiosPrivate.get(`${API_DEV}/Planification/${id}`);
      return response.data;
    } catch (error) {
      return rejectWithValue(JSON.parse(JSON.stringify(error)));
    }
  }
);

export const createPlanification = createAsyncThunk(
  "[Planifications]__createPlanification__",
  async (
    payload: {
      targetId: number;
      startDate: Date | null;
      endDate: Date | null;
      nbrOccurence: number | null;
    },
    { rejectWithValue }
  ) => {
    try {
      const response = await axiosPrivate.post( 
        `${API_DEV}/Planification?withResult=true`,
        payload
      );
      return response.data;
    } catch (err:any) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const updatePlanification = createAsyncThunk(
  "[Planifications]_update_",
  async (
    payload: UpdatePlanification,
    { rejectWithValue }
  ) => {
    try {
      let response = await axiosPrivate.put(
        `${API_DEV}/Planification?withResult=true`,
        payload
      );
      return response.data;
    } catch (err:any) {
     
      return rejectWithValue(err.response.data);
    }
  }
);
const minIndex = 0;
const maxIndex = 9;
const initialState: PlanificationState = {
  requestState: "idle",
  planificationModalIsOpen: false,
  planifications: [],
  planificationByUserIdAndCycleId: null,
  planificationById: null,
  activeUpdatedPlanificationState: null,
  planificationByIdEdit: [],
  undoIsActive: false,
  redoIsActive: false,
  errors: null,
  planificationConfigModalIsOpen: false,
  activeUpdatedPlanificationStateIndex: 0,
  assignPlanificationDone: false,
  selectedDoctors: [],
  clipBoard: [],
};

export const planificationSlice = createSlice({
  name: "[Planifications]",
  initialState,
  reducers: {
    togglePlanificationModal: (state) => {
      state.planificationModalIsOpen = !state.planificationModalIsOpen;
    },
    closePlanificationModal: (state) => {
      state.planificationModalIsOpen = false;
    },
    selectDoctors: (state, action: PayloadAction<DoctorSummary[]>) => {
      state.selectedDoctors = action.payload;
    },
    copyDoctors: (state, action: PayloadAction<DoctorSummary[]>) => {
      state.assignPlanificationDone = false;
      state.clipBoard = action.payload;
    },
    applyPlanificationChange: (state, action: PayloadAction<Planification>) => {
      if (state.activeUpdatedPlanificationStateIndex < maxIndex) {
        state.planificationByIdEdit.push(action.payload);
        state.activeUpdatedPlanificationStateIndex++;
      } else {
        state.planificationByIdEdit.shift();
        state.planificationByIdEdit.push(action.payload);
        state.activeUpdatedPlanificationStateIndex++;
      }
      state.assignPlanificationDone = false;
      state.activeUpdatedPlanificationState = action.payload;
      state.undoIsActive =
        state.activeUpdatedPlanificationStateIndex > minIndex;
    },
    undoPlanificationChange: (state) => {
      if (state.activeUpdatedPlanificationStateIndex > minIndex) {
        state.activeUpdatedPlanificationState =
          state.planificationByIdEdit[
            state.activeUpdatedPlanificationStateIndex - 1
          ];
        state.activeUpdatedPlanificationStateIndex--;
      }
      state.assignPlanificationDone = false;
      state.undoIsActive =
        state.activeUpdatedPlanificationStateIndex > minIndex;

      state.redoIsActive =
        state.activeUpdatedPlanificationStateIndex < maxIndex;
    },
    redoPlanificationChange: (state) => {
      if (state.activeUpdatedPlanificationStateIndex < maxIndex) {
        state.activeUpdatedPlanificationState =
          state.planificationByIdEdit[
            state.activeUpdatedPlanificationStateIndex + 1
          ];
        state.activeUpdatedPlanificationStateIndex++;
      }
      state.assignPlanificationDone = false;
      state.redoIsActive =
        state.activeUpdatedPlanificationStateIndex <
        state.planificationByIdEdit.length - 1;

      state.undoIsActive =
        state.activeUpdatedPlanificationStateIndex > minIndex;
    },
    resetPlanificationState: (state) => {
      state.assignPlanificationDone = false;
      state.activeUpdatedPlanificationState = state.planificationById;
      state.planificationByIdEdit = state.planificationById
        ? [state.planificationById]
        : [];
      state.undoIsActive =
        state.activeUpdatedPlanificationStateIndex > minIndex;
    },
    togglePlanificationConfigModal: (state) => {
      state.planificationConfigModalIsOpen =
        !state.planificationConfigModalIsOpen;
    },
    closePlanificationConfigModal: (state) => {
      state.planificationConfigModalIsOpen = false;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchPlanificationById.pending, (state) => {
      state.requestState = "loading";
      state.errors = null;
    });
    builder.addCase(fetchPlanificationById.fulfilled, (state, { payload }) => {
      state.requestState = "idle";
      state.planificationById = payload;
      state.planificationByIdEdit = [payload];
      state.activeUpdatedPlanificationState = payload;
      state.errors = null;
    });
    builder.addCase(fetchPlanificationById.rejected, (state, { payload }) => {
      state.requestState = "idle";
      state.errors = payload;
    });
    builder.addCase(updatePlanification.pending, (state) => {
      state.requestState = "loading";
      state.errors = null;
    });
    builder.addCase(updatePlanification.fulfilled, (state, { payload }) => {
      state.requestState = "idle";
      state.errors = null;
    });
    builder.addCase(updatePlanification.rejected, (state, { payload }) => {
      state.requestState = "idle";
      state.errors = payload;
    });
    builder.addCase(createPlanification.pending, (state) => {
      state.requestState = "loading";
      state.assignPlanificationDone = false;
      state.errors = null;
    });
    builder.addCase(createPlanification.fulfilled, (state, { payload }) => {
      state.requestState = "idle";
      state.assignPlanificationDone = true;
      state.planificationById = payload;
      state.errors = null;
    });
    builder.addCase(createPlanification.rejected, (state, { payload }) => {
      state.requestState = "idle";
      state.assignPlanificationDone = false;
      state.errors = payload;
    });
  },
});

export const {
  togglePlanificationModal,
  togglePlanificationConfigModal,
  selectDoctors,
  copyDoctors,
  closePlanificationModal,
  closePlanificationConfigModal,
  applyPlanificationChange,
  undoPlanificationChange,
  redoPlanificationChange,
  resetPlanificationState,
} = planificationSlice.actions;

export default planificationSlice.reducer;
