import jwtDecode from "jwt-decode";
import React, { useEffect } from "react";
import { Outlet, useLocation, useNavigate, useParams } from "react-router-dom";
import { useAppDispatch } from "../../../redux/hooks";
import { fetchDelegateById } from "../../../redux/User/userSlice";
import User from "../../../types/User.model";
import { isManager } from "../../../utils/enumHelpers";
import { getAccessToken } from "../../../utils/currentUserToken";

import { useHandleResize } from "../../../utils/useHandleResize";
import { useRoutingConfig } from "../../../utils/useRoutingConfig";
import { Header } from "../../05-layouts/Header/Header";
import "./UserPage.scss";

const UserProfileInfo = React.lazy(() =>
  import("../../04-templates/profile/profile-infos/UserProfileInfo").then(
    (module) => ({
      default: module.UserProfileInfo,
    })
  )
);
const ProfileHeader = React.lazy(() =>
  import("../../03-organisms/user-account/profile-header/ProfileHeader").then(
    (module) => ({
      default: module.ProfileHeader,
    })
  )
);
const UpdatePhotoModal = React.lazy(() =>
  import(
    "../../03-organisms/user-account/update-photo-modal/UpdatePhotoModal"
  ).then((module) => ({
    default: module.UpdatePhotoModal,
  }))
);
interface Props {
  user: User | null;
}
export const UserPage = ({ user }: Props) => {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const { id } = useParams();
  const dispatch = useAppDispatch();

  //Responsiveness variables
  const deviceSize = useHandleResize();
  const isMobile = deviceSize === "Mobile";
  const isTablet = deviceSize === "Tablet";
  const isDesktop = !["Mobile", "Tablet"].includes(deviceSize);

  // Role

  //Sub Routes
  const routeKey = isManager(user) ? "12" : "13";
  const currentRoute = useRoutingConfig().find((e) => e.key === routeKey);
  const generalSubRoutes =
    typeof currentRoute !== "undefined" ? currentRoute.children : [];
  const subRoutes =
    isMobile || isTablet
      ? generalSubRoutes.filter((e) => e.path !== "settings")
      : generalSubRoutes.filter((e) => e.path !== "infos");

  useEffect(() => {
    const abortController = new AbortController();

    id &&
      dispatch(
        fetchDelegateById({ id: parseInt(id), signal: abortController.signal })
      );

    return () => {
      abortController.abort();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const manageUserPageRoutes = (deviceSize: string, path: string) => {
    const showCurrentUserInfos = isMobile && path === "/account";
    const showUserInfos = isMobile && path === `/user/${id}`;
    const showTeams =
      (isDesktop && path === "/account") ||
      (isDesktop && path === "/account/infos");

    const showUserAgenda = !isMobile && path === `/user/${id}`;
    const token = getAccessToken();
    const decodedToken: any = token && jwtDecode(token);
    const switchToManagerProfile = id && decodedToken.id === id;

    showCurrentUserInfos && navigate("/account/infos");
    showUserInfos && navigate(`/user/${id}/infos`);
    showTeams && navigate("/account/teams");
    showUserAgenda && navigate(`/user/${id}/agenda`);
    switchToManagerProfile && navigate(`/account/teams`);
  };

  useEffect(() => {
    manageUserPageRoutes(deviceSize, pathname);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deviceSize, pathname, navigate]);

  return (
    <>
      <Header
        leftItem={<ProfileHeader context="user" person={user} />}
        subRoutes={subRoutes}
      />
      {deviceSize !== "Mobile" ? (
        <div className="user-page__inner">
          <div className="user-page__inner__left">
            <UserProfileInfo user={user} />
          </div>
          <div className="user-page__inner__main">
            <Outlet />
          </div>
        </div>
      ) : (
        <Outlet />
      )}

      {isManager(user) && <UpdatePhotoModal />}
    </>
  );
};
