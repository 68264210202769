import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { API_DEV } from "../../utils/apiRoute";
import { axiosPrivate } from "../../utils/interceptors";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { Notif } from "../../types/Notification.model";

export interface NotificationState {
  requestState: "loading" | "idle";
  notifications: Notif[];
  unSeenCount: number;
  errors: any;
}

export const getNotifications = createAsyncThunk(
  "[Notifications]_fetchAll_",
  async (
    payload: { limit?: number; markAsSeen?: boolean },
    { rejectWithValue }
  ) => {
    try {
      let queryArray: string[] = [];
      let queryString = "";
      if (payload.limit || payload.markAsSeen) {
        payload.limit && queryArray.push(`${"limit=" + payload.limit}`);
        payload.markAsSeen &&
          queryArray.push(`${"markAsSeen=" + payload.markAsSeen}`);
        queryString = "?" + queryArray.join("&");
      }
      let response = await axiosPrivate.get(
        `${API_DEV}/Notification${queryString}`
      );
      return response.data;
    } catch (error) {
      console.log(JSON.parse(JSON.stringify(error)));
      return rejectWithValue(JSON.parse(JSON.stringify(error)));
    }
  }
);

export const markAsSeen = createAsyncThunk(
  "[Notifications]_markAsSeen_",
  async (_, { rejectWithValue }) => {
    try {
      let response = await axiosPrivate.put(`${API_DEV}/Notification`);
      return response.data;
    } catch (error) {
      console.log(JSON.parse(JSON.stringify(error)));
      return rejectWithValue(JSON.parse(JSON.stringify(error)));
    }
  }
);

const initialState: NotificationState = {
  requestState: "idle",
  unSeenCount: 0,
  errors: null,
  notifications: [],
};

export const notificationSlice = createSlice({
  name: "[Notifications]",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getNotifications.pending, (state) => {
      state.requestState = "loading";
      state.errors = null;
    });
    builder.addCase(
      getNotifications.fulfilled,
      (
        state,
        action: PayloadAction<{ unSeenCount: number; notifications: Notif[] }>
      ) => {
        state.requestState = "idle";
        state.notifications = action.payload.notifications;
        state.unSeenCount = action.payload.unSeenCount;
        state.errors = null;
      }
    );
    builder.addCase(getNotifications.rejected, (state, { payload }) => {
      state.requestState = "idle";
      state.errors = payload;
    });
    builder.addCase(markAsSeen.pending, (state) => {
      state.requestState = "loading";
      state.errors = null;
    });
    builder.addCase(markAsSeen.fulfilled, (state) => {
      state.requestState = "idle";
      state.unSeenCount = 0;
      state.errors = null;
    });
    builder.addCase(markAsSeen.rejected, (state, { payload }) => {
      state.requestState = "idle";
      state.errors = payload;
    });
  },
});

export default notificationSlice.reducer;
