import React from "react";
import Dashboard from "../../03-organisms/dashboard/Dashboard";

import "./HomeTemplate.scss";
interface Props {}

export const HomeTemplate = (props: Props) => {
  return (
    <div className="home-template__wrapper">
      <Dashboard />
    </div>
  );
};
