import React, { useEffect, useState } from "react";
import { Avatar } from "rsuite";
import { Doctor } from "../../../../../types/Doctor.model";
import { ExternalMember } from "../../../../../types/ExternalMember.model";
import { Participant } from "../../../../../types/Participant.model";
import User from "../../../../../types/User.model";
import { CustomAvatar } from "../../../../01-atoms/CustomAvatar/CustomAvatar";
import { EventParticipantModal } from "../../../../02-molecules/cards/event-card/EventParticipantModal";
import "./PhalconCell.scss";
type Props = {
  item: any;
  rowName: string;
};
interface ParticipantState {
  users: User[];
  doctors: Doctor[];
  externals: ExternalMember[];
  moderators: (User | ExternalMember)[];
  allParticipants: (User | Doctor | ExternalMember)[];
}
const ParticipantsCell = ({ item, rowName }: Props) => {
  const [participantModalIsOpen, setparticipantModalIsOpen] = useState(false);

  const toggleParticipantModal = () =>
    setparticipantModalIsOpen(!participantModalIsOpen);

  const [participantsState, setparticipantsState] = useState<ParticipantState>({
    users: [],
    doctors: [],
    externals: [],
    moderators: [],
    allParticipants: [],
  });

  useEffect(() => {
    const participants: Participant = item ? JSON.parse(item) : null;
    participants &&
      setparticipantsState({
        users: participants.users,
        doctors: participants.doctors,
        externals: participants.externals,
        moderators: participants.moderators,
        allParticipants: [
          ...participants.users,
          ...participants.doctors,
          ...participants.externals,
          ...participants.moderators,
        ],
      });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <span
        className="phalcon-participants-cell"
        onClick={() => toggleParticipantModal()}
      >
        {participantsState.users.length > 0
          ? participantsState.users.map((user) => (
              <CustomAvatar
                image={{
                  src: user.photoUrl,
                  alt: `${user.firstName} ${user.lastName}`,
                }}
                style={{ width: "25px", height: "25px" }}
              />
            ))
          : null}
        {participantsState.allParticipants.length > 2 ? (
          <Avatar circle size="sm" className="participants-count">
            +{participantsState.allParticipants.length}
          </Avatar>
        ) : null}
      </span>
      <EventParticipantModal
        participantModalIsOpen={participantModalIsOpen}
        toggleParticipantModal={toggleParticipantModal}
        participantsState={participantsState}
      />
    </>
  );
};

export default ParticipantsCell;
