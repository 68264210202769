import React from "react";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import { CustomButton } from "../../01-atoms/Buttons/Buttons";
import Icon from "@mdi/react";
import { mdiExport } from "@mdi/js";
import { Fade, Menu, MenuItem } from "@material-ui/core";
import { useHandleResize } from "../../../utils/useHandleResize";

type Props = {
  withModal?: boolean;
  context?: string;
  textClassName?: string;
  modalMenuItem?: {
    label: string;
    exportFunction: (
      exportToCS: (exportedData: any, filteName: string) => void
    ) => void;
  }[];
  getDataAndFormatDataFunction: (
    exportToCS: (exportedData: any, filteName: string) => void, context?: string
  ) => void; 
};

export const FileExporter = ({
  getDataAndFormatDataFunction,
  withModal,
  modalMenuItem,
  textClassName,
  context,
}: Props) => {
  const fileType =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  const fileExtension = ".xlsx";

  const exportToCSV = (exportedData: any, fileName: any) => {
    const ws = XLSX.utils.json_to_sheet(exportedData);
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, fileName + fileExtension);
  };
  const deviceSize = useHandleResize();

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  }; 
  const handleClose = () => { 
    setAnchorEl(null);
  };

  if (!withModal) {
    return (
      <CustomButton
        color="customized"
        text={["Mobile", "Tablet"].includes(deviceSize) ? "" : "Exporter"}
        icon={<Icon path={mdiExport} size={0.75} />}
        onClick={() => context ?  getDataAndFormatDataFunction(exportToCSV, context) : getDataAndFormatDataFunction(exportToCSV)}
        textClassName={textClassName}
      />
    );
  } else {
    return (
      <div>
        <CustomButton
          color="customized"
          text={["Mobile", "Tablet"].includes(deviceSize) ? "" : "Exporter"}
          icon={<Icon path={mdiExport} size={0.75} />}
          aria-controls={open ? "fade-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={open ? "true" : undefined}
          handelClickMouseEvent={handleClick}
          textClassName={textClassName} 
        />
        <Menu
          id="fade-menu"
          MenuListProps={{
            "aria-labelledby": "fade-button",
          }}
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          TransitionComponent={Fade}
          style={{marginTop:"3.2rem"}}
         
        >
          {modalMenuItem &&
          typeof modalMenuItem === "object" &&
          modalMenuItem.length 
            ? modalMenuItem.map((el, index) => { 
                let { exportFunction, label } = el;
                return (
                  <MenuItem onClick={() => exportFunction(exportToCSV)} key={index}>
                    {label}
                  </MenuItem>
                );
              })
            : ""}
        </Menu>
      </div>
    );
  }
};
