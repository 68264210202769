import { createSlice } from "@reduxjs/toolkit";
import { API_DEV } from "../../utils/apiRoute";
import { axiosPrivate } from "../../utils/interceptors";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { Seller } from "../../types/Seller.model";

type filteredSellers = {
  data: Seller[];
  pagination: {
    pageNumber: number;
    totalPages: number;
    pageSize: number;
    totalCount: number;
  };
};
export interface SellerState {
  requestState: "idle" | "loading";
  requestStateForm : boolean;
  sellers: Seller[];
  sellerById: Seller | null;
  filteredSellers: filteredSellers;
  errors: any;
}

export const addSeller = createAsyncThunk(
  "[Sellers]_add_",
  async (payload: any, { rejectWithValue }) => {
    try {
      await axiosPrivate.post(`${API_DEV}/Seller`, payload);
    } catch (err:any) {
      return rejectWithValue(err.response.data);
    }
  }
);


export const fetchSellerById = createAsyncThunk(
  "[Sellers]_fetchById_",
  async (payload: { id: number; signal: any }, { rejectWithValue }) => {
    try {
      let response = await axiosPrivate.get(`${API_DEV}/Seller/${payload.id}`, {
        signal: payload.signal,
      });
 
      return response.data; 
    } catch (error) {
      return rejectWithValue(JSON.parse(JSON.stringify(error)));
    }
  }
); 

export const getFilteredSellers = createAsyncThunk(
  "[Sellers]_getFiltered_",
  async (options: any, { rejectWithValue }) => {
    try {
      let response = await axiosPrivate.post(
        `${API_DEV}/Seller/Filters`,
        options
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(JSON.parse(JSON.stringify(error)));
    }
  }
);

export const updateSeller = createAsyncThunk(
  "[Sellers]_update_",
  async (payload: any, { rejectWithValue }) => {
    try {
      let response = await axiosPrivate.put(`${API_DEV}/Seller`, payload);
      return response.data;
    } catch (err:any) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const deleteSeller = createAsyncThunk(
  "[Sellers]_delete_",
  async (payload:{itemId: number, password: string}, { rejectWithValue }) => {
    try {
      let response = await axiosPrivate.delete(`${API_DEV}/Seller/${payload.itemId}`, {data:payload});
      return response.data;
    } catch (err:any) { 
      return rejectWithValue(err.response.data);
    }
  }
);


const initialState: SellerState = {
  requestState: "idle",
  requestStateForm: false,
  sellers: [],
  sellerById: null,
  filteredSellers: {
    data: [],
    pagination: {
      pageNumber: 0,
      pageSize: 0,
      totalPages: 0,
      totalCount: 0,
    },
  },
  errors: null,

};

export const sellerSlice = createSlice({
  name: "[Sellers]",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(addSeller.pending, (state) => {
      state.requestState = "loading";
      state.errors = null;
    });
    builder.addCase(addSeller.fulfilled, (state) => {
      state.requestState = "idle";
      state.requestStateForm = !state.requestStateForm;
      state.errors = null;
    });
    builder.addCase(addSeller.rejected, (state, { payload }) => {
      state.requestState = "idle";
      state.errors = payload; 
      
    });
    builder.addCase(fetchSellerById.pending, (state) => {
      state.requestState = "loading";
      state.errors = null;
    });
    builder.addCase(fetchSellerById.fulfilled, (state, { payload }) => {
      state.requestState = "idle";
      state.sellerById = payload;
      state.errors = null;
    });
    builder.addCase(fetchSellerById.rejected, (state, { payload }) => {
      state.requestState = "idle";
      state.errors = payload;
    });
    builder.addCase(getFilteredSellers.pending, (state) => {
      state.requestState = "loading";
      state.errors = null;
    });
    builder.addCase(getFilteredSellers.fulfilled, (state, { payload }) => {
      state.requestState = "idle";
      state.filteredSellers = payload;
      state.errors = null;
    });
    builder.addCase(getFilteredSellers.rejected, (state, { payload }) => {
      state.requestState = "idle";
      state.errors = payload;
    });

    builder.addCase(updateSeller.pending, (state) => {
      state.requestState = "loading";
      state.errors = null;
    });
    builder.addCase(updateSeller.fulfilled, (state, { payload }) => {
      state.requestState = "idle";
      state.errors = null;
    });
    builder.addCase(updateSeller.rejected, (state, { payload }) => {
      state.requestState = "idle";
      state.errors = payload;
    });

    builder.addCase(deleteSeller.pending, (state) => {
      state.requestState = "loading";
      state.errors = null;
    });
    builder.addCase(deleteSeller.fulfilled, (state, { payload }) => {
      state.requestState = "idle";
      state.errors = null;
    });
    builder.addCase(deleteSeller.rejected, (state, { payload }) => {
      state.requestState = "idle";
      state.errors = payload;
    });
  },
});

export default sellerSlice.reducer;
