import React, { useState, useEffect } from "react";
import { Divider, Loader, Progress } from "rsuite";
import { useAppSelector } from "../../../redux/hooks";
import { RootState } from "../../../redux/store";
import { getLocalDate } from "../../../utils/dateHelpers";

import ActivityTrackingVisitTable from "../visit/ActivityTrackingVisitTable";
import "./ActivityTrackingContent.scss";

type Props = {};

const ActivityTrackingContent = (props: Props) => {
  const {
    weekNumberInYear,
    startDate,
    endDate,
    localities,
    weekContents,
    potentialProgress,
  } = useAppSelector((state: RootState) => state.tracking.week);

  const { requestState } = useAppSelector((state: RootState) => state.tracking);


  const [done, setDone] = useState<number>(0);
  const [total, setTotal] = useState<number>(0);
  const colors = ["#007aff", "#007aff", "#00c7be", "#ff3b30"];

  useEffect(() => {
    potentialProgress &&
      setDone(
        potentialProgress.reduce((partialSum, a) => partialSum + a.done, 0)
      );
    potentialProgress &&
      setTotal(
        potentialProgress.reduce((partialSum, a) => partialSum + a.total, 0)
      );
  }, [potentialProgress]);

  return (
    <div className="activity-tracking-content__wrapper">
      <div className="activity-tracking-content__subheader">
        <div className="top-area">
          {requestState === "idle" ? (
            <>
              <div className="top-area--left">
                <div className="week-cycle">
                  Semaine de l'année : {weekNumberInYear}
                </div>
              </div>
              <div className="top-area--middle">
                <div className="week-range">
                  {getLocalDate(startDate)} - {getLocalDate(endDate)}
                </div>
              </div>
              <div className="top-area--right">
                <div className="week-brick">
                  {localities &&
                    localities?.length !== 0 &&
                    localities.map((item) => item.localityName).join(" / ")}
                </div>
              </div>
            </>
          ) : (
            ""
          )}
        </div>
        <div className="progress-area">
          {requestState === "idle" &&
            potentialProgress?.length > 0 &&
            [...potentialProgress]
              ?.sort((a, b) => {
                let val1 = a?.potential?.potentialId || 0;
                let val2 = b?.potential?.potentialId || 0;
                if (val1 > val2) {
                  return 1;
                } else if (val1 < val2) {
                  return -1;
                } else {
                  return 0;
                }
              })
              .map((item) => (
                <>
                  <div
                    key={item.potential.potentialId}
                    className="progress-area--item"
                  >
                    <span className="progress-area--label">
                      Pot. {item.potential.potentialName}
                    </span>
                    <span className="progress-area--data">
                      {item.done}/{item.total}
                    </span>
                    <Progress.Line
                      percent={
                        item.total === 0 ? 0 : (item.done / item.total) * 100
                      }
                      strokeColor={colors[item.potential.potentialId]}
                      strokeWidth={4}
                      showInfo={false}
                    />
                  </div>
                  <Divider vertical />
                </>
              ))}
          <div className="progress-area--item">
            <span className="progress-area--label">Total</span>
            <span className="progress-area--data">
              {requestState === "idle" ? done : 0}/{requestState === "idle" ? total : 0} 
            </span> 
            <Progress.Line
              percent={done ? (done / total) * 100 : 0}
              strokeColor="#000"
              showInfo={false}
              strokeWidth={4}
            />
          </div>
        </div>
      </div>
      <div className="content-area" style={{paddingLeft: "4px"}}>
        {requestState === "idle" ? (
          <ActivityTrackingVisitTable weekContents={weekContents} />
        ) : (
          <div
            style={{
              display: "flex",
              height: "600px",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {" "}
            <Loader size="lg" />{" "}
          </div>
        )}
      </div>
    </div>
  );
};

export default ActivityTrackingContent;
