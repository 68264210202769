import { API_DEV } from "../../utils/apiRoute";
import { axiosPrivate, axiosPublic } from "./../../utils/interceptors";
import axios from "axios";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getAccessToken } from "../../utils/currentUserToken";

export interface AuthState {
  requestState: "loading" | "success" | "fail" | "";
  supportState: "loading" | "success" | "fail" | "";
  verifyTokenState: "loading" | "success" | "fail" | "";
  resetPasswordState: "loading" | "success" | "fail" | "";
  refreshToken: string;
  isAuth: boolean;
  error: any;
}

export const login = createAsyncThunk(
  "[authentication]_login_",
  async (
    credentials: { username: string; password: string; rememberMe: boolean },
    { rejectWithValue }
  ) => {
    try {
      let response = await axios.post(
        `${API_DEV}/Authentication/authenticate-manager`,
        {
          username: credentials.username,
          password: credentials.password,
        }
      );
      return {
        data: response.data,
        rememberMe: credentials.rememberMe,
      };
    } catch (error) {
      return rejectWithValue(JSON.parse(JSON.stringify(error)));
    }
  }
);

export const registerDevice = createAsyncThunk(
  "[authentication]_registerDevice_",
  async (payload: { deviceId: string }, { rejectWithValue }) => {
    try {
      let response = await axiosPrivate.post(
        `${API_DEV}/Authentication/register-device`,
        payload
      );
      return {
        data: response.data,
      };
    } catch (error) {
      return rejectWithValue(JSON.parse(JSON.stringify(error)));
    }
  }
);

export const logout = createAsyncThunk(
  "[authentication]_logout_",
  async (refreshToken: string, { rejectWithValue, getState }) => {
    try {
      let response = await axiosPrivate.post(
        `${API_DEV}/Authentication/revoke-token`,
        {
          token: refreshToken,
        }
      );

      return response.data;
    } catch (error) {
      return rejectWithValue(JSON.parse(JSON.stringify(error)));
    }
  }
);

export const sendSupportEmail = createAsyncThunk(
  "[authentication]_sendSupportEmail_",
  async (credentials: string, { rejectWithValue }) => {
    try {
      await axiosPublic.post(`${API_DEV}/Support/Send`, {
        emailLogin: credentials,
      });
    } catch (error) {
      return rejectWithValue(JSON.parse(JSON.stringify(error)));
    }
  }
);

export const verifyToken = createAsyncThunk(
  "[authentication]_verifyToken_",
  async (token: string, { rejectWithValue }) => {
    try {
      await axios.post(`${API_DEV}/Support/VerifyTokenWeb`, {
        token,
      });
      return token;
    } catch (error) {
      return rejectWithValue(JSON.parse(JSON.stringify(error)));
    }
  }
);

export const resetPassword = createAsyncThunk(
  "[authentication]_resetPassword_",

  async (
    {
      emailLogin,
      newPassword,
      token,
    }: {
      emailLogin: string;
      newPassword: string;
      token: string;
    },
    { rejectWithValue }
  ) => {
    try {
      let response = await axios.post(`${API_DEV}/Support/ResetPassword`, {
        emailLogin,
        newPassword,
        token,
      });
      return response.data;
    } catch (error) {
      JSON.parse(JSON.stringify(error));

      return rejectWithValue(JSON.parse(JSON.stringify(error)));
    }
  }
);

const initialState: AuthState = {
  isAuth: getAccessToken() ? true : false,
  requestState: "",
  error: "",
  supportState: "",
  refreshToken: "",
  verifyTokenState: "",
  resetPasswordState: "",
};

export const authenticationSlice = createSlice({
  name: "[authentication]",
  initialState,
  reducers: {
    removeSession: (state) => {
      localStorage.clear();
      sessionStorage.clear();
      state.requestState = "success";
      state.isAuth = false;
      state.error = "";
    },
  },
  extraReducers: (builder) => {
    builder.addCase(login.pending, (state) => {
      state.requestState = "loading";
      state.supportState = "";
      state.verifyTokenState = "";
      state.resetPasswordState = "";
      state.error = "";
    });
    builder.addCase(login.rejected, (state, { payload }) => {
      state.requestState = "fail";
      state.supportState = "";
      state.verifyTokenState = "";
      state.resetPasswordState = "";
      state.isAuth = false;
      state.error = payload;
    });
    builder.addCase(login.fulfilled, (state, { payload }) => {
      if (payload.rememberMe) {
        localStorage.setItem("accessToken", payload.data.accessToken);
      } else {
        sessionStorage.setItem("accessToken", payload.data.accessToken);
      }
      state.refreshToken = payload.data.refreshToken;
      state.requestState = "success";
      state.supportState = "";
      state.verifyTokenState = "";
      state.resetPasswordState = "";
      state.isAuth = true;
      state.error = "";
    });
    builder.addCase(logout.pending, (state) => {
      state.requestState = "loading";
      state.supportState = "";
      state.verifyTokenState = "";
      state.resetPasswordState = "";
      state.error = "";
    });
    builder.addCase(logout.rejected, (state, { payload }) => {
      localStorage.clear();
      sessionStorage.clear();
      state.requestState = "";
      state.supportState = "";
      state.verifyTokenState = "";
      state.resetPasswordState = "";
      state.isAuth = false;
      state.error = payload;
    });
    builder.addCase(logout.fulfilled, (state, { payload }) => {
      localStorage.clear();
      sessionStorage.clear();
      state.requestState = "success";
      state.supportState = "";
      state.verifyTokenState = "";
      state.resetPasswordState = "";
      state.isAuth = false;
      state.error = "";
    });

    builder.addCase(sendSupportEmail.pending, (state) => {
      state.supportState = "loading";
      state.requestState = "";
      state.verifyTokenState = "";
      state.resetPasswordState = "";
      state.error = "";
    });
    builder.addCase(sendSupportEmail.rejected, (state, { payload }) => {
      state.supportState = "fail";
      state.requestState = "";
      state.verifyTokenState = "";
      state.resetPasswordState = "";
      state.error = payload;
    });
    builder.addCase(sendSupportEmail.fulfilled, (state) => {
      state.supportState = "success";
      state.requestState = "";
      state.verifyTokenState = "";
      state.resetPasswordState = "";
      state.error = "";
    });
    builder.addCase(verifyToken.pending, (state) => {
      state.verifyTokenState = "loading";
      state.requestState = "";
      state.supportState = "";
      state.resetPasswordState = "";
      state.error = "";
    });
    builder.addCase(verifyToken.rejected, (state, { payload }) => {
      state.verifyTokenState = "fail";
      state.requestState = "";
      state.supportState = "";
      state.resetPasswordState = "";
      state.error = payload;
    });
    builder.addCase(
      verifyToken.fulfilled,
      (state, { payload }: { payload: string }) => {
        localStorage.setItem("resetPasswordtoken", payload);
        state.verifyTokenState = "success";
        state.requestState = "";
        state.supportState = "";
        state.resetPasswordState = "";
        state.error = "";
      }
    );
    builder.addCase(resetPassword.pending, (state) => {
      state.resetPasswordState = "loading";
      state.requestState = "";
      state.supportState = "";
      state.verifyTokenState = "";
      state.error = "";
    });
    builder.addCase(resetPassword.rejected, (state, { payload }) => {
      state.resetPasswordState = "fail";
      state.requestState = "";
      state.supportState = "";
      state.verifyTokenState = "";
      state.error = payload;
    });
    builder.addCase(resetPassword.fulfilled, (state, { payload }) => {
      localStorage.clear();
      state.resetPasswordState = "success";
      state.requestState = "";
      state.supportState = "";
      state.verifyTokenState = "";
      state.error = "";
    });
  },
});

export const { removeSession } = authenticationSlice.actions;

export default authenticationSlice.reducer;
