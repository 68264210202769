import { DoctorState } from "./../../../../redux/Doctor/doctorSlice";
import { useAppDispatch, useAppSelector } from "../../../../redux/hooks";
import { RootState } from "../../../../redux/store";
import {
  toggleAddDoctorDrawer,
  toggleDoctorsFilterDrawer,
} from "../../../../redux/UI/uiSlice";
import { Doctor } from "../../../../types/Doctor.model";
import { Locality } from "../../../../types/Locality.model";
import { doctorPhones } from "../../../../helpers/dataFormator";
// import { mdiFormatLetterCase } from "@mdi/js";

export interface DoctorTableData {
  id: number;
  title: string;
  firstName: string;
  lastName: string;
  email: string;
  phones: string;
  potential: string;
  tags: string;
  businessUnits: string;
  facilityType: string;
  specialities: string;
  locality1: string;
  locality2: string;
  locality3: string;
  locality4: string;
  streetName: {streetName: string | null, addressNote: string | null};
}
const useDoctorTable = () => {
  const dispatch = useAppDispatch();
  const { doctorsFilterDrawerIsOpen } = useAppSelector(
    (state: RootState) => state.UI
  );
  const doctorState = useAppSelector((state: RootState) => state.doctor);

  const formatData: (fetchedData: Doctor[]) => DoctorTableData[] = (
    fetchedData
  ) => {
    const primaryLocalities = (doctor: Doctor) => {
      const office = doctor.offices[0];
      if (office?.isPrimary) {
        return {
          facilityType: "Cabinet", //to verify
          locality1:
            office.address.localities.find((e) => e.level === 1)
              ?.localityName || "",
          locality2:
            office.address.localities.find((e) => e.level === 2)
              ?.localityName || "",
          locality3:
            office.address.localities.find((e) => e.level === 3)
              ?.localityName || "",
          locality4:
            office.address.localities.find((e) => e.level === 4)
              ?.localityName || "",
          streetName: office.address.streetName || "",
          noteAdress: office.address.addressNote || "",
        };
      } else {
        const primaryfacility = doctor.facilities?.find(
          (facility) => facility.isPrimaryFacility === true
        );
        return {
          facilityType:
            primaryfacility?.facilityName || "",
          locality1: 
            primaryfacility?.address.localities.find(
              (e: Locality) => e.level === 1
            )?.localityName || "",
          locality2:
            primaryfacility?.address.localities.find(
              (e: Locality) => e.level === 2
            )?.localityName || "",
          locality3:
            primaryfacility?.address.localities.find(
              (e: Locality) => e.level === 3
            )?.localityName || "",
          locality4:
            primaryfacility?.address.localities.find(
              (e: Locality) => e.level === 4
            )?.localityName || "",
          streetName: primaryfacility?.address.streetName || "",
          noteAdress: primaryfacility?.address.addressNote || "",
        };
      }
    };

    return fetchedData.length > 0
      ? fetchedData.map((doctor: Doctor) => ({
          id: doctor.doctorId,
          title: doctor?.title || "",
          firstName: doctor?.firstName || "",
          lastName: doctor?.lastName || "",
          email: doctor?.email || "",
          phones: JSON.stringify(doctorPhones(doctor?.phones || [], doctor?.offices || [])),
          potential: doctor?.potential?.potentialName || "",
          tags: JSON.stringify(doctor.tags),
          businessUnits: JSON.stringify(doctor.businessUnits),
          specialities: JSON.stringify(doctor.specialities),
          facilityType: primaryLocalities(doctor).facilityType,
          locality1: primaryLocalities(doctor).locality1,
          locality2: primaryLocalities(doctor).locality2,
          locality3: primaryLocalities(doctor).locality3,
          locality4: primaryLocalities(doctor).locality4,
          streetName: {streetName: primaryLocalities(doctor).streetName, addressNote: primaryLocalities(doctor).noteAdress},
          noteAdress: primaryLocalities(doctor).noteAdress,
        }))
      : [];
  };

  const prepareDatabase = (doctorState: DoctorState) => {
    return {
      data: formatData(doctorState.filteredDoctors.data),
      dataResquestState: doctorState.requestState || "idle",
      errors: doctorState.errors || null,
      pageNumber: doctorState.filteredDoctors.pagination.pageNumber,
      pageSize: doctorState.filteredDoctors.pagination.pageSize,
      totalCount: doctorState.filteredDoctors.pagination.totalCount,
      filterDrawerIsOpen: doctorsFilterDrawerIsOpen,
      toggleFilterDrawer: () => dispatch(toggleDoctorsFilterDrawer()),
      toggleAddDrawer: () => dispatch(toggleAddDoctorDrawer()),
    };
  };

  const {
    data,
    dataResquestState,
    errors,
    pageNumber,
    pageSize,
    totalCount,
    filterDrawerIsOpen,
    toggleFilterDrawer,
    toggleAddDrawer,
  } = prepareDatabase(doctorState);

  const columns = [
    {
      key: "title",
      label: "Titre",
      width: 70,
      fixed: false,
      type: "textCell",
      checked: true,
      role: "Informations générales",
    },
    {
      key: "lastName",
      label: "Nom",
      width: 150,
      fixed: false,
      type: "textCell",
      sortable: true,
      checked: true,
      role: "Informations générales",
    },
    {
      key: "firstName",
      label: "Prénom",
      width: 150,
      fixed: false,
      type: "textCell",
      sortable: true,
      checked: true,
      role: "Informations générales",
    },

    {
      key: "email",
      label: "Email",
      width: 300,
      fixed: false,
      type: "textCell",
      sortable: false,
      checked: true,
      role: "Informations générales",
    },
    {
      key: "phones",
      label: "Téléphones",
      width: 200,
      fixed: false,
      type: "phoneCell",
      checked: true,
      role: "Informations générales",
    },
    {
      key: "potential",
      label: "Potentiel",
      width: 100,
      fixed: false,
      type: "textCell",
      sortable: true,
      checked: true,
      role: "Informations générales",
    },
    {
      key: "specialities",
      label: "Spécialité",
      width: 160,
      fixed: false,
      type: "specialityCell",
      sortable: true,
      checked: true,
      role: "Informations générales",
    },
    {
      key: "tags",
      label: "Tags",
      width: 200,
      fixed: false,
      type: "tagsCell",
      sortable: false,
      checked: false,
      role: "Informations générales",
    },
    {
      key: "businessUnits",
      label: "Busines units",
      width: 200,
      fixed: false,
      type: "businessUnitsCell",
      sortable: false,
      checked: false,
      role: "Informations générales",
    },
    {
      key: "facilityType",
      label: "Etablissement",
      width: 200,
      fixed: false,
      type: "textCell",
      sortable: false,
      checked: true,
      role: "Localisation",
    },
    {
      key: "locality1",
      label: "Gouvernorat",
      width: 140,
      fixed: false,
      type: "textCell",
      sortable: true,
      checked: true,
      role: "Localisation",
    },
    {
      key: "locality2",
      label: "Délégation",
      width: 180,
      fixed: false,
      type: "textCell",
      sortable: true,
      checked: true,
      role: "Localisation",
    },
    {
      key: "locality3",
      label: "Code postal",
      width: 140,
      fixed: false,
      type: "textCell",
      sortable: true,
      checked: false,
      role: "Localisation",
    },
    {
      key: "locality4",
      label: "Localité",
      width: 200,
      fixed: false,
      type: "textCell",
      sortable: true,
      checked: false,
      role: "Localisation",
    },
    {
      key: "streetName",
      label: "Adresse",
      width: 430,
      fixed: false,
      type: "addressCell",
      sortable: false,
      checked: true,
      role: "Localisation",
    },
    // {
    //   key: "noteAdress",
    //   label: "Complément d'adresse",
    //   width: 320,
    //   fixed: false,
    //   type: "textCell",
    //   checked: false,
    //   role: "Localisation",
    // },
  ];

  return {
    data,
    columns,
    dataResquestState,
    errors,
    pageNumber,
    pageSize,
    totalCount,
    filterDrawerIsOpen,
    toggleFilterDrawer,
    toggleAddDrawer,
  };
};

export default useDoctorTable;
