import React from "react";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import Chart from "react-apexcharts";
import { Col } from "rsuite";

ChartJS.register(ArcElement, Tooltip, Legend);

type Props = {
  title: string;
  type: "pie" | "donut";
  labels: string[];
  series: { name: string; data: number[] }[];
  xs?: number;
  sm?: number;
  md?: number;
  lg?: number;
};
const PhalconPieChart = ({
  xs,
  sm,
  md,
  lg,
  title,
  type,
  labels,
  series,
}: Props) => {
  
  const definition: any = {
    series: series && series.length? series : [0],
    options: {
      labels: labels && labels.length ? labels : [""],
      title: {
        text: title,
        align: "center",
        responsive: [
          { 
            breakpoint: 480,
            options: {
              chart: {
                width: 200,
              },
              legend: {
                position: "bottom",
              },
            },
          },
        ],
      },
      dataLabels: {
        enabled: true,

        dropShadow: {
          enabled: false,
        },
      },

      legend: {
        show: true,
        position: "bottom",
      },
    },
  };
  return (
    <Col xs={xs || 24} sm={sm} md={md} lg={lg}>
      <div className="phalcon-pie__wrapper">
        <div className="phalcon-pie__inner">
          <Chart
            options={definition.options}
            series={definition.series  }
            type={type}
            width="100%"
            height={418}
          />
        </div>
      </div>
    </Col>
  );
};
export default PhalconPieChart;
