import React from "react";
import TrashIcon from "@rsuite/icons/Trash";
import { FlexboxGrid } from "rsuite";
import "./RemoveBlockButton.scss";
type RemoveBlockButtonProps = {
  contextArray: any;
  index: number;
  callBack?: () => void;
  style?: any;
  removeAll?: boolean;
};
export const RemoveBlockButton = ({
  contextArray,
  index,
  callBack,
  style,
  removeAll,
}: RemoveBlockButtonProps) => {
  const limit = removeAll ? 0 : 1;
  return (
    <>
      {contextArray.fields.length > limit && (
        <FlexboxGrid.Item colspan={1} style={style}>
          <div
            className="form-remove__button"
            onClick={() => {
              callBack && callBack();
              contextArray.remove(index);
            }}
          >
            <TrashIcon />
          </div>
        </FlexboxGrid.Item>
      )}
    </>
  );
};
