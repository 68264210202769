import React from "react";
import { Calendar } from "rsuite";

import "./Agenda.scss";
type Props = {};

const Agenda = (props: Props) => {
  return (
    <div className="agenda__wrapper">
      <Calendar
        bordered
        className="delegate-agenda"
        isoWeek
        onChange={(date) => console.log(date)}
      />
    </div>
  );
};

export default Agenda;
