import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { API_DEV } from "../../utils/apiRoute";
import { axiosPrivate } from "../../utils/interceptors";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { Approval } from "../../types/Approval.model";

export interface ApprovalState {
  requestState: "loading" | "idle";
  selectedRequest: Approval | null;
  DoctorApprovals: Approval[];
  DoctorApprovalById: Approval | null;
  navigateToValidationTable: boolean;
  errors: any;
}

export const GetActiveRequests = createAsyncThunk(
  "[Approvals]_GetDoctorApprovals_",
  async (_, { rejectWithValue }) => {
    try {
      const response = await axiosPrivate.get(
        `${API_DEV}/ApprovalRequest/Doctors/Active`
      );
      return response.data;
    } catch (error) {
      console.log(JSON.parse(JSON.stringify(error)));
      return rejectWithValue(JSON.parse(JSON.stringify(error)));
    }
  }
);

export const GetActiveRequestSingle = createAsyncThunk(
  "[Approvals]_GetDoctorApproval_",
  async (id: number, { rejectWithValue }) => {
    try {
      const response = await axiosPrivate.get(
        `${API_DEV}/ApprovalRequest/Doctors/Active/${id}`
      );
      return response.data;
    } catch (error) {
      console.log(JSON.parse(JSON.stringify(error)));
      return rejectWithValue(JSON.parse(JSON.stringify(error)));
    }
  }
);

export const ApproveRequest = createAsyncThunk(
  "[Approvals]_ApproveRequest_",
  async (id: number, { rejectWithValue }) => {
    try {
      const response = await axiosPrivate.post(
        `${API_DEV}/ApprovalRequest/Doctors/${id}/Approve`
      );
      return response.data;
    } catch (error) {
      console.log(JSON.parse(JSON.stringify(error)));
      return rejectWithValue(JSON.parse(JSON.stringify(error)));
    }
  }
);

export const RejectRequest = createAsyncThunk(
  "[Approvals]_RejectRequest_",
  async (id: number, { rejectWithValue }) => {
    try {
      const response = await axiosPrivate.post(
        `${API_DEV}/ApprovalRequest/Doctors/${id}/Reject`
      );
      return response.data;
    } catch (error) {
      console.log(JSON.parse(JSON.stringify(error)));
      return rejectWithValue(JSON.parse(JSON.stringify(error)));
    }
  }
);

const initialState: ApprovalState = {
  requestState: "idle",
  selectedRequest: null,
  DoctorApprovals: [],
  DoctorApprovalById: null,
  navigateToValidationTable: false,
  errors: null,
};

export const approvalSlice = createSlice({
  name: "[Approvals]",
  initialState,
  reducers: {
    toggleNavigateToValidationTable: (
      state,
      { payload }: PayloadAction<boolean>
    ) => {
      state.navigateToValidationTable = payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(GetActiveRequests.pending, (state) => {
      state.requestState = "loading";
      state.errors = null;
    });
    builder.addCase(GetActiveRequests.fulfilled, (state, { payload }) => {
      state.requestState = "idle";
      state.DoctorApprovals = payload;
      state.errors = null;
    });
    builder.addCase(GetActiveRequests.rejected, (state, { payload }) => {
      state.requestState = "idle";
      state.errors = payload;
    });
    builder.addCase(GetActiveRequestSingle.pending, (state) => {
      state.requestState = "loading";
      state.errors = null;
    });
    builder.addCase(GetActiveRequestSingle.fulfilled, (state, { payload }) => {
      state.requestState = "idle";
      state.DoctorApprovalById = payload;
      state.errors = null;
    });
    builder.addCase(GetActiveRequestSingle.rejected, (state, { payload }) => {
      state.requestState = "idle";
      state.errors = payload;
    });
  },
});

export const { toggleNavigateToValidationTable } = approvalSlice.actions;

export default approvalSlice.reducer;
