import { SellerState } from "../../../../redux/Seller/sellerSlice";
import { useAppDispatch, useAppSelector } from "../../../../redux/hooks";
import { RootState } from "../../../../redux/store";
import {
  toggleAddSellerDrawer,
  toggleSellersFilterDrawer,
} from "../../../../redux/UI/uiSlice";
import { Seller } from "../../../../types/Seller.model";

export interface SellerTableData {
  id: number;
  name: string;
  website: string;
  shift: string;
  email: string;
  phones: string;
  potential: string;
  tags: string;
  sellerType: string;
  businessUnits: string;
  locality1: string;
  locality2: string;
  locality3: string;
  locality4: string;
  streetName: {streetName: string | null, addressNote: string | null};
}
const useSellerTable = () => {
  const dispatch = useAppDispatch();
  const { sellersFilterDrawerIsOpen } = useAppSelector(
    (state: RootState) => state.UI
  ); 
  const sellerState = useAppSelector((state: RootState) => state.seller);
  const sellerTypeList = useAppSelector((state: RootState)=> state.general.sellerTypes)  
  const formatData: (fetchedData: Seller[]) => SellerTableData[] = (
    fetchedData
  ) => {
    return fetchedData && fetchedData.length > 0 && sellerTypeList 
      ? fetchedData.map((seller: Seller) => ({
          id: seller.sellerId,
          name: seller?.sellerName || "_",
          email: seller?.email || "_",
          website: seller?.website || "_", 
          shift: seller?.shift ? "Nuit" : "Jour",
          sellerType: sellerTypeList?.find((sellerType) => sellerType.sellerTypeId === seller.sellerType.sellerTypeId )?.sellerTypeName || "_",
          phones: JSON.stringify(seller.phones),
          potential: seller.potential.potentialName,
          tags: JSON.stringify(seller.tags),
          businessUnits: JSON.stringify(seller.businessUnits),
          locality1: seller?.address?.localities.find((e) => e.level === 1)?.localityName || "",
          locality2: seller?.address?.localities.find((e) => e.level === 2)?.localityName || "",
          locality3: seller?.address?.localities.find((e) => e.level === 3)?.localityName || "",
          locality4: seller?.address?.localities.find((e) => e.level === 4)?.localityName || "" ,
          streetName: {streetName: seller?.address?.streetName, addressNote: seller?.address?.addressNote},
          noteAdress: seller?.address.addressNote || "", 
        }))
      : []; 
  }; 

  const prepareDatabase = (sellerState: SellerState) => {
    return {
      data: formatData(sellerState.filteredSellers.data),
      dataResquestState: sellerState.requestState || "idle",
      errors: sellerState.errors || null,
      currentPageNumber: 
        sellerState.filteredSellers.pagination.pageNumber,
      pageSize: sellerState.filteredSellers.pagination.pageSize,
      totalCount: sellerState.filteredSellers.pagination.totalCount,
      filterDrawerIsOpen: sellersFilterDrawerIsOpen,
      toggleFilterDrawer: () => dispatch(toggleSellersFilterDrawer()),
      toggleAddDrawer: () => dispatch(toggleAddSellerDrawer()),
    };
  };

  const {
    data,
    dataResquestState,
    errors,
    currentPageNumber,
    pageSize,
    totalCount,
    filterDrawerIsOpen,
    toggleFilterDrawer,
    toggleAddDrawer,
  } = prepareDatabase(sellerState);

  const columns = [
    {
      key: "sellerType",
      label: "Type",
      width: 150,
      fixed: false,
      type: "textCell",
      sortable: true,
      checked: true,
      role: "Informations générales",
    },
    {
      key: "name",
      label: "Nom",
      width: 300,
      fixed: false,
      type: "textCell",
      sortable: true,
      checked: true,
      role: "Informations générales",
    },
    {
      key: "shift",
      label: "Shift",
      width: 150,
      fixed: false,
      type: "textCell",
      sortable: true,
      checked: false,
      role: "Informations générales",
    },
    {
      key: "potential",
      label: "Potentiel",
      width: 100,
      fixed: false,
      type: "textCell",
      sortable: true,
      checked: true,
      role: "Informations générales",
    },
    {
      key: "phones",
      label: "Téléphones",
      width: 200,
      fixed: false,
      type: "phoneCell",
      checked: true,
      role: "Informations générales",
    },
    {
      key: "email",
      label: "Email",
      width: 300,
      fixed: false,
      type: "textCell",
      sortable: true,
      checked: true,
      role: "Informations générales",
    },
   
  
    {
      key: "businessUnits",
      label: "Busines units",
      width: 200,
      fixed: false,
      checked: false,
      type: "businessUnitsCell",
      role: "Informations générales",
    },
    {
      key: "locality1",
      label: "Gouvernorat",
      width: 140,
      fixed: false,
      type: "textCell",
      sortable: true,
      checked: true,
      role: "Localisation",
    },
    {
      key: "locality2",
      label: "Délégation",
      width: 180,
      fixed: false,
      type: "textCell",
      sortable: true,
      checked: true,
      role: "Localisation",

    },
    {
      key: "locality3",
      label: "Code postal",
      width: 140,
      fixed: false,
      type: "textCell",
      sortable: true,
      checked: false,
      role: "Localisation",
    },
    {
      key: "locality4",
      label: "Localité",
      width: 200,
      fixed: false,
      type: "textCell",
      sortable: true,
      checked: false,
      role: "Localisation",
    },
    {
      key: "streetName",
      label: "Adresse",
      width: 430,
      fixed: false,
      type: "addressCell",
      checked: true,
      role: "Localisation",
    },
    {
      key: "website",
      label: "Site Web",
      width: 400,
      fixed: false,
      type: "textCell",
      checked: false,
      role: "Informations générales",
    },
    {
      key: "tags",
      label: "Tags",
      width: 200,
      fixed: false,
      checked: false,
      type: "tagsCell",
      role: "Informations générales",
    },
  ];

  return {
    data,
    columns,
    dataResquestState,
    errors,
    currentPageNumber,
    pageSize,
    totalCount,
    filterDrawerIsOpen,
    toggleFilterDrawer,
    toggleAddDrawer,
  };
};

export default useSellerTable;
