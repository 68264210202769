import { AddCircleOutline } from "@material-ui/icons";
import React from "react";
import { useForm } from "react-hook-form";
import { AchivedSalesTargetTableRowsInterface, ExpectedSalesTargetTableRowsInterface, SalesTargetUsedInFormInterface } from "../../../types/SalesTarget.model";
import { CustomButton } from "../../01-atoms/Buttons/Buttons";
import { FileExporter } from "../../02-molecules/Exoort-File/FileExporter";
import DelegateSelectorButton from "./sales-target-buttons/DelegateSelectorButton";
import ProductFilterButton from "./sales-target-buttons/ProductSelecter";
import YearSetterButton from "./sales-target-buttons/YearSetterButtom";
import SalesTargetTable from "./sales-target-table/SalesTargetTables";
import "./SalesTarget.scss";

type Props = {
  context: "achievedSales" | "estimatedSales";
  title: string;
  setFormTargetSalesModalOpen?: React.Dispatch<React.SetStateAction<boolean>>;
  achievedSalesTargetList?: AchivedSalesTargetTableRowsInterface[];
  expectedSalesTargetList?: ExpectedSalesTargetTableRowsInterface[];
  listToCompare?: ExpectedSalesTargetTableRowsInterface[];
  captureDelegate?: (delegateId: number) => void;
  captureYear?: (year: number | string) => void;
  captureProducts?: (products: number[]) => void;
  captureLimitTarget: (limit: number, context: "achievedSales" | "estimatedSales") => void;
  capturePageTarget: (page: number, context: "achievedSales" | "estimatedSales") => void;
  salesTargetrequestState: "loading" | "idle";
  pageSize: number;
  totalCount: number;
  formatExportedData: any;
  setUpdateSalesTargetObject?: React.Dispatch<
    React.SetStateAction<{
      productId: number;
      salesObject: SalesTargetUsedInFormInterface;
      monthsId: { label: number | string, value: number | string | null }[];
    } | null>>;
  setFormContext?: React.Dispatch<React.SetStateAction<"addSalesTarget" | "updateSalesTarget">>;
};

export default function SalesTarget({
  context,
  title,
  setFormTargetSalesModalOpen,
  achievedSalesTargetList,
  expectedSalesTargetList,
  captureDelegate,
  listToCompare,
  captureYear,
  captureProducts,
  captureLimitTarget,
  capturePageTarget,
  salesTargetrequestState,
  pageSize,
  totalCount,
  formatExportedData,
  setUpdateSalesTargetObject,
  setFormContext,

}: Props) {
  const handleOpenSalesTargetModal = () => {
    setFormTargetSalesModalOpen && setFormTargetSalesModalOpen(true);
  };
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<any>({
    defaultValues: null,
    criteriaMode: "all",
    reValidateMode: "onChange",
  });

  return (
    <>
      <div className="sales-target-top">{title}</div>
      <div className="sales-target-middle">
        <div className="sales-target-filters">
          {context === "estimatedSales" ? (
            <>
              <YearSetterButton captureYear={captureYear} />
              <ProductFilterButton
                context="check"
                control={control}
                errors={errors}
                handleSubmit={handleSubmit}
                captureProducts={captureProducts}
              />
              <div className="sales-target-middle-btn">
                <CustomButton
                  text="Ajouter un objectif de vente"
                  icon={<AddCircleOutline />}
                  color="customized"
                  className="add-target-sales-form-btn"
                  textClassName="custom-btn-display"
                  onClick={() => { setFormContext && setFormContext("addSalesTarget"); handleOpenSalesTargetModal(); }}
                />
              </div>
            </>
          ) : (
            <div className="sales-target-delegate-btn">
              <DelegateSelectorButton captureDelegate={captureDelegate} />
            </div>
          )}
        </div>
        <div>
          <div className="sales-target-middle-btn">
            <FileExporter
              getDataAndFormatDataFunction={formatExportedData}
              context={context}
            />
          </div>
        </div>
      </div>
      <div className="sales-target-bottom">
        <SalesTargetTable
          data={
            context === "achievedSales"
              ? achievedSalesTargetList || []
              : expectedSalesTargetList || []
          }
          salesTargetIsLoaded={salesTargetrequestState}
          context={context}
          listToCompare={listToCompare}
          captureLimitTarget={captureLimitTarget}
          capturePageTarget={capturePageTarget}
          pageSize={pageSize}
          totalCount={totalCount}
          handleOpenSalesTargetModal={handleOpenSalesTargetModal}
          setUpdateSalesTargetObject={setUpdateSalesTargetObject}
          setFormContext={setFormContext}
        />
      </div>
    </>
  );
}
