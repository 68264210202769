//@ts-nocheck
import React, { useEffect, useState } from 'react'
import { useAppDispatch, useAppSelector } from '../../../redux/hooks'
// import { fetchProducts } from '../../../redux/Products/Products'
import { Box, Grid } from '@material-ui/core';
import { DataGrid, GridCellParams, GridColDef } from "@material-ui/data-grid";
import "./productsStyle.scss"
import { SearchBar } from "../../02-molecules/searchBar/SearchBar"
import Button from '@material-ui/core/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faToggleOn, faToggleOff } from '@fortawesome/free-solid-svg-icons';
import AddForm from './formsAndPopups/AddForm';
// import { ToggleProduct } from '../../../redux/Products/Products';
import localizedTextsMap from '../../../utils/dataGridColumnMenuLanguage';
import { getAllUsersThunk } from '../../../redux/users/users';
import { deleteUserById } from '../../../redux/users/users';
import { getConnectedUserId } from '../../../utils/getConnectedData';
import { mdiPlusCircleOutline } from '@mdi/js';
import {

  Modal,


} from "rsuite";
import Icon from '@mdi/react';
export default function Users() {
  const userId = getConnectedUserId()

  const { users } = useAppSelector(state => state.users)
  const dispatch = useAppDispatch()
  const [gridRows, setGridRows] = useState([])
  const [searchQuery, setSearchQuery] = useState('');
  const [showAddForm, setShowAddForm] = useState(false)
  const [userToDelete, setUserToDelete] = useState()
  const [showDelPrompt, setDelPrompt] = useState(false)
  useEffect(() => {
    dispatch(getAllUsersThunk(userId))
  }, [dispatch, userId])

  useEffect(() => {
    console.log(users)
    const newGridRows = users.map((item: any, i) => ({
      FirstName: item.firstName,
      id: item.userId,
      LastName: item.lastName,
      Address: item.address.localities[0].localityName + " " + item.address.localities[1]?.localityName + " " + item.address.localities[2]?.localityName,
      Email: item.email,
      Phone: item.phones[0].phoneNumber,
      ReleaseDate: item.releaseDate,
      HireDate: getFormattedDate(new Date(item.hireDate)),
      registrantionNumber: item.registrantionNumber,
      toggle: item.status,
      role: item.role
    }));
    let filteredRows = newGridRows.filter(item => (item.role === 3))
    setGridRows(filteredRows)
  }, [users])

  // const renderVirtualCell = (params: GridCellParams) => (
  //   <div style={{ color: params.value ? 'black' : 'black' }}>
  //     {params.value ? 'Action' : 'Produit'}
  //   </div>
  // );

  function getFormattedDate(date) {
    var year = date.getFullYear();

    var month = (1 + date.getMonth()).toString();
    month = month.length > 1 ? month : '0' + month;

    var day = date.getDate().toString();
    day = day.length > 1 ? day : '0' + day;

    return month + '/' + day + '/' + year;
  }

  const renderToggleCell = (params: GridCellParams) => {
    // console.log(params)
    return (<div style={{ display: 'flex', justifyContent: 'flex-end' }}>
      {params.value === 1 ? <FontAwesomeIcon onClick={() => {
        setDelPrompt(true)
        setUserToDelete(params.row.id)

      }} style={{ fontSize: '2em', color: "#20abe3" }} icon={faToggleOn} /> : <FontAwesomeIcon style={{ fontSize: '2em', color: "#20abe3", opacity: 0.5 }} icon={faToggleOff} />}
    </div>)
  };
  const handleSearchChange = (value: any) => {
    setSearchQuery(value);
  };

  const filteredRows = gridRows.filter(row => {
    return row.FirstName.toLowerCase().includes(searchQuery.toLowerCase());
  });

  const columns: GridColDef[] = [
    { field: 'FirstName', headerName: 'Prénom', minWidth: 190, flex: 1 },
    { field: 'LastName', headerName: 'Nom', width: 150, flex: 1 },
    {
      field: 'Address',
      headerName: 'Addresse',
      width: 150,
      flex: 1
      // renderCell: renderVirtualCell,
    },
    { field: 'Email', headerName: 'Email', width: 150, flex: 1 },
    { field: 'Phone', headerName: 'Num telephone', width: 150, },

    // { field: 'productId', headerName: 'ID', width: 180 },
    { field: 'HireDate', headerName: 'Date de recrutement', flex: 1 },
    {
      field: 'registrantionNumber',
      headerName: 'Matricule',
      width: 150,

    },
    {
      field: 'toggle',
      headerName: 'Statut',
      width: 150,
      renderCell: renderToggleCell,
    },

  ];


  return (
    <>
      {showAddForm ? <AddForm closeForm={setShowAddForm} /> : null}
      <Modal
        backdrop="static"
        role="alertdialog"
        open={showDelPrompt}
        onClose={()=>setUserToDelete()}
        size="xs"
        className="database-action-cell-alert-modal"
      >
        <Modal.Body>
          <p style={{ fontSize: 18, fontWeight: 500, lineHeight: 1.4 }} className={"delete-message"}>
            Êtes vous sûr de vouloir désactiver cet utilisateur ? <span style={{ fontWeight: 700 }}>cette action  est irréversible.</span>
          </p>
        </Modal.Body>
        <Modal.Footer>
          <Button
            style={{ color: "#fff", backgroundColor: "#181b24", fontSize: ".865rem", fontWeight: 600 }}
            onClick={()=>setDelPrompt(false)}
            className="cycle-remove-cancel-button"
            appearance="primary"
          >
            Annuler
          </Button>
          <Button
            style={{ color: "#F93E3E", fontSize: ".875rem", fontWeight: 600, marginLeft: "10px", backgroundColor: "transparent" }}
            disabled={false}
            className="cycle-remove-submit-button"
            onClick={async () => {
              await dispatch(deleteUserById(userToDelete))
              await dispatch(getAllUsersThunk(userId))
              setDelPrompt(false)
            }}
            appearance="primary"
          >
            Désactiver
          </Button>
        </Modal.Footer>
      </Modal>
      <Box sx={{ height: 600, width: "100%" }}>
        <Grid style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }} container >
          <SearchBar className="searchit" containerClass='productSearchBar' placeHolder='Recherche' setSearchTerm={handleSearchChange} />
          <Button onClick={() => setShowAddForm(true)} style={{ padding: 20, color: "white", width: "8rem", height: 10, marginRight: "1rem" }} variant="contained" size="large" color="primary" >
            ajouter <div style={{ display: "flex", justifyContent: "center", alignItems: "center", marginLeft: 7 }}><Icon path={mdiPlusCircleOutline} size={1.2} /></div>
          </Button>
        </Grid>
        {gridRows.length > 0 ? (
          <DataGrid
            rowsPerPageOptions={[5, 10, 20]}
            localeText={localizedTextsMap}
            style={{ backgroundColor: "white", marginRight: 12, marginLeft: 17 }}
            rows={filteredRows}
            columns={columns}
            autoPageSize={10}
            disableColumnMenu
          />
        ) : null}
      </Box>
    </>
  )
}
