import { getFilteredFacilities } from "../redux/Facility/facilitySlice";
import {
  getFilteredDoctor,
  getFilteredDoctorSummaries,
} from "./../redux/Doctor/doctorSlice";
import { useAppDispatch } from "./../redux/hooks";
import { useState, useEffect } from "react";
import { useAppSelector } from "../redux/hooks";
import { RootState } from "../redux/store";
import { BusinessUnit } from "../types/BusinessUnit.model";
import { Locality } from "../types/Locality.model";
import { Facility } from "../types/Facility.model";
import { FacilityType } from "../types/FacilityType.model";
import { Potential } from "../types/Potential.model";
import { ServiceType } from "../types/ServiceType.model";
import { Speciality } from "../types/Speciality.model";
import { Tag } from "../types/Tag.model";
import { getFilteredSellers } from "../redux/Seller/sellerSlice";
import { getFilteredPharmacies } from "../redux/Pharmacy/pharmacySlice";
import { SellerType } from "../types/SellerType.model";

export type facilityFilterValues = (string | number)[];
export type CheckTreeData = { label: string; value: number }[];
export type CheckTreeDataWithChildren = {
  label: string;
  value: number | string;
  children: any;
}[];
export type FilterTagItem = {
  label: string;
  type: string;
};
export interface FilterValues {
  localities: {
    localityId: number;
  }[];
  facilityTypes: { facilityTypeId: number }[];
  serviceTypes: { serviceTypeId: number }[];
  potentials: { potentialId: number }[];
  tags: { tagId: number }[];
  businessUnits: { businessUnitId: number }[];
  specialities: { specialityId: number }[];
  facilities: { facilityId: number }[];
  cycleId?: number | null;
  sellerTypes: { sellerTypeId: number }[];
  shift: number | null;
}

export interface PaginationValues {
  currentPageNumber: number;
  pageSize: number;
}
/*************** Raw data of Filters to be displayed in checktrees *****************/
interface filterContextProps {
  dataBaseContext:
    | "doctor"
    | "doctorTarget"
    | "pharmacy"
    | "seller"
    | "facility";
}
const useGetFiltersRawData = ({ dataBaseContext }: filterContextProps) => {
  const {
    firstLevelLocalities,
    services,
    facilityTypes,
    potentials,
    tags,
    businessUnits,
    specialities,
    requestState,
    sellerTypes,
    errors,
  } = useAppSelector((state: RootState) => state.general);
  const { facilities } = useAppSelector((state: RootState) => state.facility);

  const [localitiesData, setLocalitiesData] =
    useState<CheckTreeDataWithChildren>([]);
  const [servicesData, setServicesData] = useState<CheckTreeData>([]);
  const [facilityTypesData, setfacilityTypesData] = useState<CheckTreeData>([]);
  const [potentialsData, setPotentialsData] = useState<CheckTreeData>([]);
  const [tagsData, setTagsData] = useState<CheckTreeData>([]);
  const [businessUnitsData, setBusinessUnitsData] = useState<CheckTreeData>([]);
  const [specialitiesData, setSpecialitiesData] = useState<CheckTreeData>([]);
  const [facilitiesData, setfacilitiesData] = useState<CheckTreeData>([]);
  const [sellerTypeData, setSellerTypeData] = useState<CheckTreeData>([]);
  const shiftData: any = [
    { label: "Jour", value: 0 , type: "shift", },
    { label: "Nuit", value: 1 , type: "shift", }, 
  ];
  useEffect(() => {
    if (requestState === "idle" && !errors) {
      if (facilities) {
        let locs = facilities.map((loc: Facility) => ({
          label: loc.facilityName,
          value: loc.facilityId,
          type: "facility",
        }));
        setfacilitiesData(locs);
      }
      if (specialities) {
        let specs = specialities.map((spec: Speciality) => ({
          label: spec.specialityName,
          value: spec.specialityId,
          type: "speciality",
        }));
        setSpecialitiesData(specs);
      }
      if (businessUnits) {
        let bus = businessUnits.map((bu: BusinessUnit) => ({
          label: bu.businessUnitName,
          value: bu.businessUnitId,
          type: "businessUnit",
        }));
        setBusinessUnitsData(bus);
      }
      if (tags) {
        let t = tags.map((tag: Tag) => ({
          label: tag.tagName,
          value: tag.tagId,
          type: "tag",
        }));
        setTagsData(t);
      }
      if (potentials) {
        let pots = potentials.map((potential: Potential) => ({
          label: potential.potentialName,
          value: potential.potentialId,
          type: "potential",
        }));
        setPotentialsData(pots);
      }
      if (firstLevelLocalities) {
        let govs = firstLevelLocalities.map((gov: Locality) => ({
          label: gov.localityName,
          value: gov.localityId,
          type: "locality",
          children: [],
        }));
        setLocalitiesData(govs);
      }
      if (services) {
        let obj = services.map((serviceType: ServiceType) => ({
          label: serviceType.serviceTypeName,
          value: serviceType.serviceTypeId,
          type: "service",
        }));
        setServicesData(obj);
      }
      if (facilityTypes) {
        let obj = facilityTypes.map((facilityType: FacilityType) => ({
          label: facilityType.facilityTypeName,
          value: facilityType.facilityTypeId,
          type: "facilityType",
        }));
        setfacilityTypesData(obj);
      }
      if (sellerTypes) {
        let obj = sellerTypes.map((sellerType: SellerType) => ({
          label: sellerType.sellerTypeName,
          value: sellerType.sellerTypeId,
          type: "sellerType",
        }));
        setSellerTypeData(obj);
      }
    }
  }, [
    businessUnits,
    errors,
    firstLevelLocalities,
    facilityTypes,
    facilities,
    potentials,
    requestState,
    services,
    specialities,
    tags,
    sellerTypes,
  ]);

  return {
    localitiesData,
    servicesData,
    facilityTypesData,
    potentialsData,
    tagsData,
    businessUnitsData,
    specialitiesData,
    facilitiesData,
    sellerTypeData,
    shiftData, 
  };
};
/***********************************************************************************/
export const useFilter = ({ dataBaseContext }: filterContextProps) => {
  const dispatch = useAppDispatch();
  const filtersDisplayData = useGetFiltersRawData({ dataBaseContext });
  const [filterValues, setFilterValues] = useState<FilterValues>({
    potentials: [],
    localities: [],
    facilityTypes: [],
    specialities: [],
    serviceTypes: [],
    tags: [],
    businessUnits: [],
    facilities: [],
    cycleId: null,
    sellerTypes: [],
    shift: null,
  });
  const [searchTextValue, setSearchTextValue] = useState("");
  const [paginationValue, setPaginationValue] = useState({
    pageNumber: 1,
    pageSize: 10,
  });
  const captureFilterInputs = (data: FilterValues) => {
    setFilterValues(data);
    setFilterTagsArray(selectedFilters);
  };
  const captureSearchText = (data: string) => {
    setSearchTextValue(data);
  };
  const capturePageTarget = (page: number) => {
    let pagination = { ...paginationValue, pageNumber: page };
    setPaginationValue(pagination);
  };

  const captureLimitTarget = (limit: number) => {
    let pagination = { pageNumber: 1, pageSize: limit };

    setPaginationValue(pagination);
  };

  const [filterTagsArray, setFilterTagsArray] = useState<
    { label: string; type: string }[]
  >([]);

  const [selectedFilters, setSelectedFilters] = useState<
    { label: string; type: string }[]
  >([]);

  const [orderBy, setOrderBy] = useState<number>(0);

  const captureSelectedFilters = (data: any) => {
    if (data.checkState === 0) {
      let newState = [
        ...selectedFilters,
        { label: data.label, value: data.value, type: data.type },
      ];
      setSelectedFilters(newState);
    }
    if (data.checkState === 1) {
      let newState = selectedFilters.filter(
        (item: { label: string; type: string }) => item.label !== data.label
      );
      setSelectedFilters(newState);
    }
  };

  interface FacilityOrderBy {
    [name: string]: number;
    name_desc: number;
    facilityType: number;
    facilityType_desc: number;
    sector: number;
    sector_desc: number;
    locality1: number;
    locality1_desc: number;
    locality2: number;
    locality2_desc: number;
    locality3: number;
    locality3_desc: number;
    locality4: number;
    locality4_desc: number;
    streetName: number;
    streetName_desc: number;
  }

  interface DoctorOrderBy {
    [firstName: string]: number;
    firstName_desc: number;
    lastName: number;
    lastName_desc: number;
    specialities: number;
    specialities_desc: number;
    potential: number;
    potential_desc: number;
    locality1: number;
    locality1_desc: number;
    locality2: number;
    locality2_desc: number;
    locality3: number;
    locality3_desc: number;
    locality4: number;
    locality4_desc: number;
  }


  interface SellerOrderBy {
    [name: string]: number;
    name_desc: number;
    email: number;
    email_desc: number;
    potential: number;
    potential_desc: number;
    sellerType: number;
    sellerType_desc: number;
    shift: number;
    shift_desc: number;
    locality1: number;
    locality1_desc: number;
    locality2: number;
    locality2_desc: number;
    locality3: number;
    locality3_desc: number;
    locality4: number;
    locality4_desc: number;
  }
  
  const captureOrderBy = (sortColumn: string, sortType: string) => {
    let orderByValue = `${
      sortType === "desc" ? sortColumn + "_" + sortType : sortColumn
    }`;
    const facilityOrderBy: FacilityOrderBy = {
      name: 0,
      name_desc: 1,
      facilityType: 2,
      facilityType_desc: 3,
      sector: 4,
      sector_desc: 5,
      locality1: 6,
      locality1_desc: 7,
      locality2: 8,
      locality2_desc: 9,
      locality3: 10,
      locality3_desc: 11,
      locality4: 12,
      locality4_desc: 13,
      streetName: 14,
      streetName_desc: 15,
    };

    const doctorOrderBy: DoctorOrderBy = {
      firstName: 0,
      firstName_desc: 1,
      lastName: 2,
      lastName_desc: 3,
      specialities: 4,
      specialities_desc: 5,
      potential: 6,
      potential_desc: 7,
      locality1: 8,
      locality1_desc: 9,
      locality2: 10,
      locality2_desc: 11,
      locality3: 12,
      locality3_desc: 13,
      locality4: 14,
      locality4_desc: 15,
      facilityType: 18,
      facilityType_desc: 19,
    };

    const sellerOrderBy: SellerOrderBy = {
      name: 0,
      name_desc: 1,
      email: 2,
      email_desc: 3,
      potential: 4,
      potential_desc: 5,
      sellerType: 6,
      sellerType_desc: 7,
      shift: 8,
      shift_desc: 9,
      locality1: 10,
      locality1_desc: 11,
      locality2: 12,
      locality2_desc: 13,
      locality3: 14,
      locality3_desc: 15,
      locality4: 16,
      locality4_desc: 17,
    }
    switch (dataBaseContext) {
      case "facility":
        setOrderBy(facilityOrderBy[orderByValue]);
        break;
      case "doctor":
        setOrderBy(doctorOrderBy[orderByValue]);
        break;
        case "seller":
          setOrderBy(sellerOrderBy[orderByValue]); 
          break;
      default:
        break;
    }
  };

  useEffect(() => {
    const payload =
      dataBaseContext === "doctorTarget"
        ? { ...filterValues, searchTextValue }
        : {
            search: {
              searchText: searchTextValue,
              ...filterValues,
            },
            pagination: paginationValue,
            orderBy,
          };
    dataBaseContext === "doctor" && dispatch(getFilteredDoctor(payload));
    dataBaseContext === "facility" && dispatch(getFilteredFacilities(payload));
    dataBaseContext === "seller" && dispatch(getFilteredSellers(payload));
    dataBaseContext === "pharmacy" && dispatch(getFilteredPharmacies(payload));
    dataBaseContext === "doctorTarget" &&
      dispatch(getFilteredDoctorSummaries(payload));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterValues, searchTextValue, paginationValue, orderBy]);
  const resetSelectedFilters = () => setSelectedFilters([]);

  const [filterState, setFilterState] = useState<{
    localitiesFilters: FilterTagItem[];
    facilityTypesFilters: FilterTagItem[];
    servicesFilters: FilterTagItem[];
    specialitiesFilters: FilterTagItem[];
    facilitiesFilters: FilterTagItem[];
    potentialsFilters: FilterTagItem[];
    tagsFilters: FilterTagItem[];
    businessUnitsFilters: FilterTagItem[];
    sellerTypesFilters: FilterTagItem[];
    shiftFilters: FilterTagItem[];
  }>({
    localitiesFilters: [],
    facilityTypesFilters: [],
    servicesFilters: [],
    specialitiesFilters: [],
    facilitiesFilters: [],
    potentialsFilters: [],
    tagsFilters: [],
    businessUnitsFilters: [],
    sellerTypesFilters: [],
    shiftFilters: [],
  });

  const defineFilterTags = () => {
    let localitiesFilters: FilterTagItem[] = [];
    let facilityTypesFilters: FilterTagItem[] = [];
    let servicesFilters: FilterTagItem[] = [];
    let specialitiesFilters: FilterTagItem[] = [];
    let facilitiesFilters: FilterTagItem[] = [];
    let potentialsFilters: FilterTagItem[] = [];
    let tagsFilters: FilterTagItem[] = [];
    let businessUnitsFilters: FilterTagItem[] = [];
    let sellerTypesFilters: FilterTagItem[] = [];
    let shiftFilters: FilterTagItem[] = [];
    filterTagsArray.forEach((e) => {
      e.type === "locality" && localitiesFilters.push(e);
      e.type === "facilityType" && facilityTypesFilters.push(e);
      e.type === "service" && servicesFilters.push(e);
      e.type === "speciality" && specialitiesFilters.push(e);
      e.type === "facility" && facilitiesFilters.push(e);
      e.type === "potential" && potentialsFilters.push(e);
      e.type === "tag" && tagsFilters.push(e);
      e.type === "businessUnit" && businessUnitsFilters.push(e);
      e.type === "sellerType" && sellerTypesFilters.push(e);
      e.type === "shift" && shiftFilters.push(e)
    });
    setFilterState({
      localitiesFilters,
      facilityTypesFilters,
      servicesFilters,
      specialitiesFilters,
      facilitiesFilters,
      potentialsFilters,
      tagsFilters,
      businessUnitsFilters,
      sellerTypesFilters,
      shiftFilters
    });
  };
  useEffect(() => {
    defineFilterTags();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterTagsArray]);

  return {
    filtersDisplayData,
    filterState,
    selectedFilters,
    filterTagsArray,
    captureFilterInputs,
    captureSearchText,
    capturePageTarget,
    captureLimitTarget,
    captureSelectedFilters,
    captureOrderBy,
    resetSelectedFilters,
  };
};
