import { createSlice } from "@reduxjs/toolkit";
import { API_DEV } from "../../utils/apiRoute";
import { axiosPrivate } from "../../utils/interceptors";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { Pharmacy } from "../../types/Pharmacy.model";

type filteredPharmacies = {
  content: Pharmacy[];
  pagination: {
    currentPageNumber: number;
    pageSize: number;
    totalCount: number;
    totalPagesBySize: number;
  };
};
export interface Pharmaciestate {
  requestState: "loading" | "idle";

  pharmacies: Pharmacy[];
  pharmacyById: Pharmacy | null;
  filteredPharmacies: filteredPharmacies;
  errors: any;
}

export const addPharmacy = createAsyncThunk(
  "[Pharmacies]_add_",
  async (payload: any, { rejectWithValue }) => {
    try {
      await axiosPrivate.post(`${API_DEV}/Pharmacy`, payload);
    } catch (error) {
      console.log(JSON.parse(JSON.stringify(error)));
      return rejectWithValue(JSON.parse(JSON.stringify(error)));
    }
  }
);

export const fetchPharmacies = createAsyncThunk(
  "[Pharmacies]_fetchAll_",
  async (_, { rejectWithValue }) => {
    try {
      let response = await axiosPrivate.get(`${API_DEV}/Pharmacy`);
      return response.data;
    } catch (error) {
      console.log(JSON.parse(JSON.stringify(error)));
      return rejectWithValue(JSON.parse(JSON.stringify(error)));
    }
  }
);
export const fetchPharmacyById = createAsyncThunk(
  "[Pharmacies]_fetchById_",
  async (payload: { id: number; signal: any }, { rejectWithValue }) => {
    try {
      let response = await axiosPrivate.get(
        `${API_DEV}/Pharmacy/${payload.id}`,
        { signal: payload.signal }
      );
      return response.data;
    } catch (error) {
      console.log(JSON.parse(JSON.stringify(error)));
      return rejectWithValue(JSON.parse(JSON.stringify(error)));
    }
  }
);

export const getFilteredPharmacies = createAsyncThunk(
  "[Pharmacies]_getFiltered_",
  async (options: any, { rejectWithValue }) => {
    try {
      let response = await axiosPrivate.post(
        `${API_DEV}/Pharmacy/GetAllWithFilters`,
        options
      );
      return response.data;
    } catch (error) {
      console.log(JSON.parse(JSON.stringify(error)));
      return rejectWithValue(JSON.parse(JSON.stringify(error)));
    }
  }
);

export const updatePharmacy = createAsyncThunk(
  "[Pharmacies]_update_",
  async (payload: any, { rejectWithValue }) => {
    try {
      let response = await axiosPrivate.put(`${API_DEV}/Pharmacy`, payload);
      return response.data;
    } catch (error) {
      console.log(JSON.parse(JSON.stringify(error)));
      return rejectWithValue(JSON.parse(JSON.stringify(error)));
    }
  }
);

export const deletePharmacy = createAsyncThunk(
  "[Pharmacies]_delete_",
  async (id: number, { rejectWithValue }) => {
    try {
      let response = await axiosPrivate.delete(`${API_DEV}/Pharmacy/${id}`);
      return response.data;
    } catch (error) {
      console.log(JSON.parse(JSON.stringify(error)));
      return rejectWithValue(JSON.parse(JSON.stringify(error)));
    }
  }
);
export const deletePharmacyRange = createAsyncThunk(
  "[Pharmacies]_deleteRange_",
  async (payload: any, { rejectWithValue }) => {
    try {
      let response = await axiosPrivate.delete(`${API_DEV}/Pharmacy/Range`, {
        data: payload,
        headers: { "Content-Type": "application/json;charset=utf-8" },
      });
      return response.data;
    } catch (error) {
      console.log(JSON.parse(JSON.stringify(error)));
      return rejectWithValue(JSON.parse(JSON.stringify(error)));
    }
  }
);

const initialState: Pharmaciestate = {
  requestState: "idle",

  pharmacies: [],
  pharmacyById: null,
  filteredPharmacies: {
    content: [],
    pagination: {
      currentPageNumber: 0,
      pageSize: 0,
      totalCount: 0,
      totalPagesBySize: 0,
    },
  },
  errors: null,
};

export const pharmacySlice = createSlice({
  name: "[Pharmacies]",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(addPharmacy.pending, (state) => {
      state.requestState = "loading";
      state.errors = null;
    });
    builder.addCase(addPharmacy.fulfilled, (state) => {
      state.requestState = "idle";
      state.errors = null;
    });
    builder.addCase(addPharmacy.rejected, (state, { payload }) => {
      state.requestState = "idle";
      state.errors = payload;
    });

    builder.addCase(fetchPharmacies.pending, (state) => {
      state.requestState = "loading";
      state.errors = null;
    });
    builder.addCase(fetchPharmacies.fulfilled, (state, { payload }) => {
      state.requestState = "idle";
      state.pharmacies = payload;
      state.errors = null;
    });
    builder.addCase(fetchPharmacies.rejected, (state, { payload }) => {
      state.requestState = "idle";
      state.errors = payload;
    });

    builder.addCase(fetchPharmacyById.pending, (state) => {
      state.requestState = "loading";
      state.errors = null;
    });
    builder.addCase(fetchPharmacyById.fulfilled, (state, { payload }) => {
      state.requestState = "idle";
      state.pharmacyById = payload;
      state.errors = null;
    });
    builder.addCase(fetchPharmacyById.rejected, (state, { payload }) => {
      state.requestState = "idle";
      state.errors = payload;
    });
    builder.addCase(getFilteredPharmacies.pending, (state) => {
      state.requestState = "loading";
      state.errors = null;
    });
    builder.addCase(getFilteredPharmacies.fulfilled, (state, { payload }) => {
      state.requestState = "idle";
      state.filteredPharmacies = payload;
      state.errors = null;
    });
    builder.addCase(getFilteredPharmacies.rejected, (state, { payload }) => {
      state.requestState = "idle";
      state.errors = payload;
    });

    builder.addCase(updatePharmacy.pending, (state) => {
      state.requestState = "loading";
      state.errors = null;
    });
    builder.addCase(updatePharmacy.fulfilled, (state, { payload }) => {
      state.requestState = "idle";
      state.errors = null;
    });
    builder.addCase(updatePharmacy.rejected, (state, { payload }) => {
      state.requestState = "idle";
      state.errors = payload;
    });

    builder.addCase(deletePharmacy.pending, (state) => {
      state.requestState = "loading";
      state.errors = null;
    });
    builder.addCase(deletePharmacy.fulfilled, (state, { payload }) => {
      state.requestState = "idle";
      state.errors = null;
    });
    builder.addCase(deletePharmacy.rejected, (state, { payload }) => {
      state.requestState = "idle";
      state.errors = payload;
    });
    builder.addCase(deletePharmacyRange.pending, (state) => {
      state.requestState = "loading";
      state.errors = null;
    });
    builder.addCase(deletePharmacyRange.fulfilled, (state, { payload }) => {
      state.requestState = "idle";
      state.errors = null;
    });
    builder.addCase(deletePharmacyRange.rejected, (state, { payload }) => {
      state.requestState = "idle";
      state.errors = payload;
    });
  },
});

export default pharmacySlice.reducer;
