import React, { createRef } from "react";
import { Controller } from "react-hook-form";
import { DateRangePicker, Form, Col } from "rsuite";
import { CustomErrorMessage } from "../ErrorMessage/CustomErrorMessage";
type Props = {
  control: any;
  errors: any;
  label: string;
  inputName: string;
  xs?: number;
  md?: number;
  lg?: number;
  cleanable?: boolean;
  readOnly?: boolean;
  onSubmit?: () => void;
  placement?:
    | "bottomStart"
    | "bottomEnd"
    | "topStart"
    | "topEnd"
    | "leftStart"
    | "leftEnd"
    | "rightStart"
    | "rightEnd"
    | "auto"
    | "autoVerticalStart"
    | "autoVerticalEnd"
    | "autoHorizontalStart"
    | "autoHorizontalEnd"
    | "autoHorizontal";
};

export const DateRangeField = ({
  label,
  control,
  inputName,
  errors,
  xs,
  md,
  lg,
  cleanable,
  readOnly,
  placement,
  onSubmit,
}: Props) => {
  const datePickerRangeRef: any = createRef();
  return (
    <Col xs={xs || 24} md={md} lg={lg} style={{ marginBottom: "1rem" }}>
      <Form.Group>
        <Form.ControlLabel>{label}</Form.ControlLabel>
        <Controller
          control={control}
          name="date"
          render={({ field: { onChange, name, value } }) => (
            <Form.Control
              ref={datePickerRangeRef}
              accepter={DateRangePicker}
              name={name}
              isoWeek
              value={value}
              className="input-full-width"
              format={"yyyy-LL-dd"}
              style={{ width: "100%" }}
              cleanable={cleanable}
              readOnly={readOnly}
              container={() => datePickerRangeRef.current}
              preventOverflow
              menuClassName="picker-fixed-top"
              placement={placement ? placement : "autoVerticalStart"}
              onChange={(e: any) => {
                onChange(e);
                onSubmit && onSubmit();
              }}
            />
          )}
        />
        <CustomErrorMessage errors={errors} name={inputName} />
      </Form.Group>
    </Col>
  );
};
